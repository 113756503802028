import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Button, List, Dropdown, Menu, Select, Checkbox, Drawer, Input, DatePicker, Tooltip, Modal } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import Notify, { NotifyStatus } from '../../../components/notify';
import { connect } from 'react-redux';
import HeaderModal from "../../../configureHIS/common/headerModal";
import { isNullOrEmpty } from '../../../libs/util';
import moment from "moment";
import Speaker from '../../../components/speaker';
import * as CoporationServices from "../../../services/CorporationServices";
import * as PacsService from '../../../services/pascService';
import * as PrintService from '../../../services/printService';
import DetailHoSo from '../../exam/hoso/chitietNew';
import Detail from "./detail";
import Edit from "./edit";
import Print from "./pacsPrint";

const { Option } = Select;

const Pacs = forwardRef((props, ref) => {
    const _notiRef = useRef();
    const [roomId, setRoomId] = useState(props.compprops.idloai);
    const [roomName, setRoomName] = useState(props.compprops.title);
    const [skipCount, setSkipCount] = useState(0);
    const [sortingType, setSortingType] = useState('');
    const [sortingField, setSortingField] = useState('');
    const [maxResultCount, setMaxResultCount] = useState(10);
    const [coporationId, setCoporationId] = useState();
    const [keyword, setKeyword] = useState('');
    const [processStatus, setProcessStatus] = useState();
    const [dateFrom, setDateFrom] = useState(moment());
    const [dateTo, setDateTo] = useState(moment());
    const [totalRecord, setTotalRecord] = useState(0);
    const [listResult, setListResult] = useState([]);
    const [sortFields, setSortFields] = useState([]);
    const [sortTypes, setSortTypes] = useState([]);
    const [listCoporations, setListCoporations] = useState([]);
    const listPageSize = [10, 20, 50, 100];
    const [visibleMenu, setVisibleMenu] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [visibleHoSo, setVisibleHoSo] = useState(false);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visiblePrint, setVisiblePrint] = useState(false);


    useEffect(() => {
        setRoomId(props.compprops.idloai);
        setRoomName(props.compprops.title);
    }, [props]);

    useEffect(() => {
        getListCoporations();
        onSearch();
    }, []);

    useEffect(() => {
        if (skipCount > 0) onSearch();
    }, [skipCount]);

    useEffect(() => {
        onSearch(true);
    }, [sortingField, sortingType, maxResultCount]);

    const resetFilter = () => {
        setCoporationId();
        setDateFrom(moment());
        setDateTo(moment());
        setProcessStatus();
        setKeyword();
    }

    const getListCoporations = () => {
        CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
            if (result.isSuccess) {
                setListCoporations(result.data);
                _notiRef && _notiRef.current.Close();
            }
            else {
                if (result.error)
                    _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
                if (result.err)
                    _notiRef.current.Show(result.err.msgString, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
        })
    }

    const enterToSearch = () => {
        setSkipCount(0);
        onSearch(true);
    }

    const onSearch = (clickBtnSrch = false) => {
        if (dateFrom > dateTo) {
            _notiRef.current.Show('"Ngày đăng ký từ" phải nhỏ hơn hoặc bằng "ngày đăng ký đến"', NotifyStatus.Warning);
            return;
        }
        Actions.setLoading(true);
        let skip = clickBtnSrch ? 0 : skipCount;
        setSkipCount(skip);
        let filterModel = {
            skipCount: skip,
            maxResultCount: maxResultCount,
            sorting: ((sortingField ?? '').length < 1 ? 'registeddate' : sortingField) + ((sortingType ?? '').length < 1 ? ' asc' : sortingType),
            keyword: keyword,
            dateFrom: dateFrom,
            dateTo: dateTo,
            coporationIds: coporationId ? [coporationId] : [],
            processStatus: processStatus,
            pacsType: roomId,
        }
        PacsService.getListPacs(filterModel).then(result => {
            if (result.isSuccess) {
                setTotalRecord(result.data.totalCount);
                setSortFields(result.data.sortFields);
                setSortTypes(result.data.sortTypes);
                if (skip == 0)
                    setListResult(result.data.items);
                else {
                    setListResult([...listResult, ...result.data.items]);
                }
                _notiRef && _notiRef.current.Close();
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const onClickFunction = (e, record, sel) => {
        if (!isNullOrEmpty(sel)) {
            e.stopPropagation();
            e.target.parentNode.parentElement.parentNode.parentElement.classList.add("ant-dropdown-hidden");
            if (sel === "preview") loadDetail(record);
            if (sel === "edit") loadDetailToEdit(record);
            if (sel === "print") printData(record);//printData(record.appointmentId, record.managermentId, record.technicalId, record.patientCode, record.appointmentDate);
        } else {
            if (!isNullOrEmpty(record) && record.isDone === true) loadDetail(record);
            if (!isNullOrEmpty(record) && record.isDone === false) loadDetailToEdit(record);
        }
    }

    const loadDetail = (row) => {
        if (row) {
            setVisibleDetail(true);
            setSelectedData(row);
        }
    }

    const loadDetailToEdit = (row) => {
        if (row) {
            setVisibleEdit(true);
            setSelectedData(row);
        }
    }

    const printData = (row) => {
        if (row) {
            if (row.printconfigold) { print(row); }
            else {
                setVisiblePrint(true);
                setSelectedData(row);
            }
        }
    }
    const print = (row) => {
    //   if (!validator.current.allValid()) {
    //     validator.current.showMessages();
    //     notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
    //     forceUpdate();
    //     return;
    //   }
      Actions.setLoading(true);
    //   notiRef && notiRef.current.Close();
      //     PrintService.PrintPASC(idchidinh, maql, idkt, mabn, ngay, roomId).then((result) => {
      //         Actions.DoPrint(result.item)
      //     })
      // public string AppointmentId { get; set; }
      // public Guid PrintTemplateId { get; set; }
      // public List<Guid> ListUploadIds { get; set; }
      let data={
        appointmentId: row.appointmentId,
        printTemplateId: '00000000-0000-0000-0000-000000000000',
        listUploadIds: [],
      };
      PacsService.print(data).then(result => {
        if (result.isSuccess) {
          Actions.DoPrint(result.data)
        }
        else {
            // notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
        }
      }).catch(err => {
        // if (err.error && err.error.messageText)
        //     notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
        // else notiRef.current.Show(err, NotifyStatus.Error);
      }).finally(() => {
        Actions.setLoading(false);
      });
    }

    // const printData = (idchidinh, maql, idkt, mabn, ngay) => { //old version
    //     PrintService.PrintPASC(idchidinh, maql, idkt, mabn, ngay, roomId).then((result) => {
    //         Actions.DoPrint(result.item)
    //     })
    // }

    const handleOpenHoSoKham = (e, record) => {
        e.stopPropagation();
        setSelectedData(record);
        setVisibleHoSo(true);
    }

    const rowOptions = (record) => {
        let items = [
            {
                label:
                    <a href="#" onClick={(e) => {
                        onClickFunction(e, record, "preview");
                    }}><i className='fa fa-eye mr-2' />Xem chi tiết</a>,
                key: 'viewdetail_action',
            },
            {
                label:
                    <a href="#" onClick={(e) => {
                        onClickFunction(e, record, "edit");
                    }}><i className="fas fa-pen mr-2"></i>{record.isDone ? "Cập nhật" : "Thực hiện"}</a>,
                key: 'update_action',
            },
        ];
        if (record.isDone) items.push({
            label:
                <a href="#" onClick={(e) => {
                    onClickFunction(e, record, "print");
                }}><i className='fa fa-print mr-2' />In kết quả</a>,
            key: 'printresult_action',
        });
        return (
            <Menu
                items={items}
            />
        )
    }

    const reloadAfterSaving = () => {
        setVisibleEdit(false);
        setVisibleDetail(true);
        onSearch();
    } 

    return (
        <Fragment>
            <div className="list-page containerChilderWrapper">
                <HeaderModal title={`Danh sách - ${roomName}`} />
                <Notify ref={_notiRef} />
                <div className="list-page-body">
                    <div className="list-page-search">
                        <div className="row">
                            <div className="col-md-4 d-flex flex-column">
                                <label>Ngày đăng ký</label>
                                <div className="d-flex">
                                    <DatePicker
                                        allowClear={false}
                                        locale={viVN}
                                        showNow={false}
                                        className="w-100"
                                        placeholder="Từ ngày"
                                        value={dateFrom}
                                        format={SystemConfig.FORMAT_DATE}
                                        onChange={(e) => {
                                            setDateFrom(e ? moment(e) : null);
                                        }}
                                    />
                                    <div className="m-auto">&nbsp;~&nbsp;</div>
                                    <DatePicker
                                        allowClear={false}
                                        locale={viVN}
                                        showNow={false}
                                        className="w-100"
                                        placeholder="Đến ngày"
                                        value={dateTo}
                                        format={SystemConfig.FORMAT_DATE}
                                        onChange={(e) => {
                                            setDateTo(e ? moment(e) : null);
                                        }}
                                        disabledDate={(date) => date < moment(dateFrom)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <label>Nguồn khách hàng</label>
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) => {
                                        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    className="w-100"
                                    placeholder="Chọn nguồn khách hàng"
                                    maxTagCount='responsive'
                                    value={coporationId}
                                    onChange={(value) => { setCoporationId(value); }}
                                >
                                    {(listCoporations ?? []).map((record, index) => {
                                        return (
                                            <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <label>Trạng thái</label>
                                <Select
                                    className="w-100"
                                    placeholder="Chọn trạng thái"
                                    value={processStatus}
                                    onChange={(value) => { setProcessStatus(value); }}
                                    allowClear
                                >
                                    <Option key={0} value={0}>Chưa thực hiện</Option>
                                    <Option key={1} value={1}>Đã thực hiện</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12 d-flex flex-column">
                                <label>Từ khóa bệnh nhân</label>
                                <Input
                                    placeholder="Nhập mã, tên bệnh nhân hoặc quét mã PID"
                                    value={keyword}
                                    onChange={(e) => {
                                        setKeyword(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            enterToSearch();
                                        }
                                    }}
                                ></Input>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-md-12 d-flex justify-content-end'>
                                <button onClick={() => { onSearch(true) }} className="btn btn-color"><i className="fa fa-search mr-1" />Tìm kiếm</button>
                                <button className="btn btn-secondary ml-2"
                                    onClick={() => { resetFilter(); }}
                                > <i className="fa fa-eraser mr-1" />Xóa dữ liệu nhập</button>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-md-12 pl-1 d-flex justify-content-end'>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <label>Sắp xếp theo</label>
                                    <Select className="ml-1" style={{ width: '140px' }}
                                        placeholder="Ngày đăng ký, tên"
                                        value={sortFields.find(x => x.selected)?.key}
                                        onChange={(value) => { setSortingField(value ?? ''); }}
                                    >
                                        {(sortFields ?? []).map((record, index) => {
                                            return (
                                                <Option key={record.key} value={record.key}>{record.text}</Option>
                                            )
                                        })}
                                    </Select>
                                    <Select className="mx-1" style={{ width: '100px' }}
                                        placeholder="Tăng dần/giảm dần"
                                        maxTagCount='responsive'
                                        value={sortTypes.find(x => x.selected)?.key}
                                        onChange={(value) => { setSortingType(value ?? ''); }}
                                    >
                                        {(sortTypes ?? []).map((record, index) => {
                                            return (
                                                <Option key={record.key} value={record.key}>{record.text}</Option>
                                            )
                                        })}
                                    </Select>
                                    <label>Hiển thị</label>
                                    <Select className="ml-1"
                                        placeholder="10, 20, 50, 100"
                                        value={maxResultCount}
                                        onChange={(value) => { setMaxResultCount(value); }}
                                    >
                                        {listPageSize.map((record, index) => {
                                            return (
                                                <Option key={record} value={record}>{record}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-page-table mt-2">
                        <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                            bordered={false}
                            dataSource={listResult}
                            renderItem={(record, index) => {
                                let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover";
                                return (
                                    <List.Item
                                        onClick={(e) => { onClickFunction(e, record) }}
                                        key={`${record.managermentId}_${record.appointmentId}`}
                                        className={className}>
                                        <div className="d-flex justify-content-between w-100">
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: 18 }}>
                                                {index + 1}
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: 16 }}>
                                                <Checkbox className="mr-2"
                                                    checked={false}
                                                    onChange={() => { }}
                                                    onClick={(e) => { e.stopPropagation(); }}
                                                ></Checkbox>
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: '30%' }}>
                                                <div className="">
                                                    <a href="#" onClick={(e) => { handleOpenHoSoKham(e, record) }}><b>{record.patientName} - {record.patientCode}</b></a>
                                                </div>
                                                <div className="">
                                                    <a href="#" onClick={() => { }}><label>{record.serviceName}</label></a>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: 'calc(36% - 46px)' }}>
                                                <div className="d-flex">
                                                    <div style={{ width: "40% "}}>
                                                        <label className="mita-title">Giới tính: </label>
                                                        <label className="mita-value">{record.gender}</label>
                                                    </div>
                                                    <div>
                                                        <label className='mita-title'>Ngày sinh: </label>
                                                        <label className="mita-value">{record.onlyYear == true ? record.yob : moment(record.dob).format(SystemConfig.FORMAT_DATE)}</label>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <label className="mita-title">Ngày đăng ký: </label>
                                                    <label className="mita-value">{moment(record.registedDate).format(SystemConfig.FORMAT_DATE_TIME)}</label>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: '30%' }}>
                                                <div className="">
                                                    <label className="mita-title">Nguồn khách hàng: </label><a href="#" onClick={() => { }}>{record.coporationName}</a>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    {
                                                        record.isDone
                                                            ? <label><span>Đã thực hiện bởi {record.technician}</span>&nbsp;<Tooltip title={record.doneAt && moment(record.doneAt).format(SystemConfig.FORMAT_DATE_TIME)}><i className='far fa-clock' /></Tooltip></label>
                                                            : <label><span>Chưa thực hiện</span>&nbsp;
                                                                <Speaker sentence={`Mời khách hàng ${record.patientName} vào phòng ${roomName && roomName.toLowerCase().replace("phòng ", "")}`}
                                                                    notShowButton={true}></Speaker>
                                                            </label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: 12 }}>
                                                <Dropdown overlay={rowOptions(record)} trigger={['click']} onClick={(e) => { e.stopPropagation() }}>
                                                    <a className="ant-dropdown-link"><i className='fas fa-ellipsis-v'></i></a>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </List.Item>
                                )
                            }}
                        >
                            <List.Item key="showMore">
                                <div className="w-100 d-flex justify-content-center">
                                    <Button
                                        className='btn btn-secondary'
                                        hidden={totalRecord <= listResult.length}
                                        onClick={() => {
                                            setSkipCount(skipCount + maxResultCount);
                                        }}
                                    >Hiển thị thêm {totalRecord - listResult.length}</Button>
                                </div>
                            </List.Item>
                        </List>
                    </div>
                </div>
            </div>
            <Drawer
                className='custom-ant-drawer'
                maskClosable={false}
                mask={true}
                closable={false}
                width={"40%"}
                placement="right"
                visible={visibleHoSo}
                destroyOnClose={true}
                onCancel={() => { setVisibleHoSo(false) }}>
                <DetailHoSo patientId={selectedData.patientCode} onClose={() => setVisibleHoSo(false)} />
            </Drawer>
            <Drawer className='custom-ant-drawer'
                maskClosable={false}
                mask={true}
                closable={false}
                width={1200}
                placement="right"
                visible={visibleDetail}
                destroyOnClose={true}>
                <Detail
                    roomName={roomName}
                    data={selectedData}
                    // printData={(idchidinh, maql, idkt, mabn, ngay) => { printData(idchidinh, maql, idkt, mabn, ngay); }}
                    printData={() => printData(selectedData)}//setVisiblePrint(true)}
                    onCancel={() => { setVisibleDetail(false); }}
                    hanldeUpdate={() => { setVisibleDetail(false); setVisibleEdit(true); }}
                />
            </Drawer>
            <Drawer className='custom-ant-drawer'
                maskClosable={false}
                mask={true}
                closable={false}
                width={1200}
                placement="right"
                visible={visibleEdit}
                destroyOnClose={true}>
                <Edit
                    roomName={roomName}
                    data={selectedData}
                    afterSaving={() => { reloadAfterSaving() }}
                    onCancel={() => { setVisibleEdit(false); onSearch(); }}
                />
            </Drawer>
            <Modal maskClosable={false}
                width={800}
                height="calc(100% - 10px)"
                visible={visiblePrint}
                onCancel={() => setVisiblePrint(false) }
                destroyOnClose={true}
                // cancelButtonProps={{ style: { display: 'none' } }}
                // okButtonProps={{ style: { display: 'none' } }}
            >
                <Print
                    onCancel={() => setVisiblePrint(false) }
                    data={selectedData}
                    pacsType={roomId}
                />
            </Modal>
        </Fragment>
    )
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Pacs)