import React, { Component } from 'react';
import {
    Modal, Checkbox, notification, Popconfirm, DatePicker,
    Divider, Upload, Tooltip, Input, Select, Tag, Button, Radio
} from 'antd';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import TaoLichLayMau from "../lichhen/taolichhen";
import '../lichhen/lichhen.css';
import * as Actions from '../../libs/actions';
import * as AppointmentService from '../../services/appointmentService';
import viVN from "antd/es/date-picker/locale/vi_VN";
import * as CommonService from "../../services/commonService";
import * as UrlConfig from '../../configure/urlConfig';
import AddOrder from './addOrder';
import * as CollaboratorsServices from '../nhomctv/nhomctvservices';
import * as DistributeServices from '../dieuphoi/distributeservices';
import SimpleReactValidator from 'simple-react-validator';
import * as ChiDinhService from '../../services/chiDinhService';
import { ValidationCustom } from '../../commons/validationCustom';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import { connect } from 'react-redux';
import * as SystemConfig from "../../configure/systemConfig";
import Nguonkhachhang from '../recept/register/nguonkhachhang';
import * as PatientService from '../../services/patientService';
import { IsActive_FeatureLCTV } from '../../configure/featureConfig';

moment.locale('vi_vn');
const localizer = momentLocalizer(moment);
const { Option } = Select;
const { TextArea } = Input;
var GroupCTV = true;
export class CalendarToolbar extends Toolbar {
    constructor(props) {
        super(props);
        this.state = {
            btnKey: 'month',
        }
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-4 mb-2">
                    <div className="btn-group mr-2" role="group">
                        <button className={`btn btn-appointment ${this.state.btnKey === 'PREV' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'PREV' }); this.navigate('PREV') }}><i className="fa fa-angle-left"></i></button>
                        <button className={`btn btn-appointment ${this.state.btnKey === 'NEXT' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'NEXT' }); this.navigate('NEXT') }}><i className="fa fa-angle-right"></i></button>
                    </div>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'TODAY' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'TODAY' }); this.navigate('TODAY') }}>Hôm nay</button>
                </div>
                <div className="col-md-4 mb-2 text-center align-self-center">
                    <div className="h4 text-uppercase">{this.props.label}</div>
                </div>
                <div className="col-md-4 mb-2 text-right">
                    <button className={`btn btn-appointment ${this.state.btnKey === 'month' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'month' }); this.view('month', null) }}>Tháng</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'week' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'week' }); this.view('week', null) }}>Tuần</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'day' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'day' }); this.view('day', null) }}>Ngày</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'agenda' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'agenda' }); this.view('agenda', null) }}>Lịch</button>
                </div>
            </div>
        );
    }
}
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
class LichLayMau extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstEvent: [],
            lstCTV: [],
            visibleCreate: false,
            inEditmode: false,
            selectedAppointment: {},
            selectedPatient: {},
            selectedDate: {},
            isCheckIn: false,
            isGetSample: false,
            fileListA: [],
            fileListB: [],
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            appointmentid: null,
            isCompleted: false,
            ghichu: "",
            group: [],
            key: 0,
            giocheckin: moment(),
            gionhanmau: moment(),
            diadiem: "",
            lat: "",
            lng: "",
            idGiaLayMau: 0,
            listGiaLayMau: [],
            newAddress: '',
            visibleOrder: false,
            dataOrder: [],
            vatinvoice: false,
            payconfirm: false,
            dateRange: [moment().startOf('month'), moment().endOf('month')],
            getType: 1,
            nhomCTV: "all",
            lstNhomCTV: [],
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            createBy: null,
            createAt: null,
            createFrom: null,
            banggia: "default",
            disableCoporation : false
        }
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    componentDidMount() {
        UsersService.CheckKyThuatVien().then(res => {
            if (res.isSuccess) {
                GroupCTV = true;
                this.setState({ chinhanh: this.props.siteid }, () => {
                    this.loadDanhSachLichHen();
                })
            } else {
                GroupCTV = false;
                this.setState({ chinhanh: this.props.siteid }, () => {
                    this.loadDanhSachLichHen();
                    this.getListCollaborators();
                    this.GetListGiaLayMau();
                    this.getListCollaboratorsGroup();
                })
            }
            UsersService.CheckPremissionMultisite().then(result => {
                if (result.isSuccess) {
                    this.setState({
                        isMultisite: result.item
                    });
                    if (result.item == true) {
                        this.GetListSite();
                    }
                }
            });
        }).catch(() => { })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteid !== this.props.siteid) {
            this.loadDanhSachLichHen();
            this.getListCollaborators();
            this.GetListGiaLayMau();
            this.getListCollaboratorsGroup();
        }
    }

    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    notifyUpdate() {
        this.loadDanhSachLichHen(this.state.key);
    }
    GetListGiaLayMau = () => {
        AppointmentService.GetListGiaLayMau(true).then(result => {
            if (result.isSuccess) {
                this.setState({ listGiaLayMau: result.data })
            }
            else {
                notification.error({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            notification.error({ message: err });
        })
    }
    GetListOrderById(id, chinhanh) {
        AppointmentService.GetListOrderById(id, chinhanh).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataOrder: result.data
                })
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
    }
    onSelectEvent = (value) => {
        AppointmentService.ScheduleGetSampleByPatientID(value.id, value.chinhanh).then(result => {
            if (result.isSuccess) {
                this.setState({
                    selectedDate: result.item.ngayhen,
                    selectedPatient: result.item,
                    selectedAppointment: value,
                    visibleDetail: true,
                    mactv: (result.item.mactv || result.item.mactv !== "0") ? result.item.mactv : '',
                    isCheckIn: moment(result.item.giocheckin).year() === 1 ? false : true,
                    isGetSample: moment(result.item.gionhanmau).year() === 1 ? false : true,
                    isCompleted: result.item.dalaymau === 1 ? true : false,
                    appointmentid: value.id,
                    ghichu: result.item.ghichu,
                    mabn: result.item.mabn,
                    giocheckin: moment(result.item.giocheckin).year() !== 1 ? moment(result.item.giocheckin).format(SystemConfig.FORMAT_TIME_DATE) : "",
                    gionhanmau: moment(result.item.gionhanmau).year() !== 1 ? moment(result.item.gionhanmau).format(SystemConfig.FORMAT_TIME_DATE) : "",
                    diadiem: result.item.diachicheckin ? result.item.diachicheckin : "",
                    lat: result.item.lat,
                    lng: result.item.lng,
                    idGiaLayMau: result.item.idGiaLayMau,
                    newAddress: result.item.diachi,
                    vatinvoice: result.item.vatinvoice,
                    payconfirm: result.item.payconfirm,
                    createBy: result.item.createBy,
                    createAt: result.item.createAt,
                    createFrom: result.item.createFrom,
                    disableCoporation: result.item.dalaymau === 1 ? true : false,
                })
                let imageList = [];
                (result.item.image !== null && result.item.image.length > 0) && result.item.image.forEach(image => {
                    imageList.push({
                        uid: image.id,
                        url: this.getUrlPreview(image.filepath),
                        name: image.filename,
                        order: image.sort
                    });
                });
                this.setState({
                    fileListA: imageList.filter(x => x.order === 1), // Hình ảnh trước khi lấy mẫu
                    fileListB: imageList.filter(x => x.order === 2)  // Hình ảnh sau khi lấy mẫu
                });
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
        this.GetListOrderById(value.id, value.chinhanh);
    }
    eventStyleGetter = (e) => {
        var backgroundColor = 'white'
        var color = 'black'
        var diff = moment().diff(e.ngayhen, 'minutes')
        if (e.status === 0) {
            backgroundColor = '#D9822D'
            color = 'white'
        }
        else if (e.status === 2) {
            backgroundColor = '#dc3545'
            color = 'white'
        }
        else {
            if (e.hoantat === 1) {
                backgroundColor = 'rgb(40,167,69)'
                color = 'white'
            } else {
                if (e.dalaymau === 1) {
                    backgroundColor = 'rgba(25,134,218,.8)'
                    color = 'white'
                } else {
                    if (diff > 0) {
                        backgroundColor = 'rgba(32,32,32,0.7)'
                        color = 'white'
                    } else {
                        if (diff > -60) {
                            backgroundColor = 'rgba(240,240,67,0.8)'
                            color = 'black'
                        } else {
                            backgroundColor = 'rgba(242,242,242,0.8)'
                            color = 'black'
                        }
                    }
                }
            }
        }
        var style = {
            fontSize: 12,
            fontFamily: 'Averta',
            cursor: 'pointer',
            backgroundColor: backgroundColor,
            color: color
        };
        return {
            style: style
        };
    }

    getListCollaborators = (getType = this.state.getType, group = this.state.nhomCTV, chinhanh = this.state.chinhanh) => {
        var getAll = getType === 1 ? true : false;
        if (!getAll) {
            var date = moment(this.state.ngayhen).format('YYYY/MM/DD HH:mm:ss');
            DistributeServices.GetListCollaborators(date, true, group, chinhanh).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        lstCTV: res.data,
                    });
                } else {
                    this.setState({
                        lstCTV: [],
                    });
                }
            }).catch(() => { })
        }
        else {
            CommonService.GetListCollaborators(1, 10, false, group, chinhanh).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        lstCTV: res.data,
                    });
                }
            }).catch(() => { })
        }
    }
    loadDanhSachLichHen(key = this.state.key, status = -1) {
        AppointmentService.DanhSachHenLayMau(key, status, this.state.dateRange[0].format('YYYY/MM/DD'), this.state.dateRange[1].format('YYYY/MM/DD'), this.state.chinhanh).then(result => {
            let lstEvent = []
            if (result.isSuccess) {
                for (let item of result.data) {
                    var event = {
                        id: item.id,
                        tenbn: item.tenbn,
                        mabn: item.mabn,
                        ngayhen: item.ngayhen,
                        title: item.tenbn + "-" + item.mabn,
                        start: moment(item.ngayhen).toDate(),
                        end: moment(item.ngayhen).add(1, 'hours').toDate(),
                        dalaymau: moment(item.gionhanmau).year() === 1 ? 0 : 1,
                        hoantat: item.dalaymau,
                        status: item.status,
                        chinhanh: item.chinhanh,
                    }
                    lstEvent.push(event)
                }
                this.setState({
                    lstEvent,
                    group: result.groupUser
                })
            } else {
                Actions.openMessageDialog("Thông báo", result.err.msgString);
                this.setState({
                    lstEvent: [],
                })
            }
        })
    }
    handleCancel = () => {
        this.setState({
            visibleCreate: false
        });
        this.loadDanhSachLichHen(this.state.key);
    }
    disabledDate(current) {
        return current && current.valueOf() < Date.now();
    }

    CapNhatChiTietLichHenLayMau = () => {
        if (this.validator.allValid()) {
            const id = this.state.appointmentid;
            if (id === null || id === undefined)
                notification.warning({ message: "Chưa chọn lịch hẹn" })
            const data = {
                ngayhen: this.state.selectedDate,
                mactv: this.state.mactv,
                mabn: this.state.mabn,
                newAddress: this.state.newAddress,
                idGiaLayMau: this.state.idGiaLayMau,
                ghichu: this.state.ghichu,
                vatinvoice: this.state.vatinvoice,
                payconfirm: this.state.payconfirm
            };
            AppointmentService.CapNhatChiTietLichHenLayMau(id, data, this.state.selectedAppointment.chinhanh).then((result) => {
                if (result.isSuccess) {
                    this.nguonKhachHang && this.nguonKhachHang.updateInfo();
                    notification.success({ message: "Cập nhật thành công" })
                    this.loadDanhSachLichHen(this.state.key);
                    this.setState({
                        inEditmode: false
                    })
                } else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
                Actions.openMessageDialog("Error ", err);
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    updateLichHenLayMau = (event = "") => {
        //event:
        //1. checkin: cập nhật thời gian check in.
        //2. getsample: cập nhật thời gian lấy mẫu (Hình ảnh chụp mẫu máu sẽ lưu ở bước này).
        //3. sendsample: cập nhật thời gian giao mẫu.
        var data = {};
        const id = this.state.appointmentid;
        if (id === null || id === undefined)
            notification.warning({ message: "Chưa chọn lịch hẹn" })
        if (event === "") {
            data = {
                ngayhen: this.state.selectedDate,
                mactv: this.state.mactv
            };
        } else {
            data = {
                ghichu: this.state.ghichu,
                vatinvoice: this.state.vatinvoice,
                payconfirm: this.state.payconfirm
            }
        }
        AppointmentService.updateLichHenLayMau(id, event, data, this.state.selectedAppointment.chinhanh).then((result) => {
            if (result.isSuccess) {
                this.submitUploadFiles();
                this.nguonKhachHang && this.nguonKhachHang.updateInfo();
                this.onSelectEvent(this.state.selectedAppointment);
                this.setState({
                    inEditmode: false
                });
            } else {
                Actions.openMessageDialog("Lỗi " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi ", err);
        })
    }
    deleteLichHenLayMau = () => {
        const id = this.state.appointmentid;
        AppointmentService.deleteLichHenLayMau(id, this.state.selectedAppointment.chinhanh).then((result) => {
            if (result.isSuccess) {
                notification.success({ message: "Đã xóa lịch hẹn thành công" });
                this.loadDanhSachLichHen(this.state.key);
                this.setState({
                    visibleDetail: false
                })
            } else {
                Actions.openMessageDialog("Lỗi " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi ", err);
        })
    }
    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };
    handleChangeA = ({ fileList }) => {
        this.setState({ fileListA: fileList });
    }
    handleChangeB = ({ fileList }) => {
        this.setState({ fileListB: fileList });
    }
    submitUploadFiles = () => {
        let formdata = new FormData();
        let { fileListA, fileListB } = this.state;
        fileListA.forEach(element => {
            formdata.append('listFileA', element.originFileObj);
        });
        fileListB.forEach(element => {
            formdata.append('listFileB', element.originFileObj);
        });
        AppointmentService.uploadHinhAnhMau(formdata, this.state.appointmentid).then(() => {
            notification.success({ message: "Đã cập nhật!" });
            this.onSelectEvent(this.state.selectedAppointment);
        }).catch(() => {
            notification.error({ message: "Không thể upload ảnh!" });
        })
    }
    getUrlPreview = (path) => {
        let url = UrlConfig.BASE_URL + path;
        url = url.replace("\\", "/").replace("/api/wwwroot", "");
        return url
    }
    handleRemove(id) {
        GroupCTV &&
            AppointmentService.deleteHinhAnhMau(id).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Xóa thành công' });
                }
            }).catch(err => {
            })
    }
    XoaChiDinh(row) {
        Actions.setLoading(true);
        ChiDinhService.XoaChiDinh(row.idChiDinh, row.chinhanh).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công" })
                this.GetListOrderById(this.state.appointmentid, this.state.selectedPatient.chinhanh)
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err);
            Actions.setLoading(false);
        })
    }
    getListCollaboratorsGroup = () => {
        CollaboratorsServices.GetListGroup("", 1, -1).then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstNhomCTV: res.data
                });
            } else {
                notification.warning({ message: "Không thể lấy thông tin nhóm" })
            }
        }).catch(err => console.log(err))
    }
    
    checkBangGia = (info) => {
        let banggia = this.state.banggia;
        let itemFound = this.state.dataOrder.find(x => x.banggia !== "" && x.banggia !== null && x.banggia !== undefined);
        if (itemFound !== undefined) banggia = itemFound.banggia;
        if (itemFound !== undefined && info.banggia !== banggia) {
            if (this.state.dataOrder.length > 0) {
                Actions.openMessageDialog("Thông báo",
                    `Việc thay đổi nguồn khách hàng (Bảng giá <b>${info.banggia === "default" ? "Mặc định" : info.banggia}</b>) đã xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`)
                this.cancelExam(itemFound.maql);
            }
            this.setState({ banggia });
        } else this.setState({ banggia: info.banggia });
    }

    cancelExam(managementID) {
        Actions.setLoading(true);
        PatientService.cancelExam(managementID).then((respone) => {
            if (respone.isSuccess) {
                this.GetListOrderById(this.state.appointmentid, this.state.selectedPatient.chinhanh)
            } else {
                Actions.openMessageDialog("Lỗi", respone.err.msgString);
            }
            Actions.setLoading(false);
        })

    }

    render() {
        const { selectedAppointment, inEditmode, selectedPatient, selectedDate, fileListA, fileListB, group } = this.state;
        const uploadButton = (
            <div>
                <i className="fa fa-upload" />
                <div style={{ marginTop: 8 }}>Chọn ảnh</div>
            </div>
        );
        return (
            <div style={{ height: '100%' }}>
                <div className={'containerChilderWrapper'}>
                    <div className='h3 text-center mt-2'>Lịch hẹn lấy mẫu máu</div>
                    {!GroupCTV &&
                        <div className="row no-gutters" style={{ display: "flex" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: '100px' }}>Cộng tác viên&nbsp;</span>
                                <Select
                                    style={{ width: '200px' }}
                                    value={this.state.key}
                                    placeholder="Lọc theo cộng tác viên"
                                    allowClear={false}
                                    onChange={(e) => {
                                        this.setState({ key: e });
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option key={-1} value={0}>Tất cả</Option>
                                    {this.state.lstCTV.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            {/* <div>
                                {this.state.isMultisite === true &&
                                    <Select
                                        style={{ width: '200px' }}
                                        showSearch
                                        allowClear={false}
                                        value={this.state.chinhanh}
                                        onChange={(e) => {
                                            this.setState({ chinhanh: e },()=>{
                                                this.loadDanhSachLichHen();
                                                this.getListCollaborators();
                                            })
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key={-1} value={''}>Chi nhánh</Option>
                                        {this.state.listSite.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.ma}</Option>
                                            )
                                        })}
                                    </Select>
                                }
                            </div> */}
                            <Tooltip title="Tải lại trang">
                                <Button className="btn btn-secondary ml-2"
                                    onClick={() => this.loadDanhSachLichHen()}>
                                    <i className="fa fa-sync" /></Button>
                            </Tooltip>
                        </div>}
                    <div className="row no-gutters" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ backgroundColor: "#fff", padding: 5, borderRadius: 5, lineHeight: 2 }}>
                            <span>Mã màu:&nbsp;</span>
                            <Tag color="rgba(242,242,242,0.8)"><span style={{ color: "#333" }}>Đang chờ</span></Tag>
                            <Tag color="rgba(32,32,32,0.7)">Quá giờ</Tag>
                            <Tag color="rgba(240,240,67,0.8)"><span style={{ color: "#333" }}>1 giờ đổ lại</span></Tag>
                            <Tag color="rgba(25,134,218,.8)">Đã lấy mẫu</Tag>
                            <Tag color="rgb(40,167,69)">Đã bàn giao mẫu</Tag>
                            <Tag color="#D9822D" onClick={() => this.loadDanhSachLichHen(this.state.key, 0)} style={{ cursor: "pointer" }}>Đang chờ xác nhận</Tag>
                            <Tag color="#dc3545" onClick={() => this.loadDanhSachLichHen(this.state.key, 2)} style={{ cursor: "pointer" }}>Từ chối</Tag>
                        </div>
                        {!GroupCTV && <div>
                            <Tooltip title="Tạo lịch hẹn">
                                <button className="btn btn-color"
                                    onClick={() => this.setState({ visibleCreate: true })}>
                                    <i className="fas fa-calendar-plus" />
                                </button>
                            </Tooltip>
                        </div>}
                    </div>
                    <div className="mt-2" style={{ height: "calc(100% - 130px)", backgroundColor: '#fff' }}>
                        <div style={{ height: "100%" }}>
                            <Calendar
                                selectable
                                localizer={localizer}
                                components={
                                    {
                                        toolbar: CalendarToolbar,
                                        week: {
                                            header: ({ date, localizer }) => capitalizeFirstLetter(localizer.format(date, 'dddd, DD'))
                                        },
                                        month: {
                                            header: ({ date, localizer }) => capitalizeFirstLetter(localizer.format(date, 'dddd'))
                                        }
                                    }
                                }
                                messages={{
                                    noEventsInRange: "Chưa có dữ liệu",
                                    date: "Ngày",
                                    time: "Giờ",
                                    event: "Thông tin",
                                    showMore: (count) => <span><i className="fa fa-eye" style={{ marginRight: 5 }} />Xem thêm {count} lịch hẹn</span>
                                }}
                                onSelectEvent={this.onSelectEvent}
                                events={this.state.lstEvent}
                                startAccessor="start"
                                endAccessor="end"
                                eventPropGetter={(this.eventStyleGetter)}
                                onRangeChange={(value) => {
                                    if (value instanceof Array) {
                                        this.setState({ dateRange: [moment(value[0]), moment(value[value.length - 1])] }, () => {
                                            this.loadDanhSachLichHen(this.state.key)
                                        })
                                    }
                                    else {
                                        this.setState({ dateRange: [moment(value.start), moment(value.end)] }, () => {
                                            this.loadDanhSachLichHen(this.state.key)
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {this.state.visibleCreate &&
                        <Modal
                            maskClosable={false}
                            width={1600}
                            visible={this.state.visibleCreate}
                            onCancel={this.handleCancel}
                            okButtonProps={{ disabled: true, hidden: true }}
                            cancelButtonProps={{ style: { display: 'none' } }}>
                            <TaoLichLayMau onCancel={this.handleCancel} layMau={true} chinhanh={this.state.chinhanh} />
                        </Modal>
                    }
                    <Modal
                        maskClosable={false}
                        width={1000}
                        visible={this.state.visibleDetail}
                        onCancel={() => this.setState({ visibleDetail: false })}
                        okButtonProps={{ disabled: true, hidden: true }}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        destroyOnClose={true}>
                        <div>
                            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>{this.state.inEditmode ? "Cập nhật " : "Chi tiết "} lịch hẹn lấy mẫu máu</h3>
                                <h3 onClick={() => this.setState({ visibleDetail: false })}><i className='far fa-times-circle' /></h3>
                            </div>
                            {this.state.createFrom && this.state.createBy && this.state.createAt && <div className='h5 text-danger text-center mt-2'>Được tạo từ <b className='text-uppercase'>{this.state.createFrom}</b> bởi <b className='text-uppercase'>{this.state.createBy}</b> vào ngày <b>{this.state.createAt}</b></div>}
                            <div className="row mt-2">
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Tên bệnh nhân:&nbsp;</span><b>{selectedAppointment.tenbn}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Mã bệnh nhân:&nbsp;</span><b>{selectedAppointment.mabn}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Ngày sinh:&nbsp;</span><b>{selectedPatient.onlyyear ? "Không ghi nhận" : moment(selectedPatient.ngaysinh).format("DD-MM")}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Năm sinh:&nbsp;</span><b>{moment(selectedPatient.ngaysinh).format("YYYY")}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Giới tính:&nbsp;</span><b>{selectedPatient.gioitinh === 0 ? "Nam" : "Nữ"}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>CMND/CCCD:&nbsp;</span> <b>{selectedPatient.cmnd}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Điện thoại:&nbsp;</span><b>{selectedPatient.dienthoai}</b></div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12"><i>*Chỉ có thể thêm/cập nhật nguồn khách hàng khi có thông tin chỉ định</i></div>
                                <div className="col-md-12"><i className='text-danger'>*Việc thay đổi nguồn khách hàng có thể hủy toàn bộ chỉ định ngay lập tức mà <b>không thể hoàn tác</b></i></div>
                                <Nguonkhachhang ref={(c) => this.nguonKhachHang = c} managermentId={selectedPatient.maql} updateInfoBangGia={(info) => { this.checkBangGia(info) }} disable={this.state.disableCoporation} />
                            </div>
                            <div className='row'>
                                <div className="col-md-12 mb-2">
                                    <label>Ghi chú <i>(Từ hotline)</i></label>
                                    <TextArea rows={3} value={selectedPatient.ghichuHotline}
                                        readOnly={true}
                                    ></TextArea>
                                </div>
                                <div className="col-md-12 mb-2"><label>Địa chỉ lấy mẫu</label>
                                    <Input style={{ width: '100%' }} disabled={!inEditmode} value={this.state.newAddress} onChange={(e) => this.setState({ newAddress: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label>Thời gian</label>
                                    <DatePicker locale={viVN} style={{ width: '100%' }} allowClear={false} showTime format={"DD-MM-YYYY HH:mm"} onChange={(e) => { this.setState({ selectedDate: e }) }} value={moment(selectedDate)}
                                        disabledDate={this.disabledDate} disabled={!inEditmode} placeholder="dd-MM-YYYY" />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label>Nhóm cộng tác viên</label>
                                    <Select
                                        style={{ width: '100%' }}
                                        value={this.state.nhomCTV}
                                        placeholder="Chọn nhóm cộng tác viên" disabled={!inEditmode}
                                        onChange={(e) => { this.setState({ nhomCTV: e, mactv: null }, this.getListCollaborators(this.state.getType, e)); }}>
                                        <Option value="all">Tất cả nhóm</Option>
                                        {this.state.lstNhomCTV.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.label}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <label>
                                        <Radio.Group disabled={!inEditmode} onChange={(e) => this.setState({ getType: e.target.value, mactv: null }, this.getListCollaborators(e.target.value, this.state.nhomCTV))} value={this.state.getType}>
                                            <Radio value={1}>Tất cả CTV</Radio>
                                            {IsActive_FeatureLCTV() &&
                                                <Radio value={2}>CTV đã đăng ký lịch</Radio>
                                            }
                                        </Radio.Group>
                                    </label>
                                    <Select
                                        style={{ width: '100%' }}
                                        disabled={!inEditmode}
                                        value={this.state.mactv}
                                        placeholder="Chọn cộng tác viên"
                                        allowClear={false}
                                        onChange={(e) => { this.setState({ mactv: e }) }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        {this.state.lstCTV.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Cộng tác viên", this.state.mactv, "selection")}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <Checkbox
                                        disabled={!inEditmode}
                                        checked={this.state.vatinvoice}
                                        onChange={e => this.setState({ vatinvoice: !this.state.vatinvoice })}
                                    >Xuất hóa đơn đỏ</Checkbox>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Checkbox
                                        disabled={!inEditmode}
                                        checked={this.state.payconfirm}
                                        onChange={e => this.setState({ payconfirm: !this.state.payconfirm })}
                                    >CTV xác nhận đã thanh toán</Checkbox>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <label>Ghi chú</label>
                                    <Input disabled={this.state.isCompleted} placeholder="Nhập ghi chú" value={this.state.ghichu} onChange={(e) => this.setState({ ghichu: e.target.value })} />
                                </div>
                                <div className="col-12 mb-2">
                                    <div className="border-register">
                                        <div data-toggle="collapse" data-target="#chidinh" className="row no-gutters align-items-center">
                                            <div className="h4 font-weight-bold">Chỉ định
                                                ({this.state.dataOrder ? this.state.dataOrder.reduce((tmp, cur) =>
                                                    tmp + Number(cur.dongia), 0).toLocaleString('en-US') : '0'})đ</div>
                                            <Button className="btn btn-color ml-auto"
                                                disabled={!inEditmode} onClick={e => {
                                                    e.stopPropagation();
                                                    this.setState({ visibleOrder: true })
                                                }}>Thêm chỉ định</Button>
                                        </div>
                                        <div class="collapse" id="chidinh">
                                            <div className="table-responsive">
                                                <table class="table table-striped table-inverse table-hover">
                                                    <thead class="thead-inverse">
                                                        <tr>
                                                            <th>STT</th>
                                                            <th>Mã</th>
                                                            <th>Tên</th>
                                                            <th>Giá</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.dataOrder && this.state.dataOrder.map((row, index) =>
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{row.mavp}</td>
                                                                <td>{row.ten}</td>
                                                                <td>{row.dongia?.toLocaleString('en-US')}</td>
                                                                <td>
                                                                    <div style={{ cursor: 'pointer' }}
                                                                        className="text-danger"
                                                                        disabled={row.status === 'Đã đóng tiền'}
                                                                        onClick={() => {
                                                                            this.XoaChiDinh(row, index)
                                                                        }} >
                                                                        <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.diadiem &&
                                    <React.Fragment>
                                        <div className="col-md-12 d-flex mb-2"><span>Địa chỉ check-in:&nbsp;</span><b>{this.state.diadiem}</b></div>
                                        <div className="col-12 mb-2">
                                            <Button className="btn btn-color" type="button" data-toggle="collapse" data-target="#collapseExample">
                                                Xem bản đồ
                                            </Button>
                                            <div class="collapse" id="collapseExample">
                                                <div className="container-iframe-adsress">
                                                    <iframe className="responsive-iframe-adsress" title="Vị trí" src={`https://maps.google.com?q=${this.state.lat},${this.state.lng}&output=embed`}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            {this.state.isCheckIn &&
                                <div>
                                    <Divider orientation="center">Hình ảnh</Divider>
                                    {!this.state.isCompleted || (this.state.isCompleted && (this.state.fileListA.length > 0 || this.state.fileListA.length > 0)) ?
                                        <div className="row" style={{ display: "flex", flexDirection: "row" }}>
                                            <div className="col-md-12">
                                                <Upload
                                                    accept="image/*"
                                                    listType="picture-card"
                                                    multiple={true}
                                                    fileList={fileListA}
                                                    onPreview={this.handlePreview}
                                                    onChange={this.handleChangeA}
                                                    disabled={this.state.isCompleted || !GroupCTV}
                                                    onRemove={img => this.handleRemove(img.uid)}
                                                    beforeUpload={() => false}
                                                >
                                                    {this.state.isCompleted || !GroupCTV ? null : uploadButton}
                                                </Upload>
                                            </div>
                                        </div>
                                        :
                                        <p>Chưa thêm dữ liệu hình ảnh</p>}

                                </div>}
                            <div className='custom-hr'></div>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                                {!this.state.isCompleted &&
                                    <div>
                                        {!this.state.isGetSample && GroupCTV ?
                                            <Popconfirm
                                                title={this.state.isCheckIn ? "Xác nhận đã lấy mẫu máu?" : "Xác nhận đến nơi lấy mẫu?"}
                                                onConfirm={() => { this.state.isCheckIn ? this.updateLichHenLayMau("getsample") : this.updateLichHenLayMau("checkin") }}
                                                okText="Đồng ý" cancelText="Hủy">
                                                <Tooltip title={moment(this.state.selectedDate) > moment() && "Bạn đang thực thực hiện thao tác sớm hơn thời gian hẹn"}>
                                                    <button className="btn btn-color" style={{ marginRight: 10 }}><i style={{ marginRight: 5 }} className={`fa fa-${this.state.isCheckIn ? "save" : "map-marker-alt"}`}></i>{this.state.isCheckIn ? "Hoàn tất lấy mẫu" : "Check-in"}</button>
                                                </Tooltip>
                                            </Popconfirm>
                                            :
                                            !GroupCTV ?
                                                this.state.isGetSample &&
                                                <Popconfirm
                                                    title="Đây là lần chỉnh sửa cuối cùng, bạn xác nhận CTV đã gửi mẫu máu?"
                                                    onConfirm={() => { this.updateLichHenLayMau("sendsample") }}
                                                    okText="Đồng ý" cancelText="Hủy" disabled={!this.state.isGetSample}>
                                                    <button style={{ display: this.state.isCompleted ? "none" : "initial", marginRight: 10 }} className="btn btn-color"><i style={{ marginRight: 5 }} className="fa fa-save"></i>Tiếp nhận mẫu</button>
                                                </Popconfirm>
                                                :
                                                <button style={{ display: this.state.isCompleted ? "none" : "initial", marginRight: 10 }}
                                                    className="btn btn-color" onClick={() => { this.updateLichHenLayMau("getsample") }}><i style={{ marginRight: 5 }} className="fa fa-save"></i>Lưu thay đổi</button>
                                        }
                                    </div>
                                }
                                {!GroupCTV && !this.state.isCompleted && this.state.isCheckIn === false && <div>
                                    {inEditmode === false ?
                                        <button className="btn btn-color" style={{ marginRight: 10 }} onClick={() => this.setState({ inEditmode: true })}><i style={{ marginRight: 5 }} className="fa fa-edit"></i>Cập nhật</button>
                                        :
                                        <button className="btn btn-color" style={{ marginRight: 10 }} onClick={() => this.CapNhatChiTietLichHenLayMau()}><i style={{ marginRight: 5 }} className="fa fa-save"></i>Lưu</button>
                                    }
                                    <Popconfirm
                                        title="Bạn có chắc muốn xóa lịch hẹn này không?"
                                        onConfirm={() => { this.deleteLichHenLayMau() }}
                                        okText="Đồng ý" cancelText="Hủy">
                                        <button className="btn btn-danger" style={{ marginRight: 10 }}
                                        >
                                            <i style={{ marginRight: 5 }} className="fa fa-trash" />Xóa</button>
                                    </Popconfirm>
                                </div>}
                                <button className="btn btn-danger"
                                    onClick={() => {
                                        this.setState({
                                            visibleDetail: false,
                                            inEditmode: false,
                                            mactv: null,
                                            getType: 1,
                                            nhomCTV: 'all',
                                            createBy: null,
                                            createAt: null,
                                            createFrom: null,
                                        });
                                        this.loadDanhSachLichHen();
                                        this.nguonKhachHang && this.nguonKhachHang.resetState();
                                    }}
                                ><i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                </button>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        visible={this.state.previewVisible}
                        footer={null}
                        onCancel={() => this.setState({ previewVisible: false })}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>{this.state.previewTitle}</span>
                            <span onClick={() => this.setState({ previewVisible: false })}><i className="fas fa-times" style={{ fontSize: 24, color: "brown" }} /></span>
                        </div>
                        <br />
                        <img alt={this.state.previewTitle} style={{ width: '100%' }} src={this.state.previewImage} />
                    </Modal>
                </div>
                {this.state.visibleOrder &&
                    <Modal
                        width={1400}
                        visible={this.state.visibleOrder}
                        onCancel={() => this.setState({ visibleOrder: false })}
                    >
                        <AddOrder selectedPatient={this.state.selectedPatient} chinhanh={this.state.chinhanh}
                            reLoad={() => {
                                this.GetListOrderById(this.state.appointmentid, this.state.selectedPatient.chinhanh)
                            }}
                            onClose={() => this.setState({ visibleOrder: false })}
                            banggia={this.state.banggia} />
                    </Modal>
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};



export default connect(mapStateToProps)(LichLayMau)
