import { Button, Form, Modal, Popconfirm, DatePicker, Input, Table, Pagination } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as PascService from '../../../services/pascService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import { FORMAT_DATE } from '../../../configure/systemConfig';


export default class VideoList extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            fileListVideo: [],
            fromDate: moment(),
            toDate: moment(),
            key: ''
        };
    }

    componentWillMount() {
        this.getDanhsachVideo();
    }

    deleteVideo(record) {
        PascService.deleteVideo(record.maql, record.idcd, record.stt).then(result => {
            this.getDanhsachVideo();
        }).catch(err => {
            //    notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }

    downloadVideo = (record) => {

        PascService.downloadVideo(record.maql, record.idcd, record.stt).then(result => {
            result.blob().then((blob) => {
                let urlTemp = window.URL.createObjectURL(blob);
                this._hiddenLinkVideo.href = urlTemp; //Image Base64 Goes here                         
                this._hiddenLinkVideo.download = `${this.state.patientName + '-' + this.state.patientID + '.mp4'}`;
                this._hiddenLinkVideo.click();
            })
        }).catch(err => {
            //    notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }


    getDanhsachVideo() {
        PascService.getdanhsachvideo(this.state.fromDate.format('YYYY-MM-DD'), this.state.toDate.format('YYYY-MM-DD'),
            this.state.key).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        fileListVideo: result.data,
                    });
                    if (result.data.length > 0) {
                        this.setState({
                            totalRecords: result.data[0].count,
                        });
                    }
                }

            }).catch(err => {
            })
    }


    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên bệnh nhân',
                dataIndex: 'hoten',
                key: 'hoten',
            },
            {
                title: 'Ngày sinh',
                dataIndex: 'birthday',
                key: 'birthday',
                render: (text) => {
                    return moment(text).format(FORMAT_DATE)
                }
            },
            {
                title: 'Ngày Tạo',
                dataIndex: 'ngay',
                key: 'ngay',
                render: (text) => moment(text).format(FORMAT_DATE)
            },
            {
                title: '',
                render: (text, record, index) => {
                    return (
                        <div key={'video' + index} style={{
                            position: "relative",
                            width: 140,
                            height: 100, margin: 20, border: '1px solid rgb(232,232,232)',
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }} >

                            <img style={{ position: 'absolute', top: 0, left: 0 }} alt="thumnailPASC" src={`/api/Pasc/thumnail?maql=${record.maql}&idcd=${record.idcd}&stt=${record.stt}`}
                                width="100%" />
                            <Button style={{ marginRight: 10 }} onClick={() => {
                                this.setState({
                                    patientID: record.mabn, patientName: record.hoten,
                                    selectedVideo: record, previewVisibleVideo: true
                                })
                            }}>
                                <i className="fas fa-eye" style={{ marginRight: 5 }}></i>
                            </Button>

                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.deleteVideo(record) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger"><i className="fa fa-trash" style={{ marginRight: 5 }} /></button>
                            </Popconfirm>
                        </div>
                    )
                },
            },
        ];
        let dataSource1 = this.state.fileListVideo.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLinkVideo = c }} >download</a>
                <div className='containerChilderWrapper' >
                    <div className='h4 text-center mt-2 font-bold text-uppercase'>Quản lý danh sách video</div>
                    <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="row" style={{ display: 'flex' }}>
                            <div className="col-md-6">
                                <Form.Item label="Từ ngày:">
                                    <DatePicker locale={viVN} format={"DD-MM-YYYY"}
                                        disabledDate={this.disabledDate} allowClear={false}
                                        defaultValue={moment()}
                                        placeholder="DD-MM-YYYY"
                                        onChange={(value) => (this.setState({ fromDate: value }, () => this.getDanhsachVideo()))} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Đến ngày:">
                                    <DatePicker locale={viVN} format={"DD-MM-YYYY"}
                                        disabledDate={this.disabledDate} allowClear={false}
                                        defaultValue={moment()}
                                        placeholder="DD-MM-YYYY"
                                        onChange={(value) => (this.setState({ toDate: value }, () => this.getDanhsachVideo()))} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row" style={{ display: 'flex' }}>
                            <div className="col-md-6">
                                <Form.Item>
                                    <Input
                                        value={this.state.key}
                                        placeholder="Nhập tên bệnh nhân"
                                        onChange={(e) => this.setState({ key: e.target.value })}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.getDanhsachVideo();
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item>
                                    <button className="btn btn-success"
                                        onClick={() => { this.getDanhsachVideo() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{ textAlign: 'end' }}>
                        <button className="btn btn-success"
                            onClick={() => this.setState({ listModal: true })}
                        ><i className={"fas fa-list"} style={{ marginRight: 5 }} />Danh sách Video</button>
                    </div> */}
                    <div  style={{ paddingTop: 10 , height:'calc(100% - 110px)'}}>
                        <Table dataSource={dataSource1} scroll={{ x: 'max-content', y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        current={this.state.currentPage}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}

                        showSizeChanger={true}
                    />
                </div>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                <Modal maskClosable={false}
                    className={"modalPreview"}
                    width={900}
                    style={{ width: '900px', height: '700px', backgroundColor: 'transparent' }}
                    visible={this.state.previewVisibleVideo}
                    title={"video"}
                    footer={null}
                    onCancel={() => this.setState({ previewVisibleVideo: false })}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        {this.state.selectedVideo &&
                            <video id="myvideo" height="400" width="600" controls>
                                <source src={`/api/Pasc/playvideo?maql=${this.state.selectedVideo.maql}&idcd=${this.state.selectedVideo.idcd}&stt=${this.state.selectedVideo.stt}`} type="video/mp4" />
                            </video>
                        }
                    </div>
                    <div style={{
                        display: 'flex',
                        marginTop: 10, justifyContent: 'center'
                    }}>
                        <Button type="primary" style={{}}
                            onClick={() => {
                                this.downloadVideo(this.state.selectedVideo)
                            }}>
                            <i className="fas fa-download" style={{ marginRight: 5 }}
                            /> Tải về

                        </Button>
                        <Button type="primary" style={{ marginLeft: 30 }} danger
                            onClick={() => {
                                this.setState({ previewVisibleVideo: false })
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng

                        </Button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}


