import { Select } from 'antd';
import React, { Component } from 'react';
const { Option } = Select;
let timeout;


export default class autocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            value: undefined,
            text: ''
        };
    }
    Search(value) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => {
            if (this.props.handleSearch) {
                this.props.handleSearch(value, this.props.title);
            }
        }, 300)
    }
    setValue(item) {
        if (this.props.getItem) {
            if (item)
                this.props.getItem(item.key, item.children)
            else {
                this.props.getItem(null, null)
            }
        }
    }
    clear() {
        this.setState({
            data: [],
            value: undefined,
            text: ''
        });
    }
    render() {
        var options = []

        if (this.props.dataSource && this.props.dataSource.length > 0)
            options = this.props.dataSource.map(d => <Option key={d.value}>{d.label}</Option>);

        return (
            <Select
                getPopupContainer={trigger => trigger.parentNode}
                className={this.props.className !== undefined ? this.props.className : ''}
                disabled={this.props.disabled !== undefined ? this.props.disabled : false}
                style={{ width: `${this.props.width !== undefined ? this.props.width : '165px'}`, height: '28px' }}
                showSearch
                placeholder={this.props.placeholder !== undefined ? this.props.placeholder : "Nhập để tìm kiếm"}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(e) => { this.Search(e) }}
                onChange={(e, item) => { this.setValue(item) }}
                notFoundContent={null}
                value={this.props.value}
                listHeight={this.props.listHeight ? this.props.listHeight : 500}
                allowClear={this.props.allowClear}
            >
                {options}
            </Select>
        );
    }

}