import * as ApiCaller from '../libs/httpRequests';
const prefix = 'PTTT';


export function DanhSachTenPhauThat(key="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachTenPhauThat?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ThongTinThuThuatBenhNhan(mabn,maql,mavp,ngay, idchidinh='')  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinThuThuatBenhNhan?mabn=${mabn}&maql=${maql}&mavp=${mavp}&ngay=${ngay}&idchidinh=${idchidinh}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DanhSachLoaiPhauThat(key="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachLoaiPhauThat?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};




export function ThongTinThuThuatBenhNhanPhongLuu(mabn)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinThuThuatBenhNhan?mabn=${mabn}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function DanhSachPPhauThuat() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/pppttt`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachKhoa() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dskhoa`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachMeTe() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsmete`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachLichPT(key, makp, tungay, denngay, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dslichptt?key=${key}&makp=${makp}&tungay=${tungay}&denngay=${denngay}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhatPTT(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/updatethongtinpt`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function LuuLichPT(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luulichptt`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};