import * as ApiCaller from '../../libs/httpRequests';
const prefix='Consult';

export function GetListConsults(dateFrom, dateTo, key, page, size, status, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListConsults?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}&uncompleted=${status}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
