import { Table, Popconfirm, notification, Modal, Input, Form, Pagination } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import Autocomplete from '../../../components/autocomplete';
import * as KhoService from '../../../services/KhoService';
import * as Actions from '../../../libs/actions';
// import Form from 'antd/lib/form/Form';

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            lstKho: [],
            lstKhoConfig: [],
            currentpage: 1,
            pagesize: 10,
            name: '',
            value: '',

            txtsearch: '',

            createModal: false,
            listPhongham: '',

            txtWareHouseName: '',
            idWarehouse: 0,

            pkobj: '',
            totalRecords: 0,


        };
    }

    componentWillMount() {
        this.loadDanhSachKho('');
    }




    loadDanhSachKho(key) {
        KhoService.getListWareHouse(key, this.state.currentpage, this.state.pagesize).then((respone) => {
            if (respone.isSuccess === true) {
                this.setState({ lstKho: respone.data, totalRecords: respone.totalRecords })
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }

    summit() {
        let { txtWareHouseName, pkobj } = this.state;
        let data = { tenkho: txtWareHouseName, maphong: +pkobj };

        if (this.validator.allValid()) {
            KhoService.addWareHouse(data).then((respone) => {
                if (respone.isSuccess === true) {
                    notification.success({ message: "Thêm thành công!" })
                    this.loadDanhSachKho('');
                    this.setState({
                        createModal: false, txtWareHouseName: '', wareHouseId: 0, txtsearch: '', pkobj: ''
                    })
                } else {
                    Actions.openMessageDialog("Error", respone.err.msgString);
                }
            }).catch((ex) => {
                Actions.openMessageDialog("Error", ex);
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    update() {
        let { txtWareHouseName,
            pkobj, makho } = this.state

        let data = { tenkho: txtWareHouseName, maphong: +pkobj, makho };
        if (this.validator.allValid()) {
            KhoService.updateWareHouse(this.state.idWarehouse, data).then((respone) => {
                if (respone.isSuccess === true) {
                    notification.success({ message: "Chỉnh sửa thành công!" })
                    this.loadDanhSachKho('');
                    this.setState({
                        createModal: false, txtWareHouseName: '', idWarehouse: 0, txtsearch: '', pkobj: ''
                    })
                } else {
                    Actions.openMessageDialog("Error", respone.err.msgString.toString());
                }
            }).catch((ex) => {
                Actions.openMessageDialog("Error", ex.toString());
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    delete(id) {

        KhoService.deleteWareHouse(id).then((respone) => {
            if (respone.isSuccess === true) {
                notification.success({ message: "xóa thành công!" })
                this.loadDanhSachKho('');
                this.setState({
                    createModal: false, txtWareHouseName: '', idWarehouse: 0, txtsearch: '',
                })
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }

    getColumns() {
     
        return [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => ((this.state.currentpage - 1) * 10 + Number(index) + 1),
            },
            {
                title: 'Tên Kho',
                dataIndex: 'tenkho',
                key: 'tenkho',
            },
           
            {
                title: 'Actions',
                render: (row, index) => {
                    return (
                        <div>
                            {![502, 519, 520].includes(row.id) &&
                                <div>
                                    <button className="btn btn-warning"
                                        onClick={() => {
                                            let pkobj = row.maphong
                                            let makho = row.makho
                                            this.setState({
                                                idWarehouse: row.id,
                                                pkobj,
                                                makho,
                                                txtWareHouseName: row.tenkho,
                                                createModal: true
                                            });

                                        }}
                                        style={{ marginRight: 10 }}>
                                        <i className="fa fa-edit" style={{ marginRight: 5 }}></i> Cập nhật
                                    </button>

                                    <Popconfirm
                                        title="Bạn có chắc không muốn dùng kho thuốc này?"
                                        onConfirm={() => { this.delete(row.id) }}
                                        okText="Đồng ý" cancelText="Hủy">
                                        <button className="btn btn-danger" ><i className="fa fa-trash" style={{ marginRight: 5 }}></i>Xóa
                                        </button>
                                    </Popconfirm>
                                </div>
                            }
                        </div>
                    )
                }
            }

        ];
    }
    render() {
        return (
            <>
                <div className='containerChilderWrapper' >
                    <div className='h4 text-center mt-2 font-bold text-uppercase'>Danh sách kho</div>
                    <div className="row no-gutters" style={{ justifyContent: 'center', alignItems: "center", padding: '20px' }}>
                        <div className="col-auto">
                            <Input
                                placeholder={"Nhập tên kho để tìm"}
                                value={this.state.txtsearch}
                                onChange={(e) => this.setState({ txtsearch: e.target.value })}
                                onPressEnter={() => this.loadDanhSachKho(this.state.txtsearch)} />
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => {
                                this.loadDanhSachKho(this.state.txtsearch)
                            }} >  <i className="fa fa-search" style={{ marginRight: 5 }} />Tìm</button>

                            <button className="btn btn-success" style={{ marginLeft: 5 }} onClick={() => {
                                this.setState({ createModal: true })
                            }} >  <i className="fa fa-plus" style={{ marginRight: 5 }} />Tạo mới</button>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: 'calc(100% - 145px)' }}>
                        <Table dataSource={this.state.lstKho}
                            pagination={false}
                            columns={this.getColumns()}
                            scroll={{ x: 'max-content', y: 0 }}
                        />
                        <Pagination
                            current={this.state.currentpage}
                            position={["bottomCenter"]}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => {
                             
                                this.setState({ currentpage: page })

                                setTimeout(() => {
                                    this.loadDanhSachKho(this.state.txtsearch)
                                }, 100)
                            }}
                            onShowSizeChange={(page, size) => {
                                this.setState({ pagesize: size,currentpage:1 })
                                setTimeout(() => {
                                 
                                    this.loadDanhSachKho(this.state.txtsearch)
                                }, 1000)
                            }}
                            showSizeChanger={true}
                        />
                    </div>
                </div>

                <Modal maskClosable={false} visible={this.state.createModal}
                    width={500}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>{this.state.idWarehouse !== 0 ? "Cập nhật kho" : "Thêm kho"}</h3>
                    </div>
                    <div>
                        <div className="row no-gutters">
                            <Form.Item label="Tên kho:">
                                <Input
                                    style={{ width: 350 }}
                                    value={this.state.txtWareHouseName}
                                    onChange={(e) => this.setState({ txtWareHouseName: e.target.value })} />
                                {this.validator.message("Tên kho", this.state.txtWareHouseName, "required")}
                            </Form.Item>
                        </div>

                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '12px',
                        marginTop: '15px'
                    }}>
                        <button className="btn btn-success" style={{ marginRight: 15 }} onClick={() => {
                            if (this.state.idWarehouse !== 0) {
                                this.update()
                            } else {
                                this.summit()
                            }

                        }}> <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>{' '}
                        <button className="btn btn-danger"
                            onClick={() => {
                                this.setState({
                                    createModal: false, txtWareHouseName: '', idWarehouse: 0,
                                });
                                this.validator.hideMessages();
                            }}><i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                    </div>
                </Modal>
            </>
        )
    }
}
