import React, { Component } from 'react'
import { Input, Form, notification,InputNumber } from 'antd';
import * as VPService from '../services/VPService';

const initialState = {
    maloaitt: '',
    tenloaitt: '',
    viettat: '',
    stt:0,
}

let timeOut = null;
export default class CreateNewLoaiTT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            lstLoaiTT: []
        };
    }
    async CreateLoaiTT() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                ten: this.state.tenloaitt,
                viettat: this.state.viettat,
                stt : Number(this.state.stt),
            }
            VPService.CreateLoaiTT(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.resetState();
            }).catch(err => {
                notification.warning({ message: 'Tạo thất bại!' });
            })
        }
    }
    resetState() {
        timeOut = setTimeout(() => {
            this.form.resetFields();
        }, 20);
        this.form.setFieldsValue({
            maloaitt: null,
            tenloaitt: null,
            stt:null
        });
    }
    
    componentWillUnmount() {
        if (timeOut) {
            clearTimeout(timeOut);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm loại thanh toán</h3>
                    </div>
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ marginBottom: 10, marginTop: 10, display:'none' }}>
                            <Form.Item style={{ flex: 1 }} label="Mã loại thanh toán:" name="maloaitt" >
                                <Input
                                    value={this.state.manhomvp}
                                    onChange={(e) => this.setState({ mavp: e.target.value })}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'left' }} >
                            <Form.Item style={{ width: 500 }} label="Tên loại thanh toán:" name="tenloaitt"
                                rules={[{ required: true, message: 'Yêu cầu nhập tên loại thanh toán!' }]}>
                                <Input
                                    onChange={(e) => this.setState({ tenloaitt: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item style={{ width: 500 }} label="Thứ tự:" name="stt">
                                <InputNumber 
                                    min={0}
                                    onChange={(e) => this.setState({ stt: e })}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>

                            <button className="btn btn-success"
                                onClick={() => { this.CreateLoaiTT() }}>
                                <i className="fas fa-plus" style={{ marginRight: 5 }} />Thêm</button>
                            <button className="btn btn-warning" style={{ marginLeft: 10 }}
                                onClick={() => this.resetState()}>
                                <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.resetState();
                                    this.props.onCancel();
                                    this.props.onReload();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
