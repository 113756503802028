import Autocomplete from '../../../../../components/autocomplete';
import React, { useEffect, useState } from 'react';
import { Form, Input, Select, InputNumber, notification, Switch, Tooltip } from 'antd';
import * as MedicineService from '../../../../../services/medicineService';
const { Option, OptGroup } = Select;
export default function UpdateMedicine(props) {
    const [detail, setDetail] = useState({});
    const [lstLoai, setLstLoai] = useState([]);
    const [lstNuocSX, setLstNuocSX] = useState([]);
    const [lstHangSX, setLstHangSX] = useState([]);
    const [lstDangThuoc, setLstDangThuoc] = useState([]);
    const [lstDVT, setLstDVT] = useState([]);
    const [lstBaoQuan,setlstBaoQuan] = useState([]);
    const [giaban, setGiaban] = useState(0);
    const [mathuoc, setMathuoc] = useState('');
    const [idthuoc, setIdthuoc] = useState('');
    const [loai, setLoai] = useState('');
    const [quycach, setQuycach] = useState('');
    const [tenthuoc, setTenthuoc] = useState('');
    const [donvi, setDonvi] = useState('');
    const [hamluong, setHamluong] = useState('');
    const [baoquan, setbaoquan] = useState('');
    const [hangsx, setHangsx] = useState('');
    const [nuocsx, setNuocsx] = useState('');
    const [gia_bh, setGia_bh] = useState(0);
    const [gianhap, setGianhap] = useState(0);
    const [hopchat, setHopchat] = useState('');
    const [madang, setMadang] = useState('');
    const [hienthi, setHienthi] = useState(true);
    const [form] = Form.useForm();
    const [tonToiThieu, setTonToiThieu] = useState(0);
    const [donViSuDung, setDonViSuDung] = useState(null);
    useEffect(() => {
        loadHSX();
        loadLoaiDMBD();
        loadNuocSX();
        loadDangThuoc();
        loadBaoQuan();
    }, []);
    useEffect(() => {
        setDetail(props.detail);
    }, [props.detail]);
    useEffect(() => {
        setQuycach(detail.quycach);
        setLoai(detail.loai);
        setIdthuoc(detail.id);
        setTenthuoc(detail.ten);
        setMathuoc(detail.ma);
        setDonvi(detail.donvi);
        setHamluong(detail.hamluong);
        setGiaban(detail.giaban);
        setGia_bh(detail.gia_bh);
        setHangsx(detail.idhang);
        setNuocsx(detail.nuocsx ? detail.nuocsx : null);
        setGianhap(detail.gianhap);
        setHopchat(detail.tenhc);
        setMadang(detail.madang);
        setbaoquan(detail.baoquan !== "" && detail.baoquan != "0" ? Number(detail.baoquan) : null);
        setHienthi(detail.hienthi === "0" ? true : false)
        setTonToiThieu(detail.sltoithieu);
        setDonViSuDung(detail.donViDung);
        form.setFieldsValue({
            quycach: detail.quycach,
            loai: detail.loai,
            ma: detail.ma,
            tenthuoc: detail.ten,
            donvi: detail.donvi,
            giaban: detail.giaban,
            gia_bh: detail.gia_bh,
            hangsx: detail.tenhang,
            nuocsx: detail.nuocsx ? detail.nuocsx : '',
            hamluong: detail.hamluong,
            gianhap: detail.gianhap,
            madang: detail.madang,
            donViSuDung: detail.donViDung,
        })
    }, [detail]);
    const update = async () => {
        let numberError = 0;
        await form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (giaban < 0 || giaban === undefined) {
            notification.warning({ message: 'Giá bán không hợp lệ!' });
            return;
        }
        if (numberError === 0) {
            const data = {
                ma: mathuoc,
                id: Number(idthuoc),
                ten: tenthuoc,
                tenhc: hopchat,
                donvi: donvi,
                hamluong: hamluong,
                giaban: Number(giaban),
                gia_bh: Number(gia_bh),
                hangsx: hangsx,
                quycach: quycach,
                nuocsx: nuocsx,
                loai: loai,
                idhang: Number(hangsx),
                nhom: props.nhom,
                dang: madang,
                baoquan: baoquan !== null ? baoquan.toString() : '',
                hienthi : hienthi ? '0' : '1',
                sltoithieu: tonToiThieu,
                donViDung: donViSuDung,
            }
            MedicineService.CapNhatThuoc(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    props.onClose && props.onClose();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
                notification.warning({ message: err })
            })
        }
    }
    const loadHSX = (e, page = 1, size = 10) => {
        MedicineService.HangSanXuat(e, page, size).then(result => {
            if (result.isSuccess) {
                setLstHangSX(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu hãng sản xuất bị lỗi' })
        })
    }
    function loadDangThuoc() {
        MedicineService.DangThuoc().then(result => {
            if (result.isSuccess) {
                setLstDangThuoc(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dạng thuốc bị lỗi' })
        })
    }
    function loadLoaiDMBD() {
        MedicineService.DanhSachLoaiDMBD().then(result => {
            if (result.isSuccess) {
                setLstLoai(result.data);
            }
        }).catch(err => {
        })
    }
    function loadNuocSX() {
        MedicineService.NuocSanXuatThuoc().then(result => {
            if (result.isSuccess) {
                setLstNuocSX(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nước sản xuất bị lỗi' })
        })
    }

    function loadBaoQuan(){
        MedicineService.DanhSachBaoQuan().then(result => {
            if (result.isSuccess) {
                setlstBaoQuan(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dạng bảo quản bị lỗi' })
        })
    }
    function loadDonViTinh(key) {
        MedicineService.DonViTinh(key).then(result => {
            if (result.isSuccess) {
                setLstDVT(result.data);
            }
        }).catch(err => {
        })
    }
    function isMedicine(item) {
        if (lstLoai.find(x => x.value === item) !== undefined) {
            return (lstLoai.find(x => x.value === item)).nhom === 1
        }
    }
    return (
        <React.Fragment>
            <Form form={form} name="control-hooks">
                <div className="modal-header" style={{ display: 'flex', justifyContent: 'left' }}>
                    <h3>{detail.nhom === 1 ? "Cập nhật thông tin thuốc" : "Cập nhật thông tin vật tư"}</h3>
                </div>
                <div className="row">
                    <div className="col-md-10 mt-2">
                        <label>Mã thuốc - vật tư</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Form.Item name="ma" className='input-not-formitem'
                            rules={[{ required: true, message: 'Yêu cầu nhập mã thuốc!' }]}>
                            <Input
                                value={mathuoc}
                                onChange={(e) => setMathuoc(e.target.value)}>
                            </Input>
                        </Form.Item>
                    </div>
                    <div className="col-md-2 mt-2">
                        <label>Sử dụng</label>
                        <Form.Item className='input-not-formitem'>
                            <Switch checkedChildren="Hiện" unCheckedChildren="Ẩn" 
                            checked={hienthi}
                            onChange={(e) => {
                                setHienthi(e)
                            }} 
                            />
                        </Form.Item>
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Tên thuốc - vật tư</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Form.Item name="tenthuoc" className='input-not-formitem'
                            rules={[{ required: true, message: 'Yêu cầu nhập tên thuốc!' }]}>
                            <Input
                                value={tenthuoc}
                                onChange={(e) => setTenthuoc(e.target.value)}>
                            </Input>
                        </Form.Item>
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Hoạt chất</label>
                        <Form.Item className='input-not-formitem'>
                            <Input placeholder="Nhập hoạt chất"
                                value={hopchat}
                                disabled={!isMedicine(loai)}
                                onChange={(e) => setHopchat(e.target.value)} />
                        </Form.Item>
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Loại</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Form.Item name="loai" className='input-not-formitem' rules={[{ required: true, message: 'Vui lòng chọn loại thuốc!' }]}>
                            <Select style={{ width: '100%' }}
                                showSearch
                                onChange={(e) => {
                                    setLoai(e);
                                    // if (!isMedicine(e)) {
                                        setMadang(null);
                                        form.setFieldsValue({
                                            madang: null
                                        });
                                        form.resetFields(["madang"]);
                                    // }
                                }}
                                filterOption={(input, option) =>
                                    option.children !== undefined && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {/* {isMedicine(loai) ?
                                    <OptGroup label="Nhóm thuốc">
                                        {lstLoai.filter(x => x.nhom === 1).map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </OptGroup> :
                                    <OptGroup label="Nhóm vật tư">
                                        {lstLoai.filter(x => x.nhom === 2).map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </OptGroup>
                                } */}
                                <OptGroup label="Nhóm thuốc">
                                    {lstLoai.filter(x => x.nhom === 1).map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </OptGroup>
                                <OptGroup label="Nhóm vật tư">
                                    {lstLoai.filter(x => x.nhom === 2).map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Nước sản xuất</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Form.Item name="nuocsx" className='input-not-formitem' rules={[{ required: true, message: 'Vui lòng chọn nước sản xuất!' }]}>
                            <Select className="auto"
                                showSearch
                                onChange={(e) => setNuocsx(e)}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {lstNuocSX.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Hãng sản xuất</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Form.Item name="hangsx" className='input-not-formitem'
                            rules={[{ required: true, message: 'Yêu cầu chọn hãng sản xuất!' }]}>
                            <Autocomplete
                                value={hangsx}
                                width='100%'
                                dataSource={lstHangSX}
                                handleSearch={loadHSX.bind(this)}
                                getItem={(key) => { form.setFieldsValue({ hangsx: key }); setHangsx(key) }} />
                        </Form.Item>
                    </div>
                    <div className="col-md-4 mt-2">
                        <label>Giá nhập</label>
                        <Form.Item className='input-not-formitem'>
                            <InputNumber className="w-100" value={gianhap}
                                disabled={true}
                                formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                        </Form.Item>
                    </div>
                    <div className="col-md-4 mt-2">
                        <label>Giá bán</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Form.Item name="giaban" className='input-not-formitem'>
                            <InputNumber
                                onKeyDown={(e) => {
                                    if (e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                defaultValue={0}
                                style={{ width: '100%' }}
                                min={0}
                                maxLength={10}
                                value={giaban}
                                formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(e) => setGiaban(e)}>
                            </InputNumber>
                        </Form.Item>
                    </div>
                    <div className="col-md-4 mt-2">
                        <label>Đơn vị tính</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Form.Item name="donvi" style={{ flex: 1 }} className='input-not-formitem'
                            rules={[{ required: true, message: 'Yêu cầu nhập đơn vị tính!' }]}>
                            <Autocomplete
                                width='100%'
                                dataSource={lstDVT}
                                handleSearch={loadDonViTinh.bind(this)}
                                getItem={(key, value) => { setDonvi(value); form.setFieldsValue({ donvi: key }) }} />
                        </Form.Item>
                    </div>
                    
                    <div className="col-md-6 mt-2">
                        <label>Hàm lượng</label>
                        <Form.Item className='input-not-formitem'>
                            <Input
                                disabled={!isMedicine(loai)}
                                maxLength={20}
                                value={hamluong}
                                onChange={(e) => setHamluong(e.target.value)} />
                        </Form.Item>
                    </div>
                    <div className="col-md-3 mt-2">
                        <label>Tồn tối thiểu</label>
                        <InputNumber
                            className="w-100"
                            placeholder="Nhập số lượng tồn tối thiểu"
                            min={0}
                            value={tonToiThieu}
                            onChange={(e) => setTonToiThieu(e)} />
                    </div>
                    <div className="col-md-3 mt-2">
                        <label>Đơn vị dùng</label><Tooltip title="Đơn vị tính khi sử dụng: Ví dụ: 1 viên, 1 giọt..."><i className='ml-1 far fa-question-circle'/></Tooltip>
                        <Form.Item name="donViSuDung" style={{ flex: 1 }} className='input-not-formitem'>
                            <Autocomplete
                                placeholder="Nhập đơn vị sử dụng"
                                allowClear
                                className="w-100"
                                dataSource={lstDVT}
                                handleSearch={loadDonViTinh.bind(this)}
                                getItem={(key, value) => { setDonViSuDung(value); form.setFieldsValue({ donViSuDung: key }) }} />
                        </Form.Item>
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Quy cách</label>
                        <Form.Item className='input-not-formitem'>
                            <Input placeholder="Ví dụ: 1 lọ/60 viên"
                                maxLength={20}
                                value={quycach}
                                onChange={(e) => setQuycach(e.target.value)} />
                        </Form.Item>
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Dạng thuốc</label>
                        <Form.Item name="madang" className='input-not-formitem'
                            rules={[{ required: isMedicine(loai), message: "Vui lòng chọn dạng thuốc" }]}
                        >
                            <Select className="auto"
                                disabled={!isMedicine(loai)}
                                placeholder="Chọn dạng thuốc"
                                onChange={(e) => setMadang(e)}
                            >
                                {lstDangThuoc.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.ma}>{row.ten}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Bảo quản</label>
                        <Form.Item className='input-not-formitem'>
                            <Select className="auto"
                                value={baoquan}
                                showSearch
                                onChange={(e) => setbaoquan(e)}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {lstBaoQuan.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.ten}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    
                    {/* <div className="col-md-6">
                        <Form.Item label="Giá BHYT:" name="gia_bh">
                            <InputNumber
                                disabled={!isMedicine(loai)}
                                onKeyDown={(e) => {
                                    if (e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                defaultValue={0}
                                style={{ width: '100%' }}
                                min={0}
                                maxLength={10}
                                value={gia_bh}
                                formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(e) => setGia_bh(e)}>
                            </InputNumber>
                        </Form.Item>
                    </div> */}
                </div>
                <div className='custom-hr'></div>
                <div style={{ display: 'flex', margin: '10px 0 10px 0', justifyContent: 'center', marginTop: 20, alignItems: 'center' }}>
                    <button className="btn btn-color"

                        onClick={() => { update() }} >
                        <i className="fa fa-save" style={{ marginRight: 5 }} /> Lưu
                    </button>
                    <button className="btn btn-danger" style={{ marginLeft: 5 }}
                        onClick={() => { props.onClose() }}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                </div>
            </Form>
        </React.Fragment>
    )
}