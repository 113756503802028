export let FeatureModel = {
    id: '',
    code: '',
    isActive: '',
}

export let FeatureDeactive = () => {
    let t = sessionStorage.getItem("ListFeature") ?? '[]';
    let listFeature = JSON.parse(t);
    return listFeature.map(x=>{
        if (!x.isActive) return x.id;
    })
}

export let FeatureCodeDeactive = () => {
    let t = sessionStorage.getItem("ListFeature") ?? '[]';
    let listFeature = JSON.parse(t);
    return listFeature.map(x=>{
        if (!x.isActive) return x.code;
    })
}

export let Set = (data) => {
    sessionStorage.setItem("ListFeature", JSON.stringify(data));
}
//Phân hệ Lịch đăng ký CTV
export let IsActive_FeatureLCTV = () => {
    let t = sessionStorage.getItem("ListFeature") ?? '[]';
    let listFeature = JSON.parse(t);
    return listFeature.findIndex(x => x.code == 'Feature_LCTV' && x.isActive) >= 0;
}

//Phân hệ Lịch hẹn (đăng ký khám, hẹn tái khám, hẹn lấy mẫu tại nhà)
export let IsActive_FeatureLH = () => {
    let t = sessionStorage.getItem("ListFeature") ?? '[]';
    let listFeature = JSON.parse(t);
    return listFeature.findIndex(x => x.code == 'Feature_LH' && x.isActive) >= 0;
}

