import * as ApiCaller from '../../libs/httpRequests';
const prefix = 'Alias';

export function getAliasList(keyword, output, page = 1, size = 10, group, getAll = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetAliasList?keyword=${keyword}&output=${output}&page=${page}&size=${size}&group=${group}&getAll=${getAll}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function createAlias(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateAlias`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateAlias(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateAlias`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteAlias(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteAlias?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};