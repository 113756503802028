import * as ApiCaller from '../libs/httpRequests';


const prefix = 'config';

export function DanhSachKho(key)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachkho?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListEquipOfService(idService)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListEquipOfService?idService=${idService}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListEquipReceipt(chinhanh)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListEquipReceipt?chinhanh=${chinhanh}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListServiceTypeConfig()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListServiceTypeConfig`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListEquipmentTypeConfig()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListEquipmentTypeConfig`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListServiceConfig(typeId,key)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListServiceConfig?typeId=${typeId}&key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListEquipmentConfig(typeId,key)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListEquipmentConfig?typeId=${typeId}&key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function danhsachkhoconfig(key)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachkhoconfig`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function InsertEquipOfService(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/InsertEquipOfService`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function InsertEquipReceipt(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/InsertEquipReceipt`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateEquipOfService(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateEquipOfService`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DeleteEquipOfService(id,mavp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteEquipOfService?id=${id}&mavp=${mavp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateEquipReceipt(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateEquipReceipt`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DeleteEquipReceipt(chinhanh,mavp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteEquipReceipt?chinhanh=${chinhanh}&mavp=${mavp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetSuatAn(key, page=1, size=10) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachSuatAn?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CreateSuatAn(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/TaoMoiSuatAn`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateSuatAn(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPut(`${prefix}/CapNhatSuatAn`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function UpdateHideSuatAn(ma, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/AnHienSuatAn?ma=${ma}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteSuatAn(idSuatAn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/XoaSuatAn?id=${idSuatAn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getLoaiPhong(key, page=1, size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachLoaiPhong?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getSelectBoxLoaiPhongByKhoa(makp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachLoaiPhongByKhoa?&makp=${makp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function createLoaiPhong(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/TaoMoiLoaiPhong`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateLoaiPhong(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPut(`${prefix}/CapNhatLoaiPhong`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteLoaiPhong(idLoaiPhong) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/XoaLoaiPhong?id=${idLoaiPhong}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getPhong(key, khoa, loaiphong, page=1, size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachPhong?key=${key}&khoa=${khoa}&loaiphong=${loaiphong}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function createPhong(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/TaoMoiPhong`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deletePhong(idphong) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/XoaPhong?id=${idphong}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updatePhong(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPut(`${prefix}/CapNhatPhong`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateHidePhong(idphong, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/AnHienPhong?ma=${idphong}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getSelectBoxPhong() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/SelectBoxPhong`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getSelectBoxPhongByLoaiPhong(makp, idlp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/SelectBoxPhongByLoaiPhong?&makp=${makp}&id=${idlp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getSelectBoxLoaiPhong() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/SelectBoxLoaiPhong`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getGiuong(key, khoa, loaiphong, phong, page=1, size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachGiuong?key=${key}&khoa=${khoa}&loaiphong=${loaiphong}&phong=${phong}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function createGiuong(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/TaoMoiGiuong`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateHideGiuong(idgiuong, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/AnHienGiuong?ma=${idgiuong}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateGiuong(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPut(`${prefix}/CapNhatGiuong`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteGiuong(idgiuong) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/XoaGiuong?id=${idgiuong}`).then(respone =>{
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getDanhMucMaDichVuKyThuatTuongDuong(key, radiocheck, page=1, size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachMaDichVuKyThuatTuongDuong?key=${key}&status=${radiocheck}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function createDanhMuc(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/TaoMoiDanhMuc`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateDanhMuc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/CapNhatDanhMuc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteDanhMuc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/XoaDanhMuc?id=${id}`).then(respone =>{
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportLoaiDichVu(status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportLoaiDichVuBHYT?&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getSelectBoxDichVuByNhom(nhom) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachDichVuByNhom?nhom=${nhom}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function TemplateLoaiDichVu() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/TemplateLoaiDichVuBHYT`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function importDanhSach(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/importDanhSach`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getDanhSachkhoaBHYT(key, radiocheck, page=1, size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachKhoaBHYT?key=${key}&status=${radiocheck}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function createKhoaBHYT(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/TaoMoiKhoaBHYT`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteKhoaBHYT(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/XoaKhoaBHYT?id=${id}`).then(respone =>{
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateKhoaBHYT(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/CapNhatKhoaBHYT`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err);
        })
    });
};
export function importKhoaBHYT(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/importKhoaBHYT`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function exportDanhSachKhoaBHYT(status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportKhoaBHYT?&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function templateKhoaBHYT() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/templateKhoaBHYT`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreateSite(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateSite`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateSite(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateSite`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteSite(id) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteSite?id=${id}`).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateSiteDefault(id) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateSiteDefault/${id}`).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListSite(islogin=true) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListSite?islogin=${islogin}`).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListSitByUserID(userID) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListSiteByUserID?userID=${userID}`).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CreateHub(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateHub`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateHub(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateHub`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteHub(id) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteHub?id=${id}`).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListHub(siteid) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListHub?siteid=${siteid}`).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListHubByMutilpleSize(sites) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListHubByMutilpleSize?sites=${sites}`).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function BangGiaDefaultSite(chinhanh) {
    if (chinhanh === null || chinhanh === undefined || chinhanh === "")
        chinhanh = 0;
    return new Promise((resolve,reject) => {
        return ApiCaller.httpGet(`${prefix}/BangGiaDefaultSite?chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function changePass(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/changepass`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function getListActiveServiceGroups() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListActiveServiceGroups`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function UpdateEmailConfig(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateEmailConfig`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function GetEmailConfigBySiteId(siteid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetEmailConfigBySiteId/${siteid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function GetKenhKhachHang(page, size,getAll) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetKenhKhachHang?pageNumber=${page}&pageSize=${size}&getAll=${getAll}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function CreateKenhKhachHang(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateKenhKhachHang`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function UpdateKenhKhachHang(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateKenhKhachHang`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function DeleteKenhKhachHang(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DelKenhKhachHang?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function HideKenhKhachHang(id, hide) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/HideKenhKhachHang?id=${id}&hide=${hide}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function GetListSubMenu(isFull, key='',page=1,size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListSubMenu?key=${key}&page=${page}&size=${size}&isFull=${isFull}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function loadSysConfig() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LoadSystemConfiguration`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getHTTKQ(key, page, size, getAll) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetHTTKQ?key=${key}&page=${page}&size=${size}&all=${getAll}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}