import { Collapse, DatePicker, Form, notification, Select, Table, Pagination } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as KhoService from '../../../../services/KhoService';
import SimpleReactValidator from 'simple-react-validator';
import * as SystemConfig from "../../../../configure/systemConfig";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;
export default class ThongKeNhapKho extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            loai: '',
            tenloai: 'Tất cả',
            makho: 0,
            lstKho: [],
            lstExpandable: [],
            page: 1,
            size: 10,
            totalRecords: 0,
        };
    }
    componentWillMount() {
        this.loadKho();
        this.LoadThongKeNhapKho();
    }
    loadKho() {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstKho: result.data,
                })
            }

        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi!' })
        })
    }
    LoadThongKeNhapKho() {
        Actions.setLoading(true);
        StatisticService.ThongKeNhapKho(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),
            this.state.makho, this.state.page, this.state.size).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        dataSource: result.data,
                        totalRecords: result.totalRecords,
                        lstExpandable: []
                    });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
    }
    ExportThongKeNhapKho(isPhieu) {
        Actions.setLoading(true);
        StatisticService.ExportNhapkho(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),
            this.state.makho, isPhieu)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeNhapKho";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    ThongKeNhapKhoChiTiet(row, page = 1, size = 10) {
        Actions.setLoading(true);
        StatisticService.ThongKeNhapKhoChiTiet(row.idPhieu, page, size, false).then(result => {
            if (result.isSuccess) {
                let data = this.state.dataSource;
                let index = data.findIndex(x => x.idPhieu == row.idPhieu);
                if (index >= 0) {
                    data[index]['lstData'] = result.data;
                    data[index].currentPage = page
                    data[index].currentSize = size
                    data[index].totalRecords = result.totalRecords || 0
                    this.setState({
                        dataSource: data.map(x => ({ ...x })),
                    });
                }
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    render() {
        const lstEP = this.state.lstExpandable;
        let columnChild = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                // render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
                // render: (text, record, index) => Number(index) + 1,
                //render: (text, record, index) => ((dataSource.indexOf(record) + 1) + ((this.state.currentPage - 1) * 10)),
                render: (text, record, index) => {
                    return (((record.page || 1) - 1) * (record.size || 10) + Number(index) + 1)
                },
                align: 'center',
                width: 80,
            },
            {
                title: 'Mã thuốc/vật tư',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
                width: 100,
            },
            {
                title: 'Tên thuốc/vật tư',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                align: 'left',
                width: 200,
            },
            {
                title: 'Hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                align: 'left',
                width: 120,
            },
            {
                title: 'Đơn vị',
                dataIndex: 'donvitinh',
                key: 'donvitinh',
                align: 'left',
                width: 100,
            },
            {
                title: 'Quy cách',
                dataIndex: 'quycach',
                key: 'quycach',
                align: 'left',
                width: 100,
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                align: 'center',
                width: 120,
            },
            {
                title: 'Lô',
                dataIndex: 'losx',
                key: 'losx',
                align: 'center',
                width: 120,
            },
            {
                title: 'Ngày sản xuất',
                dataIndex: 'ngaysx',
                key: 'ngaysx',
                align: 'left',
                width: 120,
                render: (value) => {
                    return <span>{moment(value).year() == 1 ? "" : moment(value).format(SystemConfig.FORMAT_DATE)}</span>;
                },
            },
            {
                title: 'Ngày hết hạn',
                dataIndex: 'ngayhh',
                key: 'ngayhh',
                align: 'left',
                width: 120,
                render: (value) => {
                    return <span>{moment(value).year() == 1 ? "" : moment(value).format(SystemConfig.FORMAT_DATE)}</span>;
                },
            },
            {
                title: 'SL nhập',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            // {
            //     title: 'Thành tiền',
            //     dataIndex: 'tongtien',
            //     key: 'tongtien',
            //     render: (text, record, index) => {
            //         return <span>{text.toLocaleString('en-US')}</span>;
            //     },
            // }
        ]
        let columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                render: (text, record, index) => ((this.state.page - 1) * this.state.size + Number(index) + 1),
                // render: (text, record, index) => Number(index) + 1,
                // render: (text, record, index) => (this.state.dataSource.indexOf(record) + 1),
                align: 'center',
                width: 80,
            },
            {
                title: 'Ngày nhập',
                dataIndex: 'ngay',
                key: 'ngay',
                align: 'left',
                width: 120,
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Số phiếu',
                dataIndex: 'sophieu',
                key: 'sophieu',
                align: 'left',
                width: 120,
            },
            {
                title: 'Thao tác',
                align: 'center',
                width: 110,
                render: (row, value, index) => {
                    // return <div><i className='fas fa-ellipsis-h'></i></div>
                    return <div className="dropdown dropdown-inline-table" style={{ cursor: 'pointer' }}>
                        <div className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className='fas fa-ellipsis-h'></i>
                        </div>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {lstEP.findIndex(x => x == index) < 0 ?
                                <a className="dropdown-item" href="#" onClick={e => {
                                    let lst = this.state.lstExpandable;
                                    lst.push(index)
                                    this.setState({ lstExpandable: JSON.parse(JSON.stringify(lst)) })
                                    this.ThongKeNhapKhoChiTiet(row, 1, 10)
                                }}>Xem chi tiết</a>
                                :
                                <a className="dropdown-item" href="#" onClick={e => {
                                    let lst = this.state.lstExpandable;
                                    lst = lst.filter(x => x != index);
                                    this.setState({ lstExpandable: JSON.parse(JSON.stringify(lst)) })
                                }}>Đóng</a>
                            }
                        </div>
                    </div>
                }
            },
            {
                title: 'Kho nhập',
                dataIndex: 'tenkho',
                key: 'tenkho',
                align: 'left',
                width: 200,
            },
            {
                title: 'Người nhập',
                dataIndex: 'nguoinhap',
                key: 'nguoinhap',
                align: 'left',
                width: 250,
            },
            {
                title: 'Lý do nhập',
                dataIndex: 'lydo',
                key: 'lydo',
                align: 'left',
            },
            // {
            //     title: 'Chi tiết',
            //     dataIndex: 'lstData',
            //     key: 'lstData',
            //     align: 'center',
            //     width: 1000,
            //     render: (row) => {

            //         return (
            //             <Collapse className="remove-space-statistic">
            //                 <Panel header="Xem chi tiết">
            //                     <Table
            //                         style={{ margin: '0 !important' }}
            //                         scroll={{ x: 'max-content' }}
            //                         pagination={false}
            //                         // pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
            //                         dataSource={row} columns={columnChild} rowKey={(row, index) => index} />
            //                 </Panel>
            //             </Collapse>
            //         );
            //     },
            // },
            // {
            //     title: 'Tổng tiền',
            //     dataIndex: 'tongtien',
            //     key: 'tongtien',
            //     render: (text, record, index) => {
            //         return <span>{text.toLocaleString('en-US')}</span>;
            //     },
            // }
        ]
        return (
            <>
                <Form ref={(c) => this.form = c} >
                    <div className='containerChilderWrapper'>
                        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                        <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê nhập kho</div>
                        <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="col-md-3 mt-2">
                                <Form.Item label="Từ ngày:">
                                    <RangePicker allowClear={false} disabledDate={this.disabledDate}
                                        format="DD-MM-YYYY" value={this.state.ngay}
                                        locale={viVN} onChange={(value => this.setState({ ngay: value, page: 1 }, () => this.LoadThongKeNhapKho()))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-3 mt-2">
                                <Form.Item label="Kho nhập:">
                                    <Select
                                        value={this.state.makho}
                                        onChange={(e) => { this.setState({ makho: e, page: 1 }, () => this.LoadThongKeNhapKho()) }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key={null} value={0}>TẤT CẢ</Option>
                                        {this.state.lstKho.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-5" style={{ display: 'flex' }}>
                                <Form.Item>
                                    <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => {
                                        this.setState({ page: 1 }, () => {
                                            this.LoadThongKeNhapKho()
                                        })
                                    }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                </Form.Item>
                                <Form.Item>
                                    <div className="dropdown dropdown-inline-table">
                                        <button className="dropdown-toggle btn btn-color" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a className="dropdown-item" href="#" onClick={e => {
                                                this.ExportThongKeNhapKho(false)
                                            }}>Xuất theo thuốc-vật tư</a>
                                            <a className="dropdown-item" href="#" onClick={e => {
                                                this.ExportThongKeNhapKho(true)
                                            }}>Xuất theo phiếu</a>
                                        </div>
                                    </div>
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 110px)' }} className="table-full-height">
                            <Table
                                scroll={{ x: 'max-content', y: 0 }}
                                pagination={{
                                    pageSize: this.state.size,
                                    size: this.state.size,
                                    position: ['bottomCenter'],
                                    showSizeChanger: true,
                                    style: { textAlignLast: "center" },
                                    total: this.state.totalRecords,
                                    showTotal: (total) => (`Tổng: ${total}`),
                                    onChange: (page, size) => {
                                        this.setState({ page: page, size: size }, () => {
                                            this.LoadThongKeNhapKho()
                                        })
                                    },
                                    onShowSizeChange: (page, size) => {
                                        this.setState({ page: 1, size: size }, () => {
                                            this.LoadThongKeNhapKho()
                                        })
                                    }
                                }}
                                dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <div style={{ padding: 10 }}>
                                            <Table
                                                style={{ margin: '0 !important' }}
                                                scroll={{ x: 'max-content' }}
                                                pagination={{
                                                    total: record.totalRecords,
                                                    current: record.currentPage || 1,
                                                    size: record.currentSize || 10,
                                                    showTotal: total => `Tổng: ${total}`,
                                                    showSizeChanger: true,
                                                    onChange: (page, size) => {
                                                        this.ThongKeNhapKhoChiTiet(record, page, size)
                                                    },
                                                    onShowSizeChange: (page, size) => this.ThongKeNhapKhoChiTiet(record, 1, size)
                                                }}
                                                dataSource={record.lstData} columns={columnChild} rowKey={(row, index) => index}

                                            />
                                        </div>
                                    ),
                                    showExpandColumn: false,
                                    expandedRowKeys: this.state.lstExpandable,
                                    expandRowByClick: true
                                }}
                            />
                        </div>
                        {/* <Pagination
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            size={this.state.currentSize}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({ currentPage: page, currentSize: size }, () => {
                                    // this.LoadThongKeDoanhSo()
                                })
                            }}
                            onShowSizeChange={(page, size) => this.setState({ currentPage: 1, currentSize: size }, () => {
                                // this.LoadThongKeDoanhSo()
                            })}
                            showSizeChanger={true}
                        /> */}
                    </div>
                </Form>
            </ >
        )
    }
}
