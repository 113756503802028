import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Menu';

export function getMenu() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/get-menu`, ).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

//#region SuperAdmin
export function getAllWithLevelDivision() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetAllWithLevelDivision`, ).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/get/`+id).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function create(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/create`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function update(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/update/`+data.id, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function importByJson(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/ImportByJson`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportJson() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportJson`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportJsListComponents() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportFileImportComponent`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
//#endregion
