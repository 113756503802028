import { Button, DatePicker, Input, notification, Pagination, Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import * as Actions from '../../../libs/actions';
import * as ConfigService from '../../../services/configService';
import * as Statistic from '../../../services/statisticService';

const { RangePicker } = DatePicker;
export default (props) => {
    const _hiddenLink = useRef()
    const [date, setDate] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [filter, setFilter] = useState('');
    const [siteId,] = useState(props.siteid);
    const [lstMarketing, setLstMarketing] = useState([]);
    const [key, setKey] = useState('')

    useEffect(() => {
        setPage(1);
    }, [date, filter])

    useEffect(() => {
        GetThongKeDeNghiKetLuan();
    }, [page, size])

    useEffect(() => {
        GetKenhKhachHang()
    }, [])

    const GetThongKeDeNghiKetLuan = () => {
        Actions.setLoading(true);
        Statistic.ThongKeDeNghiKetLuan(date[0].format("yyyy/MM/DD"), date[1].format("yyyy/MM/DD"), page, size, siteId, key).then(res => {
            if (res.isSuccess) {
                setDataSource(res.data);
                setTotalRecord(res.totalRecords);
                Actions.setLoading(false);
            }
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    const ExportThongKeDeNghiKetLuan = () => {
        Actions.setLoading(true);
        Statistic.ExportThongKeDeNghiKetLuan(date[0].format("yyyy/MM/DD"), date[1].format("yyyy/MM/DD"), siteId, key).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = "ThongKeDeNghiKetLuan";
                _hiddenLink.current.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    const GetKenhKhachHang = () => {
        Actions.setLoading(true);
        ConfigService.GetKenhKhachHang(1, 100, false).then(result => {
            if (result.isSuccess) {
                setLstMarketing(result.data)
            } else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
            width: 50
        },
        {
            title: "Ngày",
            dataIndex: "ngay",
            key: "ngay",
            align: "left",
            width: 80,
        },
        {
            title: "Mã bệnh nhân",
            dataIndex: "mabn",
            key: "mabn",
            align: "center",
            width: 100,
        },
        {
            title: "Tên bệnh nhân",
            dataIndex: "hoten",
            key: "hoten",
            align: "left",
            width: 150,
        },
        {
            title: "Giới tính",
            dataIndex: "gioitinh",
            key: "gioitinh",
            align: "center",
            width: 90,
        },
        {
            title: "Ngày sinh",
            dataIndex: "ngaysinh",
            key: "ngaysinh",
            align: "center",
            width: 90,
        },
        {
            title: "Đề nghị",
            dataIndex: "denghi",
            key: "denghi",
            width: 150,
        },
        {
            title: "Kết Luận",
            dataIndex: "ketluan",
            key: "ketluan",
            width: 150,
        },
        {
            title: "Nguồn khách hàng",
            dataIndex: "nguonkhachhang",
            key: "nguonkhachhang",
            align: "left",
            width: 200,
        },
    ];

    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
            <div className='containerChilderWrapper'>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê đề nghị - kết luận</div>
                <div className="d-flex justify-content-center my-1">
                    <div style={{ width: 350 }} className="mr-2">
                        <Input value={key} placeholder="Nhập tên hoặc mã bệnh nhân"
                            onChange={(e) => setKey(e.target.value)}
                        />
                    </div>
                    <div className="mr-2">
                        <RangePicker allowClear={false} format="DD-MM-YYYY" value={date} locale={viVN}
                            onChange={(value => setDate(value))}
                        />
                    </div>
                    <div className="d-flex">
                        <Button className="btn btn-color"
                            onClick={() => {
                                if (page == 1)
                                    GetThongKeDeNghiKetLuan()
                                else
                                    setPage(1)
                            }}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                        <Button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { ExportThongKeDeNghiKetLuan() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                    </div>
                </div>
                <div style={{ width: "100%", height: 'calc(100% - 120px)', marginTop: 20 }}>
                    <Table
                        scroll={{ y: 0, x: 'max-content' }}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        rowKey={(row, index) => index}
                    />
                </div>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                    total={totalRecord}
                    showTotal={total => `Tổng: ${total}`}
                    size={size}
                    current={page}
                    onChange={(page, size) => {
                        setPage(page);
                        setSize(size);
                    }}
                    onShowSizeChange={(page, size) => {
                        setPage(1);
                        setSize(size);
                    }}
                    showSizeChanger={true}
                />
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};
