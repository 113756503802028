import * as ApiCaller from '../libs/httpRequests';
const prefix = 'MedicalExamination';

export function SaveTienSu(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/tiensukb`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function GetTienSu(mabn)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/searchtskb?mabn=${mabn}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function CreateUpdateMedicalHistoryAsync(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateUpdateMedicalHistoryAsync`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function GetMedicalHistoryAsync(patientId)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetMedicalHistoryAsync/${patientId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

