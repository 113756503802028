import React, { Fragment, useEffect, useRef, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import moment from "moment";
import { Input, Switch, Radio, Button, Select, InputNumber, DatePicker, notification } from 'antd';
import DateTimePicker from 'react-datetime-picker';
import * as SystemConfig from '../../configure/systemConfig';
import * as Actions from '../../libs/actions';
import * as PromotionService from '../../services/PromotionServices';
import * as PromotionVoucherService from '../../services/PromotionVoucherServices';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import VoucherList from './voucher/voucher-list';
import Notify, { NotifyStatus } from '../../components/notify';
import viVN from 'antd/es/date-picker/locale/vi_VN';


const { Option } = Select;
const { TextArea } = Input;
export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        loadData: (id) => {
            loadData(id);
      }
    }));
    //#region khai báo ref
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const notiRef = useRef(null);
    const voucherListRef = useRef();
    //#endregion
    //#region khai báo state
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
    //model Promotion
    const [promotionId, setPromotionId] = useState(props.promotionId);
    const [promotionName, setPromotionName] = useState('');
    const [promotionType, setPromotionType] = useState(props.listPromotionType ? props.listPromotionType[0].key : null);
    const [discountValue, setDiscountValue] = useState(0);
    const [isPercent, setIsPercent] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [isDraft, setIsDraft] = useState(true);
    const [beginningDate, setBeginningDate] = useState(moment(new Date()));
    const [endingDate, setEndingDate] = useState(null);
    const [remark, setRemark] = useState('');
    //
    
    // open={openConfirmDeleteListVoucher}
    // onConfirm={onConfirmDeleteListVoucherHandle}
    // // okButtonProps={{ loading: confirmLoading }}
    // onCancel={onCancelDeleteListVoucherHandle}
    const [listVoucher, setListVoucher] = useState([]);
    //#endregion

    const model = {
        id: null,
        promotionName: '',
        promotionType: null,
        discountValue: 0,
        isActive: true,
        isPercent: false,
        beginningDate: null,
        endingDate: null,
        remark: null,
        isDraft: true,
    };
    //#region useEffect
    useEffect(() => {
    }, []);

    useEffect(() => {
        setDetailScreenMode(props.detailScreenMode);
    }, [props.detailScreenMode]);

    useEffect(() => {
    }, [props.promotionId]);
    //#endregion 

    const reset = () => {
        setPromotionId(null);
        setPromotionName('');
        setPromotionType(props.listPromotionType ? props.listPromotionType[0].key : null);
        setDiscountValue(0);
        setIsPercent(false);
        setIsActive(true);
        setBeginningDate(null);
        setEndingDate(null);
        setRemark('');
        notiRef && notiRef.current.Close();
        validator && validator.current.hideMessages();

        voucherListRef.current.reset();
        forceUpdate();
    }

    const setDataUpdate = (data) => {
        //model state
        setPromotionId(data.id);
        setPromotionName(data.promotionName);
        setPromotionType(props.listPromotionType ? data.promotionType : null);
        setDiscountValue(data.discountValue);
        setIsPercent(data.isPercent);
        setIsActive(data.isActive);
        setBeginningDate(data.beginningDate ? moment(data.beginningDate) : null);
        setEndingDate(data.endingDate ? moment(data.endingDate) : null);
        setRemark(data.remark);
        //other state
        voucherListRef.current.loadData();
        forceUpdate();
    }

    const loadData = (promotionId) => {
        Actions.setLoading(true);
        PromotionService.getById(promotionId).then(result => {
          if (result.isSuccess) {
            setDataUpdate(result.data);
          }
          else {
            notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
          }
        }).catch(err => {
            Actions.setLoading(true);
          if (err.error && err.error.messageText)
            notiRef.current.Show(err.error.messageText, NotifyStatus.Warning)
          else
            notiRef.current.Show(err, NotifyStatus.Warning);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    //#endregion
    const setModel = () => {
        model.id= promotionId;
        model.promotionName = promotionName;
        model.promotionType = promotionType;
        model.discountValue = discountValue;
        model.isActive = isActive;
        model.isDraft = isDraft;
        model.isPercent = isPercent;
        model.beginningDate = beginningDate;
        model.endingDate = endingDate;
        model.remark = remark;
    }

    const submitCreate = (isClose = true) => {
        Actions.setLoading(true);
        PromotionService.create(model).then(result => {
          if (result.isSuccess) {
            setPromotionId(result.data.id);
            if (isClose) {
                onCancel();
                notification.success({ message: "Thành công" });
            }
            return true;
          }
          else {
            notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
            return false;
          }
        }).catch(err => {
            Actions.setLoading(true);
          if (err.error && err.error.messageText)
            notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
          else notiRef.current.Show(err, NotifyStatus.Warning)
          return false;
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const submitEdit = () => {
        model.isDraft = false;
        Actions.setLoading(true);
        PromotionService.edit(model).then(result => {
          if (result.isSuccess) {
            onCancel();
            notification.success({ message: "Thành công" });
          }
          else {
            notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
          }
        }).catch(err => {
            Actions.setLoading(true);
          if (err.error && err.error.messageText)
            notiRef.current.Show(err.error.messageText, NotifyStatus.Warning)
          else notiRef.current.Show(err, NotifyStatus.Warning)
        }).finally(() => {
            Actions.setLoading(false);
        })
    }
    
    const onCancel = (reload = true) => {
        reset();
        props.onCancel(reload);
    }
    //#region event các element (click button,...)
    const onChangePromotionTypeHandle = (e) => {
        setPromotionType(e);
        if (detailScreenMode == detailScreenModeEnum.create && listVoucher.length > 0 && promotionId) {
            // xóa list voucher
            Actions.setLoading(true);
            PromotionVoucherService.delete_by_promotionid(promotionId).then(result => {
            if (result.isSuccess) {
                voucherListRef.current.loadData();
            }
            else {
                notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
            }
            }).catch(err => {
                if (err.error && err.error.messageText)
                    notiRef.current.Show(err.error.messageText, NotifyStatus.Warning)
                else notiRef.current.Show(err, NotifyStatus.Warning)
            }).finally(() => {
                Actions.setLoading(false);
            })
        }
    }

    //event click close button
    const closeButtonHandle = () => {
        if (promotionId && detailScreenMode == detailScreenModeEnum.create && isDraft) {
            PromotionService.delete_promotion(promotionId).then(result => {
                if (result.isSuccess) {
                    setPromotionId(null);
                }
                else {
                  notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
                }
              }).catch(err => {
                if (err.error && err.error.messageText)
                  notiRef.current.Show(err.error.messageText, NotifyStatus.Warning)
                else notiRef.current.Show(err, NotifyStatus.Warning)
              })
        };
        onCancel(false);
    }
    //event click save button
    const saveButtonHandle = () => {
        setIsDraft(false);
        if (validator.current && validator.current.allValid()) {
            setModel();
            if (detailScreenMode == detailScreenModeEnum.update || promotionId) {
                submitEdit();
            }
            else if (detailScreenMode == detailScreenModeEnum.create) {
                model.isDraft = false;
                submitCreate();
            }
        } else {
            validator.current.showMessages();
            notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
            forceUpdate();
        }
    }
    //event create voucher
    const onCreateVoucherButtonClickHandle = () => {
        setModel();
        if (!promotionId) submitCreate(false);
    }
    return (
        <Fragment>
            <div className='h-100 d-flex flex-column justify-content-between' >
                <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>{ detailScreenMode == detailScreenModeEnum.create ? 'Tạo mới ' : 
                            (detailScreenMode == detailScreenModeEnum.update ? 'Cập nhật ' : 'Chi tiết ')
                        } 
                        - Chương trình khuyến mại
                    </h3>
                    <h3 hidden={ !props.isDialog }
                        onClick={onCancel}
                        style={{ cursor: 'pointer' }}
                    ><i className='far fa-times-circle' /></h3>
                </div>
                <div className='overflow-auto max-h-100 h-100 px-3 d-flex flex-column justify-content-start'>{/* body */}
                    <div className='d-flex flex-column justify-content-start'> {/* Info of Promotion */}
                        <Notify ref={notiRef} />
                        <div className="row">
                            <div className="col-md-4 mt-2">{/* Tên chương trỉnh */}
                                <div>
                                    <label className='required-field'>Tên chương trình </label>
                                </div>
                                <div>
                                    <Input value={promotionName} 
                                        readOnly={detailScreenMode == detailScreenModeEnum.detail}
                                        onChange={(e) => setPromotionName(e.target.value)}
                                        placeholder='Nhập tên chương trình khuyến mại'>
                                    </Input>
                                    {validator.current.message("Tên chương trình khuyến mại", promotionName, "required")}
                                </div>
                            </div>
                            <div className="col-md-4 mt-2">{/* Đối tượng KM */}
                                <label className='required-field'>Đối tượng khuyến mại </label>
                                <Select
                                    className='w-100'
                                    disabled={detailScreenMode != detailScreenModeEnum.create}
                                    value={ promotionType }
                                    onChange={(e) => {  onChangePromotionTypeHandle(e); }}
                                    filterOption={(input, option) => { }}
                                >
                                    {(props.listPromotionType ?? []).map((row, index) => {
                                        return (
                                            <Option key={index} value={row.key}>{row.text}</Option>
                                        )
                                    })}
                                </Select>
                                {validator.current.message("Đối tượng khuyến mại", promotionType, "selection")}
                            </div>
                            <div className="col-md-4 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Hoạt động */}
                                <label>Hoạt động </label>
                                <div>
                                    <Switch 
                                        disabled={detailScreenMode == detailScreenModeEnum.detail}
                                        checked={isActive} onChange={(checked, e) => { setIsActive(checked) }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Tỷ lệ khuyến mại */}
                                <div>
                                    <label className='required-field'>Tỷ lệ khuyến mại </label>
                                </div>
                                <div style={{ display: "flex", justifyContent: "start" }}>
                                    <div hidden={detailScreenMode == detailScreenModeEnum.detail}>
                                        <Radio.Group defaultValue="value" buttonStyle="solid" 
                                            
                                            value={isPercent ? 'percent' : 'value'}
                                            onChange={(e) => { setIsPercent(e.target.value == 'percent'); }}
                                        >
                                            <Radio.Button value="value" checked={!isPercent}>
                                                <i className="fa fa-dollar-sign" aria-hidden="true"></i></Radio.Button>
                                            <Radio.Button value="percent" checked={isPercent}>
                                                <i className="fa fa-percent" aria-hidden="true"></i></Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <InputNumber 
                                        readOnly={detailScreenMode == detailScreenModeEnum.detail}
                                        value = { discountValue }
                                        onChange = {(value) => { setDiscountValue(value); }}
                                        addonAfter = { isPercent ? '%':'VND' }
                                        step = { isPercent ? 1 : 10000 }
                                        min = {0}
                                        max = { isPercent ? 100: 1000000000 }
                                        className='text-right'
                                        style = {{ textAlign: "right" }}
                                    />
                                </div>
                                {validator.current && validator.current.message("Tỷ lệ khuyến mại", discountValue, 'required')}
                            </div>
                            <div className="col-md-4 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Ngày giờ bắt đầu */}
                                <div>
                                    <label className='required-field'>Ngày giờ bắt đầu </label>
                                </div>
                                <div hidden={detailScreenMode != detailScreenModeEnum.detail}>
                                    <Input readOnly={true}
                                        value={beginningDate ? moment(beginningDate).format(SystemConfig.FORMAT_TIME_DATE) : '' }
                                    />
                                </div>
                                <div hidden={detailScreenMode == detailScreenModeEnum.detail}>
                                    <DatePicker showTime  allowClear={false}
                                        locale={viVN}
                                        showNow={false}
                                        className='w-100'
                                        value={beginningDate}
                                        format = { SystemConfig.FORMAT_TIME_DATE }
                                        onChange={(e) => { setBeginningDate(e ? moment(e) : null);} }
                                    />
                                    {validator.current.message("Ngày giờ bắt đầu", beginningDate, "required")}
                                </div>
                            </div>
                            <div className="col-md-4 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Ngày giờ kết thúc */}
                                <label>Ngày giờ kết thúc </label>
                                <div hidden={detailScreenMode != detailScreenModeEnum.detail}>
                                    <Input readOnly={true}
                                        value={endingDate ? moment(endingDate).format(SystemConfig.FORMAT_TIME_DATE) : '' }
                                    />
                                </div>
                                <div hidden={detailScreenMode == detailScreenModeEnum.detail}>
                                    <DatePicker 
                                        showTime allowClear={true}
                                        readOnly={detailScreenMode == detailScreenModeEnum.detail}
                                        locale={viVN}
                                        showNow={false}
                                        className='w-100'
                                        value={endingDate}
                                        format = { SystemConfig.FORMAT_TIME_DATE }
                                        disabledDate = {(current) => {
                                            // Can not select days before today and today
                                            if (current && beginningDate) {
                                                return current < beginningDate.clone().endOf('day');
                                            }
                                            // return current && current < (beginningDate.clone() ?? moment()).endOf('day');
                                        }}
                                        onChange={(e) => { setEndingDate(e ? moment(e) : null);} }
                                        // onOk = {(value) => {
                                        //     setEndingDate(moment(value));
                                        // }}
                                    />
                                    {validator.current.message("Ngày giờ kết", endingDate, "expiredRange:" + beginningDate + ',' + SystemConfig.FORMAT_TIME_DATE.replace(',',''))}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Ghi chú */}
                                <label>Ghi chú</label>
                                <div>
                                    <TextArea className="w-100"
                                        readOnly={detailScreenMode == detailScreenModeEnum.detail}
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mt-2">{/* Chi tiết */}
                                <label className='border-bottom border-dark font-weight-bold'>Chi tiết chương trình</label>
                            </div>
                        </div>
                    </div>
                    <div className="max-h-100 h-100 d-flex flex-column justify-content-start">{/* Chi tiết */}
                        {/* <div className="col-md-12 mt-2 h-100 ">
                        </div> */}
                        <VoucherList ref={voucherListRef}
                            promotionId={promotionId}
                            promotionType={promotionType}
                            detailScreenMode={detailScreenMode}
                            setListVoucher={(e) => {setListVoucher(e);}}
                            onCreateVoucherButtonClickHandle = {() => onCreateVoucherButtonClickHandle()}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-1">{/* footer */}
                    <div>
                        {/* <Button>
                            <i className='fa fa-copy'></i>
                            Sao chép
                        </Button> */}
                    </div>
                    <div className='d-flex justify-content-end' >
                        <button 
                            className='btn btn-color mr-2' 
                            hidden={detailScreenMode == detailScreenModeEnum.detail}
                            onClick={saveButtonHandle}
                        >
                            <i className='fa fa-save mr-2'></i>
                            Lưu
                        </button>
                        <button
                            className='btn btn-danger'
                            onClick={closeButtonHandle}
                        >
                            <i className='fa fa-sign-out-alt mr-2'></i>
                            Đóng
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
})
// export default PromotionCreate;
