import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input, Table, notification, Pagination, Modal, Popconfirm, Tooltip, Select } from 'antd';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import * as CollaboratorsServices from "./nhomctvservices";
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
const { Option } = Select;
function NhomCTV() {
    const [totalGroups, setTotalGroups] = useState(0);
    const [currentPageGroup, setCurrentPageGroup] = useState(1);
    const [pageSizeGroup, setPageSizeGroup] = useState(10);
    const [keyGroup, setKeyGroup] = useState("");
    const [totalCollabs, setTotalCollabs] = useState(0);
    const [currentPageCollab, setCurrentPageCollab] = useState(1);
    const [pageSizeCollabs, setPageSizeCollab] = useState(10);
    const [keyCollab, setKeyCollab] = useState("");
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroupName, setSelectedGroupName] = useState("");
    const [totalGroupsCollabs, setTotalGroupsCollabs] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [listAdd, setListAdd] = useState([]);
    const [listDel, setListDel] = useState([]);
    const [listCollaboratorsGroup, setListCollaboratorsGroup] = useState([]);
    const [listCollaborators, setListCollaborators] = useState([]);
    const [listGroupsCollaborators, setListGroupsCollaborators] = useState([]);
    const [_listGroupsCollaborators, _setListGroupsCollaborators] = useState([]);
    const [show, setShow] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [newGroupName, setNewGroupName] = useState("");
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    
    const [isMultisite, setIsMultisite] = useState(false);
    const [branch, setBranch] = useState("");
    const [listBranch, setListBranch] = useState([]);

    const columnsGroup = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            render: (text, record, index) => ((currentPageGroup - 1) * pageSizeGroup + Number(index) + 1),
            align: 'center'
        },
        {
            title: 'Tên nhóm',
            dataIndex: 'label',
            key: 'groupname',
            width: 120,
            align: 'center'
        },
        {
            key: "action",
            align: "center",
            render: (row) => {
                return (
                    <div>
                        <Tooltip title="Cập nhật">
                            <button className="btn btn-warning" onClick={() => { setShow(true); setIsUpdate(true); setNewGroupName(row.label) }}><i className="fas fa-edit" /></button>
                        </Tooltip>
                        <Popconfirm title="Xóa tất cả cộng tác viên của nhóm?" okText="Đồng ý" cancelText="Hủy" onConfirm={() => { deleteGroup() }}>
                            <button className="btn btn-danger ml-2"><i className="fas fa-trash" /></button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]
    const columnsCollab = [
        {
            title: "STT",
            dataIndex: "index",
            key: 'index',
            render: (text, record, index) => ((currentPageCollab - 1) * pageSizeCollabs + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        {
            title: 'Tên đăng nhập',
            dataIndex: 'userid',
            key: 'userid',
            align: 'center',
            width: 150
        },
        {
            title: 'Tên',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: "50%"
        },
        {
            key: "action",
            render: (row) => {
                return (
                    listGroupsCollaborators.find(x => Number(x.id) === Number(row.id)) && <i className="fas fa-check" />
                )
            }
        }
    ]
    const columns = [
        {
            title: "STT",
            dataIndex: "index",
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        {
            title: 'Tên',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: "60%",
        },
        {
            key: "action",
            align: "right",
            render: (row) => {
                return (
                    <div>
                        <button className="btn btn-danger" onClick={() => deleteCollabsFromList(row.id)}><i className="fas fa-trash" /></button>
                    </div>
                )
            }
        }
    ]

    useEffect(() => {
        GetListCollaborators();
    }, [currentPageCollab, pageSizeCollabs, branch]);

    useEffect(() => {
        getListGroup()
    }, [currentPageGroup, pageSizeGroup]);

    const getListGroup = (key = keyGroup, page = currentPageGroup, size = pageSizeGroup) => {
        CollaboratorsServices.GetListGroup(key, page, size).then(res => {
            if (res.isSuccess) {
                setListCollaboratorsGroup(res.data);
                setTotalGroups(res.totalRecords);
            } else {
                notification.warning({ message: "Không thể lấy thông tin nhóm" })
            }
        }).catch(err => console.log(err))
    }
    const GetListCollaborators = (page = currentPageCollab, size = pageSizeCollabs, chinhanh = branch) => {
        CollaboratorsServices.GetListCollaborators(keyCollab, page, size, chinhanh).then(res => {
            if (res.isSuccess) {
                setListCollaborators(res.data);
                setTotalCollabs(res.totalRecords);
            }
        }).catch(() => { })
    }
    const deleteCollabsFromList = (id) => {
        const found = listGroupsCollaborators.find(collab => collab.id === id);
        if (found) {
            const result = listGroupsCollaborators.filter(collab => collab.id !== id);
            setListGroupsCollaborators(result);
            if (listAdd.find(collab => collab.id === id)) {
                setListAdd(listAdd.filter(collab => collab.id !== id));
            }
            if (listDel.find(collab => collab.id === id) === undefined && _listGroupsCollaborators.find(collab => collab.id === id)) {
                setListDel([...listDel, { id: found.id, name: found.name }]);
            }
        } else {
            notification.warning({ message: "Không thể xóa! Hãy thử lại sau" });
        }
    }
    const getListCollaboratorsOfGroup = (groupname = selectedGroupName, chinhanh = branch) => {
        CollaboratorsServices.GetListCollaboratorsOfGroup(groupname, chinhanh).then(res => {
            if (res.isSuccess) {
                setListGroupsCollaborators(res.data);
                _setListGroupsCollaborators(res.data);
            } else {
                notification.warning({ message: "Không thể lấy thông tin cộng tác viên của nhóm: " + selectedGroupName })
            }
        }).catch(err => console.log(err))
    }
    const saveResult = (groupname = selectedGroupName) => {
        const data = {
            listDel: listDel,
            listAdd: listAdd
        }
        CollaboratorsServices.updateListCollaboratorsOfGroup(groupname, data).then(res => {
            if (res.isSuccess) {
                getListCollaboratorsOfGroup(groupname);
                setListDel([]);
                setListAdd([]);
                notification.success({ message: "Lưu thành công" })
            } else {
                notification.warning({ message: "Không thể lưu, vui lòng thử lại sau" })
            }
        }).catch(err => console.log(err))
    }
    const deleteGroup = (groupname = selectedGroupName) => {
        CollaboratorsServices.deleteGroup(groupname).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Xóa thành công" })
                getListGroup();
                setSelectedGroupName("");
                listGroupsCollaborators([]);
                setListGroupsCollaborators([]);
                setListDel([]);
                setListAdd([]);
            } else {
                notification.warning({ message: "Không thể xóa, vui lòng thử lại sau" })
            }
        }).catch(err => console.log(err))
    }

    const updateGroup = () => {
        if (validator.current.allValid()) {
            if (isUpdate) {
                const data = {
                    groupName: selectedGroupName,
                    newGroupName: newGroupName
                }
                CollaboratorsServices.updateGroup(data).then(res => {
                    if (res.isSuccess) {
                        notification.success({ message: "Cập nhật thành công" })
                        getListGroup();
                        setShow(false);
                        setSelectedGroupName("");
                        listGroupsCollaborators([]);
                    } else {
                        notification.warning({ message: "Không thể cập nhật, vui lòng thử lại sau" })
                    }
                }).catch(err => console.log(err))
            } else {
                if (listCollaboratorsGroup.find(x => (x.label).toLowerCase() === newGroupName.toLowerCase())) {
                    notification.warning({ message: "Tên nhóm bị trùng" });
                } else {
                    setListCollaboratorsGroup([...listCollaboratorsGroup, { label: newGroupName }]);
                    setShow(false);
                    setSelectedGroupName(newGroupName);
                    setListGroupsCollaborators([]);
                }
            }
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    useEffect(() => {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item);
                if (result.item) GetListSite();
            }
        });
    }, [])
    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListBranch(result.data);
            } else {
                setListBranch([]);
            }
        }).catch(err => {
        })
    }
    return (
        <>

            <div className='containerChilderWrapper' style={{ display: 'flex' }}>

                <div className="warrperStyleGroup" style={{ width: '30%', marginRight: 20 , height:'100%', padding: "20px 5px !important" }}>
                    <div className="grouplabel">
                        <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Nhóm</h4>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                        <Input value={keyGroup} onChange={(e) => setKeyGroup(e.target.value)}
                            placeholder="Nhập tên nhóm để tìm kiếm" style={{ width: "50%", marginRight: 10 }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") getListGroup();
                            }} />
                        <button className="btn btn-color mr-2" onClick={() => { getListGroup() }}><i className="fas fa-search" /></button>
                        <button className="btn btn-color" onClick={() => { setShow(true); setIsUpdate(false); setNewGroupName("") }}> <i className="fas fa-plus" /></button>
                    </div>
                    <div className="col-md-12 mt-2" style={{ height: 'calc(100% - 80px)' }}>
                        <Table
                            rowKey={(row) => `collab-group-${row.label.replace(" ", "")}`}
                            scroll={{ x: 'max-content', y: 0 }}
                            dataSource={listCollaboratorsGroup}
                            columns={columnsGroup}
                            // pagination={false}
                            onRow={(row) => {
                                return {
                                    onClick: () => {
                                        if (row.label !== selectedGroupName) {
                                            if (listAdd.length > 0 && selectedGroupName !== "") {
                                                setListAdd([]);
                                                setSelectedGroupName(row.label);
                                                getListCollaboratorsOfGroup(row.label);
                                                notification.warning({ message: "Đã hủy bỏ những thay đổi chưa được lưu" });
                                            } else {
                                                setSelectedGroupName(row.label);
                                                getListCollaboratorsOfGroup(row.label);
                                            }
                                        }
                                    }
                                };
                            }}
                            rowClassName={(row) => {
                                return `rowCustomSelect ${selectedGroupName === row.label ? 'rowCustomSelectActive' : ''}`
                            }}
                            pagination={{ showTotal: total => `Tổng: ${total}` }}
                        />
                        {/* <Pagination
                                style={{ textAlignLast: 'center', marginTop: 10 }}
                                total={totalGroups}
                                showTotal={total => `Tổng: ${total}`}
                                current={currentPageGroup}
                                onChange={(page, size) => {
                                    setCurrentPageGroup(page);
                                    setPageSizeGroup(size);
                                }}
                                onShowSizeChange={(page, size) => {
                                    setCurrentPageGroup(1);
                                    setPageSizeGroup(size);
                                }}
                                showSizeChanger={true}
                            /> */}
                    </div>
                </div>


                <div className="warrperStyleGroup" style={{ width: selectedGroupName ? "39%" : '69%', marginRight: 20, height:'100%', padding: "20px 0px !important" }}>
                    <div className="grouplabel">
                        <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Cộng tác viên</h4>
                    </div>
                    {/* {isMultisite === true &&
                        <div className="col-md-12 d-flex justify-content-center align-items-center mb-2">
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                allowClear={false}
                                value={branch}
                                onChange={(e) => {
                                    setBranch(e);
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={-1} value={''}>Chi nhánh</Option>
                                {listBranch.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.ma}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    } */}
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                        <Input disabled={selectedGroupName === ""} value={keyCollab} onChange={(e) => setKeyCollab(e.target.value)}
                            placeholder="Nhập tên CTV sau đó bấm Enter để tìm kiếm" style={{ width: "70%", marginRight: 10 }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") GetListCollaborators();
                            }} />
                        <button disabled={selectedGroupName === ""} className="btn btn-color" onClick={() => GetListCollaborators()}>Tìm kiếm</button>
                    </div>
                    <div className="col-md-12 mt-2" style={{ height: isMultisite ? 'calc(100% - 108px)' : 'calc(100% - 80px)' }}>
                        <Table
                            rowKey={(row) => `collab-${row.id}`}
                            scroll={{ x: 'max-content', y: 0 }}
                            dataSource={listCollaborators}
                            columns={columnsCollab}
                            pagination={false}
                            onRow={(row) => {
                                return {
                                    onClick: () => {
                                        if (selectedGroupName !== "") {
                                            if (_listGroupsCollaborators.find(x => x.id === row.id)) {
                                                listGroupsCollaborators.find(x => x.id === row.id) === undefined &&
                                                    setListGroupsCollaborators([...listGroupsCollaborators, { id: row.id, name: row.name }]);
                                            } else {
                                                if (listGroupsCollaborators.find(x => x.id === row.id) === undefined) {
                                                    setListGroupsCollaborators([...listGroupsCollaborators, { id: row.id, name: row.name }]);
                                                    setListAdd([...listAdd, { id: row.id, name: row.name }]);
                                                }
                                            }
                                            listDel.find(x => x.id === row.id) &&
                                                setListDel(listDel.filter(x => x.id !== row.id));
                                        }
                                    }
                                };
                            }}
                        />
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={totalCollabs}
                            showTotal={total => `Tổng: ${total}`}
                            current={currentPageCollab}
                            onChange={(page, size) => {
                                setCurrentPageCollab(page);
                                setPageSizeCollab(size);
                            }}
                            onShowSizeChange={(page, size) => {
                                setCurrentPageCollab(1);
                                setPageSizeCollab(size);
                            }}
                            showSizeChanger={true}
                        />
                    </div>
                </div>
                {selectedGroupName !== "" &&
                    <div className="warrperStyleGroup" style={{ width: '29%', height:'100%', padding: "20px 0px !important" }}>
                        <div className="grouplabel">
                            <div className='text-center mt-2 font-bold'>Cộng tác viên của nhóm {selectedGroupName !== "" && `${selectedGroupName}`}</div>
                        </div>

                        <div className="col-md-12 d-flex flex-row-reverse align-items-center">
                            <button disabled={selectedGroupName === "" || listGroupsCollaborators.length === 0} className={`btn btn-${listAdd.length > 0 || listDel.length > 0 ? "warning" : "color"}`} onClick={() => { saveResult() }}>
                                <i className="fas fa-save" style={{ marginRight: 5 }} />{listAdd.length > 0 || listDel.length > 0 ? "Lưu thay đổi" : "Lưu"}</button>
                        </div>
                        <div className="col-md-12 mt-2" style={{ height: 'calc(100% - 80px)' }}>
                            <Table
                                rowKey={(row) => `groups-collab-${row.id}`}
                                scroll={{ x: 'max-content', y: 0 }}
                                dataSource={listGroupsCollaborators}
                                columns={columns}
                                pagination={{ showTotal: total => `Tổng: ${total}` }}
                            />
                            {/* <Pagination
                                style={{ textAlignLast: 'center', marginTop: 10 }}
                                total={totalGroupsCollabs}
                                showTotal={total => `Tổng: ${total}`}
                                current={currentPage}
                                onChange={(page, size) => {
                                    setCurrentPage(page);
                                    setPageSize(size);
                                }}
                                onShowSizeChange={(page, size) => {
                                    setCurrentPage(1);
                                    setPageSize(size);
                                }}
                                showSizeChanger={true}
                            /> */}
                        </div>
                    </div>
                }
            </div>

            <Modal visible={show} maskClosable={true}
                onCancel={() => setShow(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}>
                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <h3>{isUpdate ? "Cập nhật nhóm" : "Tạo nhóm"}</h3>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label>Tên nhóm</label>
                        <Input value={newGroupName}
                            autoFocus
                            onChange={(e) => setNewGroupName(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") updateGroup();
                            }} />
                        {validator.current.message("Tên nhóm", newGroupName, "required")}
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-4">
                    <button className="btn btn-success mr-2"
                        onClick={() => updateGroup()}><i className="fas fa-save" style={{ marginRight: 5 }} />{isUpdate ? "Cập nhật" : "Thêm"}</button>
                    <button className="btn btn-danger" onClick={() => { setShow(false); setIsUpdate(false); validator.current.hideMessages() }}><i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                </div>
            </Modal>
        </>
    )
}
export default NhomCTV;