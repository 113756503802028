import moment from 'moment';
import {Table , notification} from 'antd';
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import * as VPService from '../../../../configureHIS/update_vp/services/VPService';
import * as StatisticService from '../../../../services/statisticService';
import * as Actions from '../../../../libs/actions';
import { connect } from 'react-redux';


function ReportByBill(props, ref){
    const _hiddenLink = useRef();

    const [site, setSite] = useState([props.siteid])
    const [date, setDate] = useState(props.Ngay)
    const [paymentType, setPaymentType] = useState(props.paymentType);
    const [filter, setFilter] = useState(Number(props.filter));

    const [dataSource, setDataSource] = useState([]);
    const [listPaymentType, setListPaymentType] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [totalMoney , setTotalMoney] = useState(0);
    const [totalRefund , setTotalRefund] = useState(0);


    useEffect(() => {
        getPaymentMethod();
    },[])

    useEffect(() => {
        RevenueRefundByBill();
    },[date, filter, paymentType, date])

    useEffect(() => {
        setSite([props.siteid]);
        setDate(props.Ngay);
        setPaymentType(props.paymentType);
        setFilter(Number(props.filter));
    }, [props])

    const RevenueRefundByBill = () => {
        let filterModel = {
            //common: paging & sorting
            skipCount: 0,
            maxResultCount: 10,
            //riêng theo module
            dateFrom: date[0],
            dateTo: date[1],
            PaymentTypeIds: paymentType,
            siteIds : site,
            productType : filter,
            isGetAll : true
        }
        StatisticService.RevenueRefundByBill(filterModel).then(result => {
                if (result.isSuccess) {
                    let total = 0;
                    let totalRefund = 0;
                    let data = result.data.items;
                    data.filter(x => x.billType === 1).map(x => total += x.total);
                    data.filter(x => x.billType === 2).map(x => totalRefund += x.total);
                    setTotalMoney(total);
                    setTotalRefund(totalRefund);
                    setDataSource(data);
                    setTotalRecord(result.data.totalCount);
                }
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
    }

    const getPaymentMethod = () => {
        VPService.lstLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                setListPaymentType(result.data)
            }
        }).catch(err => {
        })
    }

    const ExportRevenueRefundByBill = () => {
        Actions.setLoading(true);
        let filterModel = {
            //common: paging & sorting
            skipCount: 0,
            maxResultCount: 10,
            //riêng theo module
            dateFrom: date[0],
            dateTo: date[1],
            PaymentTypeIds: paymentType,
            siteIds : site,
            productType : filter,
            isGetAll : true
        }
        StatisticService.ExportRevenueRefundByBill(filterModel)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeDoanhSoTheoHoaDon";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    const column = [
        {
            title: '#',
            width: 50,
            align: 'center',
            render: (text, record, index) =>  (
                <span>{index + 1}</span>
            )
        },
        {
            title: 'Họ và tên',
            width: 200,
            dataIndex: 'patientName',
            key: 'patientName',
            render: (text, record, index) => (
                <span>{text}</span>
            )
        },
        {
            title: 'Số biên lai',
            width: 100,
            align: 'center',
            dataIndex: 'billNumber',
            key: 'billNumber',
            render: (text, record, index) =>  (
                <span>{text}</span>
            )
        },
        {
            title: 'Loại',
            width: 100,
            dataIndex: 'billType',
            key: 'billType',
            render: (text, record, index) => (
                <span>{text === 1 ? "Thanh toán" : "Hoàn trả"}</span>
            )
        },
        ...(listPaymentType && listPaymentType.map(x => {
                return (
                    {
                        title: x.ten,
                        width: 100,
                        align: 'right',
                        render: (text, record, index) =>  {
                            let total = 0;
                            if(record.paymentType && x.ma.toString() === record.paymentType.toString()){
                                total = record.total
                            }
                            if(record.billType === 2) {total = 0 - total}
                            return (
                                <span>{total.toLocaleString('en-US')}</span>
                            )
                        }
                    }
                )
        })),
        {
            title: 'Số biên lai thanh toán',
            width: 100,
            dataIndex: 'paymentBillNumber',
            key: 'paymentBillNumber',
            render: (text, record, index) =>  (
                <span>{text}</span>
            )
        },
        {
            title: 'Nguồn khách hàng',
            width: 200,
            dataIndex: 'coporation',
            key: 'coporation',
            render: (text, record, index) =>  (
                <span>{text}</span>
            )
        },
        {
            title: 'Ngày giờ lập',
            width: 150,
            dataIndex: 'date',
            key: 'date',
            render: (text, record, index) =>  (
                <span>{moment(text).format("DD/MM/YYYY HH:mm")}</span>
            )
        },
        {
            title: 'Ghi chú',
            width: 150,
            dataIndex: 'remark',
            key: 'remark',
            render: (text, record, index) =>  (
                <span>{text}</span>
            )
        },
    ]

    return (
        <React.Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
            <div className='row'>
                <div className='col-6'>
                    <div className='row'>
                        <div className="col-auto">Tổng biên lai (bao gồm hoàn trả): <strong>{totalRecord}</strong></div>
                        <div className="col-auto">Tổng tiền: <strong>{totalMoney.toLocaleString('en-US')}</strong></div>
                        <div className="col-auto">Hoàn trả: <strong>{totalRefund.toLocaleString('en-US')}</strong></div>
                        {/* <div className="col-auto">Tổng thanh toán: <strong>{this.state.tongtien.toLocaleString('en-US')}</strong></div>  */}
                    </div>
                </div>
                <div className='col-6 mb-1'>
                    <div className='d-flex align-content-center justify-content-end'>
                        <button className='btn btn-color' style={{ marginRight: 10 }} onClick={() => { ExportRevenueRefundByBill() }}><i className="fas fa-download" style={{ marginRight: 5 }}></i> Xuất excel</button>
                        {/* <button className='btn btn-outline-secondary' onClick={() => { }}><i className="fa fa-print"></i> In</button> */}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(90%)' }}>
                <Table
                    pagination={false} sticky
                    scroll={{ x: 'max-content', y: 0 }}
                    dataSource={dataSource} columns={column} 
                    rowKey={(row, index) => index} />
            </div>
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ReportByBill);
