import * as ApiCaller from '../libs/httpRequests';

const prefix = 'nhapvien';

export function ThongTinBenhNhap(mabn,maql,mavv)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongtinnhapvien?mabn=${mabn}&maql=${maql}&mavv=${mavv}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThongTinBaoHiem(mabn, doituong, ngay)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongtinbaohiem?mabn=${mabn}&doituong=${doituong}&ngay=${ngay}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LuuNhapVien(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luunhapvien`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachNhapVien(dateFrom,dateTo)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachNhapVien?dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
