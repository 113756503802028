import { Form, Input, notification, Select } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as CommonService from '../../../services/commonService';
const { Option } = Select;
const initialState = {
    matt: '',
    maquan: '',
    tenquan: '',
}
export default class createdistrict extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstCity: [],
            newDistrict: true
        };
    }
    componentDidMount() {
        this.loadCity();
    }
    loadCity() {
        CommonService.DanhMucThanhThi().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstCity: result.data
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thành phố bị lỗi' })
        })
    }
    async create() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                matt: this.state.matt,
                //maquan: this.state.maquan,
                tenquan: this.state.tenquan,
                loai: 'quanhuyen',
            }
            CommonService.updateCity(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Lưu thành công!' });
                    if (this.props.onLoad)
                        this.props.onLoad();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }
    }
    resetState() {
        this.form.setFieldsValue({
            matt: null,
            tenquan: null,
            maquan: null
        });
        this.setState({
            matt: '',
            tenquan: '',
            maquan: ''
        })
    }
    
    checkNewDistrict(key="", page = 1, size = 1) {
        CommonService.DanhMucQuanHuyen(this.state.matt, key, page, size).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    newDistrict: false
                });
            } else {
                this.setState({
                    newDistrict: true
                });
            }
        }).catch(err => {
        })
    }

    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ padding: "5px 20px" }}>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: " 30px" }}>
                            <h3>Thêm dữ liệu quận/huyện</h3>
                        </div>
                        <div style={{ display: 'flex', marginBottom: 10, marginTop: 10 }}>
                            <Form.Item style={{ flex: 1 }} label="Thành phố:" rules={[{ required: true, message: "Yêu cầu chọn thành phố"}]}>
                                <Select className="auto"
                                    showSearch
                                    value={this.state.matt}
                                    onChange={(e) => { this.setState({ matt: e }); this.form.setFieldsValue({maquan: null}) }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="">Tất cả</Option>
                                    {this.state.lstCity.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.key}>{row.value}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            {/* <Form.Item style={{ flex: 1 }} label="Mã quận:" name="maquan"
                                rules={[{ required: true, message: 'Yêu cầu nhập mã quận!' }]}>
                                <Input
                                    disabled={this.state.matt == ''}
                                    maxLength={5}
                                    value={this.state.maquan}
                                    onChange={(e) => {
                                        this.setState({ maquan: e.target.value });
                                        this.checkNewDistrict(e.target.value);
                                    }}
                                    onBlur={() => {
                                        !this.state.newDistrict && this.state.maquan && notification.warning({message: "Mã quận/huyện đã tồn tại"});
                                    }}
                                />
                            </Form.Item> */}
                            <Form.Item style={{ flex: 1 }} label="Tên quận:" name="tenquan"
                                rules={[{ required: true, message: 'Yêu cầu nhập tên quận!' }]}>
                                <Input
                                    disabled={this.state.matt === ''}
                                    value={this.state.tenquan}
                                    onChange={(e) => this.setState({ tenquan: e.target.value })}
                                />
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', marginTop: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Form.Item>
                                <button className="btn btn-success" disabled={!this.state.newDistrict} onClick={() => this.create()}
                                ><i className="fa fa-save" style={{ marginRight: 5 }}/>Lưu</button>
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-warning" onClick={() => this.resetState()}> <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-danger" type="button"
                                    onClick={() => {
                                        this.props.onCancel()
                                    }}>
                                    <i style={{ marginRight: 5 }} className="fa fa-eraser" />Đóng</button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
