import React, { useState, useEffect, useRef } from 'react'
import { notification, Button, DatePicker, Select } from 'antd';
import * as Actions from '../../libs/actions';
import * as PatientService from '../../services/patientService';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from "../../configure/systemConfig";
import * as NhanVienService from '../../services/employeeService';
import HeaderModal from '../../configureHIS/common/headerModal'

const { Option } = Select;
function CreateNguoiGioiThieu(props) {
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [hoten, sethoten] = useState('');
    const [gioitinh, setGioitinh] = useState(0);
    const [ngaysinh, setNgaysinh] = useState(moment());
    const [checkValid, setcheckValid] = useState(false);
    const [dienthoai, setDienthoai] = useState('');
    const [cccd, setCccd] = useState('');
    const [email, setEmail] = useState('');
    const [diachi, setdiachi] = useState('');
    const [mangt, setmangt] = useState('0');
    const [key, setKey] = useState(null);
    const [lstNguoiGioiThieu, setLstNguoiGioiThieu] = useState([])
    const [lineManager, setLineManager] = useState(null);
    const [code, setCode] = useState(null);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        if (props.edit === true) {
            sethoten(props.detail.hoTen);
            setGioitinh(props.detail.gioiTinh === "Nam" ? 0 : 1);
            setNgaysinh(props.detail.ngaySinh && moment(props.detail.ngaySinh));
            setDienthoai(props.detail.dienThoai);
            setCccd(props.detail.cccd);
            setEmail(props.detail.email);
            setdiachi(props.detail.diaChi);
            setmangt(props.detail.id.toString());
            setcheckValid(false);
            setLineManager(props.detail.parentCode);
            setCode(props.detail.code);
        }
    }, [props.detail])

    useEffect(() => {
        loadNhanVien()
    },[key])

    function GetListNguoiGioiThieu() {
        let filterModel = {
            //common: paging & sorting
            skipCount: 0,
            maxResultCount: 10,
            //riêng theo module
            keyword: key,
        }
        PatientService.GetReferentList(filterModel).then(results => {
            if (results.isSuccess) {
                let data = results.data.items;
                setLstNguoiGioiThieu(data);
            } else {
                Actions.openMessageDialog("Lỗi", results.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    function loadNhanVien() {
        let filterModel = {
            //common: paging & sorting
            skipCount: 0,
            maxResultCount: 10,
            //riêng theo module
            keyword: key
        }
        NhanVienService.GetListEmployee(filterModel).then(result => {
            if (result.isSuccess) {
                let data = result.data.items;
                setLstNguoiGioiThieu(data);
            }
        }).catch(err => {
        })
    }


    function Submit() {
        setcheckValid(true);
        if (validator.current.allValid()) {
            const data = {
                ngaysinh: ngaysinh,
                gioitinh: gioitinh.toString(),
                hoten: hoten,
                dienthoai: dienthoai,
                email: email,
                cccd: cccd,
                diachi: diachi,
                id: mangt,
                parentCode : lineManager,
                code : code,
            }
            Actions.setLoading(true);
            PatientService.UpdateNguoiGioiThieu(data).then(results => {
                if (results.isSuccess) {
                    notification.success({ message: 'Lưu thành công!' })
                    if (props.reload) {
                        props.reload();
                    }
                    if (props.onClose)
                        props.onClose();
                } else {
                    Actions.openMessageDialog("Lỗi", results.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
                Actions.setLoading(false);
            })
        }
        else {
            validator.current.showMessages();
            forceUpdate();
        }
    }
    return <React.Fragment>
        <HeaderModal 
            title={ props.edit ? 'Cập nhật thông tin người giới thiệu' : 'Thêm thông tin người giới thiệu'} 
            onClose={ () => { if (props.onClose) props.onClose(); }}/>
        <div className="row">
            <div className="col-md-6 col-sm-12 py-1">
                <label className='required-field'>Mã người giới thiệu</label>
                <input type="text" 
                    maxLength={40} 
                    className="form-control" 
                    placeholder="Nhập mã người giới thiệu" 
                    value={code} 
                    onChange={(e) => {
                        setCode(e.target.value)
                    }}
                />
                {validator.current && validator.current.message("Mã người giới thiệu", code, "required")}
            </div>
            <div className="col-md-6 col-sm-12 py-1">
                <label className='required-field'>Họ tên</label>
                <input 
                    type="text" 
                    maxLength={40} 
                    className="form-control" 
                    placeholder="Nhập họ tên" 
                    value={hoten} 
                    onChange={(e) => {
                        sethoten(e.target.value.toUpperCase())
                    }}
                />
                {validator.current && validator.current.message("Họ tên", hoten, "required")}
            </div>
            <div className="col-md-6 col-sm-12 py-1">
                <label >Giới tính</label>
                <select className="form-control" value={gioitinh} onChange={(e) => setGioitinh(e.target.value)}>
                    <option value={0}>Nam</option>
                    <option value={1}>Nữ</option>
                </select>
            </div>
            <div className="col-md-6 col-sm-12 py-1">
                <label >Ngày sinh</label>
                <DatePicker locale={viVN} className="w-100" format={SystemConfig.FORMAT_DATE} value={ngaysinh} allowClear={true} onChange={(e) => {
                    setNgaysinh(e);
                }}
                    disabledDate={current => { return current && (current >= moment() || current <= moment("1900-01-01")) }}
                />
            </div>
            <div className="col-md-6 col-sm-12 py-1">
                <label >Điện thoại</label>
                <input type="text" className="form-control" placeholder="Nhập điện thoại" value={dienthoai}
                    maxLength={11}
                    onChange={(e) => {
                        if (isNaN(e.target.value))
                            return;
                        setDienthoai(e.target.value);
                    }}
                />
            </div>
            <div className="col-md-6 col-sm-12 py-1">
                <label >Căn cước công dân</label>
                <input type="text" maxLength={20} className="form-control" placeholder="Nhập số căn cước" value={cccd} onChange={(e) => {
                    if (isNaN(e.target.value))
                        return;
                    setCccd(e.target.value)
                }}
                />
            </div>
            <div className="col-md-6 col-sm-12 py-1">
                <label >Email</label>
                <input type="text" className="form-control" placeholder="Nhập email" value={email} onChange={(e) => {
                    setEmail(e.target.value)
                }}
                />
            </div>
            <div className="col-md-6 col-sm-12 py-1">
                <label >Địa chỉ</label>
                <input type="text" className="form-control" placeholder="Nhập địa chỉ" value={diachi} onChange={(e) => {
                    setdiachi(e.target.value)
                }}
                />
            </div>
            <div className="col-md-6 col-sm-12 mb-2">
                <label>Người quản lý trực tiếp</label>
                <Select
                    className='w-100'
                    showSearch
                    allowClear={true}
                    value={lineManager}
                    onSelect={(e) => {setLineManager(e)}}
                    onClear={() => {setLineManager(null)}}
                    onSearch={(e) => setKey(e)}
                    filterOption={(input, option) =>{
                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                                option.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }}
                >
                    {lstNguoiGioiThieu && lstNguoiGioiThieu.filter(x=> x.id.toString() !== mangt).map((row, index) => {
                        return (
                            <Option key={index} value={row.uuid}>{row.code} - {row.hoTen}</Option>
                        )
                    })}
                </Select>
            </div>
        </div>
        <div className='custom-hr'></div>
        <div className="form-group" style={{ display: 'flex', justifyContent: 'end' }}>
            <button className="btn btn-color" onClick={() => Submit()}><i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
            <button className="btn btn-danger" style={{ marginLeft: "10px" }}
                onClick={() => {
                    if (props.onClose)
                    props.onClose();
                }}>
                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                /> Đóng
            </button>
        </div>
    </React.Fragment>
}

export default CreateNguoiGioiThieu;