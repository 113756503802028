import { Input, Tooltip, Select, Modal, Pagination, Table, Tag, Slider, notification } from 'antd';
import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import * as LoyalServices from './LoyalServices';
import * as AdministrativeService from "../../services/administrativeService";
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import moment from 'moment';
import * as signalR from '@aspnet/signalr';
import * as Configure from '../../configure/urlConfig';
import { FORMAT_DATE } from '../../configure/systemConfig';
import * as Actions from '../../libs/actions';
import UnSupport from '../unknowPage/unSupport';

const { Option } = Select;
const DEFAULT_CITY = 101; // Hà Nội
const DEFAULT_DISTRICT = 10101; // Bắc Từ Liêm

function Loyalty() {
    const _hiddenLink = useRef();
    const [kwd, setKwd] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [vsbCreate, setVsbCreate] = useState(false);
    const [suggest, setSuggest] = useState(false);
    const [listLoyalMembers, setListLoyalMembers] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [listGroup, setListGroup] = useState([]);
    const [selectedTieuChi, setSelectedTieuChi] = useState([]);
    const [listTieuChiCreate, setListTieuChiCreate] = useState([]);
    const [ageRange, setAgeRange] = useState([18, 50]);
    const [gender, setGender] = useState(0);
    const [spent, setSpent] = useState(1);

    const [vsbAgeRange, setVsbAgeRange] = useState(false);
    const [vsbGender, setVsbGender] = useState(false);
    const [vsbLocation, setVsbLocation] = useState(false);
    const [vsbSpent, setVsbSpent] = useState(false);
    const [groupName, setGroupName] = useState("");

    const [lstCity, setLstCity] = useState([]);
    const [city, setCity] = useState(DEFAULT_CITY);
    const [lstDistrict, setLstDistrict] = useState([]);
    const [district, setDistrict] = useState(0);

    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const getListLoyalGroups = (getAll = true) => {
        LoyalServices.getListLoyalGroups("", 1, 10, getAll).then(res => {
            if (res.isSuccess) {
                setListGroup(res.data);
            }
        }).catch(() => { })
    }

    const loadCity = (pageNumber, pageSize) => {
        AdministrativeService.GetAllCity(pageNumber, pageSize).then(result => {
            if (result.isSuccess) {
                setLstCity(result.data);
                setCity(DEFAULT_CITY);
            }
        }).catch(err => {
        })
    }

    const loadDistrict = (id = city, pageNumber = 1, pageSize = 100) => {
        AdministrativeService.GetDistrictByCityId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess) {
                setLstDistrict(result.data);
                setDistrict(0);
            }
            else {
                setLstDistrict([])
            }
        }).catch(err => {
        })
    }

    const createGroup = () => {
        var querryString = "";
        if (validator.current.allValid()) {
            listTieuChiCreate.sort().forEach((tieuChi) => {
                switch (tieuChi) {
                    case "age":
                        querryString === "" ?
                            querryString = `${tieuChi} >= ${ageRange[0]} and ${tieuChi} <= ${ageRange[1]}`
                            : querryString += `and ${tieuChi} >= ${ageRange[0]} and ${tieuChi} <= ${ageRange[1]} `;
                        break;
                    case "gender":
                        querryString === "" ?
                            querryString = `${tieuChi} = ${gender}`
                            : querryString += ` and ${tieuChi} = ${gender}`;
                        break;
                    case "location":
                        querryString === "" ?
                            district === 0 ? querryString = `city = ${city}`
                                : querryString = `city = ${city} and district = ${district}`
                            : district !== 0 ? querryString += ` and city = ${city} and district = ${district}`
                                : querryString += ` and city = ${city}`;
                        break;
                    case "spent":
                        querryString = querryString === "" ? querryString : querryString + " and ";
                        if (spent === 1) querryString += `${tieuChi} < 5000000`
                        else if (spent === 2) querryString += `${tieuChi} >= 5000000 and ${tieuChi} < 10000000`
                        else if (spent === 3) querryString += `${tieuChi} >= 10000000 and ${tieuChi} < 20000000`
                        else if (spent === 4) querryString += `${tieuChi} >= 20000000`
                        break;
                }
            })
            const data = {
                key: groupName,
                value: querryString
            };
            LoyalServices.createLoyalGroup(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Đã tạo thành công" });
                    resetState();
                    getListLoyalGroups();
                }
                else {
                    if (res.err.msgString) notification.warning({ message: res.err.msgString })
                    else notification.warning({ message: "Không thể tạo nhóm, vui lòng thử lại sau" })
                }
            }).catch(() => { })
        } else {
            forceUpdate();
            validator.current.showMessages();
        }
    }

    const getListLoyalMembers = (page = currentPage, size = pageSize) => {
        LoyalServices.getListLoyalMembers(selectedGroup == null ? 0 : Number(selectedGroup), kwd, page, size).then(res => {
            if (res.isSuccess) {
                setListLoyalMembers(res.data);
                setCurrentPage(page);
                setPageSize(size);
                setTotalRecords(res.totalRecords);
            }
        }).catch(() => { })
    }

    function ExportListLoyalMembers() {
        Actions.setLoading(true);
        LoyalServices.exportListLoyalMembers(selectedGroup && Number(selectedGroup), kwd)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "DanhSachThanhVien";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    const resetState = () => {
        validator.current.hideMessages();
        setVsbCreate(false);
        setGroupName("");
        setListTieuChiCreate([]);
        setAgeRange([18, 50]);
        setGender(0);
        setCity(DEFAULT_CITY);
        setDistrict(0);
        setSpent(1);
        setVsbAgeRange(false);
        setVsbGender(false);
        setVsbLocation(false);
        setVsbSpent(false);
    }


    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 70
        },
        {
            title: 'Mã BN',
            dataIndex: 'patientID',
            key: 'patientID',
            width: 100,
        },
        {
            title: 'Họ tên',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 200,
            render: (value) => {
                return <b>{value}</b>;
            },
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            width: 80,
            render: (value) => {
                return <span>{value === 0 ? "Nam" : "Nữ"}</span>;
            },
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'dob',
            key: 'dob',
            width: 100,
            render: (dob, row) => {
                return <span>{row.onlyyear ? moment(dob).format("YYYY") : moment(dob).format(FORMAT_DATE)}</span>;
            },
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            width: 250,
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 120
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'left',
            width: 160
        },
        {
            title: 'Tổng chi tiêu',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 100,
            render: (total) => total.toLocaleString('en-US'),
        },
    ]

    const ThongTinTieuChi = () => {
        var found = listGroup.find(x => x.key === selectedGroup);
        if (found) {
            var preSet = found.value.replaceAll("age ", "Tuổi ")
            .replaceAll("gender = 0", "Giới tính: Nam")
            .replace("gender = 1", "Giới tính: Nữ")
            .replace("city =", "Tỉnh/thành phố:")
            .replace("district =", "Quận/huyện:")
            .replaceAll("spent", "Tổng chi tiêu");
            var listTieuChi = preSet.split(" and ");
            setSelectedTieuChi(listTieuChi);
        }
    }

    useEffect(() => {
        ThongTinTieuChi();
        getListLoyalMembers();
    }, [selectedGroup])

    useEffect(() => {
        if (vsbLocation) {
            loadCity();
            loadDistrict();
        }
    }, [vsbLocation])

    useEffect(() => {
        loadDistrict()
    }, [city])

    useEffect(() => {
        getListLoyalGroups();
    }, [])

    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(Configure.BASE_NOTIFY_URL + "/notify")
            .build();

        connection.on("notify", data => {
            if (data === 'loyalty')
                getListLoyalGroups();
        })
        connection.start();
    }, []);

    const setVisibleFields = (e) => {
        e.find(x => x === "age") !== undefined ? setVsbAgeRange(true) : setVsbAgeRange(false);
        e.find(x => x === "gender") !== undefined ? setVsbGender(true) : setVsbGender(false);
        e.find(x => x === "location") !== undefined ? setVsbLocation(true) : setVsbLocation(false);
        e.find(x => x === "spent") !== undefined ? setVsbSpent(true) : setVsbSpent(false);
    }

    return (
        1===1 ? <UnSupport title="Chức năng này chưa hỗ trợ ở thời điểm hiện tại" /> :
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
            <div className={'containerChilderWrapper'}>
                <h4 className="font-bold text-center text-uppercase mt-2">Danh sách thành viên</h4>
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-2 mt-2">
                        <Select value={selectedGroup} placeholder="Chọn nhóm" onChange={(e) => { setSelectedGroup(e); setCurrentPage(1) }} className="w-100">
                            {listGroup.map((group, index) => {
                                return <Option key={`option-group-${group.key}`} value={group.key}>{group.label}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="col-md-4">
                        <Input
                            className="mt-2"
                            value={kwd} placeholder="Nhập tên hoặc mã khách hàng sau đó bấm Enter để tìm kiếm"
                            onChange={(e) => setKwd(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    getListLoyalMembers(1);
                                }
                            }}>
                        </Input>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-color mt-2" onClick={() => getListLoyalMembers(1)}>
                            <i className="fas fa-search" />
                        </button>
                        {/* <Tooltip title="Thêm nhóm thành viên">
                            <button className="btn btn-color ml-2 mt-2" onClick={() => { setVsbCreate(true) }}>
                                <i className="fas fa-plus" />
                            </button>
                        </Tooltip> */}
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-color mt-2" onClick={() => ExportListLoyalMembers()}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                    </div>
                </div>
                <div style={{ height: 'calc(100% - 130px)', padding: 10 }}>
                    <div style={{ paddingBottom: 5 }}>{selectedTieuChi && <span>Tiêu chí phân loại của nhóm: {selectedTieuChi.length > 0 ? selectedTieuChi.map(tc => <Tag key={tc}>{tc}</Tag>) : <i>Chưa chọn nhóm</i>}</span>}</div>
                    <Table scroll={{ y: 0 }} pagination={false} dataSource={listLoyalMembers}
                        columns={columns}
                        rowKey={row => `table-item-${row.patientID}`}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    setSelectedId(record.patientID)
                                }
                            };
                        }}
                        rowClassName={(record) => {
                            return `rowCustomSelect ${(selectedId === record.patientID) ? 'rowCustomSelectActive' : ''}`
                        }}
                    />
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        onChange={(page, size) => {
                            getListLoyalMembers(page, size);
                        }}
                        onShowSizeChange={() => {}}
                        showSizeChanger={true}
                    />
                </div>
            </div>
            <Modal visible={vsbCreate} maskClosable={false} width={700}
                onCancel={() => resetState()}>
                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <h3>Thêm nhóm thành viên</h3>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-2">
                        <label>Tên nhóm</label>
                        <Input placeholder="Nhập tên nhóm" value={groupName} onChange={(e) => setGroupName(e.target.value)}></Input>
                        {validator.current.message("Tên nhóm", groupName, "required")}
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Tiêu chí phân loại</label>&nbsp;
                        <span onClick={() => setSuggest(!suggest)}><i className="fas fa-question-circle" style={{ marginRight: 5 }} /></span>
                        <Select mode="multiple" style={{ width: '100%' }}
                            value={listTieuChiCreate} placeholder="Bấm vào để chọn, có thể chọn nhiều"
                            onChange={(e) => { setListTieuChiCreate(e); setVisibleFields(e) }}>
                            <Option value="age">Tuổi</Option>
                            <Option value="gender">Giới tính</Option>
                            <Option value="location">Vị trí (địa chỉ)</Option>
                            <Option value="spent">Số tiền đã chi</Option>
                        </Select>
                        {validator.current.message("Tiêu chí phân loại", setListTieuChiCreate.length < 0, "required")}
                    </div>
                    {vsbAgeRange &&
                        <div className="col-md-6 mt-2">
                            <label>Chọn khoảng tuổi: {ageRange.length > 0 && <span>({ageRange[0]} - {ageRange[1]})</span>}</label>
                            <Slider range value={ageRange} onChange={(e) => setAgeRange(e)} />
                        </div>}
                    {vsbGender &&
                        <div className="col-md-6 mt-2">
                            <label>Chọn giới tính:</label>
                            <Select className="w-100" value={gender} onChange={(e) => setGender(e)}>
                                <Option value={0}>Nam</Option>
                                <Option value={1}>Nữ</Option>
                            </Select>
                        </div>}
                    {vsbLocation &&
                        <>
                            <div className="col-md-6 mt-2">
                                <label>Chọn tỉnh/thành:</label>
                                <Select className="w-100" value={city} onChange={(e) => setCity(e)} showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {lstCity.map((city, index) => {
                                        return <Option value={city.value} key={city.value}>{city.label}</Option>
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-6 mt-2">
                                <label>Chọn quận/huyện:</label>
                                <Select className="w-100" value={district} onChange={(e) => setDistrict(e)} showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option value={0}>Tất cả</Option>
                                    {lstDistrict.map((dist, index) => {
                                        return <Option value={dist.value} key={dist.value}>{dist.label}</Option>
                                    })}
                                </Select>
                            </div>
                        </>}
                    {vsbSpent &&
                        <div className="col-md-6 mt-2">
                            <label>Chọn khoảng tiền:</label>
                            <Select className="w-100" value={spent} onChange={(e) => setSpent(e)}>
                                <Option value={1}>Dưới 5 triệu</Option>
                                <Option value={2}>5 triệu đến dưới 10 triệu</Option>
                                <Option value={3}>10 triệu đến dưới 20 triệu</Option>
                                <Option value={4}>Từ 20 triệu</Option>
                            </Select>
                        </div>}
                    <div className="col-md-12 mt-2" onClick={() => setSuggest(!suggest)}>
                        <ol type="I" visible={!suggest && "none"} style={{ display: suggest ? "flow-root" : "none" }}>
                            <li>Nhóm 1 tiêu chí:
                                <ol type="1">
                                    <li>Tuổi</li>
                                    <li>Giới tính</li>
                                    <li>Vị trí (địa chỉ)</li>
                                    <li>Số tiền đã chi</li>
                                </ol>
                            </li>
                            <li>Nhóm 2 tiêu chí:
                                <ol type="1">
                                    <li>Tuổi + Giới tính</li>
                                    <li>Tuổi + Vị trí (địa chỉ)</li>
                                    <li>Tuổi + Số tiền đã chi</li>
                                    <li>Giới tính + Vị trí (địa chỉ)</li>
                                    <li>Giới tính + Số tiền đã chi</li>
                                    <li>Vị trí (địa chỉ) + Số tiền đã chi</li>
                                </ol>
                            </li>
                            <li>Nhóm 3 tiêu chí:
                                <ol type="1">
                                    <li>Tuổi + Giới tính + Vị trí (địa chỉ)</li>
                                    <li>Tuổi + Giới tính + Số tiền đã chi</li>
                                    <li>Tuổi + Vị trí (địa chỉ) + Số tiền đã chi</li>
                                    <li>Giới tính + Vị trí (địa chỉ) + Số tiền đã chi</li>
                                </ol>
                            </li>
                            <li>Nhóm 4 tiêu chí:
                                <ol type="1">
                                    <li>Tuổi + Giới tính + Vị trí (địa chỉ) + Số tiền đã chi</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                    <button className="btn btn-success" disabled={listTieuChiCreate.length === 0} onClick={() => createGroup()}>
                        <i className="fas fa-plus" style={{ marginRight: 5 }} />Thêm
                    </button>
                    <button className="ml-2 btn btn-danger" onClick={() => resetState()}><i className="mr-2 fas fa-sign-out-alt"></i>Đóng</button>
                </div>
            </Modal>
        </Fragment>
    );
}

export default Loyalty;