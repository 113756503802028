
import * as React from 'react';
import { AutoComplete, Input } from 'antd';
import moment from 'moment';
import './main.css';
import { connect } from 'react-redux';
class AutocompleteTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      columns: [],
      searchList: [],
    }
  }

  componentDidMount() {

    this.setState({ columns: this.props.headers })
  }

  renderGroupName = (text) => {
    return (

      <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
        {text}
      </div>
    )
  }

  focus() {
    this.search.focus()
  }
  renderTittle = () => {
    return (
      <div
        style={{ display: 'flex' }}>
        {this.state.columns.map((col, index) => {
          return (
            <span key={"data" + col.dataIndex + index} style={{
              width: col.width, overflow: 'hidden',
              textOverflow: 'ellipsis', marginRight: 5
            }}>{col.title}</span>
          )
        })
        }
      </div>
    )
  };

  getFormat(item, col) {
    if (col.format === "number")
      return item[col.dataIndex].toLocaleString('en-US')
    if (col.format === "datetime" && item[col.dataIndex])
      return moment(item[col.dataIndex]).format('DD-MM-YYYY');

    return item[col.dataIndex]
  }
  renderItem = (item) => {
    var color = 'black'
    //hard vo soluonton
    if (this.props.medicineMarket) {
      if (item.slton === 0) {
        color = '#cf2a0c'
      } else {
        if (item.slton < 20) {
          //color = '#bdbd0b'
          color = 'black'
        }
      }
    }
    return {
      value: item[this.props.keyValue],
      label: (
        <div
          style={{ display: 'flex' }}>
          {this.state.columns.map((col, index) => {
            return (
              <span key={"data" + col.dataIndex + index} style={{
                color: color,
                width: col.width, overflow: 'hidden',
                textOverflow: 'ellipsis', marginRight: 5
              }}>{
                  this.getFormat(item, col)
                } </span>
            )
          })
          }
        </div>
      ),
    };
  };

  groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  handleSearch = (value) => {
    if(value !== ""){
      let options = [];
      let option = {
        label: this.renderTittle(),
        options: [],
      }
      options.push(option);
      this.props.onSearch(value).then((result) => {
        if (result.isSuccess) {
          var grouped = this.groupBy(result.data, (c) => c.group);
          for (let item in grouped) {
            if (this.props.hideGroupHeader !== true) {
              option = {
                label: this.renderGroupName(item),
                options: [],
              }
            }
            for (let r of grouped[item]) {
              option.options.push(this.renderItem(r))
            }
            if (this.props.hideGroupHeader !== true) {
              options.push(option)
            }
          }
        }
        this.setState({ options, searchList: result.data })
        }).catch((ex) => {
        this.setState({ options })
      })
    } else {
        this.setState({ options: [], searchList: [] })
    }
  }

  onSelect = (value) => {
    this.setState({
      searchInput: '',
      options: [], 
      searchList: []
    });
    let item = this.state.searchList.filter(record => record[this.props.keyValue] === value)[0];
    this.props.onSelect(item);
  }

  render() {
    return (
      <React.Fragment>
        <AutoComplete
          value={this.state.searchInput}
          onChange={e => this.setState({ searchInput: e })}
          onSelect={this.onSelect}
          onSearch={this.handleSearch}
          dropdownMatchSelectWidth={this.props.tablewidth}
          style={{ width: this.props.width, height: 500 }}
          dropdownClassName={"tckdropdowncustom certain-category-search-dropdown"}
          options={this.state.options}
          listHeight={this.props.listHeight ? this.props.listHeight : 500}
          disabled={this.props.disabled}
          getPopupContainer={trigger => trigger.parentNode}
        >
          <Input ref={(c) => { this.search = c }} placeholder={this.props.placeholder} />
        </AutoComplete>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
    return ({
        medicineMarket: state.global.sysConfig.medicineMarket ? state.global.sysConfig.medicineMarket : false,
    })
};
export default connect(
    mapStateToProps, null, null, { forwardRef: true }
)(AutocompleteTable);