/*
Component này là thông tin Kết quả xét nghiệm. 
Right panel theo màn hình 6/10 của mockup "HIS-phan he xet nghiem.pdf": thông tin chi tiết kết quả
*/
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, useCallback, useImperativeHandle } from 'react';
import { Button, List, Checkbox, Input, Tooltip } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import { ValidationCustom } from '../../../commons/validationCustom';
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../../components/notify';
import * as ExamResultService from '../../../services/ExamResultServices';
import { connect } from 'react-redux';

const DownLoadTemplate = React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    listTestParentModified: () => {
          return listTestParentModified;
    },
    listTestChildrenModified: () => {
          return listTestChildrenModified;
    }
  }));
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const upload = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  //state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [detailScreenMode, setDetailScreenMode] = useState(props.detailScreenMode);
  //model
  const [listResult, setListResult] = useState([]);//list nhiều level: loại dv -> test cha -> test con
  const [listTestParent, setListTestParent] = useState([]);
  const [listTestChildren, setListTestChildren] = useState([]);
  const [listTestParentModified, setListTestParentModified] = useState([]);
  const [listTestChildrenModified, setListTestChildrenModified] = useState([]);
  //#region useEffect
  useEffect(() => {
  }, [listTestParentModified]);
  useEffect(() => {
    search();
    // initData();
  }, []);
  //#endregion 
  const setData = (data = []) => {
    let lCha = [];
    let lCon = [];
    data.forEach(nhom => {
      lCha = [...lCha, ...nhom.parentTests];
      nhom.parentTests.forEach(cha => {
        if (cha.childrenTests && cha.childrenTests.length > 0) {
          lCon = [...lCon, ...cha.childrenTests];
        }
      })
    })
    setListTestParent(lCha);
    setListTestChildren(lCon);
  }

  const search = () => {
    Actions.setLoading(true);
    let filterModel = {
      siteId: props.siteid,
      maQL: props.maQL,
      sid: props.sid,
    }
    ExamResultService.getListExamResultTest(filterModel).then(result => {
      if (result.isSuccess) {
        setListResult(result.data);
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const changeBatThuong = (record, fieldName, value) => {
    if (record.vienPhiMaCha) changeBatThuongCon(record, fieldName, value);
    else changeBatThuongCha(record, fieldName, value);
  }
  const changeBatThuongCha = (record, fieldName, value) => {
    let list = [...listTestParentModified];
    let oldRecord = list.filter(x => x.idChiDinh == record.idChiDinh && x.vienPhiId == record.vienPhiId)[0];
    if (oldRecord) {
      oldRecord[fieldName] = value;
    }
    else {
      record[fieldName] = value;
      list.push(record);
    }
    setListTestParentModified(list);
  }
  const changeBatThuongCon = (record, fieldName,value) => {
    let list = [...listTestChildrenModified];
    let oldRecord = list.filter(x => x.vienPhiMa == record.vienPhiMa && x.vienPhiMaCha == record.vienPhiMaCha)[0];
    if (oldRecord) {
      oldRecord[fieldName] = value;
    }
    else {
      record[fieldName] = value;
      list.push(record);
    }
    setListTestChildrenModified(list);
  }

  const columns = [
    {
        title: '#',
        key: 'stt',
        dataIndex: 'stt',
        align: 'left',
        width: 60,
        // render: (text, record, index) => {
        //   return <span>{ record.isChild ? "" : index.toString() }</span>;
        // }
    },
    {
        title: 'Tên dịch vụ',
        key: 'vienPhiTen',
        dataIndex: 'vienPhiTen',
        align: 'left',
        width: '100%',
        render: (text, record, index) => {
          return <span className={record.vienPhiMaCha ? "ml-4" : "" }>{ text }</span>;
        }
    },
    {
        title: 'Kết quả',
        key: 'ketQua',
        dataIndex: 'ketQua',
        align: 'center',
        width: 200,
        render: (text, record, index) => {
          let className = '';
          if ((record.batThuong ?? '') != '') className='font-weight-bold';
          return (record.childrenTests && record.childrenTests.length > 0) ? "" : (
            <Input
              placeholder=""
              value={text} 
              onChange={(e) => { changeBatThuong(record, 'ketQua', e.target.value); }}
              // onKeyUp={(e) => { enterToSearch(e); }}
            ></Input>
          );
        }
    },
    {
        title: 'Bất thường',
        key: 'batThuong',
        dataIndex: 'batThuong',
        align: 'center',
        width: 320,
        render: (text, record, index) => {
          let className = '';
          return (record.childrenTests && record.childrenTests.length > 0) ? "" : (
            <div className="d-flex justify-content-center align-items-center">
              <Checkbox className="px-1"
                readonly={true}
                checked={(record.batThuong ?? '') != ''}
                onChange={(e) => {changeBatThuong(record, 'batThuong',e.target.checked ? 'abnormal' : '');}}
              ></Checkbox>
              <div type="link" className="p-0 px-1 color-red" 
                style={{fontSize: '18px !important', color: (record.batThuong == 'High' ? 'red' : '')}}
                onClick={() => {
                  changeBatThuong(record, 'batThuong','High');
                }}
              ><i className="fa fa-long-arrow-alt-up"></i></div>
              <div type="link" className="p-0 px-1 color-black" 
                style={{fontSize: '18px !important', color: (record.batThuong == 'Low' ? 'red' : '')}}
                onClick={() => {
                  changeBatThuong(record, 'batThuong','Low');
                }}
              ><i className="fa fa-long-arrow-alt-down"></i></div>
            </div>
          );
        }
    },
    {
        title: 'Chỉ số bình thường',
        key: 'normalRange',
        dataIndex: 'normalRange',
        align: 'center',
        width: 320,
    },
    {
        title: 'Đơn vị tính',
        key: 'unit',
        dataIndex: 'unit',
        align: 'center',
        width: 150,
    },
    {
        title: 'Tiền sử',
        key: 'lastKetQua',
        dataIndex: 'lastKetQua',
        align: 'center',
        width: 160,
        render: (text, record, index) => {
          let className = '';
          if ((record.lastBatThuong ?? '') != '') className='font-weight-bold float-right';
          let lh = ''; // Low / High
          switch ((record.lastBatThuong ?? '').toLowerCase()) {
            case 'high':
              lh=' H';
              break;
            case 'low':
              lh=' L';
              break;
            case 'abnormal':
              break;
            default:
              break;
          }
          return text ?
            <span className={className}>{ text }{lh}
              <Tooltip title={"Ngày đăng ký: "+moment(record.lastNgayDangKy).format(SystemConfig.FORMAT_DATE_TIME)}><i className="far fa-clock ml-1"></i>
              </Tooltip>
            </span> : "";
        }
    },
  ]

  const geneHeader = () => {
    return (
      // <div className="d-flex justify-content-between w-100 p-1 py-2">
      //   {
      //     columns.map(x => {return(
      //       <div key={x.key} style={{width:x.width, textAlign: x.align}}>
      //         <label><b>{x.title}</b></label>
      //       </div>
      //     )})
      //   }
      // </div>
      <List className="none-border-bottom none-border-bottom-item ant-table-header"
        key={'header'}
        bordered={false}
      >
        <List.Item key={0}
          className="d-flex w-100 p-1 py-2"
        >
          <div className="d-flex justify-content-between w-100">
          {
            columns.map(x => {return(
              <div key={x.key} style={{width:x.width, textAlign: x.align}}>
                <label><b>{x.title}</b></label>
              </div>
            )})
          }
          </div>
        </List.Item>
      </List>
    )
  }
  const renderDvCon = (listDvCon) => {
    if (!listDvCon || listDvCon.length == 0) return "";
    let className = "p-1 border-2 border-bottom py-2";
    return (
      listDvCon.map((dvCon, index) => {
        return(
        <List.Item key={"dvCon-"+dvCon.vienPhiMa}
          className={className}
        >
          <div className="d-flex justify-content-between w-100">
          {
            columns.map(x => {
              return(
              <div key={x.key} style={{width:x.width, textAlign: x.align}}>{
                x.render ? x.render(dvCon[x.dataIndex], dvCon, index) : dvCon[x.dataIndex]
              }
              </div>
            )})
          }
          </div>
        </List.Item>
        )
      })
    )
  }
  const renderDvCha = (listDvCha) => {
    if (!listDvCha || listDvCha.length == 0) return "";
    let className = "p-1 border-2 border-bottom py-2 d-flex flex-column";
    return (
      listDvCha.map((dvCha, index) => {
        return(
          <>
            <List.Item key={"dvCha-"+dvCha.idChiDinh}
              className={className}
              style={{backgroundColor:"#F2F2F2"}}
            >
              <div className="d-flex justify-content-between w-100">
              {
                columns.map(x => {
                  return (
                  <div key={x.key} style={{width:x.width, textAlign: x.align}}>
                    {
                      x.render ? x.render(dvCha[x.dataIndex], dvCha, index) : dvCha[x.dataIndex]
                    }
                  </div>
                )})
              }
              </div>
              {/* {dvCha.mauGui &&
              <div className="d-flex w-100 pl-4">
                <div style={{width:'50%'}}>
                  <label className="mita-title">Chi nhánh gửi: </label>
                  <label className="mita-value">
                    <MitaDisplayLongText
                      content={dvCha.siteMauGuiTen}
                      maxLength={30} // mặc định 64
                      showMoreText={"...Xem thêm"}
                      showLessText={" Rút gọn"}
                    />
                  </label>
                </div>
                <div style={{width:'50%'}}>
                  <label className="mita-title">Chi nhánh nhận: </label>
                  <label className="mita-value">
                    <MitaDisplayLongText
                      content={dvCha.siteMauNhanTen}
                      maxLength={30} // mặc định 64
                      showMoreText={"...Xem thêm'"}
                      showLessText={" Rút gọn"}
                    />
                  </label>
                </div>
              </div>
              } */}
            </List.Item>
            {(dvCha.childrenTests && dvCha.childrenTests.length > 0) && renderDvCon(dvCha.childrenTests)}
          </>
        )
      })
    )
  }
  return (
    <div className="list-page-body p-2">{/* body */}
      <div className="row">{/* notification */}
        <div className="col-md-12"><Notify ref={notiRef} /></div>
      </div>
      <div className="list-page-search">{/* Không có search|filter trong này. Nhưng dùng tạm class này để chia các panel ra riêng biệt (vùng thông tin ở trên, vùng chi tiết-table bên dưới)*/}
        <div className="row mt-2">{/* Chi tiết title */}
          <div className="col-md-12 d-flex flex-column">
            <label><b>Chi tiết các dịch vụ</b></label>
          </div>
        </div>
      </div>
      <div className="list-page-table mt-2">{/* list */}
        {geneHeader()}
        <List className="none-border-bottom none-border-bottom-item h-100"
          key={'list'}
          bordered={false}
          // header={geneHeader()}
          dataSource={listResult}
          renderItem={(record) => {
            let className = "p-1 border-2 border-bottom py-2";
            return (
              <>
              <List.Item key={'loai-'+record.loaiId}
                className={className}
                style={{backgroundColor:"#D8D8D8"}}
              >
                <div key={"loaidv"} className="d-flex justify-content-between w-100">{/* 1 item */}
                  {record.loaiTen}
                </div>
              </List.Item>
              {renderDvCha(record.parentTests)}
              </>
          )}}
        >
        </List>
      </div>
    </div>
  );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DownLoadTemplate);