import React, { Fragment, useRef, useEffect, useState } from 'react';
import Notify, { NotifyStatus } from '../../components/notify';
import { Menu, Dropdown, Drawer, Tooltip } from 'antd';
import PrintSIDComp from "../invoice/paymentmulti/InSIDBarcode";
import SidChange from '../../screens/xacnhanmau/sidChange';
import * as Actions from '../../libs/actions';
import * as TestReceivedServices from '../../services/testReceivedService';
import { isNullOrEmpty } from '../../libs/util';
import * as SystemConfig from "../../configure/systemConfig";
import moment from 'moment';
///TẤT CẢ CÁC MỤC GHI CHÚ LÀ YÊU CẦU ĐÓNG BỞI ANH BẢO, CHƯA THỰC HIỆN

// ExamStatusFromLIS này map với enum ExamStatusFromLIS (file \HIS.Resource\Enums\TestReceiving.cs trong BE) nhé. Có đổi BE nhớ đổi ở đây theo
var ExamStatusFromLIS = {
    RECEIVED: 0,
    CANCEL: 1
}
function TestReceiving(props) {
    var stt = 0;
    const notiRef = useRef();
    const _btnSubmit = useRef();
    const [visiblePrint, setVisiblePrint] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [dataUpdateSID, setDataUpdateSID] = useState([]);
    const [listSample, setListSample] = useState([]);
    // const [checkAll, setCheckAll] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [receivingAllowed, setReceivingAllowed] = useState(false);
    const onCancel = () => {
        props.onCancel && props.onCancel();
    }
    const handleCloseDrawer = (reload = false) => {
        setVisibleEdit(false);
        setVisiblePrint(false);
        if (reload) {
            props.reload();
        }
    }
    // useEffect(() => {
    //     stt = 0;
    //     if (props.data.maQL && props.data.siteId) getListDetail(props.data.maQL, props.data.orderId, props.data.siteId);
    // }, [props]);
    useEffect(() => {
        _btnSubmit && _btnSubmit.current && _btnSubmit.current.focus();
    }, [receivingAllowed])
    //Dùng useEffect props ở trên sẽ bị gọi getListDetail nhiều lần. Do lúc mới load lên là bị change props nhiều lần (props.data.maQL, props.data.orderId, props.data.siteId: 3 lần)
    //useEffect dưới này là gọi khi load component naày lần đầu. Nên có khuyết điểm là: nếu chỗ nào gọi component này thì khi tắt (modal, drawer) phải destroy đi.
    //Để khi mở lại thì sẽ load lại. Còn không destroy mà tận dụng state cũ thì sẽ không gọi getListDetail.
    useEffect(() => {
        if (props.data.maQL && props.data.siteId) {
            stt = 0;
            getListDetail(props.data.maQL, props.data.orderId, props.data.siteId);
        }
    }, []);

    const SIDOption = () => {
        return (
            <Menu
                items={[
                    // {
                    //     label:
                    //         <a href="#" onClick={() => {
                    //         }}><i className='fas fa-plus-circle mr-2' />Tạo</a>,
                    //     key: 'createsid',
                    // },
                    isNullOrEmpty(props.data.nguoiXacNhan) && {
                        label:
                            <a href="#" onClick={() => {
                                handleOpenEditSID(props.data.maQL);
                                let data = {
                                    patientID: props.data.maBN,
                                    managementID: props.data.maQL,
                                    sid: props.data.sid,
                                    orderId: props.data.orderId,
                                }
                                setDataUpdateSID(data);
                            }}><i className="fas fa-pen mr-2"></i>Cập nhật</a>,
                        key: 'updatesid',
                    },
                    {
                        label:
                            <a href="#" onClick={() => {
                                handleOpenPrintSID(props.data);
                            }}><i className='fa fa-print mr-2' />In</a>,
                        key: 'printsid',
                    },
                ]}
            />
        )
    }
    const handleOpenPrintSID = (data) => {
        if (data.sid != undefined) {
            setVisiblePrint(true);
            notiRef.current && notiRef.current.Close();
        }
        else notiRef.current.Show("Chưa chọn SID", NotifyStatus.Warning);
    }
    const handleOpenEditSID = (maql) => {
        if (maql != undefined) {
            setVisibleEdit(true);
            notiRef.current && notiRef.current.Close();
        }
        else notiRef.current.Show("Chưa chọn SID", NotifyStatus.Warning);
    }
    const getListDetail = (managementId, orderId, siteId) => {
        Actions.setLoading(true);
        TestReceivedServices.getListConfirmTestReceived(managementId, orderId, siteId).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setTotalCount(result.data.length);
                setReceivingAllowed(result.data.find(x => x.receivingAllowed));
                const resultGroup = result.data.reduce(function (r, a) {
                    r[a.loaiTen] = r[a.loaiTen] || [];
                    r[a.loaiTen].push(a);
                    return r;
                }, Object.create(null));
                const resultGroupArr = Object.keys(resultGroup).map((key) => ({ key, key, value: resultGroup[key] }));
                setListSample(resultGroupArr);
            } else {
                notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }
    const handleChangeCheckAll = (e) => {
    }
    const onChangeCheckItem = (r) => {
    }
    const ConfirmTest = () => {
        Actions.setLoading(true);
        TestReceivedServices.ConfirmTest(props.data.maQL, props.data.orderId, props.data.siteId).then(result => {
            if (result.isSuccess) {
                notiRef.current.Show('Xác nhận thành công', NotifyStatus.Success);
                props.onSubmit && props.onSubmit();
            } else
                notiRef.current.Show(result.err.msgString, NotifyStatus.Error);
            Actions.setLoading(false);
        }).catch(err => {
            if (err.msgString && err.msgString)
                notiRef.current.Show(err.msgString, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }
    return (
        <Fragment>
            <div className='list-page'>
                <div className="list-page-body">
                    <div className="w-100 mt-2"><Notify ref={notiRef} /></div>
                    {/* <div className="row mt-2 mb-4">
                        <div className="col-md-12">
                            <div classname=""><i className="fas fa-external-link-alt mr-1" />Gửi mẫu</div>
                        </div>
                    </div> */}
                    <div className="table-responsive" style={{ overflowY: "auto", height: "calc(100vh - 190px)" }}>
                        <table className="table table-striped table-inverse table-hover">
                            <thead className="text-white">
                                <tr>
                                    {/* <th>
                                        <Checkbox checked={checkAll}
                                            indeterminate={false}
                                            onChange={(e) => handleChangeCheckAll(e) }/>
                                    </th> */}
                                    <th style={{ width: 60 }}>#</th>
                                    <th>Tên dịch vụ</th>
                                    <th style={{ width: 80, textAlign: 'center' }}>Trạng thái</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listSample.map((row, index, arr) => {
                                    return <Fragment key={index}>
                                        <tr key={index}>
                                            <td colSpan={4}
                                            // className="font-bold"
                                            >{row.key}</td>
                                        </tr>
                                        {
                                            row.value.map((r, ind) => {
                                                stt += 1;
                                                return (
                                                    <tr key={r.vienPhiId}>
                                                        {/* <td>
                                                            <Checkbox checked={r.checked ?? false} onChange={(r) => onChangeCheckItem(r)}/>
                                                        </td> */}
                                                        <td style={{ width: 60 }}>{stt}</td>
                                                        <td>{r.vienPhiTen}</td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {(r.status_XN == null && r.ngayGioLayMau == null) &&
                                                            <Tooltip title={`Chờ lấy mẫu`}>
                                                                <label className="text-warning"><i class="far fa-clock" /></label>
                                                            </Tooltip>
                                                            }
                                                            {(r.status_XN == null && r.ngayGioLayMau != null) &&
                                                            <Tooltip title={`${r.nguoiLayMau} đã lấy mẫu ${moment(r.ngayGioLayMau).format(SystemConfig.FORMAT_DATE_TIME)}`}>
                                                                <label className="text-success"><i class="fas fa-tint" /></label>
                                                            </Tooltip>
                                                            }
                                                            {r.status_XN == ExamStatusFromLIS.RECEIVED &&
                                                            <Tooltip title={`${r.nguoiNhanMau} đã nhận mẫu ${moment(r.ngayGioNhanMau).format(SystemConfig.FORMAT_DATE_TIME)}`}>
                                                                <label className="text-info"><i class="fas fa-check-circle" /></label>
                                                            </Tooltip>
                                                            }
                                                            {r.status_XN == ExamStatusFromLIS.CANCEL &&
                                                            <Tooltip title={`${r.nguoiNhanMau} đã từ chối ${moment(r.ngayGioNhanMau).format(SystemConfig.FORMAT_DATE_TIME)}`}>
                                                                <label className="text-danger"><i class="fas fa-times-circle" /></label>
                                                            </Tooltip>
                                                            }
                                                        </td>
                                                        {/* <td>
                                                            <Checkbox disabled checked={r.mauGui} />
                                                                <label><i className="map-marker-alt mr-1" />{r.siteMauGui}</label>
                                                        </td> */}
                                                    </tr>)
                                            })
                                        }
                                    </Fragment>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="list-page-footer mt-2">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                        <Dropdown overlay={() => SIDOption()} trigger={['click']} placement="topRight">
                            <button className='btn btn-secondary'><i className="fas fa-barcode mr-1" />SID<i className="fas fa-angle-right ml-1" /></button>
                        </Dropdown>
                        <div>
                            {receivingAllowed && isNullOrEmpty(props.data.nguoiXacNhan) &&
                                <button className="btn btn-color" ref={_btnSubmit} onClick={() => { ConfirmTest() }}><i className="fas fa-check-circle mr-1" />Xác nhận</button>}
                            <button className="btn btn-danger ml-2" onClick={() => onCancel()}><i className="fas fa-times-circle mr-1" />Đóng</button>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                closable={false}
                width={700}
                placement="right"
                visible={visiblePrint}>
                <PrintSIDComp billSelected={props.data.sid}
                    patientInfo={{
                        patientId: props.data.maBN,
                        patientName: props.data.hoTen,
                        dob: props.data.onlyYear ? props.data.namSinh : moment(props.data.ngaySinh).format(SystemConfig.FORMAT_DATE),
                        gender: props.data.phai === 1 ? "Nữ" : "Nam",
                    }}
                    onSubmit={() => handleCloseDrawer()}
                    onCancel={() => handleCloseDrawer()} />
            </Drawer>
            <Drawer className='custom-ant-drawer'
                closable={false}
                width={700}
                visible={visibleEdit}>
                <div style={{ lineHeight: "20px" }}>
                    <SidChange data={dataUpdateSID} onCancel={(reload) => handleCloseDrawer(reload)}></SidChange>
                </div>
            </Drawer>
        </Fragment >
    );
}

export default TestReceiving;