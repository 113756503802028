import * as ApiCaller from '../libs/httpRequests';

const prefix = 'chidinh';
export function getList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachLoaiChiDinh(key="" , group="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachloaichidinh?key=${key}&group=${group}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ThongTinDongYBenhNhan(mabn,maql,mavp,ngay,idchidinh='')  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinDongYBenhNhan?mabn=${mabn}&maql=${maql}&mavp=${mavp}&ngay=${ngay}&idchidinh=${idchidinh}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DanhSachChiDinh(key="",loai="",doituong,sothe,makp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachchidinh?key=${key}&loai=${loai}&doituong=${doituong}&sothe=${sothe}&makp=${makp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function XoaChiDinh(id, chinhanh="") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/xoachidinh?id=${id}&chinhanh=${chinhanh}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DanhSachChiDinhBenhNhan(mabn,maql,makp,ngay,thuthuat=false, type = 0, subType=0, isNoiTru=false, idbenhtrinh = "", roomCode = "")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachchidinhbenhnhan?mabn=${mabn}&maql=${maql}&makp=${makp}&ngay=${ngay}&thuthuat=${thuthuat}&type=${type}&subType=${subType}&isNoiTru=${isNoiTru}&idbenhtrinh=${idbenhtrinh}&roomCode=${roomCode}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DanhSachThuThuatBenhNhan(mabn,maql,ngay,mavp="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Surgery/DanhSachThuThuatBenhNhan?mabn=${mabn}&maql=${maql}&ngay=${ngay}&mavp=${mavp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DanhSachDongYBenhNhan(mabn,maql,ngay,mavp="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Surgery/DanhSachDongYBenhNhan?mabn=${mabn}&maql=${maql}&ngay=${ngay}&mavp=${mavp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ThongTinChiDinh(id,doituong,mabn,makp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongtinchidinh?id=${id}&doituong=${doituong}&mabn=${mabn}&makp=${makp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ThongTinChiDinhCDHA(id,doituong,mabn,makp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinChiDinhCDHA?id=${id}&doituong=${doituong}&mabn=${mabn}&makp=${makp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function GetPhongKham(pageNumber =1, pageSize = 200)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Common/phongkham?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function LuuChiDinh(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuchidinh`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function LuuThuThuat(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuThuThuat`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function searchSerivce(group , type , banggia, name, page = 1,size = 10)  {
    name = encodeURIComponent(name);
    banggia = encodeURIComponent(banggia);
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/searchSerivce?type=${type}&name=${name}&banggia=${banggia}&group=${group}&page=${page}&size=${size}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function getExamService()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getExamService`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DanhSachPhongKhamTheoLoai()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachPhongKhamTheoLoai`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DanhSachPKBenh()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachPKBenh`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function LoaiPhongKhamTheoMaVP(idvp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LoaiPhongKhamTheoMaVP?idvp=${idvp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ThongTinKetLuanHenKham(mabn, maql)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinKetLuanHenKham?mabn=${mabn}&maql=${maql}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function getAllTypeService()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getAllTypeService`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function getGroups(type)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getGroups?type=${type}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function CheckExistsChiDinh(mavp,maql)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/CheckExistsChiDinh?mavp=${mavp}&maql=${maql}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DanhSachChiDinhKhamBenh(maql)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachChiDinhKhamBenh?&maql=${maql}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getICDs(maql, mavp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetICDs?maql=${maql}&mavp=${mavp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function saveICDs(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SaveICDs`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};