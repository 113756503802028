import { Button, DatePicker, Form, Pagination, Input, Select, Tabs,Table, Radio } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';
import DanhSach from './nguoigioithieuchitietnew'
import HeaderModal from '../../../../configureHIS/common/headerModal'
import Notify, { NotifyStatus } from '../../../../components/notify';
import { BUTTON } from '../../../../configureHIS/common/constants';
import * as SystemConfig from "../../../../configure/systemConfig";
import DonutChart from '../../../../components/chart/DonutPlotWithStatistics'
import * as AuthServices from '../../../../services/authServices';
import * as CorporationServices from '../../../corporation/CorporationServices';
import * as PatientService from "../../../../services/patientService";
import * as NhanVienSerivce from '../../../../services/employeeService';
import { connect } from 'react-redux';
import SiteFilter from '../../../../configureHIS/common/siteFilter';

const { Option } = Select;
const { RangePicker } = DatePicker;
function NguoiGioiThieu(props) {
    const [ngay, setngay] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [key, setKey] = useState('');
    const [site, setSite] = useState([]);
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [filter, setFilter] = useState(1);
    const [isChart, setIsChart] = useState(true);
    const [availableSites, setAvailableSites] = useState([]);
    const [dataSelect, setDataSelect] = useState([]);
    const [listSelected, setListSelected] = useState([]);
    
    const [optionTypes] = useState([
        {
            label: "Nguồn khách hàng",
            value: 1,
        },
        {
            label: "Người giới thiệu",
            value: 2,
        },
        {
            label: "Sale",
            value: 3,
        }
    ]);

    const _hiddenLink = useRef();
    const _report = useRef();
    const _notiRef = useRef();
    const _chartWithNumber = useRef();
    const _chartWithValue = useRef();

    useEffect(() => {
        getAvaiableSites();
        GetListSite();
    }, [])

    useEffect(() => {
        Reload()
    }, [isChart]);

    const Reload = () =>{
        ThongKeNguoiGioiThieu();
        if(_report && _report.current){
            _report.current.reload();
        }
    }

    useEffect(() => {
        switch(filter){
            case 1 :
                return loadCoporation(key);
            case 2 :
                return loadNguoiGioiThieu(key);
            case 3 :
                return loadNhanVienSale(key);
        }
    }, [filter, key])

    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }

    const getAvaiableSites = () => {
        AuthServices.getAvailableSites().then((res) => {
            if (res.isSuccess) {
                setAvailableSites(res.data);
            }
        })
    };

    const loadCoporation = (key = "", page = 1, size = 10) => {
        CorporationServices.GetList(key, page, size, true).then(res => {
            if (res.isSuccess) {
                let data = res.data.map((e) => ({value : e.ma.toString(), label : e.ten}));
                setDataSelect(data);
            }
        })
    }

    const loadNguoiGioiThieu = (data) => {
        PatientService.GetListNguoiGioiThieu(data, false, 1, 20).then(res => {
            if (res.isSuccess) {
                let data = res.data.map((e) => ({value : e.id.toString(), label : e.hoten}));
                setDataSelect(data);
            }
        })
    }

    const loadNhanVienSale = (data) => {
        NhanVienSerivce.DanhSachNhanVien(data, 1, 100,'26').then(res => {
            if (res.isSuccess) {
                let data = res.data.map((e) => ({value : e.id.toString(), label : e.hoten}));
                setDataSelect(data);
            }
        })
    }

    function ThongKeNguoiGioiThieu() {
        Actions.setLoading(true);
        let filterModel = {
            //common: paging & sorting
            skipCount: (page - 1) * size,
            maxResultCount: size,
            //riêng theo module
            dateFrom: ngay[0],
            dateTo: ngay[1],
            referenceIds: listSelected,
            siteIds : site,
            filter : filter,
        }
        StatisticService.ReferenceRevenueStatisticChart(filterModel).then(result => {
            if (result.isSuccess) {
                let dataSourceWithNumber = result.data && result.data.map(x => ({type : x.name , value : x.totalNumber}))
                let dataSourceWithValue = result.data && result.data.map(x => ({type : x.name , value : x.revenue}))
                _chartWithNumber.current && _chartWithNumber.current.setDataSource(dataSourceWithNumber);
                _chartWithValue.current && _chartWithValue.current.setDataSource(dataSourceWithValue);
                setDataSource(result.data);
                setTotalRecord(result.totalRecords);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    function ExportThongKeDoanhSoBS() {
        Actions.setLoading(true);
        StatisticService.ExportThongKeNguoiGioiThieu(ngay[0].format('YYYY-MM-DD'), ngay[1].format('YYYY-MM-DD'), chinhanh, key, filter)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeNguoiGioiThieu";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }


    return (
        <>
        <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
        <div className='containerChilderWrapper'>
            <HeaderModal title='Thống kê người giới thiệu' />
            <Notify ref={_notiRef} />
            <div className='row mt-2'>
                <SiteFilter updateSeletedSite={(listSite) => { setSite(listSite) }} />
                <div className="col-md-8 mt-2">
                    <label>Lựa chọn</label>
                    <div className='row no-gutters'>
                    <div className='col-auto'>
                        <Radio.Group 
                        disabled={listSelected.length > 0}
                        id="radio-grp-filter"
                        onChange={(e) => {setFilter(e.target.value);}} 
                        value={filter} 
                        buttonStyle="solid">
                        {optionTypes && optionTypes.map((row, index) => {
                            return (
                                <Radio.Button key={index} value={row.value}>{row.label}</Radio.Button>
                            )
                        })}
                        </Radio.Group>
                        </div>
                        <div className='col-6'>
                        <Select
                            id="prosta-slt-people"
                            mode="multiple"
                            allowClear
                            className='w-100'
                            placeholder="Nhập mã, tên"
                            value={listSelected}
                            onChange={(e) => { setListSelected(e) }}
                            onSearch={(e) => {setKey(e)}}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {dataSelect && dataSelect.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-2">
                    <label>Ngày lập phiếu</label>
                    <RangePicker
                        id="promotion-select-date"
                        className='w-100'
                        locale={viVN}
                        value={ngay}
                        format={[SystemConfig.FORMAT_DATE, SystemConfig.FORMAT_DATE]}
                        onChange={(e) => { setngay(e) }}>
                    </RangePicker>
                </div>
            </div>
            <div className="row">
                <div className="ml-auto d-flex flex-direction-row align-items-center mt-2">
                    <span>Hiển thị</span>
                    <div className="col-auto">
                        <Select
                            id="promotion-select-chrtlst"
                            style={{ width: 200 }}
                            value={isChart}
                            onChange={(value) => {
                                setIsChart(value);
                            }}>
                            <Option key={1} value={true}><i className="fas fa-chart-bar mr-1" />Biểu đồ</Option>
                            <Option key={2} value={false}><i className="fas fa-list mr-1" />Danh sách</Option>
                        </Select>
                    </div>
                    <div className="col-auto">
                        <Button className={BUTTON.THEME} onClick={() => { Reload() }}><i className="fas fa-chart-bar mr-1" />Thống kê</Button>
                    </div>
                </div>
            </div>
            {isChart ?
                <div className='row mt-2 d-flex justify-content-between'>
                        <div className='warrperStyleGroup ml-2 p-2' style={{width: 'calc(50% - 10px)'}}>
                            <label className='d-flex justify-content-center'>Biểu đồ theo số lượng khách hàng</label>
                            <DonutChart ref={_chartWithNumber} isValue ={false} />
                        </div>
                        <div className='warrperStyleGroup mr-2 p-2' style={{width: 'calc(50% - 10px)'}}>
                            <label className='d-flex justify-content-center'>Biểu đồ theo thực thu</label>
                            <DonutChart ref={_chartWithValue} isValue ={true} />
                        </div>
                </div>
            :
                <DanhSach ref={_report} ngay={ngay} filter={filter} siteid={site} listSelected={listSelected}/>
            }
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(NguoiGioiThieu);
