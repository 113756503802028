import 'antd/dist/antd.css';
import vi from "date-fns/locale/vi";
import * as React from 'react';
import { registerLocale } from "react-datepicker";
import { Route, Switch } from 'react-router';
// import Register from './screens/recept/screens/register';
// import Storage from './screens/recept/screens/storage';
// import NhapKhoa from './screens/recept/screens/nhapkhoa';
// import NhapVien from './screens/recept/screens/nhapvien';
// import XuatKhoa from './screens/recept/screens/xuatkhoa';
// import XuatVien from './screens/recept/screens/xuatvien';
import './commons/css/animation.css';
import './commons/css/dashboard1.css';
import './commons/css/jquery.toast.css';
import './commons/css/main.scss';
import './commons/css/morris.css';
import './commons/css/style.min.css';
import Authenticated from './components/authenticated';
import Layout from './components/layout';
import MDIContainer from './components/mdiContainer';
import KhaiBaoYTe from './screens/khaibao';
import Result from './screens/result';
import './custom.css';
import Login from './screens/login';
import UnknowPage from './screens/unknowPage';
import QMS from './screens/qms';

registerLocale("vi", vi);
export default () => (
    <Layout >
        <Switch>
            <Route exact path='/login' component={Login} />
            <Authenticated exact path='/' component={MDIContainer} />
            <Route exact path='/result' component={Result} />
            <Route path='/khaibaoyte' component={KhaiBaoYTe} />
            <Route exact path='/qms' component={QMS} />
            {/* <Authenticated exact path='/dang-ky' component={Register} />
            <Authenticated exact path='/phong-luu' component={Storage} />
            <Authenticated exact path='/nhap-khoa' component={NhapKhoa} />
            <Authenticated exact path='/nhap-vien' component={NhapVien} />
            <Authenticated exact path='/xuat-vien' component={XuatVien} />
            <Authenticated exact path='/xuat-khoa' component={XuatKhoa} /> */}
            <Authenticated component={UnknowPage} />
        </Switch>
    </Layout>
);
