import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Button, DatePicker, Pagination, Table, Select, Tooltip, Radio } from 'antd';
import * as Actions from '../../../libs/actions';
import { connect } from 'react-redux';
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Statistic from '../../../services/statisticService';
import * as SystemConfig from "../../../configure/systemConfig";

const { RangePicker } = DatePicker;
const { Option } = Select;

function ThongKeLichHen(props) {
    const _hiddenLink = useRef()
    const [date, setDate] = useState([moment().subtract(7, "days"), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [siteId, setSiteId] = useState(props.siteid);
    const [userCreate, setUserCreate] = useState(null);
    const [listUserCreate, setListUserCreate] = useState([]);
    const [status, setStatus] = useState(null);
    const [filterByAppointmentDate, setFilterByAppointmentDate] = useState(false);
    const [listStatus,] = useState([
        {
            value: 1,
            label: "Chờ",
        },
        {
            value: 2,
            label: "Đã đến",
        },
        {
            value: 3,
            label: "Quá ngày",
        },
    ]);

    const ThongKeLichHen = () => {
        Actions.setLoading(true);
        Statistic.ThongKeLichHen(date[0].format("yyyy/MM/DD"), date[1].format("yyyy/MM/DD"), status, userCreate, page, size, siteId, filterByAppointmentDate).then(res => {
            if (res.isSuccess) {
                setDataSource(res.data);
                setTotalRecord(res.totalRecords);
                Actions.setLoading(false);
            }
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    const ExportThongKeLichHen= () => {
        Actions.setLoading(true);
        Statistic.ExportThongKeLichHen(date[0].format("yyyy/MM/DD"), date[1].format("yyyy/MM/DD"), status, userCreate, siteId, filterByAppointmentDate).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = "DanhSachLichHen";
                _hiddenLink.current.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    useEffect(() => {
        ThongKeLichHen();
    }, [date, page, size, siteId, status, userCreate, filterByAppointmentDate])

    useEffect(() => {
        GetDanhSachNguoiTao();
    }, [userCreate, props.siteid]);

    useEffect(() => {
        setSiteId(props.siteid);
    }, [props.siteid])

    const GetDanhSachNguoiTao = () => {
        const isLayMau = false;
        Statistic.GetCreateUserList(siteId, isLayMau).then(res => {
            if (res.isSuccess) setListUserCreate(res.data);
            else setListUserCreate([]);
        }).catch(() => {})
    }

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
            width: 70
        },
        {
            title: "Ngày tạo",
            dataIndex: "dateCreate",
            key: "dateCreate",
            align: "center",
            width: 100,
            render: (day) => {
                return <span className={`${!filterByAppointmentDate && "font-bold"}`}>{day && moment(day).format(SystemConfig.FORMAT_TIME_DATE)}</span>
            }
        },
        {
            title: "Người tạo",
            dataIndex: "userCreate",
            key: "userCreate",
            align: "left",
            width: 110,
        },
        {
            title: "Mã khách hàng",
            dataIndex: "patientID",
            key: "patientID",
            align: "center",
            width: 100,
        },
        {
            title: "Tên khách hàng",
            dataIndex: "patientName",
            key: "patientName",
            align: "left",
            width: 130,
        },
        {
            title: "Ngày hẹn",
            dataIndex: "appointmentDate",
            key: "appointmentDate",
            align: "center",
            width: 100,
            render: (day) => {
                return <span className={`${filterByAppointmentDate && "font-bold"}`}>{day && moment(day).format(SystemConfig.FORMAT_TIME_DATE)}</span>
            }
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 90,
        },
        {
            title: "Tạo từ Hotline",
            dataIndex: "fromHotline",
            key: "fromHotline",
            align: "center",
            width: 100,
            render: (fromOngCoNoi) => {
                return <i className={`${fromOngCoNoi ? "fas fa-check-square" : "far fa-square"}`}></i>
            }
        },
        // {
        //     title: "Có sửa thông tin",
        //     dataIndex: "dateUpdate",
        //     key: "dateUpdate",
        //     align: "center",
        //     width: 100,
        //     render: (dateConTroiCapNhat) => {
        //         if (dateConTroiCapNhat !== null && dateConTroiCapNhat !== undefined && dateConTroiCapNhat !== "")
        //             return <Tooltip title={dateConTroiCapNhat}><i className="fas fa-check-square"/></Tooltip>
        //         else return;
        //     }
        // }
    ];
    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
            <div className='containerChilderWrapper'>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê lịch hẹn</div>
                <div className="d-flex justify-content-center my-1">
                    <div className="mr-2">
                        <Radio.Group onChange={() => setFilterByAppointmentDate(!filterByAppointmentDate)} value={filterByAppointmentDate}>
                            <Radio value={false}>Ngày tạo</Radio>
                            <Radio value={true}>Ngày hẹn</Radio>
                        </Radio.Group>
                    </div>
                    <div className="mr-2">
                        <RangePicker allowClear={false} format="DD-MM-YYYY" value={date} locale={viVN}
                            onChange={(value => setDate(value))}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Select onChange={(e) => setUserCreate(e)}
                            showSearch value={userCreate} className="w-100" placeholder="Chọn người tạo lịch"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                <Option ley="user-all" value={0}>Mọi người tạo lịch</Option>
                            {listUserCreate && listUserCreate.map((item, index) => {
                                return (
                                    <Option key={`user-${index}`} value={item.donvi}>{item.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className='col-md-2'>
                        <Select className="w-100" placeholder="Chọn trạng thái" onChange={(e) => setStatus(e)} value={status}>
                            <Option ley="status-all" value={0}>Mọi trạng thái</Option>
                            {listStatus && listStatus.map((item, index) => {
                                return (
                                    <Option key={`status-${index}`} value={item.value}>{item.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="d-flex">
                        <Button className="btn btn-color"
                            onClick={() => { ThongKeLichHen() }}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                        <Button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { ExportThongKeLichHen() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                    </div>
                </div>
                <div style={{ width: "100%", height: 'calc(100% - 120px)', marginTop: 20 }}>
                    <Table
                        scroll={{ y: 0, x: 'max-content' }}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        rowKey={(row, index) => index}
                        rowClassName={(row) => {
                            if (!row.arrived && row.status !== "Chờ") return "bg-warning"
                        }}
                    />
                </div>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                    total={totalRecord}
                    showTotal={total => `Tổng: ${total}`}
                    size={size}
                    current={page}
                    onChange={(page, size) => {
                        setPage(page);
                        setSize(size);
                    }}
                    onShowSizeChange={(page, size) => {
                        setPage(1);
                        setSize(size);
                    }}
                    showSizeChanger={true}
                />
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ThongKeLichHen)