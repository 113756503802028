import * as ApiCaller from '../libs/httpRequests';


const prefix = 'Common';

export function GetAllPeople(pageNumber =1, pageSize = 200)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dantoc?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetAllJob(pageNumber =1, pageSize = 100)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/nghenghiep?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function GetAllNation()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/quoctich`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetAllCity(pageNumber =1, pageSize = 100)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thanhthi?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetDistrictByCityId(id,pageNumber =1, pageSize = 100)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/quanhuyen?pageNumber=${pageNumber}&pageSize=${pageSize}&id=${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetWardByDistrictId(id, pageNumber =1, pageSize = 100)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/phuongxa?pageNumber=${pageNumber}&pageSize=${pageSize}&id=${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};