import React, { Fragment, useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from "react";
import { InboxOutlined } from '@ant-design/icons';
import { Upload, Checkbox, List, Popconfirm, notification, Modal } from 'antd';
import * as Actions from '../../../libs/actions';
import * as UploadFileService from '../../../services/UploadFileService';
import * as UrlConfig from '../../../configure/urlConfig';
import LayoutDetail from './Layouts/details';
import LayoutMediumIcons from './Layouts/mediumIcons';
import LayoutSmallIcons from './Layouts/smallIcons';
import MitaCamera from "../Camera/mitaCamera";
import Notify, { NotifyStatus } from '../../../components/notify';

const { Dragger } = Upload;
export default forwardRef((props, ref) => {
    const _hiddenLink = useRef();
    const _upload = useRef();
    const _notiRef = useRef();
    // Mã tính năng (xn, cdha, patient)
    const [featureName, setFeatureName] = useState(props.featureName ? props.featureName : '')
    // Mã component sử dụng (chẩn đoán hình ảnh, xét nghiệm)
    const [componentKey, setComponentKey] = useState('');
    // Id tham chiếu
    const [refId, setRefId] = useState('');
    // Tên mở rộng của file (extension name)
    const [fileExtentions, setFileExtentions] = useState('');
    /* 
    - False : Hiển thị icon copy đường dẫn, click vào icon đó sẽ copy vào clipboard
    - True : hiển thị icons: copy, delete, vùng upload, kéo thả văn bản, ghi đè 
    */
    const [isEdit, setIsEdit] = useState(props.isEdit ? props.isEdit : false);
    /* 
    - False : Ẩn icon copy đường dẫn, click vào icon đó sẽ copy vào clipboard
    - True : hiển thị icons: copy
    Default cho = true để không ảnh hưởng những cái trước đó đã làm
    */
    const [showCopy, setShowCopy] = useState(props.showCopy ?? true);
    /* 
    - False : Ẩn icon checkbox chọn
    - True : hiển thị checkbox chọn
    */
    const [showSelect, setShowSelect] = useState(props.showSelect ?? false);
    /* 
    - False : Ẩn icon checkbox chọn
    - True : hiển thị checkbox chọn
    */
    const [showPreview, setShowPreview] = useState(props.showPreview ?? false);
    /*
    - Details
    - MediumIcons (không có icon delete)
    - SmallIcons
    */
    const [layout, setLayout] = useState(props.layout ?? 'Details');
    // Hiển thị add-in camera
    const [visibleCamera, setVisibleCamera] = useState(props.camera ?? false);
    // File types that can be accepted
    const [listExtentionAccept, setListExtentionAccept] = useState(props.extention ?? '');
    const [fileList, setFileList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrlBlob, setFileUrlBlob] = useState('');
    const [overwrite, setOverwrite] = useState(false);
    // Visible of Modal 
    const [visiblePreviewFile, setVisiblePreviewFile] = useState(false);
    const [urlPreview, setUrlPreview] = useState('');

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
        GetListFile: () => {
            GetListFile();
        }
    }));

    useEffect(() => {
        const close = (e) => {
            if(e.key === 'Escape'){
                setVisiblePreviewFile(false);
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    },[])

    useEffect(() => {
        setComponentKey(props.componentKey);
        setRefId(props.refId);
        setFileExtentions(props.fileExtentions ?? '');
    }, [props])   

    useEffect(() => {
        // Khi mở component thì uncheck cho phép ghi đè
        setOverwrite(false);
        if(componentKey !== '')
            GetListFile();
    }, [componentKey, refId])
    
    const GetListFile = () => {
        let filterModel = {ComponentKey: componentKey, RefId: refId, fileExtentions: fileExtentions }
        UploadFileService.GetListAsync(filterModel).then(res => {
            if (res.isSuccess) {
                setFileList(res.data);
                forceUpdate();
            } else {
                _notiRef && _notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
            }
        }).catch((res) => {
            _notiRef && _notiRef.current.Show(res.error.messageText, NotifyStatus.Error);
        })
    }

    const handleFileChange = (e) => {
        e.fileList.forEach(file => {
            if (file.status == "done") {
                UploadFile([file]);
                _upload.current.fileList.shift();
            }
        });
    }

    const UploadFile = (listFile) => {
        if (listFile == null || listFile==undefined || listFile.length == 0) return;
        Actions.setLoading(true);
        let formData = new FormData();
        formData.append('featureName', featureName);
        formData.append('componentKey', componentKey);
        formData.append('overwrite', overwrite);
        formData.append('refId', refId);
        formData.append('extentionAccept',listExtentionAccept)
        listFile.forEach(element => {
            formData.append('files', element.originFileObj);
        });
        UploadFileService.CreateUploadFileAsync(formData).then(res => {
            if (res.isSuccess) {
                Actions.setLoading(false);
                _notiRef && _notiRef.current.Show("Tải tệp tin thành công." , NotifyStatus.Success);
                setOverwrite(false);
                GetListFile();
            } else {
                Actions.setLoading(false);
                _notiRef && _notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
            }
        }).catch((res) => {
            _notiRef && _notiRef.current.Show(res.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const onDeleteFileClickHandle = (record) => {
        Actions.setLoading(true);
        UploadFileService.DeleteFileAsync(record.id).then(res => {
            if (res.isSuccess) {
                _notiRef && _notiRef.current.Show("Xóa file thành công" , NotifyStatus.Success);
                Actions.setLoading(false);
                GetListFile();
            } else {
                _notiRef && _notiRef.current.Show("Không thể xóa file.", NotifyStatus.Warning);
                Actions.setLoading(false);
            }
        }).catch((res) => {
            _notiRef && _notiRef.current.Show(res.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const viewResultPdf = (data) => {
        Actions.setLoading(true);
        UploadFileService.DownloadFileAsync(data.id)
            .then((response) => {
                let res = response.clone();
                res.json().then((t) => {
                    if (!t.isSuccess) 
                        notification.error({ message: t.error.messageText })
                }).catch(err => {
                    response.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        setSelectedFile(data);
                        setFileUrlBlob(url);
                        if(data.extensionName === "png" 
                            || data.extensionName === "pdf" 
                            || data.extensionName === "jpg"
                            || data.extensionName === "jpeg")
                        {
                            setVisiblePreviewFile(true);
                        } else {
                            _hiddenLink.current.href = url;
                            _hiddenLink.current.download = data.fileName;
                            _hiddenLink.current.click();
                        }
                    })
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    const downloadFile = () => {
        UploadFileService.DownloadFileAsync(selectedFile.id).then(result => {
            result.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = selectedFile.fileName;
                _hiddenLink.current.click();
            })
        }).catch(err => {
            _notiRef && _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
        })
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const renderSwitch = (layout) => {
        switch(layout) {
            case 'MediumIcons':
            return (
                <LayoutMediumIcons
                    fileList={fileList} 
                    isEdit={isEdit} 
                    showCopy={showCopy} 
                    showSelect={showSelect} 
                    onViewFile={(record) => { viewResultPdf(record); }} 
                    onDelete={(record) => { onDeleteFileClickHandle(record) }} 
                />
            );
            case 'SmallIcons' :
            return (
                <LayoutSmallIcons
                    fileList={fileList} 
                    isEdit={isEdit} 
                    showCopy={showCopy} 
                    showSelect={showSelect} 
                    showPreview={showPreview}
                    defaultSelectAll={props.defaultSelectAll}
                    onViewFile={(record) => { viewResultPdf(record); }} 
                    onDelete={(record) => { onDeleteFileClickHandle(record) }} 
                    changeSelectedIds={(ids) => { props.changeSelectedIds(ids); } }
                />
            );
            default:
            return (
                <LayoutDetail 
                    fileList={fileList} 
                    isEdit={isEdit} 
                    showCopy={showCopy} 
                    showSelect={showSelect} 
                    defaultSelectAll={props.defaultSelectAll}
                    onViewFile={(record) => { viewResultPdf(record); }} 
                    onDelete={(record) => {onDeleteFileClickHandle(record)}} 
                    changeSelectedIds={(ids) => { props.changeSelectedIds(ids); } }
                />
            );
        }
    }

    return (
        <Fragment>
        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
        <div className="col-12">
            <Notify ref={_notiRef} />
        </div>
        <div className="col-12">
            <div className="row my-1">
                <div className="col-12">
                { renderSwitch(layout) }
                </div>
            </div>
            {visibleCamera && 
            <>
                <div className="row">
                    <MitaCamera onSelectedDevice={(device) => { props.onSelectedDevice(device) }} onClose={() => GetListFile()} />
                </div>
            </>
            }
            {isEdit && 
            <>
                <div className="row my-1">
                    <div className="col-12">
                        <Dragger
                            ref={_upload}
                            customRequest={dummyRequest}
                            onChange={handleFileChange.bind(this)}
                            accept={listExtentionAccept}
                            showUploadList={false}
                            multiple
                        >
                            <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                            <p className="ant-upload-text">Kéo hoặc thả file vào đây</p>
                            <p className="ant-upload-hint">Xóa hoặc Upload file là sẽ cập nhật file ngay</p>
                        </Dragger>
                    </div>
                </div>
                <div className="row my-1">
                    <div className="col-12">
                        <Checkbox checked={overwrite} onChange={(e) => {setOverwrite(e.target.checked)}}>Cho phép ghi đè</Checkbox>
                    </div>
                </div>
            </>
            }
        </div>

        {visiblePreviewFile &&
            <Modal maskClosable={true}
                width={1000}
                visible={visiblePreviewFile}
                onCancel={() => {setVisiblePreviewFile(false);}}
                footer={null}>
                <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                    <div className="list-button-preview">
                        <button className="btn btn-secondary mr-2" onClick={() => {
                            downloadFile()
                        }}><i className="fa fa-download"></i></button>
                        <button className="btn btn-danger mr-2" onClick={() => {setVisiblePreviewFile(false);}}>Đóng</button>
                    </div>
                    <embed src={`${fileUrlBlob}#toolbar=0`}
                        style={{ height: '100%', width: '100%' }}
                    />
                </div>
            </Modal>
        }
        </Fragment>
    )
});
