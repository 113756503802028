import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Package';
const prefix1 = 'Appointment';
const prefix2 = 'Patient';
export function AllService(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/Services?name=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function TimKiemBN(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix2}/timkiembenhnhan?key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function LuuLichHen(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix1}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachHenKham(mabs, chinhanh, create) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/danhsachlichhen?mabs=${mabs}&chinhanh=${chinhanh}&create=${create}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function updateAppointment(id, data, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix1}/${id}?chinhanh=${chinhanh}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};



export function deleteAppointment(id, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix1}/${id}?chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ListUserCreate() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/ListUserCreate`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetAppoimentDetailByID(id, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/GetAppoimentDetailByID/${id}?chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ConfirmArrived(data, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix1}/ConfirmArrived?chinhanh=${chinhanh}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};



export function NgayHenGanNhat(mabn, laymau) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/NgayHenGanNhat?mabn=${mabn}&laymau=${laymau}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

//Lịch hẹn lấy mẫu máu
export function DanhSachHenLayMau(mactv, status, dateFrom, dateTo, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/danhsachlichhenlaymau?mactv=${mactv}&status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuLichHenLayMau(data, chinhanh = "") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix1}/taolichhenlaymau?chinhanh=${chinhanh}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ScheduleGetSampleByPatientID(id, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/chitietlichhenlaymau?id=${id}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListOrderById(id, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/GetListOrderById?id=${id}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CapNhatChiTietLichHenLayMau(id, data, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix1}/capnhatchitietlichhenlaymau?id=${id}&chinhanh=${chinhanh}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateLichHenLayMau(id, event, data, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix1}/capnhatlichhenlaymau?id=${id}&type=${event}&chinhanh=${chinhanh}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteLichHenLayMau(id, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix1}/xoalichhenlaymau?id=${id}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function uploadHinhAnhMau(data, appointmentid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostWithFile(`${prefix1}/uploadhinhanhmau/${appointmentid}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteHinhAnhMau(id) {

    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/deletehinhanhmau?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function GetListGiaLayMau(isDelete = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/GetListGiaLayMau?isDelete=${isDelete}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreateGiaLayMau(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix1}/CreateGiaLayMau`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateGiaLayMau(data, id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix1}/UpdateGiaLayMau/${id}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateIsDeleteGiaLayMau(id, isDelete) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix1}/UpdateIsDeleteGiaLayMau/${id}?isDelete=${isDelete}`, {}).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function InsertLichDangKy(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix1}/InsertLichDangKy`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateLichDangKy(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix1}/UpdateLichDangKy`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteLichDangKy(autoid, isdeleted) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix1}/DeleteLichDangKy?autoid=${autoid}&isdeleted=${isdeleted}`, {}).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachLichDangKyBenhNhanTheoNgay(mactv, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/DanhSachLichDangKyBenhNhanTheoNgay?mactv=${mactv}&ngay=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachLichDangKyBenhNhanTheoRangeNgay(mactv, dateFrom, dateTo, chinhanh = "") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/DanhSachLichDangKyBenhNhanTheoRangeNgay?mactv=${mactv}&dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LichDangKyTheoId(autoid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/LichDangKyTheoId?autoid=${autoid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetVATPayconfirmByMaql(maql, ispaid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/GetVATPayconfirmByMaql?maql=${maql}&ispaid=${ispaid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetHinhThucTraKetQua(managermentId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/GetHinhThucTraKetQua?managermentId=${managermentId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getScheduleDetail(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix1}/ScheduleDetail`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteSchedule(idLichHen) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix1}/DeleteSchedule?id=${idLichHen}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};