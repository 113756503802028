import { notification, Popconfirm, Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as AdministrativeService from '../../../services/administrativeService';
import * as CommonService from '../../../services/commonService';
import * as PatientService from '../../../services/patientService';
import * as PrintService from '../../../services/printService';
import * as ChiDinhService from '../../../services/chiDinhService';
import * as ConfigService from '../../../services/configService';
import * as UsersService from '../../../services/usersService';
import Patients from '../components/patients';
import { isNullOrEmpty } from '../../../libs/util';

const { Option } = Select;
class patients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupList: false,
            page: 1,
            size: 10,
            key: '',
            visibleCLS: false,
            lstChiDinhBenhNhan: [],
            detailPatient: {},
            lstCity: [],
            lstDistrict: [],
            lstWard: [],
            ward: '',
            district: '',
            city: '',
            diachi: '',
            listSubPatient: [],
            listSite: [],
            isMultisite: false,

        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteid !== this.props.siteid) {
            this.loadListPatient()
        }
    }
    
    componentDidMount() {
        this.loadCity();
        this.GetListSite();
        this.loadListPatient()
        CommonService.DanhSachKhoaKham().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdepartments: result.data
                });
            }
        });
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        if (this.props.getRef){
            this.props.getRef(this)
        }
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    loadCity(pageNumber = 1, pageSize = 1000) {
        AdministrativeService.GetAllCity(pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstCity: result.data,

                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thành phố bị lỗi' })
        })
    }
    loadDistrict(id, pageNumber, pageSize) {
        AdministrativeService.GetDistrictByCityId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstDistrict: result.data,
                    lstWard: []
                });
            }
            else {
                this.form.setFieldsValue({
                    district: null,
                    ward: null
                });
                this.setState({
                    lstDistrict: [],
                    lstWard: [],
                    district: null,
                    ward: null
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ quận huyện thị bị lỗi' })
        })
    }
    loadWard(id, pageNumber, pageSize) {

        AdministrativeService.GetWardByDistrictId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstWard: result.data,
                });
            }
            else {
                this.form.setFieldsValue({
                    ward: null
                });
                this.setState({
                    loadWard: [],
                    ward: null
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ phường/xã thị bị lỗi' })
        })
    }
    loadListPatient(key = '', page = 1, size = 10, dateFrom = moment().format('YYYY/MM/DD'), dateTo = moment().format('YYYY/MM/DD')) {
        CommonService.DanhSachBenhNhanDaDangKy(key, page, size, dateFrom, dateTo, this.props.siteid.toString()).then(result => {
            if (result.isSuccess) {
                let dataSource = result.data.map(function (e, index) {
                    let o = Object.assign({}, e);
                    o.key = index;
                    return o;
                })
                this.setState({
                    lstPatient: dataSource,
                    managementID: result.data.managementID,
                    dateIn: result.data.dateIn,
                    totalRecords: result.totalRecords,
                })

            }
        }).catch(err => {
            notification.error({ message: err })
        })
    }
    deleteRow(row) {

        ChiDinhService.XoaChiDinh(row.packageId, row.chinhanh).then(result => {
            if (result.isSuccess) {
                this.loadListPatient('', this.patient.state.currentPage, 10, this.patient.state.ngay[0].format('YYYY/MM/DD'), this.patient.state.ngay[1].format('YYYY/MM/DD'));
                notification.success({ message: "Xóa thành công" })
            } else {
                notification.warning({ message: result.err.msgString })
            }
        }).catch(err => {
            notification.error({ message: err })
        })
    }
    printRegister(row) {

        PrintService.PrintRecepRecipt(row.managementID)
        .then((result) => {
            if (isNullOrEmpty(result.item)) {
                Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc các dịch vụ đã được thực hiện, lấy mẫu...");
                return;
            } else Actions.DoPrint(result.item)
        })
    }

    printchidinh(row) {

        PrintService.PrintConclusionTiepTan(row.patientID, row.managementID, moment(row.dateIn).format("YYYY-MM-DD"), true).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    deleteRecord(row) {
        Actions.setLoading(true);
        PatientService.cancelExam(row).then((respone) => {
            if (respone.isSuccess) {
                this.patient.updateDataTable(this.state.key, this.state.page, this.state.size)
            } else {
                Actions.openMessageDialog("lỗi Xóa dữ liệu", `không thể xóa đặng ký`);
            }
            Actions.setLoading(false);
        })

    }
    render() {
        const columns = [
            {
                title: 'Họ tên',
                align: 'left',

                render: (row) => {
                    return (
                        <div>
                            <span><strong>{row.patientName} - {row.patientID}</strong></span><br />
                            <span>{row.patientID}-{row.year}</span>
                        </div>
                    )
                }
            },

            {
                title: 'Ngày',
                dataIndex: 'dateIn',
                key: 'dateIn',
                width: 100,
                render: (value) => {
                    return <span>{moment(value, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm').toString()}</span>;
                },

            },

        ];
        const columnChild = [
            {
                title: 'Mã dịch vụ',
                dataIndex: 'code',
                key: 'code',
                width: 100,
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: 120,
            },
            {
                title: 'Người tạo',
                dataIndex: 'doctorName',
                key: 'doctorName',
                width: 250,
            },
            {
                title: '',
                width: 50,
                align: 'center',
                render: (row) => {
                    return (

                        <Popconfirm title="Xác nhận xóa dịch vụ này?"
                            disabled={row.status !== 'Chưa đóng tiền'}
                            onConfirm={() => { this.deleteRow(row) }} okText="Đồng ý" cancelText="Hủy">
                            <button disabled={row.status !== 'Chưa đóng tiền'} className="btn btn-danger"><i className="fa fa-trash"></i></button>
                        </Popconfirm>

                    )
                },
            }
        ]
        return (
            <React.Fragment>
                <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                    <h4 style={{ marginTop: 5, textAlign: 'center', marginRight: '10px' }}>DANH SÁCH KHÁM</h4>
                </div>
                <Patients
                    chinhanh={this.props.siteid}
                    ref={((c) => this.patient = c)}
                    column={columns}
                    columnChild={columnChild}
                    dataSource={this.state.lstPatient}
                    totalRecords={this.state.totalRecords}
                    changePage={this.loadListPatient.bind(this)}
                    SetData={(e) => { this.setState({ listSubPatient: e.listSubPatient }) }}
                />
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};



export default connect(mapStateToProps)(patients)