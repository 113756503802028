import React, {  useEffect, useState, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Table, Pagination, Select, Spin } from 'antd';
import * as ReportServices from '../../../../services/ReportService';
import Notify, { NotifyStatus } from '../../../../components/notify';

export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    loadData: () => {
      resetToFirstPage();
    },
  }));
  //#region khai báo state
  const notiRef = useRef(null);
  const firstRender = useRef(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  //
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getData();
  }, [pageIndex]);

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
  }, []);
  //#endregion 
  //#region get list data cho bộ lọc
  const getData = () => {
    setLoading(true);
    let filterModel = {
      siteIds: props.siteIds ?? [],
      accountIds : props.accountId ?? [],
      coporationIds: props.coporationIds ?? [],
      dateFrom: props.dateFrom ?? '',
      dateTo: props.dateTo ?? '',
      nhomMas : props.nhomMas ?? [],
      loaiIds : props.loaiIds ?? [],
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      IsGetAll : false
    }
    ReportServices.SummaryPerformanceServiceList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      setLoading(false);
      forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else getData();
  }

  const columns = [
    {
        title: '#',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 30,
        render: (text, record, index) => {
            return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    // Table.SELECTION_COLUMN,
  { 
      title: 'Người thực hiện',
      dataIndex: 'accountName',
      key: 'accountName',
      align: 'left',
      width: 115,
      // render: (value, record) => {
      // }
    },
    {
        title: 'Số lượng dịch vụ thực hiện',
        dataIndex: 'quantityIndication',
        key: 'quantityIndication',
        align: 'center',
        width: 80,
    },
    {
      title: 'Số lượng dịch vụ chưa thanh toán',
      align: 'center',
      key: 'totalIndicationUnpay',
      width: 80,
      render: (value, record) => {
        return (
          <span>{ record.quantityIndication - record.quantityPay }</span>
        )
      }
    },
    {
        title: 'Số lượng dịch vụ đã thanh toán',
        dataIndex: 'quantityPay',
        key: 'quantityPay',
        align: 'center',
        width: 80,
    },
    {
        title: 'Số lượng bệnh nhân',
        dataIndex: 'numberPatient',
        key: 'numberPatient',
        align: 'center',
        width: 80,
    },
    {
      title: 'Tổng tiền dịch vụ thực hiện',
      dataIndex: 'totalIndication',
      key: 'totalIndication',
      align: 'right',
      width: 80,
      render: (value, record) => {
          return (
            <span>{ Number(value).toLocaleString('en-US') }</span>
          )
      }
    },
    {
      title: 'Tổng tiền chưa thanh toán',
      key: 'totalUnPay',
      align: 'right',
      width: 80,
      render: (value, record) => {
          return (
            <span>{ Number(record.totalIndication - record.totalPay).toLocaleString('en-US') }</span>
          )
      }
    },
    {
      title: 'Tổng tiền đã thanh toán',
      dataIndex: 'totalPay',
      key: 'totalPay',
      align: 'right',
      width: 80,
      render: (value, record) => {
          return (
            <span>{ Number(value).toLocaleString('en-US') }</span>
          )
      }
    },
  ];
  //#endregion
  return (
    <React.Fragment>
      <Spin spinning={loading} className='spin-color'>
        <div className="list-page" >
            <div className="list-page-body">{/* body */}
              <div className="row">{/* notification */}
                <div className="col-md-12"><Notify ref={notiRef} /></div>
              </div>
              <div className="list-page-table mt-2">{/* Dashboard & [Chart | List] */}
                <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                  className="table-min-h-0 table-header-1row"
                  dataSource={listResult} 
                  columns={columns}
                  locale={{ emptyText: 'Không có dữ liệu' }} 
                  scroll={{ x: 1200, y: 0 }}
                  rowKey={(e) => e.accountId}
                  rowClassName={(record, rowIndex) => {
                  }}
                  pagination={false}
                  // onChange={onTableChange} 
                />
                <Pagination
                  current={pageIndex}
                  size={pageSize}
                  style={{ textAlignLast: "center", marginTop: "10px" }}
                  total={totalRecord}
                  showTotal={total => `Tổng: ${total}`}
                  onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                  onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                  showSizeChanger={true}
                />
              </div>
            </div>
        </div>
      </Spin>
    </React.Fragment>
  );
  
})