import { DatePicker, Form, notification, Table, Tabs, Pagination,Select, Tooltip } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as StatisticService from '../../../services/statisticService';
import Chart from './chart';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../services/configService';
import * as UsersService from '../../../services/usersService';
import * as SystemConfig from "../../../configure/systemConfig";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            currentPage: 1,
            totalRecords: 0,
            ngay: [moment().subtract(7, "days"), moment()],
            currentSize: 10,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
        };
    }
    componentDidMount() {
        // UsersService.CheckPremissionMultisite().then(result => {
        //     if (result.isSuccess) {
        //         this.setState({
        //             isMultisite: result.item
        //         });
        //     }
        // });
        // this.GetListSite();
        this.LoadThongKeDoanhSo();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadThongKeDoanhSo() {
        Actions.setLoading(true);

        StatisticService.LisStatistic(1, this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),this.state.chinhanh, this.state.currentPage, this.state.currentSize).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data,
                    totalRecords: result.totalRecords,
                });


            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportLisStatistic() {
        Actions.setLoading(true);
        StatisticService.ExportLisStatistic(1, this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),this.state.chinhanh)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDoanhSo";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    render() {
        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 150,
                render: (value) => {
                    return <span>{moment(value).format(SystemConfig.FORMAT_DATE_TIME)}</span>;
                },
            },
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
                width: 150
            },
            {
                title: 'Tên BN',
                dataIndex: 'tenbn',
                key: 'tenbn',
                width: 300
            },
            {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
                render: (text, record, index) => {
                    return <span>{record.onlyyear ? moment(text).format("YYYY") : moment(text).format(SystemConfig.FORMAT_DATE)}</span>;
                },
                width: 150
            },
            {
                title: 'Điện thoại',
                dataIndex: 'dienthoai',
                key: 'phone',
                width: 120
            },
            {
                title: 'CMND/CCCD',
                dataIndex: 'cmnd',
                key: 'cmnd',
                render: (text, record, index) => {
                    return <span>{text.toString() === "0" ? '' : text}</span>;
                },
                width: 120
            },
            {
                title: 'Mã DV',
                dataIndex: 'mavp',
                key: 'mavp',
                width: 90,
                render: (value) => <Tooltip title="Mã dịch vụ"><label>{value}</label></Tooltip>
            },
            {
                title: 'Mã nhanh',
                dataIndex: 'mavpmoi',
                key: 'mavpmoi',
                width: 90,
                render: (value) => <Tooltip title="Mã tìm kiếm nhanh"><label>{value}</label></Tooltip>
            },
            {
                title: 'Tên chỉ định',
                dataIndex: 'tenvp',
                key: 'tenvp',
                width: 350
            },

            {
                title: 'Tổng tiền',
                dataIndex: 'tongtien',
                key: 'tongtien',
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')} VNĐ</span>;
                },
                width: 150
            }
        ]
        return (
            <>
                <div className='containerChilderWrapper'>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <Tabs animated={true}
                        style={{ height: "100%" }}>
                        <Tabs.TabPane tab={"Biểu Đồ"} key="1" style={{ height: '100%' }}>
                            <Chart ref={(c) => this.chart = c}></Chart>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"Danh sách"} key="2">
                            <div style={{ height: '100%', overflow: 'auto' }}>
                                <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 5 }}>

                                    {/* {this.state.isMultisite === true &&
                                        <div><Form.Item>
                                            <Select
                                                style={{ width: '200px' }}
                                                showSearch
                                                allowClear={false}
                                                value={this.state.chinhanh}
                                                onChange={(e) => {
                                                    this.setState({ chinhanh: e })
                                                }}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option key={-1} value={''}>Chi nhánh</Option>
                                                {this.state.listSite.map((row, index) => {
                                                    return (
                                                        <Option key={index} value={row.id}>{row.ma}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item></div>
                                    } */}

                                    <div>
                                        <Form.Item>
                                            <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }} allowClear={false}
                                                format="DD-MM-YYYY" value={this.state.ngay}
                                                placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                                onChange={(value => this.setState({ ngay: value, currentPage: 1 }, () => this.LoadThongKeDoanhSo()))}
                                                renderExtraFooter={() =>
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.setState({ ngay: [moment().subtract(7, 'd'), moment(new Date())] })
                                                                setTimeout(() => {
                                                                    this.setState({ currentPage: 1 }, () => {
                                                                        this.LoadThongKeDoanhSo()
                                                                    })
                                                                }, 100)
                                                            }}>
                                                            7  Ngày
                                                        </div>
                                                        <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                            onClick={() => {

                                                                this.setState({ ngay: [moment().startOf('month'), moment(new Date())] })
                                                                setTimeout(() => {
                                                                    this.setState({ currentPage: 1 }, () => {
                                                                        this.LoadThongKeDoanhSo()
                                                                    })
                                                                }, 100)
                                                            }}>
                                                            Tháng hiện tại
                                                        </div>
                                                        <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.setState({ ngay: [moment().startOf('quarter'), moment(new Date())] })
                                                                setTimeout(() => {
                                                                    this.setState({ currentPage: 1 }, () => {
                                                                        this.LoadThongKeDoanhSo()
                                                                    })
                                                                }, 100)
                                                            }}>
                                                            Quý hiện tại
                                                        </div>
                                                        <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.setState({ ngay: [moment().startOf('year'), moment(new Date())] })
                                                                setTimeout(() => {
                                                                    this.setState({ currentPage: 1 }, () => {
                                                                        this.LoadThongKeDoanhSo()
                                                                    })
                                                                }, 100)
                                                            }}>
                                                            Năm hiện tại
                                                        </div>
                                                    </div>

                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <Form.Item>
                                        <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => {
                                            this.setState({ currentPage: 1 }, () => {
                                                this.LoadThongKeDoanhSo()
                                            })
                                        }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                    </Form.Item>
                                    <Form.Item>
                                        <button className="btn btn-color" onClick={() => { this.ExportLisStatistic() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                    </Form.Item>
                                </div>
                                <div className="mt-2" style={{ height: 'calc(100% - 110px)' }}>
                                    <Table dataSource={this.state.dataSource} scroll={{ x: 'max-content', y: 0 }}
                                        pagination={false}
                                        columns={columns}
                                        rowKey={(row, index) => index} />
                                </div>
                                <Pagination
                                    style={{ textAlignLast: "center", marginTop: "10px" }}
                                    total={this.state.totalRecords}
                                    showTotal={total => `Tổng: ${total}`}
                                    size={this.state.currentSize}
                                    current={this.state.currentPage}
                                    onChange={(page, size) => {
                                        this.setState({ currentPage: page, currentSize: size }, () => {
                                            this.LoadThongKeDoanhSo()
                                        })
                                    }}
                                    onShowSizeChange={(page, size) => this.setState({ currentPage: 1, currentSize: size }, () => {
                                        this.LoadThongKeDoanhSo()
                                    })}
                                    showSizeChanger={true}
                                />
                            </div>
                        </Tabs.TabPane>
                    </Tabs>

                </div>
            </>
        )
    }
}
