import { Checkbox, Form, Input, notification, Select, Table, Popconfirm, Modal } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as PrintService from '../../../../services/printService';
import * as MedicineService from '../../../../services/medicineService';
import * as EnterInfo from '../../../../services/enterInfoService';
import * as commonService from '../../../../services/commonService';
import ReceiptsDetails from './receiptDetails';
import BanLeEdit from './reciptbanle';
import Administrative from '../confirm/patientdetails';
import * as CommonService from '../../../../services/commonService';
import * as SystemConfig from "../../../../configure/systemConfig"; 

const { Option } = Select;
export default class PhieuXuat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstDon: [],
            lstdepartment: [],
            lstDoiTuong: [],
            mabn: '',
            id: '',
            maql: '',
            hoten: '',
            makhoa: '',
            madoituong: 0,
            tungay: moment(),
            denngay: moment(),
            visible: false,
            visibleBanle: false,
            ngay: [moment(), moment()],
            lstDetail: [],
            lstDetailBanLe: [],
            detail: {},
            selectedPatient: {},
            tongtienNew: 0,
        };
    }

    componentWillMount() {
        this.loadDoiTuong();
        this.loadPhongKham();
        this.reset();
    }

    componentDidMount() {
        this.searchToa();
    }
    SearchWhenChangeDate(date) {
        this.searchToa(date[0], date[1]);
    }
    reset() {
        this.setState({
            lstDon: [],
            lstdepartment: [],
            mabn: '',
            madoituong: 0,
            id: '',
            maql: '',
            hoten: '',
            makhoa: '',
            tungay: moment(),
            denngay: moment(),
            visible: false,
            visibleBanle: false,

        })
        this.loadPhongKham();
        this.loadDoiTuong();
        this.searchToa();
    }
    loadDetailPatient(mabn) {
        CommonService.GetPatientDetail(mabn).then(result => {
            if (result.isSuccess) {
                let selectedPatient = result.item;
                this.setState({ selectedPatient })
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }

        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
    }
    loadDoiTuong() {
        EnterInfo.GetNhomDoiTuong().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoiTuong: result.data
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu đối tượng bị lỗi' })
        })
    }

    searchToa(dateFrom, dateTo) {
        let tungay = this.state.tungay;
        let denngay = this.state.denngay;
        if (dateFrom) {
            tungay = dateFrom;
            this.setState({ tungay: moment(dateFrom) });
        }
        if (dateTo) {
            denngay = dateTo;
            this.setState({ denngay: moment(dateTo) });
        }
        if (tungay === null || tungay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian!' });
            return;
        }
        if (this.props.trangthai === '1') {
            MedicineService.SearchPhieuThuocDaXuat(this.state.hoten, moment(tungay).format('YYYY/MM/DD'), moment(denngay).format('YYYY/MM/DD')).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    data = data.filter(x => x.done === 2).map(x => ({ ...x, 'trangthai': 'Chưa thanh toán' }));
                    data = data.filter(x => x.isdeleted !== '1').map(x => ({ ...x, }));
                    this.setState({
                        lstDon: data,
                    })
                }
            }).catch(err => {
                notification.warning({ message: 'Tìm kiếm bị lỗi' })
            })
        }
        else if (this.props.trangthai === '2') {
            MedicineService.SearchPhieuThuocDaXuat(this.state.hoten, moment(tungay).format('YYYY/MM/DD'), moment(denngay).format('YYYY/MM/DD')).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    data = data.filter(x => x.done === 1).map(x => ({ ...x, 'trangthai': 'Đã thanh toán' }));
                    data = data.filter(x => x.isdeleted !== '1').map(x => ({ ...x, }));
                    this.setState({
                        lstDon: data,
                    })
                }
            }).catch(err => {
                notification.warning({ message: 'Tìm kiếm bị lỗi' })
            })
        }
        else if (this.props.trangthai === '') {
            MedicineService.SearchPhieuThuocDaXuat(this.state.hoten, moment(tungay).format('YYYY/MM/DD'), moment(denngay).format('YYYY/MM/DD')).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    data = data.filter(x => x.done === 1 || x.done === 2).map(x => ({ ...x, 'trangthai': x.done === 1 ? 'Đã thanh toán' : 'Chưa thanh toán' }));
                    data = data.filter(x => x.isdeleted !== '1').map(x => ({ ...x, }));
                    this.setState({
                        lstDon: data,
                    })
                }
            }).catch(err => {
                notification.warning({ message: 'Tìm kiếm bị lỗi' })
            })
        }
    }


    loadPhongKham(pageNumber, pageSize) {
        commonService.DanhSachKhoaKham().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstdepartment: result.data,

                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phòng khám bị lỗi' })
        })
    }

    onClose = e => {
        this.searchToa();
        this.setState({
            visible: false, maql: '', visibleBanle: false
        });
    };
    PrintMedicine(id, ngay) {
        PrintService.PrintMedicine(id, ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintUse(id, ngay, maql) {
        PrintService.PrintUse(id, ngay, maql).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintConclusion() {
        const data = {
            presciptionId: this.state.detail.id,
            isDuoc: true,
            serviceId: null,
        }
        Actions.setLoading(true);
        MedicineService.printDonThuoc(data).then(result => {
            if (result.isSuccess) {
                Actions.DoPrint(result.data);
            }
        }).catch(() => {
        }).finally(() => {
            Actions.setLoading(false);
        })
    }
    xoathuoc(row) {
        if (row.loai === '1')
            MedicineService.XoaToaTheoDon(row.id, moment(row.ngaychidinh).format('YYYY-MM-DD'), row.maql).then(result => {
                if (result.isSuccess) {
                    this.searchToa();
                    notification.success({ message: 'Xóa thành công!' });
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString)
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err)
            })
        else {
            MedicineService.XoaToaBanLe(row.id, moment(row.ngaylapphieu).format('YYYY-MM-DD')).then(result => {
                if (result.isSuccess) {
                    this.searchToa();
                    notification.success({ message: 'Xóa thành công!' });
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString)
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err)
            })
        }
    }
    loadDetailToa(ngay, id) {
        MedicineService.GetListChiTietHoaDon(ngay, id).then(result => {
            if (result.isSuccess) {
                let data = [];
                if (this.state.detail.done === 0) {
                    data = result.data.filter(x => x.soluong > 0);
                }
                else {
                    data = result.data.map(x => ({ ...x, soluong: x.soluongthuc })).filter(x => x.soluongthuc > 0);
                }
                let tongtien = 0;
                let index = 1;
                for (let item of data) {
                    tongtien += item.soluong * item.giathucthu;
                    item.key = item.ma;
                    item.idPhieu = item.idPhieu;
                    item.countNumber = index;
                    index++
                }
                let tongtienNew = tongtien;
                //this.newTable.setDataSource(data);
                this.setState({
                    lstDetail: JSON.parse(JSON.stringify(data)),
                    lstDetailNew: data,
                    hidden: false,
                    tongtienNew, tongtien,
                    ghichu: result.data[0].ghichu
                })
                if (data.length > 0) {
                    this.setState({
                        detailDefault: data[0],
                    })
                }
            }
        })
            .catch(err => {
                notification.warning({ message: 'Tải dữ liệu chi tiết đơn bị lỗi' })
            })
    }
    thongtinHD(ngay, id, page = 1, size = 10) {
        MedicineService.ThongTinHoaDon(ngay, id, page, size).then(result => {
            if (result.isSuccess) {
                let data = result.data.filter(x => x.soluong > 0);
                let tongtien = 0;
                let index = 1;
                for (let item of data) {
                    tongtien += item.soluong * item.giathucthu;
                    item.key = item.ma;
                    item.idPhieu = item.idPhieu;
                    item.countNumber = index;
                    index++
                };
                this.setState({
                    lstDetailBanLe: result.data,
                    tongtienNew: tongtien
                });
            }
        }).catch(err => {
        })
    }
    PrintConclusionBanLe(mabn, id, sotoa, ngay) {
        PrintService.PrintConclusionBanLe(mabn, id, Number(sotoa), ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintUseRetail(id, ngay) {
        PrintService.PrintUseRetail(id, ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    render() {
        let dataSource = this.state.lstDon;
        const columnsBS = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 35,
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                align: 'left',
                width: 150,
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',
                width: 50,
            },
            {
                title: 'Tên hợp chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                align: 'left',
                width: 100,
            },
            {
                title: 'Hãng sản xuất',
                dataIndex: 'hangsanxuat',
                key: 'hangsanxuat',
                width: 110,
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 110,
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 80,
            },
            {
                title: 'Cách dùng',
                dataIndex: 'cachdung',
                key: 'cachdung',
                width: 100,
            },
            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : '0'}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : '0'}</span>;
                },
            },
            // {
            //     title: <span style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>Kho</span>,
            //     dataIndex: 'tenkho',
            //     key: 'tenkho',
            //     align: 'left',
            // },
        ];
        const columnsBanLeDetail = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 50,
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
                width: 80,
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
                width: 150,
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',
                width: 50,
            },
            {
                title: 'Nước SX',
                dataIndex: 'tennuoc',
                key: 'datennuocng',
                align: 'center',
                width: 110,
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 80,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Cách dùng',
                dataIndex: 'cachdung',
                key: 'cachdung',
                width: 100,
            },
            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
        ];
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ display: 'none' }}>
                        <Select
                            style={{ width: 200, flex: 1 }}
                            showSearch
                            defaultValue='-----'
                            value={this.state.makhoa}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(key) => { this.setState({ makhoa: key }) }}>
                            <Option key={0} value=''>Phòng khoa</Option>
                            {this.state.lstdepartment.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}
                                    >{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div style={{ display: 'none' }}>
                        <Checkbox onChange={(e) => this.setState({ done: e.target.checked }, () => this.searchToa())}>Đã xác nhận</Checkbox>
                    </div>
                    <div className="table-responsive" style={{ height: 'calc(100vh - 300px)' }}>
                        <table className="table table-striped table-inverse table-hover table-bordered">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th style={{ minWidth: '75px' }}>Số toa</th>
                                    <th style={{ minWidth: '75px' }}>Mã BN</th>
                                    <th style={{ minWidth: '150px' }}>Họ tên</th>
                                    <th style={{ minWidth: '90px' }}>Ngày xuất</th>
                                    <th style={{ minWidth: '90px' }}>Tổng tiền</th>
                                    <th style={{ minWidth: '120px' }}>Trạng thái</th>
                                    <th style={{ minWidth: '80px' }}>Loại</th>
                                    <th style={{ minWidth: '50px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSource && dataSource.map((row, index) => (
                                    <tr key={index} style={{ cursor: 'pointer' }} onClick={() => {
                                        if (row.loai === '1') {
                                            this.setState({ detail: row, visible: true });
                                            if (row.done === 1) {
                                                this.loadDetailToa(row.ngaychidinh, row.id);
                                                this.loadDetailPatient(row.mabn);
                                            }
                                        }
                                        else {
                                            this.setState({ detail: row, visibleBanle: true });
                                            if (row.done === 1) {
                                                this.thongtinHD(row.ngaychidinh, row.id);
                                                this.loadDetailPatient(row.mabn);
                                            }
                                        }
                                    }}>
                                        <td>{row.sotoa === '' ? '#' : row.sotoa}</td>
                                        <td>{row.mabn}</td>
                                        <td>{row.hoten}</td>
                                        <td>{moment(row.ngaychidinh).format(SystemConfig.FORMAT_DATE)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number((row.sotienthucthu * ((100 - row.discount) / 100)).toFixed(0)).toLocaleString('en-US')}</td>
                                        <td>{row.trangthai}</td>
                                        <td>{row.loai === '1' ? 'Theo đơn' : 'Bán lẻ'}</td>
                                        <td>{
                                            row.trangthai !== 'Đã thanh toán' ?
                                                <Popconfirm
                                                    disabled={row.trangthai === 'Đã thanh toán'}
                                                    onClick={(e) => e.stopPropagation()}
                                                    title="Bạn có chắc muốn xóa đơn thuốc này?"
                                                    onConfirm={(e) => { e.stopPropagation(); this.xoathuoc(row) }}
                                                    onCancel={(e) => e.stopPropagation()}
                                                    okText="Đồng ý" cancelText="Hủy">
                                                    <div style={{ textAlign: 'center' }}><button className="btn btn-danger btn-sm"><i className="fa fa-trash-alt" /></button></div>
                                                </Popconfirm>
                                                :
                                                ""}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {this.state.visible && this.state.detail.done !== 1 &&
                        <Modal maskClosable={false}
                            destroyOnClose={true}
                            width={1400}
                            visible={this.state.visible}
                            placement="top"
                            closable={false}
                            onClose={() => this.setState({ visible: false })}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            {this.state.detail.id !== '' &&
                                <ReceiptsDetails detail={this.state.detail} ngay={this.state.detail.ngaychidinh} mabn={this.state.detail.mabn}
                                    done={this.state.detail.done} id={this.state.detail.id}
                                    maql={this.state.detail.maql}
                                    onClose={this.onClose}
                                ></ReceiptsDetails>
                            }
                        </Modal>
                    }
                    {this.state.visible && this.state.detail.done === 1 ?
                        <Modal maskClosable={false}
                            visible={this.state.visible}
                            width={1400}
                            footer={null}
                        >
                            {this.state.detail.id !== '' &&
                                <div>
                                    <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h3>Phiếu xuất thuốc</h3>
                                        <h3 onClick={() => this.onClose()}><i className='far fa-times-circle' /></h3>
                                    </div>
                                    <Administrative loai={this.state.detail.loai === '1' ? 1 : 2} thanhtoan={true} item={this.state.selectedPatient}></Administrative>
                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                        <span>Tổng tiền: </span><strong style={{ marginLeft: 5 }}>{this.state.tongtienNew.toLocaleString('en-US') + ' VNĐ'}</strong>
                                    </div>
                                    <Table pagination={false}
                                        scroll={{ y: '65vh', x: 'max-content' }}
                                        dataSource={this.state.lstDetail}
                                        columns={columnsBS} rowKey={(e, index) => index} />
                                    <div className='custom-hr'/>
                                    <div style={{
                                        textAlign: 'center', marginTop: '20px', display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <button className="btn btn-outline-secondary" key="1" style={{ marginLeft: 5 }} onClick={() => { this.PrintConclusion(this.state.detail.mabn, this.state.detail.maql, this.state.detail.ngaychidinh, true) }} >
                                            In phiếu thuốc </button>
                                        <button className="btn btn-outline-secondary" key="2" style={{ marginLeft: 5 }} onClick={() => { this.PrintUse(this.state.detail.id, this.state.detail.ngaychidinh,this.state.detail.maql) }} >
                                            In cách dùng </button>
                                        <button className="btn btn-danger" style={{ marginLeft: 5 }}
                                            onClick={() => {
                                                this.onClose()
                                            }}>
                                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                            />Đóng</button>
                                    </div>
                                </div>
                            }
                        </Modal>
                        :
                        ""
                    }
                    {this.state.visibleBanle && this.state.detail.done === 2 &&
                        <Modal maskClosable={false}
                            width={1400}
                            visible={this.state.visibleBanle}
                            okButtonProps={{ disabled: true, hidden: true }}
                            cancelButtonProps={{ disabled: false }}
                            footer={[null]}
                        >
                            <BanLeEdit ngay={this.state.detail.ngaychidinh} id={this.state.detail.id} mabn={this.state.detail.mabn} hoten={this.state.detail.hoten} sotoa={this.state.detail.sotoa}
                                onClose={this.onClose.bind(this)}
                            >
                            </BanLeEdit>
                        </Modal>
                    }
                    {this.state.visibleBanle && this.state.detail.done === 1 &&
                        <Modal maskClosable={false}
                            width={1400}
                            visible={this.state.visibleBanle}
                            okButtonProps={{ disabled: true, hidden: true }}
                            cancelButtonProps={{ disabled: false }}
                            footer={[null]}
                        >
                            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>Phiếu xuất thuốc</h3>
                                <h3 onClick={() => this.onClose()}><i className='far fa-times-circle' /></h3>
                            </div>
                            <div>
                                <Administrative loai={this.state.detail.loai === '1' ? 1 : 2} thanhtoan={true} item={this.state.selectedPatient}></Administrative>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <Form.Item label="ID phiếu:">
                                    <Input
                                        disabled
                                        value={this.state.detail.id} />
                                </Form.Item>
                            </div>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                <span>Tổng tiền: </span><strong style={{ marginLeft: 5 }}>{this.state.tongtienNew.toLocaleString('en-US') + ' VNĐ'}</strong>
                            </div>
                            <Table dataSource={this.state.lstDetailBanLe}
                                pagination={false}
                                scroll={{ x: 'max-content', y: 0 }}
                                columns={columnsBanLeDetail} rowKey={(e, index) => index} />
                            <div>
                                Ghi chú: {this.state.lstDetailBanLe.length > 0 ? this.state.lstDetailBanLe[0].ghichu : ''}
                            </div>
                            <div className='custom-hr'/>
                            <div style={{ textAlign: 'center', marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                                {(this.state.detail.done === 1) &&
                                    <div>
                                        <button className="btn btn-outline-secondary" style={{ marginLeft: "5px" }}
                                            onClick={() => { this.PrintConclusionBanLe(this.state.detail.mabn, this.state.detail.id, this.state.detail.sotoa, this.state.detail.ngaychidinh) }}>
                                            In phiếu thuốc</button>
                                        <button className="btn btn-outline-secondary" style={{ marginLeft: "5px" }}
                                            onClick={() => { this.PrintUseRetail(this.state.detail.id, this.state.detail.ngaychidinh) }}>
                                            In cách dùng</button>
                                    </div>
                                }
                                <button className="btn btn-danger" style={{ marginLeft: "5px" }} onClick={() => this.onClose()}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                </button>
                            </div>
                        </Modal>
                    }
                </Form>
            </React.Fragment>
        )
    }
}
