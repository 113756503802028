import React, { Component } from 'react'
import { Input, Form, notification, Select } from 'antd';
import * as VPService from '../services/VPService';

const { Option } = Select;
const initialState = {
    manhomvp: 0,
    tennhomvp: '',
    tenviettat: '',
    tenloaivp: '',
    maloaivp: ''
}
export default class CreateNewLoaiVP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            lstNhomVP: [],
            lstLoaiVP: []
        };
    }
    async CreateLoaiVP() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                id_nhom: this.state.manhomvp,
                viettatloai: this.state.tenviettat,
                tenloaivp: this.state.tenloaivp,
                ma: this.state.maloaivp
            }
            VPService.createLoaiVPByNhom(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.resetState();
            }).catch(err => {
                notification.warning({ message: 'Tạo thất bại!' });
            })
        } else {
        }
    }
    componentDidMount() {
        this.loadNhomVP();
    }

    loadNhomVP() {
        VPService.GetNhomVP().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstNhomVP: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm viện phí bị lỗi' });
        })

    }
    resetState() {
        this.form.setFieldsValue({
            manhomvp: null,
            tennhomvp: null,
            tenloaivp: null,
            tenviettat: null,
            maloaivp: null
        });
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ padding: "5px 20px" }}>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <h3>Thêm loại viện phí</h3>
                        </div>
                        <div style={{ marginBottom: 10, marginTop: 10, display: 'none' }}>
                            <Form.Item style={{ flex: 1 }} label="Mã nhóm viện phí:" name="mavp" >
                                <Input
                                    value={this.state.manhomvp}
                                    onChange={(e) => this.setState({ mavp: e.target.value })}
                                />
                            </Form.Item>
                        </div>
                        <div className="row no-gutters" style={{ marginBottom: 10, display: 'flex', justifyContent: 'center' }} >
                            <div className="col-md-12">
                                <Form.Item label="Nhóm viện phí:" name="tennhomvp"
                                    rules={[{ required: true, message: 'Yêu cầu chọn nhóm viện phí!' }]}>
                                    <Select
                                        placeholder="Chọn nhóm viện phí"
                                        value={this.state.manhomvp}
                                        onChange={(e) => {
                                            this.setState({ manhomvp: e });
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstNhomVP.map((row, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    value={row.value}
                                                >
                                                    {row.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10, justifyContent: 'center' }}>
                            <div className="col-md-12">
                                <Form.Item label="Tên loại:" name="tenloaivp"
                                    rules={[{ required: true, message: 'Yêu cầu nhập tên loại viện phí!' }]}>
                                    <Input
                                        placeholder="Tên loại viện phí"
                                        onChange={(e) => this.setState({ tenloaivp: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center' }} >
                            <div className="col-md-6">
                                <Form.Item label="Mã loại:" name="maloaivp"
                                    rules={[{ required: true, message: 'Yêu cầu nhập mã loại viện phí!' }]}>
                                    <Input
                                        placeholder="Mã loại viện phí"
                                        onChange={(e) => this.setState({ maloaivp: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Tên viết tắt:" name="tenviettat">
                                    <Input
                                        placeholder="Tên viết tắt"
                                        onChange={(e) => this.setState({ tenviettat: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success"
                                onClick={() => { this.CreateLoaiVP() }}>
                                <i className="fas fa-plus" style={{ marginRight: 5 }}></i>Thêm</button>
                            <button className="btn btn-warning" style={{ marginLeft: 10 }}
                                onClick={() => this.resetState()}><i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.props.onCancel();
                                    this.props.onReload();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
