import React, { useEffect, useCallback } from 'react';
import "./SIDCard.scss";

function SIDCard(props) {
    const handleUserKeyPress = useCallback(event => {
        const { key } = event;
        if (key > 0 && key <= props.data.length) {
            let foundSID = props.data[key - 1];
            if (foundSID !== undefined && props.onSelectSIDCard)
                props.onSelectSIDCard(foundSID);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const onSelectSIDCard = (info) => {
        if (props.onSelectSIDCard) props.onSelectSIDCard(info);

    }
    return (
        props.data && props.data.map((info, index) => {
            return (
                <div className={`mt-2 SIDCard ml-2`} key={`SIDCard-${index}`} onClick={() => onSelectSIDCard(info)}>
                    <div className="SIDSeq">{Number(index) + 1}</div>
                    <div className="SIDTitle">{info.sid}</div>
                    <hr />
                    <div>{info.patientID}</div>
                    <div><b>{info.patientName}</b> - {info.patientYOB}</div>
                </div>
            )
        })
    );
}

export default SIDCard;