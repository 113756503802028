import { DatePicker, Form, Input, Modal, notification, Pagination, Select, Table, Popconfirm, Tooltip } from 'antd';
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TaoPhieuChuyenKho from './phieuchuyen';
import * as Actions from '../../../../libs/actions';
import * as PrintService from '../../../../services/printService';
import * as WarehouseService from '../../../../services/WarehouseTransferService';
import * as XuatKhoService from '../../../../services/xuatkhoService';
import * as KhoService from '../../../../services/KhoService';
const { Option } = Select;
const { RangePicker } = DatePicker;
const initialState = {
    khoxuatID: '',
    khonhapID: '',
    key: '',
    tungay: moment(),
    denngay: moment(),
    ngayrange: [moment(), moment()],
    totalRecords: 0,
}
let task = null;
export default class PhieuXuatChuyenKhoThuoc extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstKhoXuat: [],
            lstKhoNhap: [],
            lstChuyenKho: [],
            lstDetail: [],
            visible: false,
            visibleDetail: false,
            makho: 0,
            currentPage: 1,
            currentSize: 10,
            lstKho: [],
            isUpdate: false,
            isPreview: false,
        };
    }
    componentDidMount() {
        this.loadKho();
        this.DanhSachChuyenKho();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }
    loadKho() {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstKho: result.data,
                })
            }

        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi!' })
        })
    }

    ThongTinPhieu(id, ngay) {
        XuatKhoService.ThongTinHoaDonChuyen(moment(ngay).format('YYYY-MM-DD'), id).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDetail: result.item.lstAddThuoc,
                    visible: true,
                    isPreview: true,
                }, () => this.Nhap && this.Nhap.setUpdateState(result.item))
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thông tin phiếu chuyển kho bị lỗi!' })
        })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    DanhSachChuyenKho() {
        WarehouseService.ListChuyenKhoByMaKho(this.state.key, moment(this.state.tungay).format('YYYY-MM-DD'),
            moment(this.state.denngay).format('YYYY-MM-DD'), this.state.makho, this.state.currentPage, this.state.currentSize).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        lstChuyenKho: result.data,
                        totalRecords: result.totalRecords,
                    });
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu danh sách chuyển kho bị lỗi!' })
            })
    }
    XoaDon(row) {
        XuatKhoService.deletechuyenkho(row.id, moment(row.ngayxuat).format("YYYY-MM-DD")).then(result => {
            if (result.isSuccess) {
                this.DanhSachChuyenKho();
                notification.success({ message: "Xóa thành công!" })
            }
            else {
                this.setState({
                    lstNhap: [],
                    totalRecords: 0
                });
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {
        })
    }

    // getUpdate(row) {
    //     XuatKhoService.ThongTinHoaDonChuyen(moment(row.ngay).format('YYYY-MM-DD'), row.id).then(result => {
    //         if (result.isSuccess) {
    //             this.setState({ visible: true })
    //             let data = result.item;
    //             data.idPhieu = row.id;
    //             for (let item of data.lstAddThuoc) {
    //                 if (item.nghh) {
    //                     item.nghh = moment(item.nghh).format("YYYY-MM-DD");
    //                 }
    //                 if (item.ngsx) {
    //                     item.ngsx = moment(item.ngsx).format("YYYY-MM-DD");
    //                 }
    //             }

    //             setTimeout(() => {
    //                 this.Nhap && this.Nhap.setData(result.item);
    //             }, 100);


    //         }
    //         else {
    //             this.setState({
    //                 lstNhap: [],
    //                 totalRecords: 0
    //             })
    //         }

    //     }).catch(err => {
    //     })
    // }
    handleCancel = e => {
        this.setState({
            visible: false,
            visibleDetail: false
        });
        this.DanhSachChuyenKho();
    };
    PrintPhieu(id, ngay) {
        PrintService.PrintPhieuChuyenKho(id, moment(ngay).format('YYYY-MM-DD')).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    render() {
        let { khoxuatID, khonhapID, ngay, id } = this.state;
        const columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                align: "center",
                render: (text, record, index) => (this.state.currentPage - 1) * this.state.currentSize + Number(index) + 1,
                width: 70,
            },
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                align: 'center',
                width: 120,
                render: (value) => {
                    return <span>{moment(value).format('DD-MM-YYYY')}</span>;
                }
            },
            {
                title: 'Số phiếu',
                dataIndex: 'sophieu',
                key: 'sophieu',
                align: 'left',
                width: 120,
                render: (sophieu, record) => {
                    return <a className="anchor-color" href='#'
                    onClick={() => {
                        this.ThongTinPhieu(record.id, moment(record.ngay))
                    }}>{sophieu}</a>
                }
            },
            {
                title: 'Kho xuất',
                dataIndex: 'tenkhoxuat',
                key: 'tenkhoxuat',
                align: 'left',
                width: 180,
            },
            {
                title: 'Kho nhập',
                dataIndex: 'tenkhonhap',
                key: 'tenkhonhap',
                align: 'left',
                width: 180,
            },
            {
                title: 'Lý do xuất chuyển',
                dataIndex: 'lydo',
                key: 'lydo',
                align: 'left',
                width: 400,
            },
            {
                title: 'Thao tác',
                align: 'center',
                render: (record) => {
                    return (
                        <div>
                            <button className="btn btn-color"
                                title="Thông tin phiếu chuyển kho!"
                                onClick={() => { this.ThongTinPhieu(record.id, moment(record.ngay)) }}>
                                <i className="fa fa-eye" style={{ paddingRight: 5 }} />Xem
                            </button>
                            {/* <button className="btn btn-color"
                                style={{ marginLeft: 10 }}
                                title="Xác nhận sửa hóa đơn!"
                                onClick={() => { this.getUpdate(record) }} >
                                <i className="fa fa-edit" style={{ paddingRight: 5 }} />Sửa
                            </button> */}
                            {/* <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.XoaDon(record) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: "10px" }}>
                                    <i className="fa fa-eraser" style={{ paddingRight: 5 }} />Xóa</button>
                            </Popconfirm> */}
                            <button className="btn btn-color" onClick={() => { this.PrintPhieu(record.id, moment(record.ngay)) }}
                                style={{ marginLeft: 10 }}><i className="fa fa-print"></i></button>
                        </div>
                    )
                }
            }
        ];
        let dataSource = this.state.lstChuyenKho.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        const columns1 = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 70,
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
                width: 90,
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                align: 'left',
                width: 220,
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'center',
                width: 90,
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 120,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá bán',
                dataIndex: 'giaban',
                key: 'giaban',
                align: 'right',
                width: 120,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
        ];
        let dataSource1 = this.state.lstDetail.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <>
                <Form ref={(c) => this.form = c} >
                    <div className='containerChilderWrapper' >
                        <div className='h3 text-center'>Chuyển kho</div>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="col-auto">
                                <Form.Item>
                                    <RangePicker format="DD-MM-YYYY" value={this.state.ngayrange} allowClear={false}
                                        locale={viVN} onChange={(value => this.setState({ ngayrange: value, tungay: value[0], denngay: value[1], currentPage: 1 }, () => { this.DanhSachChuyenKho() }))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-auto">
                                <Form.Item label="Kho chuyển:">
                                    <Select
                                        style={{ width: '200px' }}
                                        value={this.state.makho}
                                        onChange={(e) => { this.setState({ makho: e }) }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key={-1} value={0}>Tất cả kho</Option>
                                        {this.state.lstKho.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-auto">
                                <Form.Item>
                                    <Tooltip title={`${this.state.key === "" ? "" : "Nhập số phiếu, tên kho nhập hoặc lý do chuyển"}`}>
                                        <Input placeholder="Nhập số phiếu, tên kho nhập hoặc lý do chuyển"
                                            value={this.state.key}
                                            style={{ width: 400 }}
                                            onChange={(e) => {
                                                this.setState({ key: e.target.value });
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    this.setState({ currentPage: 1 }, () => { this.DanhSachChuyenKho() });
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </Form.Item>
                            </div>
                            <div className="col-auto">
                                <Form.Item>
                                    <button className="btn btn-color"
                                        onClick={() =>
                                            this.setState({ currentPage: 1 }, () => { this.DanhSachChuyenKho() })
                                        }><i style={{ marginRight: 5 }} className="fa fa-search" /> Tìm</button>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-left'>
                            <div className="col-auto">
                                <Form.Item>
                                    <button className="btn btn-color" onClick={() => {
                                        this.setState({ visible: true })
                                        if (this.Nhap) {
                                            this.Nhap.setCreateState()
                                        }
                                    }}
                                    > <i style={{ marginRight: 5 }}
                                        className="fa fa-plus" />Tạo mới</button>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='mt-2' style={{ width:'100%', height:'calc(100% - 145px)' }} >
                            <Table dataSource={dataSource} scroll={{ x: 'max-content', y: 0 }}
                                pagination={false}
                                columns={columns} rowKey={(e, index) => index} />
                            <Pagination
                                current={this.state.currentPage}
                                size={this.state.currentSize}
                                style={{ textAlignLast: "center", marginTop: "10px" }}
                                total={this.state.totalRecords}
                                showTotal={total => `Tổng: ${total}`}
                                onChange={(page, size) => { this.setState({ currentPage: page, currentSize: size }, () => { this.DanhSachChuyenKho() }) }}
                                onShowSizeChange={(page, size) => this.setState({ currentPage: 1, currentSize: size }, () => { this.DanhSachChuyenKho() })}
                                showSizeChanger={true}
                            />
                        </div>
                    </div>
                </Form>
                <Modal maskClosable={false}
                    width={1400}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <TaoPhieuChuyenKho ref={(c) => this.Nhap = c}
                        compprops={this.props.compprops}
                        onCancel={this.handleCancel}
                        khoxuat={khoxuatID} khonhap={khonhapID} done={1}
                        idPhieu={id} ngay={ngay}
                        isUpdate={this.state.isUpdate}
                        isPreview={this.state.isPreview}/>
                </Modal>
                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visibleDetail}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
                        <Form.Item label="ID phiếu:">
                            <Input
                                disabled
                                value={this.state.id} />
                        </Form.Item>
                    </div>
                    <Table dataSource={dataSource1}
                        scroll={{ x: 'max-content', y: 0 }}
                        pagination={false}
                        columns={columns1} rowKey={(e, index) => index} />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, alignItems: 'center' }}>
                        <button className="btn btn-danger" style={{ marginLeft: 5 }}
                            onClick={() => {
                                this.handleCancel()
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng
                        </button>
                    </div>
                </Modal>
            </>
        )
    }
}
