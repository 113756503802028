import { Form, Input, notification } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as PhongKhamService from '../services/PhongKhamService';


const initialState = {
    mapk: '',
    tenpk: '',
}
let timeOut = null;

export default class CreateRoom extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            makp: '',
            tenkp: '',
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        if (timeOut) {
            clearTimeout(timeOut);
        }
    }
    resetState() {
        timeOut = setTimeout(() => {
            this.form.resetFields();
        }, 20);
        this.form.setFieldsValue({
            makp: null,
            tenkp: null
        });
        this.setState({
            makp: '',
            tenkp: ''
        })
    }

    async createDepatement() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (this.state.mapk.length > 3) {
            notification.warning({ message: 'Mã phòng khám tối đa 3 ký tự!' });
            return;
        }
        if (numberError === 0) {
            const data = {
                makp: this.state.makp,
                tenkp: this.state.tenkp,
            }
            PhongKhamService.createNewDepartent(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                    this.resetState();
                }
                else {
                    notification.warning({ message: result.err.msgString })
                }
            }).catch(err => {
                notification.warning({ message: 'Tạo thất bại. Có lỗi xảy ra!' });
            })
        } else {
            this.validator.showMessages();
        }
    }



    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm khoa khám bệnh</h3>
                    </div>
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ display: 'flex', marginBottom: 10, marginTop: 10 }}>
                            <Form.Item style={{ flex: 1 }} label="Mã Khoa:" name="makp"
                                rules={[{ required: true, whitespace: true, message: 'Yêu cầu nhập mã khoa!' }]}>
                                <Input
                                    maxLength={3}
                                    value={this.state.mapk}
                                    onChange={(e) => this.setState({ makp: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} label="Tên Khoa:" name="tenkp"
                                rules={[{ required: true, whitespace: true, message: 'Yêu cầu nhập tên khoa!' }]}>
                                <Input
                                    value={this.state.tenpk}
                                    onChange={(e) => this.setState({ tenkp: e.target.value })}
                                />
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', marginTop: 20, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success" onClick={() => { this.createDepatement() }}>
                                <i style={{ marginRight: 5 }} className="fa fa-save" />Thêm
                            </button>
                            <button className="btn btn-warning" style={{ marginLeft: 10 }} onClick={() => this.resetState()}>
                                <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới
                            </button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    timeOut = setTimeout(() => {
                                        this.form.resetFields();
                                    }, 20);
                                    this.props.onCancel();
                                    this.props.onReload();
                                }}>
                                <i style={{ marginRight: 5 }} className="fas fa-sign-out-alt" />Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
