import { Button } from 'antd';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import AutocompletetableNoGroup from '../../../../components/autocompletetable/autocompletetableNoGroup';
import * as InHospitalService from '../../../../services/inPatientService';
const columnPatient = [
    {
        title: 'Mã BN',
        dataIndex: 'patientID',
        key: 'patientID',
        width: 80,
    },
    {
        title: 'Họ tên',
        dataIndex: 'patientName',
        key: 'patientName',
        width: 250,
    },
    {
        title: 'Giới tính',
        dataIndex: 'gender',
        key: 'gender',
        width: 80,
    },
    {
        title: 'Năm sinh',
        dataIndex: 'year',
        key: 'year',
        width: 100,
    },
    {
        title: 'Ngày vào',
        dataIndex: 'dateIn',
        key: 'dateIn',
        width: 120,
    },
    {
        title: 'Thẻ BHYT',
        dataIndex: 'cardNumber',
        key: 'cardNumber',
        width: 120,
    },
];
const NoiTru = forwardRef((props, ref) => {
    const [patient, setPatient] = useState({});
    const GetListPaymentInPatient = (data) => {
        return InHospitalService.GetListPaymentInPatient(data, 1, 20);
    }
    useImperativeHandle(ref, () => ({

        reset() {
            setPatient({});
        }

    }));
    const SelectPatient = (data) => {
        setPatient(data);
        if (props.GetData) {
            props.GetData(data)
        }
    }
    return (
        <React.Fragment>
            <div className="warrperStyleGroup">
                <div className="grouplabel">
                    Thông tin bệnh nhân
                </div>

                <div className="row">
                    <div className="col-md-6 py-1">
                        <AutocompletetableNoGroup
                            placeholder={"Nhập mã, họ tên bệnh nhân"}
                            width='100%'
                            tablewidth={500}
                            onSearch={GetListPaymentInPatient.bind(this)}
                            onSelect={SelectPatient.bind(this)}
                            headers={columnPatient}
                            keyValue={'patientID'}
                            value={patient.patientName ? `${patient.patientID}-${patient.patientName}` : ''}
                        />
                    </div>
                    {patient.patientName &&
                        <div className="col-md-6 py-1">
                            <div className="row">
                                <div className="col-auto">Ngày vào: {patient.dateIn}</div>
                                <div className="col-auto ml-auto"><Button className="btn btn-outline-secondary"
                                    onClick={() => {
                                        if (props.Reload) {
                                            props.Reload();
                                        }
                                    }}
                                ><i className="fa fa-sync-alt"></i></Button></div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
})

export default NoiTru;
