import { Button, DatePicker, Form, Pagination, Input, Select, Tabs,Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../../libs/actions';
import * as StatisticService from '../../../services/statisticService';
import * as ConfigService from '../../../services/configService';
import * as UsersService from '../../../services/usersService';
import * as SystemConfig from '../../../configure/systemConfig';
import './custom.css';

const { Option } = Select;
const { RangePicker } = DatePicker;
export default function ThongKeSinhNhat() {
    const _hiddenLink = useRef()
    const [date, setDate] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [key, setKey] = useState('');
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [filter, setFilter] = useState('1');
    const [isMultisite, setIsMultisite] = useState(false);

    // useEffect(() => {
    //     GetListSite();
    //     UsersService.CheckPremissionMultisite().then(result => {
    //         if (result.isSuccess) {
    //             setIsMultisite(result.item)
    //         }
    //     });
    // }, [])

    useEffect(() => {
        ThongKeDanhSachSinhNhat();
    }, [date, page, size]);


    function ThongKeDanhSachSinhNhat() {
        Actions.setLoading(true);
        StatisticService.ThongKeDanhSachSinhNhat(date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD'), page, size).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecord(result.totalRecords);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    function ExportThongKeDanhSachSinhNhat() {
        Actions.setLoading(true);
        StatisticService.ExportThongKeDanhSachSinhNhat(date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD'))
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeSinhNhat";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    const columns = [
        {
            title: 'Mã BN',
            dataIndex: 'patientID',
            key: 'patientID',
            width: 100
        },
        {
            title: 'Tên',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 180,
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            width: 180,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            width: 120
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 120
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'birthYear',
            key: 'birthYear',
            width: 150,
            render: text => moment(text).format(SystemConfig.FORMAT_DATE)
        }
    ];

    return (
        <>
        <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
        <div className='containerChilderWrapper'>
            <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê sinh nhật</div>
            <div className="row justify-content-center my-1">
                <div className="col-auto mb-2">
                    <Form.Item>
                        <RangePicker dropdownClassName='myCustomPicker' allowClear={false} format="MM" value={date} picker="month"
                            locale={viVN}
                            onChange={((value) => setDate(value))}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto mb-2">
                    <Form.Item>
                        <Button className="btn btn-color"
                            onClick={() => { ThongKeDanhSachSinhNhat() }}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />
                            Tìm
                        </Button>
                    </Form.Item>
                </div>
            </div>
            <div className="row no-gutters">
                <div className='col-md-6 mb-2'></div>
                <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                    <Form.Item>
                        <Button className="btn btn-color" onClick={() => { ExportThongKeDanhSachSinhNhat() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                    </Form.Item>
                </div>
            </div>
            <div style={{ marginBottom: '10px', height: 'calc(100% - 140px)' }}>
                <Table dataSource={dataSource} scroll={{ y: 0 }}
                    pagination={false}
                    columns={columns} rowKey={(e, index) => index}
                />
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                    total={totalRecord}
                    showTotal={total => `Tổng: ${total}`}
                    size={size}
                    current={page}
                    onChange={(page, size) => {
                        setPage(page);
                        setSize(size)
                    }}
                    onShowSizeChange={(page, size) => {
                        setPage(1);
                        setSize(size)
                    }
                    }
                    showSizeChanger={true}
                />
            </div>
        </div>
        </>
    )
}
