import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import AliasPasc from './aliaspasc';

const { TabPane } = Tabs;

function shortHand(props) {
    return (
        <div className='containerChilderWrapper'>
            <div className='h4 text-center mt-2 font-bold text-uppercase'>Danh mục từ ngữ viết tắt</div>
            <Tabs animated={true} style={{ height: '84vh' }}>
                <TabPane tab="kết luận CĐHA" key="1">
                    <div style={{ height: '100%', padding: 10, marginBottom: 10 }}>
                        <AliasPasc />
                    </div>
                </TabPane>
                <TabPane tab="Kết luận khám" key="2">
                    <div>Chưa xây dựng</div>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default shortHand;