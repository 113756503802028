import * as ApiCaller from '../libs/httpRequests';

const prefix = 'ConfirmOrder';

export function GetListTaiCho(key,dateFrom, dateTo,chinhanh,page=1,size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListTaiCho?key=${key}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListTaiNha(key="",dateFrom, dateTo,chinhanh,page=1,size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListTaiNha?key=${key}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListOrderTaiCho(maql, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListOrderTaiCho?maql=${maql}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XacNhanMau(maql,loai,ngaytiepdon) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/XacNhanMau?maql=${maql}&loai=${loai}&ngaytiepdon=${ngaytiepdon}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

/*2023.03.17: Có thể cái này không dùng nữa.
    sau 3 tháng mà không dùng nữa thì xóa nhé */
// export function SendToOTBBarcode(maql, siteid, sentOTBarcode) {
//     return new Promise((resolve, reject) => {
//         return ApiCaller.httpPost(`${prefix}/SendToOTBBarcode?maql=${maql}&siteid=${siteid}&sentOTBarcode=${sentOTBarcode}`).then(respone => {
//             return resolve(respone)
//         }).catch(err => {
//             return reject(err)
//         })
//     });
// };

export function XacNhanGuiMau(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/XacNhanGuiMau`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XacNhanTraMau(maql, siteid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/XacNhanTraMau?maql=${maql}&siteid=${siteid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XacNhanDaLayMau(maql, siteid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/XacNhanDaLayMau?maql=${maql}&siteid=${siteid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getManagermentIDBySID(SID) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetManagermentIDBySID?SID=${SID}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getPatientDetail(maql, siteid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetPatientDetail?maql=${maql}&site=${siteid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};