import React, { Fragment, useEffect, useState } from 'react';

function RenderDataInfo(props) {
    const [patient, setPatient] = useState(props.data ? props.data
        :
        [
            {
                label: "Nhập label",
                value: "Không có thông tin",
                column: 12,
            }
        ]
    );

    useEffect(() => {
        if (props.data !== null && props.data !== undefined) {
            setPatient(props.data);
        }
    }, [props.data]);

    return (
        <Fragment>
            <div className="mt-2">
                <div className="row">
                    {patient && patient.length > 0 &&
                        patient.map((info, index) => {
                            return (
                                <div className={`col-md-${info.column} d-flex flex-column`} key={index}>
                                    <label className="mita-title">{info.label}</label>
                                    <label className="mita-value">
                                        {info.value !== null && info.value !== undefined && info.value !== ""
                                            ? info.value
                                            : "Không có thông tin"
                                        }
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default RenderDataInfo;