import React ,{ useState, useRef, useEffect } from "react";
import { Input, notification, Select, Checkbox, Tooltip, Upload, Modal } from 'antd';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { ValidationCustom } from '../../commons/validationCustom';
import * as ManagegerService from '../../services/ManageService';
import * as ConfigService from '../../services/configService';
import * as Actions from '../../libs/actions';
import SimpleReactValidator from 'simple-react-validator';
import HeaderModal from "../../configureHIS/common/headerModal";
import { actions } from "../../stores/modals";

const { Option } = Select;
const { Dragger } = Upload;
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
});
export default (props) => {
    const validator = useRef(new SimpleReactValidator(ValidationCustom));

    const [lstUser, setLstUser] = useState([]);
    const [lstChinhanh, setLstChinhanh] = useState([]);
    const [lstHub, setLstHub] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [name, setName] = useState('');
    const [userid, setUserid] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [chinhanh, setChinhanh] = useState([]);
    const [hub, setHub] = useState('');
    const [worldfone, setWorldfone] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const [signPath, setSignPath] = useState('');
    const [isKyThuatVien, setIsKyThuatVien] = useState(props.isKyThuatVien);
    const [isAdmin, setIsAdmin] = useState(props.isAdmin);

    const [previewOpen, setPreviewOpen] = useState(false);

    useEffect(() => {
        GetListSite();
        GetListHubByMutilpleSize();
    },[])

    useEffect(() => {
        setName(props.hoten);
        setUserid(props.userName);
        setChinhanh(props.chinhanh);
        setWorldfone(props.worldfone);
        setSignPath(props.signPath)
        if(props.chinhanh && props.chinhanh != ''){
            GetListHubByMutilpleSize();
        }
    },[props])


    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setLstChinhanh(result.data);
            }
            else {
                setLstChinhanh([]);
                Actions.openMessageDialog('error', result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog('error', err);
        })
    }

    const GetListHubByMutilpleSize = () => {
        ConfigService.GetListHubByMutilpleSize(chinhanh).then(result => {
            if (result.isSuccess) {
                setLstHub(result.data);
            } else {
                setLstHub([])
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const CreateAccount = () => {
        let numberError = 0;
        if (numberError === 0) {
            let isPassword = props.hidePassword === true ? true : false;
            if (!isPassword) {
                if (password.length < 6) {
                    notification.warning({ message: 'Mật khẩu tối thiểu phải có 6 ký tự' });
                    return;
                }
                if (password !== confirmPassword) {
                    notification.warning({ message: 'Mật khẩu và xác thực mật khẩu không trùng khớp' });
                    return;
                }
            }    
            let data = {
                name: name,
                userid: userid,
                password: password,
                userName: userid,
                isPassword: isPassword,
                ID: props.selectedEditUserID,
                chinhanh: chinhanh,
                hub: hub.toString(),
                worldfone: worldfone,
                isKyThuatVien: isKyThuatVien,
                isAdmin: isAdmin,
                SignPath : signPath
            }
            if (validator.current.allValid()) {
                if (props.selectedEditUserID !== 0 && props.selectedEditUserID !== undefined) {
                    ManagegerService.UpdateAccount(data).then(object => {
                        if (object.isSuccess) {
                            notification.success({ message: 'Cập nhật tài khoản thành công' })
                            resetState();
                            props.onCancel();
                        }
                        else {
                            Actions.openMessageDialog('error', object.err.msgString.toString());
                        }
                    }).catch(err => {
                        Actions.openMessageDialog('error', err);
                    })
                } else {
                    ManagegerService.CreateAccount(data).then(object => {
                        if (object.isSuccess) {
                            notification.success({ message: 'Đăng ký tài khoản thành công' })
                            resetState();
                            props.onCancel();
                        }
                        else {
                            Actions.openMessageDialog('error', object.err.msgString.toString());
                        }
                    }).catch(err => {
                        Actions.openMessageDialog('error', err);
                    })
                }
            } else {
                validator.current.showMessages();
            }
        }
    }

    const resetState = () => {
        setName('');
        setUserid('');
        setPassword('');
        setConfirmPassword('');
        setSignPath('');
        setChinhanh([]);
    }

    const config = {
        name: 'file',
        multiple: false,
        accept: 'image/*',
        onChange(info) {
            console.log(info);
            if(info.file.size > 100000){
                notification.warning({ message: 'Dung lượng file không được quá 100Kb' });
                return
            }
            getBase64(info.file).then(result => {
                setSignPath(result);
            }).catch(err => {
                setSignPath('');
                console.log(err);
            })
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <React.Fragment>
            <HeaderModal title={(props.selectedEditUserID === 0) ? 'Tạo tài khoản mới' : 'Cập nhật tài khoản'} onClose={ () => { if (props.onCancel) props.onCancel(); }}/>
            <div className="row">
                <div className="col-12 mt-1">
                    <label className='required-field'>Họ tên</label>
                    <Input autocomplete="off"
                        disabled={props.hidePassword !== true}
                        required={name.length >= 6}
                        value={name}
                        onChange={(e) => setName(e.target.value)} />
                    {validator.current && validator.current.message("Họ tên", name, "required")}
                </div>
                <div className="col-12 mt-1">
                {/* 180323 :  Disable trường tài khoản khi cập nhật. Do cập nhật thì cũng có đc đâu ??? Ủa aloooo */}
                    <label className='required-field'>Tài khoản</label>
                    <Input
                        maxLength={10}
                        disabled={props.hidePassword !== true && props.selectedEditUserID !== 0}
                        autocomplete="off"
                        value={userid}
                        onChange={(e) => setUserid(e.target.value) }>
                    </Input>
                    {validator.current && validator.current.message("Tài khoản", userid, "required")}
                </div>
                <div className="col-12 mt-1">
                    <label className='required-field'>Chi nhánh</label>
                    <Input.Group className="d-flex justify-content-between align-items-center">
                        <Select
                            className="w-100 mr-1"
                            mode="multiple"
                            showSearch
                            onChange={(e) => {
                                setChinhanh(e);
                                setHub('');
                                GetListHubByMutilpleSize();
                            }}
                            value={chinhanh}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {lstChinhanh.map((row, index) => {
                                return (
                                    <Option key={index} value={row.id}>{row.ma}</Option>
                                )
                            })}
                        </Select>
                        <Tooltip title="Quản lý tất cả chi nhánh">
                            <Checkbox checked={isAdmin === true} value={isAdmin} onChange={() => setIsAdmin(!isAdmin)}></Checkbox>
                        </Tooltip>
                    </Input.Group>
                    {validator.current && validator.current.message("Chi nhánh", chinhanh, "required")}
                </div>
                <div className="col-12 mt-1">
                    <label>Hub</label>
                    <Select
                        autocomplete="off"
                        className="w-100"
                        showSearch
                        allowClear={true}
                        onChange={(e) => {
                            setHub(e)
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {lstHub.map((row, index) => {
                            return (
                                <Option key={index} value={row.id}>{row.hubname}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="col-12 mt-1" style={{ display: `${props.hidePassword === true ? 'none' : 'unset'}` }}>
                    <label>Mật khẩu</label>
                    <Input.Password
                        autocomplete="off"
                        value={password}
                        maxLength={50}
                        onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="col-12 mt-1" style={{ display: `${props.hidePassword === true ? 'none' : 'unset'}` }}>
                    <label>Xác nhận mật khẩu</label>
                    <Input.Password
                        autocomplete="off"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value) } />
                </div>
                <div className="col-12 mt-1">
                    <label>Tài khoản worldfone</label>
                        <Input
                            value={worldfone}
                            onChange={(e) => setWorldfone(e.target.value)} />
                </div>
                <div className="col-12 mt-2">
                        <Checkbox
                            checked={isKyThuatVien}
                            onChange={() => setIsKyThuatVien(!isKyThuatVien)} >
                            Tài khoản cộng tác viên (Kỹ thuật viên lấy máu tại nhà)
                        </Checkbox>
                </div>
                <div className="col-12 mt-1" >
                    <Tooltip title="Chữ ký số">
                        <Dragger {...config} beforeUpload={() => false} fileList={fileList} itemRender={null} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây.</p>
                            <p className="ant-upload-hint">
                                Chỉ chấp nhận .jpg .jpeg. File không quá 100Kb.
                            </p>
                        </Dragger>
                    </Tooltip>
                </div>
                {signPath && signPath !== '' &&
                    <div className="col-12 mt-2 d-flex justify-content-center">
                        <img style={{border:'1px solid',width: 150, height: 150}} src={signPath} alt='sign'/>
                    </div>
                }
                <div className="custom-hr"></div>
                <div className="col-12" style={{ display: 'block', textAlign: 'center' }}>
                    <button className="btn btn-color"
                        onClick={() => { CreateAccount() }}>
                        <i style={{ marginRight: 5 }} className="fa fa-save" />{(props.selectedEditUserID === 0) ? "Thêm" : "Cập nhật"}</button>
                    <button className="btn btn-secondary" style={{ marginLeft: 5 }}
                        onClick={() => { setSignPath('') }}>
                        <i className='fa fa-eraser mr-2'></i>Xóa chữ ký</button>
                    <button className="btn btn-danger" style={{ marginLeft: 5 }}
                        onClick={() => {resetState(); props.onCancel()}}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />
                        Đóng</button>
                </div>
            </div>

            <Modal visible={previewOpen}>
                <HeaderModal onClose={() => {setPreviewOpen(false)}}/>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </React.Fragment>
    )
};
