import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input, Table, notification, Pagination, DatePicker, Checkbox, Modal, Popconfirm, Select, Button, Steps, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as signalR from '@aspnet/signalr';
import * as Configure from '../../configure/urlConfig';
import * as Actions from '../../libs/actions';
import * as DistributeServices from "./distributeservices";
import moment from 'moment';
import { GetListCollaborators } from '../../services/commonService';
import * as AppointmentService from '../../services/appointmentService';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import AddOrder from '../lichhenlaymau/addOrder';
import * as CollaboratorsServices from "../nhomctv/nhomctvservices";
import DateTimePicker from 'react-datetime-picker';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import { connect } from 'react-redux';
import * as SystemConfig from "../../configure/systemConfig";
import { IsActive_FeatureLCTV } from '../../configure/featureConfig';

function Dieuphoi(props) {
    const { Option } = Select;
    const { Step } = Steps;
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [assigned, setAssigned] = useState(true);
    const [key, setKey] = useState("");
    const [date, setDate] = useState(moment());
    const [listDistribution, setListDistribution] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [info, setInfo] = useState([]);
    const [ngayhen, setNgayhen] = useState(new Date());
    const [diffAddr, setDiffAddr] = useState(false);
    const [newAddress, setNewAddress] = useState("");
    const [listCollabs, setListCollabs] = useState([]);
    const [collabsId, setCollabsId] = useState(null);
    const [listGiaLayMau, setListGiaLayMau] = useState([]);
    const [idGiaLayMau, setIdGiaLayMau] = useState(0);
    const [vatinvoice, setVatinvoice] = useState(false);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [collabsIdKey, setCollabsIdKey] = useState(0);
    const [registed, setRegisted] = useState(true);
    const [initialCTV, setInitialCTV] = useState("");
    const [listCollabsFilter, setListCollabsFilter] = useState([]);
    const [dataOrder, setDataOrder] = useState([]);
    const [visibleOrder, setVisibleOrder] = useState(false);
    const [collabsGroup, setCollabsGroup] = useState("all");
    const [listCollabsGroup, setListCollabsGroup] = useState([]);
    const [showMore, setShowMore] = useState('');
    const OrderRef = useRef();
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [isMultisite, setIsMultisite] = useState(false);
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        {
            title: 'Họ và tên',
            dataIndex: 'tenbn',
            key: 'tenbn',
            align: 'center',
            width: 240,
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'ngaysinh',
            key: 'ngaysinh',
            align: 'right',
            width: 90,
            render: (date, row) => row.onlyyear ? moment(date, 'DD/MM/YYYY HH:mm:ss').format('YYYY') : moment(date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')
        },
        {
            title: 'Giới tính',
            dataIndex: 'gioitinh',
            key: 'gioitinh',
            align: 'center',
            width: 80,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            align: 'center',
            width: 110,
            render: (phone) => {
                return (
                    phone &&
                    <div className="border border-success rounded text-success"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            navigator.clipboard.writeText(phone);
                            notification.info({ message: "Đã sao chép vào bộ nhớ tạm" })
                        }}>{phone}
                    </div>
                )
            }
        },
        {
            title: 'Ghi chú',
            dataIndex: 'ghichu',
            key: 'ghichu',
            align: 'left',
            width: 220,
            render: (note, row) => {
                var value = note.indexOf("\n") === 0 ? note.substring(1, note.length) : note;
                const maxLength = 100;
                return (
                    <div>
                        <span style={{ whiteSpace: "pre-wrap" }}>{showMore === row.id ? value : value.substring(0, maxLength)}</span>
                        {note.length > maxLength && <span value={row.id} style={{ color: "blue", cursor: "pointer" }}
                            onClick={(e) => showMore === row.id ? setShowMore('') : setShowMore(row.id)}>{showMore === row.id ? " Thu gọn" : "...Xem thêm"}</span>}
                    </div>
                )
            }
        },
        {
            title: 'Địa chỉ lấy mẫu',
            key: 'diachilaymau',
            align: 'left',
            width: 200,
            render: (row) => {
                return (
                    <span>{row.diachilaymau !== null && row.diachilaymau !== "" ? row.diachilaymau : row.diachi}</span>
                )
            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'ngayhen',
            key: 'ngayhen',
            align: 'right',
            width: 90,
            render: (schedule) => {
                return (
                    <span>{schedule && moment(schedule).year() !== 1 && moment(schedule).format("HH giờ mm")}</span>
                )
            }
        },
        {
            title: 'Cộng tác viên',
            dataIndex: 'tenctv',
            key: 'tenctv',
            align: 'center',
            width: 180,
            render: (ten, row) => Number(row.status) === 2 ? `${ten.toUpperCase()} báo bận` : ten
        },
        {
            title: "Thao tác",
            align: "center",
            render: (row) => {
                return (
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-color" onClick={() => {
                            setVisibleModal(true);
                            setInfo(row);
                            setNgayhen(row.ngayhen && moment(row.ngayhen).year() !== 1 ? moment(row.ngayhen).toDate() : new Date());
                            setDiffAddr(row.diachilaymau ? false : true)
                            setNewAddress(row.diachilaymau);
                            setIdGiaLayMau(row.idGiaLayMau ? row.idGiaLayMau : null);
                            setCollabsId(row.mactv ? row.mactv : null);
                            setVatinvoice(row.vatinvoice);
                            GetListOrderById(row.id, row.chinhanh);
                            row.mactv ? setInitialCTV(row.mactv) : setInitialCTV("");
                            row.mactv ? setRegisted(false) : setRegisted(true);
                        }}><i className={row.mactv ? "fas fa-eye" : "fa fa-plus"} style={{ marginRight: 5 }} />
                            {row.mactv ? "Xem chi tiết" : "Chọn cộng tác viên"}</button>
                        <Popconfirm title={`Xóa lịch hẹn của ${row.tenbn}?`} onConfirm={() => deleteAppointment(row.id, row.chinhanh)} okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}><i className="fas fa-trash" /></button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]
    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }
    useEffect(() => {
        getListDistribution();
    }, [assigned, collabsIdKey, currentPage, pageSize, chinhanh, props.siteid]);
    useEffect(() => {

        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item)
            }
            if (result.item == true) {
                GetListSite();
            }
        });
        setChinhanh(props.siteid)
        getListGiaLayMau();
        getListCollaboratorsGroup();
    }, []);
    useEffect(() => {
        setChinhanh(props.siteid);
        GetListCollaborators(1, 10, false, "all", chinhanh).then(res => {
            if (res.isSuccess)
                setListCollabsFilter(res.data);
        }).catch(() => { });
    }, [chinhanh, props.siteid])
    useEffect(() => {
        getListCollaborators();
    }, [registed, ngayhen, collabsGroup, props.siteid]);
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(Configure.BASE_NOTIFY_URL + "/notify")
            .build();

        connection.on("notify", data => {
            if (data === 'dieuphoi') {
                getListDistribution();
            }
        })
        connection.start();
    }, []);
    const getListDistribution = (page = currentPage, size = pageSize) => {
        Actions.setLoading(true);
        DistributeServices.getListDistribution(date.format('YYYY-MM-DD'), key, page, size, assigned, collabsIdKey, chinhanh).then(res => {
            if (res.isSuccess) {
                setListDistribution(res.data.reverse());
                setTotalRecords(res.totalRecords);
                setCurrentPage(page);
                setPageSize(size);
            }
            Actions.setLoading(false);
        }).catch(() => {
            Actions.setLoading(false);
        })
    }
    const getListCollaborators = (getAll = registed, group = collabsGroup) => {
        if (getAll && initialCTV === "") {
            var date = moment(ngayhen).format('YYYY/MM/DD HH:mm:ss');
            DistributeServices.GetListCollaborators(date, getAll, group, chinhanh).then(res => {
                if (res.isSuccess)
                    setListCollabs(res.data);
            }).catch(() => { })
        }
        else {
            GetListCollaborators(1, 10, false, group, chinhanh).then(res => {
                if (res.isSuccess)
                    setListCollabs(res.data);
            }).catch(() => { })
        }
    }
    const getListGiaLayMau = () => {
        AppointmentService.GetListGiaLayMau(true).then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    setIdGiaLayMau(result.data[0].id);
                }
                setListGiaLayMau(result.data);
            }
            else {
                notification.error({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            notification.error({ message: err });
        })
    }
    const saveAppointment = () => {
        if (validator.current.allValid()) {
            const data = {
                mabn: info.mabn.toString(),
                ngayhen: ngayhen,
                mactv: collabsId,
                // idGiaLayMau: idGiaLayMau,
                newAddress: newAddress,
                vatinvoice: vatinvoice,
            }
            const id = Number(info.id);
            Actions.setLoading(true);
            AppointmentService.CapNhatChiTietLichHenLayMau(id, data, info.chinhanh).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: 'Lưu thành công!' })
                    onClose();
                } else {
                    notification.warning({ message: res.err.msgString });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                console.log(err);
            })
        }
        else {
            validator.current.showMessages();
            forceUpdate();
        }
    }
    const onClose = () => {
        validator.current.hideMessages();
        setVisibleModal(false);
        setInfo([]);
        setNgayhen(new Date());
        setVatinvoice(false);
        setCollabsId(null);
        setIdGiaLayMau(null);
        getListDistribution();
        setCollabsGroup("all");
    }
    const GetListOrderById = (id, siteid) => {
        if (!siteid) {
            siteid = info.chinhanh
        }
        AppointmentService.GetListOrderById(id, siteid).then(result => {
            if (result.isSuccess) {
                setDataOrder(result.data)
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
    }
    const deleteAppointment = (id, chinhanh) => {
        AppointmentService.deleteLichHenLayMau(id, chinhanh).then((result) => {
            if (result.isSuccess) {
                notification.success({ message: "Đã xóa lịch hẹn thành công" });
                getListDistribution();
            } else {
                notification.error({ message: result.err.msgString })
            }
        }).catch(() => { })
    }
    const getListCollaboratorsGroup = () => {
        CollaboratorsServices.GetListGroup("", 1, -1).then(res => {
            if (res.isSuccess) {
                setListCollabsGroup(res.data);
            } else {
                notification.warning({ message: "Không thể lấy thông tin nhóm" })
            }
        }).catch(err => console.log(err))
    }
    return (
        <React.Fragment>
            <div className={'containerChilderWrapper'}>
                <div style={{ height: '100%' }}>
                    <div className='h4 text-center pt-2 font-bold text-uppercase'>Điều phối cộng tác viên</div>
                    <div className="row mt-2 mb-2">
                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                            {/* {isMultisite === true &&
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    allowClear={false}
                                    value={chinhanh}
                                    onChange={(e) => {
                                        setChinhanh(e);
                                        setCollabsIdKey(0);
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select>
                            } */}
                            <DatePicker allowClear={false} style={{ marginRight: 10 }} format={SystemConfig.FORMAT_DATE} locale={viVN} value={date} onChange={(e) => setDate(e)} />
                            <Input value={key} onChange={(e) => setKey(e.target.value)}
                                placeholder="Nhập tên bệnh nhân hoặc số điện thoại sau đó bấm Enter để tìm kiếm" style={{ width: "40%", marginRight: 10 }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") getListDistribution();
                                }}
                                suffix={<Checkbox checked={!assigned} onChange={(e) => { setAssigned(!e.target.checked); }}>Chưa chỉ định</Checkbox>} />
                            <Select
                                style={{ width: 250, marginRight: 10 }}
                                placeholder="Chọn cộng tác viên của nhóm"
                                value={collabsIdKey}
                                onChange={(e) => { setCollabsIdKey(e) }}>
                                <Option key={-1} value={0}>Tất cả cộng tác viên</Option>
                                {listCollabsFilter.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                            <button className="btn btn-color" onClick={() => { getListDistribution() }}> <i style={{ marginRight: 5 }} className="fa fa-refresh" />Tìm kiếm</button>
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px', height: 'calc(100% - 120px)' }}>
                        <Table
                            scroll={{ x: 'max-content', y: 0 }}
                            dataSource={listDistribution}
                            columns={columns}
                            pagination={false}
                            rowKey={(row) => `distr-${row.id}`}
                            rowClassName={(row) => row.status === 2 && "bg-warning text-white"}
                        />
                    </div>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setPageSize(size);
                            getListDistribution(page, size);
                        }}
                        onShowSizeChange={(page, size) =>
                            getListDistribution(page, size)
                        }
                        showSizeChanger={true}
                    />
                </div>
            </div>

            {visibleModal &&
                <Modal visible={visibleModal} maskClosable={true} zIndex={888}
                    onCancel={() => setVisibleModal(false)}
                    width={1000}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Lịch hẹn của {info.tenbn}</h3>
                    </div>
                    <Steps className="mb-4" progressDot>
                        <Step status={info.mactv ? "finish" : "process"} title="Chỉ định CTV" description={info.tenctv}></Step>
                        <Step status={info.giocheckin && moment(info.giocheckin).year() !== 1 ? "finish" : "process"} title="Check-in" description={info.giocheckin && moment(info.giocheckin).year() !== 1 && moment(info.giocheckin).format(SystemConfig.FORMAT_DATE_TIME)}></Step>
                        <Step status={info.gionhanmau && moment(info.gionhanmau).year() !== 1 ? "finish" : "process"} title="Lấy mẫu" description={info.gionhanmau && moment(info.gionhanmau).year() !== 1 && moment(info.gionhanmau).format(SystemConfig.FORMAT_DATE_TIME)}></Step>
                        <Step status={info.dalaymau === 1 ? "finish" : "process"} title="Bàn giao"></Step>
                    </Steps>
                    <div className="row content-container">
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: 100 }}>Mã BN:</span><strong className="ml-2">{info.mabn}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: 100 }}>Họ tên:</span><strong className="ml-2">{info.tenbn}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: 100 }}>{info.onlyyear ? "Năm sinh" : "Ngày sinh"}:</span><strong className="ml-2">{info.onlyyear ? moment(info.ngaysinh, "DD/MM/YYYY HH:mm:ss").format("YYYY") : moment(info.ngaysinh, "DD/MM/YYYY HH:mm:ss").format(SystemConfig.FORMAT_DATE)}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: 100 }}>Giới tính:</span><strong className="ml-2">{info.gioitinh}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: 100 }}>Điện thoại:</span><strong className="ml-2">{info.dienthoai}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: 100 }}>Email:</span><strong className="ml-2">{info.email}</strong>
                        </div>
                        <div className="col-md-12 my-1 d-flex">
                            <span style={{ width: 100 }}>Địa chỉ:</span><strong className="ml-2">{info.diachi}</strong>
                        </div>
                    </div>
                    <div className="row mt-2 content-container">
                        <div className="col-md-12">
                            <label>Ngày hẹn</label>
                            {/* <DatePicker
                                locale={viVN}
                                showTime
                                format={"HH giờ mm, DD/MM/YYYY"}
                                className="w-100"
                                allowClear={false}
                                disabledDate={(current) => { return current && current.valueOf() < Date.now() }}
                                onChange={(e) => setNgayhen(e)}
                                value={moment(ngayhen)} placeholder="Ngày/tháng/năm" /> */}
                            <DateTimePicker
                                className="w-100"
                                locale="vi"
                                calendarIcon={<Tooltip title="Mở lịch"><i className='fas fa-calendar-week customclearicon' /></Tooltip>}
                                clearIcon={<Tooltip title="Chọn ngày hiện tại"><i className='fas fa-broom customclearicon' /></Tooltip>}
                                format={'dd-MM-yyyy HH:mm'}
                                isClockOpen={false}
                                isCalendarOpen={false}
                                onChange={setNgayhen}
                                minDate={moment(ngayhen).year() > 1 ? moment(ngayhen).toDate() : moment().toDate()}
                                value={ngayhen ? ngayhen : new Date()}
                            />
                        </div>
                        <div className="col-md-12 mt-2">
                            <label>Địa chỉ lấy mẫu</label>&nbsp;<i style={{ color: "#ccc" }}>{diffAddr ? "[Bấm vào \"Giống địa chỉ liên hệ\" để nhập địa chỉ mới]" : "[Bấm vào \"Địa chỉ mới\" để chọn địa chỉ liên hệ]"}</i>
                            <Input
                                placeholder={diffAddr ? "" : "Nhập địa chỉ lấy mẫu"}
                                value={diffAddr ? "" : newAddress}
                                disabled={diffAddr}
                                prefix={<button className={diffAddr ? "btn btn-secondary btn-sm mr-2" : "btn btn-success btn-sm mr-2"}
                                    onClick={(e) => { e.preventDefault(); setDiffAddr(!diffAddr); setNewAddress(""); }}>{diffAddr ? "Giống địa chỉ liên hệ" : "Địa chỉ mới"}</button>}
                                onChange={(e) => setNewAddress(e.target.value)}
                            />
                        </div>
                        <div className="col-md-12 mt-2 d-flex ml-auto">
                            <Checkbox
                                checked={vatinvoice}
                                onChange={e => setVatinvoice(!vatinvoice)}
                            >Xuất hóa đơn đỏ</Checkbox>
                        </div>
                        <div className="col-md-6 mt-2">
                            <label>Nhóm CTV</label>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Chọn nhóm cộng tác viên"
                                value={collabsGroup}
                                onChange={(e) => { setCollabsGroup(e); setCollabsId(null) }}>
                                <Option key={-1} value="all">Tất cả</Option>
                                {listCollabsGroup.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.label}>{row.label}</Option>
                                    )
                                })}

                            </Select>
                            {validator.current.message("Nhóm cộng tác viên", collabsGroup, "selection")}
                        </div>
                        <div className="col-md-6 mt-2">
                            <label>
                                <Checkbox checked={!registed} onChange={() => { setRegisted(!registed); setInitialCTV(""); setCollabsId(null) }}></Checkbox>&nbsp;
                                Tất cả cộng tác viên
                                {IsActive_FeatureLCTV() && 
                                <Checkbox checked={registed} onChange={() => { setRegisted(!registed); setInitialCTV(""); setCollabsId(null) }}>&nbsp;
                                Cộng tác viên đã đăng ký lịch</Checkbox>
                                }
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                placeholder={collabsGroup !== "all" ? `Chọn cộng tác viên của nhóm: ${collabsGroup}` : "Chọn cộng tác viên"}
                                value={collabsId}
                                allowClear={true}
                                onChange={(e) => { setCollabsId(e) }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {listCollabs.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}

                            </Select>
                            {validator.current.message("Cộng tác viên", collabsId, "selection")}
                        </div>
                        {/* <div className="col-md-6 mt-2">
                            <label>Loại</label>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Chọn loại lấy mẫu"
                                allowClear={false}
                                onChange={(e) => { setIdGiaLayMau(e) }}
                                showSearch
                                value={idGiaLayMau}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {listGiaLayMau.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.name} ({row.dongia.toLocaleString('en-US')}đ)</Option>
                                    )
                                })}
                            </Select>
                            {validator.current.message("Loại", idGiaLayMau, "selection")}
                        </div> */}
                        <div className="col-12 mb-2">
                            <div className="border-register" style={{ background: '#fff' }}>
                                <div data-toggle="collapse" data-target="#chidinh" className="row no-gutters align-items-center">
                                    <div className="h4 font-weight-bold">Chỉ định ({!dataOrder ? 0 : dataOrder.reduce((tmp, cur) =>
                                        tmp + Number(cur.dongia), 0).toLocaleString('en-US')})</div>
                                    <Button className="btn btn-secondary ml-auto" onClick={e => {
                                        e.stopPropagation();
                                        setVisibleOrder(true);
                                    }}>Thêm/Sửa chỉ định</Button>
                                </div>
                                <div class="collapse" id="chidinh">
                                    <div className="table-responsive">
                                        <table class="table table-striped table-inverse table-hover">
                                            <thead class="thead-inverse">
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Mã</th>
                                                    <th>Tên</th>
                                                    <th>Giá</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataOrder && dataOrder.map((row, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.mavp}</td>
                                                        <td>{row.ten}</td>
                                                        <td>{row.dongia?.toLocaleString('en-US')}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center w-100 mt-4">
                        <button className="btn btn-success" onClick={() => { saveAppointment() }}><i className="mr-2 fa fa-save"></i>{info.mactv !== null & info.mactv !== "" ? "Cập nhật" : "Lưu"}</button>
                        <button className="ml-2 btn btn-danger" onClick={() => onClose()}><i className="mr-2 fas fa-sign-out-alt"></i>Đóng</button>
                    </div>
                </Modal>
            }
            {visibleOrder &&
                <Modal
                    width={1400}
                    zIndex={889}
                    maskClosable={false}
                    visible={visibleOrder}
                    onCancel={() => setVisibleOrder(false)}
                >
                    <AddOrder
                        chinhanh={chinhanh}
                        ref={OrderRef}
                        selectedPatient={{
                            mabn: info.mabn,
                            maql: info.maql,
                            ngayhen: info.ngayhen,
                            id: info.id?.toString()
                        }}
                        reLoad={() => { GetListOrderById(info.id) }}
                        onClose={() => {
                            setVisibleOrder(false);
                        }} />
                </Modal>
            }
        </React.Fragment>
    );
}
const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Dieuphoi);
