import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle  } from 'react';
import { Button, Spin } from 'antd';
import { Line } from '@ant-design/plots';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../../../configure/systemConfig';
import * as Actions from '../../../../libs/actions';
import * as ReportServices from '../../../../services/ReportService';
import Notify, { NotifyStatus } from '../../../../components/notify';

const actionDateEnum = {next: 'next', previous: 'previous'};
export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    loadData: () => {
      // getData(props.dateFrom, props.dateTo);
      onChangeDateHandle();
    },
  }));
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //other
  const [loading, setLoading] = useState(false);
  const [dateSteps, setDateSteps] = useState(30);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [dataChart, setDataChart] = useState([]);
  //#endregion
  
  //#region useEffect
  // useEffect(() => {
  //   setDateFrom(props.dateFrom);
  // }, [props.dateFrom]);
  
  // useEffect(() => {
  //   setDateTo(moment(dateFrom).add(dateSteps, 'days'));
  // }, [dateFrom]);
  
  useEffect(() => {
  }, []);
  //#endregion 
  //#region get list data cho bộ lọc
  //#region đoạn này cho next hoặc previous không bị giới hạn thời gian bởi props.dateFrom & props.dateTo (truyền từ index)
  // do anh B muốn next hoac95 previous giớ hạn trong thời gian bởi props.dateFrom & props.dateTo (truyền từ index)
  // const getData = (dFrom = props.dateFrom) => {
  //   if (!dFrom) return;
  //   let filterModel = {
  //     siteIds: props.siteIds,
  //     coporationIds: props.coporationIds,
  //     paymentTypeIds: props.paymentTypeIds,
  //     dateFrom: dFrom,
  //     // dateTo: props.dateTo
  //     dateTo: moment(dFrom).add(dateSteps, 'days'),
  //   }
  //   ReportServices.getAverageRevenueListForChart(filterModel).then(result => {
  //     if (result.isSuccess) {
  //       setDataChart(result.data);
  //       notiRef && notiRef.current.Close();
  //     }
  //     else {
  //       notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
  //     }
  //   }).catch(err => {
  //     if (err.error && err.error.messageText)
  //       notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
  //     else notiRef.current.Show(err, NotifyStatus.Error);
  //   }).finally(() => {
  //       forceUpdate();
  //   })
  // }
  
// const onChangeDateHandle = (action) => {
//   let d = dateFrom;
//   switch (action) {
//     case actionDateEnum.next:
//       d = moment(d).add(dateSteps + 1,'days');
//       break;
//     case actionDateEnum.previous:
//       d = moment(d).add(-dateSteps - 1,'days');
//       break;
//     default:
//       break;
//   }
  //#endregion 

const getData = (dFrom, dTo) => {
  if (!dFrom || !dTo) return;
  setLoading(true);
  let filterModel = {
    siteIds: props.siteIds,
    coporationIds: props.coporationIds,
    keyword: props.keyword,
    city : props.city,
    district : props.district,
    minSpent: props.minSpent,
    maxSpent: props.maxSpent,
    dateFrom: moment(dFrom).format(SystemConfig.FORMAT_YYYYMMDD),
    dateTo: moment(dTo).format(SystemConfig.FORMAT_YYYYMMDD)
  }
  ReportServices.getSpentRevenueListForChart(filterModel).then(result => {
    if (result.isSuccess) {
      setDataChart(result.data);
      notiRef && notiRef.current.Close();
    }
    else {
      notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
    }
  }).catch(err => {
    if (err.error && err.error.messageText)
      notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
    else notiRef.current.Show(err, NotifyStatus.Error);
  }).finally(() => {
      forceUpdate();
      setLoading(false);
  })
}

const onChangeDateHandle = (action) => {
  let dFrom = dateFrom ?? props.dateFrom;
  let dTo = dateTo ?? props.dateTo;
  if (props.dateTo.diff(props.dateFrom, 'days') <= dateSteps) {
    setDateFrom(props.dateFrom);
    setDateTo(props.dateTo);
    getData(props.dateFrom, props.dateTo);
    return;
  }
  switch (action) {
    case actionDateEnum.next:
      dFrom = moment(dTo).add(1,'days');
      dTo = moment(dFrom).add(dateSteps - 1,'days');
      if (dTo.diff(props.dateTo, 'days') > 0) {
        dTo = props.dateTo;
        // dFrom = moment(dTo).add(-(dateSteps - 1),'days');
      }
      break;
    case actionDateEnum.previous:
      dTo = moment(dFrom).add(-1,'days');
      dFrom = moment(dTo).add(-(dateSteps - 1),'days');
      if (dFrom.diff(props.dateFrom, 'days') < 0) {
        dFrom = props.dateFrom;
        // dTo = moment(dFrom).add(dateSteps - 1,'days');
      }
      break;
    default:
      dTo = moment(props.dateFrom).add(dateSteps - 1,'days');
      dFrom = props.dateFrom;
      break;
  }
  setDateFrom(dFrom);
  setDateTo(dTo);
  getData(dFrom, dTo);
}

  const configChart = {
    data: dataChart,
    color: '#2B78E4',
    xField: 'ngay',
    yField: 'revenue',
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    xAxis: {
      label: {
        formatter: (v) => moment(v).format(SystemConfig.FORMAT_DD),
      },
    },
    tooltip: {
      title:(e)=>{
        return moment(e).format(SystemConfig.FORMAT_DATE)
      },
      formatter:
        (record) => {
          return { name: 'Thực thu', value: record.revenue.toLocaleString('en-US') };
        },
      
    },
  };

  //#endregion 
  return (
    <React.Fragment>
      <Spin spinning={loading} className='spin-color'>
        <div className="list-page" >
          <div className="row">{/* notification */}
            <div className="col-md-12"><Notify ref={notiRef} /></div>
          </div>
          <div className="row">
            <Line className="w-100" {...configChart} />
          </div>
          <div className="row">
            <div className="col-md-12">
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center align-items-center">
              <Button type="link" className="p-1"
                hidden={dateFrom <= props.dateFrom}
                onClick={() => {onChangeDateHandle(actionDateEnum.previous);}}
              >
                  <i class="fa fa-chevron-circle-left text-color" style={{ fontSize:"x-large" }}></i>
              </Button>
              <div className="mx-2">
                {moment(dateFrom).format(SystemConfig.FORMAT_DATE)} - {moment(dateTo).format(SystemConfig.FORMAT_DATE)}
              </div>
              <Button type="link" className="p-1"
                hidden={dateTo >= props.dateTo}
                onClick={() => {onChangeDateHandle(actionDateEnum.next);}}
              >
                <i className="fa fa-chevron-circle-right text-color" style={{ fontSize:"x-large" }}></i>
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </React.Fragment>
  );
})
