import { Button, Modal, notification, Select, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import Update from './update'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import * as Actions from '../../libs/actions';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from '../../services/commonService';
import { CalendarToolbar } from '../lichhenlaymau/index'
import * as signalR from '@aspnet/signalr';
import * as Configure from '../../configure/urlConfig';
import 'react-big-calendar/lib/sass/styles.scss';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import { connect } from 'react-redux';

moment.locale('vi_VN');
const localizer = momentLocalizer(moment);
const { Option } = Select;
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function LichDangKy(props) {
    const [visableCreate, setVisableCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [listctv, setListctv] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [mactv, setMactv] = useState(0);
    const [rangeCalendar, setRangeCalendar] = useState(null);
    const [dateRange, setDateRange] = useState([moment().startOf('month'), moment().endOf('month')]);
    const [detail, setDetail] = useState({});
    const [backgroundColorArray, setBackgroundColorArray] = useState([]);
    const [customColor, setCustomColor] = useState(false);

    const [isMultisite, setIsMultisite] = useState(false);
    const [branch, setBranch] = useState("");
    const [listBranch, setListBranch] = useState([]);
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(Configure.BASE_NOTIFY_URL + "/notify")
            .build();

        connection.on("notify", data => {
            if (data === 'Lịch đăng ký')
                DanhSachLichDangKyBenhNhanTheoRangeNgay()
        })
        connection.start();
    }, []);
    useEffect(() => {
        DanhSachCTV();
    }, [branch])
    useEffect(() => {
        if (rangeCalendar === null)
            return;
        if (rangeCalendar instanceof Array) {
            setDateRange([moment(rangeCalendar[0]), moment(rangeCalendar[rangeCalendar.length - 1])])
        }
        else {
            setDateRange([moment(rangeCalendar.start), moment(rangeCalendar.end)])
        }
    }, [rangeCalendar])
    useEffect(() => {
        DanhSachLichDangKyBenhNhanTheoRangeNgay();
    }, [dateRange, mactv, branch, props.siteid])
    useEffect(() => {
        generateColor();
    }, [listctv])
    useEffect(() => {
        DanhSachCTV();
    }, [props.siteid])
    const DanhSachCTV = (page = 1, size = 10, getAll = true, chinhanh = branch) => {
        CommonService.GetListCollaborators(page, size, getAll, "all", chinhanh).then(result => {
            if (result.isSuccess) {
                setListctv(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu cộng tác viên bị lỗi!' })
        })
    }
    const DanhSachLichDangKyBenhNhanTheoRangeNgay = () => {
        AppointmentService.DanhSachLichDangKyBenhNhanTheoRangeNgay(mactv, dateRange[0].format('YYYY/MM/DD'), dateRange[1].format('YYYY/MM/DD'), branch).then(result => {
            if (result.isSuccess) {
                let data = [];
                for (let item of result.data) {
                    var event = {
                        id: item.autoid,
                        hoten: item.hoten,
                        mactv: item.mactv,
                        ngayhen: item.ngayhen,
                        title: `${item.mactv} - ${item.hoten} ${moment(item.datestart).format("HH:mm")}-${moment(item.dateend).format("HH:mm")}`,
                        start: moment(item.datestart).toDate(),
                        end: moment(item.dateend).toDate(),
                    }
                    data.push(event)
                }
                setDataSource(data);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    const onSelectEvent = (row) => {
        AppointmentService.LichDangKyTheoId(row.id).then(result => {
            if (result.isSuccess) {
                setDetail(result.item);
                setIsEdit(true);
                setVisableCreate(true);
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
    }

    const generateColor = (numberOfColor = listctv.length) => {
        let colorArray = [];
        for (var i = 0; i < numberOfColor; i++) {
            var o = Math.round, r = Math.random, s = 255;
            var clr = 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ')';
            colorArray.push(clr);
        }
        setBackgroundColorArray(colorArray);
    }

    const eventStyleGetter = (e) => {
        var color = "white";
        var backgroundColor = "black";

        listctv.forEach((x, index) => {
            if (Number(e.mactv) === Number(x.value)) {
                backgroundColor = backgroundColorArray[index]
                color = 'white'
            }
        })
        var style = {
            cursor: 'pointer',
            backgroundColor: backgroundColor,
            color: color
        };
        return {
            style: style
        };
    }
    useEffect(() => {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item);
                if (result.item) GetListSite();
            }
        });
    }, [])
    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListBranch(result.data);
            } else {
                setListBranch([]);
            }
        }).catch(err => {
        })
    }
    return (
        <div style={{ height: '100%' }}>
            <div className={'containerChilderWrapper'}>
                <div className='h4 text-center mt-1 font-bold text-uppercase'>Lịch đăng ký</div>
                <div className="row">
                    {/* {isMultisite === true &&
                        <div className="col-md-12 d-flex justify-content-center align-items-center mb-2">
                            <Select
                                style={{ width: '40%' }}
                                showSearch
                                allowClear={false}
                                value={branch}
                                onChange={(e) => {
                                    setBranch(e);
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={-1} value={''}>Chi nhánh</Option>
                                {listBranch.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.ma}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    } */}
                    <div className="col-md-6 d-flex mb-2" style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ width: '100px', cursor: "help" }} title="Shine bright like a diamond! Bấm vào để xem" onClick={() => { setCustomColor(true); generateColor() }}>Cộng tác viên&nbsp;</span>
                        <Select
                            style={{ width: 200 }}
                            value={mactv}
                            placeholder="Lọc theo cộng tác viên"
                            allowClear={false}
                            onChange={(e) => {
                                setMactv(e)
                            }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            <Option key={-1} value={0}>Tất cả</Option>
                            {listctv.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                        <Tooltip title="Tải lại trang">
                            <Button className="btn btn-secondary ml-2"
                                onClick={() => DanhSachLichDangKyBenhNhanTheoRangeNgay()}>
                                <i className="fa fa-sync" /></Button>
                        </Tooltip>
                    </div>
                    <div className="col-md-6 mb-2 text-right">
                        <Tooltip title="Tạo lịch đăng ký">
                            <button className="btn btn-success"
                                onClick={(e) => {
                                    setDetail({})
                                    setIsEdit(false)
                                    setVisableCreate(true)
                                }}>
                                <i className="fas fa-calendar-plus" />
                            </button>
                        </Tooltip>
                    </div>
                </div>
                <div style={{ height: isMultisite ? "calc(100% - 110px)" : "calc(100% - 70px)", backgroundColor: '#fff' }}>
                    <Calendar
                        selectable
                        localizer={localizer}
                        components={
                            {
                                toolbar: CalendarToolbar,
                                week: {
                                    header: ({ date, localizer }) => capitalizeFirstLetter(localizer.format(date, 'dddd, DD'))
                                },
                                month: {
                                    header: ({ date, localizer }) => capitalizeFirstLetter(localizer.format(date, 'dddd'))
                                }
                            }
                        }
                        messages={{
                            noEventsInRange: "Chưa có dữ liệu",
                            date: "Ngày",
                            time: "Giờ",
                            event: "Thông tin",
                            showMore: (count) => <span><i className="fa fa-eye" style={{ marginRight: 5 }} />Xem thêm {count} lịch đăng ký</span>
                        }}
                        onSelectEvent={onSelectEvent}
                        events={dataSource}
                        startAccessor="start"
                        endAccessor="end"
                        onRangeChange={(value) => {
                            setRangeCalendar(value);
                        }}
                        eventPropGetter={customColor && (eventStyleGetter)}
                    />

                </div>
            </div>
            {visableCreate &&
                <Modal
                    maskClosable={false}
                    visible={visableCreate}
                    onCancel={e => {
                        setVisableCreate(false)
                        setIsEdit(false)
                    }}
                >
                    <Update isEdit={isEdit}
                        detail={detail}
                        chinhanh={branch}
                        reLoad={() => {
                            DanhSachLichDangKyBenhNhanTheoRangeNgay()
                        }}
                        onClose={e => {
                            setIsEdit(false);
                            setVisableCreate(false)
                        }} />
                </Modal>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(LichDangKy);