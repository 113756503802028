import * as React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as AuthServices from '../../services/authServices';
import history from '../../stores/history';
import { notification, Form, Select } from 'antd';
import './login.css'
import * as WorldfoneService from '../../services/worldfoneService';
import * as Configure from '../../configure/urlConfig';
import * as Actions from '../../libs/actions';
import * as ConfigService from '../../services/configService';

const { Option } = Select;
var task = {};
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            username: "",
            password: "",
            rememberMe: false,
            showPassword: false,
            hasError: false,
            sites: [],
            selectedSiteID: null,
        };
    }

    GetListSitByUserID() {
        this.setState({selectedSiteID : null});
        ConfigService.GetListSitByUserID(this.state.username).then(result => {
            if (result.isSuccess) {
                this.setState({ sites: result.data });
                if (result.data && result.data.length === 1 && result.data[0]) {
                    this.setState({
                        selectedSiteID: result.data[0].id
                    });
                } else if (result.data && result.data.length > 1) {
                    var foundCurSiteLog = result.data.find(x => x.currentSite !== 0);
                    if (foundCurSiteLog !== undefined && foundCurSiteLog !== null && foundCurSiteLog !== "") {
                        this.setState({
                            selectedSiteID: foundCurSiteLog.currentSite,
                        });
                    }
                }
            }
            else {
                this.setState({ sites: [] })
                Actions.openMessageDialog('error', result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog('error', err);

        })
    }
    onSiteChange(e){
        this.setState({selectedSiteID:e})
    }
    loginWF() {
        let userWF = localStorage.getItem('userWF');
        const data = new FormData();
        data.append("secret_key", Configure.SECRETKEY_WORLDWFONE);
        data.append("userextension", userWF.split('@')[0]);
        WorldfoneService.GetToken(data, userWF).then(result => {
            if (result.status === true) {
                notification.success({ message: 'Đăng nhập worldfone thành công!' });
                localStorage.setItem("tokenWF", result.data.token)
                WorldfoneService.login(userWF, result.data.token).then(response => {
                    window.location.reload();
                }).catch(err => Actions.openMessageDialog("Thông báo", "Vui lòng khởi động lại Worldfone và đăng nhập lại."))
            } else {
                notification.error({ message: 'Đăng nhập worldfone thất bại!' });
            }
        }).catch(err => {
            console.log(err);
            window.location.reload();
        })
    }
    handleSubmit() {
        if(this.state.selectedSiteID == null){
            notification.error({ message: 'Vui lòng chọn chi nhánh' })
            return;
        }
        const { username, password } = this.state;
        if (this.validator.allValid()) {
            this.setState({
                hasError: false,
            })
            AuthServices.loginWithSite(username, password, this.state.selectedSiteID).then((objRespone) => {
                if (objRespone.isSuccess === true) {
                    if (this.props.location && this.props.location.state) {
                        const { from } = this.props.location.state;
                        if (from !== '' && from !== undefined) {
                            history.push(from);
                        } else {
                            history.push('/');
                        }
                    } else {
                        history.push('/');
                    }
                    if (objRespone.userWF && objRespone.userWF !== '') {
                        this.loginWF();
                    }
                    else {
                        window.location.reload();
                    }

                }
            })
        } else {
            this.setState({
                hasError: true,
            })
            this.validator.showMessages();
            this.forceUpdate();
        }

    }
    handleChangeUserName(event) {
        let value = event.target.value;

        if(task){
            clearTimeout(task);
        }
        task = setTimeout(() => {
            this.GetListSitByUserID();
        }, 1000);

        this.setState({ username: value });
    }
    handleChangePassword(event) {
        let value = event.target.value;
        this.setState({ password: value });
    }

    componentDidMount() {
        this.checkprops(this.props);

        
    }
    componentWillUnmount(){
        if(task){
            clearTimeout(task);
        }
    }

    checkprops(props) {
        if (props === undefined || props.global === undefined || props.global.token === undefined) return
        if (props && props.global.token !== '') {
            if (props.location && props.location.state) {
                const { from } = props.location.state;
                if (from !== '' && from !== undefined) {
                    history.push(from);
                } else {
                    history.push('/');
                }
            } else {
                history.push('/');
            }
        }
    }

    render() {
        const { username, password, hasError } = this.state;
        return (
            <React.Fragment>
                <div className="loginwrapper">
                    <span className="r"></span>
                    <span className="r s"></span>
                    <span className="r s"></span>
                    <span className="r"></span>
                    <div className="card-body">
                        <div className="card-form">
                            <div className="logo">
                                <img alt="logo" src="/images/logologin.png" width={115} />
                            </div>
                           <div style={{ width: '100%', display: 'flex', justifyContent: 'center' , marginBottom: 50}}>
                               <Select
                                   className='w-100 text-center'
                                   placeholder="CHI NHÁNH"
                                   value={this.state.selectedSiteID}         
                                   onChange={this.onSiteChange.bind(this)}
                                 
                                   filterOption={(input, option) =>
                                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                   }
                               >
                                   {this.state.sites.map((record) =>
                                       <Option value={record.id}>{record.ma}</Option>
                                   )}
                               </Select>
                           </div>
                            <Form  autocomplete="off">
                                <div className="inputContainer">
                                    <label className="prefixIcon">
                                        <i style={{ color: "#fff" }} className="fas fa-user" />
                                    </label>
                                    <input  autocomplete="off"
                                        className={hasError && !this.state.username ? "intputLoginCustom intputError" : "intputLoginCustom"}
                                        maxLength={25}
                                        type="text" name="username" value={username}
                                        placeholder="Tên người dùng"
                                        onChange={this.handleChangeUserName.bind(this)} />
                                    {this.validator.message('Tên người dùng', this.state.username, 'required')}
                                </div>
                                <div className="inputContainer">
                                    <label className="prefixIcon">
                                        <i style={{ color: "#fff" }} className="fas fa-lock" />
                                    </label>
                                    <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                        <i className={this.state.showPassword ? "far fa-eye showPassword" : "far fa-eye-slash showPassword"}
                                            onClick={() => this.setState({ showPassword: !this.state.showPassword })} />
                                        <input  autocomplete="off"
                                            className={hasError && !this.state.password ? "intputLoginCustom intputError" : "intputLoginCustom"}
                                            style={{ paddingRight: 60 }}
                                            type={this.state.showPassword ? "text" : "password"} name="password" value={password}
                                            placeholder="Mật khẩu"
                                            onChange={this.handleChangePassword.bind(this)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    this.handleSubmit();
                                                }
                                            }} />
                                        {this.validator.message('Mật khẩu', this.state.password, 'required')}
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div >
                            <button className={"buttonlogincustom"}
                                onClick={this.handleSubmit.bind(this)} >Đăng nhập
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};


function mapStateToProps(state, props) {
    return {
        global: state.global
    };
}



export default connect(
    mapStateToProps
)(Login)
