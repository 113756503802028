
import * as React from 'react';
import { AutoComplete, Button, Input } from 'antd';
import { connect } from 'react-redux';
import * as Actions from '../../libs/actions';
import moment from 'moment'
import './main.css'

class AutocompleteTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],

      columns: [],
      searchInput:'',
      searchList: [],
    }
  }

  componentDidMount() {

    this.setState({ columns: this.props.headers })
  }

  renderGroupName = (text) => {
    return (

      <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
        {text}
      </div>
    )
  }

  focus() {
    this.search.focus()
  }
  renderTittle = () => {
    return (
      <div
        style={{ display: 'flex' }}>
        {this.state.columns.map((col, index) => {
          return (
            <span key={"data" + col.dataIndex + index} style={{
              width: col.width, overflow: 'hidden',
              textOverflow: 'ellipsis', marginRight: 5, paddingLeft: 10, textAlign: col.align,
            }}>{col.title}</span>
          )
        })
        }
      </div>

    )
  };
  getFormat(item, col) {
    if (col.format == "number")
      return item[col.dataIndex].toLocaleString('en-US')
    if (col.format == "datetime" && item[col.dataIndex]) {
      return moment(item[col.dataIndex]).format('DD-MM-YYYY');
    }
    if (col.format == "gender" && item[col.dataIndex]) {
      return Number(item[col.dataIndex]) === 0 ? 'NAM' : 'NỮ';
    }
    // return item[col.dataIndex]
    if (col.render) {
      return col.render(item[col.dataIndex], item)
    }
    return item[col.dataIndex]
  }
  renderItem = (item) => {
    return {
      value: item[this.props.keyValue],
      label: (
        <div
          style={{ display: 'flex' }}>
          {this.state.columns.map((col, index) => {
            return (
              <span key={"data" + col.dataIndex + index} style={{
                width: col.width, overflow: 'hidden',
                textOverflow: 'ellipsis', marginRight: 5, textAlign: col.align,
              }}>{
                  this.getFormat(item, col)
                } </span>
            )
          })
          }
        </div>
      ),
    };
  };

  groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  handleSearch = (value) => {
    let options = [];
    let option = {
      label: this.renderTittle(),
      options: [],
    }

    this.props.onSearch(value).then((result) => {
      if (result.isSuccess) {
        for (let item of result.data) {
          option.options.push(this.renderItem(item))
        }
        options.push(option)
      } else {
        options = []
      }

      this.setState({ options, searchList: result.data })
    }).catch((ex) => {
      this.setState({ options })
    })
  }

  onSelect = (value) => {
    let item = this.state.searchList.filter(record => record[this.props.keyValue] === value)[0];
    this.setState({
      // searchInput: item.patientName
      searchInput: '',
      options: [], 
      searchList: []
    });
    this.props.onSelect(item);
  }

  setValue = (value) => {
    this.setState({
      searchInput: value
    });
  }

  componentDidUpdate() {
    if (this.props.resetBarCode === true) {
      Actions.ResetBarCode(false);
      this.setState({ searchInput: '' })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.value !== nextProps.value) {
      this.setState({ searchInput: nextProps.value })
    }
    return true;
  }
  render() {
    return (
      <React.Fragment>
        <div style={{ display: 'relative' }}>
          <AutoComplete
            value={this.state.searchInput ? (this.props.keepTransfrom ? this.state.searchInput : this.state.searchInput.toUpperCase()) : ''}
            onChange={e => { this.setState({ searchInput: e }); if (this.props.onChange) { this.props.onChange(e) } }}
            onSelect={this.onSelect}
            onSearch={this.handleSearch}
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={this.props.tablewidth}
            style={{ width: this.props.width }}
            options={this.state.options}
            listHeight={this.props.listHeight ? this.props.listHeight : 500}
            maxLength={40}
            disabled={this.props.disabled ? true : false}
          >
            <Input style={{}} ref={(c) => { this.search = c }} placeholder={this.props.placeholder} />
          </AutoComplete>
          {this.props.qrcode === true &&
            <div style={{
              position: 'absolute', right: '0px', bottom: '4px', cursor: 'pointer', border: '1px solid rgb(191,191,191)',
              padding: '2px 9px', borderRadius: 8
            }}
              onClick={() => {
                if (this.props.showQRCode) { this.props.showQRCode() }
              }}
            ><i className="fa fa-qrcode"></i></div>
          }
          {this.props.showRemove === true &&
            <div style={{
              position: 'absolute', right: `${this.props.qrcode === true ? '32' : '6'}px`, bottom: '4px', cursor: 'pointer', border: 'none',
              padding: '2px 9px', borderRadius: 8
            }}
              onClick={() => {
                if (this.props.CleanText) { this.props.CleanText() }
              }}
            ><i className="fas fa-times"></i></div>
          }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    resetBarCode: state.global.resetBarCode,
  })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(AutocompleteTable);