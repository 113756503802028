import { Checkbox, DatePicker, Input, Pagination, Popconfirm, Popover, Select, Table, Tooltip, Modal } from 'antd';
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react'
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as CorporationServices from '../../../services/CorporationServices';
import * as PatientService from '../../../services/commonService';
import * as Statistic from '../../../services/statisticService';
import * as InvoiceService from '../../../services/invoiceService';
import * as SystemConfig from "../../../configure/systemConfig";
import AutocompletetableNoGroup from '../../../components/autocompletetable/autocompletetableNoGroup';
import Notify, { NotifyStatus } from '../../../components/notify'
import PhieuThu from './PhieuThuCongNo';
import PhieuXoa from './PhieuXoaThuCongNo'

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
export default forwardRef(({ type, ShowNotify, date1, onComplete, site }) => {
    const _hiddenLink = useRef()
    const notiRef = useRef(null);
    const [date, setDate] = useState([null, null])
    const [coporator, setCoporator] = useState(null);
    const [dataCoporator, setDataCoporator] = useState([]);
    const [key, setKey] = useState('');
    const [patientName, setPatientName] = useState('');
    const [patientId, setPatientId] = useState('');
    const [datecreate, setDatecreate] = useState(moment())
    const [dataSource, setDataSource] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isCheckAll, setIsCheckAll] = useState(false)
    const [billSelected, setBillSelected] = useState('');
    const [visible, setVisible] = useState(false);
    const [visibleDel, setVisibleDel] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [bill, setBill] = useState('');
    const [paymentDetail, setPaymentDetail] = useState(null);
    const [lstPaymentDetail, setLstPaymentDetail] = useState([]);
    const [barcode, setBarcode] = useState("");
    const [visiblePay, setVisiblePay] = useState(false);
    const [lstServicesPatient, setLstServicesPatient] = useState([]);
    const [isPaid, setIsPaid] = useState(false)
    // useImperativeHandle(ref, () => ({
    //     GetList: () => {
    //         setCurrentPage(1);
    //         GetList();
    //     },
    // }));
    useEffect(() => {
        DanhSachCongNoPhaiThu();
    }, [page, size])

    useEffect(() => {
        setPage(1);
        DanhSachCongNoPhaiThu();
    }, [type, date, patientId, coporator, site])

    useEffect(() => {
        setDate(date1)
        DanhSachCongNoPhaiThu();
    }, [date1])

    useEffect(() => {
        LoadCoporation();
    }, [])

    const LoadCoporation = (key = "") => {
        CorporationServices.GetList(key, 1, 40).then(res => {
            if (res.isSuccess) {
                setDataCoporator(res.data)
            }
        })
    }

    const loadPatient = (data) => {
        setKey(date)
        return PatientService.searchPatient(data);
    }

    const DanhSachCongNoPhaiThu = () => {
        let data = {
            page, size,
            manguonkhachhang: coporator,
            isAll: false,
            mabn: patientId
        }
        if (date[0])
            data.dateFrom = date[0].format("yyyy/MM/DD");
        if (date[1])
            data.dateTo = date[1].format("yyyy/MM/DD");
        if(site)
            data.site = site;
        if (type == 1) {
            Actions.setLoading(true);
            Statistic.DanhSachCongNoPhaiThu(data).then(res => {
            Actions.setLoading(false);
                if (res.isSuccess) {
                    setIndeterminate(false);
                    setIsCheckAll(false)
                    setDataSource(res.data);
                    setTotalRecord(res.totalRecords);
                }
                else {
                    if (notiRef && notiRef.current)
                        notiRef.current.Show(res.err.msgString, NotifyStatus.Warning)
                }
            }).catch((err) => {
                Actions.setLoading(false);
            })
        } else {
            Actions.setLoading(true);
            Statistic.DanhSachCongNoDaThu(data).then(res => {
            Actions.setLoading(false);
                if (res.isSuccess) {
                    setIndeterminate(false);
                    setIsCheckAll(false)
                    setDataSource(res.data);
                    setTotalRecord(res.totalRecords);
                }
                else {
                    if (notiRef && notiRef.current)
                        notiRef.current.Show(res.err.msgString, NotifyStatus.Warning)
                }
            }).catch((err) => {
                Actions.setLoading(false);
            })
        }
    }

    const onSubmit = () => {
        DanhSachCongNoPhaiThu();
        setVisible(false);
        setVisibleDel(false);
        if(onComplete) onComplete();
    }

    const showError = (msgString) => {
        if (notiRef && notiRef.current) 
            notiRef.current.Show(msgString, NotifyStatus.Success)
    }

    const ExportData = () => {
        let data = {}
            if (date[0]){
                data.dateFrom = date[0].format("yyyy/MM/DD");
            } else {
                data.dateFrom = "0001/01/01 00:00"
            }
            if (date[1]){
                data.dateTo = date[1].format("yyyy/MM/DD");
            } else {
                data.dateTo = "0001/01/01 23:59"
            }
            if(site)
                data.site = site;
            if(coporator){
                data.coporator = coporator;
            } else {
                data.coporator = "";
            }
            data.mabn = patientId;
        if (type == 1) {
            Actions.setLoading(true);
            Statistic.ExportCongNoPhaiThu(data.dateFrom, data.dateTo, data.coporator, data.mabn, data.site).then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ExportCongNoPhaiThu";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
        }
        else {
            Actions.setLoading(true);
            Statistic.ExportCongNoDaThu(data.dateFrom, data.dateTo, data.coporator, data.mabn,data.site).then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ExportCongNoDaThu";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
        }
    }
        
    const SelectAll = (checked) => {
        setIndeterminate(false)
        setIsCheckAll(checked)
        const data = dataSource.map(x => ({ ...x, checked: checked }))
        setDataSource(data)
    }

    const onCancel = () => {
        setVisible(false)
        setVisibleDel(false)
    }

    const handleOpenChange = (newOpen) => {
        setVisible(newOpen);
    };

    const handleOpenDelChange = (newOpen) => {
        setVisibleDel(newOpen);
    };

    const LoadPaymentDetail = (data) => {
        // let x = this.state.patient;
        // let banle = false
        // if (x.loaithu === "3") {
        //     banle = true;
        // }
        InvoiceService.PatientPaymentInvoiceDetail(data.sohd, moment(data.ngaylap).format('YYYY-MM-DD'), false).then(result => {
            if (result.isSuccess) {
                setLstPaymentDetail(result.data)
                setBarcode(result.returnObject.barcode ? result.returnObject.barcode : "")
                setVisiblePay(true)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const columns = [
        {
            title: 'Mã BN',
            dataIndex: 'patientID',
            key: 'patientID',
            width: 100,
        },
        {
            title: 'Tên BN',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 250,
        },
        {
            title: 'Năm sinh',
            dataIndex: 'year',
            key: 'year',
            width: 80,
        },
    ];
    const columnData = [
        {
            title: '#',
            width: 60,
            align: 'Center',
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
        },
        {
            title: <div>
                <Checkbox indeterminate={indeterminate} checked={isCheckAll} onChange={e => {
                    SelectAll(e.target.checked)
                }} />
            </div>,
            width: 60,
            align: 'Center',
            render: (text, record, index) => <div>
                <Checkbox
                    checked={record.checked == true}
                    onChange={e => {
                        let data = dataSource;
                        data[index].checked = e.target.checked
                        let count = data.filter(x => x.checked == true).length
                        if (count > 0 && count < data.length) {
                            setIndeterminate(true)
                        }
                        else {
                            setIndeterminate(false)
                        }
                        if(count == 0){
                            setIsCheckAll(false)
                        }
                        if(count == data.length){
                            setIsCheckAll(true)
                        }
                        setDataSource(data.map(x => ({ ...x })))
                    }}
                />
            </div>
        },
        {
            title: 'Mã phiếu',
            dataIndex: 'sohd',
            align : 'center',
            key: 'sohd',
            width: 100,
            render: (sophieu, record) => {
                return <a className="link-primary" href='#'
                onClick={() => {
                    LoadPaymentDetail(record)
                    setPaymentDetail(record) 
                }}>{sophieu}</a>
            }
        },
        ...(type == 1 ? [{
            title: 'Thao tác',
            align: 'center',
            width: 80,
            render: (value, record) => {
                return (
                    <Tooltip title='Thu hồi công nợ' placement='top'>
                        <Popover
                            placement="right"
                            content={
                                <div style={{ width: 400 }}>
                                    <PhieuThu billSelected={record.sohd} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()}/>
                                </div>
                            }
                            trigger="click"
                            visible={visible && bill == record.sohd}
                            onVisibleChange={handleOpenChange}
                            >
                                <i className={'fas fa-undo-alt'} onClick={()=> {
                                    setBill(record.sohd)
                                    setVisible(true)
                                }}></i>
                        </Popover>
                    </Tooltip>
                )
            },
        }] : [{
            title: 'Thao tác',
            align: 'center',
            width: 80,
            render: (value, record) => {
                return (
                    <Tooltip title='Xóa công nợ đã thu' placement='top'>
                        <Popover
                            placement="right"
                            content={
                                <div style={{ width: 400 }}>
                                    <PhieuXoa billSelected={record.sohd} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()}/>
                                </div>
                            }
                            trigger="click"
                            visible={visibleDel && bill == record.sohd}
                            onVisibleChange={handleOpenDelChange}
                            >
                                <i className={'fas fa-trash-alt'} onClick={()=> {
                                    setBill(record.sohd)
                                    setVisibleDel(true)
                                }}></i>
                        </Popover>
                    </Tooltip>
                )
            },
        }]),
        {
            title: 'Ngày lập phiếu',
            dataIndex: 'ngaylap',
            key: 'ngaylap',
            width: 120,
            render: (value) => {
                return <span>{value ? moment(value).format("DD/MM/YYYY HH:mm") : ''}</span>;
            },
        },
        {
            title: 'Chi nhánh',
            dataIndex: 'chinhanh',
            align : 'center',
            key: 'chinhanh',
            width: 100,
        },
        {
            title: 'Nguồn khách hàng',
            dataIndex: 'nguonkhachhang',
            key: 'nguonkhachhang',
            width: 120,
        },
        {
            title: 'Mã bệnh nhân',
            dataIndex: 'mabn',
            key: 'mabn',
            width: 100,
        },
        {
            title: 'Tên bệnh nhân',
            dataIndex: 'tenbn',
            key: 'tenbn',
            width: 150,
        },
        {
            title: 'Số tiền',
            dataIndex: 'sotien',
            key: 'sotien',
            align: 'right',
            width: 120,
            render: (value) => {
                return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
            },
        },
        ...(type == 1 ? [] : [{
            title: 'Ngày thu',
            dataIndex: 'ngaythu',
            key: 'ngaythu',
            width: 120,
            render: (value) => {
                return <span>{value ? moment(value).format("DD/MM/YYYY") : ''}</span>;
            },
        },
        {
            title: 'Ghi chú',
            dataIndex: 'ghichu',
            key: 'ghichu',
            width: 150,
        }]),
    ]

    let tongtien = 0;
    let tongthanhtoan = 0;
    let tongbhyt = 0;
    let lengthService = lstServicesPatient.filter(x => x.loai === '1').length;
    let lengthPayService = lstPaymentDetail.filter(x => x.loai === '1').length;
    if (isPaid === false) {
        tongtien = lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongiakm), 0);
        tongthanhtoan = lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongiakm), 0);
        tongbhyt = lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.giabh), 0);
    }
    let posGK = 0;
    let posGKM = 0;
    return (
        <React.Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
            <div className='modal-header fit-content'>
                <div className='h5'>Chi tiết - {`${type == 1 ? 'Công nợ phải thu' : 'Công nợ đã thu'}`}</div>
            </div>
            <Notify ref={notiRef} />
            <div className='row'>
                <div className='col-md-4 py-1' >
                    <label>Ngày {`${type == 1 ? 'lập phiếu' : 'thu'}`}</label>
                    <RangePicker allowClear={true} format="DD-MM-YYYY" value={date} locale={viVN}
                        onChange={(value) => {
                            if (value == null)
                                setDate([null, null])
                            else
                                setDate(value)
                        }}
                    />
                </div>
                <div className='col-md-4 py-1' >
                    <label>Nguồn khách hàng</label>
                    <Select value={coporator} placeholder="Chọn nguồn khách hàng"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            setCoporator(e)
                        }}
                        showSearch
                        allowClear
                        onSearch={(value) => LoadCoporation(value)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {dataCoporator.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                    </Select>
                </div>
                <div className='col-md-4 py-1' >
                    <label>Tên bệnh nhân</label>
                    <AutocompletetableNoGroup
                        showRemove={false}
                        placeholder="Nhập họ tên hoặc mã bệnh nhân"
                        width='100%'
                        onSearch={(e) => loadPatient(e)}
                        onSelect={(e) => {
                            setPatientId(e.patientID)
                            setPatientName(e.patientName)
                        }}
                        CleanText={() => {
                            setPatientId('')
                            setPatientName('');
                        }}
                        headers={columns}
                        keyValue={'patientID'}
                        title="tbl-search"
                        value={patientId + ' ' + patientName}
                        listHeight={400}
                        keepTransfrom={true}
                    />
                </div>
                <div className='col-12 py-1'>
                    <div className='text-right'>
                        <button className='btn btn-primary mr-1'
                            onClick={() => { DanhSachCongNoPhaiThu() }}
                        ><i className='fa fa-search mr-1'></i> Tìm kiếm</button>
                        <button className='btn btn-secondary'
                            onClick={() => {
                                setDate([null, null])
                                setCoporator(null)
                                setPatientId('')
                                setPatientName('');
                            }}
                        ><i className='fa fa-eraser mr-1'></i> Xóa dữ liệu nhập</button>
                    </div>
                </div>
                <div className='col-12 py-1'>
                    {type == 1 ?
                        <Popover 
                            placement="right" 
                            title={""} 
                            content={
                                <div style={{ width: 400 }}>
                                    <PhieuThu billSelected={billSelected} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()}/>
                                </div>
                            }
                            visible={visible && bill == ''}
                            onVisibleChange={handleOpenChange}
                            trigger="click">
                                <button className='btn btn-secondary mr-1'
                                    onClick={() => {
                                        setBillSelected(dataSource.filter(x => x.checked == true).map(x => x.sohd).toString())
                                        setVisible(true)
                                        setBill('')
                                    }}
                                ><i className='fas fa-undo-alt mr-1'></i> Thu công nợ theo lô</button>
                        </Popover>
                        :
                        <Popover 
                            placement="right"
                            content = {
                                <div style={{ width: 400 }}>
                                    <PhieuXoa billSelected={billSelected} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()}/>
                                </div>
                            }
                            visible={visibleDel && bill == ''}
                            onVisibleChange={handleOpenDelChange}
                            trigger="click"
                            >
                                <button className='btn btn-secondary mr-1'
                                onClick={() => {
                                    setBillSelected(dataSource.filter(x => x.checked == true).map(x => x.sohd).toString())
                                    setVisibleDel(true)
                                    setBill('')
                                }}
                            ><i className='fa fa-trash-alt mr-1'></i> Xóa công nợ đã thu theo lô</button>
                        </Popover>
                    }
                    <button className='btn btn-secondary float-right' onClick={() => ExportData()}><i className='fa fa-file-excel mr-1'></i> Xuất Excel</button>

                </div>
                <div className='col-12 py-1'>
                    <Table
                        columns={columnData}
                        dataSource={dataSource}
                        pagination={false}
                        rowKey={(row, index) => index}
                    />
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                        total={totalRecord}
                        showTotal={total => `Tổng: ${total}`}
                        size={size}
                        current={page}
                        onChange={(page, size) => {
                            setPage(page);
                            setSize(size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setPage(1);
                            setSize(size);
                        }}
                        showSizeChanger={true}
                    />
                </div>
            </div>

            <Modal
                width={1000}
                visible={visiblePay} >
                <div>
                    <div className='text-right'>Tổng tiền: <strong>{lstPaymentDetail.reduce((tmp, cur) =>
                        tmp + Math.round((cur.soluong * cur.dongia * ((100 - cur.tylegiamgia) / 100) - cur.soluong * cur.giabh) * ((100 - cur.giamgia) / 100)), 0).toLocaleString('en-US')}</strong></div>
                    <div className='table-responsive' style={{ maxHeight: '70vh', marginBottom: 10 }}>
                        <table className="table table-striped table-hover table-bordered w-100">
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>STT</th>
                                    <th>Tên dịch vụ/Thuốc</th>
                                    <th>SL</th>
                                    <th>Đơn giá</th>
                                    <th>Thành tiền</th>
                                    {/* <th>Bảo hiểm trả</th> */}
                                    <th>Giảm trừ khác</th>
                                    <th>Bệnh nhân trả</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lstPaymentDetail.filter(x => x.loai === '0').length > 0 &&
                                    <React.Fragment>
                                        {posGK === 0 &&
                                            <tr key={-1}>
                                                <span className="d-none">{posGKM = posGKM + 1}</span>
                                                <td colSpan='8' className='font-bold'>Gói khám</td>
                                            </tr>}
                                    </React.Fragment>
                                }
                                {lstPaymentDetail.filter(x => x.loai === '0').map((x, index) => (
                                    <tr key={'1' + index}>
                                        <td>{index + 1}</td>
                                        <td>{x.tenvp}</td>
                                        <td>{x.soluong}</td>
                                        <td className='text-right'>{Math.round(x.dongia * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                        <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                        {/* <td className='text-right'>{(x.soluong * x.giabh).toLocaleString('en-US')}</td> */}
                                        <td className='text-right'>{x.tylegiamgia === 0 ? Math.round(x.soluong * (x.dongia - x.giabh) * (x.giamgia / 100)).toLocaleString('en-US') : Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * (x.giamgia / 100)).toLocaleString('en-US')}</td>
                                        <td className='text-right'>{Math.round((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toLocaleString('en-US')}</td>
                                    </tr>
                                ))
                                }
                                {lstPaymentDetail.filter(x => x.loai === '1').length > 0 &&
                                    <tr key={-1}>
                                        <td colSpan='8' className='font-bold'>Dịch vụ</td>
                                    </tr>
                                }
                                {lstPaymentDetail.filter(x => x.loai === '1').map((x, index) => (
                                    <tr key={'1' + index}>
                                        <td>{index + 1}</td>
                                        <td>{x.tenvp}</td>
                                        <td>{x.soluong}</td>
                                        <td className='text-right'>{Math.round(x.dongia * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                        <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                        {/* <td className='text-right'>{(x.soluong * x.giabh).toLocaleString('en-US')}</td> */}
                                        <td className='text-right'>{x.tylegiamgia === 0 ? Math.round(x.soluong * (x.dongia - x.giabh) * (x.giamgia / 100)).toLocaleString('en-US') : Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * (x.giamgia / 100)).toLocaleString('en-US')}</td>
                                        <td className='text-right'>{Math.round((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toLocaleString('en-US')}</td>
                                    </tr>
                                ))
                                }
                                {lstPaymentDetail.filter(x => x.loai === '4').length > 0 &&
                                    <tr key={-2}>
                                        <td colSpan='8' className='font-bold'>Vật tư sử dụng</td>
                                    </tr>
                                }
                                {lstPaymentDetail.filter(x => x.loai === '4').map((x, index) => (
                                    <tr key={'1' + index}>
                                        <td>{index + 1 + lengthPayService}</td>
                                        <td>{x.tenvp}</td>
                                        <td>{x.soluong}</td>
                                        <td className='text-right'>{Math.round(x.dongia * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                        <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                        {/* <td className='text-right'>{(x.soluong * x.giabh).toLocaleString('en-US')}</td> */}
                                        <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.giabh) * (x.giamgia / 100)).toLocaleString('en-US')}</td>
                                        <td className='text-right'>{Math.round((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toLocaleString('en-US')}</td>
                                    </tr>
                                ))
                                }
                                {lstPaymentDetail.filter(x => x.loai === '2' || x.loai === '3').length > 0 &&
                                    <tr key={-2}>
                                        <td colSpan='8' className='font-bold'>Thuốc</td>
                                    </tr>
                                }
                                {lstPaymentDetail.filter(x => x.loai === '2' || x.loai === '3').map((x, index) => (
                                    <tr key={'1' + index}>
                                        <td>{index + 1 + lengthPayService}</td>
                                        <td>{x.tenvp}</td>
                                        <td>{x.soluong}</td>
                                        <td className='text-right'>{Math.round(x.dongia * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                        <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                        {/* <td className='text-right'>{(x.soluong * x.giabh).toLocaleString('en-US')}</td> */}
                                        <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.giabh) * (x.giamgia / 100)).toLocaleString('en-US')}</td>
                                        <td className='text-right'>{Math.round((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toLocaleString('en-US')}</td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-danger" onClick={() => { setVisiblePay(false); posGKM = 0 }}>Đóng</button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
})
