import * as ApiCaller from '../libs/httpRequests';
const prefix = 'WarehouseTransfer';

export function ListChuyenKho(key, tungay, denngay, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dschuyenkho?key=${key}&tungay=${tungay}&denngay=${denngay}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ListKhoXuat() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dskhoxuat`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ListKhoNhap(makho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dskhonhap?makho=${makho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ListThuoc(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsthuoc?key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SoLuongTonKho(makho, mabd) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/soluongvattu?makho=${makho}&mabd=${mabd}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function TaoPhieuChuyenKho(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuphieuchuyenkho`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DetailChuyenKho(id, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DetailChuyenKho?id=${id}&ngay=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ListChuyenKhoByMaKho(key, tungay, denngay, makho,page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dschuyenkhobymakho?key=${key}&tungay=${tungay}&denngay=${denngay}&makho=${makho}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};