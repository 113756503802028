import React, { Component } from 'react'
import { Input, Form, notification } from 'antd';
import * as VPService from '../services/VPService';

const initialState = {
    manhomvp: '',
    tennhomvp: '',
    tenviettat: ''
}
export default class CreateNewNhomVP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            lstNhomVP: []
        };
    }
    async CreateNhomVP() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                tennhomvp: this.state.tennhomvp,
                tenviettat: this.state.tenviettat
            }
            VPService.createNhomVP(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.resetState();
            }).catch(err => {
                notification.warning({ message: 'Tạo thất bại!' });
            })
        } else {
        }
    }
    resetState() {
        this.form.setFieldsValue({
            manhomvp: null,
            tennhomvp: null,
            tenviettat: null,
        });
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm nhóm viện phí</h3>
                    </div>
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ marginBottom: 10, marginTop: 10, display: 'none' }}>
                            <Form.Item style={{ flex: 1 }} label="Mã nhóm viện phí:" name="mavp" >
                                <Input
                                    value={this.state.manhomvp}
                                    onChange={(e) => this.setState({ mavp: e.target.value })}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }} >
                            <Form.Item style={{ width: 500 }} label="Tên nhóm viện phí:" name="tennhomvp"
                                rules={[{ required: true, message: 'Yêu cầu nhập tên nhóm viện phí!' }]}>
                                <Input
                                    onChange={(e) => this.setState({ tennhomvp: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item style={{ width: 400 }} label="Tên viết tắt:" name="tenviettat"
                                rules={[{ required: true, message: 'Yêu cầu nhập tên viết tắt!' }]}>
                                <Input
                                    onChange={(e) => this.setState({ tenviettat: e.target.value })}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>

                            <button className="btn btn-success"
                                onClick={() => { this.CreateNhomVP() }}>
                                <i className="fas fa-plus" style={{ marginRight: 5 }} />Thêm</button>
                            <button className="btn btn-warning" style={{ marginLeft: 10 }}
                                onClick={() => this.resetState()}>
                                <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.resetState();
                                    this.props.onCancel();
                                    this.props.onReload();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
