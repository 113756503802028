import { Button, Form, Input, Select, Table, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import SelectRoomDetail from './selectRoomDetail'
export default (props) => {
    const [dataSource, setDataSource] = useState([])
    useEffect(() => {
        if (props.dataSource)
            setDataSource(props.dataSource)
    }, [props.dataSource])
    const editPhongKham = (row, dataPK) => {
        let listSelectedServices = dataSource;
        let index = listSelectedServices.findIndex(record => record.id === row.id);
        if(listSelectedServices[index].status_kham && listSelectedServices[index].status_kham !== ""){
            notification.warning({ message: "Dịch vụ đã khám hoặc đang khám. Không thể đổi phòng." });
            return;
        }
        listSelectedServices[index].makp = dataPK.makhoa;
        listSelectedServices[index].maphong = dataPK.maphong;
        listSelectedServices[index].phongkham = dataPK.maphong;
        listSelectedServices[index].tenphong = dataPK.tenphong;
        setDataSource(listSelectedServices.map(x => ({ ...x })))
    }
    return (
        <React.Fragment>
            <div className='row p-2'>
                <div className='col-1 mb-2'>
                    <div className='h4 font-weight-bold'>STT</div>
                </div>
                <div className='col-11 mb-2'>
                    <div className='h4 font-weight-bold'>Dịch vụ</div>
                </div>
                {dataSource.filter(x => x.groupId === 8)?.map((row, index) => <React.Fragment key={index}>
                    <div className='col-1 mb-2'>
                        <div className='h4 font-weight-bold'>{index + 1}</div>
                    </div>
                    <div className='col-11 mb-2'>
                        <div className='h4 font-weight-bold'>{row.ten}</div>
                        <SelectRoomDetail
                            item={row}
                            onSubmit={(value) => {
                                editPhongKham(row, value)
                            }}
                        />
                    </div>
                </React.Fragment>)}
                <div className='col-12 mb-2 text-center'>
                    <Button className='btn btn-secondary mr-1' onClick={() => {
                        if (props.onClose) props.onClose()
                    }}
                    >Đóng</Button>
                    <Button className='btn btn-color ml-1' onClick={() => {
                        if (props.onSubmit) props.onSubmit(dataSource)
                    }}
                    >Lưu</Button>
                </div>
            </div>
        </React.Fragment>
    )
}
