import { notification } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ModalCLS from '../../components/MDCLS';
import * as Actions from '../../libs/actions';
import { isNullOrEmpty } from '../../libs/util';
import * as ChiDinhService from '../../services/chiDinhService';

const AddOrder = forwardRef(({ onClose, selectedPatient, reLoad, chinhanh, banggia, disableSelectBangGia = true }, ref) => {
    useImperativeHandle(ref, () => ({
        XoaChiDinh: (row) => {
            deleteRow(row)
        },
        UpdateSelectedFromProps: (data) => {
            UpdateSelectedFromProps(data)
        },
    }));
    const AddRow = (lstData) => {
        if (lstData === [])
            return;
        const lstChiDinhBenhNhan = lstData.map(x => ({
            ...x,
            ma: x.ma !== undefined ? x.ma : "0",
            madoituong: "2",
            mavp: x.id.toString(),
            ngay: moment(selectedPatient.ngayhen).format("YYYY/MM/DD HH:mm"),
            soluong: x.soluong,
            isNew: true,
            packageId: x.packageId !== undefined ? x.packageId : "0"
        }));
        if (lstChiDinhBenhNhan.length === 0) {
            return;
        }
        Actions.setLoading(true);
        const patient = {
            'code': selectedPatient.mabn,
            'department': "00",
            'subjectCode': '2',
            'managerId': selectedPatient.maql,
            'enterCode': selectedPatient.mavaovien,
        }
        const data = {
            "listChiDinh": lstChiDinhBenhNhan,
            "patient": patient,
            "orderid": selectedPatient.id,
            chinhanh: !isNullOrEmpty(chinhanh) ? chinhanh.toString() : "",
        }
        ChiDinhService.LuuChiDinh(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Lưu thành công" });
                if (reLoad) { reLoad() }
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    const deleteRow = (row) => {
        ChiDinhService.XoaChiDinh(row.idChiDinh, row.chinhanh).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công" })
                if (Number(row.type) === 1) {
                    if (modalCLS)
                        modalCLS.current.GetServicesHaveBeenSelected(true);
                    if (reLoad) {
                        reLoad()
                    }
                }
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
                if (modalCLS) {
                    modalCLS.current.GetServicesHaveBeenSelected(true);
                }
                if (reLoad) {
                    reLoad()
                }
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        })
    }
    const modalCLS = useRef();
    const UpdateSelectedFromProps = (data) => {
        modalCLS && modalCLS.current && modalCLS.current.UpdateSelectedFromProps(data);
    }
    return (
        <React.Fragment>
            <ModalCLS maskClosable={false}
                ref={modalCLS}
                showGoiKham={false}
                chinhanh={chinhanh}
                patientID={selectedPatient.mabn}
                managementID={selectedPatient.maql}
                dateIn={selectedPatient.ngayhen}
                deleteFunc={(row) => { deleteRow(row) }}
                editAfterRegister={true}
                onClose={(data) => {
                    if (onClose) {
                        onClose()
                    }
                    AddRow(data)
                }}
                banggia={banggia}
                disableSelectBangGia={disableSelectBangGia}
            />
        </React.Fragment>
    )
})

AddOrder.propTypes = {
    selectedPatient: PropTypes.object,
};
export default AddOrder;