import { Checkbox, DatePicker, Form, Input, Modal, notification, Popconfirm, Select, Table, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../../libs/actions';
import * as KhoService from '../../../../services/KhoService';
import * as NhapKhoService from '../../../../services/medicineService';
import * as PrintService from '../../../../services/printService';
import Nhap from './phieunhap';
import * as SystemConfig from "../../../../configure/systemConfig";

const { Option } = Select;

const initialState = {
    ngay: moment(),
    key: '',
    id: 0,
    ngayrange: [moment(), moment()],
}
const { RangePicker } = DatePicker;
export default class NhapKho extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstNhap: [],
            details: null,
            // visible: false,
            visibleDetail: false,

            totalRecords: 0,
            lstKho: [],
            makho: '',
            currentPage: 1,
            pageSize: 10,
            fromdate: moment(),
            todate: moment(),
            chkbox: false,
            ineditmode: false,
        };
    }
    componentDidMount() {
        this.loadKho();
        this.search();
    }
    loadKho() {
        KhoService.GetListKho('all').then(result => {

            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
        })
    }
    showModal = () => {
        this.setState({ ineditmode: false });
        this.setState({
            visible: true,
        });
    };
    showModalDetail = () => {
        this.setState({
            visible: true,
        });
        this.thongtinHD();
    };

    handleOk = e => {
        this.setState({
            visible: false,
            visibleDetail: false,
            currentPage: 1
        });
        setTimeout(() => {
            this.search();
        }, 200)

    };

    handleCancel = e => {
        if (this.state.ineditmode === false)
            this.search();
        this.setState({
            visible: false,
            visibleDetail: false
        });
    };
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }

    search() {
        this.state.chkbox === false ? (
            NhapKhoService.SearchNhapKho(
                this.state.fromdate.format("YYYY-MM-DD 00:00"), this.state.todate.format("YYYY-MM-DD 23:59"),
                this.state.key, this.state.makho
            ).then(result => {
                if (result.isSuccess) {
                    if (result.data.length > 0) {
                        this.setState({
                            lstNhap: result.data,
                        });
                    }
                    else {
                        this.setState({
                            lstNhap: [],
                        });
                    }
                }
            }).catch(err => {
            }))
            : (
                NhapKhoService.SearchSanPham(
                    this.state.fromdate.format("YYYY-MM-DD 00:00"), this.state.todate.format("YYYY-MM-DD 23:59"),
                    this.state.key, this.state.makho).then(result => {
                        if (result.isSuccess) {
                            this.setState({
                                lstNhap: result.data
                            });
                        }
                        else {
                            this.setState({
                                lstNhap: [],
                            });
                        }
                    }).catch(err => {
                    })
            )

    }
    thongtinHD(row, page = 1, size = 300) {
        let thuoc = true;
        if (this.props.compprops === 2) {
            thuoc = false;
        }
        NhapKhoService.ThongTinHoaDonNhap(moment(row.ngaynhap).format('YYYY-MM-DD'), row.id, thuoc).then(result => {
            if (result.isSuccess) {
                this.setState({
                    details: result.item,
                });

            }
            else {
                notification.warning({ message: result.err.msgString });
            }

        }).catch(err => {
        })
    }

    getUpdate(row, status) {
        let thuoc = true;
        if (this.props.compprops === 2) {
            thuoc = false;
        }
        NhapKhoService.ThongTinHoaDonNhap(moment(row.ngaynhap).format('YYYY-MM-DD'), row.id, thuoc).then(result => {
            if (result.isSuccess) {
                let data = result.item
                data.idPhieu = row.id;
                for (let item of data.lstAddThuoc) {
                    if (item.nghh !== "" && item.nghh != null && moment(item.nghh).year() !== 1) {
                        item.nghh = moment(item.nghh).format("YYYY-MM-DD");
                    } else {
                        item.nghh = null;
                    }
                    if (item.ngsx !== "" && item.ngsx != null && moment(item.ngsx).year() !== 1) {
                        item.ngsx = moment(item.ngsx).format("YYYY-MM-DD");
                    } else {
                        item.ngsx = null;
                    }
                }
                this.setState({visible: true, ineditmode: true }, () => {this.Nhap.setData(result.item, status);})
                // setTimeout(() => {
                //     this.setState({ ineditmode: true })
                //     this.Nhap.setData(result.item, status);
                // }, 100);

            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
        })
    }


    Xoa(row) {
        let thuoc = true;
        if (this.props.compprops === 2) {
            thuoc = false;
        }
        NhapKhoService.deleteNhapKho(row.id, moment(row.ngaynhap).format("YYYY-MM-DD"), thuoc).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.search();
            }
            else {
                this.setState({
                    //lstNhap: [],
                    //  totalRecords: 0
                })
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {
        })
    }

    PrintPhieuNhap(id, ngay) {
        PrintService.PrintPhieuNhapKho(id, moment(ngay).format('YYYY-MM-DD'), 1).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    checkdisable(id) {
        if (id === "0121000001"
            || id === "0121000002"
            || id === "0121000003"
        )
            return true;
    }
    render() {
        const columns = this.state.chkbox === false ? [
            {
                title: 'Số phiếu',
                dataIndex: 'sophieu',
                key: 'sophieu',
                align: 'left',
                width: 120
            },
            {
                title: 'Số hóa đơn',
                dataIndex: 'sohd',
                key: 'id',
                align: 'left',
                width: 120,
            },
            {
                title: 'Ngày nhập',
                dataIndex: 'ngaynhap',
                key: 'ngaynhap',
                align: 'left',
                width: 120,
                render: (value) => {
                    return <span>{moment(value).format(SystemConfig.FORMAT_DATE)}</span>;
                }
            },

            {
                title: 'Kho',
                dataIndex: 'tenkho',
                key: 'tenkho',
                align: 'left',
                width: 200,
            },
            {
                title: 'Nhà cung cấp',
                dataIndex: 'tenncc',
                key: 'tenncc',
                align: 'left',
                width: 300,
            },
            {
                title: 'Chi nhánh',
                dataIndex: 'chinhanh',
                key: 'chinhanh',
                align: 'left',
                width: 90,
            },
            {
                title: 'Action',
                align: 'center',
                width: 250,
                render: (row) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip title="Xem thông tin">
                                <button className="btn btn-color"
                                    title="Chi tiết hóa đơn!"
                                    onClick={() => { this.getUpdate(row, true) }} >
                                    <i className="fa fa-eye" />
                                </button>
                            </Tooltip>
                            <Tooltip title="Cập nhật thông tin">
                                <button className="btn btn-color"
                                    style={{ marginLeft: 10 }}
                                    title="cập nhật hóa đơn!"
                                    onClick={() => { this.getUpdate(row, false) }} >
                                    <i className="fa fa-edit" />
                                </button>
                            </Tooltip>
                            <Popconfirm
                                disabled={this.checkdisable(row.sophieu)}
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.Xoa(row) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <Tooltip title="Xóa phiếu" placement='bottom'>
                                    <button className="btn btn-danger" style={{ marginLeft: "10px" }}
                                        disabled={this.checkdisable(row.sophieu)}><i className="fa fa-eraser" /></button>
                                </Tooltip>                            </Popconfirm>
                            <Tooltip title="In phiếu">
                                <button className="btn btn-outline-secondary"
                                    style={{ marginLeft: 10 }} onClick={() => { this.PrintPhieuNhap(row.id, row.ngaynhap) }} >
                                    <i className="fa fa-print" /></button>
                            </Tooltip>
                        </div>
                    )
                }
            }
        ] : [
            {
                title: 'STT',
                align: 'center',
                render: (text, record, index) => (this.state.lstNhap.indexOf(record) + 1),
                width: 50
            },
            {
                title: 'Ngày nhập',
                dataIndex: 'ngaynhap',
                key: 'ngaynhap',
                align: 'center',
                width: 120,
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'left',
                width: 80
            },
            {
                title: 'Tên sản phẩm',
                dataIndex: 'tensp',
                key: 'tensp',
                align: 'left',
                width: 150
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'right',
                width: 100
            },
            {
                title: 'Kho',
                dataIndex: 'tenkho',
                key: 'tenkho',
                align: 'left',
                width: 150
            },
            {
                title: 'Nhà cung cấp',
                dataIndex: 'tenncc',
                key: 'tenncc',
                align: 'left',
                width: 250
            },
            {
                title: 'Thao tác',
                align: 'center',
                width: 250,
                render: (row) => {
                    return (
                        <div>
                            <Tooltip title="Xem thông tin">
                                <button
                                    className="btn btn-success"
                                    title="Xem thông tin!"
                                    onClick={() => { this.getUpdate(row) }} >
                                    <i className="fa fa-eye"/>
                                </button>
                            </Tooltip>
                            <Tooltip title="Cập nhật thông tin">
                                <button type="primary"
                                    style={{ marginLeft: 10 }}
                                    className="btn btn-warning"
                                    title="Cập nhật sản phẩm!"
                                    onClick={() => { this.getUpdate(row) }} >
                                    <i className=" fa fa-edit"/>
                                </button>
                            </Tooltip>
                            <Popconfirm
                                disabled={this.checkdisable(row.sophieu)}
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.Xoa(row) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <Tooltip title="Xóa phiếu" placement='bottom'>
                                    <button className="btn btn-danger" style={{ marginLeft: "15px" }}
                                        disabled={this.checkdisable(row.sophieu)}><i className="fa fa-eraser" /></button>
                                </Tooltip>
                            </Popconfirm>
                            <Tooltip title="In phiếu">
                                <button className="btn btn-primary"
                                    style={{ marginLeft: 10 }} onClick={() => { this.PrintPhieuNhap(row.id, row.ngaynhap) }} >
                                    <i className="fa fa-print"/></button>
                            </Tooltip>
                        </div>
                    )
                }
            }
        ];
        // let dataSource1 = this.state.lstNhap.map(function (e, index) {
        //     let o = Object.assign({}, e);
        //     o.key = index;
        //     return o;
        // })
        const columnsDetail = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',

            },
            {
                title: 'Lô',
                dataIndex: 'losx',
                key: 'losx',
                align: 'left',
            },
            {
                title: 'Ngày sản xuất',
                dataIndex: 'ngsx',
                key: 'ngsx',
                align: 'left',
                render: (value) => {
                    if (value === null) return <span>Không xác định</span>
                    if (moment(value).format('DD/MM/YYYY') === '01/01/0001') {
                        return <span>Không xác định</span>
                    }
                    else {
                        return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                    }
                },
            },
            {
                title: 'Ngày hết hạn',
                dataIndex: 'nghh',
                key: 'nghh',
                align: 'left',
                render: (value) => {
                    if (value === null) return <span>Không xác định</span>
                    if (moment(value).format('DD/MM/YYYY') === '01/01/0001') {
                        return <span>Không xác định</span>
                    }
                    else {
                        return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                    }
                },
            },
            {
                title: 'Nước',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                align: 'left',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Đơn giá',
                dataIndex: 'giaban',
                key: 'giaban',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
        ];
        let dataSource2 = [];
        if (this.state.details)
            dataSource2 = this.state.details.lstAddThuoc.map(function (e, index) {
                let o = Object.assign({}, e);
                o.key = index;
                return o;
            })
        return (
            <React.Fragment>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} ></a>
                <div className='containerChilderWrapper' >
                    <div className='h3 text-center'>Nhập kho</div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-auto mb-2">
                            <RangePicker format="DD-MM-YYYY" value={this.state.ngayrange} allowClear={false}
                                locale={viVN} onChange={(value => this.setState({ ngayrange: value, fromdate: value[0], todate: value[1] }, () => { this.search() }))}
                            />
                        </div>
                        <div className="col-auto mb-2">
                            <Select style={{ width: '200px' }} onChange={(value, label) => { this.setState({ makho: value }) }}
                                value={this.state.makho}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={-1} value={''}>Tất cả kho</Option>
                                {this.state.lstKho.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="col-auto mb-2">
                            {this.state.chkbox === false ?
                                (<Input style={{ width: "250px" }}
                                    value={this.state.key}
                                    placeholder="Nhập nhà cung cấp, hóa đơn, kho"
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.search();
                                        }
                                    }}
                                />)
                                : <Input style={{ width: "250px" }}
                                    value={this.state.key}
                                    placeholder="Nhập mã, tên sản phẩm hoặc nhà cung cấp"
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.search();
                                        }
                                    }}
                                />
                            }
                        </div>
                        <div className="col-auto mb-2">
                            <Checkbox value={this.state.chkbox} style={{ alignSelf: 'center' }}
                                checked={this.state.chkbox}
                                onChange={(e) => {
                                    setTimeout(() => {
                                        this.setState({ chkbox: e.target.checked },
                                            () => { this.search() }, 800)
                                    })
                                }} >Hiển thị sản phẩm</Checkbox>
                        </div>
                        <div className="col-auto mb-2">
                            <Tooltip title="Tìm">
                                <button className="btn btn-color" onClick={() => { this.search() }}><i className="fa fa-search" /></button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-auto">
                            <button className="btn btn-color"
                                onClick={
                                    () => {
                                        if (this.Nhap) this.Nhap.setNewState();
                                        this.showModal();
                                    }
                                }
                            ><i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                        </div>
                    </div>
                    <div className='mt-2' style={{ width: '100%', height: 'calc(100% - 145px)' }}>
                        <Table dataSource={this.state.lstNhap} columns={columns}
                            scroll={{ x: 'max-content' , y : 0}}
                            rowKey={(e, index) => index}
                            rowClassName={(record, rowIndex) => {
                                return "rowCustom"
                            }}
                            pagination={{
                                showTotal: total => `Tổng: ${total}`,
                                position: ["bottomCenter"],
                                style: {
                                    marginBottom: 5
                                },
                                showSizeChanger: true
                            }}
                        />
                    </div>

                    <Modal maskClosable={false}
                        width={1400}
                        visible={this.state.visibleDetail}
                        onCancel={this.handleCancel}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <div>
                            <div className="modal-header" style={{ display: 'flex', justifyContent: 'left' }}>
                                <h3>Phiếu nhập kho {this.state.details != null ? this.state.details.hoadonnhap.sophieu : ''}</h3>
                            </div>
                            <div className="container-fluid row equal pr-0 pl-2 mt-2" style={{ justifyContent: 'center' }}>
                                <div className="col-md-4 mt-1">
                                    <Form.Item label="Số Phiếu:" style={{ marginTop: 10 }}>
                                        <Input disabled
                                            value={this.state.details != null ? this.state.details.hoadonnhap.sophieu : ''}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-4 mt-1">
                                    <Form.Item label="Số Hóa Đơn:" style={{ marginTop: 10 }}>
                                        <Input disabled
                                            value={this.state.details != null ? this.state.details.hoadonnhap.sohoadon : ''}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-4 mt-1">
                                    <Form.Item label="Tên Kho:" style={{ marginTop: 10 }}>
                                        <Input disabled
                                            value={this.state.details != null ? this.state.details.hoadonnhap.tenkho : ''}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="container-fluid row equal pr-0 pl-2" style={{ marginBottom: 15, justifyContent: 'center' }}>
                                <div className="col-md-4 mt-1">
                                    <Form.Item label="Nhà C.Cấp:" style={{ marginTop: 10 }}>
                                        <Input disabled
                                            value={this.state.details != null ? this.state.details.hoadonnhap.tenncc : ''}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-4 mt-1">
                                    <Form.Item label="Ngày Nhập" style={{ marginTop: 10 }}>
                                        <Input disabled
                                            value={this.state.details != null ? moment(this.state.details.hoadonnhap.ngaynhap).format(SystemConfig.FORMAT_DATE) : ''}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-4 mt-1">
                                    <Form.Item label="Người Nhập" style={{ marginTop: 10 }}>
                                        <Input disabled
                                            value={this.state.details != null ? this.state.details.hoadonnhap.nguoinhap : ''}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='mt-2' style={{ width:'100%', height: 'calc(100% - 145px)' }}>
                            <Table dataSource={dataSource2}
                                pagination={false}
                                scroll={{ y: '65vh' }}
                                columns={columnsDetail} rowKey={(e, index) => index} />
                        </div>
                        <div className='custom-hr'></div>
                        <div className="mt-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <button className="btn btn-danger" style={{ marginLeft: 5 }}
                                    onClick={() => {
                                        this.handleCancel()
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                    /> Đóng

                                </button>
                            </div>
                    </Modal>
                    
                    <Modal
                        width={1500}
                        maskClosable={false} visible={this.state.visible}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <Nhap onCancel={() => {
                            this.search()
                            this.setState({ visible: false })
                        }}
                            // compprops này bị mất khi dùng menu lấy từ API
                            compprops={this.props.compprops ? this.props.compprops : 1} ref={(c) => this.Nhap = c} />
                    </Modal>
                </div>
            </React.Fragment>
        )
    }
}


