import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Button, DatePicker, Pagination, Input, notification, Table, Tooltip, Tag } from 'antd';
import * as Actions from '../../../libs/actions';
import { connect } from 'react-redux';
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Statistic from '../../../services/statisticService';
import * as SystemConfig from "../../../configure/systemConfig";

const { RangePicker } = DatePicker;

function ThongKeDauSinhTon(props) {
    const _hiddenLink = useRef()
    const [date, setDate] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    // const [key, setKey] = useState('');
    const [siteId,] = useState(props.siteid);

    const ThongKeChiSoSinhTon = () => {
        Actions.setLoading(true);
        Statistic.ThongKeChiSoSinhTon(date[0].format("yyyy/MM/DD"), date[1].format("yyyy/MM/DD"), page, size, siteId).then(res => {
            if (res.isSuccess) {
                setDataSource(res.data);
                setTotalRecord(res.totalRecords);
                Actions.setLoading(false);
            }
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    const ExportThongKeChiSoSinhTon = () => {
        Actions.setLoading(true);
        Statistic.ExportThongKeChiSoSinhTon(date[0].format("yyyy/MM/DD"), date[1].format("yyyy/MM/DD"), siteId).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = "DanhSachChiSoSinhTon";
                _hiddenLink.current.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    // const updateChiSoSinhTon = (managermentId) => {

    // }

    // const deleteChiSoSinhTon = (managermentId) => {

    // }

    useEffect(() => {
        ThongKeChiSoSinhTon();
    }, [date, page, size])

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
            width: 70
        },
        {
            title: "Ngày",
            dataIndex: "dateCreate",
            key: "dateCreate",
            align: "center",
            width: 110,
            render: (day) => {
                return <span>{day && moment(day).format(SystemConfig.FORMAT_DATE)}</span>
            }
        },
        {
            title: "Tên bệnh nhân",
            dataIndex: "patientName",
            key: "patientName",
            align: "left",
            width: 150,
        },
        {
            title: "Mã bệnh nhân",
            dataIndex: "patientCode",
            key: "patientCode",
            align: "center",
            width: 120,
        },
        {
            title: "SID",
            dataIndex: "sid",
            key: "sid",
            align: "center",
            width: 120,
        },
        {
            title: "Giới tính",
            dataIndex: "patientGender",
            key: "patientGender",
            align: "center",
            width: 90,
        },
        {
            title: "Ngày sinh",
            dataIndex: "patientDOB",
            key: "patientDOB",
            align: "center",
            width: 110,
            render: (day, row) => {
                return <span>{row.onlyYear ? moment(day).format("YYYY") : moment(day).format(SystemConfig.FORMAT_DATE)}</span>
            }
        },
        {
            title: "Nguồn khách hàng",
            dataIndex: "coporator",
            key: "coporator",
            align: "left",
            width: 250,
        },
        {
            title: "Điện thoại",
            dataIndex: "phone",
            key: "phone",
            align: "right",
            width: 110,
        },
        {
            title: "CCCD",
            dataIndex: "cardID",
            key: "cardID",
            align: "right",
            width: 100,
        },
        {
            title: "Mạch",
            dataIndex: "pulse",
            key: "pulse",
            align: "right",
            width: 90,
        },
        {
            title: "Nhiệt độ",
            dataIndex: "temperature",
            key: "temperature",
            align: "right",
            width: 90,
        },
        {
            title: "Huyết áp",
            dataIndex: "bloodPresure",
            key: "bloodPresure",
            align: "right",
            width: 90,
        },
        {
            title: "Cân nặng",
            dataIndex: "weight",
            key: "weight",
            align: "right",
            width: 90,
        },
        {
            title: "Chiều cao",
            dataIndex: "height",
            key: "height",
            align: "right",
            width: 90,
        },
        {
            title: "BMI",
            dataIndex: "bmi",
            key: "bmi",
            align: "right",
            width: 90,
            render: (value) => { return <Tag color={BMIWarning(value)}>{value}</Tag> }
        },
        // {
        //     title: "Thao tác",
        //     align: "center",
        //     render: (row) => {
        //         return (
        //             <Fragment>
        //                 <button className="btn btn-warning"
        //                     onClick={() => updateChiSoSinhTon(row.managermentId)}>
        //                     <i className="fa fa-edit" />
        //                 </button>
        //                 <button className="btn btn-danger"
        //                     style={{ marginLeft: 10 }}
        //                     onClick={() => deleteChiSoSinhTon(row.managermentId)}>
        //                     <i className="fa fa-trash" />
        //                 </button>
        //             </Fragment>
        //         )
        //     }
        // }
    ];

    const BMIWarning = (BMIValue, gender) => {
        if (Number(BMIValue) < 18.5) return "blue"; // "underWeight";
        else if (18.5 <= Number(BMIValue) <= 24.9) return "green"; //"normal";
        else if (25 <= Number(BMIValue) <= 29.9) return "lime"; // "overWeight";
        else if (30 <= Number(BMIValue) <= 34.9) return "orange"; // "obese";
        else if (Number(BMIValue) > 35) return "red"; // "extremlyObese";
    }

    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
            <div className='containerChilderWrapper'>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê chỉ số sinh tồn</div>
                <div className="d-flex justify-content-center my-1">
                    <div className="mr-2">
                        <RangePicker allowClear={false} format="DD-MM-YYYY" value={date} locale={viVN}
                            onChange={(value => setDate(value))}
                        />
                    </div>
                    {/* <div style={{ width: 350 }} className="mr-2">
                        <Input value={key} placeholder="Thích gì nhập đó, tìm được hay không là do ăn ở"
                            onChange={(e) => setKey(e.target.value)}
                        />
                    </div> */}
                    <div className="d-flex">
                        <Button className="btn btn-color"
                            onClick={() => { ThongKeChiSoSinhTon() }}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                        <Button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { ExportThongKeChiSoSinhTon() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                    </div>
                </div>
                <div style={{ width: "100%", height: 'calc(100% - 120px)', marginTop: 20 }}>
                    <Table
                        scroll={{ y: 0, x: 'max-content' }}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        rowKey={(row, index) => index}
                    />
                </div>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                    total={totalRecord}
                    showTotal={total => `Tổng: ${total}`}
                    size={size}
                    current={page}
                    onChange={(page, size) => {
                        setPage(page);
                        setSize(size);
                    }}
                    onShowSizeChange={(page, size) => {
                        setPage(1);
                        setSize(size);
                    }}
                    showSizeChanger={true}
                />
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ThongKeDauSinhTon)