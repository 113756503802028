import { DatePicker, notification, Table, Select, Tabs } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as PrintService from '../../../services/printService';
import * as StatisticService from '../../../services/statisticService';
import * as ConfigService from '../../../services/configService';
import * as UsersService from '../../../services/usersService';
import ReportByBill from './report/reportbybill';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import HeaderModal from '../../../configureHIS/common/headerModal';
const { RangePicker } = DatePicker;
const { Option } = Select;
export default class baocaotonghop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(moment().format('YYYY-MM-DD') + " 00:00"), moment()],
            dataSource: [],
            tongthucthu: 0,
            tongdoanhthu: 0,
            tongbn: 0,
            hinhthucthanhtoan: 0,
            tienmat: 0,
            chuyenkhoan: 0,
            quetthe: 0,
            congno: 0,
            tongBHYT: 0,
            tongtienBD: 0,
            tonggiamtru: 0,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            seletedItem: 0,
            tongtien: 0,
            listTT: [],
            lstLoaiTT: [],
        };
    }

    componentDidMount() {
        this.setState({ chinhanh: this.props.chinhanh })
        this.GetListSite();
        this.getPaymentMethod();
        this.LoadThongKeDoanhSo();
        this.LoadGeneral();
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }

    LoadGeneral() {
        StatisticService.GetGeneralRevenueByService(this.state.ngay[0].format('YYYY-MM-DD HH:mm'),
            this.state.ngay[1].format('YYYY-MM-DD HH:mm')).then(result => {
                if (result.isSuccess) {
                    let tongthucthu = 0;
                    result.item.lstRevenues.map(x => { tongthucthu += x.totalMoney });
                    this.setState({
                        tongdoanhthu: tongthucthu,
                        listTT: result.item.lstRevenues
                    })
                }
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
    }

    LoadThongKeDoanhSo(paymentType = this.state.hinhthucthanhtoan.toString(), loading = true) {
        Actions.setLoading(loading);
        const fromPayment = true;
        StatisticService.ThongKeTheoDichVuBenhNhan(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), this.state.ngay[1].format('YYYY-MM-DD HH:mm'), paymentType, this.props.data.filter, this.state.chinhanh.toString(), fromPayment).then(result => {
            if (result.isSuccess) {
                let tongbn = 0;
                let tonggiamtru = 0;
                let tongtien = 0;
                result.data.map(x => {
                    tonggiamtru += (x.soluong * x.dongia - x.soluong * x.giabh) - (x.soluong * x.dongia - x.soluong * x.giabh) * ((100 - x.tylekhuyenmai) / 100) * ((100 - x.giamgia) / 100)
                    tongtien += x.tongtien;
                    if (x.mavp === null || x.mavp === '') {
                        tongbn++;
                    }
                });
                this.setState({
                    dataSource: result.data,
                    tongbn: tongbn,
                    tonggiamtru,
                    tongtien,
                    tongtienBD: tongtien
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    PrintTongHopNgay() {
        PrintService.PrintThongkeDichVuBenhNhan(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.state.hinhthucthanhtoan.toString(), "", this.state.chinhanh.toString(), this.props.data.filter).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    ExportThongKeDoanhSo() {
        Actions.setLoading(true);
        StatisticService.ExportThongKeTheoDichVuBenhNhan(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.state.hinhthucthanhtoan.toString(), "", this.state.chinhanh.toString(), this.props.data.filter)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDoanhSoTheoHoaDon";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    GetAllInvoices = (data) => {
        if (data.sobienlai)
            return data.sobienlai;
        else return "";
    }
    getPaymentOwnList = (record) => {
        let tongtien = (record.tongtien).toLocaleString('en-US');
        let result = "";
        if (record.listpayment && record.listpayment.length > 0) {
            record.listpayment.map((item, index) => {
                if (Number(item.total) > 0) {
                    if (result !== "") result += ", ";
                    result += `${item.name}: ${item.total.toLocaleString('en-US')}đ`;
                }
            })
        }
        if (result !== "") result += " - ";
        result += `Tổng: ${tongtien}đ`;
        return result;
    }

    getPaymentMethod() {
        const getAll = true;
        VPService.GetDanhSachLoaiThanhToan(getAll).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiTT: result.data,
                })
            }
        }).catch(err => { })
    }

    render() {
        const columns = [
            {
                title: 'STT',
                width: 50,
                align: 'center',
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <strong style={{ fontSize: '15px', float: 'left', marginLeft: '5px' }}>Biên lai: {this.GetAllInvoices(record)} - {record.tenbn} - {record.ngay}</strong>,
                            props: {
                                colSpan: 11,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{this.state.dataSource.filter(x => x.sobienlai === record.sobienlai).indexOf(record)}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Sản phẩm/Dịch vụ',
                dataIndex: 'tenvp',
                key: 'tenvp',
                width: 150,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span>{ }</span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{record.tenvp}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'SL',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span>{ }</span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{record.soluong}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 100,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{record.dongia.toLocaleString('en-US')}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Thành tiền',
                align: 'right',
                width: 100,
                render: (text, row, index) => {
                    if (row.mavp === null || row.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{Math.round((row.soluong * row.dongia)).toLocaleString('en-US')}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            // {
            //     title: 'BHYT trả',
            //     align: 'right',
            //     width: 120,
            //     render: (text, row, index) => {
            //         if (row.mavp === null || row.mavp === '') {
            //             return {
            //                 children: <span></span>,
            //                 props: {
            //                     colSpan: 0,
            //                 }
            //             }
            //         }
            //         else {
            //             return {
            //                 children: <span>{(row.soluong * row.giabh).toLocaleString('en-US')}</span>,
            //                 props: {
            //                     colSpan: 1,
            //                 }
            //             }
            //         }
            //     },
            // },
            {
                title: 'Giảm trừ khác',
                align: 'right',
                width: 100,
                render: (text, row, index) => {
                    if (row.mavp === null || row.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{(Number(((row.soluong * row.dongia - row.soluong * row.giabh) - (row.soluong * row.dongia - row.soluong * row.giabh) * ((100 - row.tylekhuyenmai) / 100) * ((100 - row.giamgia) / 100)).toFixed(3))).toLocaleString('en-US')}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Thanh toán',
                align: 'right',
                width: 100,
                render: (text, row, index) => {
                    if (row.mavp === null || row.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{(Number(((row.soluong * row.dongia - row.soluong * row.giabh) * (1 - row.tylekhuyenmai / 100) * (1 - row.giamgia / 100)).toFixed(3))).toLocaleString('en-US')}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            // {
            //     title: 'Công nợ',
            //     align: 'right',
            //     width: 100,
            //     render: (text, record, index) => {
            //         if (record.mavp === null || record.mavp === '') {
            //             return {
            //                 children: <span></span>,
            //                 props: {
            //                     colSpan: 0,
            //                 }
            //             }
            //         }
            //         else {
            //             return {
            //                 children: <span>0</span>,
            //                 props: {
            //                     colSpan: 1,
            //                 }
            //             }
            //         }
            //     },
            // },
            // {
            //     title: 'Thu thực tế',
            //     align: 'right',
            //     width: 100,
            //     render: (text, row, index) => {
            //         if (row.mavp === null || row.mavp === '') {
            //             return {
            //                 children: <span></span>,
            //                 props: {
            //                     colSpan: 0,
            //                 }
            //             }
            //         }
            //         else {
            //             return {
            //                 children: <span>{((row.soluong * row.dongia) * (1 - row.tylekhuyenmai / 100) * (1 - row.giamgia / 100)).toLocaleString('en-US')}</span>,
            //                 props: {
            //                     colSpan: 1,
            //                 }
            //             }
            //         }
            //     },
            // },
            // {
            //     title: 'Hình thức thanh toán',
            //     align: 'right',
            //     width: 130,
            //     render: (text, record, index) => {
            //         if (record.mavp === null || record.mavp === '') {
            //             return {
            //                 children: <span></span>,
            //                 props: {
            //                     colSpan: 0,
            //                 }
            //             }
            //         }
            //         else {
            //             return {
            //                 children: <span>{ }</span>,
            //                 props: {
            //                     colSpan: 1,
            //                 }
            //             }
            //         }
            //     },
            // },
            {
                title: 'Người lập',
                dataIndex: 'nguoilap',
                key: 'nguoilap',
                width: 150,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{text}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Hình thức',
                dataIndex: 'hinhthuc',
                key: 'hinhthuc',
                width: 150,
                render: (type, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        let foundHinhThucThanhToan = this.state.lstLoaiTT && this.state.lstLoaiTT.find(x => Number(x.ma) === Number(type));
                        let hinhthucthanhtoan = (Number(type) > 0 && this.state.lstLoaiTT && foundHinhThucThanhToan !== undefined && foundHinhThucThanhToan.ten)
                        if (Number(type) == 4 && record.congnodathu !== null && record.congnodathu !== "") {
                            return {
                                children: <span>
                                    {hinhthucthanhtoan} (Đã thu)
                                </span>,
                                props: {
                                    colSpan: 1,
                                }
                            }
                        } else {
                            return {
                                children: <span>
                                    {hinhthucthanhtoan}
                                </span>,
                                props: {
                                    colSpan: 1,
                                }
                            }
                        }
                    }
                },
            },
            // {
            //     title: 'Ngày lập',
            //     dataIndex: 'ngay',
            //     key: 'ngay',
            //     width: 100,
            //     render: (text, record, index) => {
            //         if (record.mavp === null || record.mavp === '') {
            //             return {
            //                 children: <span></span>,
            //                 props: {
            //                     colSpan: 0,
            //                 }
            //             }
            //         }
            //         else {
            //             return {
            //                 children: <span>{text}</span>,
            //                 props: {
            //                     colSpan: 1,
            //                 }
            //             }
            //         }
            //     },
            // }
        ]


        return (
            <React.Fragment>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                <HeaderModal title={this.props.data.title} onClose={() => { this.props.onCancel && this.props.onCancel() }} />
                <div className='list-page'>
                    <div style={{ background: 'aliceblue', borderRadius: 8 }}>
                        <div className="row mt-2">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                        showTime={{ format: 'HH:mm' }}
                                        allowClear={false} format="DD-MM-YYYY HH:mm" value={this.state.ngay}
                                        placeholder={['DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm']}
                                        onChange={(value => this.setState({ ngay: value }, () => {
                                            this.LoadGeneral()
                                            this.LoadThongKeDoanhSo()
                                        }))}
                                        getPopupContainer={(e) => e.parentNode}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2" style={{ overflow: 'auto', height: 108, }}>
                            <div className="col-lg-3 col-sm-6 col-xs-12">
                                <div className='container' onClick={() => { this.setState({ hinhthucthanhtoan: 0 }, () => this.LoadThongKeDoanhSo()) }}>
                                    <div className={`r4_counter row mb-0 mt-2 ${this.state.hinhthucthanhtoan === 0 && "btn-color"}`}>
                                        <div className="col-3" style={{ alignSelf: 'center', textAlign: 'center' }}>
                                            <i className="fas fa-dollar-sign fa-3x" style={{ color: 'cadetblue' }}></i>
                                        </div>
                                        <div className="col-8" style={{ alignSelf: 'center' }}>
                                            <div><h3>{this.state.tongdoanhthu.toLocaleString('en-US')}</h3></div>
                                            <span style={{ textAlign: 'left' }}>Tổng doanh thu</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.listTT && this.state.listTT.map((loaiTT, index) => {
                                    return (
                                        <div className="col-lg-3 col-sm-6 col-xs-12">
                                            <div className="container" onClick={() => { this.setState({ hinhthucthanhtoan: loaiTT.revenueID }, () => this.LoadThongKeDoanhSo()) }}>
                                                <div className={`r4_counter row mb-0 mt-2 ${this.state.hinhthucthanhtoan === loaiTT.revenueID && "btn-color"}`}>
                                                    <div className="col-3" style={{ alignSelf: 'center', textAlign: 'center' }}>
                                                        <i className="fas fa-money-bill-alt fa-3x" style={{ color: 'cadetblue' }}></i>
                                                    </div>
                                                    <div className="col-8" style={{ alignSelf: 'center' }}>
                                                        <div><h3>{loaiTT.totalMoney.toLocaleString('en-US')}</h3></div>
                                                        <span style={{ textAlign: 'left' }}>{loaiTT.revenuName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="list-page-body">
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Chi tiết doanh thu" key="1">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div>
                                        <label>Tổng biên lai: <strong>{this.state.tongbn.toLocaleString('en-US')}</strong></label>
                                        <label className='ml-2'>Thực thu: <strong>{this.state.tongtienBD.toLocaleString('en-US')}</strong></label>
                                        {/* <label className='ml-2'>BHYT trả: <strong>{this.state.tongBHYT.toLocaleString('en-US')}</strong></label> */}
                                        <label className='ml-2'>Giảm trừ khác: <strong>{this.state.tonggiamtru.toLocaleString('en-US')}</strong></label>
                                        {/* <label className='ml-2'>Tổng thanh toán: <strong>{this.state.tongtien.toLocaleString('en-US')}</strong></label>  */}
                                    </div>
                                    <div>
                                        <button className='btn btn-color' onClick={() => { this.ExportThongKeDoanhSo() }}><i className="fas fa-download mr-1"></i>Xuất excel</button>
                                        <button className='btn btn-outline-secondary ml-2' onClick={() => { this.PrintTongHopNgay() }}><i className="fa fa-print mr-1"></i>In</button>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100% - 48px)' }}>
                                    <Table
                                        pagination={false} sticky
                                        scroll={{ x: 'max-content', y: 0 }}
                                        dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Hình thức thanh toán" key="2">
                                <ReportByBill ref={(c) => this.ReportByBill = c} Ngay={this.state.ngay} paymentType={this.state.hinhthucthanhtoan} filter={this.props.data.filter.toString()} />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                    <div className="list-page-footer text-center">
                        <button className="btn btn-danger"
                            onClick={() => { this.props.onCancel() }}><i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
