import React, { Fragment, useState, useEffect } from 'react';
import BOX from "./box";
import './index.scss';
import * as QMSService from '../../services/QMSService';
import UnSupport from '../unknowPage/unSupport';

function QMS(props) {
    const [roomInfo, setRoomInfo] = useState([]);
    const [errMsg, setErrMsg] = useState();
    const getColumn = () => {
        let colClassName = "col-12";
        if (roomInfo && roomInfo.length >= 4) {
            colClassName = "col-6"
        } else {
            let colValue = 12 / roomInfo.length;
            colClassName = `col-${colValue}`;
        }
        return colClassName;
    }
    const getHeight = () => {
        let height = "100%";
        if (roomInfo && roomInfo.length >= 4) {
            height = "50%"
        }
        return height;
    }
    useEffect(() => {
        let currentLocation = window.location;
        const qmsConfig = currentLocation.search.replace("?id=", "")
        getQMS(qmsConfig);
        setInterval(() => {
            getQMS(qmsConfig);
        }, 30000);
    }, [])
    const getQMS = (QMSId) => {
        QMSService.GetQMS(QMSId).then(result => {
            if (result.isSuccess) {
                setRoomInfo(result.data);
            } else {
                setErrMsg(result.err.msgString);
                setRoomInfo([]);
            }
        }).catch(() => { });
    }
    return (
        <Fragment>
            {roomInfo && roomInfo.length > 0 ?
                <div className="QMS row">
                    {roomInfo.map((room, index) => {
                        return (
                            <div className={getColumn()} style={{ height: getHeight() }}>
                                <BOX roomInfo={room} index={index} />
                            </div>
                        )
                    })}
                </div>
                : <div style={{ width: "100vw", height: "100vh" }}><UnSupport title={errMsg ?? "Không có gì ở đây"} /></div>}
        </Fragment>
    );
}

export default QMS;