import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Input } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import { ValidationCustom } from '../../commons/validationCustom';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../components/notify';
import * as CompanyExamService from '../../services/CompanyExamService';
import { connect } from 'react-redux';
import ListTiepDon from './listTiepDon';

const { TextArea } = Input;
const listSendResultTypes = [ // lấy theo SendMessageType trong HIS.Resource/Enum
  // Sms,
  // Zalo,
  // Email
  {value: 0, text: 'Sms'},
  {value: 1, text: 'Zalo'},
  {value: 2, text: 'Email'},
]
const DownLoadTemplate = React.forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const upload = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  //state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [detailScreenMode, setDetailScreenMode] = useState(props.detailScreenMode);
  //model
  const [id, setId] = useState(props.companyExamId);
  const [coporationId, setCoporationId] = useState();
  const [coporationName, setCoporationName] = useState();
  const [siteId, setSiteId] = useState();
  const [siteName, setSiteName] = useState();
  const [saleId, setSaleId] = useState();
  const [saleName, setSaleName] = useState();
  const [nguoiGioiThieuId, setNguoiGioiThieuId] = useState(null);
  const [nguoiGioiThieuName, setNguoiGioiThieuName] = useState(null);
  const [contractNo, setContractNo] = useState();
  const [sendResultTypes, setSendResultTypes] = useState([]);
  const [sendResultTypeNames, setSendResultTypeNames] = useState([]);
  const [listFileImport, setListFileImport] = useState([]);
  const [remark, setRemark] = useState();
  //#region useEffect
  useEffect(() => {
    getById(props.companyExamId);
  }, []);
  //#endregion 
  const setData = (data) => {
    setCoporationId(data.coporationId);
    setCoporationName(data.coporationName);
    setSiteId(data.siteId);
    setSiteName(data.siteName);
    setSaleId(data.saleId);
    setSaleName(data.saleName);
    setNguoiGioiThieuId(data.nguoiGioiThieuId);
    setNguoiGioiThieuName(data.nguoiGioiThieuName);
    setContractNo(data.contractNo);
    setSendResultTypes(data.sendResultTypes);
    if (data.sendResultTypes && data.sendResultTypes.length) {
      setSendResultTypeNames(
        listSendResultTypes.filter(x => data.sendResultTypes.indexOf(x.value) > -1).map(x => x.text).join(", ")
      );
    }
    setListFileImport(data.listFileImport);
    setRemark(data.remark);
  }

  const getById = (id) => {
    if (!id) return;
    Actions.setLoading(true);
    CompanyExamService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
    // forceUpdate();
  }

  const  downloadFile = async (record) => {
    try {
      let filePath = record.host + '/' + record.filePath; 
      // let filePath = record.filePath;//như này nó auto lấy host là host đang chạy
      var reader = new XMLHttpRequest();
      reader.open('HEAD', filePath, false);
      reader.send();
      if (reader.status == 200) {
        notiRef && notiRef.current.Close();
        hiddenLink.current.href =  filePath;
        hiddenLink.current.download = "importfile"+moment(new Date()).format('YYMMDDhhmmss');
        hiddenLink.current.click();
      }
    }
    catch(error) {
      notiRef.current.Show('Không tìm thấy file', NotifyStatus.Warning);
    }
  }

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Xem chi tiết</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel();
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row">{/* import file */}
                <div className="col-md-12 d-flex flex-column">
                  <label>Đính kèm</label>
                    {(listFileImport ?? []).map((record, index) => {
                      return (
                        <div key={index}>
                          <a href="#" 
                            onClick={() => {downloadFile(record);}}
                          >{record.fileName}</a>
                          <span> ({moment(record.dateImport).format(SystemConfig.FORMAT_DATE_TIME)})</span>
                        </div>
                      )
                    })}
                </div>
              </div>
              <div className="row mt-2">{/* Chi nhánh | Số hợp đồng */}
                <div className="col-md-6 d-flex flex-column">
                  <label>Chi nhánh khám</label>
                  <label>{siteName}</label>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label>Số hợp đồng</label>
                  </div>
                  <Input
                    value={contractNo}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                  ></Input>
                </div>
              </div>
              <div className="row mt-2">{/* Nguồn khách hàng | Nhân viên sales */}
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label>Nguồn khách hàng</label>
                  </div>
                  <Input
                    value={coporationName}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                  ></Input>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label>Nhân viên sales</label>
                  <Input
                    value={saleName}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                  ></Input>
                </div>
              </div>
              <div className="row mt-2">{/* Người giới thiệu | Hình thức trả kết quả */}
                <div className="col-md-6 d-flex flex-column">
                  <label>Người giới thiệu</label>
                  <Input
                    value={nguoiGioiThieuName}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                  ></Input>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label>Hình thức trả kết quả</label>
                  <Input
                    value={sendResultTypeNames}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                  ></Input>
                </div>
              </div>
              <div className="row mt-2">{/* Ghi chú */}
                <div className="col-md-12 d-flex flex-column">
                  <label>Ghi chú</label>
                  <TextArea 
                    rows={4}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    value={remark}
                  ></TextArea >
                </div>
              </div>
              <div className="list-page-table mt-2">{/* list */}
                <ListTiepDon
                  isDialog={ true }
                  hiddenClose={ true }
                  companyExamId={id}
                ></ListTiepDon>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div >
            </div>
            <div className='d-flex justify-content-end' >
                <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
      siteCode: state.global.siteCode,
      siteName: state.global.siteName
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DownLoadTemplate);