

export let IsSuperAdmin = () => {
    return sessionStorage.getItem("isSuperAdmin");
}

export let Set = () => {
    sessionStorage.setItem("isSuperAdmin", true);
}

export let Remove = () => {
    sessionStorage.removeItem("isSuperAdmin");
}
