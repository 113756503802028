import moment from "moment";
import { isNullOrEmpty } from "../libs/util";
const DATE_FORMAT_SYSTEM = localStorage.getItem("dateFormat");
const TIME_FORMAT_SYSTEM = localStorage.getItem("timeFormat");
const regex = /\w/g;
const SEPARATE_CHARACTER = DATE_FORMAT_SYSTEM ? DATE_FORMAT_SYSTEM.replace(regex, "").charAt(0) : "";
const HOUR_TYPE = TIME_FORMAT_SYSTEM ? TIME_FORMAT_SYSTEM.includes("12") ? "hh" : "HH" : "HH";
const AM_PM = TIME_FORMAT_SYSTEM && TIME_FORMAT_SYSTEM.includes("12") ? "A" : "";
export let FORMAT_DATE = !isNullOrEmpty(SEPARATE_CHARACTER) ? `DD${SEPARATE_CHARACTER}MM${SEPARATE_CHARACTER}YYYY` : window.publicConfig.dateMonthYear;
export let FORMAT_DATE_FULL = `[Ngày] DD [tháng] MM [năm] YYYY`;
export let FORMAT_MONTH_DATE = !isNullOrEmpty(SEPARATE_CHARACTER) ? `MM${SEPARATE_CHARACTER}DD${SEPARATE_CHARACTER}YYYY` : window.publicConfig.monthDateYear;
export let FORMAT_DATE_TIME = !isNullOrEmpty(SEPARATE_CHARACTER) ? `DD${SEPARATE_CHARACTER}MM${SEPARATE_CHARACTER}YYYY ${HOUR_TYPE}:mm ${AM_PM}` : window.publicConfig.dateMonthYearHourMinus;
export let FORMAT_DATE_TIME_FULL = `[Ngày] DD [tháng] MM [năm] YYYY, ${HOUR_TYPE} [giờ] mm ${AM_PM}`;
export let FORMAT_TIME_DATE = !isNullOrEmpty(SEPARATE_CHARACTER) ? `${HOUR_TYPE}:mm, DD${SEPARATE_CHARACTER}MM${SEPARATE_CHARACTER}YYYY` : window.publicConfig.hourMinusDateMonthYear;
export let FORMAT_TIME_DATE_FULL = !isNullOrEmpty(AM_PM) ? `${HOUR_TYPE} [giờ] mm ${AM_PM}, [ngày] DD [tháng] MM [năm] YYYY`
                                                        : `${HOUR_TYPE} [giờ] mm, [ngày] DD [tháng] MM [năm] YYYY`;
export let FORMAT_DATE_TIME_SECOND = !isNullOrEmpty(SEPARATE_CHARACTER) ? `DD${SEPARATE_CHARACTER}MM${SEPARATE_CHARACTER}YYYY ${HOUR_TYPE}:mm:ss ${AM_PM}` : window.publicConfig.dateMonthYearHourMinusSecond;
export let FORMAT_YYYYMMDD = !isNullOrEmpty(SEPARATE_CHARACTER) ? `YYYY${SEPARATE_CHARACTER}MM${SEPARATE_CHARACTER}DD` : 'YYYY-MM-DD';
export let FORMAT_DD = 'DD';

export const LIST_PATIENT_MODE = {
    NORMAL: 'Normal', 
    READ: 'Read'
}

export let PAYMENT_WITHOUT_MEDICINE = true;

export let COUNTDOWN1 = (time, expiredTextDefault = '') =>{
    let expTimeMinute = moment(time).diff(moment(), "minutes");
    let expTimeText = '';
    switch (true) {
        case (expTimeMinute <= 0):
            expTimeText = expiredTextDefault;
            break;
        case (expTimeMinute > 0 && expTimeMinute <= 60):
            expTimeText = `Còn ${expTimeMinute} phút`;
            break;
        case (expTimeMinute > 60 && expTimeMinute <= (60*24)):
            let expTimeHour = Math.floor(expTimeMinute/60);
            expTimeText = `Còn ${expTimeHour} tiếng ${expTimeMinute%60} phút`;
            break;
        case (expTimeMinute > (60*24)):
            expTimeHour = Math.floor(expTimeMinute/60);
            let expTimeDay = Math.floor(expTimeHour/24);
            expTimeMinute = expTimeMinute - (expTimeHour*60)
            expTimeText = `Còn ${expTimeDay} ngày ${expTimeHour%24} tiếng ${(expTimeMinute%60)} phút`;
            break;
        default: 
            expTimeText = '';
            break;
    }
    return expTimeText;
};