import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef } from 'react';
import { SearchOutlined, FilterOutlined, EditFilled } from '@ant-design/icons';
import { Input, Button, Table, Space, DatePicker, Switch, notification, Modal, Tooltip, Popconfirm, Pagination, Checkbox, Dropdown, Menu } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import * as PromotionService from '../../services/PromotionServices';
import * as PromotionVoucherService from '../../services/PromotionVoucherServices';
import PromotionCreate from './create';
import { data } from "jquery";
import Notify, { NotifyStatus } from '../../components/notify';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";

export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const createRef = useRef();
  const _hiddenLink = useRef();
  const deletePopupRef = useRef([]);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  const [filterModel, setFilterModel] = useState({
    promotionName: '',
    remark: '',
    promotionTypes: [], 
    beginningDateFrom: '',
    beginningDateTo: '',
    endingDateFrom: '',
    endingDateTo: '',
  });
  //list model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //
  const [filteringColumns, setFilteringColumns] = useState('');
  const [listPromotionType, setListPromotionType] = useState([]);
  //row options
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [promotionIdToEdit, setPromotionIdToEdit] = useState(null);
  //table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    search();
  }, [pageIndex, sorting])

  useEffect(() => {
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
    getLListPromotionType();
  }, []);
  //#endregion 
  //#region search & filter
  const validateSearch = () => {
    if (filterModel.beginningDateFrom > filterModel.beginningDateTo
      || filterModel.endingDateFrom > filterModel.endingDateTo
    ) {
      notiRef.current.Show('"Từ ngày" phải nhỏ hơn hoặc bằng "Đến ngày"' , NotifyStatus.Warning);
      return false;
    }
    return true;
  }

  const search = (isResetSelectedRow = false) => {
    if (!validateSearch()) return;
    let sortingAndPagingModel = {pageIndex: pageIndex, pageSize: pageSize, sorting: sorting};
    Actions.setLoading(true);
    PromotionService.getList(sortingAndPagingModel, filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        if (isResetSelectedRow) setSelectedRowKeys([]);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
  }

  const resetToFirstPage = (isResetSelectedRow = false) => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }

  const setFilterModelFieldValue = (field, value) => {
    let fModel = filterModel;
    fModel[field] = value;
    setFilterModel(fModel);
    resetToFirstPage();
  }

  const setFilterModelFieldValues = (fieldValues = [{field: null, value: null}]) => {
    let fModel = filterModel;
    fieldValues.forEach(element => {
      fModel[element.field] = element.value;
    });
    setFilterModel(fModel);
    resetToFirstPage();
  }

  const getLListPromotionType = () => {
    PromotionService.getListPromotionType().then(result => {
        if (result.isSuccess) {
          setListPromotionType(result.data.items);
        }
        else {
            notification.warning({ message: result.Error.MessageText });
        }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notification.error({ message: err.error.messageText });
      else notification.error({ message: err });
    })
  }
  //#endregion 
  //#region search input text
  const handleSearchText = (selectedKeys, confirm, dataIndex, filters) => {
    // confirm(); // gọi confirm() là nó filter trên client (với dữ liệu của page hiện tại). vì thế không cần gọi confirm, mà gọi api
    let fModel = filterModel;
    fModel[dataIndex] = (selectedKeys[0] ? selectedKeys[0] : '');
    setFilterModel(fModel);
    if (pageIndex != 1) setPageIndex(1);
    else search(true);
    if (fModel[dataIndex] && fModel[dataIndex].length > 0)
      setFilteringColumns(filteringColumns+'['+dataIndex+']');
    else
      setFilteringColumns(filteringColumns.replaceAll('['+dataIndex+']'),'');
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchTextProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
      <div style={{ padding: 8, }} >
        <Input
          // ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchText(selectedKeys, confirm, dataIndex, filters)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchText(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90, }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        // onClick={() => { searchInput.current.focus(); }}
        style={{
          color: filteringColumns.indexOf(dataIndex)>-1 ? 'yellow' : "white",
        }}
      />
    ),
  });
  //#endregion 
  //#region filter by PromotionType
  const onFilterPromotionTypeChange = (checkedValues) => {
    setFilterModelFieldValue('promotionTypes', checkedValues);
  };

  const onFilterPromotionTypeCheckAllChange = (e) => {
    setFilterModelFieldValue('promotionTypes', e.target.checked ? listPromotionType.map(element => {return element.key}) : []);
  }
  const getColumnFilterPromotionTypeProps = () => ({
    filterDropdown: ({  }) => (
      <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }} >
        <Space style={{borderBottom: "solid 1px lightgray"}}>
            <Checkbox 
              checked={filterModel.promotionTypes.length == listPromotionType.length}
              indeterminate={filterModel.promotionTypes.length > 0 && filterModel.promotionTypes.length < listPromotionType.length}
              onChange={onFilterPromotionTypeCheckAllChange}
            >Tất cả</Checkbox>
        </Space>
        <Checkbox.Group
          options={listPromotionType.map(element => {return {label: element.text, value: element.key}})}
          defaultValue={filterModel.promotionTypes}
          value={filterModel.promotionTypes}
          onChange={onFilterPromotionTypeChange}
        />
      </div>
    ),
    filterIcon: () => (
      <FilterOutlined
        style={{
          color: filterModel.promotionTypes.length > 0 ? 'yellow' : "white",
        }}
      />
    ),
  });
  //#endregion
  //#region filter by BeginningDate & EndingDate
  const onFilterDateChange = (selectedKeys, field) => {
    let dateFrom = selectedKeys[0] ? moment(selectedKeys[0]).format(SystemConfig.FORMAT_YYYYMMDD) : '';
    let dateTo= selectedKeys[1] ? moment(selectedKeys[1]).format(SystemConfig.FORMAT_YYYYMMDD) : '';
    let filterDateObj = [
      { field: field + 'From', value: dateFrom },
      { field: field + 'To', value: dateTo }
    ];
    setFilterModelFieldValues(filterDateObj);
  };
  const getColumnFilterDateFromToProps = (field) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys = [null, null], confirm, clearFilters }) => (
      <div style={{ padding: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
        <Space>
          <DatePicker allowClear={true}
            value={selectedKeys[0]}
            onChange={(e) => {setSelectedKeys([e, selectedKeys[1]])}}
            // onChange={(date, dateString) => onFilterDateChange(date, dateString, 'beginningDateFrom')} 
            format={SystemConfig.FORMAT_DATE}
            placeholder='Từ ngày'
          /> ~ 
          <DatePicker allowClear={true}
            value={selectedKeys[1]}
            onChange={(e) => {setSelectedKeys([ selectedKeys[0], e])}}
            // onChange={(date, dateString) => onFilterDateChange(date, dateString, 'beginningDateTo')} 
            format={SystemConfig.FORMAT_DATE}  
            placeholder='Đến ngày'
          />
        </Space>
        <Space style={{ justifyContent: 'center', marginTop: 6 }}>
          <Button
            type="primary"
            onClick={() => onFilterDateChange(selectedKeys, field)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {clearFilters && handleReset(clearFilters); onFilterDateChange([null, null], field)}}
            size="small"
            style={{ width: 90, }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterOutlined
        style={{
          color: filterModel[field+'From'] || filterModel[field+'To'] > 0 ? 'yellow' : "white",
        }}
      />
    ),
  });
  //#endregion
  //#region table info & event
  const loadDetail = (row, detailScrMode) => {
    setDetailScreenMode(detailScrMode);
    setPromotionIdToEdit(row.id);
    setVisibleCreate(true);
    if (createRef && createRef.current) {
      createRef.current.loadData(row.id);
    }
    else setTimeout(() => {
      createRef.current.loadData(row.id);
    },100)
  }

  const onDeleteClickHandle = (id) => {
    PromotionService.delete_promotion(id).then(result => {
      if (result.isSuccess) {
        if (listResult.length == 1) setPageIndex(pageIndex - 1);
        else search();
        setSelectedRowKeys(selectedRowKeys.filter(x => x.id != id));
        notification.success({message: "Thành công"});
      }
      else {
        notification.error({ message: result.error.messageText });
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notification.error({ message: err.error.messageText });
      else notification.error({ message: err });
    })
  };
  
  const onExportListPromotionClickHandle = () => {
    if (!validateSearch()) return;
    let sortingAndPagingModel = {pageIndex: pageIndex, pageSize: pageSize, sorting: sorting};

    Actions.setLoading(true);
    PromotionService.exportListPromotions(sortingAndPagingModel, filterModel)
      .then(result => {
        result.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          _hiddenLink.current.href = url;
          _hiddenLink.current.download = "DanhSachCTKM";
          _hiddenLink.current.click();
        })
      }).catch(err => {
        if (err.error && err.error.messageText)
          notiRef.current.show(err.error.messageText, NotifyStatus.Warning);
        else notiRef.current.show(err, NotifyStatus.Warning);
      }).finally(() => {
        Actions.setLoading(false);
      });
  }

  const onExportVoucherClickHandle = (promotionIds = []) => {
    if (!promotionIds || promotionIds.length == 0) {
      notification.warning({ message: "Vui lòng chọn chương trình khuyến mại" })
      return;
    }
    Actions.setLoading(true);
    let filterModel = {
      promotionIds: promotionIds,
      // keyword: keyword,
      voucherStates: [],
    }
    PromotionVoucherService.exportList(filterModel)
      .then(result => {
        result.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          _hiddenLink.current.href = url;
          _hiddenLink.current.download = "DanhSachvoucher";
          _hiddenLink.current.click();
        })
      }).catch(err => {
        if (err.error && err.error.messageText)
          notiRef.current.show(err.error.messageText, NotifyStatus.Warning);
        else notiRef.current.show(err, NotifyStatus.Warning);
      }).finally(() => {
          Actions.setLoading(false);
      })
  }

  useEffect(() => {
    // console.log(`selectedRowKeys useEffect: ${selectedRowKeys}`);
  }, [selectedRowKeys])

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      /*TH 1: hợp khi đổi page.
      vd: page 1: check 2 cái, qua page 2 check tiếp 3 cái -> tổng check 5 cái -> chuyển qua lại các page thì vẫn 2 check page & 3 check page 2.
      issue: checkAll? do đổi page là load dữ liệu khác -> vậy đang ở page thì làm sao biết page khác có gì mà lưu check.
      Trên UI vẫn làm được (tạo 1 flag isCheckAll), nhưng khi cần thao tác đến dữ liệu (xóa, hay gửi hay gì gì đó) thì ...
      1 cách khách là khi checkAll thì chỉ check all trên page, rồi lưu tập key vào hợp -> hợp lý
      */
    //  console.log('selectedRows =-----');
    // //  console.log(selectedRows);
    //  console.log(selectedRowKeys);
    //   let yourArray = [...selectedRowKeys,...newSelectedRowKeys];
    //   yourArray = Array.from(new Set(yourArray.map((item) => item)));
      // setSelectedRowKeys(yourArray);

      // TH 2: các check chỉ tồn tại trong 1 page. qua page khác rồi quay lại thì mất
      // setSelectedRowKeys(newSelectedRowKeys);
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      let newSelecteds = selectedRowKeys.filter(x => x != record.id);
      if (selected) {
        newSelecteds.push(record.id);
      }
      setSelectedRowKeys(newSelecteds);
      return true;
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      let selectedRowIds = changeRows.map(c => c.id);
      let newSelecteds = selectedRowKeys.filter(x => selectedRowIds.indexOf(x) < 0);
      if (selected) {
        newSelecteds = [...newSelecteds,...changeRows.map(x => x.id)];
        newSelecteds = Array.from(new Set(newSelecteds.map((item) => item)));
      }
      setSelectedRowKeys(newSelecteds);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const clonePromotion = (id) => {
    Actions.setLoading(true);
    PromotionService.clone(id).then(result => {
      if (result.isSuccess) {
        loadDetail(result.data, detailScreenModeEnum.update);
        search();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
        Actions.setLoading(false);
    })
  }

  const rowOptions = (record) => {
    return (
      <Menu
        items={[
          {
            label: 
              <a href="#" onClick={() => {
                loadDetail(record, detailScreenModeEnum.update);
              }}><EditFilled /><i className='fa fa-pencil mr-2'/> Chỉnh sửa</a>,
            key: '0',
          },
          {
            label: 
              <a href="#" onClick={() => { deletePopupRef.current[record.id].click() }}
              ><i className='fas fa-trash-alt mr-2'/> Xóa chương trình</a>,
            key: '1',
          },
          {
            label: 
              <a href="#" onClick={() => {
                clonePromotion(record.id);
              }}><i className='fa fa-clone mr-2'/> Sao chép</a>,
            key: '2',
          },
          {
            label: 
              <a href="#" onClick={() => {
                onExportVoucherClickHandle([record.id]);
              }}><i className='fa fa-file-excel mr-2'/> Xuất excel voucher</a>,
            key: '3',
          },
        ]}
      />
    )
  }
  const columns = [
      {
          title: '#',
          dataIndex: 'stt',
          key: 'stt',
          align: 'center',
          width: 40,
          render: (text, record, index) => {
              return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
          }
      },
      Table.SELECTION_COLUMN,
      {
          title: 'Tên chương trình',
          dataIndex: 'promotionName',
          key: 'promotionName',
          align: 'left',
          width: 250,
          sorter: () => {},
          ...getColumnSearchTextProps('promotionName', 'Tên chương trình'),
          render: (value, record) => {
            return (
              <>
                <a href="#"
                  onClick={() => {
                    loadDetail(record, detailScreenModeEnum.detail);
                  }}
                >{value}
                </a>
                <Popconfirm
                    title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{value}</b>?</span>)}}
                    onConfirm={() => onDeleteClickHandle(record.id)}
                    onCancel={() => {}}
                    okText="Đồng ý"
                    cancelText="Hủy"
                  >
                    <a ref={e => deletePopupRef.current[record.id] = e}></a>
                  </Popconfirm>
                </>
            )
          }
      },
      {
          title: '',
          align: 'left',
          width: 25,
          // isHideColumn: mode !== LIST_PATIENT_MODE.NORMAL,
          render: (value, record) => {
              return (
                <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                  <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                </Dropdown>
              )      
          }
      },
      {
          title: 'Đối tượng',
          dataIndex: 'promotionTypeText',
          key: 'promotionTypeText',
          align: 'left',
          width: 110,
          ...getColumnFilterPromotionTypeProps(),
      },
      {
          title: 'Giảm giá',
          dataIndex: 'discountValue',
          key: 'discountValue',
          align: 'right',
          width: 100,
          sorter: () => {},
          render: (text, record, index) => {
              return <span>{(record.discountValue ? record.discountValue.toLocaleString('en-US') : '') + (record.isPercent ? ' %' : ' đ')}</span>;
          }
      },
      {
          title: 'Ngày giờ bắt đầu',
          dataIndex: 'beginningDate',
          key: 'beginningDate',
          align: 'left',
          width: 165,
          sorter: () => {},
          ...getColumnFilterDateFromToProps('beginningDate'),
          // onFilterDropdownVisibleChange: (visible) => { },
          render: (value) => {
            return <span>{value ? moment(value).format(SystemConfig.FORMAT_DATE_TIME) : ''}</span>;
          }
      },
      {
          title: 'Ngày giờ kết thúc',
          dataIndex: 'endingDate',
          key: 'endingDate',
          align: 'left',
          width: 165,
          sorter: () => {},
          ...getColumnFilterDateFromToProps('endingDate'),
          render: (value) => {
              return <span>{value ? moment(value).format(SystemConfig.FORMAT_DATE_TIME) : ''}</span>;
          }
      },
      {
          title: 'Hoạt động',
          dataIndex: 'isActive',
          key: 'isActive',
          align: 'center',
          width: 100,
          render: (value, record) => {
              return <Switch checked={value} disabled={true} />;
          }
      },
      {
          title: 'SL mã',
          dataIndex: 'totalVoucherQuantityText',
          key: 'totalVoucherQuantityText',
          align: 'center',
          width: 90,
          render: (value, record) => {
            return <span>{ value.toLocaleString('en-US') }</span>;
          }
      },
      {
          title: 'Đã dùng',
          dataIndex: 'totalVoucherQuantityUsed',
          key: 'totalVoucherQuantityUsed',
          align: 'center',
          width: 90,
          render: (value, record) => {
              return <span>{ (value ?? 0) == 0 ? "" : value.toLocaleString('en-US') }</span>;
          }
      },
      {
          title: 'Ghi chú',
          dataIndex: 'remark',
          key: 'remark',
          align: 'left',
          ...getColumnSearchTextProps('remark', 'Ghi chú'),
          render: (value, record) => {
              return (
                <div style={{whiteSpace: "pre-line"}}>
                  {value}
                </div>
              );
          }
      },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };
  //#endregion 
  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleCreate(false);
    if (reload) search(true);
  };
  //#endregion 

  return (
      <React.Fragment>
        <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
          <div className='containerChilderWrapper' >
              {/* <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a> */}
              <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h3>Danh sách chương trình khuyến mại</h3>
                  <h3 hidden={!props.isDialog}
                    onClick={() => {
                    //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                    //   this.reset()
                    //   }
                      this.props.onCancel()
                    }}
                  ><i className='far fa-times-circle' /></h3>
              </div>
              <div className="row">
                <div className="col-md-12"><Notify ref={notiRef} /></div>
              </div>
              <div className="row">
                  <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                      <Button className=""
                        type="link"
                          onClick={() => {
                            setIsCreate(true);
                            setDetailScreenMode(detailScreenModeEnum.create);
                            setPromotionIdToEdit(null);
                            setVisibleCreate(true);
                          }}
                      ><i style={{ marginRight: 5 }} className="fa fa-plus-circle" />Tạo mới</Button>
                  </div>
                  <div className='col-md-6 mb-2 dropdown-inline-table' style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <button className="btn btn-color dropdown-toggle " type="button" onClick={() => {  }}
                      id="dropdownExportButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                    {/* <div className="btn btn-color dropdown-toggle" id="dropdownExportButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className='fas fa-file-excel'></i>Xuất excel
                    </div> */}
                    <div className="dropdown-menu" aria-labelledby="dropdownExportButton">
                        <a className="dropdown-item" href="#" onClick={() => {
                            onExportListPromotionClickHandle();
                        }}>Danh sách chương trình</a>
                        <a className="dropdown-item" href="#" onClick={() => {
                            onExportVoucherClickHandle(selectedRowKeys);
                        }}>Danh sách mã voucher</a>
                    </div>
                  </div>
              </div>            
              <div style={{ width: '100%', height: 'calc(100% - 140px)' }}>
                  <Table dataSource={listResult} columns={columns}
                    locale={{ emptyText: 'Không có dữ liệu' }} 
                    scroll={{ x: 'max-content', y: 0 }}
                    rowKey={(e) => e.id}
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    rowClassName={(record, rowIndex) => {
                        return "rowCustom"
                    }}
                    pagination={false}
                    onChange={onTableChange} 
                  />
                  <Pagination
                      current={pageIndex}
                      size={pageSize}
                      style={{ textAlignLast: "center", marginTop: "10px" }}
                      total={totalRecord}
                      showTotal={total => `Tổng: ${total}`}
                      onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                      onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                      showSizeChanger={true}
                  />
              </div>

              <Modal maskClosable={false}
                  width={1400}
                  visible={visibleCreate}
                  onCancel={handleCancel}
                  cancelButtonProps={{ style: { display: 'none' } }}
                  okButtonProps={{ style: { display: 'none' } }}>
                  <PromotionCreate ref={createRef}
                    isDialog={ true } 
                    listPromotionType = { listPromotionType }
                    isCreate = {isCreate}
                    promotionId = {promotionIdToEdit}
                    detailScreenMode = {detailScreenMode}
                    onCancel={(reload) => {handleCancel(reload);}}/>
              </Modal>
          </div>
      </React.Fragment>
  );
})