import { InboxOutlined } from '@ant-design/icons';
import { notification, Upload, Button, Popconfirm, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import * as Actions from '../../../libs/actions';
import * as PatientService from '../../../services/patientService';
import FileViewer from 'react-file-viewer';
import * as UrlConfig from '../../../configure/urlConfig';
import FilePreviewer from 'react-file-previewer';
import moment from 'moment';
const { Dragger } = Upload;

function UploadPatient(props) {
    const _hiddenLink = useRef();
    const [patientid, setpatientid] = useState('');
    const [dataSource, setdataSource] = useState([]);
    const [fileList, setfileList] = useState([]);
    const [listNgay, setListNgay] = useState([]);
    const [visiblePreview, setVisiblePreview] = useState(false);
    const [detail, setDetail] = useState({});
    const [urlPreview, setUrlPreview] = useState('');
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        setpatientid(props.patientid);
    }, [props.patientid]);
    useEffect(() => {
        setDisabled(props.disabled === true);
    }, [props.disabled])
    useEffect(() => {
        GetListFilePatient(patientid);
    }, [props.reload]);
    useEffect(() => {
        if (patientid === null || patientid === undefined || patientid === '') {
            setdataSource([]);
        } else {
            GetListFilePatient(patientid);
        }
        setfileList([]);
        if (props.fileList) {
            props.fileList([]);
        }
    }, [patientid]);
    useEffect(() => {
        if (detail.filepath === undefined || detail.filepath === '')
            return;
        let url = getUrlPreview(detail.filepath);
        setUrlPreview(url);

    }, [detail]);
    const config = {
        name: 'file',
        multiple: true,
        onChange(info) {
            setfileList(info.fileList)
            if (props.fileList) {
                props.fileList(info.fileList);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    function GetListFilePatient() {
        if (patientid === null || patientid === undefined || patientid === '')
            return;
        Actions.setLoading(true);
        PatientService.GetListFilePatient(patientid).then(result => {
            setdataSource(result.data);
            let data = result.data
            let listType = data.map(x => moment(x.ngay).format('DD/MM/YYYY'));
            let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
            setListNgay(ListTypeDistinct);
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    function DownloadFile(row) {
        PatientService.DownloadFilePatient(row.filepath).then(result => {
            result.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = `${row.mabn}-${row.filename}`;
                _hiddenLink.current.click();
            })
        }).catch(err => {
        })
    }
    function DeleteFile(id) {
        Actions.setLoading(true);
        PatientService.DeleteFilePatientById(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' });
                GetListFilePatient();
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    function SubmitUploadFile() {
        Actions.setLoading(true);
        let formdata = new FormData();
        fileList.forEach(element => {
            formdata.append('listFile', element.originFileObj);
        });
        PatientService.UploadFile(formdata, patientid).then(x => {
            notification.success({ message: "Upload file thành công!" });
            Actions.setLoading(false);
            GetListFilePatient();
            setfileList([]);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    function getUrlPreview(path) {
        let url = UrlConfig.BASE_URL + path;
        url = url.replace("\\", "/").replace("/api/wwwroot", "");
        return url
    }

    return (
        <React.Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
            <Dragger {...config} beforeUpload={() => false} fileList={fileList} disabled={disabled} >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây.</p>
            </Dragger>
            {props.isUpload === true && fileList.length > 0 &&
                <div className="text-center">
                    <Button className="btn btn-color" onClick={() => { SubmitUploadFile() }}>Lưu</Button>
                </div>
            }
            <div className="row" style={{ width: '99%' }}>
                {listNgay.map((ngay, i) => (
                    <React.Fragment key={`parent-${i}`}>
                        <div className="col-12 py-1">
                            <strong>{ngay}</strong>
                        </div>
                        {dataSource.filter(x => moment(x.ngay).format("DD/MM/YYYY") === ngay).map((row, index) => (
                            <div className="col-sm-6 col-md-3 col-lg-2 py-1" key={index}>
                                <div className="upload-file-container">
                                    <div className="upload-file-content">
                                        <div className="upload-file-action" title="Xem" onClick={() => {
                                            setUrlPreview(getUrlPreview(row.filepath)); setDetail(row); setVisiblePreview(true);
                                        }}>
                                            <div className="upload-file-icon">
                                                <i className="fa fa-eye"></i>
                                            </div>
                                        </div>
                                        <div className="upload-file-action" title="Download" onClick={() => { DownloadFile(row) }}>
                                            <div className="upload-file-icon"><i className="fa fa-download"></i>
                                            </div>
                                        </div>
                                        <Popconfirm
                                            title={`Xác nhận xóa ${row.filename}?`}
                                            onConfirm={() => { DeleteFile(row.id) }}
                                            okText="Đồng ý" cancelText="Hủy">
                                            <div className="upload-file-action" title="Xóa" onClick={() => { }}>
                                                <div className="upload-file-icon"><i className="fa fa-trash"></i>
                                                </div>
                                            </div>
                                        </Popconfirm>
                                    </div>
                                    <div className="w-50" style={{ margin: '0 auto' }}>
                                        <FileIcon extension={row.extension} {...defaultStyles[`${row.extension}`]} />
                                    </div>
                                </div>
                                <div>
                                    {row.filename}
                                </div>
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
            {visiblePreview &&
                <Modal
                    visible={visiblePreview}
                    onCancel={() => { setUrlPreview(''); setVisiblePreview(false) }}
                    width={1400}
                >
                    <div style={{ position: 'relative', minHeight: '80vh' }}>
                        <div className="list-button-preview">
                            <Button className="mr-2" onClick={() => DownloadFile(detail)}><i className="fa fa-download"></i></Button>
                            <Button onClick={() => setVisiblePreview(false)}>Đóng</Button>
                        </div>
                        {urlPreview && urlPreview !== '' &&
                            <React.Fragment>
                                {(detail.extension.includes('pdf') || detail.extension.includes('png')
                                    || detail.extension.includes('jpg') || detail.extension.includes('gif')) ?
                                    <FilePreviewer hideControls={true} file={{
                                        url: `${urlPreview}`,
                                        mimeType: `${detail.filetype}`,
                                        name: `${detail.filename}`,
                                    }}
                                    />
                                    :
                                    <React.Fragment>
                                        {detail.filetype === 'text/plain' || detail.filetype === 'text/html' ?
                                            <iframe src={urlPreview} title="test" width="100%" height="800"> </iframe>
                                            :
                                            <div className="preview-excel">
                                                <FileViewer
                                                    fileType={detail.extension}
                                                    filePath={urlPreview}
                                                    onError={(e) => { console.log(e) }}
                                                />
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </div>
                </Modal>
            }
        </React.Fragment>
    )
}

export default UploadPatient;