import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';

function BillDetail(props) {
    const [billId, setBillId] = useState(props.billId);
    const lstPaymentDetail = props.lstPaymentDetail;
    let posGK = 0;
    let posGKM = 0;
    let lengthPayService = lstPaymentDetail.filter(x => x.loai === '1').length;
    useEffect(() => {
        setBillId(props.billId);
    }, [props.billId])
    return (
        <Fragment>
            <div className="modal-header d-flex justify-content-between">
                <h3>Thông tin phiếu thanh toán #{billId}</h3>
                <h3 onClick={() => props.onClose()}><i className='far fa-times-circle' /></h3>
            </div>
            <div className='text-right'>Tổng tiền: <strong>{Math.round(lstPaymentDetail.reduce((tmp, cur) =>
                tmp + ((cur.soluong * cur.dongia * ((100 - cur.tylegiamgia) / 100) - cur.soluong * cur.giabh) * ((100 - cur.giamgia) / 100)), 0)).toLocaleString('en-US')}</strong></div>
            <div className='table-responsive' style={{ maxHeight: '70vh', marginBottom: 10 }}>
                <table className="table table-striped table-hover table-bordered w-100">
                    <thead>
                        <tr>
                            <th style={{ width: '50px' }}>STT</th>
                            <th>Tên dịch vụ/Thuốc</th>
                            <th>SL</th>
                            <th>Đơn giá</th>
                            <th>Thành tiền</th>
                            <th>Giảm trừ khác</th>
                            <th>Bệnh nhân trả</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lstPaymentDetail.filter(x => x.loai === '0').length > 0 &&
                            <React.Fragment>
                                {posGK === 0 &&
                                    <tr key={-1}>
                                        <span className="d-none">{posGKM = posGKM + 1}</span>
                                        <td colSpan='8' className='font-bold'>Gói khám</td>
                                    </tr>}
                            </React.Fragment>
                        }
                        {lstPaymentDetail.filter(x => x.loai === '0').map((x, index) => (
                            <tr key={'1' + index}>
                                <td>{index + 1}</td>
                                <td>{x.tenvp}</td>
                                <td>{x.soluong}</td>
                                <td className='text-right'>{Math.round(x.dongia * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                <td className='text-right'>{x.tylegiamgia === 0 ? Number((x.soluong * (x.dongia - x.giabh) * (x.giamgia / 100)).toFixed(3)).toLocaleString('en-US') : Number((x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * (x.giamgia / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                <td className='text-right'>{Number(((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                            </tr>
                        ))
                        }
                        {lstPaymentDetail.filter(x => x.loai === '1').length > 0 &&
                            <tr key={-1}>
                                <td colSpan='8' className='font-bold'>Dịch vụ</td>
                            </tr>
                        }
                        {lstPaymentDetail.filter(x => x.loai === '1').map((x, index) => {
                            let totalDiscount = (x.dongiagoc - x.dongia - x.giabh) + (x.dongia * x.tylegiamgia / 100) + (x.dongia * x.giamgia / 100 );
                            return (
                                <tr key={'1' + index}>
                                    <td>{index + 1}</td>
                                    <td>{x.tenvp}</td>
                                    <td>{x.soluong}</td>
                                    <td className='text-right'>{Math.round(x.dongiagoc * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                    <td className='text-right'>{Math.round(x.soluong * (x.dongiagoc * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                    {/* <td className='text-right'>{(x.soluong * x.giabh).toLocaleString('en-US')}</td> */}
                                    <td className='text-right'>{Number(totalDiscount.toFixed(3)).toLocaleString('en-US')}</td>
                                    <td className='text-right'>{Number(((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                </tr>
                            )
                        })
                        }
                        {lstPaymentDetail.filter(x => x.loai === '4').length > 0 &&
                            <tr key={-2}>
                                <td colSpan='8' className='font-bold'>Vật tư sử dụng</td>
                            </tr>
                        }
                        {lstPaymentDetail.filter(x => x.loai === '4').map((x, index) => (
                            <tr key={'1' + index}>
                                <td>{index + 1 + lengthPayService}</td>
                                <td>{x.tenvp}</td>
                                <td>{x.soluong}</td>
                                <td className='text-right'>{Math.round(x.dongia * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                <td className='text-right'>{Number((x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.giabh) * (x.giamgia / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                <td className='text-right'>{Number(((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                            </tr>
                        ))
                        }
                        {lstPaymentDetail.filter(x => x.loai === '2' || x.loai === '3').length > 0 &&
                            <tr key={-2}>
                                <td colSpan='8' className='font-bold'>Thuốc</td>
                            </tr>
                        }
                        {lstPaymentDetail.filter(x => x.loai === '2' || x.loai === '3').map((x, index) => (
                            <tr key={'1' + index}>
                                <td>{index + 1 + lengthPayService}</td>
                                <td>{x.tenvp}</td>
                                <td>{x.soluong}</td>
                                <td className='text-right'>{Math.round(x.dongia * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                <td className='text-right'>{Number((x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.giabh) * (x.giamgia / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                <td className='text-right'>{Number(((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="custom-hr"></div>
            <div className="text-right">
                <button className="btn btn-danger" onClick={() => { props.onClose && props.onClose(); posGKM = 0 }}>Đóng</button>
            </div>
        </Fragment>
    );
}

export default BillDetail;