import * as ApiCaller from '../libs/httpRequests';

const prefix = 'statistic';

export function ThongKeKhamBenh(dateFrom, dateTo, makp = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongkekhambenh?dateFrom=${dateFrom}&dateTo=${dateTo}&makp=${makp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeDoanhSo(dateFrom, dateTo,chinhanh, loai, page, size, admin = true) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeDoanhSo?dateFrom=${dateFrom}&dateTo=${dateTo}&loai=${loai}&page=${page}&size=${size}&admin=${admin}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeDoanhSoDichVu(dateFrom, dateTo,chinhanh, loai, page, size, admin = true) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeDoanhSoDichVu?dateFrom=${dateFrom}&dateTo=${dateTo}&loai=${loai}&page=${page}&size=${size}&admin=${admin}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeDoanhSoDichVuChiTiet(date,chinhanh, loai, page, size, admin = true) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeDoanhSoDichVuChiTiet?date=${date}&loai=${loai}&page=${page}&size=${size}&admin=${admin}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeDoanhSoTheoBN(dateFrom, dateTo, paymenttype, coporator, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeDoanhSoTheoBN?dateFrom=${dateFrom}&dateTo=${dateTo}&paymenttype=${paymenttype}&coporator=${coporator}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })  
    });
};

export function ThongKeDoanhSoTheoKhoa(dateFrom, dateTo,chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeDoanhSoTheoKhoa?dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function RevenueStatisticChart(type, fromDate, toDate, chinhanh) {
    return new Promise((resolve, reject) => {

        let url = `${prefix}/RevenueStatisticChart?type=${type}&fromDate=${fromDate}&chinhanh=${chinhanh}`
        if (toDate != null) {
            url += `&toDate=${toDate}`
        }
        return ApiCaller.httpGet(url).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetGeneralRevenueByService(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetGeneralRevenueByService?dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function ThongKeTheoDichVuBenhNhan(dateFrom, dateTo, type = '', filter = '', chinhanh = '', fromPayment = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeTheoDichVuBenhNhan?dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&filter=${filter}&chinhanh=${chinhanh}&fromPayment=${fromPayment}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeTheoDichVuBenhNhanPerformance(dateFrom, dateTo, paymenttype, coporator, type = '', filter = '', chinhanh = '', page=1,size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeTheoDichVuBenhNhanPerformance?dateFrom=${dateFrom}&dateTo=${dateTo}&paymenttype=${paymenttype}&coporator=${coporator}&filter=${filter}&chinhanh=${chinhanh}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeNhapKho(dateFrom, dateTo, makho, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeNhapKho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function ThongKeNhapKhoChiTiet(idPhieu, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeNhapKhoChiTiet?idPhieu=${idPhieu}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeXuatKho(dateFrom, dateTo, makho, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeXuatKho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeXuatKhoChiTiet(idPhieu, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeXuatKhoChiTiet?idPhieu=${idPhieu}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeDoanhSoBS(dateFrom, dateTo, loai) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeDoanhSoBS?dateFrom=${dateFrom}&dateTo=${dateTo}&loai=${loai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeBacSi(dateFrom, dateTo, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeBacSi?dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeSoLuongCDHA(dateFrom, dateTo, idloai, idvp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeSoLuongCDHA?dateFrom=${dateFrom}&dateTo=${dateTo}&idloai=${idloai}&idvp=${idvp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachLoaiVP() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachLoaiVP`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachVPTheoLoai(loaivp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachVPTheoLoai?loaivp=${loaivp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportKhamBenh(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportkhambenh?dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportDoanhSo(dateFrom, dateTo,chinhanh, loai, tenloai, admin = true) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportdoanhso?dateFrom=${dateFrom}&dateTo=${dateTo}&loai=${loai}&tenloai=${tenloai}&admin=${admin}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportDoanhSoTheoBN(dateFrom, dateTo, paymenttype, coporator,chinhanh, type='', filter='') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportDoanhSoTheoBN?dateFrom=${dateFrom}&dateTo=${dateTo}&paymenttype=${paymenttype}&coporator=${coporator}&type=${type}&filter=${filter}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportLuotKhamTheoNguonKhachHang(dateFrom, dateTo, paymenttype,chinhanh, type='', filter='') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportLuotKhamTheoNguonKhachHang?dateFrom=${dateFrom}&dateTo=${dateTo}&paymenttype=${paymenttype}&type=${type}&filter=${filter}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportDoanhSoTheoKhoa(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportDoanhSoTheoKhoa?dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeTheoDichVuBenhNhan(dateFrom, dateTo, paymenttype, coporator, chinhanh, filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeTheoDichVuBenhNhan?dateFrom=${dateFrom}&dateTo=${dateTo}&paymenttype=${paymenttype}&coporator=${coporator}&chinhanh=${chinhanh}&filter=${filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportThongKeDichVuTheoNguonKhachHang(dateFrom, dateTo, paymenttype, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeDichVuTheoNguonKhachHang?dateFrom=${dateFrom}&dateTo=${dateTo}&paymenttype=${paymenttype}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportThongKeKhamBenhTrongNgay(dateFrom, dateTo, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeKhamBenhTrongNgay?dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportNhapkho(dateFrom, dateTo, makho,isPhieu) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportnhapkho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&isPhieu=${isPhieu}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportXuatkho(dateFrom, dateTo, makho, isPhieu) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportxuatkho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&isPhieu=${isPhieu}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportCongNo(dateFrom, dateTo,chinhanh, mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportCongNo?dateFrom=${dateFrom}&dateTo=${dateTo}&mabn=${mabn}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportDoanhSoBS(dateFrom, dateTo,chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeBacSi?dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function ExportSoLuongCDHA(dateFrom, dateTo, idloai, idvp, tenloai, ten) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportSoLuongCDHA?dateFrom=${dateFrom}&dateTo=${dateTo}&idloai=${idloai}&idvp=${idvp}&tenloai=${tenloai}&ten=${ten}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function TimThuoc(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/SearchMedicineStatistic?key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeThuoc(dateFrom, dateTo, mathuoc, makp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongkethuoc?dateFrom=${dateFrom}&dateTo=${dateTo}&mathuoc=${mathuoc}&makp=${makp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThuoc(dateFrom, dateTo, mathuoc, makp) {
    // return new Promise((resolve, reject) => {
    //     return ApiCaller.httpGetDownload(`${prefix}/exportthuoc?dateFrom=${dateFrom}&dateTo=${dateTo}&mathuoc=${mathuoc}&makp=${makp}`).then(respone => {
    //         return resolve(respone)
    //     }).catch(err => {   
    //         return reject(err)
    //     })
    // });
};

export function DanhSachCongNoBenhNhanTheoNgay(dateFrom, dateTo,chinhanh, mabn, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Invoice/DanhSachCongNoBenhNhanTheoNgay?dateFrom=${dateFrom}&dateTo=${dateTo}&mabn=${mabn}&page=${page}&size=${size}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LisStatisticChart(type, fromDate, toDate,chinhanh, clsType) {
    return new Promise((resolve, reject) => {
        let url = `LisStatistic/LisStatisticChart?type=${type}&fromDate=${fromDate}&clsType=${clsType}&chinhanh=${chinhanh}`
        if (toDate != null) {
            url += `&toDate=${toDate}`
        }
        return ApiCaller.httpGet(url).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function LisStatistic(type, dateFrom, dateTo,chinhanh, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`LisStatistic/LisStatistic?type=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportLisStatistic(type, dateFrom, dateTo, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`LisStatistic/ExportLisStatistic?type=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongKeLuotKhamTrongNgay(dateFrom, dateTo,chinhanh, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`LisStatistic/ThongKeKhamBenhTrongNgay?dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongKeLuotKhamTrongNgayChart(type, fromDate, toDate, chinhanh) {
    return new Promise((resolve, reject) => {

        let url = `LisStatistic/ThongKeLuotKhamTrongNgayChart?type=${type}&chinhanh=${chinhanh}&fromDate=${fromDate}`
        if (toDate != null) {
            url += `&toDate=${toDate}`
        }
        return ApiCaller.httpGet(url).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function ExportThongKeQuayThuoc(dateFrom,dateTo, makho, loai="", notZero = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportthongkequaythuoc?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&loai=${loai}&notzero=${notZero}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportDanhSachChoTheoNgay(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportDanhSachChoTheoNgay?dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachThongKeDonThuocTheoNgay(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/DanhSachThongKeDonThuocTheoNgay?dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachThongKeThuocBanLeTheoNgay(dateFrom, dateTo, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/DanhSachThongKeThuocBanLeTheoNgay?dateFrom=${dateFrom}&dateTo=${dateTo}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongKeKiemTraNhapKho(dateFrom, dateTo, makho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/kiemtranhapkho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongKeKiemTraXuatKho(dateFrom, dateTo, makho, key = '', tenbn = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/kiemtraxuatkho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&key=${key}&tenbn=${tenbn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongKeKiemTraXuatKhoManHinhDuoc(dateFrom, dateTo, makho, key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeKiemTraXuatKhoManHinhDuoc?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportKiemTraNhapkho(dateFrom, dateTo, makho, tenkho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportkiemtranhapkho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&tenkho=${tenkho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportKiemTraXuatkho(dateFrom, dateTo, makho, key, tenbn = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportkiemtraxuatkho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&key=${key}&tenbn=${tenbn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function ExportDanhMucThuoc(nhom, key = "") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportdanhmucthuoc?nhom=${nhom}&key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportDanhMucDV(key = "", banggia="default", nhom = 0, id_loai = 0) {
    if (nhom == null) nhom = 0;
    if (id_loai == null) id_loai = 0;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportdanhmucdichvu?key=${key}&banggia=${banggia}&nhom=${nhom}&id_loai=${id_loai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportDanhSachBenhNhanDaDangKy(key, page, size, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exportdanhsachbenhnhandadangky?key=${key}&page=${page}&size=${size}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongKeNguoiGioiThieu(dateFrom, dateTo,chinhanh, key,filter, page=1,size=10, isexport=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeNguoiGioiThieu?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&filter=${filter}&page=${page}&size=${size}&export=${isexport}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportThongKeNguoiGioiThieu(dateFrom, dateTo,chinhanh,key, filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeNguoiGioiThieu?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&chinhanh=${chinhanh}&filter=${filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeChiTietNguoiGioiThieu(dateFrom, dateTo,chinhanh, key,filter, page=1,size=10, isexport=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeChiTietNguoiGioiThieu?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&filter=${filter}&page=${page}&size=${size}&export=${isexport}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportThongKeChiTietNguoiGioiThieu(dateFrom, dateTo,chinhanh,key, filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeChiTietNguoiGioiThieu?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&chinhanh=${chinhanh}&filter=${filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeNguoiTiepDon(dateFrom, dateTo,chinhanh, key, page=1,size=10, isexport=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeNguoiTiepDon?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}&export=${isexport}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeChiTietNguoiTiepDon(dateFrom, dateTo,chinhanh, key, page=1,size=10, isexport=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeChiTietNguoiTiepDon?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}&export=${isexport}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeNguoiTiepDon(dateFrom, dateTo,chinhanh,key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeNguoiTiepDon?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeChiTietNguoiTiepDon(dateFrom, dateTo,chinhanh,key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeNguoiTiepDon?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetStatisticInPatientByService(dateFrom, dateTo, key, print, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetStatisticInPatientByService?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&print=${print}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportStatisticInPatientByService(dateFrom, dateTo, key='') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportStatisticInPatientByService?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetStatisticInPatientByInvoice(dateFrom, dateTo, key, print, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetStatisticInPatientByInvoice?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&print=${print}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportStatisticInPatientByInvoice(dateFrom, dateTo, key='') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportStatisticInPatientByInvoice?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetStatisticInPatientByDay(dateFrom, dateTo, key, print, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetStatisticInPatientByDay?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&print=${print}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportStatisticInPatientByDay(dateFrom, dateTo, key='') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportStatisticInPatientByDay?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeSoLuongThuoc(dateFrom, dateTo,chinhanh, makho, loai, key,isexport, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeSoLuongThuoc?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&loai=${loai}&key=${key}&export=${isexport}&page=${page}&size=${size}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function ExportThongKeSoLuongThuoc(dateFrom, dateTo,chinhanh, makho, loai, key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeSoLuongThuoc?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&loai=${loai}&key=${key}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeVattuTieuHao(dateFrom,dateTo,key,tinhphi) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeVattuTieuHao?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&tinhphi=${tinhphi}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function ExportMISA(dateFrom, dateTo, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportMISA?from=${dateFrom}&to=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongKeTheoDoiBanThuoc(dateFrom, dateTo, chinhanh, makho, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/theodoibanthuoc?dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}&makho=${makho}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportTheoDoiBanThuoc(dateFrom, dateTo,chinhanh, makho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/exporttheodoibanthuoc?dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}&makho=${makho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetTemplate(filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/GetTemplateMedicinesImport?filter=${filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetTemplateExcel(filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/GetTemplateExcelMedicinesImport?filter=${filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeChiSoSinhTon(dateFrom, dateTo, page, size, siteId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeChiSoSinhTon?dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&site=${siteId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeChiSoSinhTon(dateFrom, dateTo, siteId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeChiSoSinhTon?dateFrom=${dateFrom}&dateTo=${dateTo}&site=${siteId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportHoanTra(dateFrom, dateTo, siteId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportHoanTra?dateFrom=${dateFrom}&dateTo=${dateTo}&site=${siteId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeLayMauTaiNha(dateFrom, dateTo, status, userCreate, page, size, siteId) {
    if (status === null) status = 0;
    if (userCreate === null) userCreate = 0;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeLayMauTaiNha?dateFrom=${dateFrom}&dateTo=${dateTo}&status=${status}&user=${userCreate}&page=${page}&size=${size}&site=${siteId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeLichLayMauTaiNha(dateFrom, dateTo, status, userCreate, siteId) {
    if (status === null) status = 0;
    if (userCreate === null) userCreate = 0;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeLichLayMauTaiNha?dateFrom=${dateFrom}&dateTo=${dateTo}&status=${status}&user=${userCreate}&site=${siteId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetCreateUserList(siteId, isLayMau = true) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetCreateAppointmentUserList/${siteId}?laymau=${isLayMau}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function ThongKeLichHen(dateFrom, dateTo, status, userCreate, page, size, siteId, filterByAppointmentDate) {
    if (status === null) status = 0;
    if (userCreate === null) userCreate = 0;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeLichHen?dateFrom=${dateFrom}&dateTo=${dateTo}&status=${status}&user=${userCreate}&page=${page}&size=${size}&site=${siteId}&filterByNgayHen=${filterByAppointmentDate}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeLichHen(dateFrom, dateTo, status, userCreate, siteId, filterByAppointmentDate) {
    if (status === null) status = 0;
    if (userCreate === null) userCreate = 0;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeLichHen?dateFrom=${dateFrom}&dateTo=${dateTo}&status=${status}&user=${userCreate}&site=${siteId}&filterByNgayHen=${filterByAppointmentDate}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeKenhKhachHang(dateFrom, dateTo, page, size, siteId,filter, isexport=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeKenhKhachHang?dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&site=${siteId}&key=${filter}&export=${isexport}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeKenhKhachHang(dateFrom, dateTo, siteId, filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeKenhKhachHang?dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=&key=${filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeDeNghiKetLuan(dateFrom, dateTo, page, size, siteId, key='') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeDeNghiKetLuan?dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&site=${siteId}&key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeDeNghiKetLuan(dateFrom, dateTo, siteId, key='') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeDeNghiKetLuan?dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${siteId}&key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeXacNhanLayMau(from, to, site, type) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeXacNhanLayMau?from=${from}&to=${to}&site=${site}&type=${type}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function StatisticVoucher(dateFrom, dateTo, page, size, promotionId='', type='') {
    let param=`page=${page}&size=${size}&promotionId=${promotionId}&type=${type}`;
    if(dateTo != null)
    {
        param+=`&dateTo=${dateTo}`;
    }
    if(dateFrom != null)
    {
        param+=`&dateFrom=${dateFrom}`;
    }
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/StatisticVoucher?${param}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportStatisticVoucher(dateFrom, dateTo, page, size,typeExport, promotionId='', type='') {
    let param=`page=${page}&size=${size}&promotionId=${promotionId}&type=${type}&typeExport=${typeExport}`;
    if(dateTo != null)
    {
        param+=`&dateTo=${dateTo}`;
    }
    if(dateFrom != null)
    {
        param+=`&dateFrom=${dateFrom}`;
    }
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportStatisticVoucher?${param}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeChuyenKho(dateFrom, dateTo, makho, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeChuyenKho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportChuyenKho(dateFrom, dateTo, makho, tenkho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportChuyenKho?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&tenkho=${tenkho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportExprired(ngay,makho,key, songayhethan = 60,isExpired=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportExpiredMedicine?ngay=${ngay}&makho=${makho}&key=${key}&isExpired=${isExpired}&expdays=${songayhethan}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachCongNoPhaiThu(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/DanhSachCongNoPhaiThu`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachCongNoDaThu(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/DanhSachCongNoDaThu`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CongNoPhaiThuSummary(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CongNoPhaiThuSummary`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CongNoDaThuSummary(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CongNoDaThuSummary`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThuHoiCongNo(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThuHoiCongNo`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteCongNo(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/deleteCongNo?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportCongNoDaThu(dateFrom, dateTo,coporator = '', patient = '', site='') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportCongNoDaThu?dateFrom=${dateFrom}&dateTo=${dateTo}&coporator=${coporator}&patient=${patient}&site=${site}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportCongNoPhaiThu(dateFrom, dateTo, coporator = '', patient = '', site='') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportCongNoPhaiThu?dateFrom=${dateFrom}&dateTo=${dateTo}&coporator=${coporator}&patient=${patient}&site=${site}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function IdConfigCongNo(){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/IdConfigCongNo`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function IdConfigTheTraTruoc(){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/IdConfigTheTraTruoc`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function ThongKeDanhSachSinhNhat(dateFrom, dateTo,page=1,size=10, isexport=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeDanhSachSinhNhat?dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&export=${isexport}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeDanhSachSinhNhat(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeDanhSachSinhNhat?dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetThongKeOngMau(dateFrom, dateTo, page, size, coporator, type, site) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetThongKeOngMau?dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&coporator=${coporator}&type=${type}&site=${site}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportThongKeOngMau(dateFrom, dateTo, coporator, type, site) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportThongKeOngMau?dateFrom=${dateFrom}&dateTo=${dateTo}&coporator=${coporator}&type=${type}&site=${site}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetChartThongKeOngMau(dateFrom, dateTo, coporator, type, site) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetChartThongKeOngMau?dateFrom=${dateFrom}&dateTo=${dateTo}&coporator=${coporator}&type=${type}&site=${site}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ReferenceRevenueStatisticChart(filterModel){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ReferenceRevenueStatisticChart`, filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function ReferenceRevenueStatistic(filterModel){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ReferenceRevenueStatistic`, filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function ExportReferenceRevenueStatistic(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportReferenceRevenueStatistic`,filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ReceptionistRevenueStatisticChart(filterModel){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ReceptionistRevenueStatisticChart`, filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function ReceptionistRevenueStatistic(filterModel){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ReceptionistRevenueStatistic`, filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function ExportReceptionistRevenueStatistic(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportReceptionistRevenueStatistic`,filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function RevenueRefundByBill(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/RevenueRefundByBill`, filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportRevenueRefundByBill(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportRevenueRefundByBill`,filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportMedicineMinRequirement(warehouse, keyword) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportMedicineMinRequirement?warehouse=${warehouse}&keyword=${keyword}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};