import { Input, Form, notification } from 'antd';
import React, { Component } from 'react';
import * as cfgService from '../../../services/configService';
import SimpleReactValidator from 'simple-react-validator';

const initialState = {
    tenloaiphong: '',
    maloaiphong: ''
}
export default class createNewLoaiPhong extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            lstLoaiPhong: []
        };
        this.validator = new SimpleReactValidator({
            messages: {
                numeric: "Cần phải nhập :attribute là số!",
                required: "Cần phải nhập :attribute!",
                max: "Nhập :attribute tối đa :max kí tự!"
            },
            element: message => <div style={{ color: "red" }}>{message}</div>
        });
    }
    componentDidMount() {
        this.setState({
            tenloaiphong: '',
            maloaiphong: ''
        })
    }

    CreateLoaiPhong() {
        if (this.validator.allValid()) {
            const data = {
                "tenLoaiPhong": this.state.tenloaiphong,
                "maLoaiPhong": this.state.maloaiphong
            }
            cfgService.createLoaiPhong(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.resetState();
            }).catch(() => {
                notification.warning({ message: 'Tạo thất bại!' });
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetState() {
        this.setState({
            tenloaiphong: '',
            maloaiphong: '',
        })
        this.validator.hideMessages();
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm loại phòng nội trú</h3>
                    </div>
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }} >
                            <Form.Item style={{ width: 300 }} label="Mã loại phòng:" name="maloai">
                                <Input value={this.state.maloaiphong}
                                    onChange={(e) => this.setState({ maloaiphong: e.target.value })}
                                />
                                {this.validator.message("Mã loại phòng", this.state.maloaiphong, "required|max:10")}
                            </Form.Item>
                            <Form.Item style={{ width: 400 }} label="Loại phòng:" name="tenloaiphong">
                                <Input value={this.state.tenloaiphong}
                                    onChange={(e) => this.setState({ tenloaiphong: e.target.value })}
                                />
                                {this.validator.message("Tên loại phòng", this.state.tenloaiphong, "required")}
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>

                            <button className="btn btn-success"
                                onClick={() => { this.CreateLoaiPhong() }}>Thêm</button>
                            <button className="btn btn-warning" style={{ marginLeft: 10 }}
                                onClick={() => this.resetState()}><i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.resetState();
                                    this.props.onCancel();
                                    this.props.onReload();
                                    this.validator.hideMessages();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                />Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}