import { Button, Input, notification, Popconfirm } from 'antd';
import React, { useState, useEffect } from 'react'
import * as Actions from '../../../libs/actions';
import * as CommonService from '../../../services/commonService';

export default (props) => {
    const [detail, setDetail] = useState({});
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if (detail.managementID)
            GetData();
    }, [detail])
    useEffect(() => {
        setDetail(props.detail);
    }, [props.detail])

    const Submit = () => {
        const data = {
            patient: detail,
            listData: dataSource
        }
        Actions.setLoading(true);
        CommonService.UpdateResultTest(data, props.chinhanh).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công.' });
                if (props.onClose)
                    props.onClose();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }
    const GetData = () => {
        Actions.setLoading(true);
        CommonService.GetLisResultToEdit(detail.managementID, detail.sid, props.chinhanh).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }
    return (
        <React.Fragment>
            <div className="text-center h4 text-uppercase" >Chỉnh sửa kết quả</div>
            <div className="row">
                <div className="col-12 mb-2">
                    <div className="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Mã</th>
                                    <th>Tên</th>
                                    <th>Kết quả</th>
                                    <th>Mức bình thường</th>
                                    <th>Đơn vị</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSource.map((row, index) => <React.Fragment>
                                    <tr key={index}>
                                        <td>{row.maDV}</td>
                                        <td>{row.tenDichVu}</td>
                                        <td>
                                            <Input value={row.ketQua} style={{ width: '100px' }} maxLength={20}
                                                onChange={(e) => {
                                                    row.ketQua = e.target.value;
                                                    setDataSource(dataSource.map(x => ({ ...x })))
                                                }}
                                            />
                                        </td>
                                        <td>{row.normalRange}</td>
                                        <td>{row.unit}</td>
                                    </tr>
                                    {row.listSubTestResult && row.listSubTestResult.length > 0 &&
                                        <tr key={`sub${index}`}>
                                            <td colSpan="5" style={{ border: '2px solid #000', marginLeft: '10px', width: '95%', fontStyle: 'italic' }}>
                                                <table class="table">
                                                    <thead>
                                                        <th>Mã</th>
                                                        <th>Tên</th>
                                                        <th>Kết quả</th>
                                                        <th>Mức bình thường</th>
                                                        <th>Đơn vị</th>
                                                    </thead>
                                                    <tbody>
                                                        {row.listSubTestResult.map((sub, indexSub) => <tr key={`${index}${indexSub}`}>
                                                            <td>{sub.maDV}</td>
                                                            <td>{sub.tenDichVu}</td>
                                                            <td>
                                                                <Input value={sub.ketQua} style={{ width: '100px' }} maxLength={20}
                                                                    onChange={(e) => {
                                                                        sub.ketQua = e.target.value;
                                                                        setDataSource(dataSource.map(x => ({ ...x })))
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{sub.normalRange}</td>
                                                            <td>{sub.unit}</td>
                                                        </tr>)}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    }
                                </React.Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12 mb-2 text-center">
                    <div className="d-flex justify-content-center">
                        <Popconfirm
                            title="Xác nhận lưu kết quả?"
                            onConfirm={() => { Submit() }}
                            okText="Đồng ý" cancelText="Hủy">
                            <Button className="btn-color">Lưu</Button>
                        </Popconfirm>
                        <Button className="ml-2"
                            onClick={() => {
                                if (props.onClose)
                                    props.onClose()
                            }}
                        >Đóng</Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
