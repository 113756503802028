import { Input, Form, notification, Select } from 'antd';
import React, { Component } from 'react';
import * as cfgService from '../../../services/configService';
import SimpleReactValidator from 'simple-react-validator';
import * as PhongKhamService from '../../departmentConfig/services/PhongKhamService';

const { Option } = Select;
export default class createNewPhong extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idloaiphong: 0,
            makp: "0",
            lstdepartment: [],
            lstloaiphong: [],
            key: '',
            idphong: '',
            tenphong: '',
            maphong: '',
            maloaiphong: ''
        };
        this.validator = new SimpleReactValidator({
            messages: {
                numeric: "Cần phải nhập :attribute là số!",
                required: "Cần phải nhập :attribute!",
                max: "Nhập :attribute tối đa :max kí tự!"
            },
            element: message => <div style={{ color: "red" }}>{message}</div>
        });
    }
    componentDidMount() {
        this.loadLoaiPhong();
        this.LoadDepartment();
        this.setState({
            tenphong: null,
            maphong: null,
            makp: null,
            idloaiphong: null
        })
    }
    loadLoaiPhong() {
        cfgService.getLoaiPhong(this.state.key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstloaiphong: result.data
                });
            }
        }).catch(err => {
        })
    }
    LoadDepartment() {
        PhongKhamService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdepartment: result.data
                });
            }
        })
    }
    CreatePhong() {
        if (this.validator.allValid()) {
            const data = {
                "tenPhong": this.state.tenphong,
                "maPhong": this.state.maphong,
                "maKP": this.state.makp,
                "idLoaiPhong": this.state.idloaiphong
            }
            cfgService.createPhong(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.setState({
                    makp: '',
                    idloaiphong: '',
                    maphong: '',
                    tenphong: ''
                });
                this.resetState();
            }).catch(() => {
                notification.warning({ message: 'Tạo thất bại!' });
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetState() {
        this.setState({
            tenphong: null,
            maphong: null,
            makp: null,
            idloaiphong: null
        });
        this.validator.hideMessages();
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm phòng nội trú</h3>
                    </div>
                    <div>
                        <div className="row no-gutters" style={{ display: 'flex', margin: 15 }} >
                            <div className="col-md-6">
                                <Form.Item label="Khoa:" name="makp">
                                    <Select
                                        placeholder="Chọn khoa"
                                        value={this.state.makp}
                                        onChange={(e) => {
                                            this.setState({ makp: e })
                                        }}
                                    >
                                        {this.state.lstdepartment.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.makp}>{row.tenkp}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Khoa", this.state.makp, "required")}
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Loại phòng:" name="idloaiphong">
                                    <Select
                                        value={this.state.idloaiphong}
                                        placeholder="Chọn loại phòng"
                                        onChange={(e) => {
                                            this.setState({ idloaiphong: e })
                                        }}
                                    >
                                        {this.state.lstloaiphong.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.tenLoaiPhong}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Loại phòng", this.state.idloaiphong, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', margin: 15 }} >
                            <div className="col-md-6">
                                <Form.Item label="Mã phòng:" name="maphong">
                                    <Input
                                        placeholder="Nhập mã phòng"
                                        value={this.state.maphong}
                                        onChange={(e) => this.setState({
                                            maphong: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Mã phòng", this.state.maphong, "required|max:10")}
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Tên phòng:" name="tenphong">
                                    <Input
                                        placeholder="Nhập tên phòng"
                                        value={this.state.tenphong}
                                        onChange={(e) => this.setState({
                                            tenphong: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Tên phòng", this.state.tenphong, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success"
                                onClick={() => { this.CreatePhong() }}>
                                <i className="fas fa-plus" style={{ marginRight: 5 }} />Thêm
                            </button>
                            <button className="btn btn-success" style={{ marginLeft: 10 }}
                                onClick={() => this.resetState()}><i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button style={{ marginLeft: 10 }} className="btn btn-danger"
                                onClick={() => {
                                    this.resetState();
                                    this.props.onCancel();
                                    this.props.onReload();
                                    this.validator.hideMessages();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                />Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}