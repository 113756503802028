import moment from "moment";
import React, {  useEffect, useState, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Table, Pagination, Select, Spin, Modal,Menu,Dropdown } from 'antd';
import * as SystemConfig from '../../../../configure/systemConfig';
import * as ReportServices from '../../../../services/ReportService';
import Notify, { NotifyStatus } from '../../../../components/notify';
import * as Actions from '../../../../libs/actions';
import * as WorldfoneService from '../../../../services/worldfoneService';
import LoginWF from '../../../hotline/loginWF';
import Detail from './detail';

const { Option } = Select;
const now = new Date();
export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    loadData: () => {
      resetToFirstPage();
    },
  }));
  //#region khai báo state
  const notiRef = useRef(null);
  const firstRender = useRef(true);
  const _hiddenLink = useRef();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [visibleDetail, setVisibleDetail] = useState(false);

  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoginWF, setIsLoginWF] = useState(false);
  const [titleWF, setTitleWF] = useState('');
  const [patientId , setPatientId] = useState('');
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getData();
  }, [pageIndex]);

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
  }, []);
  //#endregion 
  //#region get list data cho bộ lọc
  const getData = () => {
    setLoading(true);
    let filterModel = {
      siteIds: props.siteIds,
      coporationIds: props.coporationIds,
      keyword: props.keyword,
      city : props.city,
      district : props.district,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      minSpent: props.minSpent,
      maxSpent: props.maxSpent,
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
    }
    ReportServices.getSpentRevenueList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      setLoading(false);
      forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else getData();
  }
  
  const onExportExcelClinkHandle = () => {
    setLoading(true);
    let filterModel = {
      siteIds: props.siteIds,
      coporationIds: props.coporationIds,
      keyword: props.keyword,
      city : props.city,
      district : props.district,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      minSpent: props.minSpent,
      maxSpent: props.maxSpent
    }
    ReportServices.getSpentRevenueListExport(filterModel).then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = "ThongKeChiTieu_"+moment(new Date()).format('YYMMDDhhmmss');
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      setLoading(false);
      forceUpdate();
    })
  }

  const MakeCall = (row) => {
        if (row.dienThoai === null || row.dienThoai === '' || row.dienThoai.length < 5) {
            notiRef.current.Show("Bệnh nhân không có số điện thoại.", NotifyStatus.Warning);
            return;
        }
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            setIsLoginWF(true);
            return;
        }
        Actions.setLoading(true);
        WorldfoneService.makecall(row.dienThoai, row.hoTen, tokenWF).then(result => {
            if (result.status !== 1) {
                setTitleWF('Vui lòng đăng nhập Worldfone');
                setIsLoginWF(true);
            }
            Actions.setLoading(false);
            Actions.OpenCallWorldfone(true,'NewCall')
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }

  const rowOptions = (row) => {
    return (
      <Menu
        items={[
          {
            label: 
              <a href="#" onClick={() => {
                MakeCall(row)
              }}><i className="fas fa-phone mr-2" /> Gọi điện</a>,
            key: '0',
          },
        ]}
      />
    )
  }

  const columns = [
    {
        title: '#',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 30,
        render: (text, record, index) => {
            return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    // Table.SELECTION_COLUMN,
  { 
      title: 'Mã khách hàng',
      dataIndex: 'maBN',
      key: 'maBN',
      align: 'left',
      width: 115,
      // render: (value, record) => {
      // }
    },
    {
      title: 'Thao tác',
      align: 'center',
      width: 100,
      render: (row, record) => {
          return (
            <Dropdown overlay={rowOptions(row)} trigger={['click']}>
            <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
            </Dropdown>
          )          
      }
    },
    {
        title: 'Khách hàng',
        dataIndex: 'hoTen',
        key: 'hoTen',
        align: 'left',
        width: 200,
    },
    {
        title: 'Ngày sinh',
        dataIndex: 'ngaySinh',
        key: 'ngaySinh',
        align: 'left',
        width: 80,
    },
    {
        title: 'Giới tính',
        dataIndex: 'phai',
        key: 'phai',
        align: 'left',
        width: 80,
        render: (value, record) => {
            return (
              <span>{value == 0 ? "Nam": "Nữ" }</span>
            )
        }
    },
    {
      title: 'Lần khám',
      dataIndex: 'countOrder',
      key: 'countOrder',
      align: 'center',
      width: 100,
      render: (value, record) => {
          return (
            <span>{ Number(value) }</span>
          )
      }
    },
    {
      title: 'Thực thu',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      align: 'right',
      width: 100,
      render: (value, record) => {
          return (
            <span>{ Number(value).toLocaleString('en-US') }</span>
          )
      }
    },
    {
        title: 'Ngày lập phiếu',
        children:[
            {
                title: 'Lần đầu',
                dataIndex: 'ngayDauTien',
                key: 'ngayDauTien',
                align: 'center',
                width: 100,
                render: (value, record) => {
                    return (
                      <span>{ moment(value).format(SystemConfig.FORMAT_DATE_TIME) }</span>
                    )
                }
            },
            {
                title: 'Gần đây',
                dataIndex: 'ngayGanDay',
                key: 'ngayGanDay',
                align: 'center',
                width: 100,
                render: (value, record) => {
                    return (
                      <span>{ moment(value).format(SystemConfig.FORMAT_DATE_TIME) }</span>
                    )
                }
            }
        ]
    },
  ];
  //#endregion
  return (
    <React.Fragment>
      <Spin spinning={loading} className='spin-color'>
        <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
        <div className="list-page" >
            <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
            </div>
            <div className="list-page-body">{/* body */}
              <div className="row">{/* notification */}
                <div className="col-md-12"><Notify ref={notiRef} /></div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-end p-0">
                  <button className="btn btn-color" type="button" 
                    onClick={() => { onExportExcelClinkHandle(); }}
                  ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                </div>
              </div>
              <div className="list-page-table mt-2">{/* Dashboard & [Chart | List] */}
                <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                  className="table-min-h-0 table-header-1row"
                  dataSource={listResult} 
                  columns={columns}
                  locale={{ emptyText: 'Không có dữ liệu' }} 
                  scroll={{ x: 1200, y: 0 }}
                  rowKey={(e) => e.ngay.toString() + e.maBN + e.coporationName}
                  rowClassName={(record, rowIndex) => {
                  }}
                  pagination={false}
                  // onChange={onTableChange} 
                />
                <Pagination
                  current={pageIndex}
                  size={pageSize}
                  style={{ textAlignLast: "center", marginTop: "10px" }}
                  total={totalRecord}
                  showTotal={total => `Tổng: ${total}`}
                  onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                  onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                  showSizeChanger={true}
                />
              </div>
            </div>
        </div>
      </Spin>

      <Modal
        visible={isLoginWF}
        onCancel={() => {setIsLoginWF(false)}}> 
        <LoginWF title={titleWF} onClose={() => setIsLoginWF(false)} />
      </Modal>

      <Modal
        visible={visibleDetail}
        width={'60%'}
        onCancel={() => {}}> 
        <Detail patientID={patientId} dateFrom={props.dateFrom} dateTo={props.dateTo} onClose={() => {setVisibleDetail(false)}} />
      </Modal>
    </React.Fragment>
  );
  
})