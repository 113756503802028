import { DatePicker, Form, Input, Modal, notification, Pagination, Popconfirm, Select, Table, Dropdown, Menu } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as NhanVienService from '../../services/employeeService';
import * as ConfigService from '../../services/configService';
import * as Actions from '../../libs/actions';
import TaoNhanVien from './taonhanvien';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import HeaderModal from '../../configureHIS/common/headerModal'
const { Option } = Select;
const initialState = {
    visible: false,
    visibleCreate: false,
    visibleUpdate: false,
    vitri: '',
    role: null,
    cmnd: '',
    id: '',
    tinhtrang: '',
    hoten: '',
    diachi: '',
    phone: '',
    chucvu: '',
    key: '',
    ngaysinh: null,
    totalRecords: 0,
    detail: {},
}
export default class QuanLyNhanVien extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            lstViTri: [],
            lstNhanVien: [],
            currentPage: 1,
            currentSize: 10,
            lstChinhanh: [],
            chinhanh: '',
            hub: '',
            lstHub: [],
            sorting : null,
        };
    }

    componentDidMount() {
        this.loadNhanVien();
        this.loadViTri();
        this.GetListSite();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ lstChinhanh: result.data })
            }
            else {
                this.setState({ lstChinhanh: [] })
                Actions.openMessageDialog('error', result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog('error', err);

        })
    }

    loadNhanVien() {
        let filterModel = {
            //common: paging & sorting
            skipCount: (this.state.currentPage - 1) * this.state.currentSize,
            maxResultCount: this.state.currentSize,
            sorting: this.state.sorting,
            //riêng theo module
            keyword: this.state.key,
            role : this.state.role
        }
        NhanVienService.GetListEmployee(filterModel).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNhanVien: result.data.items,
                    totalRecords: result.data.totalCount
                });
            }
        }).catch(err => {
        })
    }
    loadViTri() {
        NhanVienService.DanhSachVitri().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstViTri: result.data,
                });
            }
        }).catch(err => {
        })
    }
    showModalUpdate = () => {
        this.setState({
            visibleUpdate: true,
        })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    reset() {
        this.setState({
            role: null,
            cmnd: '',
            hoten: null,
            diachi: '',
            phone: '',
            chucvu: '',
            key: '',
            ngaysinh: null,
            vitri: '',
        })
    }
    DeleteNhanVien(row) {
        const data = {
            id: row.id,
        }
        NhanVienService.XoaNhanVien(data).then(result => {
            if (result.isSuccess) {
                this.loadNhanVien();
                notification.success({ message: 'Xóa thành công!' });
                this.reset();
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString.toString());
            }

        }).catch(err => { })
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    handleOk = (isUpdate) => {
        if (isUpdate)
            this.handleCancel();
        this.loadNhanVien();
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };

    onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
        let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
            this.setState({sorting : sort})
        }
        else {
            this.setState({sorting : ''})
        }
        this.loadNhanVien();
    };

    rowOptions = (record) => {
        return (
        <Menu
            items={[
                {
                    label: 
                    <a href="#" onClick={() => {
                        this.setState({ visibleCreate: true }, () => this.createStaff.setUpdateInfo(record));
                    }}><i className="fa fa-edit"/> Cập nhật </a>,
                    key: '0',
                },
                {
                    label: 
                    <>
                        <Popconfirm
                            title="Bạn có chắc muốn xóa?"
                            onConfirm={(e) => { this.DeleteNhanVien(record); }}
                            okText="Đồng ý" cancelText="Hủy">
                            <a href="#"><i className="fa fa-trash"/> Xóa </a>
                        </Popconfirm>
                    
                    </>,
                    
                    key: '1',
                },
            ]}
        />
        )
    }

    render() {
        const columnData = [
            {
                title: '#',
                width: 60,
                align: 'Center',
                render: (text, record, index) => (this.state.currentPage - 1) * this.state.currentSize + Number(index) + 1,
            },
            {
                title: 'Mã nhân viên',
                dataIndex: 'code',
                align : 'center',
                key: 'code',
                width: 100,
            },
            {
                title: 'Thao tác',
                align: 'center',
                width: 100,
                render: (row, record) => {
                    return (
                        <>
                        <Dropdown overlay={this.rowOptions(record)} trigger={['click']}>
                            <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                        </Dropdown>
                        </>
                    )          
                }
            },
            {
                title: 'Họ tên',
                dataIndex: 'hoTen',
                key: 'hoTen',
                sorter: () => {},
                width: 120,
            },
            {
                title: 'Số điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                width: 150,
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'diaChi',
                key: 'diaChi',
                width: 120,
            },
            {
                title: 'CMND',
                dataIndex: 'cmnd',
                key: 'cmnd',
                width: 120,
            },
            {
                title: 'Vị trí',
                dataIndex: 'role',
                key: 'role',
                align: 'left',
                width: 120,
            },
            {
                title: 'Người quản lý',
                dataIndex: 'parentName',
                key: 'parentName',
                align: 'left',
                width: 120,
            },
        ]

        return (
            <>
            <div className='containerChilderWrapper'>
                <HeaderModal title={'Quản lý nhân viên'} />
                <div className="row mb-2">
                    <div className="col-md-5 col-sm-6">
                        <label>Chức vụ</label>
                        <Select
                            style={{ width: "100%" }}
                            value={this.state.role} placeholder="Chọn chức vụ"
                            onSelect={(e) => {this.setState({ role: e })}}
                            showSearch
                            allowClear
                            onClear={() => {this.setState({role : null})}}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSearch={(value) => {}}>
                            {this.state.lstViTri && this.state.lstViTri.map((value, index) => {
                                return (
                                    <Option key={index} value={value.value}>{value.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="col-md-7 col-sm-6">
                        <label>Từ khóa</label>
                        <Input
                            placeholder="Nhập mã, tên, cccd, điện thoại nhân viên"
                            value={this.state.key}
                            onChange={(e) => {this.setState({ key: e.target.value })}}
                            onPressEnter={() => {
                                this.setState({ currentPage: 1 }, () => { this.loadNhanVien() })
                            }}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn btn-primary mr-1" onClick={() => {
                            this.setState({ currentPage: 1 }, () => { this.loadNhanVien() })
                        }}><i className="fa fa-search mr-1"></i> Tìm kiếm</button>
                        <button className="btn btn-secondary" onClick={() => {this.reset()}}><i className="fa fa-eraser mr-1"></i> Xóa dữ liệu nhập</button>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6 d-flex justify-content-start">
                        <button className="btn btn-color mr-1" onClick={() => {this.showModalCreate()}}><i className="fa fa-plus mr-1"></i> Tạo mới</button>
                    </div>
                </div>
                <div className='mt-2' style={{width:'100%', height:'calc(100% - 245px)'}}>
                    <Table 
                        dataSource={this.state.lstNhanVien} 
                        scroll={{x: 'max-content', y: 0 }}
                        pagination={false}
                        columns={columnData} rowKey={(e, index) => index}
                        onChange={this.onTableChange}
                        locale={{
                            triggerDesc: "Bấm để sắp xếp giảm dần",
                            triggerAsc: "Bấm để sắp xếp tăng dần",
                            cancelSort: "Bấm để hủy bỏ sắp xếp",
                        }}
                    />
                </div>
                <Pagination
                    current={this.state.currentPage}
                    size={this.state.currentSize}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={this.state.totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => { this.setState({ currentPage: page, currentSize: size }, () => { this.loadNhanVien() }) }}
                    onShowSizeChange={(page, size) => {
                        this.setState({ currentSize: size }, () => {
                                this.loadNhanVien()
                            })
                    }}
                    showSizeChanger={true}
                />
            </div>
            {this.state.visibleCreate && <Modal maskClosable={false}
                width={800}
                visible={this.state.visibleCreate}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}>
                <TaoNhanVien onCancel={this.handleCancel} onOK={(isUpdate) => this.handleOk(isUpdate)} ref={(re) => this.createStaff = re}/>
            </Modal>
            }
        </>
        )
    }
}
