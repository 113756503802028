import { AutoComplete, Tooltip } from 'antd';
import React, { Fragment, useState, useRef, useCallback, useImperativeHandle, forwardRef, useEffect } from 'react';
// import * as MedicineService from '../../../services/medicineService';
import * as ProductServices from "../../services/ProductService";

/** Bao gồm cả các dịch vụ & gói (gói chọn nhanh. Chọn gói 1 phát là chọn các dịch vụ trong gói).
 * Hướng dẫn sử dụng component
 * Các component đang sử dụng:
    - src\screens\exam\examination\detail-components\clinical-exam-indication.js
 ** Các props nhận vào:
* @param {Array} props.selectedListServices: list dịch vụ đã được chọn trước đó. 
* @param {string} props.bangGia : (Bắt buộc) Bảng giá
* //Các props liên quan hiển thị
 * @param {boolean} props.showBangGia : hiện giá trên dịch vụ. Mặc định = false
 * Cái này dùng cho 1 số component truyền vào. list dịch vụ bên phải không có item thì bảng giá cũng bị disable nếu props.disableBangGia này true
 * 
    *theo model (dùng tên field product không dùng service để tránh nhầm lẫn các từ khóa service trong code)
  ** Model của product (dùng cho props.selectedListServices & arrayResult):
     ** {
      ** id: number (bắt buộc)
      ** loaiId: 15
      ** loaiTen: "HOÁ SINH MIỄN DỊCH"
      ** ma: "2-1496" (bắt buộc)
      ** maDv: null
      ** nhomId: 2
      ** nhomTen: "Xét nghiệm"
      ** ten: "A/G"  (bắt buộc)
      ** idChiDinh: null (không bắt buộc, dành cho những chỗ có dùng idChiDinh)
      ** allowDelete: true | false (bắt buộc)
      ** //dành cho liên quan đến giá
      ** gia_Th: (không bắt buộc) đơn giá
      ** paid: (không bắt buộc) =1: đã thanh toán. =0: chưa thanh toán
      ** tenKhoaPhong: (không bắt buộc)
      ** // extend:2 cái dưới dùng nội bộ trong component này & trả về cho component cha biết status
      ** uiStatus: selected | new 
      **  - selected : từ component cha truyền vào (đã có trong db) 
      **  - new: đang được chọn
      ** uiDeleted: true | false //field này chỉ dành cho uiStatus=selected. 
      **  - true: trả lại component cha lả bị xóa. (để dành cho sau này có trường hợp: từ component cha click reset (không thay đổi list)).
    ** }
    ** cái props.selectedListServices với uiStatus = selected hoặc undefined thì khi trả về arrayResult (trong hàm props.onConfirm) sẽ giữ nguyên các phần tử.
    ** Nếu có xóa trong props.selectedListServices thì sẽ có uiDeleted = true.
    ** (*) Trả như vậy cho component cha tự xử, muốn làm gì làm. Ví dụ có trường hợp: từ component cha click reset (không thay đổi list) -> trả về list như ban đầu.
    Note (*): còn tùy, component cha mà có props.onDeleteItem thì sẽ xóa luôn item, chứ không chuyển uiDeleted=true.
 */
let timeoutToSearch;

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));

    const firstRender = useRef(true);
    //#region khai báo state
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    //state paging và sorting
    const [skipCount, setSkipCount] = useState(0);
    const [maxResultCount, setMaxResultCount] = useState(1000);
    const [sorting, setSorting] = useState('');
    //search/filter model
    const [keyword, setKeyword] = useState('');
    const [nhomMas, setNhomMas] = useState([]);
    const [loaiIds, setLoaiIds] = useState([]);
    const [bangGia, setBangGia] = useState(props.bangGia?.toLowerCase() == 'default' ? 'Mặc định' : props.bangGia);
    //result model
    const [totalRecord, setTotalRecord] = useState(0);
    const [listResult, setListResult] = useState([]);
    const [options, setOptions] = useState([]);
    // const [keyword, setKeyword] = useState(null);
    // const [listThuoc, setListThuoc] = useState([]);
    // // Thuốc đơn (liên quan đến kho) hay thuốc kê ngoài (Không liên quan đến kho)
    // const [isDonNgoai,] = useState(props.isDonNgoai ?? true);

    useEffect(() => {
        if (firstRender.current) return;
        search();
    }, [skipCount])
    useEffect(() => {
        if (firstRender.current) return;
        resetToFirstPage();
    }, [nhomMas, loaiIds, bangGia])

    const search = (kw) => {
        // Actions.setLoading(true);
        // setListResultLoading(true);
        let filterModel = {
          //common: paging & sorting
          skipCount: skipCount,
          maxResultCount: maxResultCount,
          sorting: sorting,
          //riêng theo module
          keyword: kw,
          nhomMas: nhomMas,
          loaiIds: loaiIds,
          bangGia: bangGia,
          hides: [0],
        }
        ProductServices.getListVPAndPackage(filterModel).then(result => {
          if (result.isSuccess) {
            setTotalRecord(result.data.totalCount);
            // if (skipCount > 0) setListResult([...listResult, ...result.data.items]);
            // else setListResult(result.data.items);
            // let ops = [];
            // for (let item of [...listResult, ...result.data.items]) {
            //     ops.push(renderItem(item));
            // }
            
            let ops = [];
            for (let item of result.data.items) {
                ops.push(renderItem(item));
            }
            setOptions(ops);
            // notiRef && notiRef.current.Close();
          }
        //   else {
        //     notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
        //   }
        }).catch(err => {
        //   if (err.error && err.error.messageText)
        //     notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
        //   else notiRef.current.Show(err, NotifyStatus.Warning);
        }).finally(() => {
            // Actions.setLoading(false);
        //   setListResultLoading(false);
            forceUpdate();
        })
    }

    const resetToFirstPage = () => {
      // if (pageIndex != 1) setPageIndex(1);
      // else search();
      if (skipCount > 0) setSkipCount(0);
      else search();
    }
    
    const renderItem = (item) => {
        return {
            value: `${item.isPackage ? item.packageId : item.id}`,
            rawData: item,
            label: (
                item.isPackage ?
                    <div className="d-flex flex-column w-100" key={`pk-${item.packageId}`}>{/* 1 item */}
                        <div className="d-flex justify-content-between w-100">{/* 1 row in item */}
                            <div className=''>{/* column 1 */}
                                <label className='mita-title'>{item.packageName}</label>
                            </div>
                        </div>
                        {item.listChild.map((record, index) => {
                            return renderDv(record, record.isInBangGia, true);
                        })}
                    </div>
                    :
                    renderDv(item)
            ),
        };
    }

    const renderDv = (item, isInBangGia = true, isChild = false) => {
        let classInBangGia = (isInBangGia ? '' : 'text-decoration-line-through');
        //text-decoration-line-through
        return (
            <div className={`${classInBangGia} d-flex justify-content-between w-100 ${isChild ? "pl-2" : ""}`} key={`pr-${item.id}`}
                
            >{/* 1 item */}
                <div className='' style={{width: 'calc(70% - 84px)'}}>{/* column 1: tên - mã */}
                    <span className={`mita-${isChild ? 'value' : 'title'}`} style={{whiteSpace: "pre-line"}}>{item.ten} - {item.ma}</span>
                </div>
                <div className='' style={{width: '30%'}}>{/* column 2: nhóm/loại */}
                    <span className='mita-value' style={{whiteSpace: "pre-line"}}>{item.nhomTen} / {item.loaiTen}</span>
                </div>
                {props.showBangGia &&
                <div className='text-right' style={{width: 80}}>{/* column 3: giá */}
                    {isInBangGia ? 
                        <span className='mita-title' style={{whiteSpace: "pre-line"}}>{(item.gia_Th ?? 0).toLocaleString('en-US')}</span> 
                        : 
                        <Tooltip title='Không có trong bảng giá'><span className='text-danger'><i className='fas fa-times-circle' /></span></Tooltip>
                    }
                </div>
                }
            </div>
        )
    }

    const handleSearch = (e) => {
        if (timeoutToSearch) {
            clearTimeout(timeoutToSearch);
            timeoutToSearch = null;
        }
        timeoutToSearch = setTimeout(() => {
            search(e);
        }, 450)
    }

    const handleSelect = (value, record) => {
    //     let foundThuoc = listThuoc.filter(x => x.value === value && x.rawData.isGoiThuoc === record.rawData.isGoiThuoc);
    //     foundThuoc && foundThuoc.length > 0 && props.handleSelect(foundThuoc[0].rawData);
        if (record.rawData) {
            var item = record.rawData;
            let items = item.isPackage ? item.listChild : [item];
            props.onSelect(items);
        }
        // this.setState({
        //     // searchInput: item.patientName
        //     searchInput: '',
        //     options: [], 
        //     searchList: []
        //   });
        setKeyword(null);
        setOptions([]);
        forceUpdate();
    }

    return (
        <Fragment>
            <AutoComplete
                className='w-100'
                placeholder="Tìm theo mã, tên dịch vụ hoặc gói dịch vụ"
                value={keyword}
                onChange={e => { setKeyword(e) }}
                onSelect={handleSelect}
                onSearch={(e) => handleSearch(e)}
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={500}
                options={options}
                // listHeight="50vh"
                // listHeight={props.listHeight ? props.listHeight : 400}
                maxLength={40}
                disabled={false}
            >
            </AutoComplete>
        </Fragment>
    )
})