import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import {  Button, List, Dropdown, Menu, Select, Checkbox, Drawer, Tooltip, Input, notification, DatePicker, Modal, Switch } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import * as PrintTemplateService from '../../services/printTemplateService';
import Notify, { NotifyStatus } from '../../components/notify';
import MitaDisplayLongText from '../../components/MitaComponents/mitaDisplayLongText';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import Detail from "./detail";
import Modified from "./modified";
import { connect } from 'react-redux';

const { Option } = Select;
const PrintTemplateIndex = React.forwardRef((props, ref) => {
// export default forwardRef((props, ref) => {
  //niếm cục bộ
  //ref
  const notiRef = useRef(null);
  const detailRef = useRef();
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [skipCount, setSkipCount] = useState(0);
  const [maxResultCount, setMaxResultCount] = useState(10);
  const [sortingField, setSortingField] = useState('');
  const [sortingType, setSortingType] = useState('');
  const [sorting, setSorting] = useState('');
  //search/filter
  const [keyword, setKeyword] = useState('');
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  const [sortFields, setSortFields] = useState([]);
  const [sortTypes, setSortTypes] = useState([]);
  //row options
  const [visibleDetail, setVisibleDetail] = useState(false); // set visible modal detail
  const [visibleModified, setVisibleModified] = useState(false); // set visible modal update
  const [selectedId, setSelectedId] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const listPageSize = [10,20,50,100];
  
  //table
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [maxResultCount, sortingField, sortingType])

  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [skipCount])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      search();
    }
  }, []);
  //#endregion 


  //#region search & filter
  const validate = () => {
    let listErr = [];
    return listErr;
  }

  const search = (skip = null, maxResult = null) => {
    let listErr = validate();
    if (listErr.length > 0) {
      let err = listErr.join('\n');
      notiRef.current.Show(err, NotifyStatus.Error);
      return;
    }
    Actions.setLoading(true);
    if (!skip) skip = skipCount;
    if (!maxResult) maxResult = maxResultCount;
    let filterModel = {
      //common: paging & sorting
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      // sorting: ((sortingField ?? '').length < 1 ? 'ngaydangky' : sortingField) + ((sortingType ?? '').length < 1 ? ' asc' : sortingType),
      //riêng theo module
      keyword: keyword,
    }
    PrintTemplateService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        // setSortFields(result.data.sortFields);
        // setSortTypes(result.data.sortTypes);
        if (skipCount == 0)
          setListResult(result.data.items);
        else {
          setListResult([...listResult, ...result.data.items]);
        }
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const reload = () => {
    let maxRes = listResult.length;
    search(0, maxRes);
  }

  const resetToFirstPage = () => {
    if (skipCount != 0) setSkipCount(0);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }

  const resetFilter = () => {
    setKeyword();
  }
  //#endregion 
  //#region table info & event
  const loadModified = (row, detailScrMode) => {
    setDetailScreenMode(detailScrMode);
    setSelectedRecord(row);
    setVisibleModified(true); 
    setVisibleDetail(false);
  }
  const loadDetail = (row, detailScrMode) => {
    setSelectedRecord(row);
    setVisibleModified(false); 
    setVisibleDetail(true);
  }
  
  //các options cho row
  const rowOptions = (record) => {
    let items = [
      {
        label: 
          <a href="#" className='stopRowClick' onClick={(e) => {
            loadDetail(record, detailScreenModeEnum.detail);
          }}><i className='fa fa-eye mr-2'/> Xem chi tiết</a>,
        key: 'viewdetail',
      },
      {
        label: 
          <a href="#" className='stopRowClick' onClick={(e) => {
            loadModified(record, detailScreenModeEnum.update);
          }}><i className='fas fa-pencil-alt mr-2'/> Cập nhật</a>,
        key: 'edit',
      },
      {
        label: 
          <a href="#" className='stopRowClick' onClick={(e) => {
            downloadTemplate(record.id)
          }}><i className='fas fa-download mr-2'/> Tải về</a>,
        key: 'download',
      },
      {
        label: 
          <a href="#" className='stopRowClick' onClick={(e) => {
            deleteTemplate(record.id)
          }}><i className='fas fa-trash-alt mr-2'/> Xóa</a>,
        key: 'delete',
      },
    ];
    return (
      <Menu items={items} />
    )
  }
  const onClickFunction = (e, record) => {
    if (
      e.target.className.indexOf('stopRowClick')>-1
    ) return;
    record && loadDetail(record, detailScreenModeEnum.detail);
    e.stopPropagation();
  }
  const deleteTemplate = (id) => {
    PrintTemplateService.deleteById(id).then(result => {
      if (result.isSuccess) {
        notiRef && notiRef.current.Close();
        resetToFirstPage();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }
  const downloadTemplate = (id) => {
    PrintTemplateService.download(id).then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = "Template.html";
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    })
  }
  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleModified(false);
    if (reload) resetToFirstPage();
  };
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Template in</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">{/* Từ khóa | Hình thức trả kết quả */}
                <div className="col-md-12 d-flex flex-column">{/* Từ khóa */}
                  <label>Từ khóa</label>
                  <Input
                    placeholder="Nhập tên, mô tả hoặc component ID"
                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                </div>
              </div>
              <div className="row mt-2">
                  <div className='col-md-6 d-flex justify-content-start align-items-start'>
                      <Button className="pl-0"
                        type="link"
                          onClick={() => {
                            loadModified(null, detailScreenModeEnum.create);
                          }}
                      ><i className="fa fa-plus-circle mr-1" />Tạo mới</Button>
                  </div>
                  {/* <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <button className="btn btn-color" type="button" onClick={() => { onExportListClickHandle(); }}
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                  </div> */}
              </div>
            </div>
            <div className="list-page-table mt-2">{/* list */}
              <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                bordered={false}
                dataSource={listResult}
                locale={'Không có dữ liệu' }
                renderItem={(record) => {
                  let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover";
                  return (
                    <List.Item
                      onClick={(e) => {onClickFunction(e, record)}}
                      key={record.sid}
                      className={className}
                    >
                      <div className="d-flex justify-content-between w-100">{/* 1 item */}
                        <div className="d-flex flex-column justify-content-start h-100" style={{width: 16}}>{/* column 1 */}
                          {/* <Checkbox className="mr-2"
                              checked={maqls.indexOf(record.maQL) > -1}
                              onChange={(e) => {
                                if (e.target.checked) setMaqls([...maqls, record.maQL]);
                                else setMaqls(maqls.filter(x => x != record.maQL))
                              }}
                              onClick={(e) => {e.stopPropagation();}}
                          ></Checkbox> */}
                        </div>
                        <div className="d-flex flex-column justify-content-start h-100" style={{width: '40%'}}>{/* column 2 */}
                          <div className="">{/* Tên template */}
                            <label className="mita-title">Tên template: </label>
                            <label className="mita-value">{record.printTemplateName}</label>
                          </div>
                          <div className="">{/* Component id */}
                            <label className="mita-title">Component Id: </label>
                            <label className="mita-value">{record.componentId}</label>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-start h-100" style={{width: 'calc(60% - 28px)'}}>{/* column 3 */}
                          <div className="">{/* Mô tả */}
                            <label className="mita-title">Mô tả: </label>
                            <label className="mita-value" style={{whiteSpace: "pre-line"}}>{record.printTemplateDescription ?? ""}</label>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-start h-100 mr-4" style={{ width: 100 }}>
                            <label className="mita-title">Mặc định: </label>
                            <label className="mita-value">
                              <Switch disabled checked={record.isDefault}></Switch>
                            </label>
                        </div>
                        <div className="d-flex flex-column justify-content-start h-100" style={{width: 12}}>{/* column 4 */}
                          <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={(e) => onClickFunction(e)}><i className='fas fa-ellipsis-v'></i></a>
                          </Dropdown>
                        </div>
                      </div>
                    </List.Item>
                )}}
              >
                <List.Item key="noData">
                  <div className="w-100 d-flex justify-content-center">
                    <label hidden={listResult.length > 0}>Không có dữ liệu</label>
                  </div>
                </List.Item>
                <List.Item key="showMore">
                  <div className="w-100 d-flex justify-content-center">
                    <Button
                      // type="link"
                      hidden={totalRecord <= listResult.length}
                      onClick={() => {
                        setSkipCount(skipCount + maxResultCount);
                      }}
                    >Hiển thị thêm {totalRecord - listResult.length}</Button>
                  </div>
                </List.Item>
              </List>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={true}
        closable={false}
        width={"60%"}
        placement="right"
        visible={visibleDetail}
        destroyOnClose={true}
      >
        <Detail
          isDialog={ true } 
          record={selectedRecord}
          detailScreenMode = {detailScreenModeEnum.detail}
          onReload={() => {reload();}}
          onCancel={() => {setVisibleDetail(false)}}
          onModifiedClick={() => { loadModified(selectedRecord, detailScreenModeEnum.update) }}
          onDownloadClick={() => { downloadTemplate(selectedRecord.id) }}
        />
      </Drawer>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={true}
        closable={false}
        width={"60%"}
        placement="right"
        visible={visibleModified}
        destroyOnClose={true}
      >
        <Modified
          isDialog={ true } 
          record={selectedRecord}
          detailScreenMode = {detailScreenMode}
          onDownloadClick={() => { downloadTemplate(selectedRecord.id) }}
          onCancel={(reload = false) => { if (reload) resetToFirstPage(); setVisibleModified(false); }}
        />
      </Drawer>
    </React.Fragment>
  ); 
})
const mapStateToProps = (state) => {
  return ({
      // siteid: state.global.siteid,
      // availableSites: state.global.availableSites
  })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(PrintTemplateIndex);