import React, { Fragment, useRef, useEffect, useState, useCallback } from 'react';
import Notify, { NotifyStatus } from '../../components/notify';
import * as Actions from '../../libs/actions';
import HeaderModal from '../../configureHIS/common/headerModal';
import PatientInfo from "../../screens/patient/component/patientInfo";
import VitalSignInfo from "../../screens/patient/component/vitalSign";
import { Collapse } from 'antd';
import { isNullOrEmpty } from '../../libs/util';
import * as TestReceivedServices from '../../services/testReceivedService';
import ThongTinLayMau from './thongTinLayMau';

const { Panel } = Collapse;

function Detail(props) {
    const _notiRef = useRef();
    const [data, setData] = useState(props.data);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    
    useEffect(() => {
        getDetail();
    }, []);

    const getDetail = () => {
        TestReceivedServices.getTestReceivedByOrderId(props.data.orderId).then(result => {
            if (result.isSuccess) {
                _notiRef && _notiRef.current.Close();
                if (result.data)
                    setData(result.data);
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }
    const onCancel = () => {
        props.onCancel && props.onCancel();
    }
    const scrollToTop = () => {
        var containerToScroll = document.getElementById('container');
        containerToScroll.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    return (
        <Fragment>
            <div className='list-page'>
                <HeaderModal title={`Lấy mẫu - ${data.hoTen}${!isNullOrEmpty(data.sid) ? " - " + data.sid : ""}`} onClose={() => { props.onCancel() }} />
                <div className="list-page-body">
                    <div className="w-100"><Notify ref={_notiRef} /></div>
                    <div className="w-100 h-100 d-flex justify-content-between" style={{ overflowY: "hidden" }}>
                        <div style={{ width: '60%', overflowY: "auto"  }}>
                            <Collapse defaultActiveKey={['pif', 'vts']} expandIconPosition="right">
                                <Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="pif">
                                    <PatientInfo patientId={data.maBN} col={12}></PatientInfo>
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Thông tin dấu hiệu sinh tồn</label>} key="vts">
                                    <div className="row my-2" style={{ lineHeight: "20px" }}>
                                        <VitalSignInfo maql={data.maQL} col={12}></VitalSignInfo>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                        <div className="h-100 w-100 position-relative" id="container" style={{ overflowY: "auto" }}>
                            <div className='h-100 mx-2 border position-relative'>
                                <Collapse defaultActiveKey={['ttlm']} expandIconPosition="right">
                                    <Panel header={<label className='mita-header-title'>Thông tin lấy mẫu</label>} key="ttlm">
                                        <ThongTinLayMau
                                            data={data}
                                            onCancel={() => { onCancel() }}
                                            reload={() => { getDetail() }}
                                            onScrollToTop={() => scrollToTop()}
                                        ></ThongTinLayMau>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Detail;