import { AutoComplete, Input } from 'antd';
import React, { Fragment, useState, useCallback, useImperativeHandle, forwardRef, useEffect } from 'react';
import * as MedicineService from '../../../services/medicineService';

let timeoutToSearch;

export default forwardRef((props, ref) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    useImperativeHandle(ref, () => ({
    }));

    const [keyword, setKeyword] = useState(null);
    const [listThuoc, setListThuoc] = useState([]);
    // Thuốc đơn (liên quan đến kho) hay thuốc kê ngoài (Không liên quan đến kho)
    const [isDonNgoai,] = useState(props.isDonNgoai ?? true);

    const searchThuoc = (value) => {
        let options = [];
        let skip = 0;
        let maxCount = 20;
        let filterModel = {
            skipCount: skip,
            maxResultCount: maxCount,
            keyword: value,
            isDonNgoai: isDonNgoai,
        }
        MedicineService.getListMedicine(filterModel).then(result => {
            if (result.isSuccess) {
                for (let item of result.data.items) {
                    item.soLuong = 1;
                    item.sang = "0";
                    item.trua = "0";
                    item.chieu = "0";
                    item.toi = "0";
                    item.ngayDung = 1;
                    item.cachDung = "";
                    item.isNew = true;
                    if (item.listThuoc && item.listThuoc.length > 0) {
                        let danhSachThuoc = item.listThuoc;
                        if (danhSachThuoc && danhSachThuoc.length > 0) {
                            danhSachThuoc = danhSachThuoc.map(x => (
                                {
                                    ...x,
                                    sang: x.sang.toString(),
                                    trua: x.trua.toString(),
                                    chieu: x.chieu.toString(),
                                    toi: x.toi.toString(),
                                    isNew: true,
                                }
                            ))
                        }
                        item.listThuoc = danhSachThuoc
                    }
                    options.push(renderItem(item))
                };
                setListThuoc(options);
            }
            else { }
        }).catch(() => {
        }).finally(() => {
            forceUpdate();
        });
    }

    const renderItem = (thuoc) => {
        return {
            value: thuoc.isGoiThuoc ? thuoc.maGoiThuoc : thuoc.maThuoc,
            rawData: thuoc,
            label: (
                thuoc.isGoiThuoc
                    ?
                    <div key={`${thuoc.tenGoiThuoc}-${thuoc.maGoiThuoc}`}>
                        <div className='w-100'>
                            <label className='mita-title'>{thuoc.tenGoiThuoc}</label>
                        </div>
                        {thuoc.listThuoc && thuoc.listThuoc.map(subThuoc => {
                            return (
                                <div className='w-100 d-flex justify-content-between'>
                                    <label><i>Tên thuốc: {subThuoc.tenThuoc} {subThuoc.hamLuong ?? ""}</i></label>
                                    <label>{subThuoc.donViTinh}</label>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div key={`${thuoc.maThuoc}-${thuoc.tenThuoc}-${thuoc.hoatChat}`}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label className='mita-title'>{thuoc.hoatChat}</label>
                            <label className='mita-title'>{!isDonNgoai && thuoc.soLuongTon.toLocaleString('en-US')} {thuoc.donViTinh}</label>
                        </div>
                        <div className='w-100 d-flex justify-content-between'>
                            <label><i>Tên thuốc: {thuoc.tenThuoc} {thuoc.hamLuong ?? ""}</i></label>
                        </div>
                    </div>
            ),
        };
    }

    const handleSearch = (e) => {
        if (timeoutToSearch) {
            clearTimeout(timeoutToSearch);
            timeoutToSearch = null;
        }
        timeoutToSearch = setTimeout(() => {
            searchThuoc(e);
        }, 450)
    }

    const handleSelect = (value, record) => {
        let foundThuoc = listThuoc.filter(x => x.value === value && x.rawData.isGoiThuoc === record.rawData.isGoiThuoc);
        foundThuoc && foundThuoc.length > 0 && props.handleSelect(foundThuoc[0].rawData);
        setKeyword(null);
        forceUpdate();
    }

    return (
        <Fragment>
            <AutoComplete
                className='w-100'
                placeholder="Tìm theo mã, tên, hoạt chất hoặc tên gói thuốc"
                value={keyword}
                onChange={e => { setKeyword(e) }}
                onSelect={handleSelect}
                onSearch={(e) => handleSearch(e)}
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={500}
                options={listThuoc}
                listHeight={500}
                maxLength={40}
                disabled={false}
            >
            </AutoComplete>
        </Fragment>
    )
})