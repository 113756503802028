import { Button, Modal, Pagination, Popconfirm, Input, notification, Table, Tooltip, Switch, Select } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import * as Actions from '../../libs/actions';
import Update from './update';
import * as CoporationServices from "./CorporationServices";
import { FEATURE } from '../../configureHIS/common/constants';
import { FeatureDeactive } from '../../configure/featureConfig';

function Corporation() {
    const [dataSource, setDataSource] = useState([]);
    const [detail, setDetail] = useState({});
    const [modalCreate, setModalCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const [kwd, setKwd] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [hide, setHide] = useState(false);
    const [status, setStatus] = useState('');
    const {Option} = Select;

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'ma',
            key: 'ma',
            width: 90
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: 220
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'diachi',
            key: 'diachi',
            width: 250,
            render: (value) => {
                return (
                    <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: `${value}` }}>
                    </div>
                )
            }
        },
        {
            title: 'Người đại diện',
            dataIndex: 'daidien',
            key: 'daidien',
            width: 200
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 160
        },
        {
            title: 'Tài khoản ngân hàng',
            dataIndex: 'taikhoan',
            key: 'taikhoan',
            width: 160
        },
        // {
        //     title: 'Tài khoản xem KQ',
        //     dataIndex: 'username',
        //     key: 'username',
        //     width: 160
        // },
        // {
        //     title: 'Mật khẩu xem KQ',
        //     dataIndex: 'password',
        //     key: 'password',
        //     width: 160
        // },
        {
            title: 'Sale',
            dataIndex: 'saleName',
            key: 'sale',
            width: 160
        },
        {
            title: 'Người giới thiệu',
            dataIndex: 'tennguoigioithieu',
            key: 'nguoigioithieu',
            width: 160
        },
        ...FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 ? [{
            title: 'Bảng giá',
            dataIndex: 'banggia',
            key: 'banggia',
            width: 160,
            render:(value) => {
                return (
                    <span>{value === 'default' ? "Mặc định" : value}</span>
                )
            }
        }] : [],
        {
            title: 'Hiển thị',
            align: 'center',
            width: 100,
            render: (row) => {
                return (
                    <Switch loading={hide} checkedChildren="Hiển thị" unCheckedChildren="Ẩn" checked={!row.hienthi}
                        onChange={(e) => { updateHide(row.ma, e); }} />
                )
            }
        },
        {
            title: 'Thao tác',
            width: 150,
            render: (row) => {
                return (
                    <div className='d-flex'>
                        <Tooltip placement="top" title="Sửa">
                            <Button className='btn btn-warning'
                                onClick={() => {
                                    setEdit(true);
                                    setDetail(row);
                                    setModalCreate(true);
                                }}
                            ><i className='fa fa-edit'></i></Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Xóa">
                            <Popconfirm
                                title="Xác nhận xóa?"
                                onConfirm={(e) => {
                                    deleteCoporation(row.ma);
                                }}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Đồng ý" cancelText="Hủy">
                                <Button className='="btn btn-danger ml-2'><i className='fa fa-trash'></i></Button>
                            </Popconfirm>
                        </Tooltip>
                        {/* <Tooltip placement="top" title="Đặt lại mật khẩu">
                            <Popconfirm
                                title="Xác nhận đặt lại mật khẩu?"
                                onConfirm={(e) => {
                                    ResetPWCoporation(row.ma);
                                }}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Đồng ý" cancelText="Hủy">
                                <Button className='="btn btn-color ml-2'><i className='fa fa-sync-alt'></i></Button>
                            </Popconfirm>
                        </Tooltip> */}
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        getList()
    }, [currentPage, pageSize, status]);

    const getList = (page = currentPage, size = pageSize) => {
        CoporationServices.GetAllCoporation(kwd, status, page, size).then(res => {
            if (res.isSuccess) {
                setDataSource(res.data);
                setTotalRecords(res.totalRecords);
            }
        }).catch(() => { })
    }

    const ResetPWCoporation = (ma) => {
        Actions.setLoading(true);
        CoporationServices.ResetPWCoporation(ma).then(result => {
            if (result.isSuccess) {
                Actions.openMessageDialog("Thông báo", "Thao tác thành công. Mật khẩu mới là: " + result.returnObject.password);
            } else {
                result.err.msgString ?
                    notification.warning({ message: result.err.msgString })
                    : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const deleteCoporation = (ma) => {
        Actions.setLoading(true);
        CoporationServices.DeleteCoporation(ma).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' })
                getList();
            } else {
                result.err.msgString ?
                    notification.warning({ message: result.err.msgString })
                    : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const updateHide = (ma, check) =>{
        CoporationServices.HideCoporation(ma, !check).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' })
                getList();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }

    return (
        <Fragment>
            <div style={{ padding: '10px', height: '100%' }}>
                <div className={'containerChilderWrapper'}>
                    <div className="h4 text-center font-weight-bold my-2 text-uppercase">Quản lý nguồn khách hàng</div>
                    <div className="row justify-content-center">
                            <Select className="mt-2" style={{width : 120}}
                                    value={status}
                                    onChange={e => { 
                                        setStatus(e)
                                    }}
                                >
                                    <Option key={0} value=''>Tất cả</Option>
                                    <Option key={1} value='False'>Hiển thị</Option>
                                    <Option key={2} value='True'>Đã ẩn</Option>
                                </Select>
                        <div className="col-md-4">
                            <Input
                                className="mt-2"
                                value={kwd} placeholder="Nhập tên hoặc mã hoặc số điện thoại sau đó bấm Enter để tìm kiếm"
                                onChange={(e) => setKwd(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        currentPage !== 1 ? setCurrentPage(1) : getList(1);
                                    }
                                }}
                                suffix={<span onClick={() => { currentPage !== 1 ? setCurrentPage(1) : getList(1); }}><i className='fas fa-search' /></span>}>
                            </Input>
                        </div>
                        <div className="col-auto mb-2">
                            <button type="button" className="btn btn-success ml-2 mt-2"
                                onClick={() => {
                                    setEdit(false);
                                    setModalCreate(true);
                                }}>
                                <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới
                            </button>
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px', height: 'calc(100% - 120px)' }} className='mt-2'>
                        <Table dataSource={dataSource} scroll={{ y: 0 }}
                            pagination={false}
                            columns={columns}
                            rowKey={(e, index) => `tbl-copr-${e.ma}`}
                        />
                    </div>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setPageSize(size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setCurrentPage(1);
                            setPageSize(size);
                        }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
            {modalCreate &&
                <Modal
                    visible={modalCreate}
                    onCancel={() => {
                        setEdit(false);
                        setModalCreate(false);
                        setDetail({});
                    }}
                    width={1000}
                >
                    <Update edit={edit}
                        detail={detail}
                        onSuccess={() => {
                            getList();
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                        }}
                        onClose={() => {
                            getList();
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                        }} />
                </Modal>
            }
        </Fragment>
    );
}

export default Corporation;