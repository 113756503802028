import React, { Component } from 'react'
import { Input, Form, Table, Modal, Pagination, Popconfirm, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as ManagerStoreService from '../../../services/managerStore';
import CreateNCC from './createNCC';
import { ValidationCustom } from '../../../commons/validationCustom';
const initialState = {
    ma: '',
    ten: '',
    key: '',
    nhom: 0,
    nhomcc: 0,
    sotk: '',
    masothue: '',
    diachi: '',
    sodienthoai: '',
    id: 0,
    totalRecords: 0,
    currentNumber: 1,
    pageSize: 10
}
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            lstNhaCC: [],
            visibleCreate: false,
            visibleUpdate: false,
            hideUpdate: false
        };
    }
    componentWillMount() {
        this.loadListNCC();

    }

    loadDetail(id) {
        const data = new FormData();
        data.append('id', id);
        ManagerStoreService.GetDetail(data).then(result => {
            if (result.isSuccess) {
                this.setState({
                    id: result.item.id,
                    ten: result.item.ten,
                    ma: result.item.ma,
                    diachi: result.item.diachi,
                    sotk: result.item.sotk,
                    masothue: result.item.masothue,
                    sodienthoai: result.item.dienthoai,
                    visibleUpdate: true
                })
                this.form.setFieldsValue({
                    id: result.item.id,
                    ten: result.item.ten,
                    ma: result.item.ma,
                    diachi: result.item.diachi,
                    sotk: result.item.sotk,
                    masothue: result.item.masothue,
                    sodienthoai: result.item.dienthoai,
                })
            }
        }).catch(err => {
        })
    }

    loadListNCC(page = this.state.currentNumber, size = this.state.pageSize) {
        ManagerStoreService.GetListNCC(this.state.key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNhaCC: result.data,
                    totalRecords: result.totalRecords,
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải danh sách nhà cung cấp bị lỗi' })
        })
    }
    async update() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {
            const data = new FormData();
            data.append('id', this.state.id);
            data.append('ma', this.state.ma);
            data.append('ten', this.state.ten);
            data.append('diachi', this.state.diachi);
            data.append('sotk', this.state.sotk);
            data.append('masothue', this.state.masothue);
            data.append('dienthoai', this.state.sodienthoai);
            if (this.validator.allValid()) {
                ManagerStoreService.EditNCC(data).then(results => {

                    if (results.isSuccess) {

                        this.setState({
                            hideUpdate: false,
                            currentNumber: 1
                        })
                        notification.success({ message: 'Cập nhật thành công!' })
                    }
                    this.loadListNCC();
                }).catch(err => {
                    notification.warning({ message: 'Có lỗi xảy ra khi cập nhật!' })
                })
            } else {
                this.validator.showMessages();
            }
        }
    }
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    showModalUpdate = () => {

        this.setState({
            visibleUpdate: true,
        });
    };
    handleCancel = e => {
        this.setState({
            visibleCreate: false,
            visibleUpdate: false
        });
        this.loadListNCC();
    };

    XoaNCC(row) {
        const data = new FormData();
        data.append('id', row.id);
        ManagerStoreService.XoaNCC(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Nhà cung cấp ' + row.ten + ' đã bị xóa!' })
            }
            this.setState({ currentNumber: 1 }, () => {
                this.loadListNCC();
            })
        }).catch(err => {
            notification.warning({ message: 'Xóa nhà cung cấp bị lỗi' })
        })
    }
    render() {
        const columns = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                width: 70
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                width: 300
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'diachi',
                key: 'diachi',
                width: 250
            },
            {
                title: 'Số điện thoại',
                dataIndex: 'dienthoai',
                key: 'dienthoai',
                width: 120
            },
            {
                title: 'Số tài khoản',
                dataIndex: 'sotk',
                key: 'sotk',
                width: 120
            },
            {
                title: 'Mã số thuế',
                dataIndex: 'masothue',
                key: 'masothue',
                width: 120
            },
            {
                title: '',
                width: 200,
                render: (row) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-color" title="Cập nhật"
                                onClick={() => { this.loadDetail(row.id) }}>
                                <i className="fa fa-edit" style={{ marginRight: 5 }} /> Cập nhật
                            </button>
                            <Popconfirm title="Bạn có chắc muốn xóa nhà cung cấp này không?"
                                onConfirm={() => this.XoaNCC(row)}
                                okText="Đồng ý"
                                cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: 15 }}
                                    title="Xóa">
                                    <i className="fa fa-eraser" style={{ marginRight: 5 }} /> Xóa
                                </button>
                            </Popconfirm>
                        </React.Fragment>
                    )
                }

            }
        ];
        let dataSource = this.state.lstNhaCC.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <>
                <div className='containerChilderWrapper' >
                    <div className='h3 text-center mt-2'>Quản lý nhà cung cấp</div>
                    <div className="row no-gutters" style={{ justifyContent: 'center', padding: '10px' }}>
                        <div className="col-auto mb-2">
                            <Form.Item label="Tìm nhà cung cấp:">
                                <Input
                                    style={{ width: "300px" }}
                                    value={this.state.key}
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    placeholder="Nhập mã hoặc tên nhà cung cấp"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.setState({currentNumber : 1})
                                            this.loadListNCC(1, this.state.pageSize);
                                        }
                                    }} />
                            </Form.Item>
                        </div>
                        <div className="col-auto mb-2">
                            <Form.Item>
                                <button className="btn btn-color"
                                    onClick={() => this.loadListNCC(1, this.state.pageSize)}><i style={{ marginRight: 5 }} className="fa fa-search" /> Tìm </button>
                            </Form.Item>
                        </div>
                        <div className="col-auto mb-2">
                            <Form.Item>
                                <button className="btn btn-color"
                                    onClick={this.showModalCreate} type="primary"> <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{width:'100%', height:'calc(100% - 155px)'}}>
                        <Table dataSource={dataSource} scroll={{ x: 'max-content', y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        current={this.state.currentNumber}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => {
                            this.setState({ currentNumber: page, pageSize: size }, () => {
                                this.loadListNCC(page, size);
                            })
                        }}
                        onShowSizeChange={(page, size) => { this.setState({ currentNumber: 1, pageSize: size }); this.loadListNCC(1, size) }}
                        showSizeChanger={true}
                    />

                </div>


                <Modal maskClosable={false}
                    width={1000}
                    style={{ display: 'plex' }}
                    visible={this.state.visibleCreate}
                    onCancel={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateNCC onCancel={this.handleCancel} />
                </Modal>
                <Modal maskClosable={false}
                    width={1000}
                    visible={this.state.visibleUpdate}
                    onCancel={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <Form ref={(c) => this.form = c}>
                        <div className="ant-modal-header" style={{ display: 'flex', justifyContent: 'center' }}>
                            <h3>CẬP NHẬT NHÀ CUNG CẤP</h3>
                        </div>
                        <div className="ant-modal-body">
                            <div style={{ display: 'flex' }}>
                                <div className="form-group" style={{ flex: 1 }} >
                                    <Form.Item label="Tên:" name="ten"
                                        rules={[{ required: true, message: 'Yêu cầu nhập tên!' }]}>
                                        <Input
                                            value={this.state.ten}
                                            onChange={(e) => this.setState({ ten: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="form-group" style={{ flex: 1 }} >
                                    <Form.Item label="Địa chỉ:">
                                        <Input
                                            value={this.state.diachi}
                                            onChange={(e) => {
                                                this.setState({ diachi: e.target.value })
                                            }
                                            }
                                        />
                                    </Form.Item>
                                </div>
                                <div className="form-group" style={{ flex: 1 }} >
                                    <Form.Item label="Số điện thoại:">
                                        <Input
                                            value={this.state.sodienthoai}
                                            onChange={(e) => {
                                                if (isNaN(e.target.value))
                                                    return;
                                                this.setState({ sodienthoai: e.target.value });
                                                this.validator.showMessageFor("Số điện thoại");
                                            }}
                                        />
                                        {this.validator.message("Số điện thoại", this.state.sodienthoai, "phonenumber")}
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <div className="form-group" style={{ flex: 1 }} >
                                    <Form.Item label="Số tài khoản:">
                                        <Input
                                            value={this.state.sotk}
                                            onChange={(e) => {
                                                if (isNaN(e.target.value))
                                                    return;
                                                this.setState({ sotk: e.target.value })
                                            }
                                            }
                                        />
                                    </Form.Item>
                                </div>
                                <div className="form-group" style={{ flex: 1 }} >
                                    <Form.Item label="Mã số thuế:">
                                        <Input
                                            value={this.state.masothue}
                                            onChange={(e) => this.setState({ masothue: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="form-group" style={{ flex: 1 }} >
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <button className="btn btn-color" style={{ marginLeft: 10 }}
                            onClick={() => this.update() && this.setState({ hideUpdate: true })}> <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.setState({ visibleUpdate: false })
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng

                        </button>
                    </div>
                </Modal>
            </>
        )
    }
}
