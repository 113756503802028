import { Button, Form, Input, Pagination, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../libs/actions';
import * as RoleService from '../../services/roleService';

export default function AddConfig(props) {

    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [dataSelect, setDataSelect] = useState([]);
    useEffect(() => {
        setDataSelect(props.currentData);
    }, [props.currentData])
    useEffect(() => {
        if (currentPage === 0) {
            return;
        }
        GetListController()
    }, [currentPage, size]);
    const GetListController = () => {
        Actions.setLoading(true);
        RoleService.GetListController(key, currentPage, size, false).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
                setTotalRecords(result.totalRecords)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    const SelectController = (row) => {
        let data = dataSelect;
        let index = data.findIndex(x => x.id === row.id);
        if (index < 0) {
            data.push(row);
            setDataSelect(data.map(x => ({ ...x })));
        }
    }
    const RemoveSelect = (id) => {
        let data = dataSelect.filter(x => x.id !== id);
        setDataSelect(data.map(x => ({ ...x })));
    }
    const columns = [
        {
            title: 'Controller',
            dataIndex: 'controller',
            key: 'Controller',
            width: 120
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'Action',
            width: 180
        },
        {
            title: 'Method',
            dataIndex: 'method',
            key: 'Method',
            width: 80,
        }
    ];
    return (
        <React.Fragment>
            <div className="h4 font-weight-bold text-center">
                Thêm controller ({props.monitorName})
            </div>
            <div className="row">
                <div className="col-md-6 mb-2 h-100" style={{ overflowY: 'auto' }}>
                    <div className="h5">
                        Controller đã chọn
                    </div>
                    {dataSelect.map((row, index) =>
                        <div key={index} className="w-100 by-2 d-flex">
                            <div className="text-danger" style={{ cursor: 'pointer' }}
                                onClick={() => RemoveSelect(row.id)}
                            >
                                <i className="fa fa-trash"></i>
                            </div>
                            <div className="ml-2">
                                {row.method}: {row.controller}/{row.action}
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-md-6 mb-2">
                    <div className="row no-gutters" style={{ justifyContent: 'center' }}>
                        <div className="col-auto">
                            <Form.Item label="Tìm theo tên:">
                                <Input
                                    value={key}
                                    placeholder="Nhập từ khóa"
                                    onChange={(e) => setKey(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setCurrentPage(0);
                                            setTimeout(() => {
                                                setCurrentPage(1);
                                            }, 100);
                                        }
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-auto">
                            <Form.Item>
                                <Button className="btn btn-color" onClick={() => {
                                    setCurrentPage(0);
                                    setTimeout(() => {
                                        setCurrentPage(1);
                                    }, 100);
                                }
                                }>
                                    <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <Table dataSource={dataSource}
                            scroll={{ y: '65vh', x: 'max-content' }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index}
                            onRow={(record) => {
                                return {
                                    onClick: e => {
                                        setSelectedId(record.id);
                                        SelectController(record);
                                    }
                                };
                            }}
                            rowClassName={(record) => {
                                return `rowCustomSelect ${(selectedId === record.id) ? 'rowCustomSelectActive' : ''}`
                            }}
                        />
                    </div>
                    <Pagination
                        current={currentPage}
                        size={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setCurrentPage(page) }}
                        onShowSizeChange={(page, size) => { setSize(size) }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <Button className="btn btn-color mr-2" onClick={() => props.GetData(dataSelect)}>Xong</Button>
                <Button className="btn btn-outline-secondary" onClick={() => props.onClose()}>Đóng</Button>
            </div>
        </React.Fragment>
    )

}