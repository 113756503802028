import React ,{ useState ,Fragment, useRef, forwardRef, useCallback} from "react";
import { Input, Table, Radio, List, Skeleton, Divider, Pagination } from 'antd';
import * as PromotionServices from '../../promotion/PromotionServices';
import * as CardServices from '../../../services/CardService';
import moment from 'moment';
import Notify, { NotifyStatus } from '../../../components/notify';
import { useEffect } from "react";
import HeaderModal from '../../../configureHIS/common/headerModal'
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';
import { LOYALTY_TYPE, FEATURE } from "../../../configureHIS/common/constants";
import {FeatureDeactive} from '../../../configure/featureConfig';
import * as Actions from "../../../libs/actions";

export default forwardRef((props, ref) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
    //biến cục bộ
    const showMoreText='...Xem thêm';
    const showLessText=' Rút gọn';

    const [isLoyalty, setIsLoyaty] = useState(false);
    const [loyaltyType, setLoyaltyType] = useState(props.loyaltyType);

    const [keyword, setKeyword] = useState(null);
    const [voucher, setVoucher] = useState(null);
    const [promotion, setPromotion] = useState([]);
    const [promotionId, setPromotionId] = useState(null);
    const [cardInfo, setCardInfo] = useState([]);
    
    const [lstPromotion, setLstPromotion] = useState([]);
    const [listSelectedServices, setListSelectedServices] = useState([]);
    const [patient, setPatient] = useState({});
    // const [data, setData] = useState([]);

    const notiRef = useRef(null);

    const optionsLoyalty = [
        // { label: 'CTKM', value: LOYALTY_TYPE.VOUCHER },
        // { label: 'Thẻ trả trước', value: LOYALTY_TYPE.CARD },
        // { label: 'Hạng thành viên', value: LOYALTY_TYPE.MEMBER },
    ];
    if (FeatureDeactive().indexOf(FEATURE.DEBITCARD) == -1) // phân hệ Thẻ trả trước
        optionsLoyalty.push({ label: 'Thẻ trả trước', value: LOYALTY_TYPE.CARD });
    if (FeatureDeactive().indexOf(FEATURE.CTKM) == -1) // phân hệ CTKM
        optionsLoyalty.push({ label: 'CTKM', value: LOYALTY_TYPE.VOUCHER });

    useEffect(() => {
        setPatient(props.patient);
        let propsListSelectedServices = props.listSelectedServices;
        setListSelectedServices(propsListSelectedServices);
        if (props.type === LOYALTY_TYPE.SERVICE && props.isView) {
            // Just only 1 voucher service applied
            let foundVoucherCode = propsListSelectedServices.find(x => x.voucher);
            foundVoucherCode ? setVoucher(foundVoucherCode.voucher) : setPromotion([]);
        }
        setLoyaltyType(props.loyaltyType)
    }, [props])

    useEffect(() => {
        setPromotion(props.selectedPromotion);
    }, [props.selectedPromotion])

    // useEffect(() => {
    //     if(promotion && promotion.length){
    //         setPromotionId(promotion.id);
    //         setVoucher(promotion.voucherCode);
    //     }
    // }, promotion)

    const getListVoucher = () => {
        if(keyword === null || keyword === ''){
            notiRef.current.Show("Mã voucher không được để trống", NotifyStatus.Warning);
            return;
        }
        let mabn = patient && patient.mabn ? patient.mabn : ''
        PromotionServices.getVouchers(keyword, mabn, moment().format("YYYY/MM/DD HH:mm:ss"), "").then(res => {
            if (res.isSuccess) {
                setLstPromotion(res.data);
                notiRef.current.Close();
                forceUpdate();
            }
            else {
                setLstPromotion([]);
                notiRef.current.Show(res.error.messageText, NotifyStatus.Warning)
            }
        })
    }

    const getListServiceVoucher = () => {
        if(keyword === null || keyword === ''){
            notiRef.current.Show("Mã voucher không được để trống", NotifyStatus.Warning);
            return;
        }
        PromotionServices.GetServiceVoucher(keyword,patient.mabn).then(res => {
            if (res.isSuccess) {
                setLstPromotion(res.data);
                notiRef.current.Close();
            }
            else {
                setLstPromotion([]);
                notiRef.current.Show(res.error.messageText, NotifyStatus.Warning)
            }
        }).finally(() => {
            // Actions.setLoading(false);
            forceUpdate();
        })
    }

    const getListServiceCard = () => {
        if(keyword === null || keyword === ''){
            notiRef.current.Show("Mã voucher không được để trống", NotifyStatus.Warning);
            return;
        }
        CardServices.GetServiceCard(keyword).then(res => {
            if (res.isSuccess) {
                setLstPromotion(res.data);
                notiRef.current.Close();
            }
            else {
                setLstPromotion([]);
                notiRef.current.Show(res.error.messageText, NotifyStatus.Warning)
            }
        }).finally(() => {
            // Actions.setLoading(false);
            forceUpdate();
        })
    }

    const CancelVoucher = (promo) => {
        let listToRemove = promo.criteriaProductIds && promo.discountProductIds && promo.criteriaProductIds.concat(promo.discountProductIds);
        setVoucher(null)
        setPromotionId(null)
        setPromotion([]);
        props.removeVoucherService && props.removeVoucherService(listToRemove);
        notiRef && notiRef.current && notiRef.current.Close();
    }

    const ApplyVoucher = (promo) => {
        Actions.setLoading(true);
        if (props.type === LOYALTY_TYPE.SERVICE && props.isView) {
            if (voucher && voucher !== promo.voucherCode) {
                notiRef.current.Show("Chỉ có thể áp dụng 1 (một) mã khuyến mại dịch vụ cho 1 (một) lần đăng ký", NotifyStatus.Warning);
                Actions.setLoading(false);
                return;
            }
            notiRef.current.Close();
            let listCritetia = promo.criteriaProductIds;
            let listDiscount = promo.discountProductIds;
            let bangGia = props.bangGia;
            PromotionServices.GetLoyaltyService(bangGia, promo.voucherCode, promo.promotionId, listCritetia, listDiscount).then(res => {
                if (res.isSuccess) {
                    Actions.setLoading(false);
                    setPromotion(promo);
                    setVoucher(promo.voucherCode);
                    props.onApply && props.onApply(res.data);
                    notiRef.current.Show("Áp dụng thành công", NotifyStatus.Success);
                }
                else {
                    Actions.setLoading(false);
                    notiRef.current.Show(res.err.msgString, NotifyStatus.Warning);
                }
            }).catch(() => {
                Actions.setLoading(false);
            })
        }
        else {
            let listSelected = listSelectedServices.map(x => x.mavp);
            let listcritetia = promo.criteriaProductIds;
            let listdiscount = promo.discountProductIds;
            const check = listcritetia ? listcritetia.every(r => listSelected.includes(r)) : true //Kiểm tra dịch vụ đăng kí có thỏa tất cả dịch vụ yêu cầu khum
            const checkDiscount = listdiscount ? listdiscount.some(r => listSelected.includes(r)) : true //Kiểm tra có dịch vụ tặng khum

            if (check && checkDiscount) {
                setPromotion(promo);
                setVoucher(promo.voucherCode);
                setPromotionId(promo.promotionId);
            } else {
                notiRef.current.Show("Dịch vụ đã chọn không đáp ứng được yêu cầu của chương trình hoặc chưa chọn dịch vụ khuyến mãi.", NotifyStatus.Warning)
            }
            Actions.setLoading(false);
        }
    }

    const ApplyCard = (info) => {
        // Tổng tiền của bill
        let totalBill = 0;
        listSelectedServices.map(x => totalBill += (x.dongia * x.soluong));
        //Tổng giảm của bill
        let totalDiscount = 0
        let listDiscountServices = listSelectedServices;
        if(info.isAllProductPreferential){
            listDiscountServices = listDiscountServices.filter(x => !info.excludeProductIds.includes(x.mavp));
        } else {
            listDiscountServices = listDiscountServices.filter(x => info.discountProductIds.includes(x.mavp));
        }
        listDiscountServices.map(x => totalDiscount += ( (info.discountValue/100) * x.dongia * x.soluong));
        // Tổng tặng của bill
        let totalFree = 0;
        let listFreeServices = listSelectedServices;
        if(info.freeProductIds && info.freeProductIds.length > 0){
            listFreeServices = listFreeServices.filter(x => info.freeProductIds.includes(x.mavp))
            listFreeServices.map(x => {totalFree += (x.dongia * x.soluong)})
        }
        
        let totalBillAfterDiscount = totalBill - totalDiscount - totalFree
        if(info.debitRemain > totalBillAfterDiscount) {
            setPromotion(info);
            setVoucher(info.cardCode);
            setPromotionId(info.accountId);
        } else {
            notiRef.current.Show("Số dư tài khoản không đủ để thực hiện giao dịch này.", NotifyStatus.Warning)
        }
    }
    
    const SubmitVoucher = () => {
        notiRef.current.Close();
        let data;
        // if(promotion.length > 0){
        //     data = {
        //         ...promotion,
        //         loyaltyType : loyaltyType
        //     }
        // }
        props.onSubmit && props.onSubmit(promotion);
        onCancel();
    }

    const onCancel = () => {
        setVoucher(null);
        setPromotionId(null);
        setLstPromotion([]);
        setKeyword(null);
        notiRef.current.Close();
        props.onCancel();
    }

    return (
        <Fragment>
            <HeaderModal title={'Áp dụng loyalty'} onClose={() => { onCancel() }} />
            <div className="row mb-2">
                <div className="col-12 d-flex justify-content-center">
                    <Radio.Group
                        options={optionsLoyalty}
                        onChange={(e) => {setLoyaltyType(e.target.value);}}
                        value={loyaltyType}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 d-flex justify-content-start">
                    <Input
                        value={keyword}
                        onChange={(e) => {
                            setKeyword(e.target.value)
                        }}
                        onPressEnter={() => {
							if(props.type == LOYALTY_TYPE.VOUCHER) {
                                getListVoucher()
                            } else if (loyaltyType === LOYALTY_TYPE.VOUCHER) {                                
                                getListServiceVoucher()
                            } else if (loyaltyType === LOYALTY_TYPE.CARD) {
                                getListServiceCard()
                            }
                            
                        }}
                        placeholder='Nhập mã CTKM, mã thẻ'
                    ></Input>
                </div>
            </div>
            <Notify ref={notiRef} />
            <div
                id="scrollableDiv"
                style={{
                    height: '77%',
                    overflow: 'auto',
                    padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                }}
                >
                    <List
                        pagination={{
                        onChange: (page) => {
                            },
                            pageSize: 3,
                        }}
                        itemLayout="vertical"
                        dataSource={lstPromotion}
                        renderItem={(item) => (
                            <List.Item>
                                <div>
                                    <div className="row">
                                        <div className="col-8 d-flex justify-content-start">
                                            {loyaltyType === LOYALTY_TYPE.VOUCHER && <label>{item.promotionName}</label>}
                                            {loyaltyType === LOYALTY_TYPE.CARD && <label>{item.cardTypeName}</label>}
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <label>Mức giảm: {item.isPercent ? item.discountValue + '%' : item.discountValue.toLocaleString('en-US') + 'VND'}</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8 d-flex justify-content-start">
                                            {loyaltyType === LOYALTY_TYPE.VOUCHER && <span className="font-weight-bold">{item.voucherCode}</span>}
                                            {loyaltyType === LOYALTY_TYPE.CARD && <span className="font-weight-bold">{item.accountName}</span>}
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            {loyaltyType === LOYALTY_TYPE.VOUCHER && <label>Số lượng còn lại : {item.quantity === 0 ? 'Không giới hạn' : item.quantity - item.usedNumber}</label>}
                                            {loyaltyType === LOYALTY_TYPE.CARD && <label>Số điện thoại : {item.phonenumber}</label>}
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        {item.criteriaProducts && <div className="col-12">
                                            Đăng kí dịch vụ:&nbsp;
                                            <div className="d-inline-block">
                                                <MitaDisplayLongText
                                                    content={item.criteriaProducts.map(x => x.ten).join(', ')}
                                                    maxLength={120} // mặc định 64
                                                    showMoreText={showMoreText}
                                                    showLessText={showLessText}
                                                />
                                            </div>
                                        </div>}
                                        {item.discountProducts && <div className="col-12">
                                            Khuyến mãi dịch vụ:&nbsp;
                                            <div className="d-inline-block">
                                                <MitaDisplayLongText
                                                    content={item.discountProducts.map(x => x.ten).join(', ')}
                                                    maxLength={120} // mặc định 64
                                                    showMoreText={showMoreText}
                                                    showLessText={showLessText}
                                                />
                                            </div>
                                        </div>}
                                        {item.excludeProducts && <div className="col-12">
                                            Loại trừ dịch vụ: 
                                            <div className="d-inline-block">
                                                <MitaDisplayLongText
                                                    content={item.excludeProducts.map(x => x.ten).join(', ')}
                                                    maxLength={120} // mặc định 64
                                                    showMoreText={showMoreText}
                                                    showLessText={showLessText}
                                                />
                                            </div>
                                        </div>}
                                        {item.freeProducts && <div className="col-12">
                                            Tặng dịch vụ: 
                                            <div className="d-inline-block">
                                                <MitaDisplayLongText
                                                    content={item.freeProducts.map(x => x.ten).join(', ')}
                                                    maxLength={120} // mặc định 64
                                                    showMoreText={showMoreText}
                                                    showLessText={showLessText}
                                                />
                                            </div>
                                        </div>}
                                        {item.remark && <div className="col-12">
                                            Ghi chú:&nbsp;
                                            <div className="d-inline-block">
                                            <MitaDisplayLongText
                                                content={item.remark}
                                                maxLength={30} // mặc định 64
                                                showMoreText={showMoreText}
                                                showLessText={showLessText}
                                            />
                                            </div>
                                        </div>}
                                    </div>
                                    {(!props.isView || item.discountProducts) && <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            <button className="btn btn-danger mr-2" onClick={() => { CancelVoucher(item) }}>
                                                Không áp dụng
                                            </button>
                                            <button className="btn btn-color"
                                                disabled={promotion && item.id === promotion.id}
                                                onClick={() => {
                                                    {loyaltyType === LOYALTY_TYPE.VOUCHER && ApplyVoucher(item)}
                                                    {loyaltyType === LOYALTY_TYPE.CARD && ApplyCard(item)}
                                            }}>{promotion && item.id == promotion.id ? "Đã áp dụng" : "Áp dụng"} </button>
                                        </div>
                                    </div> }
                                </div>
                            </List.Item>
                            )}
                        />
            </div>
            <div className='row py-2'>
                <div className="col-12 d-flex justify-content-center">
                {/* {
                    !props.isView && 
                        <button className="btn btn-color" onClick={() => { SubmitVoucher() }}>
                            <i className="fa fa-check mr-2" />Áp dụng
                        </button>
                } */}
                    
                    <button className="w-100 btn btn-success" onClick={() => { SubmitVoucher() }}>
                        Hoàn tất
                    </button>
                </div>
            </div>
        </Fragment>
    )
});
