import {Button, Form, Input, InputNumber, notification, Popconfirm, Select, Switch, Table, Tooltip, Divider, Space, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as chiDinhService from '../../../services/chiDinhService';
import * as commonService from '../../../services/commonService';
import * as pascService from '../../../services/pascService';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import { PlusOutlined } from '@ant-design/icons';
import suneditorViLang from '../../../libs/suneditor-vi-lang';
import HeaderModal from '../../../configureHIS/common/headerModal';

const { Option } = Select;
export default class add extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            typeName: '',
            CDHAName: '',
            lstTypeCDHA: [],
            typeCDHA: '',
            lstCDHA: [],
            CDHA: '',
            lstName: [],
            name: '',
            keyCDHA: '',
            keyType: '',
            keyKT: '',
            visible: false,
            detail: {},
            columnType: [],
            title: '',
            key: '',
            type: '',
            idCDHA: -1,
            idCDHAKT: -1,
            idCDHAType: -1,
            tmpName: '',
            tmpCode: '',
            templateId: 1,
            codeType: '',
            priceType: null,
            codeKT: '',
            template: '',
            ketluan: '',
            loadingHide: false,
            lstTemplateName: [],
            dongiaType: 0,
            formName: '',
            lstFormName:[],
            formid: 0,
            tgtk: 0,
        };
    }
    componentDidMount() {
        this.DanhSachLoaiCDHA();
        this.loadDataTemplateName();
    }
    DanhSachLoaiCDHA() {
        Actions.setLoading(true);
        chiDinhService.DanhSachLoaiChiDinh('', 3).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstCDHA: result.data,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', 'Tải dữ liệu bị lỗi');
        })
    }
    LoaiVPCDHA(loading = true) {
        Actions.setLoading(loading);
        pascService.LoaiVPCDHA(this.state.CDHA, this.state.keyType).then(result => {
            if (result.isSuccess) {
                const data = result.data.map(x => ({
                    'value': x.value,
                    'label': x.label,
                    'typeName': this.state.CDHAName,
                    'key': x.key,
                    'template': x.template,
                    'autorecord': x.autorecord,
                    'templateName': x.templateName,
                    'templateId': x.templateId,
                    'tgtk' : x.tgtk,
                    'ketluan': x.ketluan,
                }))
                this.setState({
                    lstTypeCDHA: data,
                    name: '',
                    data: [],
                    lstName: [],
                });
                if (result.data.length === 0) {
                    this.setState({
                        typeCDHA: '',
                    });
                }
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', 'Tải dữ liệu bị lỗi');
        })
    }

    DanhSachLoaiKyThuatXetNghiem() {
        Actions.setLoading(true);
        pascService.DanhSachLoaiKyThuatXetNghiem(this.state.typeCDHA).then(result => {
            if (result.isSuccess) {
                const data = result.data.map(x => ({
                    'value': x.value,
                    'label': x.label,
                    'typeName': this.state.typeName,
                    'key': x.key,
                }))
                this.setState({
                    lstName: data,
                });
            }
            if (result.data.length === 0) {
                this.setState({
                    name: '',
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', 'Tải dữ liệu bị lỗi');
        })
    }
    reset() {
        this.setState({
            visible: false,
            keyCDHA: '',
            keyType: '',
            codeType: '',
            priceType: null,
            dongiaType: 0,
        })
    }

    AddForm = (idvp) =>{
        const data={
            'idvp' : idvp,
            'ten' : this.state.formName,
        }
        pascService.addForm(data).then(result => {
            if (result.isSuccess) {
                    notification.success({ message: "Thao tác thành công" })
                    //this.DanhSachLoaiCDHA();
                    //this.reset();
                    this.getListFormPACS(idvp);
                    this.setState({formName : ''})

            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err);
        })
    }

    getListFormPACS = (idvp) =>{
        pascService.getListFormPACS(idvp).then(result => {
            if(result.isSuccess){
                this.setState({
                    lstFormName : result.data
                });
                if (result.data.length > 0) {
                    this.setState({
                        formid: result.data[0].id,
                        template: result.data[0].description,
                        ketluan: result.data[0].ketluan,
                    }, () => {
                        this.forceUpdate();
                    })
                }
            }    
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err);
        })
    }

    getFormPACSDetail = (formid) => {
        pascService.getFormPACSDetail(formid).then(result => {
            if(result.isSuccess){
                this.setState({
                    template : result.item.description,
                    ketluan : result.item.ketluan
                })
            }    
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err);
        })
    }

    updateThoigiantaikham(idvp) {
        var value = this.state.lstTypeCDHA.find(x => x.id === idvp) ? this.state.lstTypeCDHA.find(x => x.id === idvp).tgtk : this.state.tgtk;
        var tmp = this.state.lstTypeCDHA.find(x => x.value === idvp);
        const data = {
                'ten': tmp.label,
                'idvp': tmp.value,
                'tgtk': value,
                'ma': tmp.key,
                }
        pascService.updateThoigiantaikham(data).then(result => {
            if (result.isSuccess) {
                this.LoaiVPCDHA()
            }
            else {
                Actions.openMessageDialog('Lỗi', "Không thể cập nhật thông tin, vui lòng thử lại sau")
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    UpdateSubmit() {
        if (this.state.title === 'cdha') {
            const data = {
                'ten': this.state.tmpName,
                'id': this.state.idCDHA,
            }
            Actions.setLoading(true);
            pascService.InsertUpdateCDHA(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Thao tác thành công" })
                    //   this.DanhSachLoaiCDHA();
                    this.DanhSachLoaiCDHA();
                    this.reset();
                }
                else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err);
            })
        }
        else if (this.state.title === 'cdhatype') {
            if(Number(this.state.formid) === 0){
                notification.warning({message:"Vui lòng chọn template!"})
            } else {
                const data = {
                'ten': this.state.tmpName,
                'id': Number(this.state.idCDHAType),
                'ma': this.state.tmpCode,
                'template': this.state.template,
                'templateId': this.state.templateId,
                'formid' : Number(this.state.formid),
                'ketluan' : this.state.ketluan,
                }
                Actions.setLoading(true);
                pascService.InsertUpdateCDHAType(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: "Thao tác thành công" })
                        this.LoaiVPCDHA();
                        this.reset();
                    }
                    else {
                        Actions.openMessageDialog('Lỗi', result.err.msgString);
                    }
                }).catch(err => {
                    Actions.openMessageDialog('Lỗi', err);
                }).finally(() => {
                    this.setState({
                        template: null,
                        ketluan: null,
                    })
                    Actions.setLoading(false);
                })
            }
        }
        else if (this.state.title === 'cdhakt') {
            const data = {
                'ten': this.state.tmpName,
                'id': Number(this.state.idCDHAKT),
                'ma': this.state.tmpCode,
            }
            Actions.setLoading(true);
            pascService.InsertUpdateCDHAKT(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Thao tác thành công" })
                    this.DanhSachLoaiKyThuatXetNghiem();
                    this.reset();
                }
                else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err);
            })
        }
    }
    AddSubmit(title) {
        if (title === 'cdha') {
            const data = {
                'ten': this.state.keyCDHA,
                'id': this.state.idCDHA,
            }
            Actions.setLoading(true);
            pascService.InsertUpdateCDHA(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Thao tác thành công" })
                    this.DanhSachLoaiCDHA();
                }
                else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err);
            })
        }
        else if (title === 'cdhatype') {
            if (this.state.CDHA === undefined || this.state.CDHA === '') {
                notification.warning({ message: "Chưa chọn loại dịch vụ" });
                return;
            }
            
            const data = {
                'ten': this.state.keyType,
                'id': this.state.idCDHAType,
                'loai': this.state.CDHA.toString(),
                'ma': this.state.codeType,
                'dongia': Number(this.state.dongiaType),
            }
            Actions.setLoading(true);
            pascService.InsertUpdateCDHAType(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Thao tác thành công" })
                    this.LoaiVPCDHA();
                    this.reset();
                }
                else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err);
            })
        }
        else if (title === 'cdhakt') {
            const data = {
                'ten': this.state.keyKT,
                'id': this.state.idCDHAKT,
                'ma': this.state.codeKT,
                'idvp': this.state.typeCDHA,
            }
            Actions.setLoading(true);
            pascService.InsertUpdateCDHAKT(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Thao tác thành công" })
                    this.DanhSachLoaiKyThuatXetNghiem();
                }
                else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err);
            })
        }
    }
    SubmitDelete(title, id) {
        if (title === 'cdha') {
            Actions.setLoading(true);
            pascService.DeleteCDHA(id).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Thao tác thành công" })
                    this.DanhSachLoaiCDHA();
                }
                else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err);
            })
        }
        else if (title === 'cdhatype') {
            Actions.setLoading(true);
            pascService.DeleteCDHAType(id).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Thao tác thành công" })
                    this.LoaiVPCDHA();
                }
                else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err);
            })
        }
        else if (title === 'cdhakt') {
            Actions.setLoading(true);
            pascService.DeleteCDHAKT(id).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Thao tác thành công" })
                    this.DanhSachLoaiKyThuatXetNghiem();
                }
                else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err);
            })
        }
    }
    updateHide(idvp, check) {
        this.setState({ loadingHide: true })
        let status = false;
        if (check === true) {
            status = true;
        }
        pascService.HideAutoRecordCDHAKT(idvp, status).then(result => {
            let data = this.state.lstTypeCDHA;
            let item = data.find(x => x.value === idvp.toString());
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.autorecord
                    item.autorecord = !tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.autorecord;
                    item.autorecord = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstTypeCDHA: data,
                loadingHide: false,
            })
        }).catch(err => {
            let data = this.state.lstTypeCDHA;
            let item = data.find(x => x.value === idvp.toString());
            if (item !== undefined) {
                const newcheck = item.autorecord;
                item.autorecord = newcheck;
            }
            this.setState({ loadingHide: false, lstTypeCDHA: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    loadDataTemplateName() {
        Actions.setLoading(true);
        commonService.templateName().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstTemplateName: result.data,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', 'Tải dữ liệu bị lỗi');
        })
    }
    onCloseUpdate() {
        this.setState({
            visible: false,
            formName: null,
            formid: null,
            template: null,
            ketluan : null
        })
    }
    render() {
        const columnType = [
            {
                title: 'Loại dịch vụ',
                dataIndex: 'typeName',
                key: 'typeName',
            },
            {
                title: 'Mã',
                dataIndex: 'key',
                key: 'key',
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'label',
                key: 'label',
            },
            // {
            //     title: 'Loại Template',
            //     dataIndex: 'templateName',
            //     key: 'templateName',
            // },
            // {
            //     title: 'TG tái khám',
            //     dataIndex: 'tgtk',
            //     key: 'tgtk',
            //     align: 'center',
            //     width: 130,
            //     render: (row) => {
            //         return (
                        // <Tooltip title="Bấm Enter để lưu">
                        //     <InputNumber
                        //         min={0}
                        //         step={1}
                        //         onChange={(e) => {
                        //             this.setState({ tgtk : e})
                        //         }}
                        //         onKeyPress={(e) => {
                        //             e.stopPropagation(); 
                        //             if (e.code === "Enter") {
                        //                 // this.updateThoigiantaikham(row.value)
                        //             }
                        //             if (e.key === " " || isNaN(e.key) === true)
                        //                 e.preventDefault();
                        //         }}
                        //     />
                        //     &nbsp;<span style={{}}>ngày</span>
                        // </Tooltip>
            //         )
            //     }
            // },
            // {
            //     title: 'Thời gian tái khám',
            //     align: 'center',
            //     render: (row) => {
            //         return (
            //             <Tooltip title="Bấm Enter để lưu">
            //                 <InputNumber
            //                     value={row.tgtk}
            //                     min={0}
            //                     step={1}
            //                     onChange={(e) => {
            //                         if(e === '' || e === null){
            //                             this.setState({ tgtk : 0})
            //                         } else {
            //                             this.setState({ tgtk : e})
            //                         }
            //                     }}
            //                     onKeyPress={(e) => {
            //                         e.stopPropagation(); 
            //                         if (e.code === "Enter") {
            //                             this.updateThoigiantaikham(row.value)
            //                         }
            //                         if (e.key === "" || isNaN(e.key) === true)
            //                             e.preventDefault();
            //                     }}
            //                 />
            //                 &nbsp;<span style={{}}>ngày</span>
            //             </Tooltip>
            //         );
            //     },
            // },
            {
                title: 'Tự động quay video',
                align: 'center',
                render: (row) => {
                    return (
                        <Switch loading={this.state.loadingHide} checkedChildren="Có" unCheckedChildren="Không" checked={row.autorecord === true}
                            onChange={(e) => {
                                this.updateHide(row.value, e)
                            }}
                        />
                    );
                },
            },
            {
                width: 150,
                render: (row) => {
                    return (
                        <div className="row no-gutters">
                            <button className="btn btn-warning" style={{ marginRight: 5 }}
                                onClick={(e) => { e.stopPropagation(); 
                                    this.setState({
                                        visible: true, idCDHAType: row.value,
                                        templateName: row.templateName,
                                        tmpName: row.label,
                                        tmpCode: row.key, title: 'cdhatype' }, () => {
                                            this.getListFormPACS(row.key);
                                        }) 
                                }}>
                                <i className="fa fa-pencil-alt"></i></button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa không?"
                                onConfirm={(e) => { e.stopPropagation(); this.SubmitDelete('cdhatype', row.value) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" onClick={(e) => { e.stopPropagation(); }}>
                                    <i className="fa fa-trash-alt" ></i></button>
                            </Popconfirm>
                        </div>
                    );
                },
            }
        ]
        const columnCDHA = [
            {
                title: 'Tên',
                dataIndex: 'label',
                key: 'label',
            },
            {
                title: 'Action',
                render: (row) => {
                    return (
                        <React.Fragment>
                            <div className="row no-gutters">
                                <Form.Item>
                                    <button className="btn btn-warning" onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({ visible: true, idCDHA: row.value, tmpName: row.label, title: 'cdha' })
                                    }}><i className="fa fa-edit" style={{ marginRight: 5 }}></i>Cập nhật</button>
                                </Form.Item>
                                <Form.Item>
                                    <Popconfirm
                                        title="Bạn có chắc muốn xóa không?"
                                        onConfirm={(e) => { e.stopPropagation(); this.SubmitDelete('cdha', row.value) }}
                                        okText="Đồng ý" cancelText="Hủy">
                                        <button className="btn btn-danger" onClick={(e) => { e.stopPropagation(); }}><i className="fa fa-eraser" style={{ marginRight: 5 }}></i>Xóa</button>
                                    </Popconfirm>
                                </Form.Item>
                            </div>
                        </React.Fragment>
                    );
                },
            }
        ]
        const columnKT = [
            {
                title: 'Loại',
                dataIndex: 'typeName',
                key: 'typeName',
            },
            {
                title: 'Mã',
                dataIndex: 'key',
                key: 'key',
            },
            {
                title: 'Tên',
                dataIndex: 'label',
                key: 'label',
            },
            {
                width: 150,
                render: (row) => {
                    return (
                        <div>
                            <button className="btn btn-warning" onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ visible: true, idCDHAKT: row.value, tmpName: row.label, tmpCode: row.key, title: 'cdhakt' })
                            }}>
                                <i className="fa fa-edit" style={{ marginRight: 5 }}></i></button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa không?"
                                onConfirm={(e) => { e.stopPropagation(); this.SubmitDelete('cdhakt', row.value) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" onClick={(e) => { e.stopPropagation(); }}>
                                    <i className="fa fa-eraser" style={{ marginRight: 5 }}></i></button>
                            </Popconfirm>
                        </div>
                    );
                },
            },
        ]
        return (
            <React.Fragment>
                <div className='containerChilderWrapper'>
                    <HeaderModal title="Cấu hình danh mục dịch vụ chẩn đoán hình ảnh" />
                    <div className="row mt-2 pr-0 patient-payment" style={{ height: 'calc(100% - 65px)' }}>
                        <div className="col-md-5">
                            <div className="row no-gutters" style={{ justifyContent: 'space-between' }}>
                                <Form.Item>
                                    <Input placeholder="Loại dịch vụ"
                                        onChange={(e) => this.setState({ keyCDHA: e.target.value, idCDHA: -1 })} value={this.state.keyCDHA} />
                                </Form.Item>
                                <Form.Item>
                                    <Popconfirm
                                        title="Bạn có chắc muốn thêm không?"
                                        onConfirm={() => { this.AddSubmit('cdha') }}
                                        okText="Đồng ý" cancelText="Hủy">
                                        <button className="btn btn-success">
                                            <i className="fa fa-plus mr-1"></i>Thêm</button>
                                    </Popconfirm>
                                </Form.Item>
                            </div>
                            <div className="table-responsive" style={{ paddingTop: 10 }}>
                                <Table dataSource={this.state.lstCDHA} columns={columnCDHA}
                                    pagination={false}
                                    scroll={{ y: 0 }}
                                    rowKey={(e, index) => index}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: e => { this.setState({ CDHA: record.value, CDHAName: record.label }, () => this.LoaiVPCDHA()) },
                                        };
                                    }}
                                    rowClassName={(record, rowIndex) => {
                                        return "rowCustom"
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-7" style={{ height: '100%' }}>
                            <div className="row no-gutters" style={{ display: 'flex' }}>
                                <div className="col-md-5">
                                    <Form.Item>
                                        <Input placeholder="Nhập tên hoặc mã và bấm enter để tìm" onChange={(e) => this.setState({ keyType: e.target.value, idCDHAType: -1 })} value={this.state.keyType}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") this.LoaiVPCDHA(false);
                                            }} />
                                        {this.validator.message("Tên dịch vụ", this.state.keyType, "required")}
                                    </Form.Item>
                                </div>
                                <div className="col-md-3">
                                    <Form.Item>
                                        <Input placeholder="Mã dịch vụ" onChange={(e) => this.setState({ codeType: e.target.value, idCDHAType: -1 })} value={this.state.codeType} />
                                        {this.validator.message("Mã dịch vụ", this.state.codeType, "required")}
                                    </Form.Item>
                                </div>
                                <div className="col-md-2">
                                    <Form.Item>
                                        <Tooltip title="Đơn giá">
                                            <InputNumber placeholder="Đơn giá" value={this.state.dongiaType} min={0} maxLength={9} step={5000} style={{ width: "100%" }}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={(e) => {
                                                    this.setState({ dongiaType: e, idCDHAType: -1 })
                                                }} />
                                        </Tooltip>
                                        {this.validator.message("Đơn giá", this.state.dongiaType, "required")}
                                    </Form.Item>
                                </div>
                                <div className="col-md-2">
                                    <Form.Item>
                                        <Popconfirm
                                            title="Bạn có chắc muốn thêm không?"
                                            onConfirm={() => { this.AddSubmit('cdhatype') }}
                                            okText="Đồng ý" cancelText="Hủy">
                                            <button className="btn btn-success">
                                                <i className="fa fa-plus mr-1"></i>Thêm</button>
                                        </Popconfirm>
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ paddingTop: 10, height: 'calc(100% - 60px' }}>
                                <Table dataSource={this.state.lstTypeCDHA} scroll={{ y: 0 }}
                                    columns={columnType} rowKey={(e, index) => index}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 1, padding: '10px', display: 'none', height: '100%' }}>
                        <h4 style={{ textAlign: 'center' }} >Kỹ thuật</h4>
                        <div style={{ display: "flex", justifyContent: 'center', marginBottom: '10px' }}>
                            <Form.Item label="Mã kỹ thuật:">
                                <Input style={{ width: "200px" }} onChange={(e) => this.setState({ codeKT: e.target.value })} value={this.state.codeKT} />
                            </Form.Item>
                            <Form.Item label="Tên kỹ thuật:">
                                <Input style={{ width: "200px" }} onChange={(e) => this.setState({ keyKT: e.target.value })} value={this.state.keyKT} />
                            </Form.Item>
                            <Form.Item>
                                <Popconfirm
                                    title="Bạn có chắc muốn thêm không?"
                                    onConfirm={() => { this.AddSubmit('cdhakt') }}
                                    okText="Đồng ý" cancelText="Hủy">
                                    <Button type="primary" style={{ marginLeft: 50 }} >Thêm</Button>
                                </Popconfirm>
                            </Form.Item>
                        </div>
                        <div style={{ paddingTop: 10, height: '100%' }}>
                            <Table dataSource={this.state.lstName} scroll={{ y: 0 }}
                                columns={columnKT} rowKey={(e, index) => index} />
                        </div>
                    </div>
                </div>
                <Modal maskClosable={false}
                    width={900}
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    destroyOnClose={true}
                    >
                    {this.state.title === 'cdha' &&
                        <div style={{ justifyContent: "center", marginTop: '30px', display: "flex" }}>
                            <Form.Item label="Tên:">
                                <Input style={{ width: "200px" }} onChange={(e) => this.setState({ tmpName: e.target.value })} value={this.state.tmpName} />
                            </Form.Item>
                        </div>
                    }
                    {this.state.title === 'cdhatype' &&
                        <div>
                            <HeaderModal title="Cập nhật loại dịch vụ chẩn đoán hình ảnh" onClose={() => { this.onCloseUpdate(); }} />
                            <div style={{ justifyContent: "center", marginTop: '30px', display: "flex" }}>
                                <Form.Item label="Mã:">
                                    <Input style={{ width: "100px" }} onChange={(e) => this.setState({ tmpCode: e.target.value })} value={this.state.tmpCode} />
                                </Form.Item>
                                <Form.Item label="Tên:">
                                    <Input style={{ width: "200px" }} onChange={(e) => this.setState({ tmpName: e.target.value })} value={this.state.tmpName} />
                                </Form.Item>
                                <Form.Item label="Loại template:" style={{ display : 'none'}}>
                                    <Select style={{ width: '120px' }} value={this.state.templateId}
                                        onChange={(e) => { this.setState({ templateId: e }) }}
                                    // filterOption={(input, option) =>
                                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    // }
                                    >
                                        {this.state.lstTemplateName.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Loại template:" >
                                    <Select
                                        style={{ width: "250px" }}
                                        value={this.state.formid}
                                        onChange={(e) => { this.setState({ formid: e }); this.getFormPACSDetail(e) }}
                                        dropdownRender={menu => (
                                            <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                <Input placeholder="Nhập tên template" value={this.state.formName} onChange={(e) => this.setState({formName : e.target.value})} />
                                                <Typography.Link onClick={() => this.AddForm(this.state.tmpCode)}  style={{ whiteSpace: 'nowrap' }}>
                                                    <button className='btn btn-color'><i className='fas fa-plus mr-1' />Thêm</button>
                                                </Typography.Link>
                                            </Space>
                                            </>
                                        )}
                                        >
                                        {this.state.lstFormName.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.ten}</Option>
                                            )
                                        })}
                                        </Select>
                                </Form.Item>
                            </div>
                            <div className="text-center mt-2"><strong>Template (Mẫu in)</strong></div>
                            <SunEditor
                                onChange={(e) => this.setState({ template: e })}
                                onBlur={(e, editorContents) => { this.setState({ template: editorContents }) }}
                                lang={suneditorViLang}
                                setDefaultStyle="font-size: 13px;paragraphStyles : normal;font-family : Arial;"
                                setContents={this.state.template}
                                setOptions={{
                                    height: 400,
                                    buttonList: [
                                        ['undo', 'redo'], ['font', 'fontSize', 'formatBlock'],
                                        ['bold', 'underline', 'italic', 'subscript', 'superscript', 'strike'],
                                        ['removeFormat'],
                                        ['fontColor', 'hiliteColor'],
                                        ['outdent', 'indent'],
                                        ['align', 'lineHeight', 'horizontalRule', 'list', 'table'],
                                        ['link', 'image', 'video'],
                                        ['fullScreen', 'showBlocks', 'codeView'],
                                        ['preview', 'print'],
                                    ]
                                }}
                                height='100%'
                            />
                            <div className="text-center mt-2"><strong>Template (Kết luận)</strong></div>
                            {/* <Input.TextArea
                                autoSize={{ minRows: 1, }}
                                value={this.state.ketluan}
                                placeholder="Nhập kết luận"
                                onChange={(e) => this.setState({ ketluan : e.target.value })}
                            ></Input.TextArea> */}
                            
                            <SunEditor
                                lang={suneditorViLang}
                                onBlur={(e, value) => {
                                    this.setState({
                                        ketluan: value
                                    });
                                }}
                                onChange={(e) => this.setState({ ketluan : e })}
                                setDefaultStyle="font-size: 13px; paragraphStyles: normal; font-family: Arial;"
                                setContents={this.state.ketluan}
                                setOptions={{
                                    height: 300,
                                    buttonList: [
                                        ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                        ['bold', 'underline', 'italic', 'subscript', 'superscript', 'removeFormat'],
                                        ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'lineHeight', 'horizontalRule', 'list', 'table', 'link'],
                                    ]
                                }}
                                height='100%'
                            />
                        </div>
                    }
                    {this.state.title === 'cdhakt' &&
                        <div style={{ justifyContent: "center", marginTop: '30px', display: "flex" }}>
                            <Form.Item label="Mã:">
                                <Input style={{ width: "100px" }} onChange={(e) => this.setState({ tmpCode: e.target.value })} value={this.state.tmpCode} />
                            </Form.Item>
                            <Form.Item label="Tên:">
                                <Input style={{ width: "200px" }} onChange={(e) => this.setState({ tmpName: e.target.value })} value={this.state.tmpName} />
                            </Form.Item>
                        </div>
                    }

                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                        <Form.Item>
                            <button className="btn btn-success"
                                hidden={this.state.hideUpdate}
                                onClick={() => { this.UpdateSubmit() }}><i className="fa fa-save" style={{ marginRight: 5 }} />Lưu</button>
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-danger"
                                onClick={() => {
                                    this.onCloseUpdate();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng
                            </button>
                        </Form.Item>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
