import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Input, InputNumber, Button, Table, notification, Drawer, Popconfirm, Pagination, Dropdown, Menu, Select, DatePicker } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../../../configure/systemConfig';
import * as Actions from '../../../../libs/actions';
import * as AuthServices from '../../../../services/authServices';
import * as CoporationServices from "../../../../services/CorporationServices";
import * as VPService from '../../../../configureHIS/update_vp/services/VPService';
import Notify, { NotifyStatus } from '../../../../components/notify';
import Summary from './summary';
import List from './list';
import AverageRevenueChart from './chart';
import SiteFilter from "../../../../configureHIS/common/siteFilter";

export default forwardRef((props, ref) => {
  //#region biến cục bộ
  const { Option } = Select;
  const now = new Date();
  const viewTypeEnum = {
    chart: "chart",
    list: "list"
  };
  //#endregion
  const summaryRef = useRef();
  const listRef = useRef();
  const chartRef = useRef();
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  //search/filter
  const [siteIds, setSiteIds] = useState([]);
  const [coporationId, setCoporationId] = useState();
  const [paymentTypeIds, setPaymentTypeIds] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment(now).add(-29,'days'));//(new moment((now).setMonth(now.getMonth() - 1)));
  const [dateTo, setDateTo] = useState(new moment());
  //other
  const [listSites, setListSites] = useState([]);
  const [listCoporations, setListCoporations] = useState([]);
  const [listPaymentTypes, setListPaymentTypes] = useState([]);
  const [viewType, setViewType] = useState(viewTypeEnum.chart);
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    loadChartOrList();
  }, [viewType]);
  
  useEffect(() => {
    console.clear();
    getListSites();
    getListCoporations();
    getListPaymentTypes();
    onClickStatisticHandle();
    firstRender.current = false;
  }, []);
  //#endregion 
  //#region get list data cho bộ lọc
  const getListSites = () => {
    AuthServices.getAvailableSites().then(result => {
      if (result.isSuccess) {
        setListSites(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Actions.setLoading(false);
      forceUpdate();
    })
  }
  const getListCoporations = () => {
    CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
      if (result.isSuccess) {
        setListCoporations(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }
  const getListPaymentTypes = () => {
    VPService.GetDanhSachLoaiThanhToan().then(result => {
      if (result.isSuccess) {
        setListPaymentTypes(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }
  //#endregion 
  const loadChartOrList = () => {
    if (viewType == viewTypeEnum.chart)
      chartRef.current.loadData();
    else listRef.current.loadData();
  }
  const validate = () => {
    let listErr = [];
    if (!dateFrom) listErr.push('Phải nhập ngày lập phiếu');
    if (dateFrom > dateTo) listErr.push('Ngày lập phiếu từ phải nhỏ hơn ngày lập phiếu đến');
    return listErr;
  }
  const onClickStatisticHandle = () => {
    let listErr = validate();
    if (listErr.length > 0) {
      let err = listErr.join('\n');
      notiRef.current.Show(err, NotifyStatus.Error);
      return;
    }
    summaryRef.current.loadData();
    loadChartOrList();
    notiRef && notiRef.current.Close();
  }
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Thống kê doanh thu trung bình đơn khách hàng</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body overflow-auto">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">{/* Chi nhánh */}
                <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
              </div>
              <div className="row mt-2">{/* Nguồn khách hàng | Ngày lập phiếu | Hình thức thanh toán */}
                  <div className='col-md-4 d-flex flex-column'>{/* Nguồn khách hàng */}
                    <div>
                      <label>Nguồn khách hàng</label>
                    </div>
                    <Select
                      allowClear
                      className="w-100"
                      placeholder="Chọn nguồn khách hàng"
                      maxTagCount= 'responsive'
                      value={ coporationId }
                      onChange={(value) => {setCoporationId(value);}}
                    >
                    {(listCoporations ?? []).map((record, index) => {
                      return (
                        <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                      )
                    })}
                    </Select>
                  </div>
                  <div className='col-md-4 d-flex flex-column'>{/* Ngày lập phiếu */}
                    <div>
                      <label>Ngày lập phiếu</label>
                    </div>
                    <div className="d-flex">
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Từ ngày"}
                        value={dateFrom}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateFrom(e ? moment(e) : null);
                        }}
                      />
                      <div className="m-auto">&#160;-&#160;</div>
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Đến ngày"}
                        value={dateTo}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateTo(e ? moment(e) : null);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 d-flex flex-column'>{/* Hình thức thanh toán */}
                    <div>
                      <label>Hình thức thanh toán</label>
                    </div>
                    <Select
                      mode="multiple"
                      allowClear
                      className="w-100"
                      placeholder="Chọn hình thức thanh toán"
                      maxTagCount= 'responsive'
                      value={ paymentTypeIds }
                      onChange={(value) => {setPaymentTypeIds(value);}}
                    >
                    {(listPaymentTypes ?? []).map((record, index) => {
                      return (
                        <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                      )
                    })}
                    </Select>
                  </div>
              </div>
              <div className="row mt-2">{/* button Thống kê */}
                  <div className='col-md-12 d-flex justify-content-end'>
                    <label className="my-auto">Hiển thị: </label>
                    <Select
                      // placeholder="Chọn hình thức thanh toán"
                      className="ml-2"
                      style={{ minWidth:"130px" }}
                      maxTagCount= 'responsive'
                      value={ viewType }
                      onChange={(value) => {setViewType(value);}}
                    >
                      <Option key="chart" value="chart"><i className='far fa-chart-bar mr-2'></i> Biểu đồ</Option>
                      <Option key="list" value="list"><i className='fas fa-list mr-2'></i> Danh sách</Option>
                    </Select>
                    <button 
                      className='btn btn-color ml-2' 
                      onClick={() => {
                        onClickStatisticHandle();
                      }}
                    >
                      <i className='far fa-chart-bar mr-2'></i>
                      Thống kê
                    </button>
                  </div>
              </div>
            </div>
            <div className="row mt-2">{/* Summary & [Chart | List] */}
              <div className='col-md-12'>
                <Summary ref={summaryRef}
                  siteIds={siteIds}
                  coporationIds={coporationId ? [coporationId] : []}
                  paymentTypeIds={paymentTypeIds}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                ></Summary>
              </div>
            </div>
            <div className="row mt-2">{/* Dashboard & [Chart | List] */}
              <div key="chart" className='col-md-12' hidden={viewType != viewTypeEnum.chart}>
                <AverageRevenueChart ref={chartRef}
                  siteIds={siteIds}
                  coporationIds={coporationId ? [coporationId] : []}
                  paymentTypeIds={paymentTypeIds}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                ></AverageRevenueChart>
              </div>
              <div key="list" className='col-md-12' hidden={viewType != viewTypeEnum.list}>
                <List ref={listRef}
                  siteIds={siteIds}
                  coporationIds={coporationId ? [coporationId] : []}
                  paymentTypeIds={paymentTypeIds}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                ></List>
              </div>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})