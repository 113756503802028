import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Modal, notification, Popconfirm, Table, Pagination, Switch } from 'antd';
import React, { Component } from 'react';
// import { listData } from '../../commons/menuData';
import * as ManagegerService from '../../services/ManageService';
import * as UsersService from '../../services/usersService';
import TreeRenders from './treeRender';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as Actions from '../../libs/actions';
import * as ConfigService from '../../services/configService';
import * as RoleService from '../../services/roleService';
import { connect } from 'react-redux';

let task = null;
class nhom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listGroup: [],
            selectedID: 0,
            selectedName: '',
            listPermission: null,
            editGroupid: 0,
            editgroupName: '',
            createNew: false,
            key: '',
            totalRecords: 0,
            currentPage: 1,
            currentSize: 10,
            groupid: 0,
            listUserOfGroup: [],
            totalUserOfGroup: 0,
            curPage: 0,
            curSize: 10,
            kwd: "",
            loadingSwitch: false,
            // listMenu: JSON.parse(JSON.stringify(listData)),
            listMenu: JSON.parse(JSON.stringify(this.props.menuData)),
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }

    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }

    componentWillMount() {
        this.setPermission();
    }
    setPermission() {
        let listPermission = [];
        for (let i = 0; i < this.state.listMenu.length; i++) {
            if (this.state.listMenu[i].code !== 'controller')
                listPermission.push(this.checkNode(this.state.listMenu[i]))
        }
        this.setState({ listPermission })
    }

    renderSmallMenu() {
        ConfigService.GetListSubMenu(true).then(result => {
            if (result.isSuccess) {
                const smallMenu = result.data;
                const newListData = this.state.listMenu.map(x=>({...x}));
                for (let index = 0; index < newListData.length; index++) {
                    const row = newListData[index];
                    let data = smallMenu.filter(x => x.parent === row.code);
                    if (data.length > 0) {
                        if (row.nodes && row.nodes.length > 0) {
                            row.nodes.concat(data);
                        }
                        else {
                            row.nodes = data;
                        }
                    }
                    if (row.nodes && row.nodes.length > 0) {
                        for (let indexChild = 0; indexChild < row.nodes.length; indexChild++) {
                            const child = row.nodes[indexChild];
                            data = smallMenu.filter(x => x.parent === child.code);
                            if (data.length > 0) {
                                if (child.nodes && child.nodes.length > 0) {
                                    child.nodes.concat(data);
                                }
                                else {
                                    child.nodes = data;
                                }
                            }
                            
                            if (child.nodes && child.nodes.length > 0) {
                                for (let indexSub = 0; indexSub < child.nodes.length; indexSub++) {
                                    const sub = child.nodes[indexSub];
                                    data = smallMenu.filter(x => x.parent === sub.code);
                                    if (data.length > 0) {
                                        if (sub.nodes && sub.nodes.length > 0) {
                                            sub.nodes.concat(data);
                                            sub.nodes.forEach(e => {
                                                const i = smallMenu.findIndex(x => x.code === e.code)
                                                if (i >= 0)
                                                    e.label = smallMenu[i].label
                                            });
                                        }
                                        else {
                                            sub.nodes = data;
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
                this.setState({ listMenu: JSON.parse(JSON.stringify(newListData)) }, () => {
                    this.setPermission();
                })
            }
        })
    }

    updateCheckSwitch(row, check) {
        this.setState({ loadingSwitch: true });
        UsersService.UpdateGroupUserMultisite(row.value, check).then(result => {
            let data = this.state.listGroup;
            let item = data.find(x => x.id === row.value);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const newcheck = item.multisite;
                    item.multisite = !newcheck;
                }
                notification.success({ message: 'Cập nhật thành công!' });
                this.GetListGroupUser();
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.multisite;
                    item.multisite = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                listGroup: data.map(x => ({ ...x })),
                loadingSwitch: false,
            })
        }).catch(err => {
            let data = this.state.listGroup;
            let item = data.find(x => x.id === row.value);
            if (item !== undefined) {
                const newcheck = item.multisite;
                item.multisite = newcheck;
            }
            this.setState({ loadingSwitch: false, listGroup: data.map(x => ({ ...x })) })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    checkNode(node) {
        let temp = {
            title: node.label,
            key: node.code,
            children: [
            ]
        }
        if (node.nodes !== undefined)
            temp.children = node.nodes.map((data, index) => {
                return this.checkNode(data)
            })
        return temp
    }

    DeleteGroup(id) {
        if (id == 18) {
            notification.warning({ message: "Không thể xóa nhóm mặc định" })
        } else {
            UsersService.DeleteGroup(id).then((res) => {
                if (res.isSuccess === true) {
                    notification.success({ message: 'Lưu thành công!' });
                    this.GetListGroupUser();
                } else {
                    notification.warning({ message: "Lưu Thất bại" });
                }
            })
        }
    }
    summit() {
        if (this.state.editGroupid === 0) {
            this.createGroup();
        } else {
            this.updateGroup();
        }
    }
    createGroup() {
        if (this.validator.allValid()) {
            UsersService.CreateGroup({ id: this.state.editGroupid, name: this.state.editgroupName }).then((res) => {
                if (res.isSuccess === true) {
                    notification.success({ message: 'Lưu thành công!' });
                    this.GetListGroupUser();
                    this.validator.hideMessages();
                } else {
                    notification.warning("Tạo nhóm Thất bại");
                }
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    updateGroup() {
        if (this.validator.allValid()) {
            UsersService.UpdateGroup({ id: this.state.editGroupid, name: this.state.editgroupName }).then((res) => {
                if (res.isSuccess === true) {
                    notification.success({ message: 'Lưu thành công!' });
                    this.GetListGroupUser();
                    this.validator.hideMessages();
                } else {
                    notification.warning({ message: "Lưu Thất bại" });
                }
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    UpdateFeatures() {
        if (this.state.selectedID === 0) {
            notification.error({ message: 'Chưa chọn nhóm người dùng!' });
            return;
        }
        let data = this.tree.getSelected();
        let finalData = [];
        for (let item of data) {
            finalData.push(item);

        }
        var uniqueArray = finalData.filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
        })

        UsersService.UpdateFeatures(this.state.selectedID, uniqueArray.toString()).then((res) => {
            if (res.isSuccess === true) {
                notification.success({ message: 'Lưu thành công!' });
                //    this.GetListGroupUser();
            } else {
                notification.warning({ message: "Lưu Thất bại" });
            }
        })
    }

    getFeature(id) {
        UsersService.getFeature(id).then((res) => {

            if (res.isSuccess === true) {
                if (res.item === "") {
                    this.tree.setelected([])
                } else {
                    let split = res.item.split(",");
                    this.tree.setelected(split)
                }
            } else {

            }
        })
    }


    componentDidMount() {
        this.GetListGroupUser();
        this.renderSmallMenu();
    }

    GetListGroupUser(page = this.state.currentPage, size = this.state.currentSize) {
        ManagegerService.GetListGroupUser(this.state.key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({ listGroup: result.data, totalRecords: result.totalRecords });
            }
            this.setState({
                editName: '',
                selectedID: 0,
                createmodal: false,
                editGroupid: 0,
                editgroupName: ''
            })
            if (this.tree)
                this.tree.setelected([])
        }).catch(err => {
            notification.warning({ message: 'Tải danh sách nhóm bị lỗi' })
        })
    }
    GetListUsersOfGroup(groupid, key = this.state.kwd, page = this.state.curPage, size = this.state.curSize) {
        ManagegerService.GetListUsersOfGroup(groupid, key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({ listUserOfGroup: result.data, totalUserOfGroup: result.totalRecords });
            }
        }).catch(err => {
            notification.warning({ message: 'Lỗi tải danh sách người dùng của nhóm' })
        })
    }
    DeleteUserOfGroup(user, group = this.state.groupid) {
        UsersService.DeleteUserOfGroup(user, group).then((res) => {
            if (res.isSuccess === true) {
                notification.success({ message: 'Đã xóa thành công!' });
                this.GetListUsersOfGroup(this.state.groupid);
            } else {
                notification.warning({ message: res.err.msgString });
            }
        })
    }
    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'enterNumber',
                key: 'enterNumber',
                width: 50,
                render: (text, record, index) => ((dataSource.indexOf(record) + 1) + ((this.state.currentPage - 1) * 10)),
            },
            {
                title: 'Nhóm',
                dataIndex: 'label',
                key: 'label',
            },
           
            {
                title: '',
                render: (row) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-warning" onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ editGroupid: row.value, editgroupName: row.label },
                                    () => this.setState({ createmodal: true }));
                            }} title="Cập nhật nhóm">
                                <i className="fa fa-edit"></i></button>
                            {
                                (row.value !== 18) &&
                                <Popconfirm onClick={(e => { e.stopPropagation(); })} title="Bạn có chắc muốn xóa nhóm này không?"
                                    onConfirm={() => this.DeleteGroup(row.value)}
                                    okText="Đồng ý"
                                    cancelText="Hủy">
                                    <button className="btn btn-danger" style={{ marginLeft: 10 }} title="Xóa nhóm người dùng">
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </Popconfirm>
                            }
                            <button className="btn btn-success" style={{ marginLeft: 10 }} onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ groupName: row.label, groupid: row.value },
                                    () => this.setState({ visibleListUsers: true }), this.GetListUsersOfGroup(row.value));
                            }} title={`Xem người dùng của nhóm ${row.label}`}>
                                <i className="fa fa-users"></i></button>
                        </React.Fragment>
                    )
                }
            }
        ]

        let dataSource = this.state.listGroup.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.id
            return o;
        })

        const listUserCols = [
            {
                title: 'Tài khoản',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: 'Họ tên',
                dataIndex: 'name',
                key: 'name',
            },
            {
                width: 160,
                render: (row) => {
                    return (
                        <Popconfirm onClick={(e => { e.stopPropagation(); })} title="Bạn có chắc muốn xóa người dùng khỏi nhóm?"
                            onConfirm={() => this.DeleteUserOfGroup(row.id)}
                            okText="Đồng ý"
                            cancelText="Hủy">
                            <button className="btn btn-danger" title="Xóa tư cách người dùng trong nhóm">
                                <i className="fa fa-trash" style={{ marginRight: 5 }} />Xóa khỏi nhóm
                            </button>
                        </Popconfirm>
                    )
                }
            }
        ]
        return (
            <React.Fragment>
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>

                    <div className="warrperStyleGroup" style={{ marginRight: 20, height: '100%', width: '50%' }}>
                        <div className="grouplabel">
                            <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Nhóm</h4>
                        </div>
                        <div className="mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <input className="form-control" placeholder="Tìm kiếm nhóm" style={{ width: "60%" }}
                                onChange={(e) => {
                                    this.setState({ key: e.target.value, currentPage: 1 });
                                    task = setTimeout(() => { this.GetListGroupUser() }, 200)
                                }} />
                            <button className="btn btn-success ml-auto"
                                onClick={() => { this.setState({ createmodal: true, createNew: true }) }}
                                icon={<PlusOutlined />} >Tạo mới</button>
                        </div>
                        <div style={{ height: 'calc(100% - 70px)', marginTop: 10 }}>
                            <Table dataSource={dataSource} rowKey={(e, index) => index} columns={columns}
                                scroll={{ x: 'max-content', y: 0 }} pagination={false}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: e => {
                                            this.setState({
                                                selectedID: record.value,
                                                selectedName: record.label
                                            });
                                            this.getFeature(record.value)
                                        }
                                    };
                                }}
                                rowClassName={(record, rowIndex) => {
                                    if (record.value === this.state.selectedID) {
                                        return "btn-color"
                                    }}}
                            ></Table>
                            <Pagination
                                current={this.state.currentPage}
                                size={this.state.currentSize}
                                style={{ textAlignLast: "center", marginTop: "10px" }}
                                total={this.state.totalRecords}
                                showTotal={total => `Tổng: ${total}`}
                                onChange={(page, size) => { this.setState({ currentPage: page, currentSize: size }, () => this.GetListGroupUser(page, size)) }}
                                onShowSizeChange={(page, size) => this.setState({ currentPage: page, currentSize: size }, () => this.GetListGroupUser(page, size))}
                                showSizeChanger={true}
                            />
                        </div>
                    </div>

                    <div className="warrperStyleGroup" style={{ height: '100%', flex: 1, width: '49%', position: 'relative' }}>
                        <div style={{position:'absolute', top:12, right:0 , zIndex:10}}>
                            <button className="btn btn-color" 
                                onClick={() => { this.UpdateFeatures() }}>
                                <i className="fa fa-save" style={{ marginRight: 5 }}></i>Lưu</button>
                        </div>
                        <div className="grouplabel">
                            <h4 className="text-uppercase">{this.state.selectedName ? `Phân quyền của nhóm ${this.state.selectedName}` : "Màn hình"}</h4>
                        </div>
                        {this.state.selectedID != null && this.state.listPermission != null &&
                            <div className="padding-table  h-100" style={{ backgroundColor: '#fff' }}>
                                <div style={{ overflow: 'auto', height: '100%' }}>
                                    <TreeRenders ref={(c) => this.tree = c} style={{ width: '100%', height: '100%', padding: '20px' }}
                                        data={this.state.listPermission} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Modal maskClosable={false} style={{ flex: 1 }}
                    width={500}
                    visible={this.state.createmodal}
                    header={
                        <h1>CẬP NHẬT</h1>
                    }
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>{this.state.createNew ? "Tạo nhóm người dùng" : "Cập nhật nhóm người dùng"}</h3>
                    </div>
                    <div>
                        <div className="row no-gutters" style={{ display: 'flex' }}>
                            <div className="col-auto" style={{ width: '100%' }}>
                                <Form.Item label="Tên nhóm:">
                                    <Input
                                        placeholder="Nhập tên nhóm"
                                        value={this.state.editgroupName}
                                        onChange={(e) => {
                                            this.setState({ editgroupName: e.target.value })
                                        }} />
                                    {this.validator.message("Tên nhóm", this.state.editgroupName, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="container-fluid row mt-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success" style={{ marginRight: 10 }}
                                hidden={this.state.hideUpdate}
                                onClick={() => { this.summit() }}><i style={{ marginRight: 5 }} className="fa fa-save" />
                                {this.state.createNew ? "Thêm" : "Cập nhật"}
                            </button>
                            <button className="btn btn-danger"
                                onClick={() => {
                                    this.setState({
                                        editName: '',
                                        selectedID: 0,
                                        createmodal: false,
                                        editGroupid: 0,
                                        editgroupName: '',
                                        createNew: false
                                    });
                                    this.validator.hideMessages();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />
                                Đóng</button>
                        </div>
                    </div>
                </Modal>
                <Modal maskClosable={false}
                    width={700}
                    visible={this.state.visibleListUsers}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Danh sách người dùng của nhóm {this.state.groupName}</h3>
                    </div>
                    <div>
                        <div className="row" style={{ display: 'flex', justifyContent: "center" }}>
                            <Input placeholder="Nhập tên để tìm kiếm"
                                className="mb-3"
                                value={this.state.kwd}
                                onChange={(e) => {
                                    this.setState({
                                        kwd: e.target.value
                                    });
                                    setTimeout(() => {
                                        this.GetListUsersOfGroup(this.state.groupid, e.target.value);
                                    }, 150);
                                }}
                            />
                            <Table
                                columns={listUserCols}
                                dataSource={this.state.listUserOfGroup}
                                pagination={false}
                            />
                            <Pagination
                                current={this.state.curPage}
                                size={this.state.curSize}
                                style={{ textAlignLast: "center", marginTop: "10px" }}
                                total={this.state.totalUserOfGroup}
                                showTotal={total => `Tổng: ${total}`}
                                onChange={(page, size) => { this.setState({ curPage: page, curSize: size }, () => this.GetListUsersOfGroup(this.state.groupid, this.state.kwd, page, size)) }}
                                onShowSizeChange={(page, size) => this.setState({ curPage: 1, curSize: size }, () => this.GetListUsersOfGroup(this.state.groupid, this.state.kwd, 1, size))}
                                showSizeChanger={true}
                            />
                        </div>
                        <div className="container-fluid row mt-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-danger"
                                onClick={() => {
                                    this.setState({
                                        visibleListUsers: false,
                                        curPage: 1,
                                        curSize: 10,
                                        kwd: ""
                                    });
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        menuData: state.global.menuData,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(nhom)
