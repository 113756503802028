import { Input, Form, notification, Select } from 'antd';
import React, { Component } from 'react';
import * as cfgService from '../../../services/configService';
import SimpleReactValidator from 'simple-react-validator';
import * as PhongKhamService from '../../departmentConfig/services/PhongKhamService';
import * as Actions from '../../../libs/actions';

let task = null;
const { Option } = Select;
export default class createNewGiuong extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idloaiphong: 0,
            makp: "0",
            lstdepartment: [],
            lstloaiphong: [],
            lstphong: [],
            lstgiuong: [],
            idphong: '',
            tenphong: '',
            maloaiphong: '',
            magiuong: '',
            tengiuong: '',
            bhyt: 0,
            giath: 0,
            giabh: 0,
            giacs: 0,
            giadv: 0,
            giann: 0,
            disableselectlp: true,
            disableselectp: true
        };
        this.validator = new SimpleReactValidator({
            messages: {
                numeric: "Cần phải nhập :attribute là số!",
                required: "Cần phải nhập :attribute!",
                max: "Nhập :attribute tối đa :max kí tự!",
                min: "Nhập giá tối thiểu bằng :min!"
            },
            element: message => <div style={{ color: "red" }}>{message}</div>
        });
    }
    componentDidMount() {
        this.loadLoaiPhong();
        this.loadLoaiPhongByKhoa();
        this.LoadDepartment();
        this.loadPhongByLoaiPhong()
        this.loadPhong();
        this.setState({
            idphong: null,
            makp: null,
            idloaiphong: null
        })
    }
    componentWillUnmount() {
        if (task) {
            clearTimeout(task)
        }
    }
    loadLoaiPhong() {
        cfgService.getSelectBoxLoaiPhong(this.state.key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstloaiphong: result.data
                });
            }
        }).catch(err => {
        })
    }
    LoadDepartment() {
        PhongKhamService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdepartment: result.data
                });
            }
        })
    }
    loadPhong() {
        cfgService.getSelectBoxPhong().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstphong: result.data
                });
            }
        }).catch(err => {
        })
    }
    loadLoaiPhongByKhoa(makp = this.state.makp) {
        cfgService.getSelectBoxLoaiPhongByKhoa(makp).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstloaiphong: result.data
                });
            }
        }).catch(() => {
            notification.warning({ messages: "Xảy ra lỗi khi tải dữ liệu loại phòng" })
        })
    }
    loadPhongByLoaiPhong(makp = this.state.makp, idlp = this.state.idloaiphong) {
        cfgService.getSelectBoxPhongByLoaiPhong(makp, idlp).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstphong: result.data
                });
            }
        }).catch(() => {
            notification.warning({ messages: "Xảy ra lỗi khi tải dữ liệu phòng" })
        })
    }
    CreateGiuong() {
        var { makp, idloaiphong, idphong, magiuong, tengiuong, bhyt, giath, giabh, giacs, giadv, giann } = this.state;
        if (this.validator.allValid()) {
            const data = {
                maKP: makp,
                idLoaiPhong: idloaiphong,
                idPhong: idphong,
                maGiuong: magiuong,
                tenGiuong: tengiuong,
                BHYT: Number(bhyt),
                giaTH: Number(giath),
                giaBH: Number(giabh),
                giaCS: Number(giacs),
                giaDV: Number(giadv),
                giaNN: Number(giann)
            }
            cfgService.createGiuong(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                    this.resetState();
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
            }).catch((err) => {
                Actions.openMessageDialog("Lỗi", err);
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetState() {
        this.validator.hideMessages();
        this.setState({
            makp: null,
            idloaiphong: null,
            idphong: null,
            magiuong: null,
            tengiuong: null,
            bhyt: 0,
            giath: 0,
            giabh: 0,
            giacs: 0,
            giadv: 0,
            giann: 0,
            disableselectlp: true,
            disableselectp: true,
        });
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ margin: 10 }}>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <h3>Thêm giường nội trú</h3>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                            <div className="col-md-4">
                                <Form.Item label="Khoa:">
                                    <Select
                                        name="makp"
                                        placeholder="Chọn khoa"
                                        value={this.state.makp}
                                        onChange={(e) => {
                                            this.setState({ makp: e, idloaiphong: null, idphong: null, disableselectp: true, disableselectlp: false })
                                            task = setTimeout(() => {
                                                this.loadLoaiPhongByKhoa(this.state.makp)
                                            }, 100);
                                        }}
                                    >
                                        {this.state.lstdepartment.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.makp}>{row.tenkp}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Khoa", this.state.makp, "required")}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item label="Loại phòng:">
                                    <Select
                                        name="idloaiphong"
                                        value={this.state.idloaiphong}
                                        placeholder="Loại phòng"
                                        disabled={this.state.disableselectlp}
                                        onChange={(e) => {
                                            this.setState({ idloaiphong: e, idphong: null, disableselectp: false })
                                            task = setTimeout(() => {
                                                this.loadPhongByLoaiPhong(this.state.makp, this.state.idloaiphong)
                                            }, 100);
                                        }}
                                    >
                                        {this.state.lstloaiphong.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.tenLoaiPhong}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Loại phòng", this.state.idloaiphong, "required")}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item label="Phòng:">
                                    <Select
                                        name="idphong"
                                        value={this.state.idphong}
                                        disabled={this.state.disableselectp}
                                        placeholder="Chọn phòng"
                                        onChange={(e) => {
                                            this.setState({ idphong: e })
                                        }}
                                    >
                                        {this.state.lstphong.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.tenPhong}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Phòng", this.state.idphong, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                            <div className="col-md-4">
                                <Form.Item label="Mã giường:">
                                    <Input
                                        name="magiuong"
                                        placeholder="Nhập mã giường"
                                        value={this.state.magiuong}
                                        onChange={(e) => this.setState({
                                            magiuong: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Mã giường", this.state.magiuong, "required|max:20")}
                                </Form.Item>
                            </div>
                            <div className="col-md-8">
                                <Form.Item label="Tên giường:">
                                    <Input
                                        name="tengiuong"
                                        placeholder="Nhập tên giường"
                                        value={this.state.tengiuong}
                                        onChange={(e) => this.setState({
                                            tengiuong: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Tên giường", this.state.tengiuong, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                            <div className="col-md-4">
                                <Form.Item label="Giá BHYT">
                                    <Input
                                        name="bhyt"
                                        suffix="đồng"
                                        minLength={1}
                                        maxLength={10}
                                        value={this.state.bhyt}
                                        onChange={(e) => this.setState({
                                            bhyt: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Giá bhyt", this.state.bhyt, "required|numeric|min:0,num")}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item label="Giá TH">
                                    <Input
                                        name="giath"
                                        suffix="đồng"
                                        minLength={1}
                                        maxLength={10}
                                        value={this.state.giath}
                                        onChange={(e) => this.setState({
                                            giath: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Giá th", this.state.giath, "required|numeric|min:0,num")}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item label="Giá BH">
                                    <Input
                                        name="giabh"
                                        suffix="đồng"
                                        minLength={1}
                                        maxLength={10}
                                        value={this.state.giabh}
                                        onChange={(e) => this.setState({
                                            giabh: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Giá bh", this.state.giabh, "required|numeric|min:0,num")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                            <div className="col-md-4">
                                <Form.Item label="Giá CS">
                                    <Input
                                        name="giacs"
                                        suffix="đồng"
                                        minLength={1}
                                        maxLength={10}
                                        value={this.state.giacs}
                                        onChange={(e) => this.setState({
                                            giacs: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Giá cs", this.state.giacs, "required|numeric|min:0,num")}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item label="Giá DV">
                                    <Input
                                        name="giadv"
                                        suffix="đồng"
                                        minLength={1}
                                        maxLength={10}
                                        value={this.state.giadv}
                                        onChange={(e) => this.setState({
                                            giadv: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Giá dv", this.state.giadv, "required|numeric|min:0,num")}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item label="Giá NN">
                                    <Input
                                        name="giann"
                                        suffix="đồng"
                                        minLength={1}
                                        maxLength={10}
                                        value={this.state.giann}
                                        onChange={(e) => this.setState({
                                            giann: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Giá nn", this.state.giann, "required|numeric|min:0,num")}
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success"
                                onClick={() => { this.CreateGiuong() }}>
                                <i className="fas fa-save" style={{ marginRight: 5 }} />Thêm
                            </button>
                            <button className="btn btn-success" style={{ marginLeft: 10 }} 
                                onClick={() => this.resetState()}><i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button style={{ marginLeft: 10 }} className="btn btn-danger"
                                onClick={() => {
                                    this.resetState();
                                    this.props.onCancel();
                                    this.props.onReload();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                />Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}