import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Medicine';
export function GetThuocTrongKho(makho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thuoctrongkhoxuat?makho=${makho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetSLTonKhoTheoID(makho, mathuoc) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/soluongtonkhotheolo?makho=${makho}&mathuoc=${mathuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetSLTonKhoByName(makho, Name) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getItemInWareHouseByName?makho=${makho}&name=${Name}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function SoLuongTonkhoXuatVatTu(makho, mavp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/SoLuongTonkhoXuatVatTu?makho=${makho}&mavp=${mavp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachXuatKho(dateFrom, dataTo, key, store, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsxuatkho?dateFrom=${dateFrom}&dateTo=${dataTo}&key=${key}&makho=${store}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongTinHoaDonXuat(ngay, id, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/detailxuatkho?ngay=${ngay}&id=${id}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuDonXuat(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/xuatkho`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ChuyenKho(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ChuyenKho`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinHoaDonChuyen(ngay, id, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DetailChuyenKho?ngay=${ngay}&id=${id}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function editXuatKho(idphieu, data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/editxuatkho/${idphieu}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function updateChuyenKho(idphieu, data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/CapNhatChuyenKho/${idphieu}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteXuatkho(idphieu, ngay, ngoaitru=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/deletexuatkho/${idphieu}?ngaynhap=${ngay}&ngoaitru=${ngoaitru}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deletechuyenkho(idphieu, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/deletechuyenkho/${idphieu}?ngaynhap=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};