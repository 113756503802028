import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import {  Button, List, Dropdown, Menu, Select, Checkbox, Drawer, Tooltip, Input, notification, DatePicker, Modal } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import * as ExamResultService from '../../services/ExamResultServices';
import * as CoporationServices from "../../services/CorporationServices";
import * as CommonService from "../../services/commonService";
import * as TestService from '../../services/lisService';
import Notify, { NotifyStatus } from '../../components/notify';
import MitaDisplayLongText from '../../components/MitaComponents/mitaDisplayLongText';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import SendResult from '../lis/component/send-result';
import Detail from "./detail";
import Modified from "./modified";
import { examResultStatusEnum } from "./examResultStatusEnum";
import { testReceiving } from "../../commons/enums/testReceiving";
import { connect } from 'react-redux';
import SiteFilter from '../../configureHIS/common/siteFilter';
import HeaderModal from "../../configureHIS/common/headerModal";
import MitaUploadFile from "../../components/MitaComponents/UploadFile/mitaUploadFile";
import * as ConfigService from '../../services/configService';

const { Option } = Select;
// const listStates = [ 
//   {value: 'ChoKetQua', text: 'Chờ kết quả'},
//   {value: 'DaCoKetQua', text: 'Đã có kết quả'},
// ]
const TestResultIndex = React.forwardRef((props, ref) => {
// export default forwardRef((props, ref) => {
  //niếm cục bộ
  //ref
  const notiRef = useRef(null);
  const detailRef = useRef();
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [skipCount, setSkipCount] = useState(0);
  const [maxResultCount, setMaxResultCount] = useState(10);
  const [sortingField, setSortingField] = useState('');
  const [sortingType, setSortingType] = useState('');
  const [sorting, setSorting] = useState('');
  //search/filter
  const [coporationId, setCoporationId] = useState();
  const [keyword, setKeyword] = useState('');
  const [sampleType, setSampleType] = useState(); // loại mẫu: Mẫu gửi | Mẫu tại chỗ
  const [sendResultType, setSendResultType] = useState();
  const [status, setStatus] = useState();
  const [dateFrom, setDateFrom] = useState(new moment());
  const [dateTo, setDateTo] = useState(new moment());
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  const [sortFields, setSortFields] = useState([]);
  const [sortTypes, setSortTypes] = useState([]);
  const [listStates, setListStates] = useState([]);
  //row options
  // const [listSites, setListSites] = useState([]);
  const [checkSendResultAll, setCheckSendResultAll] = useState(false);
  const [indeterminateCheckSendResultAll, setIndeterminateCheckSendResultAll] = useState(false);
  const [listCoporations, setListCoporations] = useState([]);
  const [visibleDetail, setVisibleDetail] = useState(false); // set visible modal detail
  const [visibleModified, setVisibleModified] = useState(false); // set visible modal update
  const [visibleSendResult, setVisibleSendResult] = useState(false); // set visible modal update
  const [selectedMaQL, setSelectedMaQL] = useState(); 
  const [selectedSid, setSelectedSid] = useState(); 
  const [selectedMaBN, setSelectedMaBN] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const [maqls, setMaqls] = useState([]);
  const [listFile, setListFile] = useState([]);
  const [resultInfoToSend, setResultInfoToSend] = useState({
    patientID: '',
    maQL: '',
    sid: '',
    siteid: '',
    coporator: '',
  });
  const listPageSize = [10,20,50,100];
  const [allSite, setAllSite] = useState(false);
  const [listSite, setListSite] = useState(props.availableSites);
  const [listSendResultTypes, setListSendResultTypes] = useState([]);
  
  //table
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    setCheckSendResultAll(maqls.length == listResult.length);
  }, [maqls])

  useEffect(() => {
    setIndeterminateCheckSendResultAll(maqls.length > 0 && maqls.length < listResult.length);
  }, [maqls, listResult])

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [maxResultCount, sortingField, sortingType])

  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [skipCount])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      search();
    }
    getListCoporations();
    getListExamResultStatus();
    loadHTTKQ();
  }, []);
  //#endregion 

  const getListExamResultStatus = () => {
    ExamResultService.getListExamResultStatus().then(result => {
      if (result.isSuccess) {
        setListStates(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        if (result.error)
          notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
        if (result.err)
          notiRef.current.Show(result.err.msgString, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        forceUpdate();
    })
  }

  const getListCoporations = () => {
    CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
      if (result.isSuccess) {
        setListCoporations(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        if (result.error)
          notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
        if (result.err)
          notiRef.current.Show(result.err.msgString, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        forceUpdate();
    })
  }
  //#endregion 

  //#region search & filter
  const validate = () => {
    let listErr = [];
    if (dateFrom > dateTo) listErr.push('"Ngày đăng ký từ" phải nhỏ hơn "ngày đăng ký đến"');
    return listErr;
  }

  const search = (skip = null, maxResult = null) => {
    let listErr = validate();
    if (listErr.length > 0) {
      let err = listErr.join('\n');
      notiRef.current.Show(err, NotifyStatus.Error);
      return;
    }
    Actions.setLoading(true);
    if (!skip) skip = skipCount;
    if (!maxResult) maxResult = maxResultCount;
    let filterModel = {
      //common: paging & sorting
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      sorting: ((sortingField ?? '').length < 1 ? 'ngaydangky' : sortingField) + ((sortingType ?? '').length < 1 ? ' asc' : sortingType),
      //riêng theo module
      keyword: keyword,
      dateFrom: dateFrom,
      dateTo: dateTo,
      coporationIds: coporationId ? [coporationId] : [],
      status: status,
      sendResultType: sendResultType,
      siteIds: listSite,
      allSite: allSite,
    }
    ExamResultService.getListExamResultTiepDon(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setSortFields(result.data.sortFields);
        setSortTypes(result.data.sortTypes);
        if (skipCount == 0)
          setListResult(result.data.items);
        else {
          setListResult([...listResult, ...result.data.items]);
        }
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const reload = () => {
    let maxRes = listResult.length;
    search(0, maxRes);
  }

  const resetToFirstPage = () => {
    if (skipCount != 0) setSkipCount(0);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }

  const resetFilter = () => {
    setCoporationId();
    setKeyword();
    setSampleType();
    setSendResultType();
    setStatus();
  }
  //#endregion 
  //#region table info & event
  const loadModified = (row, detailScrMode) => {
    if (row) {
      setSelectedMaQL(row.maQL);
      setSelectedSid(row.sid);
      setSelectedMaBN(row.maBN);
      setSelectedRecord(row);
    }
    setVisibleModified(true);
  }
  const loadDetail = (row, detailScrMode) => {
    if (row) {
      setSelectedMaQL(row.maQL);
      setSelectedSid(row.sid);
      setSelectedMaBN(row.maBN);
      setSelectedRecord(row);
      // updateLisResult(row);
    }
    setVisibleDetail(true);
  }
  
  const loadSendResult = (record) => {
    getListLISFile(record.maQL, record.siteId);
    setVisibleSendResult(true);
    setResultInfoToSend({
      patientID: record.maBN,
      maQL: record.maQL,
      sid: record.sid,
      siteid: record.siteId,
      coporator : record.maNguonKhachHang
    })
  }
  
  const updateLisResult = (row) => {
    Actions.setLoading(true);
    CommonService.GetDataFromLisByOrderId(row.maQL, row.orderId, row.siteId).then(result => {
      if (result.isSuccess) {
        notification.success({ message: "Thành công" });
        notiRef && notiRef.current && notiRef.current.Close();
        reload();
      }
      else {
        // Actions.openMessageDialog("Lỗi", result.err.msgString);
        if (result.err.msgString)
          notiRef.current.Show(result.err.msgString , NotifyStatus.Warning)
        else notiRef.current.Show(result.err, NotifyStatus.Warning)
      }
    }).catch(result => {
      if (result.err.msgString)
        notiRef.current.Show(result.err.msgString , NotifyStatus.Warning)
      else notiRef.current.Show(result.err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  //các options cho row
  const rowOptions = (record) => {
    let items = [
      {
        label: 
          <a href="#" className='stopRowClick' onClick={(e) => {
            loadDetail(record, detailScreenModeEnum.detail);
            // e.stopPropagation();
          }}><i className='fa fa-eye mr-2'/> Xem chi tiết</a>,
        key: 'viewdetail',
      },
      // {
      //   label: 
      //     <a href="#" onClick={(e) => {
      //       e.stopPropagation();
      //     }}><i className='fa fa-print mr-2'/> In kết quả</a>,
      //   key: 'printresult',
      // },
      // {
      //   label: 
      //     <a href="#" onClick={(e) => {
      //       e.stopPropagation();
      //     }}><i className='fa fa-print mr-2'/> In diễn giải</a>,
      //   key: 'printexplain',
      // },
      // {
      //   label: 
      //     <a href="#" onClick={(e) => {
      //       e.stopPropagation();
      //     }}><i className="fas fa-reply mr-2"></i> Trả kết quả</a>,// trả cho site chính (nếu là mẫu gửi)
      //   key: 'replyresult',
      // },
      {
        label: 
          <a href="#" className='stopRowClick' onClick={(e) => {
            // e.stopPropagation();
            loadSendResult(record);
          }}><i className="fas fa-paper-plane mr-2"></i> Gửi kết quả</a>,
        key: 'sendresult',
      },
      {
        label: 
          <a href="#" className='stopRowClick' onClick={(e) => {
            loadModified(record, detailScreenModeEnum.update);
            // e.stopPropagation();
          }}><i className='fas fa-pencil-alt mr-2'/> Cập nhật</a>,
        key: 'edit',
      },
    ];
    if (record.configTuongTacVoiLab && record.configQuyTrinhXacNhanMau == testReceiving.HIS) {
      let tuongTac = 
      {
        label: 
          <a href="#" className='stopRowClick' onClick={(e) => {
            e.stopPropagation();
          }}><i className="fas fa-exchange-alt mr-2"></i> Tương tác</a>,
        key: 'interactive',
        children: [
          {
            label: 
              <a href="#" className='stopRowClick' onClick={(e) => {
                // e.stopPropagation();
                updateLisResult(record);
              }}><i className='fas fa-compress-alt mr-2'/> Lấy kết quả</a>,
            key: 'getresult',
          },
        ]
      }
      
      if (record.sidSentLabStatus == 0 && !record.hasResult) 
        tuongTac.children.push(
          {
            label: 
              <a href="#" className='stopRowClick' onClick={(e) => {
                e.stopPropagation();
              }}><i className='fas fa-expand-alt mr-2'/> Gửi lại chỉ số</a>,
            key: 'send',
          },
        );
      items.push({
        label: <hr style={{margin: '-4px 0'}}></hr>,
        key: 'hr',
      });
      items.push(tuongTac);
    }
    return (
      <Menu items={items} />
    )
  }
  const onClickFunction = (e, record) => {
    if (
      e.target.className.indexOf('stopRowClick')>-1
    ) return;
    record && loadDetail(record, detailScreenModeEnum.detail);
    e.stopPropagation();
  }
  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleModified(false);
    if (reload) resetToFirstPage();
  };
  const onChangeCheckSendResultAll = (e) => {
    setCheckSendResultAll(e.target.checked);
    if (e.target.checked) {
      setMaqls(listResult.map(x=>x.maQL));
    }
    else { setMaqls([]); }
  }
  
  const getListLISFile = (maql, siteId) => {
    TestService.GetListLISFile(maql, siteId).then(result => {
        if (result.isSuccess) {
            let res = result.data;
            setListFile(res);
            // this.setState({ 
            //     listFile: res,
            //     listUrlWebFileIds: res.filter(x => x.urlWebPatient?.length > 0).map(x => x.uuid)
            //  })
        }
        else {
            Actions.openMessageDialog("Lỗi", result.err.msgString);
        }
        Actions.setLoading(false);
    }).catch(err => {
        Actions.openMessageDialog("Lỗi", err);
        Actions.setLoading(false);
    })
}
  useEffect(() => {
    if (listSite.length === props.availableSites.length)
        setAllSite(true);
    else setAllSite(false)
  }, [listSite]);
  
  const loadHTTKQ = (key = "", page = 1, size = 10) => {
    const getAll = true;
    ConfigService.getHTTKQ(key, page, size, getAll).then(res => {
      if (res.isSuccess) {
        setListSendResultTypes(res.data);
      }
    })
  }
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Kết quả xét nghiệm</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">{/* Ngày đăng ký | Nguồn khách hàng | Loại mẫu */}
                <SiteFilter updateSeletedSite={(listSite) => { setListSite(listSite) }} />
                <div className="col-md-4 d-flex flex-column mt-2">{/* Ngày đăng ký */}
                  <label>Ngày đăng ký</label>
                    <div className="d-flex">
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Từ ngày"}
                        value={dateFrom}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateFrom(e ? moment(e) : null);
                        }}
                      />
                      <div className="m-auto">&#160;-&#160;</div>
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Đến ngày"}
                        value={dateTo}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateTo(e ? moment(e) : null);
                        }}
                      />
                    </div>
                </div>
                <div className="col-md-4 d-flex flex-column mt-2">{/* Nguồn khách hàng */}
                  <label>Nguồn khách hàng</label>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    className="w-100"
                    placeholder="Chọn nguồn khách hàng"
                    maxTagCount= 'responsive'
                    value={ coporationId }
                    onChange={(value) => {setCoporationId(value);}}
                  >
                    {(listCoporations ?? []).map((record, index) => {
                      return (
                        <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                      )
                    })}
                  </Select>
                </div>
                {/* <div className="col-md-4 d-flex flex-column">
                  <label>Loại mẫu</label>
                  <Select
                    allowClear
                    className="w-100"
                    placeholder=""
                    maxTagCount= 'responsive'
                    value={ sampleType }
                    onChange={(value) => {setSampleType(value);}}
                  >
                    <Option key={1} value={1}>Mẫu tại chỗ</Option>
                    <Option key={2} value={2}>Mẫu gửi</Option>
                  </Select>
                </div> */}
                <div className="col-md-4 d-flex flex-column mt-2">{/* Trạng thái */}
                  <label>Trạng thái</label>
                  <Select
                    allowClear
                    className="w-100"
                    placeholder=""
                    maxTagCount= 'responsive'
                    value={ status }
                    onChange={(value) => {setStatus(value);}}
                  >
                  {(listStates ?? []).map((record, index) => {
                    return (
                      <Option key={record.key} value={record.key}>{record.text}</Option>
                    )
                  })}
                  </Select>
                </div>
              </div>
              <div className="row mt-2">{/* Từ khóa | Hình thức trả kết quả */}
                <div className="col-md-8 d-flex flex-column">{/* Từ khóa */}
                  <label>Từ khóa</label>
                  <Input
                    placeholder="Nhập mã, tên bệnh nhân hoặc quét mã (SID, PID)"
                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                </div>
                <div className="col-md-4 d-flex flex-column">{/* Hình thức trả kết quả */}
                  <label>Hình thức trả kết quả</label>
                  <Select
                    // mode="multiple"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    className="w-100"
                    placeholder="Chọn hình thức"
                    maxTagCount= 'responsive'
                    value={ sendResultType }
                    onChange={(value) => {setSendResultType(value);}}
                  >
                    {(listSendResultTypes ?? []).map((record, index) => {
                      return (
                        <Option key={record.keyValue} value={record.keyValue}>{record.keyName}</Option>
                      )
                    })}
                  </Select>
                </div>
              </div>
              <div className="row mt-2">{/* button Tìm kiếm | button Xóa dữ liệu nhập */}
                  <div className='col-md-12 d-flex justify-content-end'>
                    <button className="btn btn-color"
                        onClick={() => { resetToFirstPage(); }}
                    > <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm kiếm</button>
                    <Button className="btn btn-light ml-2"
                        onClick={() => { resetFilter(); }}
                    > <i style={{ marginRight: 5 }} className="fa fa-eraser" />Xóa dữ liệu nhập</Button>
                  </div>
              </div>
              <div className="row mt-2">
                  <div className='col-md-12 pl-1 d-flex justify-content-between'>
                    <div>
                        {/* <Checkbox checked={checkSendResultAll}
                          indeterminate={indeterminateCheckSendResultAll}
                          onChange={(e) => {
                            onChangeCheckSendResultAll(e);
                          }}
                        ></Checkbox>
                        <label className="mx-1"><b>|</b></label>
                        <Button className="pl-0"
                            type="link"
                            onClick={() => {
                              setVisibleSendResult(true);
                            }}
                        ><i className="fas fa-paper-plane mr-1" />Gửi kết quả</Button> */}
                    </div>
                    <div className='d-flex justify-content-end align-items-center'>
                        <label>Sắp xếp theo</label>
                        <Select className="ml-1" style={{ width:'140px' }}
                          value={ sortFields.find(x => x.selected)?.key }
                          onChange={(value) => { setSortingField(value ?? ''); }}
                        >
                          {(sortFields ?? []).map((record, index) => {
                            return (
                              <Option key={record.key} value={record.key}>{record.text}</Option>
                            )
                          })}
                        </Select>
                        <Select className="mx-1" style={{ width:'100px' }}
                            maxTagCount= 'responsive'
                            value={ sortTypes.find(x => x.selected)?.key }
                            onChange={(value) => { setSortingType(value ?? ''); }}
                        >
                            {( sortTypes ?? []).map((record, index) => {
                              return (
                                <Option key={record.key} value={record.key}>{record.text}</Option>
                              )
                            })}
                        </Select>
                        <label>Hiển thị</label>
                        <Select className="ml-1" 
                            value={ maxResultCount }
                            onChange={(value) => { setMaxResultCount(value); }}
                        >
                        {listPageSize.map((record, index) => {
                          return (
                            <Option key={record} value={record}>{record}</Option>
                          )
                        })}
                        </Select>
                    </div>
                  </div>
              </div>
            </div>
            <div className="list-page-table mt-2">{/* list */}
              <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                bordered={false}
                dataSource={listResult}
                renderItem={(record, index) => {
                  let expTimeMinute = moment(record.tgTraKq).diff(moment(), "minutes");
                  let expTimeText = '';
                  if (record.status === examResultStatusEnum.processing) {
                    switch (true) {
                      case (record.configTuongTacVoiLab && record.configQuyTrinhXacNhanMau == testReceiving.HIS && record.sidSentLabStatus == 0):
                        expTimeText = ' - Chưa gửi xuống lab ';
                        break;
                      case (record.tgTraKq != undefined && record.tgTraKq != null):
                        expTimeText = ' - ' + SystemConfig.COUNTDOWN1(record.tgTraKq, 'Đến giờ trả ');
                        break;
                      default: 
                      expTimeText = <Tooltip title="Bạn chưa cấu hình thời gian trả kết quả dự kiến! Vui lòng truy cập: Tiện ích > Cấu hình viện phí > Danh mục loại xét nghiệm "><i className="far fa-question-circle" style={{ marginLeft: 5 }} /></Tooltip>;
                        break;
                    }
                  }
                  // switch (true) {
                  //   case (record.configTuongTacVoiLab && record.sidSentLabStatus == 0 && !record.hasResult):
                  //     expTimeText = ' - Chưa gửi xuống lab';
                  //     break;
                  //   case (record.hasResult):
                  //     break;
                  //   case (expTimeMinute <= 0):
                  //     expTimeText = ' - Đến giờ trả';
                  //     break;
                  //   case (expTimeMinute > 0 && expTimeMinute <= 60):
                  //     expTimeText = ` - Còn ${expTimeMinute} phút`;
                  //     break;
                  //   case (expTimeMinute > 60 && expTimeMinute <= (60*24)):
                  //     let expTimeHour = Math.floor(expTimeMinute/60);
                  //     expTimeText = ` - Còn ${expTimeHour} tiếng ${expTimeMinute} phút`;
                  //     break;
                  //   case (expTimeMinute > (60*24)):
                  //     expTimeHour = Math.floor(expTimeMinute/60);
                  //     let expTimeDay = Math.floor(expTimeHour/24);
                  //     // expTimeText = 'Còn ' + expTimeDay  + ' ngày '+ (expTimeHour%24)  + ' tiếng ' + (expTimeMinute%(60*24))  + ' phút';
                  //     expTimeText = ` - Còn ${expTimeDay} ngày ${expTimeHour%24} tiếng ${expTimeMinute%(60*24)} phút`;
                  //     break;
                  //   default: 
                  //     expTimeText = <Tooltip title="Bạn chưa cấu hình thời gian trả kết quả dự kiến! Vui lòng truy cập: Tiện ích > Cấu hình viện phí > Danh mục loại xét nghiệm"><i className="far fa-question-circle" style={{ marginLeft: 5 }} /></Tooltip>;
                  //     break;
                  // }
                  expTimeText = <label><b>{record.statusText}</b>{expTimeText}</label>;
                  let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover";
                  return (
                    <List.Item
                      onClick={(e) => {onClickFunction(e, record)}}
                      key={record.sid}
                      className={className}
                    >
                      <div className="d-flex justify-content-between w-100">{/* 1 item */}
                        <div className="d-flex flex-column justify-content-start h-100" style={{ width: 18 }}>
                            {index + 1}
                        </div>
                        <div className="d-flex flex-column justify-content-start h-100" style={{width: 16}}>{/* column 1 */}
                          <Checkbox className="mr-2"
                              checked={maqls.indexOf(record.maQL) > -1}
                              onChange={(e) => {
                                if (e.target.checked) setMaqls([...maqls, record.maQL]);
                                else setMaqls(maqls.filter(x => x != record.maQL))
                              }}
                              onClick={(e) => {e.stopPropagation();}}
                          ></Checkbox>
                        </div>
                        <div className="d-flex flex-column justify-content-start h-100" style={{width: '30%'}}>{/* column 2 */}
                          <div className="">{/* Sid */}
                            <label className="mita-title">SID: </label>
                            {/*loadModified bên dưới là test cho nhanh. xong nhớ sửa lại là loadDetail */}
                            <label className="mita-value"><a href="#" onClick={() => {loadDetail(record, detailScreenModeEnum.detail);}}>{record.sid}</a></label>
                          </div>
                          <div className="">{/* Tên - mã KH */}
                            <label className="mita-value">{record.hoTen} - {record.maBN}</label>
                          </div>
                          <div className="">{/* Ngày sinh */}
                            <label className="mita-title">Ngày sinh: </label>
                            <label className="mita-value">{record.onlyYear == true ? record.namSinh : moment(record.ngaySinh).format(SystemConfig.FORMAT_DATE)}</label>
                          </div>
                        </div>
                        {/* calc(40% - 68px) vì col1 + col2 = 60. cộng thêm 8 để giữa các col cách nhau 2px (5col thì có 4 khoãng giữa) */}
                        <div className="d-flex flex-column justify-content-start h-100" style={{width: 'calc(40% - 86px)'}}>{/* column 3 */}
                          <div className="">{/* Ngày đăng ký */}
                            <label className="mita-title">Ngày đăng ký: </label>
                            <label className="mita-value">{moment(record.ngayDangKy).format(SystemConfig.FORMAT_DATE_TIME)}</label>
                          </div>
                          <div className="d-flex">{/* Giới tính | Điện thoại*/}
                            <div style={{width: '40%'}}>
                              <label className="mita-title">Giới tính: </label>
                              <label className="mita-value">{record.phai == 0 ? "Nam" : "Nữ"}</label>
                            </div>
                            <div>
                              <label className="mita-title">Điện thoại: </label>
                              <label className="mita-value">{record.dienThoai}</label>
                            </div>
                          </div>
                          <div className="">{/* Địa chỉ */}
                            <label className="mita-title">Địa chỉ: </label>
                            <label className="mita-value">
                              {/* {record.diaChiFull} */}
                            
                              <MitaDisplayLongText
                                  content={record.diaChiFull}
                                  maxLength={30} // mặc định 64
                                  showMoreText={"...Xem thêm"}
                                  showLessText={" Rút gọn"}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-start h-100" style={{width: '30%'}}>{/* column 4 */}
                          <div className="">{/* Còn bao lâu */}
                            <label><i className="far fa-clock mr-1"></i>{expTimeText}</label>
                          </div>
                          <div className="">{/* Nguồn khách hàng */}
                            <label className="mita-title">Nguồn khách hàng: </label>
                            <label className="mita-value">{record.tenNguonKhachHang}</label>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-start h-100" style={{width: 44}}>{/* column 5 */}
                          <div className="text-right">{/* options */}
                            <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                              <a className="ant-dropdown-link" onClick={(e) => onClickFunction(e)}><i className='fas fa-ellipsis-v'></i></a>
                            </Dropdown>
                          </div>
                          <div  className="align-self-end">{/* Số lượng kết quả */}
                            <Tooltip title={`Đã có kết quả ${record.soLuongMauCoKetQua} dịch vụ`}>
                              <label>{record.soLuongMauCoKetQua}/{record.soLuongMau}</label>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </List.Item>
                )}}
              >
                <List.Item key="showMore">
                  <div className="w-100 d-flex justify-content-center">
                    <Button
                      // type="link"
                      hidden={totalRecord <= listResult.length}
                      onClick={() => {
                        setSkipCount(skipCount + maxResultCount);
                      }}
                    >Hiển thị thêm {totalRecord - listResult.length}</Button>
                  </div>
                </List.Item>
              </List>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={true}
        closable={false}
        width={"95%"}
        placement="right"
        visible={visibleDetail}
        destroyOnClose={true}
      >
        <Detail
          isDialog={ true } 
          maQL={selectedMaQL}
          sid={selectedSid}
          maBN={selectedMaBN}
          record={selectedRecord}
          onReload={() => {reload();}}
          onCancel={() => {setVisibleDetail(false)}}
          onModifiedClick={() => {setVisibleModified(true); setVisibleDetail(false);}}
          onSendResultClick={(record) => {loadSendResult(record);}}
        />
      </Drawer>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={true}
        closable={false}
        width={"95%"}
        placement="right"
        visible={visibleModified}
        destroyOnClose={true}
      >
        <Modified
          isDialog={ true } 
          maQL={selectedMaQL}
          sid={selectedSid}
          maBN={selectedMaBN}
          record={selectedRecord}
          onCancel={() => {setVisibleModified(false)}}
        />
      </Drawer>
      
      <Modal maskClosable={false}
        destroyOnClose={true}
        width={1400}
        style={{minWidth: '600px'}}
        className="w-25"
        visible={visibleSendResult}
        onCancel={() => {setVisibleSendResult(false);}}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}>
        <SendResult 
          isDialog={ true } 
          // resultInfo = { this.state.resultInfo }
          resultInfo = {resultInfoToSend}
          listFile = { listFile.concat() }
          onCancel={(isReloadLisFile = false) => {
            setVisibleSendResult(false);
              if (isReloadLisFile) 
                resetToFirstPage();
                  // setTimeout(() => {
                  //     this.GetListLISFile(this.props.maql);
                  // },1000);
          }}/>
        </Modal>
    </React.Fragment>
  );
})
const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
      availableSites: state.global.availableSites
  })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(TestResultIndex);