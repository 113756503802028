import React, { useState } from 'react';
import * as WorldfoneService from '../../services/worldfoneService';
import * as Actions from '../../libs/actions';
import * as Configure from '../../configure/urlConfig';
import { Input, notification } from 'antd';

export default (props) => {
    const [userWF, setUserWF] = useState('');

    const loginWF = () => {
        if (userWF.length < 1 || userWF.trim() === '') {
            notification.error({ message: 'Vui lòng nhập tài khoản' });
            return;
        }
        const data = new FormData();
        data.append("secret_key", Configure.SECRETKEY_WORLDWFONE);
        data.append("userextension", userWF.split('@')[0]);
        WorldfoneService.GetToken(data, userWF).then(result => {
            if (result.status === true) {
                notification.success({ message: 'Đăng nhập worldfone thành công!' });
                if (props.onClose)
                    props.onClose()
                WorldfoneService.login(userWF, result.data.token).then(response => {
                }).catch(err => Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone."))

            } else {
                notification.error({ message: 'Đăng nhập thất bại!' });
            }
        })
    }
    return <React.Fragment>
        <div className='row'>
            <div className='h4 col-12 my-1 text-center'>{props.title}</div>
            <div className='col-12 my-1'>
                <label>Tài khoản</label>
                <Input value={userWF} placeholder='Nhập tài khoản worldfone'
                    onChange={e => setUserWF(e.target.value)}
                />
            </div>
            {/* <div className='col-12 my-1'>
                        <label>Mật khẩu</label>
                        <Input value={passWF} placeholder='Nhập mật khẩu worldfone'
                            onChange={e => setPassWF(e.target.value)}
                        />
                    </div> */}
            <div className='col-12 my-1 d-flex justify-content-center'>
                <button className='btn btn-color mr-2' onClick={() => loginWF()}>Đăng nhập worldfone</button>
                <button className='btn btn-secondary' onClick={() => {
                    if (props.onClose)
                        props.onClose()
                }}>Đóng</button>
            </div>
        </div>
    </React.Fragment>
}
