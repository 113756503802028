import { Tabs } from 'antd';
import React, { Component } from 'react';
import Users from './user';
import UseGroup from './group';

const { TabPane } = Tabs;

export default class danhmuc extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <Tabs>
                    <TabPane tab={"Người dùng"} key="1" >
                        <Users></Users>
                    </TabPane>
                    <TabPane tab={"Nhóm người dùng"} key="2">
                        <div className='containerChilderWrapper' style={{width:'100%'}}>
                            <UseGroup></UseGroup>
                        </div>
                    </TabPane>
                   
                </Tabs>
            </React.Fragment>
        )
    }
}
