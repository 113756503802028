import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import {  Button, List, Dropdown, Menu, Select, Popover, Modal, Popconfirm, Input, notification } from 'antd';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import * as CompanyExamService from '../../services/CompanyExamService';
import * as CoporationServices from "../../services/CorporationServices";
import Notify, { NotifyStatus } from '../../components/notify';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import DownloadTemplate from './downloadtemplate';
import Modified from './modified';
import Detail from './detail';
import SiteFilter from '../../configureHIS/common/siteFilter';
import PrintSID from "../invoice/paymentmulti/InSIDBarcode";

const { Option } = Select;
export default forwardRef((props, ref) => {
  //niếm cục bộ
  //ref
  const notiRef = useRef(null);
  const detailRef = useRef();
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [skipCount, setSkipCount] = useState(0);
  const [maxResultCount, setMaxResultCount] = useState(20);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [coporationId, setCoporationId] = useState();
  const [keyword, setKeyword] = useState('');
  // const [isAllSite, setIsAllSite] = useState(true);
  const [siteIds, setSiteIds] = useState();
  // const [saleId, setSaleId] = useState();
  // const [nguoiGioiThieuId, setNguoiGioiThieuId] = useState();
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //row options
  // const [listSites, setListSites] = useState([]);
  const [listCoporations, setListCoporations] = useState([]);
  const [visibleDetail, setVisibleDetail] = useState(false); // set visible modal create/update/detail
  const [visibleDownloadTemplate, setVisibleDownloadTemplate] = useState(false); // set visible modal tải mẫu
  const [visibleModified, setVisibleModified] = useState(false); // set visible import
  const [selectedId, setSelectedId] = useState(); // set visible modal create/update/detail
  const [visiblePrint, setVisiblePrint] = useState(false);
  //table
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [skipCount, sorting])

  useEffect(() => {
    if (siteIds != undefined && firstRender.current) {
      firstRender.current = false;
      search();
    }
  }, [siteIds])

  useEffect(() => {
    getListCoporations();
  }, []);
  //#endregion 

  const getListCoporations = () => {
    CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
      if (result.isSuccess) {
        setListCoporations(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        if (result.error)
          notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
        if (result.err)
          notiRef.current.Show(result.err.msgString, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        forceUpdate();
    })
  }
  //#endregion 

  //#region search & filter
  const search = () => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      coporationIds: coporationId ? [coporationId] : [],
      siteIds: siteIds,
    }
    CompanyExamService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        if (skipCount == 0)
          setListResult(result.data.items);
        else {
          setListResult([...listResult, ...result.data.items]);
        }
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (skipCount != 0) setSkipCount(0);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }

  const resetFilter = () => {
    setCoporationId();
    setKeyword();
  }
  //#endregion 
  //#region table info & event
  const loadModified = (row, detailScrMode) => {
    if (row) setSelectedId(row.id);
    setDetailScreenMode(detailScrMode);
    setVisibleModified(true);
  }
  const loadDetail = (row, detailScrMode) => {
    if (row) setSelectedId(row.id);
    setVisibleDetail(true);
  }

  const onDeleteClickHandle = (record) => {
    Actions.setLoading(true);
    CompanyExamService.deleteById(record.id).then(result => {
      if (result.isSuccess) {
        resetToFirstPage();
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  };
  
  //các options cho row
  const rowOptions = (record) => {
    return (
      <Menu
        items={[
          {
            label: 
              <>
                {/* <Dropdown overlay={rowOptions(record)} trigger={['click']} >
                </Dropdown> */}
                <a onClick={(e) => { setVisiblePrint(true); }}
                ><i className="fas fa-barcode mr-2" /> In SID</a>
                <Popover
                  placement="right"
                  content={
                    <div style={{ width: 400 }}>
                        <PrintSID billSelected={record.listMaQL.join(',')} onSubmit={() => {setVisiblePrint(false)}} onCancel={() => {setVisiblePrint(false)}} />
                    </div>
                  }
                  trigger="click"
                  visible={visiblePrint}
                  // onVisibleChange={handleOpenPrintChange}
                />
              </>,
            key: 'print',
          },
          {
            label: 
              <a href="#" onClick={() => {
                loadDetail(record, detailScreenModeEnum.detail);
              }}><i className='fa fa-eye mr-2'/> Xem chi tiết</a>,
            key: 'viewdetail',
          },
          // {
          //   label: 
          //     <a href="#" onClick={() => {
          //       // loadCardList(record);
          //     }}><i className='fas fa-file-excel mr-2'/> Xuất danh sách</a>,
          //   key: 'exportlist',
          // },
          {
            label: 
              <a href="#" onClick={() => {
                loadModified(record, detailScreenModeEnum.update);
              }}><i className='fas fa-pencil-alt mr-2'/> Cập nhật</a>,
            key: 'edit',
          },
          {
            label: 
              <Popconfirm
                  title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{}</b>?</span>)}}
                  onConfirm={() => onDeleteClickHandle(record)}
                  onCancel={() => {}}
                  okText="Đồng ý"
                  cancelText="Hủy"
                >
                  <a href="#"><i className='fas fa-trash-alt mr-2'/> Xóa</a>
                </Popconfirm>
              ,
            key: 'delete',
          },
        ]}
      />
    )
  }
  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleModified(false);
    if (reload) resetToFirstPage();
  };
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Khám sức khỏe công ty</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">
                <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
              </div>
              <div className="row mt-2">
                <div className="col-md-6 d-flex flex-column">
                  <label>Nguồn khách hàng</label>
                  <Select
                    // mode="multiple"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    className="w-100"
                    placeholder="Chọn nguồn khách hàng"
                    maxTagCount= 'responsive'
                    value={ coporationId }
                    onChange={(value) => {setCoporationId(value);}}
                  >
                    {(listCoporations ?? []).map((record, index) => {
                      return (
                        <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                      )
                    })}
                  </Select>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label>Số hợp đồng</label>
                  <Input
                    // placeholder="Số hợp đồng"
                    // prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                </div>
              </div>
              <div className="row mt-2">
                  <div className='col-md-6 d-flex justify-content-start align-items-start'>
                      <Button className="pl-0"
                        type="link"
                        hidden={props.isDialog}
                        onClick={() => {
                          setVisibleDownloadTemplate(true);
                        }}
                      ><i className="fas fa-file-excel mr-1" />Tải mẫu</Button>
                  </div>
                  <div className='col-md-6 d-flex justify-content-end'>
                    <button className="btn btn-color"
                        onClick={() => { resetToFirstPage(); }}
                    > <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm kiếm</button>
                    <Button className="btn btn-light ml-2"
                        onClick={() => { resetFilter(); }}
                    > <i style={{ marginRight: 5 }} className="fa fa-eraser" />Xóa dữ liệu nhập</Button>
                  </div>
              </div>
              <div className="row">
                  <div className='col-md-6 d-flex justify-content-start align-items-start'>
                      <Button className="pl-0"
                        type="link"
                        hidden={props.isDialog}
                        onClick={() => {
                          loadModified(null, detailScreenModeEnum.create);
                        }}
                      ><i className="fa fa-plus-circle mr-1" />Import danh sách</Button>
                  </div>
              </div>
            </div>
            <div className="list-page-table mt-2">{/* list */}
              <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                bordered={false}
                dataSource={listResult}
                renderItem={(record) => {
                  let className = "p-1 border-2 border-bottom py-3";
                  return (
                    <List.Item key={record.id}
                      className={className}
                    >
                      <div className="d-flex flex-column w-100">
                        <div className="d-flex justify-content-between">
                          <div className="">
                            <a href="#" 
                              onClick={() => {loadDetail(record, detailScreenModeEnum.detail)}}
                            >{record.coporationName}</a>
                          </div>
                          <div>
                            <i className="fa fa-history mr-1"></i>
                            <label className="mr-2">{moment(record.dateImport).format(SystemConfig.FORMAT_DATE_TIME_SECOND) }</label>
                            <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                              <a className="ant-dropdown-link"><i className='fas fa-ellipsis-v'></i></a>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="">
                            <label>{record.siteName}</label>
                          </div>
                          <div className="">
                            <label>Số hợp đồng: <b>{record.contractNo}</b></label>
                            <i className="fas fa-tint mr-1 ml-3"></i>
                            <label>{record.countTiepDonConfirm.toLocaleString('en-US')}/{record.countTiepDon.toLocaleString('en-US')}</label>
                          </div>
                        </div>
                      </div>
                    </List.Item>
                )}}
              >
                <List.Item key="showMore">
                  <div className="w-100 d-flex justify-content-center">
                    <Button className="pl-0"
                      type="link"
                      hidden={totalRecord <= listResult.length}
                      onClick={() => {
                        setSkipCount(skipCount + maxResultCount);
                      }}
                    >... Xem thêm ...</Button>
                  </div>
                </List.Item>
              </List>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
      <Modal 
        maskClosable={false}
        width={500}
        visible={visibleDownloadTemplate}
        onCancel={() => setVisibleDownloadTemplate(false)}
        destroyOnClose={true}
      >
        <DownloadTemplate
          isDialog={ true } 
          listCoporations={listCoporations}
          // detailScreenMode = {detailScreenMode}
          onCancel={() => {setVisibleDownloadTemplate(false)}}
        />
      </Modal>
      <Modal
        maskClosable={false}
        width={900}
        visible={visibleModified}
        onCancel={(reload = false) => handleCancel()}
        destroyOnClose={true}
      >
        <Modified
          isDialog={ true } 
          listCoporations={listCoporations}
          // listSales={listSales}
          // listNguoiDuoiThieus={listNguoiDuoiThieus}
          detailScreenMode = {detailScreenMode}
          onCancel={(reload = false) => {handleCancel(reload)}}
          companyExamId={selectedId}
        />
      </Modal>
      <Modal 
        maskClosable={false}
        width={900}
        visible={visibleDetail}
        onCancel={() => setVisibleDetail(false)}
        destroyOnClose={true}
      >
        <Detail
          isDialog={ true } 
          detailScreenMode = {detailScreenMode}
          onCancel={() => {setVisibleDetail(false)}}
          companyExamId={selectedId}
        />
      </Modal>
    </React.Fragment>
  );
})