import * as Actions from '../libs/actions';
import * as ApiCaller from '../libs/httpRequests';
const prefix = "Print";


export function PrintRecepRecipt(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintRecepRecipt?maql=${maql}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintInvoice(maql, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintInvoice?maql=${maql}&ngay=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintInvoicePaid(maql, idvienphi, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintInvoicePaid?maql=${maql}&idvienphi=${idvienphi}&ngay=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintListPatientPayment(sohoadon, ngay, maql, banle) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintListPatientPayment?sohoadon=${sohoadon}&ngay=${ngay}&banle=${banle}&maql=${maql}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPayment(sohoadon, ngay, maql, banle) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PrintPayment?sohoadon=${sohoadon}&ngay=${ngay}&banle=${banle}&maql=${maql}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintMedicine(id, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintMedicine?id=${id}&ngay=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintUse(id, ngay, maql, isDuoc = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintUse?id=${id}&ngay=${ngay}&isDuoc=${isDuoc}&maql=${maql}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintImprestFund(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintImprestFund?id=${id}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintQuyetToan(mabn, maql, ngayvv, ngaythu, loaidv) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintQuyetToan?mabn=${mabn}&maql=${maql}&ngayvv=${ngayvv}&ngaythu=${ngaythu}&loaidv=${loaidv}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintRefundTrucTiep(dateFrom, dateTo, mabn, maql, banle = false, sohoadon = "") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintRefundTrucTiep?dateFrom=${dateFrom}&dateTo=${dateTo}&mabn=${mabn}&maql=${maql}&banle=${banle}&sohoadon=${sohoadon}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintRefundTamUng(dateFrom, dateTo, mabn, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintRefundTamUng?dateFrom=${dateFrom}&dateTo=${dateTo}&mabn=${mabn}&maql=${maql}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintSummary(maql, mabn, ngay, banle, sohoadon, printType) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintSummary?maql=${maql}&mabn=${mabn}&ngay=${ngay}&banle=${banle}&sohoadon=${sohoadon}&printtype=${printType}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPASC(idchidinh, maql, idkt, mabn, ngay, loaicdha) {
    if (loaicdha === undefined) loaicdha = 0;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPASC?idchidinh=${idchidinh}&maql=${maql}&idkt=${idkt}&mabn=${mabn}&ngay=${ngay}
        &type=${loaicdha}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintConclusion(id = '0', mabn, maql, ngay, makp, loaiKham, isDuoc = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintConclusion?id=${id}&mabn=${mabn}&maql=${maql}&ngay=${ngay}&makp=${makp}&loaiKham=${loaiKham}&isDuoc=${isDuoc}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintTongHopNgay(dateFrom, dateTo, paymenttype, coporator, chinhanh, type = '', filter = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintTongHopNgay?dateFrom=${dateFrom}&dateTo=${dateTo}&paymenttype=${paymenttype}&coporator=${coporator}&type=${type}&filter=${filter}&chinhanh=${chinhanh}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};


export function PrintResultTest(sid, maql, ngay, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintResultTest?sid=${sid}&maql=${maql}&ngay=${ngay}&chinhanh=${chinhanh}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintConclusionBanLe(mabn, id, sotoa, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintToaBanLe?mabn=${mabn}&id=${id}&sotoa=${sotoa}&ngay=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintIsPaid(mabn, maql, ngay, lstid = "", dichvukham = "") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintIsPaid?mabn=${mabn}&maql=${maql}&ngay=${ngay}&lstid=${lstid}&dichvukham=${dichvukham}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintUseRetail(id, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintUseRetail?id=${id}&ngay=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPhieuChuyenKho(id, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPhieuChuyenKho?id=${id}&ngaytao=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPhieuNhapKho(id, ngay, check) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPhieuNhapKho?id=${id}&ngaytao=${ngay}&check=${check}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPhieuXuatKho(id, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPhieuXuatKho?id=${id}&ngaytao=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintConclusionTiepTan(mabn, maql, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintConclusionTiepTan?mabn=${mabn}&maql=${maql}&ngay=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function CheckPrintIsPaidButton(mabn, maql, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/CheckPrintIsPaidButton?mabn=${mabn}&maql=${maql}&ngay=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintThongkeDichVuBenhNhan(dateFrom, dateTo, paymenttype, coporator, chinhanh, filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintThongKeDichVuBenhNhan?dateFrom=${dateFrom}&dateTo=${dateTo}&paymenttype=${paymenttype}&coporator=${coporator}&chinhanh=${chinhanh}&filter=${filter}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintBenhTrinh(mavaovien, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintBenhTrinh?mavaovien=${mavaovien}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintSoChamSoc(mavaovien, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintSoChamSoc?mavaovien=${mavaovien}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPhieuTheoDoiChucNangSong(mavaovien, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPhieuTheoDoiChucNangSong?mavaovien=${mavaovien}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintResultTestBenhTrinh(mabn, mavaovien, maql, ngay, dateFrom, dateTo, all = true, key = '', isOld = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintResultTestBenhTrinh?mabn=${mabn}&mavaovien=${mavaovien}&maql=${maql}&ngay=${ngay}&dateFrom=${dateFrom}&dateTo=${dateTo}&all=${all}&key=${key}&isOld=${isOld}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};


export function PrintPASCBenhTrinh(mabn, mavaovien, maql, ngay, dateFrom, dateTo, all = true, key = '', isOld = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPASCBenhTrinh?mabn=${mabn}&mavaovien=${mavaovien}&maql=${maql}&ngay=${ngay}&dateFrom=${dateFrom}&dateTo=${dateTo}&all=${all}&key=${key}&isOld=${isOld}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintToaThuocNoiTru(id, mabn, mavaovien, dateFrom, dateTo, isOld = false, isAll = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintToaThuocNoiTru?id=${id}&mabn=${mabn}&mavaovien=${mavaovien}&dateFrom=${dateFrom}&dateTo=${dateTo}&isOld=${isOld}&isAll=${isAll}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPhieuLinhThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPhieuLinhThuoc?id=${id}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintListPhieuLinhThuoc(arrId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintListPhieuLinhThuoc?arrId=${arrId}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPhieuTraThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPhieuTraThuoc?id=${id}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintListPhieuTraThuoc(arrId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintListPhieuTraThuoc?arrId=${arrId}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPaymentInPatient(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPaymentInPatient?id=${id}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintRefundInPatient(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintRefundInPatient?id=${id}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};


export function PrintDetailResultTest(sid, maql, ngay, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintDetailResultTest?sid=${sid}&maql=${maql}&ngay=${ngay}&chinhanh=${chinhanh}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintOrderSID(maql, soluong, loai, all = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintOrderSID?maql=${maql}&soluong=${soluong}&loai=${loai}&all=${all}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintOrderSIDInManagermentList(listmaql, soluong, all = false, moreSheet = 1) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintOrderSIDInManagermentList?listmaql=${listmaql}&soluong=${soluong}&all=${all}&more=${moreSheet}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintKetQuaKham(id = '0', mabn, maql, ngay, mavp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintKetQuaKham?id=${id}&mabn=${mabn}&maql=${maql}&ngay=${ngay}&mavp=${mavp}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintThongKeLayMauTaiCho(from, to, site) {
    const type = "taicho";
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintThongKeXacNhanLayMau?from=${from}&to=${to}&site=${site}&type=${type}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintThongKeLayMauTaiNha(from, to, site) {
    const type = "tainha";
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintThongKeXacNhanLayMau?from=${from}&to=${to}&site=${site}&type=${type}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintPhieuHoanTra(id, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintPhieuHoanTra?id=${id}&ngay=${ngay}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintSID(listSID, moreSheet = 1) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PrintSID?listSID=${listSID}&more=${moreSheet}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function PrintSIDByMaql(listMaql, moreSheet = 1) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PrintSIDByMaql?listMaql=${listMaql}&more=${moreSheet}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function printPID(PID) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PrintPID?pid=${PID}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

export function printReceptionReceipt(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PrintReceptionReceipt?maql=${maql}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};


export function printIndication(mabn, maql, ngay, lstid = "", dichvukham = "") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PrintIndication?mabn=${mabn}&maql=${maql}&ngay=${ngay}&lstid=${lstid}&dichvukham=${dichvukham}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};