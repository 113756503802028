import { notification } from 'antd';
import React, { Fragment, useState, useEffect } from 'react';

function ActionControl(props) {
    // Hiển thị nút lưu, action props.handleSave()
    const [activeSave, setActiveSave] = useState(props.activeSave);
    // Hiển thị nút cập nhật, action props.handleUpdate()
    const [activeUpdate, setActiveUpdate] = useState(props.activeUpdate);
    // Hiển thị nút lưu và in, action props.handleSavePrint()
    const [activeSavePrint, setActiveSavePrint] = useState(props.activeSavePrint);
    // Action đóng props.handleClose()

    const handleSave = () => {
        if (props.handleSave) {
            props.handleSave();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function"});
        }
    }

    const handleUpdate = () => {
        if (props.handleUpdate) {
            props.handleUpdate();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function"});
        }
    }

    const handleSaveAndPrint = () => {
        if (props.handleSavePrint) {
            props.handleSavePrint();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function"});
        }
    }

    const handleClose = () => {
        if (props.handleClose) {
            props.handleClose();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function"});
        }
    }

    useEffect(() => {
        setActiveSave(props.activeSave);
        setActiveUpdate(props.activeUpdate);
        setActiveSavePrint(props.activeSavePrint);
    }, [props])

    return (
        <Fragment>
            <div className='custom-hr'/>
            <div className='d-flex justify-content-end'>
                {activeSave && <button
                    className='btn btn-color'
                    onClick={handleSave}>
                    <i className='fas fa-save mr-1'/>Lưu
                </button>}
                {activeUpdate && <button
                    className='btn btn-color ml-2'
                    onClick={handleUpdate}>
                    <i className='fas fa-save mr-1'/>Cập nhật
                </button>}
                {activeSavePrint && <button
                    className='btn btn-primary ml-2'
                    onClick={handleSaveAndPrint}>
                    <i className='fas fa-save mr-1'/>Lưu và in
                </button>}
                <button
                    className='btn btn-danger ml-2'
                    onClick={handleClose}>
                    <i className='far fa-times-circle mr-1'/>Đóng
                </button>
            </div>
        </Fragment>
    );
}

export default ActionControl;