import * as ApiCaller from '../../../libs/httpRequests';
const prefix = 'TemplateVoucher';

export function create(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/Create`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getList(key, type, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetList?key=${key}&type=${type}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getDetail(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function update(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/Update`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteTemplate(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/${id}`,).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
