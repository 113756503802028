import React, { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Pagination, Table } from 'antd';
import * as SystemConfig from "../../../configure/systemConfig";
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as PatientStaticService from '../../../services/patientStaticService';
import { COLOR } from '../../../configureHIS/common/TagVertical';

export default forwardRef((props, ref) => {
    const [listPatients, setListPatients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSize, setCurrentSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const _hiddenLink = useRef();
    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 80,
            align: "center",
            render: (text, record, index) => ((currentPage - 1) * currentSize + (Number(index) + 1))
        },
        {
            title: 'Ngày tiếp đón',
            dataIndex: 'dateIn',
            key: 'dateIn',
            width: 200,
            render: (date) => {
                if (date)
                    return <span>{moment(date).format(SystemConfig.FORMAT_DATE)}</span>;
                else return "Không xác định"
            },
        },
        {
            title: 'Mã khách hàng',
            dataIndex: 'patientId',
            key: 'patientId',
            width: 150,
        },
        {
            title: 'Khách hàng',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 250,
        },
        {
            title: 'Nguồn khách hàng',
            dataIndex: 'coporation',
            key: 'coporation',
            width: 180,
        },
        {
            title: 'Tiếp đón',
            dataIndex: 'isNew',
            key: 'isNew',
            width: 180,
            render: (isNew) => {
                return <span>{isNew ? "Khách hàng mới" : "Khách hàng tái khám"}</span>;
            },
        },
        {
            title: 'Ngày tiếp đón lần đầu',
            dataIndex: 'firstMet',
            key: 'firstMet',
            width: 200,
            render: (date) => {
                if (date)
                    return <span>{moment(date).format(SystemConfig.FORMAT_DATE)}</span>;
                else return "Không xác định"
            },
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            width: 100,
            align: "right",
        },
        {
            title: 'Năm sinh',
            dataIndex: 'yearOfBirth',
            key: 'yearOfBirth',
            width: 150,
            align: "right",
            render: (text, record) => {
                return <span>{text}</span>;
            },
        },
        {
            title: 'CCCD/CMND/Passport',
            dataIndex: 'identifyCard',
            key: 'identifyCard',
            width: 200,
            align: "right",
            render: (text, record, index) => {
                return <span>{!text || text.length < 5 ? '' : text}</span>;
            },
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
            align: "right",
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            width: 250,
            align: "left",
        },
    ];
    useImperativeHandle(ref, () => ({
        getList: () => {
            setCurrentPage(1);
            getList();
        },
        exportPatientStatistic: () => {
            exportPatientStatistic();
        }
    }));
    useEffect(() => {
        getList();
    }, [currentPage, currentSize]);
    const getList = () => {
        Actions.setLoading(true);
        let dateFrom = props.datePickerType === "day" ? props.dateRange[0] : getDate("from");
        let dateTo = props.datePickerType === "day" ? props.dateRange[1] : getDate("to");
        let site = props.site;
        PatientStaticService.GetPatientStatistic(dateFrom, dateTo, currentPage, currentSize, site).then(result => {
            if (result.isSuccess) {
                let data = result.data.items;
                setListPatients(data);
                setTotalRecords(result.data.totalCount);
                updatePropsSummarize(result.data.returnObject);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bị lỗi");
        })
    }
    const getDate = (type) => {
        let datePickerType = props.datePickerType;
        let dateSelected = props.date;
        if (type === "from") {
            return moment(dateSelected[0]).startOf(datePickerType);
        } else if (type === "to") {
            return moment(dateSelected[1]).endOf(datePickerType);
        }
    }
    const updatePropsSummarize = (data) => {
        let dataSummarize = [];
        dataSummarize.push({
            title: "Khách hàng mới",
            color: COLOR.Primary,
            value: data.totalNew,
        });
        dataSummarize.push({
            title: "Khách hàng tái khám",
            color: COLOR.Success,
            value: data.totalOld,
        });
        dataSummarize.push({
            title: "Tổng cộng",
            value: data.totalNew + data.totalOld,
        });
        props.updateDataSummarize && props.updateDataSummarize(dataSummarize);
    }
    const exportPatientStatistic = () => {
        Actions.setLoading(true);
        let dateFrom = props.datePickerType === "day" ? props.dateRange[0] : getDate("from");
        let dateTo = props.datePickerType === "day" ? props.dateRange[1] : getDate("to");
        let site = props.site;
        let getAll = true;
        let keyword = "";
        PatientStaticService.ExportPatientStatisticV2(dateFrom, dateTo, currentPage, currentSize, site, keyword, getAll)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeSoLuongBenhNhan";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    return (
        <React.Fragment>
            <a href="#download" style={{ display: 'none', visibility: "hidden" }} ref={_hiddenLink}></a>
            <div style={{ height: 'calc(100% - 20px)', marginTop: 10 }}>
                <Table dataSource={listPatients}
                    scroll={{ y: 0 }}
                    pagination={false}
                    columns={tableColumns}
                    rowKey={(e, index) => index} />
            </div>
            <Pagination
                className="mt-3"
                style={{ textAlignLast: "center" }}
                current={currentPage}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page); setCurrentSize(size); }}
                onShowSizeChange={(page, size) => { setCurrentPage(1); setCurrentSize(size); }}
                showSizeChanger={true}
            />
        </React.Fragment>
    );
})