import { DatePicker, Select } from 'antd';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import CongNo from './CongNo';
import CongNoChiTiet from './CongNoChiTiet';
import Notify, { NotifyStatus } from '../../../components/notify'
import * as AuthServices from '../../../services/authServices';
import { connect } from 'react-redux';
import SiteFilter from '../../../configureHIS/common/siteFilter';

const { RangePicker } = DatePicker;
const { Option } = Select;

function Congno(props) {
    const _hiddenLink = useRef()
    const [type, setType] = useState(0)
    const [date, setDate] = useState([null, null])
    const [message, setMessage] = useState(null);
    const [currentSite, setCurrentSite] = useState(props.siteid);
    const [site, setSite] = useState([]);
    const [availableSites, setAvailableSites] = useState([]);

    const notiRef = useRef(null);
    const congnophaithu = useRef();
    const congnodathu = useRef();

    const onComplete = () => {
        if(congnophaithu && congnophaithu.current){
            congnophaithu.current.GetData()
        }
        if(congnodathu && congnodathu.current){
            congnodathu.current.GetData()
        }
    }

    const getAvaiabeSites = () => {
        AuthServices.getAvailableSites().then((res) => {
            if (res.isSuccess) {
                setAvailableSites(res.data)
            }
        })
    }

    useEffect(() => {
        getAvaiabeSites();
    }, [])

    useEffect(() => {
        if(congnophaithu && congnophaithu.current){
            congnophaithu.current.GetData()
        }
        if(congnodathu && congnodathu.current){
            congnodathu.current.GetData()
        }
    }, [site])

    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
            <div className='containerChilderWrapper'>
                <div className='modal-header'>
                <div className='h3'>Công nợ - Trả sau</div>
                </div>
                <Notify ref={notiRef} />
                <div className='row pb-1'>
                    <SiteFilter updateSeletedSite={(listSite) => { setSite(listSite) }} />
                </div>
                <div className='row'>
                    <div className='col-md-6 py-1'>
                        <div style={{ width: 300 }}>
                            <CongNo ref={congnophaithu} type={1} site={site} ClickDetail={data => {setType(data.type); setDate(data.date) }} ShowNotify={(mes, status) => {
                                if (notiRef && notiRef.current)
                                    notiRef.current.Show(mes, status)
                            }} />
                        </div>
                    </div>
                    <div className='col-md-6 py-1'>
                        <div style={{ width: 300 }}>
                            <CongNo ref={congnodathu} type={2} site={site} ClickDetail={data => {setType(data.type); setDate(data.date) }} ShowNotify={(mes, status) => {
                                if (notiRef && notiRef.current)
                                    notiRef.current.Show(mes, status)
                            }}/>
                        </div>
                    </div>
                    {type != 0 &&
                        <div className='col-12 py-1'>
                            <CongNoChiTiet date1={date} type={type} site={site} onComplete={() => {onComplete()}}/>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Congno);

