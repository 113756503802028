import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react'
import * as ConfirmOrderService from '../../../services/confirmOrderService';
import * as PrintService from '../../../services/printService';
import * as Actions from '../../../libs/actions';
import moment from 'moment';
import { Pagination, Table } from 'antd';
import * as StatisticService from '../../../services/statisticService';

export default forwardRef((props, ref) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSize, setCurrentSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [selectedRow, setSelectedRow] = useState(0);
    const _hiddenLink = useRef();

    useImperativeHandle(ref, () => ({
        GetList: () => {
            setCurrentPage(1);
            GetList();
        },
        Print: () => {
            Print();
        },
        Export: () => {
            Export();
        }
    }));

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * currentSize + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        {
            title: 'Mã bệnh nhân',
            dataIndex: 'patientID',
            key: 'patientID',
            align: 'center',
            width: 120,
        },
        {
            title: 'Tên bệnh nhân',
            dataIndex: 'patientName',
            key: 'patientName',
            align: 'left',
            width: 200,
        },
        {
            title: 'Ngày vào',
            dataIndex: 'applyDate',
            key: 'applyDate',
            align: 'center',
            width: 120,
            render: (value) => value && moment(value).format("YYYY/MM/DD HH:mm")
        },
        {
            title: 'Năm sinh',
            dataIndex: 'birthYear',
            key: 'birthYear',
            align: 'center',
            width: 100,
            render: (yob) => yob && moment(yob).year()
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            align: 'center',
            width: 90,
        },
        {
            title: 'SID',
            dataIndex: 'sid',
            key: 'sid',
            align: 'center',
            width: 120,
        },
        {
            title: 'Ngày xác nhận',
            dataIndex: 'dateConfirm',
            key: 'dateConfirm',
            align: 'right',
            width: 200,
            render: (date, record) => {
                return (
                    (!record.usernameConfirm || record.usernameConfirm === '') ? '' : moment(date).format('DD/MM/YYYY HH:mm')
                )
            }
        },
        {
            title: 'Người xác nhận',
            dataIndex: 'usernameConfirm',
            key: 'usernameConfirm',
            align: 'left',
            width: 200,
        },
    ]

    useEffect(() => {
        GetList();
    }, [currentPage, currentSize]);

    const GetList = (loading = true) => {
        Actions.setLoading(loading);
        ConfirmOrderService.GetListTaiNha(props.keySearch, moment(props.dateFrom).format("YYYY/MM/DD"), moment(props.dateTo).format("YYYY/MM/DD"), props.chiNhanh, currentPage, currentSize).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecords(result.totalRecords);
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const Export = () => {
        Actions.setLoading(true);
        const type = "tainha";
        StatisticService.ExportThongKeXacNhanLayMau(moment(props.dateFrom).format("YYYY/MM/DD"), moment(props.dateTo).format("YYYY/MM/DD"), props.chiNhanh, type).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = "ThongKeXacNhanLayMauTaiNha";
                _hiddenLink.current.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    const Print = () => {
        PrintService.PrintThongKeLayMauTaiNha(moment(props.dateFrom).format("YYYY/MM/DD"), moment(props.dateTo).format("YYYY/MM/DD"), props.chiNhanh).then((result) => {
            Actions.DoPrint(result.item)
        })
    }

    return (
        <React.Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
            <div style={{ height: '100%' }}>
                <div style={{height:'calc(100% - 50px)'}}>
                    <Table
                        dataSource={dataSource}
                        scroll={{ x: 'max-content', y: 'max-content' }}
                        pagination={false}
                        onRow={(row, rowIndex) => {
                            return {
                                onClick: e => {
                                    setSelectedRow(row.sid);
                                },
                            };
                        }}
                        columns={columns}
                        rowKey={(e, index) => index}
                        rowClassName={(record, rowIndex) => {
                            if (record.sid === selectedRow) {
                                return "rowCustom btn-color"
                            }
                        }}
                    />
                </div>
                <Pagination
                    current={currentPage}
                    style={{ textAlignLast: "center", marginTop: "10px", marginBottom: '10px' }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => {
                        setCurrentPage(page);
                        setCurrentSize(size);
                    }}
                    onShowSizeChange={(page, size) => {
                        setCurrentPage(1);
                        setCurrentSize(size);
                    }}
                    showSizeChanger={true}
                />
            </div>
        </React.Fragment>
    )
})