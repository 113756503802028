import { DatePicker, Form, notification, Table, Tabs, Pagination, Select, Button } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../../libs/actions';
import * as StatisticService from '../../../../../services/statisticService';
import * as PrintService from '../../../../../services/printService';
import TheoDichVu from './theodichvu';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../../../services/configService';
import * as UsersService from '../../../../../services/usersService';
const { RangePicker } = DatePicker;
const { Option } = Select;
const TabPane = Tabs.TabPane;



export default class theoluotkham extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(moment().format('YYYY-MM-DD 00:00')), moment()],
            dataSource: [],
            tongthucthu: 0,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            paymentType: 0,
        };
    }

    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        this.GetListSite();
        this.LoadThongKeDoanhSo();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadThongKeDoanhSo() {
        Actions.setLoading(true);
        StatisticService.ThongKeDoanhSoTheoBN(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.state.paymentType, this.state.chinhanh).then(result => {
            if (result.isSuccess) {
                let tongtien = 0;
                result.data.map(x => tongtien += x.tongtien);
                this.setState({
                    dataSource: result.data,
                    tongthucthu: tongtien,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeDoanhSo() {
        Actions.setLoading(true);
        StatisticService.ExportLuotKhamTheoNguonKhachHang(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.state.paymentType, this.state.chinhanh)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeLuotKhamTheoNguonKhachHang";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    getPaymentType(type) {
        if (type !== "" && type !== null && type !== undefined) {
            var paymentTypeList = [];
            if (type.includes("1,")) paymentTypeList.push("Tiền mặt");
            if (type.includes("2,")) paymentTypeList.push("Chuyển khoản");
            if (type.includes("3,")) paymentTypeList.push("Quẹt thẻ");
            if (type.includes("4,")) paymentTypeList.push("Công nợ");
            return paymentTypeList.join(", ");
        } else return "";
    }

    
    render() {
        const columns = [
            {
                width: 60,
                title: 'STT',
                align: "center",
                render: (text, record, index) => (this.state.dataSource.indexOf(record) + 1),
            },
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
                align: "center",
                width: 110,
            },
            {
                title: 'Tên BN',
                dataIndex: 'tenbn',
                key: 'tenbn',
                width: 250,
            },
            {
                title: 'Phiếu thanh toán',
                dataIndex: 'sohoadon',
                key: 'sohoadon',
                width: 80,
                align: "center",
            },
            {
                title: 'Công ty - đơn vị',
                dataIndex: 'coporator',
                key: 'coporator',
                width: 170,
                render: (copr) => {
                    return <label style={{ wordBreak: "break-word" }}>{copr}</label>
                }
            },
            {
                title: 'Năm sinh',
                dataIndex: 'namsinh',
                key: 'namsinh',
                width: 80,
                align: "center",
            },
            {
                title: 'Giới tính',
                dataIndex: 'gioitinh',
                key: 'gioitinh',
                width: 80,
                align: "center",
            },
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 80,
                align: "center",
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>;
                },
            },
        ]

        const DatetimePicker = (
            <>
            <div style={{ height: '100%', overflow: 'auto' }}>
                <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                    <Form.Item>
                        <Select
                        showSearch
                        style={{width: '150px'}}
                        value = {this.state.paymentType}
                        onChange={(value => this.setState( {paymentType : value}, () => {
                            this.LoadThongKeDoanhSo()
                            if(this.PatientReport != null){
                                this.PatientReport.LoadThongKeDoanhSo()
                            }
                        }))}
                    >
                        <option key={1} value={0}>Tất cả</option>
                        <option key={2} value={1}>Tiền mặt</option>
                        <option key={3} value={2}>Chuyển khoản</option>
                        <option key={4} value={3}>Quẹt thẻ</option>
                        <option key={5} value={4}>Công nợ</option>
                    </Select>
                    </Form.Item>
                    <Form.Item>
                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                            showTime={{ format: 'HH:mm'}}
                            allowClear={false} format="DD-MM-YYYY HH:mm " value={this.state.ngay}
                            placeholder={['DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm']}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={(value => this.setState(  { ngay: value }, () =>  {
                                    this.LoadThongKeDoanhSo() 
                                    if(this.PatientReport != null){
                                        this.PatientReport.LoadThongKeDoanhSo()
                                    }
                                }))}
                            
                        />
                    </Form.Item>
                    <Form.Item>
                        <button className="btn btn-color" onClick={() => { 
                            this.LoadThongKeDoanhSo()
                            if(this.PatientReport != null){
                                this.PatientReport.LoadThongKeDoanhSo()
                            }
                         }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                    </Form.Item>
                </div>
            </div>
            </>
        )
        
        return (
            <>
                <div className='containerChilderWrapper'>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div>
                        <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Form.Item>
                                <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                    showTime={{ format: 'HH:mm'}}
                                    allowClear={false} format="DD-MM-YYYY HH:mm " value={this.state.ngay}
                                    placeholder={['DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm']}
                                    placement="bottomRight"
                                    onChange={(value => this.setState(  { ngay: value }, () =>  {
                                            this.LoadThongKeDoanhSo() 
                                            if(this.PatientReport != null){
                                                this.PatientReport.LoadThongKeDoanhSo()
                                            }
                                        }))}
                                    
                                />
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-color" onClick={() => { 
                                    this.LoadThongKeDoanhSo()
                                    if(this.PatientReport != null){
                                        this.PatientReport.LoadThongKeDoanhSo()
                                    }
                                }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                            </Form.Item>
                        </div>
                    </div>
                    <Tabs animated={true}
                        style={{ height: "100%" }}>
                        <Tabs.TabPane tab={"Theo lượt khám"} key="1">
                            <div style={{ height: '100%', overflow: 'auto' }}>
                                <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom:10 }}>
                                    {/* {this.state.isMultisite === true &&
                                        <div><Form.Item>
                                            <Select
                                                style={{ width: '200px' }}
                                                showSearch
                                                allowClear={false}
                                                value={this.state.chinhanh}
                                                onChange={(e) => {
                                                    this.setState({ chinhanh: e })
                                                }}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option key={-1} value={''}>Chi nhánh</Option>
                                                {this.state.listSite.map((row, index) => {
                                                    return (
                                                        <Option key={index} value={row.id}>{row.ma}</Option>
                                                    )
                                                })}
                                            </Select></Form.Item>
                                        </div>
                                    } */}
                                    <Form.Item>
                                        <button className="btn btn-color" onClick={() => { this.ExportThongKeDoanhSo() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                    </Form.Item>
                                </div>
                                <div style={{ height: 'calc(100% - 160px)' }}>
                                    <Table
                                        scroll={{ x: 'max-content', y: 0 }}
                                        pagination={{
                                            position: ['bottomCenter'],
                                            showSizeChanger: true,
                                            style: { textAlignLast: "center", marginTop: 30 },
                                            total: this.state.totalRecords,
                                            showTotal: (total) => (`Tổng: ${total}`)
                                        }}
                                        dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                                </div>

                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"Chi tiết dịch vụ"} key="2">
                            <TheoDichVu ref={(c)=>{ this.PatientReport = c }}  ngay={this.state.ngay} paymenttype={this.state.paymentType} />
                        </Tabs.TabPane>
                    </Tabs>
                    
                </div>
            </>
        )
    }
}
