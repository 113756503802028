import { DatePicker, Form, Modal, notification, Popconfirm, Select, Table, Tooltip, Button, Input } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import AutocompleteTable from '../../components/autocompletetable';
import * as ChiDinhService from '../../services/chiDinhService';
import * as Actions from '../../libs/actions';
import TaoLichHen from './taolichhen';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from '../../services/commonService';
import ModalCLS from '../../components/MDCLS';
import './lichhen.css';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import { connect } from 'react-redux';
import Nguonkhachhang from '../recept/register/nguonkhachhang';
import * as PatientService from '../../services/patientService';
import { isNullOrEmpty } from '../../libs/util';
import HeaderModal from '../../configureHIS/common/headerModal';

moment.locale('vi_vn');
const localizer = momentLocalizer(moment);
const { Option } = Select;
const { TextArea } = Input;
class CalendarToolbar extends Toolbar {
    constructor(props) {
        super(props);
        this.state = {
            func: null,
            mabs: '',
            btnKey: 'month',
            banggia: 'default'
        }
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-4 mb-2">
                    <div className="btn-group mr-2" role="group">
                        <button className={`btn btn-appointment ${this.state.btnKey === 'PREV' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'PREV' }); this.navigate('PREV') }}><i className="fa fa-angle-left"></i></button>
                        <button className={`btn btn-appointment ${this.state.btnKey === 'NEXT' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'NEXT' }); this.navigate('NEXT') }}><i className="fa fa-angle-right"></i></button>
                    </div>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'TODAY' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'TODAY' }); this.navigate('TODAY') }}>Hôm nay</button>
                </div>
                <div className="col-md-4 mb-2 text-center align-self-center">
                    <div className="h4 text-uppercase">{this.props.label}</div>
                </div>
                <div className="col-md-4 mb-2 text-right">
                    <button className={`btn btn-appointment ${this.state.btnKey === 'month' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'month' }); this.view('month', null) }}>Tháng</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'week' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'week' }); this.view('week', null) }}>Tuần</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'day' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'day' }); this.view('day', null) }}>Ngày</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'agenda' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'agenda' }); this.view('agenda', null) }}>Lịch</button>
                </div>
            </div>
        );
    }
}

class LichHen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: moment(),
            selectedValue: moment(),
            listTest: [],
            lstHen: [],
            visible: false,
            lstEvent: [],
            lstDoctor: [],
            mabs: 0,
            groupID: 0,
            lstCreate: [],
            create: '',
            editAppointmentModalFlag: false,
            inEditmode: false,
            selectedAppointment: {},
            selectedPatient: {},
            selectedDate: {},
            lstPhong: [],
            maphongkham: '',
            mabskham: [],
            lstChiDinh: [],
            visibleCLS: false,
            banggia: 'default',
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            site: 1,
        };

    }
    notifyUpdate = () => {
        this.loadDanhSachLichHen();
    }

    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        this.GetBangGiaDefaultSite();
        this.GetListSite();
        this.loadDanhSachLichHen();
        this.DanhSachBacSi();
        this.ListUserCreate();
        this.DanhSachPhongKham();
        let group = localStorage.getItem('groupid');
        this.setState({
            groupID: Number(group)
        })
    }

    GetBangGiaDefaultSite() {
        ConfigService.BangGiaDefaultSite(this.props.siteid).then((respone) => {
            if (respone.isSuccess) {
                let banggia = respone.item ? respone.item : 'default';
                this.setState({
                    banggia: banggia,
                });
            }
        }).catch(err => { })
    }

    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    getListData(value) {
        let listData;
        let { lstHen } = this.state;

        if (lstHen && lstHen.length > 0) {

            listData = lstHen.filter(x => (moment(x.ngayhen).date() === value.date()) && (moment(x.ngayhen).month() === value.month()));
        }


        return listData || [];
    }
    searchIndication(key) {
        return ChiDinhService.searchSerivce(0, 0, this.state.banggia, key, 1, 20);
    }

    onSelectIndication(item) {
        // this.modalCLS.AddService(item);

        const { lstChiDinh } = this.state;
        var existed = lstChiDinh.find(x => x.id === item.id);
        if (existed === undefined) {
            item.banggia = "default";
            item.soluong = 1;
            item.idChiDinh = "0";
            let total = 0;
            lstChiDinh.push(item)
            lstChiDinh.map(x => total += (x.dongia * x.soluong) * ((100 - x.discount) / 100))
            this.setState({
                lstChiDinh: lstChiDinh,

            })
        }
    }


    DanhSachPhongKham() {
        CommonService.GetDepartment().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhong: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phòng khám bị lỗi!' })
        })
    }
    loadDanhSachLichHen(e = 0, create = "") {
        AppointmentService.DanhSachHenKham(e, this.props.siteid, create).then(result => {
            let lstEvent = []
            if (result.isSuccess) {
                for (let item of result.data) {
                    var event = {
                        id: item.id,
                        tenbn: item.tenbn,
                        mabn: item.mabn,
                        ngayhen: item.ngayhen,
                        title: item.tenbn + "-" + item.mabn,
                        start: moment(item.ngayhen).toDate(),
                        end: moment(item.ngayhen).add(1, 'hours').toDate(),
                        arrived: item.daden,
                        maql: item.managementID,
                    }
                    lstEvent.push(event)
                }
                this.setState({
                    lstHen: result.data, lstEvent, inEditmode: false,
                    editAppointmentModalFlag: false, selectedAppointment: {}, selectedPatient: {}
                })

            }
        })
    }
    DanhSachBacSi() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoctor: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi!' })
        })
    }

    ListUserCreate() {
        AppointmentService.ListUserCreate().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstCreate: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi!' })
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };


    handleCancel = e => {
        this.setState({
            visible: false,
        });
        this.loadDanhSachLichHen(this.state.mabs, this.state.create);
    };

    disabledDate(current) {
        // let customDate = moment().add(1, 'days').format('yyyy-MM-dd').toString();
        // return current && current > moment(customDate, "yyyy-MM-dd'");
        return current && current.valueOf() < Date.now();
    }

    onSelectEvent = record => {
        this.setState({
            arrived: record.arrived,
        })
        AppointmentService.GetAppoimentDetailByID(record.id, this.props.siteid).then(result => {
            if (result.isSuccess) {
                let data = result.item.lstChiDinh;
                this.setState({
                    selectedDate: result.item.ngayhen,
                    selectedPatient: result.item,
                    selectedAppointment: record,
                    lstChiDinh: data,
                    editAppointmentModalFlag: true,
                    maphongkham: result.item.makp,
                    mabskham: (result.item.mabs || result.item.mabs !== "0") ? result.item.mabs : '',
                    banggia: data.find(x => x.banggia !== "default") ? data.find(x => x.banggia !== "default").banggia : "default",
                    site: result.item.chinhanh
                })
                if (this.state.lstDoctor.findIndex(x => x.value === result.item.mabs) < 0) {
                    let lstBS = this.state.lstDoctor;
                    lstBS.unshift({ value: result.item.mabs, label: `${result.item.mabs}-${result.item.tenbs}` })
                    this.setState({ lstDoctor: lstBS.map(x => ({ ...x })) })
                }

            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })

    };
    updateAppointment() {
        let { selectedAppointment, selectedDate } = this.state;
        const data = {
            ngayhen: selectedDate,
            makp: this.state.maphongkham,
            mabs: this.state.mabskham,
            lstChiDinh: this.state.lstChiDinh,
            mabn: selectedAppointment.mabn,
            maql: this.state.selectedPatient.maql.toString(),
            site: this.state.site.toString(),
        }
        AppointmentService.updateAppointment(selectedAppointment.id, data, this.props.siteid).then((result) => {
            if (result.isSuccess) {
                this.nguonKhachHang && this.nguonKhachHang.updateInfo();
                notification.success({ message: "Cập nhật thàng công" })
                this.loadDanhSachLichHen(this.state.mabs, this.state.create);
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
    }
    deleteAppointment() {
        let { selectedAppointment } = this.state;
        AppointmentService.deleteAppointment(selectedAppointment.id, this.props.siteid).then((result) => {
            if (result.isSuccess) {
                this.loadDanhSachLichHen(this.state.mabs, this.state.create);
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })

    }

    dateCellRender(value) {
        const listData = this.getListData(value);
        return (
            <React.Fragment>
                {listData.map(item => (
                    <div>
                        <span>BN:{item.tenbn} lúc {moment(item.ngayhen).format('HH:mm')}</span>
                    </div>

                ))}
            </React.Fragment>
        );
    }
    getColumns() {
        return [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => (Number(index) + 1),
            },
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Tên BN',
                dataIndex: 'tenbn',
                key: 'tenbn',
            },
            {
                title: 'Dịch vụ',
                dataIndex: 'tenchidinh',
                key: 'tenchidinh',
            },
            {
                title: 'Giờ',
                dataIndex: 'ngayhen',
                key: 'ngayhen',
                render: (value) => {
                    return <span>Lúc <strong>{moment(value).format('HH:mm')}</strong></span>;
                }
            },
            {
                title: 'Bác sĩ',
                dataIndex: 'tenbs',
                key: 'tenbs',
            },
            {
                title: 'Phòng khám',
                dataIndex: 'tenphongkham',
                key: 'tenphongkham',
            },
            {
                title: 'Người tạo',
                dataIndex: 'tennguoitao',
                key: 'tennguoitao',
            },
        ];
    }

    eventStyleGetter(e, start, end, isSelected) {
        var backgroundColor = 'white'
        var color = 'black'
        var diff = moment().diff(e.ngayhen, 'minutes')
        if (e.arrived === 1) {
            backgroundColor = 'rgb(40,167,69)'
            color = 'white'
        } else {
            if (diff > 0) {
                backgroundColor = 'rgba(32,32,32,0.7)'
                color = 'white'
            } else {
                if (diff > -60) {
                    backgroundColor = 'rgba(240,240,67,0.8)'
                    color = 'black'
                } else {
                    backgroundColor = 'rgba(242,242,242,0.8)'
                    color = 'black'
                }
            }
        }

        var style = {
            fontSize: 12,
            fontFamily: 'Averta',
            cursor: 'pointer',
            backgroundColor: backgroundColor,
            color: color
        };
        return {
            style: style
        };
    }
    ConfirmArrived() {
        const data = {
            id: this.state.selectedAppointment.id,
            ngayhen: this.state.selectedDate,
            makp: this.state.maphongkham,
            mabs: this.state.mabskham,
        }
        AppointmentService.ConfirmArrived(data, this.props.siteid).then((result) => {
            if (result.isSuccess) {
                this.loadDanhSachLichHen(this.state.mabs, this.state.create);
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
    }

    XoaChiDinh(record, index) {
        let { lstChiDinh } = this.state;
        lstChiDinh.splice(index, 1);
        this.setState(lstChiDinh);
    }


    getListChiDinh(data) {
        this.setState({ lstChiDinh: data });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteid !== this.props.siteid) {
            this.loadDanhSachLichHen();
            this.DanhSachPhongKham();
        }
    }

    checkBangGia = (info) => {
        let banggia = this.state.banggia;
        let itemFound = this.state.lstChiDinh.find(x => x.banggia !== "" && x.banggia !== null && x.banggia !== undefined);
        let banggianguonkhachhang = this.state.banggia;
        if (info.banggia !== null) {
            banggianguonkhachhang = info.banggia
        }
        if (itemFound !== undefined) banggia = itemFound.banggia;
        if (itemFound !== undefined && banggianguonkhachhang !== banggia) {
            if (this.state.lstChiDinh.length > 0) {
                Actions.openMessageDialog("Thông báo",
                    `Việc thay đổi nguồn khách hàng (Bảng giá <b>${banggianguonkhachhang === "default" ? "Mặc định" : banggianguonkhachhang}</b>) đã xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`)
                this.cancelExam();
                banggia = banggianguonkhachhang;
            }
            this.setState({ banggia });
        } else this.setState({ banggia: banggianguonkhachhang });
    }

    cancelExam() {
        this.setState({
            lstChiDinh: []
        })
    }

    resetStateNguonKhachHang = () => {
        this.nguonKhachHang && this.nguonKhachHang.resetState();
    }

    render() {
        let { selectedAppointment, inEditmode, selectedPatient, selectedDate, lstChiDinh } = this.state;
        const regiterServiceColumn = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => (Number(index) + 1),
                align: 'center',
                width: '8%'
            },
            {
                title: 'Mã',
                dataIndex: 'mavp',
                key: 'mavp',
                width: '10%'
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'ten',
                key: 'ten',
                width: '40%'
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value) => (<span>{value ? value.toLocaleString('en-US') : '0'}</span>),
                width: 120
            },
            {
                title: 'Số lượng',
                render: (row) => (
                    <span>{1}</span>
                    // row.status === 'Đã đóng tiền' ? <span>{row.soluong}</span> :
                    //     <InputNumber style={{ width: '60px' }} value={row.soluong} min={1} max={99} maxLength={2} onChange={(e) => {
                    //         if (e === null) {
                    //             this.editAmount(row, 1)
                    //         } else
                    //             this.editAmount(row, e)
                    //     }} />
                ),
                align: 'center',
                width: '15%'
            },
            {
                title: 'Thành tiền',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 100,
                render: (value, row) => (<Tooltip title={row.dongia && row.dongia.length > 0 && "Thuộc chương trình tặng dịch vụ"}><span>{(value).toLocaleString('en-US')}</span></Tooltip>),
            },
            {
                title: '',
                render: (text, record, index) => {
                    return (
                        <div>
                            {inEditmode === false ? <button disabled={inEditmode === false} className={`btn btn-danger`} onClick={() => {
                                this.XoaChiDinh(record, index)
                            }} >
                                <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                            </button> :
                                <Tooltip title={record.voucher && record.voucher.length > 0 && "Thuộc chương trình tặng dịch vụ"}>
                                    <button className={`btn btn-danger`} onClick={() => {
                                        this.XoaChiDinh(record, index)
                                    }} >
                                        <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                                    </button>
                                </Tooltip>
                            }

                        </div>
                    )
                },
                align: 'center',
                width: '10%'
            }
        ];
        const columnsSearh = [
            {
                title: 'Mã Dịch vụ',
                dataIndex: 'mavp',
                key: 'mavp',
                width: 70
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'ten',
                key: 'ten',
                width: 300,
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
                width: 70,
                format: 'number',
            },
        ]
        let dataSource = lstChiDinh.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })

        return (
            <>
                <div style={{ height: '100%' }}>
                    <div className='containerChilderWrapper' >
                        <div className='h3 text-center'>Lịch hẹn</div>
                        <div style={{ padding: 10, flex: 1, marginBottom: 10, backgroundColor: '#fff' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Item label="Xem lịch:" style={{ display: 'none' }}>
                                    <Select style={{ width: 350, marginLeft: 35 }}
                                        hidden={(this.state.groupID === 1 || this.state.groupID === 2) ? false : true}
                                        placeholder="Chọn bác sĩ"
                                        defaultValue="0"
                                        onChange={(e) => { this.setState({ mabs: e }); this.loadDanhSachLichHen(e, this.state.create) }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    ><Option key="" value="0">-----Chọn tất cả-----</Option>
                                        {this.state.lstDoctor.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <div className="col-auto">
                                    <Select
                                        style={{ width: '300px' }}
                                        onChange={(e) => { this.setState({ create: e }); this.loadDanhSachLichHen(this.state.mabs, e) }}
                                        showSearch
                                        defaultValue=""
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    ><Option key="" value="">-----Chọn người tạo-----</Option>
                                        {this.state.lstCreate.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                {/* <div className="col-auto">
                                    {this.state.isMultisite === true &&
                                        <Select
                                            style={{ width: '200px' }}
                                            showSearch
                                            allowClear={false}
                                            value={this.props.siteid}
                                            onChange={(e) => {
                                                this.setState({ chinhanh: e }, () => { this.loadDanhSachLichHen(this.state.mabs, this.state.create) })
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option key={-1} value={''}>Chi nhánh</Option>
                                            {this.state.listSite.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.id}>{row.ma}</Option>
                                                )
                                            })}
                                        </Select>
                                    }
                                </div> */}
                                <div className="col-auto">
                                    <Button type="primary" className="btn btn-color"
                                        onClick={() => this.showModal()}>
                                        <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo lịch hẹn</Button>
                                </div>
                                <div className="col-auto">
                                    <Tooltip title="Tải lại trang">
                                        <Button className="btn btn-secondary"
                                            onClick={() => this.loadDanhSachLichHen(this.state.mabs, this.state.create)}>
                                            <i className="fa fa-sync" /></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 100px)', backgroundColor: '#fff', }}>
                            <div style={{ height: '100%' }}>
                                <Calendar
                                    selectable
                                    localizer={localizer}
                                    components={
                                        {
                                            toolbar: CalendarToolbar,
                                            event: this.getCustomEvent,
                                        }
                                    }
                                    onSelectEvent={this.onSelectEvent}
                                    events={this.state.lstEvent}
                                    startAccessor="start"
                                    endAccessor="end"
                                    eventPropGetter={(this.eventStyleGetter)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.visible && <Modal maskClosable={false}
                    width={1600}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    okButtonProps={{ disabled: true, hidden: true }}
                    cancelButtonProps={{ style: { display: 'none' } }}>
                    <TaoLichHen
                        onCancel={this.handleCancel}
                        layMau={false}
                        chinhanh={this.props.siteid}
                    />
                </Modal>}
                {this.state.editAppointmentModalFlag &&
                    <Modal maskClosable={false}
                        width={1000}
                        visible={this.state.editAppointmentModalFlag}
                        onCancel={() => this.setState({ editAppointmentModalFlag: false, inEditmode: false }, () =>
                            this.resetStateNguonKhachHang()
                        )}
                        okButtonProps={{ disabled: true, hidden: true }}
                        cancelButtonProps={{ style: { display: 'none' } }}>
                        <div>
                            <HeaderModal
                                title="Chi tiết lịch hẹn"
                                onClose={() => {
                                    this.setState({
                                        editAppointmentModalFlag: false,
                                        selectedAppointment: {},
                                        selectedPatient: {},
                                        inEditmode: false
                                    })
                                }} />
                            <div className="row">
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Tên bệnh nhân:</span><b>{selectedAppointment.tenbn}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Mã bệnh nhân:</span><b>{selectedAppointment.mabn}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Ngày sinh:</span><b>{selectedPatient.onlyyear ? "Không ghi nhận" : moment(selectedPatient.ngaysinh).format("DD-MM")}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Năm sinh:</span><b>{moment(selectedPatient.ngaysinh).format("YYYY")}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Giới tính:</span><b>{selectedPatient.gioitinh === 0 ? "Nam" : "Nữ"}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>CMND:</span> <b>{selectedPatient.cmnd}</b></div>
                                <div className="col-12 d-flex mb-2"><span style={{ width: '100px' }}>Địa chỉ:</span><b>{selectedPatient.diachi}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Điện thoại:</span><b>{selectedPatient.dienthoai}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Thời gian:</span>
                                    <DatePicker locale={viVN} style={{ width: '200px' }} allowClear={false} showTime format={"DD-MM-YYYY HH:mm"} onChange={(e) => { this.setState({ selectedDate: e }) }} value={moment(selectedDate)}
                                        disabledDate={this.disabledDate} disabled={inEditmode === false} placeholder="dd-MM-YYYY" />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <Nguonkhachhang ref={(c) => this.nguonKhachHang = c} managermentId={selectedPatient.maql} updateInfoBangGia={(info) => { this.checkBangGia(info) }} banggiaSite={this.state.banggia} />
                            </div>
                            <div className='row'>
                                <div className="col-12">
                                    <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle' /></Tooltip></label>
                                    <Select
                                        placeholder="Chọn chi nhánh"
                                        disabled={inEditmode === false}
                                        style={{ width: '100%' }}
                                        value={this.state.site}
                                        onChange={(value => this.setState({ site: value }))}
                                    >
                                        {this.state.listSite && this.state.listSite.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.ten}</option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-12 d-flex my-2"><span style={{ width: '100px' }}>Ghi chú:</span><b>{selectedPatient.ghichu}</b></div>
                                {!isNullOrEmpty(selectedPatient.ghichuHotline) && <div className="col-12 d-flex mb-2">
                                    <label>Ghi chú hotline:</label>
                                    <TextArea rows={3} value={selectedPatient.ghichuHotline} readOnly={true}></TextArea>
                                </div>}
                            </div>
                            <div style={{ display: 'flex', alignItems: "center", marginTop: 10 }}>
                                <Tooltip placement="top" title={`Bảng giá: ${this.state.banggia === 'default' ? "Mặc định" : this.state.banggia}`}>
                                    <Form.Item label="">
                                        <AutocompleteTable
                                            ref={(c) => (this.listServicesRef = c)}
                                            width="300px"
                                            disabled={inEditmode === false}
                                            placeholder="Nhập tên dịch vụ để tìm kiếm"
                                            tablewidth={500}
                                            listHeight={"50vh"}
                                            onSearch={(value) => this.searchIndication(value)}
                                            onSelect={(item) => this.onSelectIndication(item)}
                                            value={this.state.serKey} headers={columnsSearh}
                                            keyValue={'id'}
                                        >
                                        </AutocompleteTable>
                                    </Form.Item>
                                </Tooltip>
                                <Form.Item label="">
                                    <Tooltip title="Mở danh sách dịch vụ">
                                        <Button style={{ marginLeft: 10, borderRadius: 8 }}
                                            disabled={inEditmode === false}
                                            onClick={() => {
                                                this.setState({
                                                    visibleCLS: true
                                                }, () => {
                                                    this.modalCLS.UpdateSelectedFromProps(this.state.lstChiDinh)
                                                })
                                            }}> <i className={"fas fa-list"} /></Button>
                                    </Tooltip>
                                </Form.Item>
                            </div>

                            <div className="mt-2" style={{ height: 'calc(100% - 500px)' }} >
                                <Table dataSource={dataSource}
                                    rowKey={(e) => e.id}
                                    columns={regiterServiceColumn}
                                    pagination={false}
                                    scroll={{ y: 0 }}
                                />
                            </div>
                            <div className='custom-hr'></div>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                {(this.state.arrived !== 1 && !inEditmode) &&
                                    <Popconfirm
                                        title="Xác nhận bệnh nhân đến khám!"
                                        onConfirm={() => { this.ConfirmArrived() }}
                                        okText="Đồng ý" cancelText="Hủy"
                                        disabled={inEditmode}>
                                        <button className="btn btn-color" style={{ marginRight: 10 }}><i style={{ marginRight: 5 }} className="fa fa-save"></i>Xác nhận đến</button>
                                    </Popconfirm>
                                }
                                {this.state.arrived !== 1 && <div>{inEditmode === false ?

                                    <button className="btn btn-color" onClick={() => this.setState({ inEditmode: true })}><i style={{ marginRight: 5 }} className="fa fa-edit"></i>Cập nhật</button>
                                    :
                                    <button className="btn btn-color" onClick={() => this.updateAppointment()}><i style={{ marginRight: 5 }} className="fa fa-save"></i>Lưu</button>
                                }
                                </div>}
                                {this.state.arrived !== 1 && <Popconfirm
                                    title="Bạn có chắc muốn xóa lịch hẹn này không ??"
                                    onConfirm={() => { this.deleteAppointment() }}
                                    okText="Đồng ý" cancelText="Hủy">
                                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    >
                                        <i style={{ marginRight: 5 }} className="fa fa-trash" />Xóa</button>
                                </Popconfirm>
                                }
                                <button className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => this.setState({
                                    editAppointmentModalFlag: false, selectedAppointment: {}, selectedPatient: {}, inEditmode: false
                                }, () => this.resetStateNguonKhachHang())}><i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>

                            </div>
                        </div>
                    </Modal>
                }
                {this.state.visibleCLS &&
                    <Modal maskClosable={false}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}
                        visible={this.state.visibleCLS}
                        width={1500}
                    >
                        <ModalCLS
                            hidePhongKham={true}
                            maskClosable={false}
                            ref={(c) => this.modalCLS = c}
                            editAfterRegister={true}
                            isnew={true}
                            patientID={this.state.patientID}
                            getListChiDinh={this.getListChiDinh.bind(this)}
                            onClose={(lstChiDinh) => {
                                lstChiDinh = lstChiDinh.map(x => ({ ...x }))
                                if (lstChiDinh === null) lstChiDinh = [];
                                let total = 0;
                                this.state.lstChiDinh.concat(lstChiDinh).map(x => total += (x.dongia * x.soluong) * ((100 - x.discount) / 100))
                                this.setState({ visibleCLS: false, lstChiDinh, total: total })
                            }}
                            banggia={this.state.banggia}
                            onReplaceBangGia={(tenBangGia) => {
                                if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
                                    this.setState({
                                        banggia: tenBangGia,
                                    });
                                    this.listServicesRef.handleSearch(this.searchIndication());
                                }
                            }}
                            // disableSelectBangGia={true}
                        />
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(LichHen);