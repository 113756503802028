import * as ApiCaller from '../../libs/httpRequests';
const prefix='Distribution';

export function getListDistribution(date, key, page, size, assigned, mactv, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListDistribution?date=${date}&key=${key}&page=${page}&size=${size}&assigned=${assigned}&ctv=${mactv}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListCollaborators(date, getAll, group, chinhanh = "") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListCollaborators?date=${date}&all=${getAll}&group=${group}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};