import React, { Component } from 'react';
import { Form, Input, Select, Table, notification, Switch, Modal, Pagination, InputNumber, Popconfirm } from 'antd';
import * as VPService from '../services/VPService';
import * as Actions from '../../../libs/actions';
import CreateNew from '../components/createNewLoaiVP';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';

const { Option } = Select;
let task = null;
const initialState = {
    totalRecords: 0,
    visible: false,
    visibleCreate: false,
    loadingHide: false
}


export default class DanhSachLoaiVP extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            key: '',
            manhomvp: 0,
            maloai: '',
            id_loai: 0,
            id_nhom: 0,
            lstNhomVP: [],
            lstLoaiVP: [],
            currentPage: 1,
            pageSize: 10,
            hien_thi: '',
            viettatloai: '',
            order: 0,
        }
    }
    componentDidMount() {
        this.loadNhomVP();
        this.loadDanhSachLoaiVP();
    }
    loadNhomVP() {
        VPService.GetNhomVP().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstNhomVP: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm viện phí bị lỗi' });
        })

    }
    loadDanhSachLoaiVP(page = this.state.currentPage, size = this.state.pageSize) {
        VPService.GetDanhSachLoaiVP(this.state.manhomvp, this.state.key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiVP: result.data,
                    totalRecords: result.totalRecords
                });
            }
        }).catch(err => {
        })
    }
    componentWillUnmount() {
        if (task) {
            clearTimeout(task);
        }
    }
    handleOK = () => {
        this.setState({
            visible: false,
            visibleCreate: false
        });
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            visibleCreate: false
        });
    }
    onReload() {
        this.loadNhomVP();
        this.loadDanhSachLoaiVP();
    }
    showModalCreate = () => {
        this.setState({
            visibleCreate: true
        });
    }
    updateHide(malvp, check) {
        this.setState({ loadingHide: true })
        let status = 0;
        if (check === false) {
            status = 1;
        }
        VPService.UpdateHideLoaiVP(malvp, status).then(result => {
            let data = this.state.lstLoaiVP;
            let item = data.find(x => x.id_loai === malvp);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hien_thi === 1 ? 0 : 1;
                    item.hien_thi = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hien_thi;
                    item.hien_thi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstLoaiVP: data,
                loadingHide: false,
            })
        }).catch(err => {
            let data = this.state.lstLoaiVP;
            let item = data.find(x => x.id_loai === malvp);
            if (item !== undefined) {
                const newcheck = item.hien_thi;
                item.hien_thi = newcheck;
            }
            this.setState({ loadingHide: false, lstLoaiVP: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    updateLoaiVP() {
        if (this.validator.fieldValid('Mã loại viện phí') && this.validator.fieldValid('Tên loại viện phí')) {
            const data = {
                'ma': this.state.maloai,
                'tenloaivp': this.state.tenloaivp,
                'viettatloai': this.state.viettatloai,
                'id': this.state.id_loai,
                'id_nhom': this.state.id_nhom,
                'thutu': this.state.order,
            }
            VPService.updateLoaiVP(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    let list = this.state.lstNhomVP;
                    this.onReload();
                    this.handleCancel();
                    this.setState({ lstNhomVP: list, order: 0 });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    deleteLoaiVP(id_loai) {
        VPService.deleteLoaiVP(id_loai).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                let list = this.state.lstLoaiVP;
                this.setState({ lstLoaiVP: list });
                this.onReload();
                this.handleCancel();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
        })
    }

    render() {
        const columns = [
            {
                title: 'STT',
                width: 80,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1),
            },
            {
                title: 'Nhóm',
                dataIndex: 'ten_nhom',
                key: 'ten_nhom',
                align: 'center',
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'label',
                key: 'label',
                width: '20%',
                align: 'left',
            },
            {
                title: 'Thứ tự hiển thị',
                dataIndex: 'thutu',
                key: 'thutu',
                width: '120',
                align: 'center',
            },
            {
                title: 'Hiển thị',
                align: 'center',
                render: (row) => {
                    return (
                        <Switch loading={this.state.loadingHide} checkedChildren="Hiển thị" unCheckedChildren="Ẩn" checked={row.hien_thi === 0}
                            onChange={(e) => {
                                this.updateHide(row.id_loai, e);
                            }} />
                    )
                }
            },
            {
                title: 'Action',
                align: 'center',
                render: (row) => {
                    return (
                        <div>
                            <button className="btn btn-warning"
                                title="Cập nhật nhóm viện phí!"
                                onClick={() => { this.setState({ id_loai: row.id_loai, id_nhom: row.id_nhom, viettatloai: row.viet_tat, tenloaivp: row.label, maloai: row.ma, visible: true, order: row.thutu }); }} >
                                <i style={{ marginRight: 10 }} className="fa fa-edit"></i>Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.deleteLoaiVP(row.id_loai) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: "15px" }}
                                ><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        let dataSource = this.state.lstLoaiVP.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div className='containerChilderWrapper'  >
                        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                        <div className='h4 text-center mt-2 mb-3 font-bold text-uppercase'>Cấu hình danh mục loại viện phí</div>
                        <div
                            className="row no-gutters col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Form.Item>
                                <span style={{ marginRight: 10 }}>Nhóm viện phí</span>
                                <Select style={{ width: "200px" }}
                                    value={this.state.manhomvp}
                                    onChange={(e) => {
                                        this.setState({ manhomvp: e });
                                        task = setTimeout(() => { this.loadDanhSachLoaiVP(1, this.state.pageSize) }, 300);
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={0}>Tất cả</Option>
                                    {this.state.lstNhomVP.map((row, index) => {
                                        return (
                                            <Option
                                                key={index}
                                                value={row.value}
                                            >
                                                {row.label}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    style={{ width: 300 }}
                                    placeholder="Nhập mã hoặc tên loại viện phí"
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={
                                        () => {
                                            task = setTimeout(() => { this.loadDanhSachLoaiVP(1, this.state.pageSize) }, 300);
                                        }
                                    }
                                />
                            </Form.Item>
                            <Form.Item >
                                <button
                                    className="btn btn-success"
                                    onClick={this.showModalCreate} >
                                    <i style={{ marginRight: 10 }} className="fa fa-plus" />Tạo mới
                                </button>
                                <button className="btn btn-color"
                                    disabled
                                    style={{ marginLeft: 10, display: 'none', visibility: false }}
                                    onClick={() => { this.ExportDanhMucDichVu() }} >
                                    <i style={{ marginRight: 10 }} className="fas fa-download" />Xuất excel
                                </button>
                            </Form.Item>
                        </div>
                        <div style={{ paddingTop: 20, height: 'calc(100% - 130px)' }}>
                            <Table
                                dataSource={dataSource}
                                scroll={{ y: 0 }}
                                pagination={false}
                                columns={columns}
                                rowKey={(e, index) => index} />
                        </div>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({
                                    currentPage: page,
                                    pageSize: size
                                });
                                this.loadDanhSachLoaiVP(page, size);
                            }}
                            onShowSizeChange={(page, size) => {
                                this.setState({
                                    currentPage: 1,
                                    pageSize: size
                                });
                                this.loadDanhSachLoaiVP(1, size)
                            }}
                            showSizeChanger={true}
                        />

                    </div>
                </Form>
                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Cập nhật loại viện phí</h3>
                    </div>
                    <div className="row no-gutters" style={{ marginBottom: 10, display: 'flex', justifyContent: 'center' }} >
                        <div className="col-md-12">
                            <Form.Item label="Nhóm viện phí:">
                                <Select
                                    placeholder="Chọn nhóm viện phí"
                                    value={this.state.id_nhom}
                                    onChange={(e) => {
                                        this.setState({ id_nhom: e });
                                    }}
                                >
                                    {this.state.lstNhomVP.map((row, index) => {
                                        return (
                                            <Option
                                                key={index}
                                                value={row.value}
                                            >
                                                {row.label}
                                            </Option>
                                        )
                                    })}
                                </Select>
                                {this.validator.message('Mã nhóm viện phí', this.state.manhomvp, 'required')}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10, justifyContent: 'center' }}>
                        <div className="col-md-12">
                            <Form.Item label="Tên loại:">
                                <Input
                                    placeholder="Tên loại viện phí"
                                    value={this.state.tenloaivp}
                                    onChange={(e) => {
                                        this.setState({ tenloaivp: e.target.value })
                                    }} />
                                {this.validator.message('Tên loại viện phí', this.state.tenloaivp, 'required')}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center' }} >
                        <div className="col-md-6">
                            <Form.Item label="Mã loại:">
                                <Input
                                    placeholder="Mã loại viện phí"
                                    value={this.state.maloai}
                                    onChange={(e) => {
                                        this.setState({ maloai: e.target.value })
                                    }} />
                                {this.validator.message('Mã loại viện phí', this.state.maloai, 'required')}
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item label="Tên viết tắt:">
                                <Input
                                    placeholder="Tên viết tắt"
                                    value={this.state.viettatloai}
                                    onChange={(e) => this.setState({ viettatloai: e.target.value })}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-md-12 mt-2">
                            <Form.Item label="Thứ tự:">
                                <InputNumber
                                    style={{ width: "100%" }}
                                    min={0}
                                    onKeyDown={(e) => {
                                        if (e.key === 'e') {
                                            e.preventDefault();
                                        }
                                    }}
                                    value={this.state.order}
                                    onChange={(e) => {
                                        this.setState({ order: e })
                                    }} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                        <button
                            className="btn btn-success"
                            onClick={() => this.updateLoaiVP()}>
                            <i className="fa fa-save" style={{ marginRight: 5 }} />
                            Cập nhật
                        </button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.setState({ visible: false });
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </Modal>
                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visibleCreate}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateNew
                        onCancel={this.handleCancel}
                        onReload={this.onReload.bind(this)} />
                </Modal>
            </React.Fragment>
        )
    }
}