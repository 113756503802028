import * as ApiCaller from '../libs/httpRequests';
const prefix = 'VatTuYTe';

export function NhapKhoVTYT(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/nhapkhovattu`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function LuuDonXuat(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/xuatkhovtyt`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinHoaDonXuat(ngay, id, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/detailxuatkho?ngay=${ngay}&id=${id}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetVatTuTrongKho(makho,maloai="") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/vattutrongkho?makho=${makho}&maloai=${maloai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
// export function SearchXuatKhoVTYT(ngay, key) {
//     return new Promise((resolve, reject) => {
//         return ApiCaller.httpGet(`${prefix}/searchxuatkhovtyt?ngay=${ngay}&key=${key}`).then(respone => {
//             return resolve(respone)
//         }).catch(err => {
//             return reject(err)
//         })
//     });
// };

export function GetSLTonKhoTheoID(makho, mathuoc) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/vattutonkhotheolo?makho=${makho}&mathuoc=${mathuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
