import { notification, Popconfirm, Statistic, InputNumber, Input, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import EditableTable from '../../../../commons/editableTable';
import { ValidationCustom } from '../../../../commons/validationCustom';
// import * as     Administrative from '../../../services/administrativeService';
import AutocompleteTable from '../../../../components/autocompletetable';
import Administrative from '../confirm/patientdetails';
import * as Actions from '../../../../libs/actions';
import * as ChiDinhService from '../../../../services/chiDinhService';
import * as CommonService from '../../../../services/commonService';
import * as MedicineService from '../../../../services/medicineService';
import * as PrintService from '../../../../services/printService';
import { isNullOrEmpty } from '../../../../libs/util';

export default class index extends Component {

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            activeThuoc: 0,
            thuoc: '',
            lstKho: [],
            lstNguon: [],
            maNguon: '',
            maKho: 502,
            lstDetail: [],
            lstDetailNew: [],
            lstColums: [],
            giaban: 0,
            soluong: 0,
            tongtien: 0,
            tongtienNew: 0,
            disible: true,
            hidden: true,
            tinhtien: true,
            listThuoc: [],
            drawShow: false,
            render: '',
            loai: '',
            sophieu: 0,
            numberAdd: 1,
            tenKho: '',
            tenNguon: '',
            detailDefault: {},
            lstResult: [],
            mabn: '',
            hoten: '',
            ghichu: '',
            btnEdit: true,
            btnEditText: 'Chỉnh sửa',
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.loadDetailToa(this.props.ngay, this.props.id)
        }, 600);

        this.SearchMedicine();
        CommonService.GetPatientDetail(this.props.mabn).then(result => {
            if (result.isSuccess) {
                let selectedPatient = result.item;
                let temp = 'Hành Chánh';
                if (selectedPatient) {
                    temp += '    BN: ' + selectedPatient.patientName + '  -   mã BN:' + selectedPatient.patientID;
                }
                this.setState({ AdministrativePatientName: temp })
                this.setState({ selectedPatient })
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }

        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
    }

    loadDetailToa() {
        MedicineService.ThongTinHoaDon(this.props.ngay, this.props.id, 1, 100).then(result => {

            if (result.isSuccess) {
                let data = [];
                data = result.data.filter(x => x.soluong > 0)
                let tongtien = 0;
                let index = 1;
                for (let item of data) {
                    tongtien += item.soluong * item.giathucthu;
                    item.key = item.ma;
                    item.id = item.mathuoc;
                    item.makho = 502;
                    item.manguon = 11;
                    item.cachdung = item.cachdung;
                    item.countNumber = index
                    index++
                }
                let tongtienNew = tongtien
                this.newTable.setDataSource(data);
                this.setState({
                    lstDetail: JSON.parse(JSON.stringify(data)),
                    lstDetailNew: data,
                    hidden: false,
                    tongtienNew, tongtien,
                    ghichu: result.data.length > 0 ? result.data[0].ghichu : ''
                })

                if (data.length > 0) {
                    this.setState({
                        detailDefault: data[0],
                    })
                }
            }
        })
            .catch(err => {
                alert(err)
                notification.warning({ message: 'Tải dữ liệu chi tiết đơn bị lỗi' })
            })
    }

    // loadKho() {
    //     MedicineService.GetListKho().then(result => {
    //         if (result.isSuccess) {
    //             if (result.data.length > 0) {
    //                 this.setState({
    //                     //maKho: result.data[0].value,
    //                     tenKho: result.data[0].label,
    //                 });
    //             }
    //             this.setState({
    //                 lstKho: result.data,
    //             });
    //         }
    //     }).catch(err => {
    //         notification.warning({ message: 'Tải dữ liệu kho bị lỗi' })
    //     })
    // }
    loadNguon() {
        MedicineService.GetListNguon().then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        maNguon: result.data[0].value,
                        tenNguon: result.data[0].label,
                    });
                }
                this.setState({
                    lstNguon: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nguồn bị lỗi' })
        })
    }

    XoaPhieu() {
        MedicineService.XoaToaBanLe(this.props.id.toString(), moment(this.props.ngay).format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.props.onClose();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }

    LuuHoaDon() {
        let medicines = this.newTable.getDataSource()
        for (let item of medicines) {
            item.ngay = item.ngay === null ? '0001-01-01' : item.ngay;
            if (item.soluong <= 0) {
                notification.warning({ message: 'Số lượng thuốc ' + item.ten + ' không hợp lệ!' });
                return;
            }
            else {
                item.soluong = +item.soluong;
            }
        }
        let data = {
            id: this.props.id.toString(),
            ngay: moment(this.props.ngay),
            medicines,
            ghichu: this.state.ghichu
        }
        Actions.setLoading(true);
        MedicineService.LuuXacNhanHoaDonBanLe(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công' })
                this.ChangeButton();
                this.setState({ isPrint: true })
            }
            else {
                notification.warning({ message: result.err.msgString })
            }
            Actions.setLoading(false);
        }).catch(err => {
            notification.warning({ message: 'Lưu bị lỗi' });
            Actions.setLoading(false);
        })
    }

    xoathuoc(row) {
        let lstDetailNew = this.state.lstDetailNew;
        let lst = this.state.lstDetailNew.filter(x => x.id !== row.id);
        lstDetailNew = lst;
        this.newTable.setDataSource(lstDetailNew);
        let tongtienNew = 0;
        for (let item of lstDetailNew) {
            if (item.soluong && item.giathucthu)
                tongtienNew += item.soluong * item.giathucthu;
        }
        this.setState({ tongtienNew, lstDetailNew })
    }

    handleChangeNewMedicine(data) {
        try {
            let tongtienNew = 0;
            for (let item of data) {
                if (item.soluong && item.giathucthu)
                    tongtienNew += item.soluong * item.giathucthu;
            }
            let temp = data.map(function (e, index) {
                let o = Object.assign({}, e);
                return o;
            })
            this.newTable.setDataSource(temp);
            this.setState({ tongtienNew: tongtienNew, lstDetailNew: temp });
        } catch (error) {
        }



    }
    keyFunction = (e, row, col) => {
        if (col === 'cachdung') {
            e.preventDefault();

            if (e.key === 'Tab') {
                if (row < this.state.lstDetailNew.length) {
                    this.newTable.focusNewRow(row + 1,
                        'soluong');
                } else {
                    if (this.Autocomplete)
                        this.Autocomplete.focus();
                }

            }
            if (e.key === 'Enter') {
                if (this.ButtonSave)
                    this.ButtonSave.focus();
            }
        }

    }

    AddNewMedicine(item) {
        let { lstDetailNew } = this.state
        item.id = Number(item.value);
        item.makho = Number(item.makho);
        item.manguon = Number(item.manguon);
        let olditems = lstDetailNew.filter(record => record.ma === item.ma);
        if (olditems.length > 0) {
            return
        } else {
            item.tenthuoc = item.ten;
            item.dang = item.donvi;
            item.isNew = true;
            item.key = item.ma;
            item.soluong = null;
            item.countNumber = lstDetailNew.length + 1;
            item.songay = isNullOrEmpty(item.songay) ? 1 : item.songay;
            item.slsang = isNullOrEmpty(item.slsang) ? 0 : item.slsang;
            item.sltrua = isNullOrEmpty(item.sltrua) ? 0 : item.sltrua;
            item.slchieu = isNullOrEmpty(item.slchieu) ? 0 : item.slchieu;
            item.sltoi = isNullOrEmpty(item.sltoi) ? 0 : item.sltoi;
            lstDetailNew.push(item);
        }
        let tongtienNew = 0;
        for (let item of lstDetailNew) {
            if (item.soluong && item.giathucthu)
                tongtienNew += item.soluong * item.giathucthu;
        }

        this.newTable.setDataSource(lstDetailNew);
        setTimeout(() => {
            this.newTable.focusNewRow(lstDetailNew[lstDetailNew.length - 1].countNumber,
                'soluong');
        }, 200)

        this.setState({ tongtienNew: tongtienNew, lstDetailNew: lstDetailNew })

    }

    SearchMedicine(key) {
        return MedicineService.DanhSachThuocBanle(2, key, 11, 502)
    }

    onSelect(item) {
        if (item.slton === 0) {
            notification.warning({ message: `Thuốc ${item.ten} không còn trong kho !!!` })
        } else {
            item.giathucthu = item.dongia;
            this.AddNewMedicine(item)
        }
    }
    editPrice(row, gia) {
        let { lstDetailNew } = this.state;
        let index = lstDetailNew.findIndex(record => record.id === row.id
            && record.makho === row.makho && record.manguon === row.manguon
            && record.tenhang === row.tenhang);
        lstDetailNew[index].giathucthu = gia;
        let tongtien = 0;
        for (let item of lstDetailNew) {
            tongtien += item.soluong * item.giathucthu;
        }
        let tongtienNew = tongtien;
        this.newTable.setDataSource(lstDetailNew);
        this.setState({ lstDetailNew, tongtienNew })
    }
    PrintConclusion(mabn, id, sotoa, ngay) {
        PrintService.PrintConclusionBanLe(mabn, id, Number(sotoa), ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintUse(id, ngay) {
        PrintService.PrintUseRetail(id, ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    ChangeButton() {
        this.setState({ btnEdit: !this.state.btnEdit }, () => {
            let txt = 'Lưu';
            if (this.state.btnEdit === true) {
                txt = 'Chỉnh sửa';
            }
            this.setState({ btnEditText: txt });
        })
    }
    render() {
        const columns = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                width: 35,
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'ten',
                key: 'ten',
                width: 150,
            },

            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 50,
            },
            {
                title: 'Tên hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                width: 110,
            },

            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 110,
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                width: 100,
                type: 'number',
                editable: true,
                isrequire: true,
                min: 1,
                maxLength: 7,
                //    CustomKey: 'ma'
            },
            {
                title: 'Cách dùng',
                key: 'cachdung',
                dataIndex: 'cachdung',
                editable: true,
                width: 100,
                //  CustomKey: 'ma'
            },

            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : '0'}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                width: 100,
                render: (value, row) => (<InputNumber style={{ width: '90px' }}
                    value={value} min={0} max={999999999} onChange={(e) => {
                        this.editPrice(row, e)
                    }} />)
            },

            {
                title: '',
                width: 30,
                render: (row, index) => {

                    return (
                        <Popconfirm
                            title="Bạn có chắc muốn xóa thuốc này không?"
                            onConfirm={() => { this.xoathuoc(row) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <div style={{ cursor: 'pointer' }}>
                                <span style={{ width: '100%', height: '100%', marginTop: 3, color: 'red' }}> <i className="fa fa-trash"></i>  </span>
                            </div>
                        </Popconfirm>
                    )
                }
            }
        ];
        const columnsEdit = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                width: 35,
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'ten',
                key: 'ten',
                width: 150,
            },

            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 50,
            },
            {
                title: 'Tên hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                width: 110,
            },

            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 110,
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                width: 110,
            },
            {
                title: 'Cách dùng',
                key: 'cachdung',
                dataIndex: 'cachdung',
                width: 100,
                render: (value) => {
                    return <span style={{ wordBreak: 'break-word' }}>{value}</span>;
                },
            },

            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : '0'}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                width: 100,
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : '0'}</span>;
                },
            },
        ];
        const columnsSearh = [
            {
                title: 'Tên thuốc',
                dataIndex: 'ten',
                key: 'ten',
                width: 200,
            },
            {
                title: 'Tên hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                width: 350,
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 100,
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                width: 80,
                format: 'number'
            },
            {
                title: 'Đơn vị',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 80,

            },
            {
                title: 'Tồn kho',
                dataIndex: 'slton',
                key: 'slton',
                width: 80,
                format: 'number'
            },
        ];
        let dataSourceNew = this.state.lstDetailNew.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.ma
            return o;
        })



        return (

            <React.Fragment>
                <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>Phiếu xuất thuốc</h3>
                    <h3 onClick={() => this.props.onClose()}><i className='far fa-times-circle' /></h3>
                </div>
                <div>
                    <div>
                        <Administrative loai={2} thanhtoan={this.props.done === 1} item={this.state.selectedPatient}></Administrative>
                    </div>
                    <div style={{ overflow: 'auto', marginTop: 10}}>
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ marginTop: 20 }}>
                                    <AutocompleteTable
                                        disabled={this.state.btnEdit}
                                        placeholder={"Tìm theo tên hay hoạt chất"}
                                        width={300}
                                        tablewidth={1000}
                                        onSearch={this.SearchMedicine}
                                        onSelect={this.onSelect.bind(this)}
                                        value={this.state.thuoc} headers={columnsSearh}
                                        keyValue={'ma'}
                                        ref={(c) => { this.Autocomplete = c }}
                                    >

                                    </AutocompleteTable>
                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <Statistic title="Tổng Tiền" value={this.state.tongtienNew.toLocaleString('en-US') + ' VNĐ'}
                                        precision={2} />
                                </div>
                            </div>
                            <div style={{ display: `${this.state.btnEdit === true ? '' : 'none'}` }}>
                                <Table
                                    scroll={{ x: 'max-content', y: 0 }}
                                    dataSource={dataSourceNew} columns={columnsEdit} pagination={false}
                                    rowClassName={(record) => {
                                        return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                                    }}
                                ></Table>
                            </div>
                            <div style={{ display: `${this.state.btnEdit === false ? '' : 'none'}` }}>
                                <EditableTable
                                    ref={(c) => this.newTable = c}
                                    onchange={(data) => { this.handleChangeNewMedicine(data) }}
                                    dataSource={dataSourceNew} columns={columns} pagination={false}
                                    keyFunction={this.keyFunction}
                                    rowClassName={(record) => {

                                        return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                                    }}
                                ></EditableTable>
                            </div>
                            <div style={{ marginTop: 10 }}>Ghi chú: <br></br>
                                <Input style={{ marginTop: 7 }}
                                    disabled={this.state.btnEdit}
                                    placeholder="Nhập lý do điều chỉnh giá thực thu"
                                    value={this.state.ghichu}
                                    onChange={(e) => { this.setState({ ghichu: e.target.value }) }} />
                            </div>
                        </React.Fragment>
                    </div>
                    <div className='custom-hr' />
                    <div className="form-group" style={{
                        marginTop: 15, display: 'flex', justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {this.state.btnEdit === true &&
                            <div>
                                <Popconfirm
                                    title="Xác nhận xóa phiếu!"
                                    onConfirm={() => { this.XoaPhieu() }}
                                    okText="Đồng ý" cancelText="Hủy">
                                    <button className="btn btn-danger">Xóa phiếu</button>
                                </Popconfirm>
                            </div>
                        }
                        {this.state.btnEdit === true ?
                            <button className="btn btn-color" onClick={() => { this.ChangeButton() }} style={{ marginLeft: 5 }}>
                                <i className="fa fa-save" style={{ marginRight: 5 }}></i>{this.state.btnEditText}
                            </button> :
                            <Popconfirm
                                title="Bạn có chắc muốn xác nhận đơn thuốc này không?"
                                onConfirm={() => { this.LuuHoaDon() }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-color" style={{ marginLeft: 5 }}>
                                    <i className="fa fa-save" style={{ marginRight: 5 }}></i>{this.state.btnEditText}
                                </button>
                            </Popconfirm>
                        }
                        <button className="btn btn-outline-secondary" style={{ marginLeft: "5px" }}
                            onClick={() => { this.PrintConclusion(this.props.mabn, this.props.id, this.props.sotoa, (this.props.ngay)) }}>
                            In phiếu thuốc</button>
                        <button className="btn btn-outline-secondary" style={{ marginLeft: "5px" }}
                            onClick={() => { this.PrintUse(this.props.id, (this.props.ngay)) }}>
                            In cách dùng</button>
                        <button  className="btn btn-danger" style={{ marginLeft: 5 }} danger
                            onClick={() => this.props.onClose()}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            />  Đóng
                        </button>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

