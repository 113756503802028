import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Medicine';
export function GetListNCC(key,page, size)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/listNCC?key=${key}&page=${page}&size=${size}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function SearchNCC(value)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/search?key=${value}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ThemNCC(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/addNCC`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function EditNCC(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/updateNCC`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function XoaNCC(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/delNCC`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function GetDetail(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/getdetail`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getDetails(key)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getDetails?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function XoaThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/xoathuoc?id=${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};