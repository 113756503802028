import { Button, Form, Input, Pagination, Switch, Table, notification, Modal, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../libs/actions';
import * as ConfigService from '../../services/configService';
import * as RoleService from '../../services/roleService';
export default function ListController({ selectMonitor, reloadMenu }) {
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [detail, setDetail] = useState({});
    const [visable, setVisable] = useState(false);
    useEffect(() => {
        GetListSubMenu();
    }, [selectMonitor]);


    useEffect(() => {
        if (currentPage === 0) {
            return;
        }
        GetListSubMenu()
    }, [currentPage, size]);


    const GetListSubMenu = () => {
        Actions.setLoading(true);
        RoleService.GetListSubMenuByParentCode(selectMonitor, currentPage, size, false).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
                setTotalRecords(result.totalRecords)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const InserSubMenu = () => {
        if (selectMonitor == undefined || selectMonitor === '') {
            notification.warning({ message: 'Chưa chọn màn hình' });
            return;
        }
        let data = detail;
        data.parent = selectMonitor;
        Actions.setLoading(true);
        RoleService.InsertSubMenu(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công' });
                GetListSubMenu();
                setVisable(false)
                if (reloadMenu) reloadMenu()
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const UpdateSubMenu = () => {
        Actions.setLoading(true);
        RoleService.UpdateSubMenu(detail.id, detail).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công' });
                GetListSubMenu();
                setVisable(false)
                if (reloadMenu) reloadMenu()
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const DeleteSubMenu = (code) => {
        Actions.setLoading(true);
        RoleService.DeleteSubMenu(code).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' })
                GetListSubMenu()
                if (reloadMenu) reloadMenu(true, code)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const updateHide = (code, check) => {
        RoleService.UpdateHideSubMenu(code, check).then(result => {
            let data = dataSource;
            let item = data.find(x => x.code === code);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hide === true ? false : true;
                    item.hide = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hide;
                    item.hide = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            setDataSource(data.map(x => ({ ...x })));
        }).catch(err => {
            let data = dataSource;
            let item = data.find(x => x.code === code);
            if (item !== undefined) {
                const newcheck = item.hide;
                item.hide = newcheck;
            }
            setDataSource(data.map(x => ({ ...x })));
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            width: 120
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            width: 180
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 80,
        },
        // {
        //     title: 'Parent',
        //     dataIndex: 'parent',
        //     key: 'parent',
        //     width: 80,
        // },
        {
            title: 'Display',
            dataIndex: 'display',
            key: 'display',
            width: 80,
        },
        {
            title: 'Hide',
            align: 'center',
            width: 80,
            render: (row) => {
                return (
                    <Switch checkedChildren="Có" unCheckedChildren="Không" checked={row.hide === true}
                        onChange={(e) => {
                            updateHide(row.code, e)
                        }} />
                )
            }
        },
        {
            title: 'Action',
            align: 'center',
            width: 100,
            render: (row) => {
                return (
                    <div>
                        <Button className='btn btn-warning mr-1'
                            onClick={() => {
                                setVisable(true);
                                setDetail(row);
                                setIsEdit(true);
                            }}
                        ><i className='fa fa-edit'></i></Button>
                        <Popconfirm
                            title="Bạn có chắc muốn xóa?"
                            onConfirm={() => { DeleteSubMenu(row.code) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <Button className='btn btn-danger ml-1'><i className='fa fa-trash'></i></Button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ];
    return (
        <>
            <div className='h4 text-center mb-2 font-bold text-uppercase'>Danh sách Menu ẩn</div>
            <div className="row no-gutters" style={{ justifyContent: 'center' }}>
                {/* <div className="col-auto">
                    <Form.Item>
                        <Input
                            value={key}
                            placeholder="Nhập từ khóa"
                            onChange={(e) => setKey(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setCurrentPage(0);
                                    setTimeout(() => {
                                        setCurrentPage(1);
                                    }, 100);
                                }
                            }}
                        />
                    </Form.Item>
                </div> */}
                {/* <div className="col-auto">
                    <Form.Item>
                        <Button className="btn btn-color" onClick={() => {
                            setCurrentPage(0);
                            setTimeout(() => {
                                setCurrentPage(1);
                            }, 100);
                        }
                        }>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                    </Form.Item>
                </div> */}
                <div className="col-auto ml-auto">
                    <Form.Item>
                        <Button className="btn btn-success"
                            onClick={() => {
                                setIsEdit(false);
                                setDetail({});
                                setVisable(true)
                            }}
                        >Thêm mới</Button>
                    </Form.Item>
                </div>
            </div>
            <div className="table-responsive">
                <Table dataSource={dataSource}
                    scroll={{ y: '65vh', x: 'max-content' }}
                    pagination={false}
                    columns={columns} rowKey={(e, index) => index} />
            </div>
            <Pagination
                current={currentPage}
                size={size}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page) }}
                onShowSizeChange={(page, size) => { setSize(size) }}
                showSizeChanger={true}
            />
            <Modal
                visible={visable}
                width={1000}
                onCancel={() => setVisable(false)}
            >
                <div className="h4 font-weight-bold text-center">
                    {isEdit ? "Chỉnh sửa" : "Thêm mới"}
                </div>
                <div className='row'>
                    <div className='col-md-6 py-1'>
                        <label>Key</label>
                        <Input value={detail.key} onChange={e => {
                            detail.key = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    <div className='col-md-6 py-1'>
                        <label>Label</label>
                        <Input value={detail.label} onChange={e => {
                            detail.label = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    <div className='col-md-6 py-1'>
                        <label>Code</label>
                        <Input value={detail.code} onChange={e => {
                            detail.code = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    {/* <div className='col-md-6 py-1'>
                        <label>Parent</label>
                        <Input value={detail.parent} onChange={e => {
                            detail.parent = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div> */}
                    <div className='col-md-6 py-1'>
                        <label>Display</label>
                        <Input value={detail.display}
                            maxLength={5}
                            onKeyDown={e => {
                                if (isNaN(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'Control') {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    return;

                                }
                            }}
                            onChange={e => {
                                detail.display = Number(e.target.value);
                                setDetail({ ...detail })
                            }} />
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Button className="btn btn-color mr-2" onClick={() => {
                        if (isEdit) { UpdateSubMenu() }
                        else {
                            InserSubMenu()
                        }
                    }}>Lưu</Button>
                    <Button className="btn btn-outline-secondary" onClick={() => {
                        setVisable(false)
                    }}>Đóng</Button>
                </div>
            </Modal>
        </>
    )

}