import { Form, Input, notification, Select } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as MedicineService from '../../../../services/medicineService';
const { Option } = Select;
var task = null;
export default class materialType extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            lstNhom: [],
            manhom: '',
            tenloai: '',
            selectedType: "",
        };
    }

    componentDidMount() {
        this.loadNhom();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task);
    }

    loadNhom() {
        MedicineService.DanhSachNhomThuoc().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNhom: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm thuốc bị lỗi' });
        })
    }
    resetState() {
        this.form.setFieldsValue({
            tenloai: null
        });
        this.setState({
            tenloai: ''
        })
        task = setTimeout(() => {
            this.form.resetFields();
        }, 20);
    }
    async TaoMoiLoai() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                name: this.state.tenloai,
                group: Number(this.state.manhom),
            }
            MedicineService.TaoMoiLoaiThuoc(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.resetState();
            }).catch(err => {
                notification.warning({ message: 'Tạo thất bại. Vui lòng thử lại!' });
            })
        } else {
            this.validator.showMessages();
        }
    }



    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm loại {this.state.selectedType}</h3>
                    </div>
                    <div style={{ padding: 20 }}>
                        <div className="row no-gutters" style={{ display: 'flex' }}>
                            <div className="col-md-12">
                                <Form.Item label="Nhóm:" name="manhom" rules={[{ required: true, message: 'Chưa chọn nhóm!' }]}>
                                    <Select
                                        value={this.state.manhom}
                                        onChange={(e) => { this.setState({ manhom: e, selectedType: this.state.lstNhom && this.state.lstNhom.find(x => x.value === e).label }) }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstNhom.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-12 mt-2">
                                <Form.Item label="Tên:" name="tenloai" rules={[{ required: true, message: 'Chưa nhập tên loại!' }]}>
                                    <Input value={this.state.tenloai}
                                        onChange={(e) => this.setState({ tenloai: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                            <button className="btn btn-color"
                                onClick={() => { this.TaoMoiLoai() }}><i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                            <button className="btn btn-success" style={{ marginLeft: 10 }}
                                onClick={() => this.resetState()}> <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.props.onCancel();
                                    task = setTimeout(() => {
                                        this.form.resetFields();
                                    }, 20);
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
