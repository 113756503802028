import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Collapse, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import { ValidationCustom } from '../../commons/validationCustom';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../components/notify';
import * as ExamResultService from '../../services/ExamResultServices';
import { connect } from 'react-redux';
import PatientInfo from '../patient/component/patientInfo';
import VitalSign from '../patient/component/vitalSign';
import HistoryExam from '../../components/history/indexNew';
import TestResultBegin from './modified-components/testresult-begin';
import ModifyTestResultDetail from './modified-components/testresult-detail';
import TestResultDetail from './detail-components/testresult-detail';
import TestResultBottom from './modified-components/testresult-bottom';

const { Panel } = Collapse;
const DownLoadTemplate = React.forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const detailRef = useRef();
  const bottomRef = useRef();
  //state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //model
  const [maQL, setMaQL] = useState(props.maQL);
  const [sid, setSid] = useState(props.sid);
  const [maBN, setMaBN] = useState(props.maBN);
  const [selectedRecord, setselectedRecord] = useState(props.record);
  //#region useEffect
  useEffect(() => {
  }, []);
  //#endregion 
  const model = {
    siteId: props.record.siteId,
    maQL: props.maQL,
    sid: props.sid,
    comment: '',
    suggestion: '',
    parentTests: [],
    childrenTests: []
  };

  const setModel = () => {
    model.siteId= props.record.siteId;
    model.maQL= props.maQL;
    model.sid= props.sid;
    model.comment= bottomRef.current.comment();
    model.suggestion= bottomRef.current.suggestion();
    if(!selectedRecord.configTuongTacVoiLab) model.parentTests = detailRef.current.listTestParentModified();
    if(!selectedRecord.configTuongTacVoiLab) model.childrenTests = detailRef.current.listTestChildrenModified();
  }

  const saveButtonHandle = () => {
    Actions.setLoading(true);
    setModel();
    ExamResultService.examResultUpdate(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Cập nhật kết quả - {props.record.hoTen} - {props.record.sid}</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel();
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="w-100 h-100 d-flex justify-content-between">
              <div className="border overflow-auto" style={{width:'45%'}}>{/**Left Panel */}
                <Collapse defaultActiveKey={['1','2','3']}
                  expandIconPosition="right"
                >
                  <Panel header={<b>Thông tin bệnh nhân</b>} key="1">
                    <PatientInfo patientId={maBN}/> 
                  </Panel>
                  <Panel header={<b>Thông tin dấu hiệu sinh tồn</b>} key="2">
                    <VitalSign maql={maQL}/> 
                  </Panel>
                  <Panel header={<b>Lịch sử khám</b>} key="3">
                    <div className='col-12 mt-2'>
                      <HistoryExam patientID={maBN}/> 
                    </div>
                  </Panel>
                </Collapse>
              </div>
              <div className="border h-100 d-flex flex-column overflow-auto">{/**Right Panel */}
                <div>{/**Left Panel - top: thông tin phía trên*/}
                  <TestResultBegin
                    sid={sid}
                  />
                </div>
                <div>{/**Left Panel - body: chi tiết kết quả*/} 
                { selectedRecord.configTuongTacVoiLab ? 
                    <TestResultDetail
                      maQL={maQL}
                      sid={sid}
                    />
                :
                    <ModifyTestResultDetail ref={detailRef}
                      maQL={maQL}
                      sid={sid}
                    />
                }
                  
                </div>
                <div>{/**Left Panel - bottom: thông tin phía dưới*/} 
                  <TestResultBottom ref={bottomRef}
                    sid={sid}
                    siteId={props.record.siteId}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  onClick={saveButtonHandle}
                >
                  <i className='fa fa-save mr-2'></i>
                  Lưu
                </button>
              <button
                  className='btn btn-danger ml-3'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
      siteCode: state.global.siteCode,
      siteName: state.global.siteName
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DownLoadTemplate);