import * as ApiCaller from '../../libs/httpRequests';
const prefix = 'Hotline';

export function GetListPatients(dateFrom, dateTo, key, page, size, status, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListPatients?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}&uncompleted=${status}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function addArrangerment(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/AddArrangerment`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        });
    });
};

export function updateArrangerment(id, status, idLichHen, chinhanh, destSite) {
    if (!chinhanh)
        chinhanh = "";
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateArrangerment?id=${id}&status=${status}&idLichHen=${idLichHen}&chinhanh=${chinhanh}&destSite=${destSite}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateDetailArrangerment(id, data, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateDetailArrangerment?id=${id}&chinhanh=${chinhanh}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function saveLichHen(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SaveAppointment`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        });
    });
};

export function updateLichHen(idlichhen, data, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateLichHen?idlichhen=${idlichhen}&chinhanh=${chinhanh}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteTiepNhan(id, idlichhen, idhenkham, chinhanh, fromTuVan = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteTiepNhan?&id=${id}&lichhen=${idlichhen}&henkham=${idhenkham}&tuvan=${fromTuVan}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
