import { Badge, Popover, List } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import MitaDisplayLongText from '../../components/MitaComponents/mitaDisplayLongText';

function NotificationMessage(props) {
    const showMoreText='...Xem thêm';
    const showLessText=' Rút gọn';
    const [content, setContent] = useState([{ title: "Bạn chưa có thông báo nào" }]);
    const [newNotification, setnewNotification] = useState(false);

    useEffect(() => {
        let currentNotiData = sessionStorage.getItem("notificationData");
        let listNotiData = JSON.parse(currentNotiData);
        if (listNotiData !== undefined && listNotiData !== null && listNotiData !== "") {
            let listNewNotiData = listNotiData.filter(x => x.read == false);
            setContent(listNotiData);
            if (listNewNotiData.length > 0) {
                setnewNotification(true);
            } else {
                setnewNotification(false);
            }
        } else {
            setContent([{ title: "Bạn chưa có thông báo nào" }]);
            setnewNotification(false);
        }
    }, [sessionStorage.getItem("notificationData")]);

    const createReadStatus = () => {
        let currentNotiData = sessionStorage.getItem("notificationData");
        let listNotiData = JSON.parse(currentNotiData);
        if (listNotiData !== undefined && listNotiData !== null && listNotiData !== "") {
            let listNewNotiData = listNotiData.map(x => ({ ...x, read: true }));
            sessionStorage.setItem("notificationData", JSON.stringify(listNewNotiData));
        } else {
            setContent([{ title: "Bạn chưa có thông báo nào" }]);
            setnewNotification(false);
        }
    }

    return (
        <Fragment>
            <Popover
                onVisibleChange={(visible) => {
                    if (!visible) createReadStatus();
                }}
                title="Thông báo"
                trigger="click"
                placement='bottom'
                content={
                    <div style={{ width: 350 }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={content}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={item.title}
                                        description={
                                            <MitaDisplayLongText
                                                content={item.notification}
                                                maxLength={200}
                                                showMoreText={showMoreText}
                                                showLessText={showLessText}
                                            />
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                }>
                <div
                    style={{
                        display: 'flex',
                        color: 'white',
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: "center",
                        width: "50px",
                        height: "45px",
                    }}
                >
                    <Badge dot={newNotification}><i style={{ fontSize: 20 }} className="fas fa-bell text-white" /></Badge>
                </div>
            </Popover>
        </Fragment>
    );
}

export default NotificationMessage;