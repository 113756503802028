import { Menu, Dropdown, Tooltip, Modal, Select, notification, Button } from 'antd';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ErrorMessageModal from '../ErrorMessageModal';
import Loading from '../loading';
import NavLeftSlideBar from '../navLeftSlideBar';
import Printer from '../printer';
import ChangePassword from '../changePassword';
import * as Actions from '../../libs/actions';
import * as AuthServices from '../../services/authServices';
import './main.css';
import { version } from '../../../package.json';
import * as UrlConfig from '../../configure/urlConfig';
import * as actions from '../../libs/actions';
import * as CommonService from "../../services/commonService";
import { store } from '../../stores';
import NotificationMessage from '../../configureHIS/common/notificationMessage';
const { Option } = Select;
let clockTask = null
class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openNav: true,
            visible: false,
            setVisible: false,
            print: false,
            listIndex: [],
            ishasExpiredItem: false,
            mabd: 0,
            tenthuoc: '',
            maKho: 519,
            ngay: moment(),
            totalRecords: 0,
            availableSites: [],

            DropDownVisible: false,
        }
    }
    componentWillUnmount() {
        if (clockTask) {
            clearInterval(clockTask);
        }
    }
    afterPrint() {
        setTimeout(() => {
            Actions.closePrintDialog();
        }, 300);
    }

    checkExpiredMedicine() {

    }

    getAvaiabeSites() {
        AuthServices.getAvailableSites().then((res) => {
            if (res.isSuccess) {
                this.setState({ availableSites: res.data });
                let listSiteId = [];
                if (res.data) listSiteId = res.data.map(x => (x.id));
                actions.setAvailableSite(listSiteId);
            }
        })
    }

    componentDidMount() {
        if (window.onafterprint === null || window.onafterprint === undefined) {
            window.onafterprint = this.afterPrint.bind(this);
        }
        setInterval(() => {
            this.setState({ date: moment() })
        }, 1000);
        this.getAvaiabeSites();
        this.checkExpiredMedicine();
		this.loadDefaultAddress();
        this.setSelectedTheme();
    }

    showDrawer() {
        this.setState({ setVisible: true });
    };
    onClose() {
        this.setState({ setVisible: false });
    };


    handleToggle() {
        this.setState({ openNav: !this.state.openNav })
    }
    isFullPage() {
        if (this.props.location.pathname.includes('/login')
            || this.props.location.pathname.includes('qms')
            || this.props.location.pathname.includes('khaibaoyte')
            || this.props.location.pathname.includes('result')
        ) {
            return true;
        }
        return false;
    }

    onChangeSite(value) {
        AuthServices.SwitchCurrentSite(value).then((res) => {
            if (res.isSuccess) {
                let item = res.item
                this.props.setCurrentSite({ siteid: item.id, siteCode: item.ma, siteName: item.ten })
            } else {
                notification.error(res.err.msgString)
            }
        }).catch((ex) => {
            notification.error(ex)
        })

    }

    onDocumentLoadSuccess({ numPages }) {
        this.setState({ numPages })
    }
    getlock() {
        return this.state.date ? this.state.date.format('DD-MM-YYYY HH:mm:ss') : ''
    }
    CloseChangePass() {
        this.setState({ setVisible: false })
    }

    renderMenu(siteid) {

        return (
            <Menu>
                <Menu.Item>
                    <Select
                        className='w-100'
                        style={{ width: 120, borderRadius: 10 }}
                        value={siteid}
                        showSearch
                        placeholder="Chọn Chi Nhánh"
                        optionFilterProp="children"
                        onChange={this.onChangeSite.bind(this)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {this.state.availableSites.map((record) => {
                            return <Option key={record.id} value={record.id}>{record.ma}</Option>
                        })}
                    </Select>
                </Menu.Item>
                <Menu.Item>
                    <Button className='w-100' style={{ borderRadius: 10 }} onClick={() => { this.setState({ setVisible: true }) }}>
                        <i className="fa fa-key" style={{ marginRight: 5 }} />Đổi mật khẩu
                    </Button>
                </Menu.Item>
                <Menu.Item>
                    <Button className='w-100' style={{ borderRadius: 10 }} onClick={() => { Actions.logout(); return; }}>
                        <i className="fa fa-power-off" style={{ marginRight: 5, color: 'red' }} />Đăng xuất
                    </Button>
                </Menu.Item>
            </Menu>
        );
    }

    loadDefaultAddress() {
        CommonService.loadDefaultAddress().then(result => {
            if (result.isSuccess) {
                var listDefaultAddr = result.data;
                let foundCity = listDefaultAddr.find(x => x.key === 'city');
                let foundDistrict = listDefaultAddr.find(x => x.key === 'district');
                let foundWard = listDefaultAddr.find(x => x.key === 'ward');
                if (foundCity !== undefined && foundDistrict !== undefined && foundWard !== undefined) {
                    store.dispatch({ type: 'SET_DEFAULT_ADDRESS', data: result.data });
                }
            }
        })
    }
    
    setSelectedTheme() {
        let themeSelected = localStorage.getItem("skin");
        if (themeSelected)
            this.setTheme(themeSelected);
        else this.setTheme("skin-megna");
    }

    setTheme(theme) {
        let foundThemeElement = document.querySelector(`a[data-skin="${theme}"]`);
        if (foundThemeElement) {
            let newClassName = foundThemeElement.className + " working";
            foundThemeElement.className = newClassName;
        }
    }

    render() {
        let { name, title, siteCode, siteid } = this.props

        return (
            <React.Fragment>
                {this.props.file !== undefined && this.props.file !== null &&
                    <Printer file={this.props.file} printNewTab={this.props.printNewTab}></Printer>
                }
                <div className={'notprint'} >
                    <Modal visible={this.state.setVisible}>
                        <ChangePassword open={this.state.setVisible} onClose={this.CloseChangePass.bind(this)}></ChangePassword>
                    </Modal>
                    <Loading show={this.props.loadingModal} />
                    <ErrorMessageModal />
                    {this.isFullPage() === false ?
                        <header className="topbar" >
                            <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                                <div className="navbar-header" >
                                    <div className="navbar-brand" style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                            <div>
                                                <img src="./images/headlogo2.png" style={{ maxHeight: 40 }} alt="homepage" className="light-logo" />

                                                <img src="./images/headlogo2.png" style={{ maxHeight: 40 }} alt="homepage" className="dark-logo" />
                                            </div>
                                            <span className='hidden-xs' style={{ marginLeft: 10, marginTop: 5 }}>
                                                <div style={{ height: 16 }}>
                                                    <b style={{ fontSize: 20 }}>Multi Connection</b>
                                                </div>
                                                <b style={{ fontSize: 9, color: 'white' }}  >@{moment().format('YYYY')}  ViettinLab</b>

                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="navbar-collapse">
                                    <ul id="navbar-nav-header-tab" className="navbar-nav mr-auto">
                                        <li className="nav-item">
                                            <a id='sidebartoggler' className="nav-link nav-toggler d-block d-md-none waves-effect waves-dark"
                                                href="javascript:void(0)"><i className="ti-menu"></i></a>
                                        </li>
                                        <li className="nav-item" > <a
                                            className="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark"
                                            href="javascript:void(0)">
                                            <i className="fas fa-bars" style={{ fontSize: '15px', color: 'white', fontWeight: 'bold' }}></i>
                                        </a> </li>
                                    </ul>
                                    <ul className="navbar-nav my-lg-0">
                                        <Tooltip title='Đóng tất cả' placement='bottom'>
                                            <li className="nav-item close-all-tab-button" title='Đóng tất cả'>
                                                {this.props.tabs !== undefined && this.props.tabs.length > 5 &&
                                                    <a onClick={() => Actions.clearTabs()} className="nav-link  waves-effect waves-light"
                                                        href="javascript:void(0)">
                                                        <i className="far fa-times-circle"></i></a>
                                                }
                                            </li></Tooltip>
                                        {/* <li className="nav-item dropdown u-pro" >
                                            <a className=" dropdown-toggle waves-effect waves-dark profile-pic" href=""
                                                style={{ height: '100%' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div style={{
                                                    display: 'flex', color: 'white',
                                                    height: '100%',
                                                    alignItems: 'center',
                                                    paddingLeft: 8, paddingRight: 8
                                                }}> */}
                                        <NotificationMessage />
                                        <Dropdown
                                            onClick={() => {
                                                this.setState({ DropDownVisible: !this.state.DropDownVisible })
                                            }}
                                            onVisibleChange={() => {
                                                this.setState({ DropDownVisible: false })
                                            }}
                                            visible={this.state.DropDownVisible}
                                            overlay={this.renderMenu(siteid)} placement="bottomRight" arrow>
                                            <div style={{
                                                boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 4px',
                                                borderRadius: 10,
                                                display: 'flex', color: 'white',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                paddingLeft: 8, paddingRight: 8
                                            }}>
                                                <div style={{ marginRight: 5, minWidth: 90, }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <i className='fas fa-user' style={{ marginRight: 5 }}></i>
                                                        {name}
                                                    </div>
                                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                                        <i className='fas fa-home' style={{ marginRight: 5 }}></i>
                                                        {siteCode}
                                                    </div>
                                                </div>
                                                {this.state.DropDownVisible == false ?
                                                    <DownCircleOutlined  style={{fontSize:20}}/>
                                                    :
                                                    <UpCircleOutlined  style={{fontSize:20}}/>
                                                }
                                            </div>

                                        </Dropdown>
                                        {/* <i className="fa fa-angle-down"></i>
                                                </div>
                                            </a>

                                        </li> */}

                                        <li className="nav-item right-side-toggle"> <a className="nav-link  waves-effect waves-light"
                                            href="javascript:void(0)"><i className="ti-settings"></i></a></li>
                                    </ul>
                                </div>
                            </nav>
                        </header>
                        : ""}
                    {this.isFullPage() === false ?
                        <aside id='left-sidebar' className="left-sidebar">
                            <div className="scroll-sidebar">
                                <NavLeftSlideBar isOpen={this.state.openNav} /></div>
                            <div className={`w-100 text-center text-muted mt-1 scroll-sidebar`}>
                                <nav className='sidebar-nav'>
                                    <div id="sidebarnav"><i className='hide-menu'>Phiên bản {UrlConfig.VERSION}</i></div>
                                </nav>
                            </div>
                        </aside>
                        : ""}
                    {this.isFullPage() === false ?
                        <div className="page-wrapper">
                            <div className="container-layout" >
                                {this.props.children}
                            </div>
                            <div className="right-sidebar">
                                <div className="slimscrollright">
                                    <div className="rpanel-title"> MÀU NỀN <span><i className="ti-close right-side-toggle"></i></span>
                                    </div>
                                    <div className="r-panel-body">
                                        <ul id="themecolors" className="m-t-20">
                                            <li><b>SÁNG</b></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-default" className="default-theme">1</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-green" className="green-theme">2</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-red" className="red-theme">3</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-blue" className="blue-theme">4</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-purple" className="purple-theme">5</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-megna" className="megna-theme working">6</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-yellow" className="yellow-theme">7</a></li>
                                            <li className="d-block m-t-30"><b>TỐI</b></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-default-dark"
                                                className="default-dark-theme ">7</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-green-dark"
                                                className="green-dark-theme">8</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-red-dark" className="red-dark-theme">9</a>
                                            </li>
                                            <li><a href="javascript:void(0)" data-skin="skin-blue-dark"
                                                className="blue-dark-theme">10</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-purple-dark"
                                                className="purple-dark-theme">11</a></li>
                                            <li><a href="javascript:void(0)" data-skin="skin-megna-dark"
                                                className="megna-dark-theme ">12</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        :
                        <div>
                            <div style={{
                                height: '100vh', width: '100%', display: 'flex', justifyContent: 'center',
                                alignItems: 'center'
                            }} className="printer-container-page">

                                {this.props.children}

                            </div>
                        </div>
                    }
                    {this.isFullPage() === false}
                </div>
                <Modal maskClosable={false}
                    width={1500}
                    visible={this.state.expiredModal}
                    onCancel={() => { this.setState({ expiredModal: false }) }}

                    cancelButtonProps={{ hidden: false, disabled: false }}
                    okButtonProps={{ disabled: true, hidden: true }}>

                </Modal>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        loadingModal: state.modal.loadingModal,
        print: state.global.print,
        printNewTab: state.global.printNewTab,
        file: state.global.file,
        name: state.global.name,
        title: state.global.title,
        tabs: state.global.tabs,
        siteCode: state.global.siteCode,
        siteid: state.global.siteid
    })
};


const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentSite: (siteInfo) => dispatch({ type: 'SET_ACTIVE_SITE', siteInfo: siteInfo }),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))