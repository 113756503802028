
import React, { useEffect, useState, forwardRef } from 'react';
import { Popover } from 'antd';

export default forwardRef((props, ref) => {
    // const createRef = useRef();
    /*Các props cần truyền vào:
    - content: nội dung/text cần hiển thị
    - maxLength: Độ dài ký tự tối đa được hiển thị
    - showMoreText: text hiển thị cho button show more
    - showLessText: text hiển thị cho button show less
    - displayMode:
      + 'popup': hiển thị popup
      + 'inner': hiện trong tag hiện tại
    */
    //state hứng props
    const [content, setContent] = useState([]); //nội dung/text cần hiển thị (được truyền từ props vào)
    const [maxLength, setMaxLength] = useState(3); //Độ dài tối đa
    const [showMoreText, setShowMoreText] = useState('...show more');
    const [showLessText, setShowLessText] = useState('...show less');
    const [displayMode, setDisplayMode] = useState('inner'); //"inner" | "popup": chế độ hiển thị full
    const [elementDisplay, setElementDisplay] = useState('inline'); //"inline" | "block": chế độ hiển thị các element: trên 1 dòng hoặc xuống dòng

    //state cho internal component
    const [contentLess, setContentLess] = useState([]); //nội dung/text ngắn
    const [isShowMore, setIsShowMore] = useState(false); //trạng thái đang show more hay less
    
    useEffect(() => {
        changeProps();
    }, [props]);

    useEffect(() => {
        setContent(props.content ?? []);
    }, [props.content]);

    useEffect(() => {
        updateContentLess();
    }, [content, maxLength]);

    const changeProps = () => {
        setContent(props.content ?? []);
        setMaxLength(props.maxLength ?? 3);
        setShowMoreText(props.showMoreText ?? "...show more");
        setShowLessText(props.showLessText ?? "...show less");
        setElementDisplay(props.elementDisplay ?? "inline");
        updateDisplayMode();
    }
    const updateDisplayMode = () => {
        //mặc dù có 2 giá trị là popup | popup nhưng dùng switch case thay vì if else vì có thể sau này mở rộng thêm, có các mode khác như modal hay là gì gì gì đó
        switch (props.displayMode) {
            case "popup":
                setDisplayMode("popup");
                break;
            default:
                setDisplayMode("inner");
                break;
        }
    }

    const updateContentLess = () => {
        if (content?.length > maxLength) {
            setContentLess(content.slice(0, 3));
        }
        else {
            setContentLess([...content]);
        }
    }

    return (
        <>
            <div style={{whiteSpace: "pre-line"}}>{ (isShowMore && displayMode == 'inner') ? content : contentLess }</div>
            <span 
                className='ant-btn-link'
                style={{ padding: "0", cursor: "pointer" }}
                hidden={!(content?.length > maxLength && displayMode === 'inner')}
                onClick={() => { setIsShowMore(!isShowMore); }}
            >{ isShowMore ? showLessText : showMoreText }
            </span>
            <Popover
                content = {() =>  
                    content.map((x, index) => {return (
                        <div key={index} style={{ display: elementDisplay }}>
                            { x }
                        </div>
                    )})
                }
                trigger="click"
            >
                <span
                    className='ant-btn-link'
                    style={{ padding: "0", cursor: "pointer" }}
                    hidden={!(content?.length > maxLength && displayMode === 'popup')}
                    onClick={() => {
                    }}
                >{ isShowMore ? showLessText : showMoreText }
                </span>
            </Popover>
        </>
    );
})