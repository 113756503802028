import { Button, notification, Drawer } from 'antd';
import React ,{ Fragment, useState, useEffect, useCallback } from "react";
import * as PatientService from '../../../services/patientService';
import moment from 'moment';
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';
import { FORMAT_DATE } from '../../../configure/systemConfig';
import DetailHoSo from '../../exam/hoso/chitietNew'

export default (props) => {
    //biến cục bộ
    const showMoreText='...Xem thêm';
    const showLessText=' Rút gọn';
    /*
    - Truyền vào param xem có phải đang trong modal Thông tin bệnh nhân ko
    + Nếu đang trong modal Thông tin bệnh nhân : Mã bệnh nhân là text.
    + Nếu không trong modal Thông tin bệnh nhân : Mã bệnh nhân là link -> Bấm vào hiện modal.
    */
    const [isViewInfo, setIsViewInfo] = useState(props.isViewInfo ? props.isViewInfo : false);
    const [showContact, setShowContact] = useState(props.showContact ? props.showContact : false);
    const [visibleHoSo, setVisibleHoSo] = useState(false);
    const [visibleContact, setVisibleContact] = useState(false);

    const [patientId, setPatientId] = useState('');
    const [patient, setPatient] = useState({});

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    useEffect(() => {
        setPatientId(props.patientId);
    }, [props])   

    useEffect(() => {
        if(patientId !== '')
        GetPatientInfo();
    }, [patientId])

    const GetPatientInfo = () => {
        PatientService.GetPatientDetail(patientId).then(result => {
            if (result.isSuccess) {
                setPatient(result.data);
                forceUpdate();
            } else {
            notification.error({ message: result.error.messageText });
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.error({ message: err.error.messageText });
            else notification.error({ message: err });
        })
    }

    return (
        <Fragment>
            <div className='col-12 my-2'>
                <div className="row">
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Mã bệnh nhân</label>
                        {isViewInfo ? 
                            <label className="mita-value">{patient.maBN}</label>
                        : 
                            <a href='#' onClick={() => setVisibleHoSo(true)}>{patient.maBN}</a>
                        }
                    </div>
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Tên bệnh nhân</label>
                        <label className="mita-value">{patient.hoTen}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">{patient.onlyYear ? "Năm sinh" : "Ngày sinh"}</label>
                        <label className="mita-value">{patient.onlyYear ? moment(patient.namSinh).format("YYYY") : moment(patient.ngaySinh).format(FORMAT_DATE)}</label>
                    </div>
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Giới tính</label>
                        <label className="mita-value">{patient.phai === 0 ? 'Nam' : 'Nữ'}</label>
                    </div>
                </div>
                {showContact && <a href='#' onClick={() => {setVisibleContact((c) => !c)}}><u>Thông tin liên lạc</u></a>}
                {visibleContact &&
                <>
                    <div className="row mt-2">
                        <div className="col-6 d-flex flex-column">
                            <label className="mita-title">Điện thoại</label>
                            <label className="mita-value">{patient.dienThoai}</label>
                        </div>
                        <div className="col-6 d-flex flex-column">
                            <label className="mita-title">Email</label>
                            <label className="mita-value">{patient.email}</label>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className="col-12 col-12 d-flex flex-column">
                            <label className="mita-title">Địa chỉ</label>
                            <MitaDisplayLongText
                                content={patient.soNha}
                                maxLength={64} // mặc định 64
                                showMoreText={showMoreText}
                                showLessText={showLessText}
                            />
                        </div>
                    </div>
                </>
                }
            </div>
        <Drawer 
            className='custom-ant-drawer'
            maskClosable={false}
            mask={true}
            closable={false}
            width={"40%"}
            placement="right"
            visible={visibleHoSo}
            destroyOnClose={true}
            onCancel={() => { setVisibleHoSo(false)}}>
                <DetailHoSo patientId={patient.maBN} onClose={() => setVisibleHoSo(false)} />
        </Drawer>
        </Fragment>
    )
};
