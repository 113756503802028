import React, { Fragment } from 'react';

function UnSupport(props) {
    return (
        <Fragment>
            <div className="containerChilderWrapper d-flex justify-content-center align-items-center"
                style={{ height: '100%' }}>
                <h3>{props.title ? props.title : "Chức năng không khả dụng"}</h3>
            </div>
        </Fragment>
    );
}

export default UnSupport;