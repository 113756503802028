import { DatePicker, Form, notification, Select, Table, Tabs, Pagination, Tooltip } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import Chart from './chartdoanhso';
import TheoDichVuBN from './theodichvuBN';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../../services/configService';
import { connect } from 'react-redux';

const { RangePicker } = DatePicker;
const { Option } = Select;

class DoanhSo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            loai: '',
            tenloai: 'Tất cả',
            expandableRow: [],
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            paymenttype: 0,
            listNhomVP: [],
            currentPage: 1,
            currentSize: 10
        };
    }

    componentDidMount() {
        // UsersService.CheckPremissionMultisite().then(result => {
        //     if (result.isSuccess) {
        //         this.setState({
        //             isMultisite: result.item
        //         });
        //     }
        // });
        // this.GetListSite();
        this.LoadThongKeDoanhSo();
        this.getListActiveServiceGroups();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadThongKeDoanhSo(page = 1, size = 10) {
        Actions.setLoading(true);
        StatisticService.ThongKeDoanhSoDichVu(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh, this.state.loai, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data.map(x => ({ ...x, isActive: false })),
                    totalRecords: result.totalRecords,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    LoadThongKeDoanhSoChiTiet(row, page = 1, size = 10) {
        Actions.setLoading(true);
        StatisticService.ThongKeDoanhSoDichVuChiTiet(row.ngay, this.state.chinhanh, this.state.loai, page, size, false).then(result => {
            if (result.isSuccess) {
                let data = this.state.dataSource;
                let index = data.findIndex(x => x.ngay == row.ngay);
                if (index >= 0) {
                    data[index]['lstData'] = result.data;
                    data[index].currentPage = page
                    data[index].currentSize = size
                    data[index].totalRecords = result.totalRecords || 0
                    this.setState({
                        dataSource: data.map(x => ({ ...x })),
                    });
                }
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeDoanhSo() {
        Actions.setLoading(true);
        StatisticService.ExportDoanhSo(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh, this.state.loai, this.state.tenloai)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDoanhThuTheoDichVu";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    setExpandable(index) {
        let lst = this.state.expandableRow;
        if (lst.length === 0) {
            this.setState({ expandableRow: [index] });
            return;
        }
        let old = lst.indexOf(index);
        if (old < 0) {
            this.setState({ expandableRow: [index] })
        }
        else {
            this.setState({ expandableRow: [] })
        }
    }

    getListActiveServiceGroups() {
        ConfigService.getListActiveServiceGroups().then(res => {
            if (res.isSuccess) this.setState({ listNhomVP: res.data });
        }).catch(() => { })
    }

    customExpandIcon(props) {
        if (props.expanded) {
            return <button className="btn btn-color btn-sm" onClick={e => {
                props.onExpand(props.record, e);
            }}><i className="fas fa-minus fa-1x" /></button>
        }
        else {
            return <button className="btn btn-color btn-sm" onClick={e => {
                props.onExpand(props.record, e);
            }}><i className="fas fa-plus fa-1x" /></button>
        }
    }

    render() {
        const columnChild = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
            },
            {
                title: 'Thành tiền',
                dataIndex: 'tongtien',
                key: 'tongtien',
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            }
        ]
        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                render: (text, record, index) => {
                    return <span>{moment(text).format('DD/MM/YYYY')}</span>;
                },
                width: 100
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'tongtien',
                key: 'tongtien',
                width: 600,
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            }
        ]
        const expandable = {
            expandedRowRender: row => {
                return (
                    <div style={{ padding: 10, width: '100%' }}>
                        <Table dataSource={row.lstData} pagination={{
                            total: row.totalRecords,
                            current: row.currentPage || 1,
                            size: row.currentSize || 10,
                            showTotal: total => `Tổng: ${total}`,
                            showSizeChanger: true,
                            onChange: (page, size) => {
                                this.LoadThongKeDoanhSoChiTiet(row, page, size)
                            },
                            onShowSizeChange: (page, size) => this.LoadThongKeDoanhSoChiTiet(row, 1, size)
                        }} columns={columnChild} scroll={{ x: 'max-content' }} />
                    </div>)
            },
        };
        return (
            <>
                <div className='containerChilderWrapper'>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <Tabs animated={true}>
                        <Tabs.TabPane tab={"Biểu Đồ"} key="1" style={{ height: '80vh' }}>
                            <Chart ref={(c) => this.chart = c}></Chart>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"Doanh thu theo dịch vụ"} key="2">
                            <div style={{ height: '100%', overflow: 'auto' }}>
                                <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 15 }}>
                                    {/* {this.state.isMultisite === true &&
                                        <Form.Item>
                                            <Select
                                                style={{ width: '200px' }}
                                                showSearch
                                                allowClear={false}
                                                value={this.state.chinhanh}
                                                onChange={(e) => {
                                                    this.setState({ chinhanh: e })
                                                }}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option key={-1} value={''}>Chi nhánh</Option>
                                                {this.state.listSite.map((row, index) => {
                                                    return (
                                                        <Option key={index} value={row.id}>{row.ma}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    } */}
                                    <Form.Item>
                                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                            allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                            placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                            onChange={(value => this.setState({ ngay: value, expandableRow: [] }, () => this.LoadThongKeDoanhSo(this.state.currentPage, this.state.currentSize)))}
                                            renderExtraFooter={() =>
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.setState({ ngay: [moment().subtract(7, 'd'), moment(new Date())], expandableRow: [] }, () => {
                                                                this.LoadThongKeDoanhSo(this.state.currentPage, this.state.currentSize)
                                                            })
                                                        }}>
                                                        7  Ngày
                                                    </div>
                                                    <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                        onClick={() => {

                                                            this.setState({ ngay: [moment().startOf('month'), moment(new Date())], expandableRow: [] }, () => {
                                                                this.LoadThongKeDoanhSo(this.state.currentPage, this.state.currentSize)
                                                            })
                                                        }}>
                                                        Tháng hiện tại
                                                    </div>
                                                    <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.setState({ ngay: [moment().startOf('quarter'), moment(new Date())], expandableRow: [] }, () => {
                                                                this.LoadThongKeDoanhSo(this.state.currentPage, this.state.currentSize)
                                                            })
                                                        }}>
                                                        Quý hiện tại
                                                    </div>
                                                    <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.setState({ ngay: [moment().startOf('year'), moment(new Date())], expandableRow: [] }, () => {
                                                                this.LoadThongKeDoanhSo(this.state.currentPage, this.state.currentSize)
                                                            })
                                                        }}>
                                                        Năm hiện tại
                                                    </div>
                                                </div>

                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ marginLeft: 20 }}>
                                        <Tooltip title="Nhóm">
                                            <Select
                                                style={{ width: 200 }}
                                                value={this.state.loai}
                                                showSearch
                                                onChange={(e, x) => this.setState({ loai: e, tenloai: x.children })}>
                                                <Option key={"All"} value={''}>Tất cả nhóm dịch vụ</Option>
                                                {this.state.listNhomVP && this.state.listNhomVP.map((nhomVp, index) => {
                                                    return (
                                                        <Option key={index} value={nhomVp.value}>{nhomVp.label}</Option>
                                                    )
                                                })}
                                                <Option key={"Thuốc"} value={'Thuoc'}>Thuốc</Option>
                                                <Option key={"Thuốc theo đơn"} value={'ThuocTheoDon'}>Thuốc theo đơn</Option>
                                                <Option key={"Thuốc bán lẻ"} value={'ThuocBanLe'}>Thuốc bán lẻ</Option>
                                                {/* <Option key={"Gói khám"} value={'GoiKham'}>Gói khám</Option> */}
                                            </Select>
                                        </Tooltip>
                                    </Form.Item>
                                    <Form.Item>
                                        <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => {
                                            this.setState({ expandableRow: [] })
                                            this.LoadThongKeDoanhSo(this.state.currentPage, this.state.currentSize)
                                        }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                    </Form.Item>
                                    <Form.Item>
                                        <button className="btn btn-color" onClick={() => { this.ExportThongKeDoanhSo() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                    </Form.Item>
                                </div>
                                <div style={{ height: 'calc(100% - 135px)' }}>
                                    <div className="h-100 hide-row-expand">
                                        <Table scroll={{ x: 'max-content', y: 0 }}
                                            pagination={false}
                                            dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index}
                                            expandable={expandable}
                                            expandIconColumnIndex={-1}
                                            expandRowByClick={true}
                                            expandedRowKeys={this.state.expandableRow}
                                            expandIcon={(props => this.customExpandIcon(props))}
                                            onRow={(record, index) => {
                                                // console.log(record,this.state.expandableRow.indexOf(index));
                                                // if (this.state.expandableRow.indexOf(index) < 0) {

                                                // }
                                                return {
                                                    onClick: e => {
                                                        let data = this.state.dataSource;
                                                        data[index].isActive = !record.isActive
                                                        this.setState({
                                                            dataSource: data.map(x => ({ ...x }))
                                                        });
                                                        if (record.isActive == true) {
                                                            this.LoadThongKeDoanhSoChiTiet(record, 1, 10)
                                                        }
                                                        this.setExpandable(index);
                                                    }
                                                };
                                            }}
                                            rowClassName={(row, index) => {
                                                const xx = index % 2 === 0 ? 'rowCustomSelectNewEven' : 'rowCustomSelectNewOdd'
                                                return xx
                                            }}
                                        />
                                    </div>
                                    <Pagination
                                        current={this.state.currentPage}
                                        size={this.state.currentSize}
                                        style={{ textAlignLast: "center", marginTop: "10px" }}
                                        total={this.state.totalRecords || 0}
                                        showTotal={total => `Tổng: ${total}`}
                                        onChange={(page, size) => { this.setState({ currentPage: page, currentSize: size, expandableRow: [] }); this.LoadThongKeDoanhSo(page, size) }}
                                        onShowSizeChange={(page, size) => {
                                            this.setState({ currentPage: 1, currentSize: size, expandableRow: [] });
                                            this.LoadThongKeDoanhSo(1, size)
                                        }
                                        }
                                        showSizeChanger={true}
                                    />
                                </div>
                                {/* <Pagination
                                    position={['bottomCenter']}
                                    showSizeChanger={true}
                                    style={{ textAlignLast: "center", marginTop: 15 }}
                                    total={this.state.totalRecords}
                                    showTotal={(total) => (`Tổng: ${total}`)}
                                /> */}
                            </div>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={"Doanh thu theo bệnh nhân"} key="5">
                            <TheoDichVuBN doanhso={true} paymenttype={this.state.paymenttype} chinhanh={this.props.siteid}/>
                        </Tabs.TabPane>
                    </Tabs>


                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DoanhSo);