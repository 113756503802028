import { Pagination, Table } from 'antd';
import React, { Component } from 'react';


export default class InvoiceLst extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupList: false,
            page: 1,
            size: 10,
            key: '',
            totalRecords: 0,
        };
    }
    updateDataTable(key = '', page = 1, size = 10) {
        if (this.props.changePage) {
            this.props.changePage(key, page, size);
        }
    }
    render() {
        return (
            <div style={{ padding: "5px 20px" }}>

                {/* <div className="search-input mb-2">
                    <Search placeholder="input search text" onSearch={value => this.updateDataTable(value)} enterButton />
                </div> */}


                <Table
                    dataSource={this.props.dataSource} columns={this.props.column} rowKey={(row, index) => index}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: e => {
                                if (this.props.ClickRow)
                                    this.props.ClickRow(record);
                            }
                        };
                    }}
                    rowClassName={(record, rowIndex) => {
                        return `rowCustom`
                    }}
                    pagination={<Pagination
                        total={this.props.totalRecords}
                        showTotal={total => `Tổng: ${this.props.totalRecords}`}
                        showSizeChanger={true}
                    />}
                />
            </div>
        )
    }
}
