import React, { useRef, Fragment, useState, useEffect, useCallback } from "react";
import { Tabs, Collapse, Button, Drawer, Popconfirm } from 'antd';
import HeaderModal from "../../../configureHIS/common/headerModal";
import PatientInfo from "../../../screens/patient/component/patientInfo";
import MedicalHistory from "./components/MedicalHistory";
import { HISTORYFEATURE, HISTORYFEATURETYPE } from '../../../configureHIS/common/constants';
import * as MedicalExamService from '../../../services/MedicalExam';
import * as Actions from '../../../libs/actions';
import Speaker from '../../../components/speaker';
import History from '../../../components/history/indexNew';
import ClinicalExam from "./detail-components/clinical-exam";
import Subclinical from "./detail-components/subclinical";

const { Panel } = Collapse;
export default (props) => {
    const _cinicalExam = useRef();
    const hiddenLink = useRef();
    const _historyRef = useRef();
    const _medicalHistoryRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [dataSource, setDataSource] = useState(props.data);
    const [roomName, setRoomName] = useState(props.roomName);
    const [isEdit, setIsEdit] = useState(props.isEdit);
    const [visibleMedicalHistory, setVisibleMedicalHistory] = useState(false);
    const [tabActiveKey, setTabActiveKey] = useState('clinical-exam');
    const [clinicalActiveKey, setClinicalActiveKey] = useState('examinationInfo');
    const [examPatientStatus, setExamPatientStatus] = useState(props.data.examPatientStatus)

    useEffect(() => {
        setIsEdit(props.isEdit);
    },[props.isEdit])

    const onCloseMedicalHistory = () => {
        _medicalHistoryRef.current &&  _medicalHistoryRef.current.Reload();
        setVisibleMedicalHistory(false);
    }

    const PrintExam = () => {
        let data={
            examinationId: dataSource.examinationId,
        };
        MedicalExamService.Print(data).then(result => {
            if (result.isSuccess) {
                Actions.DoPrint(result.data)
            }
            else {
            }
        }).catch(err => {
            
        });
    }

    const SaveExamination = (isDone) => {
        _cinicalExam.current && _cinicalExam.current.onSaveExam(isDone);
        forceUpdate();
    }

    return (
        <Fragment>
        <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
            <div className="list-page">
                <HeaderModal title={`${dataSource.serviceName} - ${dataSource.patientName}`} onClose={() => { props.onClose() }} />
                <div className="list-page-body">
                    <div className="w-100 h-100 d-flex justify-content-between" style={{ overflowY: "auto" }}>
                        <div className="border overflow-auto" style={{width:'40%'}}>{/**Left Panel */}
                            <Collapse defaultActiveKey={['pif', 'htr', 'mehis']} expandIconPosition="right">
                                <Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="pif">
                                    <PatientInfo patientId={dataSource.patientCode} />
                                </Panel>
                                <Panel header={
                                    <label className='mita-header-title d-flex justify-content-between align-items-center w-100'>
                                        <span>Thông tin tiền sử</span>
                                        {isEdit && <span onClick={(e) => { e.stopPropagation(); setVisibleMedicalHistory(true) }}><i className="fas fa-pen"></i></span> }
                                    </label>} key="mehis">
                                        <MedicalHistory ref={_medicalHistoryRef} isEdit={false} patientId={dataSource.patientCode} />
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Lịch sử khám</label>} key="htr">
                                    <div className="w-100 my-2" style={{ lineHeight: "20px" }}>
                                        <History 
                                            ref={_historyRef} 
                                            iShowResult={true} 
                                            feature= {[HISTORYFEATURE.KHAMBENH]}
                                            patientID={dataSource.patientCode} 
                                        />
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                        <div className="list-page border w-100 pl-2">{/**Right Panel */}
                            <div className='list-page-body'>
                                <Tabs defaultActiveKey="clinical-exam" onChange={(e) => {setTabActiveKey(e)}}
                                        tabBarExtraContent={<Speaker sentence={`Mời khách hàng ${dataSource.patientName} vào phòng ${roomName && roomName.toLowerCase().replace("phòng ", "")}`} notShowButton={true} />}>
                                    <Tabs.TabPane tab="Khám lâm sàng" key="clinical-exam">
                                        <ClinicalExam 
                                            ref={_cinicalExam}
                                            tabActiveKey={(key) => setClinicalActiveKey(key)}
                                            data={dataSource} 
                                            roomName={roomName} 
                                            examRoom={props.examRoom} 
                                            clinicRoom={props.clinicRoom} 
                                            isEdit={isEdit} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Kết quả CLS" key="subclinical">
                                        <Subclinical data={dataSource} />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                            
                            <div className={"list-page-footer d-flex justify-content-between mt-2" }>{/* footer (nếu có) */}
                                <div>
                                    <Button className="pl-0 mr-2" type="link" onClick={() => PrintExam()}><i className='fa fa-print mr-2'/>In phiếu khám</Button>
                                    {examPatientStatus !== "x" && tabActiveKey === 'clinical-exam' && clinicalActiveKey === 'examinationInfo' ?
                                        <Popconfirm
                                            title="Xác nhận khám xong?"
                                            onConfirm={() => { setExamPatientStatus("x"); SaveExamination(true) }}
                                            okText="Đồng ý"
                                            cancelText="Đóng"
                                        >
                                            <button className="btn btn-success"><i className="fas fa-check mr-2" /> Khám xong</button>
                                        </Popconfirm>
                                    : ''
                                    }
                                </div>
                                <div className='d-flex justify-content-end' >
                                    {examPatientStatus!== "x" && tabActiveKey === 'clinical-exam' && clinicalActiveKey === 'examinationInfo' ?
                                        <button className="btn btn-color mr-2" onClick={() => { SaveExamination(false) }}><i className="fas fa-save" /> Lưu</button>
                                    : ''
                                    }
                                    <button 
                                        className="btn btn-secondary" 
                                        onClick={() => { props.onClose() }}>
                                    <i className="far fa-times-circle mr-2" /> Đóng</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <Drawer className='custom-ant-drawer'
            maskClosable={false}
            mask={true}
            closable={false}
            width={"40%"}
            placement="right"
            visible={visibleMedicalHistory}
            destroyOnClose={true}
        >
            <MedicalHistory 
                isEdit={true} 
                patientId={dataSource.patientCode} 
                patientName={dataSource.patientName} 
                onClose={() => onCloseMedicalHistory()} />
        </Drawer>
        </Fragment>
    )
};
