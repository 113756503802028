import React from 'react';
import { Tree } from 'antd';

export default class TreeRender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedKeys: [],
            checkedKeys: [],
            selectedKeys: [],
            autoExpandParent: true,
            halfCheckedKeys: [],
        }
    }


    onExpand = expandedKeys => {

        this.setState({expandedKeys});

    };
    getSelected (){
        if (this.state.halfCheckedKeys) {
            return this.state.checkedKeys.concat(this.state.halfCheckedKeys);
        } else {
            return this.state.checkedKeys;
        }
    }
    setelected (checkedKeys){
        this.setState({checkedKeys});
    }
    onCheck = (checkedKeys, e) => {
        this.setState({ checkedKeys: checkedKeys.checked, halfCheckedKeys: e.halfCheckedKeys });
    };

    onSelect = (selectedKeys, info) => {
        
        this.setState({selectedKeys});
    };
    render() {

        return (
            <Tree
                checkable
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={this.state.autoExpandParent}
                onCheck={this.onCheck}
                checkedKeys={this.state.checkedKeys}
                onSelect={this.onSelect}
                selectedKeys={this.state.selectedKeys}
                treeData={this.props.data}
                checkStrictly={true}
                
            />
        );
    }
};
