import * as ApiCaller from '../../libs/httpRequests';

const prefix = 'Survey';

export function GetListSurveys(key, page, size, from, to)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListSurveys?key=${key}&page=${page}&size=${size}&from=${from}&to=${to}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetSurveyDetail(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetSurveyDetail/${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function DeleteSurvey(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteSurvey/${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};