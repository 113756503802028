import { notification } from 'antd';
import React ,{ Fragment, useState, useEffect, useCallback, forwardRef, useImperativeHandle } from "react";
import * as PatientService from '../../../services/patientService';

export default forwardRef((props, ref) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    //biến cục bộ
    const [maql, setMaql] = useState('')
    const [mach, setMach] = useState('')
    const [nhietdo, setNhietdo] = useState('')
    const [chieucao, setChieucao] = useState('')
    const [cannang, setCannang] = useState('')
    const [huyetap, setHuyetap] = useState('')
    const [nhiptho, setNhiptho] = useState('')
    const [detail, setDetail] = useState(null);

    useImperativeHandle(ref, () => ({
        Reload: () => {
            GetVitalSign();
        },
    }));

    useEffect(() => {
        setMaql(props.maql);
    }, [props.maql])   

    useEffect(() => {
        if(maql !== '')
        GetVitalSign();
    }, [maql])

    const GetVitalSign = () => {
        PatientService.GetVitalSigns(maql).then(result => {
            if (result.isSuccess) {
                setDetail(result.data);
                setMach(result.data?.mach);
                setNhietdo(result.data?.nhietDo);
                setChieucao(result.data?.chieuCao);
                setCannang(result.data?.canNang);
                setNhiptho(result.data?.nhipTho);
                setHuyetap(result.data?.huyetAp);
                forceUpdate()
            } else {
            notification.error({ message: result.error.messageText });
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.error({ message: err.error.messageText });
            else notification.error({ message: err });
        })
    }

    return (
        <Fragment>
        {detail ? 
            <div className={`col-${props.col ?? 12}`}>
                <div className="row">
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Chiều cao</label>
                        <label className="mita-value">{chieucao} cm</label>
                    </div>
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Cân Nặng</label>
                        <label className="mita-value">{cannang} kg</label>
                    </div>
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">BMI</label>
                        <label className="mita-value">{cannang && chieucao && Number(cannang/(Math.pow(chieucao/100,2))).toFixed(2)}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Huyết áp</label>
                        <label className="mita-value">{huyetap} mmHg</label>
                    </div>
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Mạch</label>
                        <label className="mita-value">{mach} lần/phút</label>
                    </div>
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Nhiệt độ</label>
                        <label className="mita-value">{nhietdo}℃</label>
                    </div>
                </div>
            </div>
        :
            <div className='col-12 d-flex justify-content-center'>
                <p className='text-body'>Không có thông tin</p>
            </div>
        }
        </Fragment>
    )
});
