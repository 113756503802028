import React, { Fragment, useRef, useState, useEffect, useCallback } from "react";
import moment from "moment";
import { List, Checkbox, Dropdown, Menu, Tooltip, Button, Select, DatePicker, Input, Popover, Drawer } from 'antd';
import * as SystemConfig from '../../../configure/systemConfig';
import * as Actions from '../../../libs/actions';
import * as PatientService from '../../../services/patientService';
import * as MedicalExamService from '../../../services/MedicalExam';
import * as CoporationServices from "../../../services/CorporationServices";
import Speaker from '../../../components/speaker';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import Notify, { NotifyStatus } from '../../../components/notify';
import { isNullOrEmpty } from '../../../libs/util';
import HeaderModal from "../../../configureHIS/common/headerModal";
import DetailHoSo from '../../exam/hoso/chitietNew';
import ChangeExamRoom from "./components/ChangeExamRoom";
import Detail from "./Detail";

const { Option } = Select;
export default (props) => {
    const _notiRef = useRef();
    const hiddenLink = useRef();
    const firstRender = useRef(true);
    // Khoa Phòng
    const [clinicId, setClinicId] = useState(props.compprops.clinicID);
    const [clinicName, setClinicName] = useState(props.compprops.clinicName);
    const [roomId, setRoomId] = useState(props.compprops.roomCode);
    const [roomName, setRoomName] = useState(props.compprops.roomName);
    // Datasource
    const [listResult, setListResult] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [listCoporations, setListCoporations] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    // Filter
    const [skipCount, setSkipCount] = useState(0);
    const [maxResultCount, setMaxResultCount] = useState(10);
    const [sortFields, setSortFields] = useState([]);
    const [sortTypes, setSortTypes] = useState([]);
    const [sortingType, setSortingType] = useState('');
    const [sortingField, setSortingField] = useState('');
    const [dateFrom, setDateFrom] = useState(moment());
    const [dateTo, setDateTo] = useState(moment());
    const [coporationId, setCoporationId] = useState();
    const [processStatus, setProcessStatus] = useState(null);
    const [keyword, setKeyword] = useState('');
    // Change Exam Room
    const [selectedExam, setSelectedExam] = useState({});
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminateCheckAll, setIndeterminateCheckAll] = useState(false);
    const listPageSize = [10, 20, 50, 100];
    // Modal
    const [isEdit, setIsEdit] = useState(false);
    const [visibleChangeExam, setVisibleChangeExam] = useState(false);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [visibleHoSo, setVisibleHoSo] = useState(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        setClinicId(props.compprops.clinicID);
        setClinicName(props.compprops.clinicName);
        setRoomId(props.compprops.roomCode);
        setRoomName(props.compprops.roomName);
    }, [props])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            GetList();
        }
        getListCoporations();
    }, []);

    useEffect(() => {
        if (firstRender.current) return;
        GetList();
    }, [skipCount])

    useEffect(() => {
        resetToFirstPage();
    }, [sortingField, sortingType, maxResultCount]);

    useEffect(() => {
        setIndeterminateCheckAll(selectedRecords.length > 0 && selectedRecords.length < listResult.length);
        if (!firstRender.current) setCheckAll(selectedRecords && selectedRecords.length > 0 && selectedRecords.length == listResult.length);
    }, [selectedRecords, listResult]);

    const validate = () => {
        let listErr = [];
        if (dateFrom > dateTo) listErr.push('"Ngày đăng ký từ" phải nhỏ hơn "ngày đăng ký đến"');
        return listErr;
    }

    const GetList = () => {
        let listErr = validate();
        if (listErr.length > 0) {
        let err = listErr.join('\n');
            _notiRef.current.Show(err, NotifyStatus.Error);
        return;
        }
        Actions.setLoading(true);
        let filterModel = {
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            sorting: ((sortingField ?? '').length < 1 ? 'ngaydangky' : sortingField) + ((sortingType ?? '').length < 1 ? ' asc' : sortingType),
            keyword: keyword,
            dateFrom: dateFrom,
            dateTo: dateTo,
            coporationIds: coporationId ? [coporationId] : [],
            examPatientStatus: processStatus,
            examRoom: roomId,
            clinicRoom : clinicId
        }
        MedicalExamService.GetListByExamRoom(filterModel).then((result) => {
            if (result.isSuccess) {
                setTotalRecord(result.data.totalCount);
                setSortFields(result.data.sortFields);
                setSortTypes(result.data.sortTypes);
                if (skipCount === 0) {
                    setListResult(result.data.items);
                }
                else {
                    setListResult([...listResult, ...result.data.items]);
                }
                _notiRef && _notiRef.current.Close();
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const getListCoporations = () => {
        CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
            if (result.isSuccess) {
                setListCoporations(result.data);
                _notiRef && _notiRef.current.Close();
            }
            else {
                if (result.error)
                    _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
                if (result.err)
                    _notiRef.current.Show(result.err.msgString, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
        })
    }

    const rowOptions = (record) => {
        let items = [];
        if (record.examPatientStatus === "x") {
            items.push(
                {
                    label:
                        <a href="#" onClick={(e) => {
                            onClickFunction(e, record, "preview");
                        }}><i className='fa fa-eye mr-2' />Xem chi tiết</a>,
                    key: 'viewdetail_action',
                },
            )
        }
        if (record.examPatientStatus !== "x") {
            items.push(
                {
                    label:
                        <a href="#" onClick={(e) => {
                            onClickFunction(e, record, "edit");
                        }}><i className="fas fa-user-md mr-2"></i>{!isNullOrEmpty(record.examPatientStatus) ? "Cập nhật" : "Tiếp nhận khám"}</a>,
                    key: 'update_action',
                },
                {
                    label:
                        <a className="pl-0 stopRowClick"
                            onClick={(e) => { 
                                setSelectedExam(record);
                                setSelectedRecords([]);
                                setVisibleChangeExam(true);
                            }}
                        ><i className="fas fa-exchange-alt mr-1" />Đổi khoa phòng</a>,
                    key: 'change_room_action',
                },
            );
        }
        if (!isNullOrEmpty(record.examPatientStatus)) items.push({
            label:
                <a href="#" onClick={(e) => {
                    onClickFunction(e, record, "print");
                }}><i className='fa fa-print mr-2' />In kết quả</a>,
            key: 'printresult_action',
        });
        return (
            <Menu items={items} />
        )
    }

    const PrintExam = (examinationId) => {
        let data={
            examinationId: examinationId,
        };
        MedicalExamService.Print(data).then(result => {
            if (result.isSuccess) {
                Actions.DoPrint(result.data)
            }
            else {
            }
        }).catch(err => {
        }).finally(() => {
        });
    }

    const onClickFunction = (e, record, sel) => {
        if (
            e.target.className.indexOf('stopRowClick')>-1
        ) return;
        if (!isNullOrEmpty(sel)) {
            e.stopPropagation();
            e.target.parentNode.parentElement.parentNode.parentElement.classList.add("ant-dropdown-hidden");
            if (sel === "preview") loadDetail(record);
            if (sel === "edit") loadDetailToEdit(record);
            if (sel === "print") PrintExam(record.examinationId);
        } else {
            if (!isNullOrEmpty(record) && record.examPatientStatus === "x" ) loadDetail(record);
            if (!isNullOrEmpty(record) && record.examPatientStatus !== "x") loadDetailToEdit(record);
        }
    }

    const handleOpenHoSoKham = (e, record) => {
        e.stopPropagation();
        setSelectedData(record);
        setVisibleHoSo(true);
    }

    const loadDetail = (row) => {
        if (row) {
            setIsEdit(false);
            setVisibleDetail(true);
            setSelectedData(row);
        }
    }

    const loadDetailToEdit = (row) => {
        if (row) {
            setIsEdit(true);
            setVisibleDetail(true);
            setSelectedData(row);
        }
    }

    const resetFilter = () => {
        setCoporationId();
        setDateFrom(moment());
        setDateTo(moment());
        setProcessStatus(0);
        setKeyword();
        setProcessStatus(null);
    }

    const resetToFirstPage = () => {
        if (skipCount != 0) setSkipCount(0);
        else GetList();
    }

    const onChangeCheckAll = (e) => {
        setCheckAll(e.target.checked);
        if (e.target.checked) {
            setSelectedRecords(listResult)
        }
        else { 
            setSelectedRecords([]);
        }
    }

    const onCloseChangeExamRoom = (e) => {
        setVisibleChangeExam(false); 
        setSelectedExam({});
    }

    const renderStatus = (record) => {
        switch(record.examPatientStatus) {
            case '?':
                return (
                    <label className="mita-value"><span>Đang khám</span></label>
                );
            case 'x':
                return (
                    <label className="mita-value"><span>Đã khám</span>&nbsp;
                        <Tooltip title={moment(record.doneAt).format(SystemConfig.FORMAT_DATE_TIME)}><i className='far fa-clock' /></Tooltip>
                    </label>
                );
            default:
                return (
                    <label className="mita-value"><span>Chưa khám</span>&nbsp;
                        <Speaker sentence={`Mời khách hàng ${record.patientName} vào phòng ${roomName && roomName.toLowerCase().replace("phòng ", "")}`} notShowButton={true} />
                    </label>
                );
        }
    }

    return (
        <Fragment>
        <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
            <div className="list-page containerChilderWrapper">
                <HeaderModal title={`Danh sách - ${clinicName} - ${roomName}`} />
                <Notify ref={_notiRef} />
                <div className="list-page-body">
                    <div className="list-page-search">
                        <div className="row">
                            <div className="col-md-4 d-flex flex-column">
                                <label>Ngày đăng ký</label>
                                <div className="d-flex">
                                    <DatePicker
                                        allowClear={false}
                                        locale={viVN}
                                        showNow={false}
                                        className="w-100"
                                        placeholder="Từ ngày"
                                        value={dateFrom}
                                        format={SystemConfig.FORMAT_DATE}
                                        onChange={(e) => {
                                            setDateFrom(e ? moment(e) : null);
                                        }}
                                        
                                    />
                                    <div className="m-auto">&nbsp;~&nbsp;</div>
                                    <DatePicker
                                        allowClear={false}
                                        locale={viVN}
                                        showNow={false}
                                        className="w-100"
                                        placeholder="Đến ngày"
                                        value={dateTo}
                                        format={SystemConfig.FORMAT_DATE}
                                        onChange={(e) => {
                                            setDateTo(e ? moment(e) : null);
                                        }}
                                        disabledDate={(date) => date < moment(dateFrom)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <label>Nguồn khách hàng</label>
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) => {
                                        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    className="w-100"
                                    placeholder="Chọn nguồn khách hàng"
                                    maxTagCount='responsive'
                                    value={coporationId}
                                    onChange={(value) => { setCoporationId(value); }}
                                >
                                    {(listCoporations ?? []).map((record, index) => {
                                        return (
                                            <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <label>Trạng thái</label>
                                <Select
                                    className="w-100"
                                    placeholder="Chọn trạng thái"
                                    value={processStatus}
                                    onChange={(value) => { setProcessStatus(value); }}
                                    allowClear={true}
                                    onClear={() => setProcessStatus(null)}
                                >
                                    <Option key={0} value={0}>Chưa khám</Option>
                                    <Option key={1} value={1}>Đang khám</Option>
                                    <Option key={2} value={2}>Đã khám</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12 d-flex flex-column">
                                <label>Từ khóa bệnh nhân</label>
                                <Input
                                    placeholder="Nhập mã, tên bệnh nhân hoặc quét mã PID"
                                    value={keyword}
                                    onChange={(e) => {
                                        setKeyword(e.target.value);
                                    }}
                                    onPressEnter={(e) => {
                                        resetToFirstPage();
                                    }}
                                ></Input>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-md-12 d-flex justify-content-end'>
                                <button onClick={() => { resetToFirstPage() }} className="btn btn-color"><i className="fa fa-search mr-1" />Tìm kiếm</button>
                                <button className="btn btn-secondary ml-2"
                                    onClick={() => { resetFilter(); }}
                                > <i className="fa fa-eraser mr-1" />Xóa dữ liệu nhập</button>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-md-12 pl-1 d-flex justify-content-between'>
                                <div>
                                    <Checkbox 
                                        className="mr-2"
                                        checked={checkAll}
                                        indeterminate={indeterminateCheckAll}
                                        onChange={(e) => {
                                            onChangeCheckAll(e)
                                        }}
                                    ></Checkbox>
                                    <Popover
                                        content={<div style={{ width: 400}}>
                                            <ChangeExamRoom 
                                                examination={selectedRecords} 
                                                examRoomId={roomId}
                                                departmentId={clinicId}
                                                onComplete = {() => { GetList(); onCloseChangeExamRoom();}}
                                                onClose={() => { onCloseChangeExamRoom(); }} />
                                        </div>}
                                        onVisibleChange={(e) => setVisibleChangeExam(e)}
                                        trigger="click"
                                        placement="right"
                                        visible={visibleChangeExam && selectedRecords.length > 0}
                                    >
                                        <Button className="pl-0"
                                            type="link"
                                            onClick={(e) => { 
                                                setSelectedExam({}); 
                                                setVisibleChangeExam(true); 
                                                }}
                                        ><i className="fas fa-exchange-alt mr-1" />Đổi khoa phòng</Button>
                                    </Popover>
                                </div>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <label>Sắp xếp theo</label>
                                    <Select className="ml-1" style={{ width: '140px' }}
                                        placeholder="Ngày đăng ký, tên"
                                        value={sortFields.find(x => x.selected)?.key}
                                        onChange={(value) => { setSortingField(value ?? ''); }}
                                    >
                                        {(sortFields ?? []).map((record, index) => {
                                            return (
                                                <Option key={record.key} value={record.key}>{record.text}</Option>
                                            )
                                        })}
                                    </Select>
                                    <Select className="mx-1" style={{ width: '100px' }}
                                        placeholder="Tăng dần/giảm dần"
                                        maxTagCount='responsive'
                                        value={sortTypes.find(x => x.selected)?.key}
                                        onChange={(value) => { setSortingType(value ?? ''); }}
                                    >
                                        {(sortTypes ?? []).map((record, index) => {
                                            return (
                                                <Option key={record.key} value={record.key}>{record.text}</Option>
                                            )
                                        })}
                                    </Select>
                                    <label>Hiển thị</label>
                                    <Select className="ml-1"
                                        placeholder="10, 20, 50, 100"
                                        value={maxResultCount}
                                        onChange={(value) => { setMaxResultCount(value); }}
                                    >
                                        {listPageSize.map((record, index) => {
                                            return (
                                                <Option key={record} value={record}>{record}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="list-page-table mt-2">
                    <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                        bordered={false}
                        dataSource={listResult}
                        renderItem={(record, index) => {
                            let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover";
                            return (
                                <List.Item
                                    onClick={(e) => { onClickFunction(e, record) }}
                                    key={`${record.examinationId}_${record.managermentId}`}
                                    className={className}>
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="d-flex flex-column justify-content-start h-100" style={{ width: 18 }}>
                                            {index + 1}
                                        </div>
                                        <div className="d-flex flex-column justify-content-start h-100" style={{ width: 16 }}>
                                            <Checkbox className="mr-2"
                                                checked={selectedRecords.map(x => x.examinationId).indexOf(record.examinationId) > -1}
                                                onChange={(e) => {
                                                    if (e.target.checked){
                                                        setSelectedRecords(c => [...c, record])
                                                    } else{
                                                        setSelectedRecords(selectedRecords.filter(x => x.examinationId !== record.examinationId))
                                                    } 
                                                    setSelectedExam({})
                                                }}
                                                onClick={(e) => {e.stopPropagation();}}
                                            ></Checkbox>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start h-100" style={{ width: '30%' }}>
                                            <div className="">
                                                <a href="#" onClick={ (e) => {handleOpenHoSoKham(e, record) }}>
                                                    <label className="mita-title">{record.patientName} - {record.patientCode}</label>
                                                </a>
                                            </div>
                                            <div className="">
                                                <label className="mita-value">{record.serviceName}</label>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start h-100" style={{ width: 'calc(36% - 46px)' }}>
                                            <div className="d-flex">
                                                <div style={{width: '40%'}}>
                                                    <label className="mita-title">Giới tính: </label>
                                                    <label className="mita-value">{record.gender}</label>
                                                </div>
                                                <div>
                                                    <label className='mita-title'>Ngày sinh: </label>
                                                    <label className="mita-value">{record.onlyYear == true ? record.yob : moment(record.dob).format(SystemConfig.FORMAT_DATE)}</label>
                                                </div>
                                            </div>
                                            <div className="">
                                                <label className="mita-title">Ngày đăng ký: </label>
                                                <label className="mita-value">{moment(record.registedDate).format(SystemConfig.FORMAT_DATE_TIME)}</label>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start h-100" style={{ width: '30%' }}>
                                            <div className="">
                                                <label className='mita-title'>Nguồn khách hàng: </label>
                                                <label className="mita-value"><a href="#" onClick={() => { }}>{record.coporationName}</a></label>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                { renderStatus(record) }
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start h-100" style={{ width: 12 }}>
                                            <Dropdown overlay={rowOptions(record)} trigger={['click']} onClick={(e) => { e.stopPropagation() }}>
                                                <a className="ant-dropdown-link"><i className='fas fa-ellipsis-v'></i></a>
                                            </Dropdown>
                                            <Popover
                                                content={<div style={{ width: 400}}>
                                                    <ChangeExamRoom 
                                                        examination={[selectedExam]} 
                                                        examRoomId={roomId}
                                                        departmentId={clinicId}
                                                        onComplete = {() => { GetList(); onCloseChangeExamRoom();}}
                                                        onClose={(e) => {  onCloseChangeExamRoom(e); }} />
                                                </div>}
                                                onVisibleChange={(e) => setVisibleChangeExam(e)}
                                                trigger="click"
                                                placement="right"
                                                visible={visibleChangeExam && selectedExam.examinationId === record.examinationId}
                                            />
                                        </div>
                                    </div>
                                </List.Item>
                            )
                        }}
                    >
                        <List.Item key="showMore">
                            <div className="w-100 d-flex justify-content-center">
                                <button
                                    className='btn btn-secondary'
                                    hidden={totalRecord <= listResult.length}
                                    onClick={() => {
                                        setSkipCount(skipCount + maxResultCount);
                                    }}
                                >Hiển thị thêm {totalRecord - listResult.length}</button>
                            </div>
                        </List.Item>
                    </List>
                </div>
            </div>
        </div>
        <Drawer
            className='custom-ant-drawer'
            maskClosable={false}
            mask={true}
            closable={false}
            width={"40%"}
            placement="right"
            visible={visibleHoSo}
            destroyOnClose={true}
            onCancel={() => { setVisibleHoSo(false) }}>
            <DetailHoSo patientId={selectedData.patientCode} onClose={() => setVisibleHoSo(false)} />
        </Drawer>
        <Drawer className='custom-ant-drawer'
            maskClosable={false}
            mask={true}
            closable={false}
            width={"80%"}
            placement="right"
            visible={visibleDetail}
            destroyOnClose={true}
        >
            <Detail
                roomName={roomName}
                data = {selectedData}
                isEdit = {isEdit}
                examRoom = {roomId}
                clinicRoom = {clinicId}
                onClose={() => { resetToFirstPage(); setVisibleDetail(false) }} 
            />
        </Drawer>
        </Fragment>
    )
};
