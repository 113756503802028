import React, { useState, useRef, useEffect } from 'react';
import { Button, Input, Table, notification, Pagination, DatePicker, Checkbox, Popconfirm, Tag, Modal, Steps, Tooltip, Select } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import Administrative from '../recept/components/administrative';
import * as RegisMediService from '../../services/regisMediService';
import * as Actions from '../../libs/actions';
import * as HotlineServices from "./hotlineservices";
import * as ChiDinhService from '../../services/chiDinhService';
import * as signalR from '@aspnet/signalr';
import * as Configure from '../../configure/urlConfig';
import * as AppointmentService from '../../services/appointmentService';
import ModalCLS from '../../components/MDCLS';
import AddOrder from '../lichhenlaymau/addOrder';
import DateTimePicker from 'react-datetime-picker';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import * as WorldfoneService from '../../services/worldfoneService';
import LoginWF from './loginWF';
import { connect } from 'react-redux';
import './style.css';
import { FORMAT_DATE_TIME } from '../../configure/systemConfig';
import { FORMAT_DATE } from '../../configure/systemConfig';
import { FORMAT_TIME_DATE } from '../../configure/systemConfig';
import NguonKhachHang from "../../screens/recept/register/nguonkhachhang";
import * as PatientService from '../../services/patientService';
import { IsActive_FeatureLH, FeatureDeactive } from '../../configure/featureConfig';
import { FEATURE } from "../../configureHIS/common/constants";
import HeaderModal from '../../configureHIS/common/headerModal';
import { isNullOrEmpty } from '../../libs/util';
import Notify, { NotifyStatus } from '../../components/notify';

const regEx = new RegExp('(^\s{0,}.{1,})');
const username = localStorage.getItem("userID");
const { Option } = Select;
const { RangePicker } = DatePicker;
function Hotline(props) {
    const { TextArea } = Input;
    const [status, setStatus] = useState("inprogress");
    const [mabn, setMabn] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [detail, setDetail] = useState("");
    const [key, setKey] = useState("");
    const [date, setDate] = useState([moment(), moment()]);
    const [listPatients, setListPatients] = useState([]);
    const [uncompleted, setUncompleted] = useState(false);
    const [tenbn, setTenbn] = useState('');
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [caseId, setCaseId] = useState(0);
    const [updateStatus, setUpdateStatus] = useState("");
    const [caseInfo, setCaseInfo] = useState([]);
    const [idLichHen, setIdLichHen] = useState(0);
    const [priority, setPriority] = useState(false);
    const [showMore, setShowMore] = useState('');
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [isMultisite, setIsMultisite] = useState(false);
    const [isLoginWF, setIsLoginWF] = useState(false);
    const [titleWF, setTitleWF] = useState('');

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        {
            title: 'Ngày tiếp nhận',
            dataIndex: 'ngay',
            key: 'ngay',
            align: 'right',
            width: 120,
            render: (date) => moment(date, 'DD/MM/YYYYY HH:mm:ss').format('HH:mm, DD/MM/YYYY')
        },
        {
            title: 'Họ và tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 240,
            align: 'center'
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'ngaysinh',
            key: 'ngaysinh',
            align: 'right',
            width: 90,
            render: (date, row) => row.onlyyear ? moment(date, 'YYYY/MM/DD').format('YYYY') : moment(date, 'YYYY/MM/DD').format(FORMAT_DATE)
        },
        {
            title: 'Giới tính',
            dataIndex: 'gioitinh',
            key: 'gioitinh',
            align: 'center',
            width: 80,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            align: 'center',
            width: 120,
        },
        {
            title: 'Ghi chú',
            key: 'ghichu',
            align: 'left',
            width: 300,
            render: (row) => {
                const note = row.ghichu;
                var value = note.indexOf("\n") === 0 ? note.substring(1, note.length) : note;
                const maxLength = 100;
                return (
                    <div>
                        <span style={{ whiteSpace: "pre-wrap" }}>{showMore === row.id ? value : value.substring(0, maxLength)}</span>
                        {note.length > maxLength && <span value={row.id} style={{ color: "blue", cursor: "pointer" }}
                            onClick={(e) => showMore === row.id ? setShowMore('') : setShowMore(row.id)}>{showMore === row.id ? " Thu gọn" : "...Xem thêm"}</span>}
                    </div>
                )
            }
        },
        {
            title: 'Trạng thái',
            key: 'hoanthanh',
            align: 'center',
            width: 150,
            render: (status) => {
                return (
                    status.huy ?
                        <Tag>Đã hủy</Tag>
                        :
                        status.hoanthanh === 1 ?
                            <Tag color="green">Xử lý xong</Tag>
                            :
                            <Tag color={(status.idlichhen && Number(status.idlichhen) > 0) || (status.idhenkham && Number(status.idhenkham) > 0) ? "blue" : status.datuvan ? "blue" : "red"}>{(status.idlichhen && Number(status.idlichhen) > 0) || (status.idhenkham && Number(status.idhenkham) > 0) ? "Đã đặt lịch" : status.datuvan ? "Đã tư vấn" : "Chờ xử lý"}</Tag>
                )
            }
        },
        {
            title: 'Thao tác',
            key: 'action',
            align: 'right',
            render: (row) => {
                return (
                    !row.huy && <div style={{ lineHeight: 3 }}>
                        {row.hoanthanh !== 1 && <button onClick={() => updateArrangerment(row.id, "tuvan", 0, row.chinhanh)} disabled={row.tuvan === 1 || row.hoanthanh === 1} className="btn btn-color">{row.tuvan === 1 ? "Đã chuyển đến tư vấn viên" : "Chuyển tư vấn"}</button>}
                        {row.hoanthanh !== 1 && IsActive_FeatureLH() &&
                            <button
                                onClick={() => {
                                    setVisibleCreate(true);
                                    setCaseId(row.id);
                                    setUpdateStatus("lenlich");
                                    setCaseInfo(row);
                                }}
                                disabled={row.hoanthanh === 1}
                                className="btn btn-color"
                                style={{ marginLeft: 10 }}>{row.idlichhen !== "" ? "Xem lịch lấy mẫu" : "Đặt lịch lấy mẫu"}</button>}
                        {row.hoanthanh !== 1 && IsActive_FeatureLH() &&
                            <button
                                onClick={() => {
                                    setVisibleCreate(true);
                                    setUpdateStatus("lichhenkham");
                                    setCaseInfo({ ...row, action: "lichhenkham" });
                                }}
                                disabled={row.hoanthanh === 1}
                                className="btn btn-color"
                                style={{ marginLeft: 10 }}>{row.idhenkham > 0 ? "Xem lịch khám" : "Đặt lịch khám"}</button>}
                        {row.hoanthanh !== 1 && <Popconfirm disabled={row.hoanthanh === 1} title="Bạn có chắc case đã hoàn tất?" onConfirm={() => updateArrangerment(row.id, "completed", 0, row.chinhanh)} okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-color" style={{ marginLeft: 10 }}>Hoàn thành</button>
                        </Popconfirm>}
                        <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => {
                            setCaseInfo(row);
                            MakeCall(row);
                        }}><i className="fas fa-phone" /></button>
                        <Popconfirm title="Tiếp nhận sẽ bị hủy bỏ?" onConfirm={() => deleteTiepNhan(row.id, row.idlichhen, row.idhenkham, row.datuvan, row.chinhanh)} okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}><i className="fas fa-trash" /></button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ];
    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }
    const _patientInfo = useRef();
    useEffect(() => {
        getPatientsList();
    }, [uncompleted, chinhanh]);
    useEffect(() => {
        setChinhanh(props.siteid)
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item)
            }
            // if (result.item == true) {
                GetListSite();
            // }
        });
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(Configure.BASE_NOTIFY_URL + "/notify")
            .build();

        connection.on("notify", data => {
            if (data === 'hotline')
                getPatientsList(currentPage, pageSize, uncompleted, false);
        })
        connection.start();
    }, []);

    useEffect(() => {
        setChinhanh(props.siteid);
    }, [props.siteid]);

    const getPatientsList = (page = currentPage, size = pageSize, status = uncompleted, noti = true) => {
        Actions.setLoading(noti);
        HotlineServices.GetListPatients(date[0].format("DD/MM/YYYY"), date[1].format("DD/MM/YYYY"), key, page, size, status, chinhanh).then(res => {
            if (res.isSuccess) {
                setListPatients(res.data);
                setTotalRecords(res.totalRecords);
                setCurrentPage(page);
                setPageSize(size);
            }
            Actions.setLoading(false);
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    const createNewRequest = (action) => {
        Actions.setLoading(true);
        _patientInfo.current.getForm().validateFields().then(values => {
            if (values.errorFields === undefined && _patientInfo.current.validator.allValid()) {
                let dataAdministrative = _patientInfo.current.getState();
                // setMabn(dataAdministrative.code);
                const data = {
                    'barCode': dataAdministrative.code ? dataAdministrative.code : '',
                    'code': dataAdministrative.code ? dataAdministrative.code : '',
                    'name': dataAdministrative.name.toUpperCase(),
                    'dob': moment(dataAdministrative.dob).format('YYYY-MM-DD'),
                    'age': dataAdministrative.age,
                    'gender': dataAdministrative.gender,
                    'people': "25",
                    'cmnd': dataAdministrative.cmnd,
                    'phone': dataAdministrative.phone,
                    'email': dataAdministrative.email,
                    'address': dataAdministrative.address,
                    'city': dataAdministrative.city ? dataAdministrative.city.toString() : '',
                    'district': dataAdministrative.district ? dataAdministrative.district.toString() : '',
                    'ward': dataAdministrative.ward ? dataAdministrative.ward.toString() : '',
                    'jobAddress': dataAdministrative.jobAddress,
                    'village': dataAdministrative.village !== undefined ? dataAdministrative.village : '',
                    'country': dataAdministrative.nation,
                    'dateInHospital': moment().format('YYYY/MM/DD'),
                    'timeInHospital': moment().format('HH:mm'),
                    'subjectCode': "2",
                    'coporator': dataAdministrative.coporator,
                    'sendby': dataAdministrative.sendby,
                    'onlyyear': dataAdministrative.onlyyear,
                    'year': dataAdministrative.year,
                }
                RegisMediService.CreatePatient(data).then(result => {
                    if (result.isSuccess) {
                        setMabn(result.patientID);
                        const info = {
                            mabn: dataAdministrative.code ? dataAdministrative.code : result.patientID,
                            ghichu: detail,
                            type: action === "lichhenkham" ? "new" : action,
                            uutien: priority
                        }
                        HotlineServices.addArrangerment(info).then(res => {
                            if (res.isSuccess) {
                                if (action !== "lenlich" && action != "lichhenkham") notification.success({ message: "Thêm thành công" })
                                var caseID = Number(res.returnObject.id); // ID hotline
                                setCaseId(caseID)
                                if (action === "lenlich" && caseID !== undefined && caseID !== 0) {
                                    setCaseInfo({
                                        dienthoai: data.phone,
                                        email: data.email,
                                        gioitinh: data.gender === 0 ? "Nam" : "Nữ",
                                        hoten: data.name,
                                        lenlich: 0,
                                        mabn: dataAdministrative.code ? dataAdministrative.code : result.patientID,
                                        ngayhen: new Date,
                                        ngaysinh: data.dob,
                                        sonha: data.address,
                                        sendby: data.sendby,
                                        idhotline: caseID,
                                        ghichu: detail !== "" ? res.enterNumber + detail : "",
                                    });
                                    setVisibleCreate(true);
                                } else if (action === "lichhenkham" && caseID !== undefined && caseID !== 0) {
                                    setCaseInfo({
                                        dienthoai: data.phone,
                                        email: data.email,
                                        gioitinh: data.gender === 0 ? "Nam" : "Nữ",
                                        hoten: data.name,
                                        lenlich: 0,
                                        mabn: dataAdministrative.code ? dataAdministrative.code : result.patientID,
                                        ngayhen: new Date,
                                        ngaysinh: data.dob,
                                        sonha: data.address,
                                        sendby: data.sendby,
                                        action,
                                        dataAdministrative,
                                        idhotline: caseID,
                                        ghichu: detail !== "" ? res.enterNumber + detail : "",
                                    });
                                    setVisibleCreate(true);
                                }
                                setTenbn("");
                            } else {
                                notification.error({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
                            }
                        }).catch(() => { })
                    }
                    else {
                        notification.error({ message: "Không thể thêm thông tin bệnh nhân, vui lòng thử lại sau" })
                        Actions.openMessageDialog("Lỗi", result.err.msgString);
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                })
                setStatus("inprogress");
                resetState();
            }
            else {
                notification.error({ message: 'Vui lòng kiểm tra lại dữ liệu!' });
                _patientInfo.current.validator.showMessages();
                Actions.setLoading(false);
            }
        }).catch((ex) => {
            Actions.setLoading(false);
        })
        setPriority(false);
        setDetail("");
    }
    const resetState = () => {
        _patientInfo.current.resetState();
        setDetail("");
        setTenbn("");
    }
    const updateArrangerment = (id = caseId, status = updateStatus, idhen = idLichHen, chinhanh, destSite) => {
        HotlineServices.updateArrangerment(id, status, idhen, chinhanh, destSite).then(res => {
            if (res.isSuccess) {
                getPatientsList(1);
                // notification.success({ message: "Đã cập nhật" });
            }
        }).catch((err) => { console.log(err); })
    }
    const deleteTiepNhan = (id, idlichhen, idhenkham, datuvan, chinhanh) => {
        if (datuvan) {
            notification.error({ message: 'Bộ phận tư vấn đã tiếp nhận nên không thể hủy' });
        } else {
            HotlineServices.deleteTiepNhan(id, idlichhen, idhenkham, chinhanh).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Đã hủy bỏ tiếp nhận" });
                    getPatientsList();
                } else {
                    if (res.err.msgString) notification.error({ message: res.err.msgString });
                    else notification.error({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" });
                }
            }).catch(() => { })
        }
    }
    const MakeCall = (row) => {
        if (row.dienthoai === '' || row.dienthoai.length < 5) {
            notification.error({ message: "Bệnh nhân không có số điện thoại." })
            return;
        }
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            setIsLoginWF(true)
            return;
        }
        Actions.setLoading(true);
        WorldfoneService.makecall(row.dienthoai, row.hoten, tokenWF).then(result => {
            if (result.status !== 1) {
                setTitleWF("Vui lòng đăng nhập Worldfone")
                setIsLoginWF(true);
            }
            Actions.setLoading(false);
            Actions.OpenCallWorldfone(true, 'NewCall')
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }

    return (
        <React.Fragment>
            <div className='containerChilderWrapper' >
                <div className=" summary-container">
                    <div className="col-md-6 btn-" onClick={() => setStatus("inprogress")}>
                        <div className={status !== "inprogress" ? "summary-item inactive" : "summary-item backgroundColorThemes"}>
                            <h4>Đã tiếp nhận</h4>
                            <i className="far fa-check-square"></i>
                        </div>
                    </div>
                    <div className="col-md-6" onClick={() => setStatus("new")}>
                        <div className={status !== "new" ? "summary-item inactive" : "summary-item backgroundColorThemes"}>
                            <h4>Tiếp nhận mới</h4>
                            <i className="fas fa-plus" />
                        </div>
                    </div>
                </div>
                {status === "new" ?
                    <div className="row content-container mt-2">
                        <div className="col-md-8">
                            <h4>ĐANG TIẾP: <span className='text-warning font-bold'>{tenbn}</span></h4>
                        </div>
                        <div className="col-md-4">
                            <div className="mt-2" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button className="btn btn-color" onClick={() => { resetState() }}> <i style={{ marginRight: 5 }} className="fa fa-refresh" />Nhập mới</button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-2" >
                                <Administrative
                                    ref={_patientInfo}
                                    newPatient={true}
                                    getPatientName={(patientName) => {
                                        setTenbn(patientName);
                                    }}
                                    onReplaceBangGia={(tenBangGia) => {
                                        if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
                                            this.setState({
                                                banggia: tenBangGia,
                                            });
                                            this.listServicesRef.handleSearch(this.searchIndication());
                                        }
                                    }}
                                    onReplaceCoporation={() => { }}
                                />
                            </div>
                            <div className='row mt-2'>
                                <div className="col-md-12">
                                    <label className="d-flex mt-2"><span>Ghi chú</span>&nbsp;<span className="ml-auto"><Checkbox checked={priority} onChange={() => setPriority(!priority)}>Ưu tiên</Checkbox></span></label>
                                    <TextArea placeholder="Nhập thông tin chi tiết của tiếp nhận" rows={5} onChange={(e) => setDetail(e.target.value)} value={detail}></TextArea>
                                </div>
                            </div>
                            <div className="mt-2" style={{ display: "flex", justifyContent: "center" }}>
                                <button className="btn btn-color" onClick={() => { createNewRequest("new") }}> <i style={{ marginRight: 5 }} className="fa fa-save" />Thêm</button>
                                <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { createNewRequest("tuvan") }}> <i style={{ marginRight: 5 }} className="fas fa-info" />Thêm và chuyển tư vấn</button>
                                {IsActive_FeatureLH() && FeatureDeactive().indexOf(FEATURE.XETNGHIEM) == -1 &&
                                <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { createNewRequest("lenlich") }}> <i style={{ marginRight: 5 }} className="fas fa-calendar-week" />Thêm và đặt lịch hẹn lấy mẫu</button>
                                }
                                {IsActive_FeatureLH() && 
                                <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { createNewRequest("lichhenkham") }}> <i style={{ marginRight: 5 }} className="far fa-clock" />Thêm và đặt lịch hẹn</button>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-center align-items-center w-100">
                            {/* {isMultisite === true &&
                                <Select
                                    style={{ width: '200px', marginRight: 10  }}
                                    showSearch
                                    allowClear={false}
                                    value={chinhanh}
                                    onChange={(e) => {
                                        setChinhanh(e)

                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select>
                            } */}
                            <div className='mt-3' style={{ width: "300px" }}>
                                <RangePicker allowClear={false} format={[FORMAT_DATE, FORMAT_DATE]} locale={viVN} value={[moment(date[0], "DD/MM/YYYY"), moment(date[1], "DD/MM/YYYY")]} onChange={(e) => setDate(e)} />
                            </div>
                            <div style={{ width: "400px" }} className="ml-2 mt-3">
                                <Input value={key} onChange={(e) => setKey(e.target.value)}
                                    placeholder="Nhập tên bệnh nhân để tìm kiếm"
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") getPatientsList(1);
                                    }}
                                    // suffix={<Checkbox checked={uncompleted} onChange={(e) => setUncompleted(e.target.checked)}>Chưa xử lý</Checkbox>}
                                />
                            </div>
                            <Button className="btn btn-color mt-3 ml-2" onClick={() => { getPatientsList(1) }}> <i className="fas fa-search" /></Button>
                        </div>
                        <div style={{ height: 'calc(100% - 155px)' }} className="mt-3">
                            <Table
                                style={{ width: "100%" }}
                                scroll={{ x: 'max-content', y: 0 }}
                                dataSource={listPatients}
                                columns={columns}
                                pagination={false}
                                rowClassName={(row) => {
                                    if (row.huy)
                                        return "row-disabled"
                                }}
                            />
                            <Pagination
                                style={{ textAlignLast: 'center', marginTop: 10 }}
                                total={totalRecords}
                                showTotal={total => `Tổng: ${total}`}
                                current={currentPage}
                                onChange={(page, size) => {
                                    getPatientsList(page, size);
                                }}
                                onShowSizeChange={() => { }}
                                showSizeChanger={true}
                            />
                        </div>
                    </>
                }
            </div>
            {visibleCreate &&
                <RenderCreateModal show={visibleCreate} setShow={setVisibleCreate} caseInfo={caseInfo} reload={getPatientsList} 
                    chinhanh={chinhanh}
                    listSite={listSite}
                    // destSite là chi nhánh đích của thao tác
                    updateIdLichHen={(idhen, destSite) => updateArrangerment(caseId, 'lenlich', idhen, caseInfo.chinhanh ? caseInfo.chinhanh : '', destSite)} />
            }
            <Modal
                visible={isLoginWF}
                onCancel={() => { setIsLoginWF(false) }}
            > <LoginWF title={titleWF}
                onClose={() => setIsLoginWF(false)}
                /></Modal>
        </React.Fragment>
    );
}

function RenderCreateModal({ show, setShow, caseInfo, reload, updateIdLichHen, chinhanh, listSite }) {
    const { TextArea } = Input;
    const { Step } = Steps;
    const [curCase, setCurCase] = useState(null);
    const [ngayhen, setNgayhen] = useState(new Date());
    const [temp, setTemp] = useState(new Date());
    const [diffAddr, setDiffAddr] = useState(false);
    const [newAddress, setNewAddress] = useState(caseInfo.diachihen);
    const [ngayhengannhat, setNgayhengannhat] = useState({});
    const [mabn, setMabn] = useState("");
    const [isNewCase, setIsNewCase] = useState(true);
    const [dataOrder, setDataOrder] = useState([]);
    const [visibleOrder, setVisibleOrder] = useState(false);
    const [stepInfo, setStepInfo] = useState([]);
    const OrderRef = useRef();
    const [lichHenKham, setLichHenKham] = useState(caseInfo.action === "lichhenkham");
    const [isNewCaseAppointment, setIsNewCaseAppointment] = useState(true);
    const [maql, setMaql] = useState("");
    const [idhotline, setIdhotline] = useState(null);
    const [dataOrderHK, setDataOrderHK] = useState([]);
    const _nguonKhachHang = useRef();
    const [bangGia, setBangGia] = useState("");
    const [banggiaTheoSite, setBangGiaTheoSite] = useState("");
    const [banggiaTheoNguonKH, setBangGiaTheoNguonKH] = useState("");
    const [site, setSite] = useState(
        caseInfo.action === "lichhenkham"
        ? !isNullOrEmpty(caseInfo.chiNhanhHenKham) ? Number(caseInfo.chiNhanhHenKham) : ""
        : !isNullOrEmpty(caseInfo.chiNhanhLayMau) ? Number(caseInfo.chiNhanhLayMau) : "");
    const [detail, setDetail] = useState();
    const [initDetail, setInitDetail] = useState();

    const notifyRef = useRef();

    useEffect(() => {
        if (JSON.stringify(curCase) !== JSON.stringify(caseInfo)) {
            setCurCase(caseInfo);
            setNewAddress(caseInfo.diachihen);
            if (caseInfo.action !== undefined)
                setNgayhen(moment(caseInfo.ngayhenkham).year() !== 1 ? moment(caseInfo.ngayhenkham).toDate() : new Date());
            else setNgayhen(moment(caseInfo.ngayhen).year() !== 1 ? moment(caseInfo.ngayhen).toDate() : new Date());
            setDiffAddr(caseInfo.diachihen === "" ? true : false);
        };
        caseInfo.mabn !== undefined && NgayHenGanNhat(caseInfo.mabn, caseInfo.action !== "lichhenkham");
        caseInfo.mabn !== undefined && setMabn(caseInfo.mabn);
        caseInfo.idlichhen !== undefined && caseInfo.idlichhen !== "" ? setIsNewCase(false) : setIsNewCase(true);
        caseInfo.idhenkham && caseInfo.idhenkham > 0 ? setIsNewCaseAppointment(false) : setIsNewCaseAppointment(true);
        if (caseInfo.action === "lichhenkham") {
            setLichHenKham(true);
            setSite(!isNullOrEmpty(caseInfo.chiNhanhHenKham) ? Number(caseInfo.chiNhanhHenKham) : "");
        } else {
            setLichHenKham(false);
            setSite(!isNullOrEmpty(caseInfo.chiNhanhLayMau) ? Number(caseInfo.chiNhanhLayMau) : "");
        }
        caseInfo.idhotline && Number(caseInfo.idhotline) > 0 ? setIdhotline(Number(caseInfo.idhotline)) : setIdhotline(caseInfo.id);
        caseInfo.ghichu && setInitDetail(caseInfo.ghichu);
        loadChiDinhInfo();
    }, [caseInfo]);

    useEffect(() => {
        let s = listSite.find(x => x.id == chinhanh);
        if (s) setBangGiaTheoSite(s.banggia);
    },[chinhanh])

    const resetState = () => {
        setShow(false);
        setMabn("");
        // setCurCase({});
        setSite();
        notifyRef && notifyRef.current && notifyRef.current.Close();
    }
    const GetListOrderById = (id) => {
        AppointmentService.GetListOrderById(id, site).then(result => {
            if (result.isSuccess) {
                setDataOrder(result.data)
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString.toString());
                !lichHenKham && resetState();
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    const saveLichHen = () => {
        if (isNullOrEmpty(site)) {
            notifyRef.current.Show("Cần xác nhận nơi khám", NotifyStatus.Warning);
            return;
        }
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        let checkngayhen = moment(ngayhen);
        if (checkngayhen.isValid()) {
            Actions.setLoading(true);
            const data = {
                mabn: curCase.mabn.toString(),
                ngayhen: checkngayhen.format("YYYY-MM-DD HH:mm"),
                newAddress: newAddress,
                site: site.toString(),
            }
            if (lichHenKham) {
                let lstSelect = dataOrderHK;
                if (!lstSelect || lstSelect.length === 0)
                    lstSelect = [];
                for (let item of lstSelect) {
                    item.ma = "0";
                    item.madoituong = "2";
                    item.giaaTH = item.dongia.toString();
                    item.dongia = item.dongia;
                    item.soluong = item.soluong;
                    item.ngay = moment().format("YYYY-MM-DD HH:mm")
                }
                const dataAdministrative = curCase.dataAdministrative;
                const dataHenKham = idhotline && Number(idhotline) > 0 ?
                    {
                        lstChiDinh: lstSelect,
                        mabn: curCase.mabn.toString(),
                        ngayhen: checkngayhen.format("YYYY-MM-DD HH:mm"),
                        machidinh: Number(''),
                        newPatient: curCase.mabn ? false : true,
                        idhotline: idhotline,
                        patient: {},
                        idsale: dataNguonKhachHang.idsale ? dataNguonKhachHang.idsale.toString() : "",
                        otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
                        manguoigioithieu: dataNguonKhachHang.idngt ? dataNguonKhachHang.idngt.toString() : "",
                        site : site.toString(),
                    } : {
                        lstChiDinh: lstSelect,
                        mabn: curCase.mabn.toString(),
                        ngayhen: checkngayhen.format("YYYY-MM-DD HH:mm"),
                        machidinh: Number(''),
                        newPatient: curCase.mabn ? false : true,
                        idhotline: idhotline,
                        patient: {
                            'barCode': dataAdministrative.code ? dataAdministrative.code : '',
                            'code': dataAdministrative.code ? dataAdministrative.code : '',
                            'name': dataAdministrative.name.toUpperCase(),
                            'dob': moment(dataAdministrative.dob).format('YYYY-MM-DD'),
                            'age': dataAdministrative.age,
                            'gender': dataAdministrative.gender,
                            'people': "25",
                            'cmnd': dataAdministrative.cmnd,
                            'phone': dataAdministrative.phone,
                            'email': dataAdministrative.email,
                            'address': dataAdministrative.address,
                            'city': dataAdministrative.city ? dataAdministrative.city.toString() : '',
                            'district': dataAdministrative.district ? dataAdministrative.district.toString() : '',
                            'ward': dataAdministrative.ward ? dataAdministrative.ward.toString() : '',
                            'jobAddress': dataAdministrative.jobAddress,
                            'village': dataAdministrative.village !== undefined ? dataAdministrative.village : '',
                            'country': dataAdministrative.nation,
                            'dateInHospital': moment().format('YYYY/MM/DD'),
                            'timeInHospital': moment().format('HH:mm'),
                            'subjectCode': "2",
                            'coporator': dataAdministrative.coporator,
                            'sendby': dataAdministrative.sendby,
                            'onlyyear': dataAdministrative.onlyyear,
                            'year': dataAdministrative.year,
                        },
                        site : site.toString(),
                    }
                AppointmentService.LuuLichHen(dataHenKham).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' });
                        resetState();
                        setLichHenKham(false);
                        reload();
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                }).finally(() => {
                    updateGhiChu(idhotline);
                })
            } else {
                HotlineServices.saveLichHen(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' });
                        updateIdLichHen(result.returnObject.id, result.returnObject.chinhanh);
                        updateGhiChu(caseInfo.idhotline);
                        UpdateChidinh(result.returnObject.id, result.returnObject.chinhanh);
                        resetState();
                        reload();
                    } else {
                        notification.warning({ message: result.err.msgString });
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    notification.warning({ message: 'Xảy ra lỗi khi lưu, vui lòng thử lại!' });
                    Actions.setLoading(false);
                })
            }
        } else {
            notification.warning({ message: 'Ngày hẹn sai định dạng' });
        }
    }

    const UpdateChidinh = (orderid) => {
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        let lstSelect = dataOrder;
        if (!lstSelect || lstSelect.length === 0)
            return;
        for (let item of lstSelect) {
            item.ma = "0";
            // item.ten = item.name;
            item.madoituong = "2";
            // item.mavp = item.id.toString();
            // if (item.type === 3) {
            //     item.mavp = item.idvp.toString();
            // }
            item.giaaTH = item.dongia.toString();
            item.dongia = item.dongia;
            item.soluong = item.soluong;
            item.ngay = moment(ngayhen).format("YYYY-MM-DD HH:mm")
        }
        const data = {
            orderid: orderid,
            listChiDinh: lstSelect,
            patient: {
                code: curCase.mabn.toString(),
                subjectCode: '2',
                sendby: curCase.sendby,
                dateInHospital: moment(ngayhen).format("YYYY-MM-DD HH:mm"),
            },
            idsale: dataNguonKhachHang.idsale ? dataNguonKhachHang.idsale.toString() : "",
            otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
            manguoigioithieu: dataNguonKhachHang.idngt ? dataNguonKhachHang.idngt.toString() : "",
            chinhanh: !isNullOrEmpty(site) ? site.toString() : "",
        }
        ChiDinhService.LuuChiDinh(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu chỉ định thành công!' });
                reload();
            } else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    const updateLichHen = () => {
        let checkngayhen = moment(ngayhen);
        if (checkngayhen.isValid() == true) {
            Actions.setLoading(true);
            updateGhiChu(caseInfo.id);
            if (isNullOrEmpty(site)) {
                notifyRef.current.Show("Cần xác nhận nơi khám", NotifyStatus.Warning);
                return;
            }
            if (lichHenKham && Number(caseInfo.idhenkham) > 0) {
                const data = {
                    ngayhen: ngayhen,
                    lstChiDinh: dataOrderHK,
                    mabn: mabn,
                    maql: maql,
                    site : site.toString(),
                }
                AppointmentService.updateAppointment(caseInfo.idhenkham, data, site).then((result) => {
                    if (result.isSuccess) {
                        _nguonKhachHang && _nguonKhachHang.current.updateInfo();
                        notification.success({ message: 'Lưu thành công!' });
                        resetState();
                        reload();
                    } else {
                        notification.warning({ message: result.err.msgString });
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    notification.warning({ message: 'Xảy ra lỗi khi lưu, vui lòng thử lại!' });
                    Actions.setLoading(false);
                })
            } else {
                const data = {
                    mabn: mabn,
                    ngayhen: ngayhen,
                    newAddress: newAddress
                }
                HotlineServices.updateLichHen(Number(curCase.idlichhen), data, site).then(result => {
                    if (result.isSuccess) {
                        _nguonKhachHang && _nguonKhachHang.current.updateInfo();
                        notification.success({ message: 'Lưu thành công!' });
                        // UpdateChidinh(curCase.idlichhen);
                        resetState();
                        reload();
                    } else {
                        notification.warning({ message: result.err.msgString });
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    notification.warning({ message: 'Xảy ra lỗi khi lưu, vui lòng thử lại!' });
                    console.log(err);
                    Actions.setLoading(false);
                })
            }
        }
    }
    const NgayHenGanNhat = (mabn, laymau = true) => {
        AppointmentService.NgayHenGanNhat(mabn, laymau).then(result => {
            if (result.isSuccess) {
                setNgayhengannhat(result.item)
            }
            else {
                setNgayhengannhat({})
            }
        })
    }
    const XoaChiDinh = (row) => {
        let data = lichHenKham ? dataOrderHK : dataOrder;
        data = data.filter(x => x.id !== row.id)
        lichHenKham ? setDataOrderHK(data.map(x => ({ ...x }))) : setDataOrder(data.map(x => ({ ...x })));
    }
    const getAppointmentDetail = (idlichhen) => {
        AppointmentService.ScheduleGetSampleByPatientID(idlichhen, site).then(res => {
            if (res.isSuccess) {
                const data = res.item;
                const info = {
                    hoantat: data.dalaymau === 1, //0: chưa, 1: rồi
                    isCheckin: data.giocheckin && moment(data.giocheckin).year() !== 1,
                    isLaymau: data.gionhanmau && moment(data.gionhanmau).year() !== 1,
                    mactv: data.mactv,
                    tenctv: data.tenctv,
                    giocheckin: data.giocheckin && moment(data.giocheckin).year() !== 1 && moment(data.giocheckin).format(FORMAT_DATE_TIME),
                    gionhanmau: data.gionhanmau && moment(data.gionhanmau).year() !== 1 && moment(data.gionhanmau).format(FORMAT_DATE_TIME),
                }
                setStepInfo(info);
            }
        }).catch(() => { })
    }
    useEffect(() => {
        if (OrderRef && OrderRef.current && lichHenKham) {
            OrderRef.current.UpdateSelectedFromProps(dataOrderHK);
        }
    }, OrderRef.current)

    const checkBangGia = (info) => {
        let banggia = banggiaTheoNguonKH && banggiaTheoNguonKH != "default" ? banggiaTheoNguonKH : banggiaTheoSite ? banggiaTheoSite : bangGia;
        let listOrder = lichHenKham ? dataOrderHK : dataOrder;
        let itemFound = listOrder && listOrder.find(x => x.banggia !== "" && x.banggia !== null && x.banggia !== undefined);
        if (itemFound !== null && itemFound !== undefined) banggia = itemFound.banggia; //nếu đã lưu chỉ định có banggia thì lấy banggia của chidinh
        if (itemFound !== null && itemFound !== undefined && info.banggia !== banggia) {
            //nếu banggia của nguonKH khác bangia hiện tại thì vào thay đổi
            //nếu đổi banggia mà có chidinh thì xóa chidinh cũ
            if (listOrder.length > 0) {
                XoaTatCaChiDinh();
                if (OrderRef && OrderRef.current) {
                    OrderRef.current.UpdateSelectedFromProps([]);
                }
                Actions.openMessageDialog("Thông báo",
                    `Việc thay đổi nguồn khách hàng (Bảng giá <b>${info.banggia === "default" ? "Mặc định" : info.banggia}</b>) đã xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`);
                if (itemFound.maql) {
                    cancelExam(itemFound.maql, listOrder);
                }
                banggia = info.banggia;
            }
            setBangGia(banggia);
        } else setBangGia(info.banggia);
    }

    const cancelExam = (managementID, listOrder) => {
        var loadDetail = false;
        Actions.setLoading(true);
        XoaTatCaChiDinh();
        if (OrderRef && OrderRef.current) {
            OrderRef.current.UpdateSelectedFromProps([]);
        }
        if (!lichHenKham) {
            PatientService.cancelExam(managementID).then((respone) => {
                if (respone.isSuccess) {
                    loadChiDinhInfo(loadDetail);
                } else {
                    Actions.openMessageDialog("Lỗi", respone.err.msgString);
                }
                Actions.setLoading(false);
            })
        } else {
            if (OrderRef && OrderRef.current && Number(caseInfo.idhenkham) > 0) {
                listOrder.forEach(chiDinh => {
                    OrderRef.current.XoaChiDinh(chiDinh);
                });
                loadChiDinhInfo(loadDetail);
            }
            Actions.setLoading(false);
        }
    }

    const loadChiDinhInfo = (loadDetail = true) => {
        if (!lichHenKham && Number(caseInfo.idlichhen) > 0) {
            GetListOrderById(caseInfo.idlichhen);
            loadDetail && getAppointmentDetail(caseInfo.idlichhen);
        }
        if (lichHenKham && Number(caseInfo.idhenkham) > 0) {
            AppointmentService.GetAppoimentDetailByID(caseInfo.idhenkham, site).then(result => {
                if (result.isSuccess) {
                    let data = result.item.lstChiDinh;
                    setDataOrderHK(data);
                    setMaql(result.item.maql);
                } else {
                    Actions.openMessageDialog("Lỗi " + result.err.msgCode, result.err.msgString.toString());
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi ", err);
            })
        }
    }
    
    const XoaTatCaChiDinh = () => {
        lichHenKham ? setDataOrderHK([]) : setDataOrder([]);
    }

    const onChangeDetail = () => {
        if (detail !== undefined && detail !== null && detail !== "" && detail != " ") {
            let data = initDetail ? `${initDetail}\n[${username}]: ${detail}` : `[${username}]: ${detail}`;
            setInitDetail(data);
            setDetail("");
        }
    }

    const updateGhiChu = (hotlineId) => {
        HotlineServices.UpdateDetailArrangerment(hotlineId, { ghichu: initDetail }, chinhanh).then(() => { })
    }

    return (
        curCase === null ?
            <React.Fragment></React.Fragment>
            :
            <React.Fragment>
                <Modal visible={show}
                    onCancel={() => setShow(false)}
                    width={1000}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <HeaderModal
                        title={`${lichHenKham ? isNewCaseAppointment ? "Tạo lịch hẹn" : "Cập nhật lịch hẹn"
                            : isNewCase ? "Tạo lịch hẹn lấy mẫu" : "Cập nhật lịch hẹn lấy mẫu"}`}
                        onClose={() => { setShow(false) }} />
                    <Notify ref={notifyRef} />
                    {!isNewCase && !lichHenKham &&
                        <Steps className="mt-2" progressDot>
                            <Step status={stepInfo.mactv ? "finish" : "process"} title="Chỉ định CTV" description={stepInfo.tenctv}></Step>
                            <Step status={stepInfo.isCheckin ? "finish" : "process"} title="Check-in" description={stepInfo.giocheckin}></Step>
                            <Step status={stepInfo.isLaymau ? "finish" : "process"} title="Lấy mẫu" description={stepInfo.gionhanmau}></Step>
                            <Step status={stepInfo.hoantat ? "finish" : "process"} title="Bàn giao"></Step>
                        </Steps>
                    }
                    <div className="row mt-2">
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: '100px' }}>Mã BN:</span><strong className="ml-2">{curCase.mabn}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: '100px' }}>Họ tên:</span><strong className="ml-2">{curCase.hoten}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: '100px' }}>{curCase.onlyyear ? "Năm sinh" : "Ngày sinh"}:</span><strong className="ml-2">{curCase.onlyyear ? moment(curCase.ngaysinh, "YYYY/MM/DD").format("YYYY") : moment(curCase.ngaysinh, "YYYY/MM/DD").format(FORMAT_DATE)}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: '100px' }}>Giới tính:</span><strong className="ml-2">{curCase.gioitinh}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: '100px' }}>Điện thoại:</span><strong className="ml-2">{curCase.dienthoai}</strong>
                        </div>
                        <div className="col-md-6 my-1 d-flex">
                            <span style={{ width: '100px' }}>Email:</span><strong className="ml-2">{curCase.email}</strong>
                        </div>
                        <div className="col-md-12 my-1 d-flex">
                            <span style={{ width: '100px' }}>Địa chỉ:</span><strong className="ml-2">{curCase.sonha}</strong>
                        </div>
                    </div>
                    {ngayhengannhat !== null &&
                        <div className='row mt-2'>
                            <div className="col-md-12">
                                <div className='backgroundColorThemes rounded w-100 p-1'>Lịch hẹn đã tạo gần nhất:<strong className="ml-2">{ngayhengannhat === null ? '' : moment(ngayhengannhat.ngayhen).format(FORMAT_TIME_DATE)}</strong></div>
                            </div>
                        </div>}
                    <div className='row mt-2'>
                        <div className="col-md-12"><i>*Chỉ có thể thêm/cập nhật nguồn khách hàng khi có thông tin chỉ định</i></div>
                        <div className="col-md-12"><i className='text-danger'>*Việc thay đổi nguồn khách hàng có thể hủy toàn bộ chỉ định ngay lập tức mà <b>không thể hoàn tác</b></i></div>
                        <div className='col-md-12'><i>*Cần xác nhận nơi khám, nguồn khách hàng không cho phép điều chỉnh lịch khi hoàn tất thao tác</i></div>
                        <NguonKhachHang ref={_nguonKhachHang}
                            managermentId={lichHenKham ? maql : curCase.maql}
                            chiNhanhDatLich={site}
                            updateInfoBangGia={(info) => { 
                                let bgia = info.banggia;
                                if (bgia == null || bgia == '' || bgia == undefined) {
                                    bgia = banggiaTheoSite
                                    if (bgia == null || bgia == '' || bgia == undefined)
                                        bgia = 'default';
                                }
                                setBangGiaTheoNguonKH(info.banggia);
                                checkBangGia({banggia: bgia});
                            }} 
                            setBangGiaDefault={false}
                            // banggiaSite={banggiaTheoSite}
                            disable={lichHenKham ? !isNewCaseAppointment : !isNewCase}
                            />
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12'>
                        <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle'/></Tooltip></label>
                        <Select
                            placeholder="Chọn chi nhánh"
                            style={{ width: '100%' }}
                            value={site}
                            // onChange={(value => setSite(value))}
                            onChange={(value,item) => {
                                //cần xét trường hợp load để edit (có sẵn banggiaTheoNguonKH theo nguonKH đã lưu), thì set banggiaTheoNguonKH từ lúc mới load
                                if (banggiaTheoNguonKH == null || banggiaTheoNguonKH == '' || banggiaTheoNguonKH == undefined) {
                                    let bgia = item.item.banggia;
                                    if (bgia == null || bgia == '' || bgia == undefined)
                                        bgia = 'default';
                                        checkBangGia({banggia: bgia});
                                }

                                // _nguonKhachHang.current.updateBangGiaSite(item.item.banggia);
                                setBangGiaTheoSite(item.item.banggia);
                                setSite(value);
                            }}
                            disabled={lichHenKham ? !isNewCaseAppointment : !isNewCase}
                        >
                            {listSite && listSite.map((item, index) => {
                                return (
                                    <option key={index} value={item.id} item={item}>{item.ten}</option>
                                )
                            })}
                        </Select>
                        </div>
                    </div>
                    <div className="row mt-2" >
                        <div className='col-12'>
                            <label>Ghi chú</label>
                            <TextArea rows={5} value={initDetail}
                                readOnly={true}
                            ></TextArea>
                        </div>
                        <div className="col-12 d-flex mt-2" >
                            <TextArea autoSize={{ minRows: 1, maxRows: 5 }}
                                onChange={(e) => {
                                    setDetail(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && e.ctrlKey) {
                                        onChangeDetail();
                                    }
                                }}
                                value={detail}
                                placeholder="Nhập ghi chú và bấm thêm hoặc bấm Ctrl+Enter"></TextArea>
                            <button style={{ width: 90 }} className={`btn ${!isNullOrEmpty(detail) ? "btn-warning-sparkle" : "btn-color"} ml-2`} onClick={() => { onChangeDetail() }}><i className="fa fa-plus mr-1"></i>Thêm</button>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <span style={{ width: '80px' }}>
                                Ngày hẹn: </span>
                            <DateTimePicker
                                className="w-100"
                                locale="vi"
                                calendarIcon={<Tooltip title="Mở lịch"><i className='fas fa-calendar-week customclearicon' /></Tooltip>}
                                clearIcon={<Tooltip title="Chọn ngày hiện tại"><i className='fas fa-broom customclearicon' /></Tooltip>}
                                format={'dd-MM-yyyy HH:mm'}
                                isClockOpen={false}
                                isCalendarOpen={false}
                                onChange={setNgayhen}
                                value={ngayhen ? ngayhen : new Date()}
                                minDate={moment(ngayhen).year() > 1 && moment(ngayhen) < moment() ? moment(ngayhen).toDate() : moment().toDate()}
                            />
                        </div>
                        {!lichHenKham && <div className="col-md-12 mt-2">
                            <label>Địa chỉ lấy mẫu</label>&nbsp;<i style={{ color: "#ccc" }}>{diffAddr ? "[Bấm vào \"Giống địa chỉ liên hệ\" để nhập địa chỉ mới]" : "[Bấm vào \"Địa chỉ mới\" để chọn địa chỉ liên hệ]"}</i>
                            <Input
                                placeholder={diffAddr ? "" : "Nhập địa chỉ lấy mẫu"}
                                value={diffAddr ? "" : newAddress}
                                disabled={diffAddr}
                                prefix={<button className={diffAddr ? "btn btn-secondary btn-sm mr-2" : "btn btn-color btn-sm mr-2"}
                                    onClick={(e) => { e.preventDefault(); setDiffAddr(!diffAddr); setNewAddress("") }}>{diffAddr ? "Giống địa chỉ liên hệ" : "Địa chỉ mới"}</button>}
                                onChange={(e) => setNewAddress(e.target.value)}
                            />
                        </div>}
                        <div className="col-12 mt-2">
                            <div className="border-register">
                                <div data-toggle="collapse" data-target="#chidinh" className="row no-gutters align-items-center">
                                    {lichHenKham ? <div className="h4 font-weight-bold">Chỉ định ({!dataOrderHK ? 0 : dataOrderHK.reduce((tmp, cur) =>
                                        tmp + Number(cur.dongia), 0).toLocaleString('en-US')})</div>
                                        : <div className="h4 font-weight-bold">Chỉ định ({!dataOrder ? 0 : dataOrder.reduce((tmp, cur) =>
                                            tmp + Number(cur.dongia), 0).toLocaleString('en-US')})</div>}
                                    <Button className="btn btn-secondary ml-auto" onClick={e => {
                                        e.stopPropagation();
                                        setVisibleOrder(true);
                                    }}>Thêm/Sửa chỉ định</Button>
                                </div>
                                <div className="collapse" id="chidinh">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-inverse table-hover">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Mã</th>
                                                    <th>Tên</th>
                                                    <th>Giá</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {lichHenKham ? (dataOrderHK && dataOrderHK.map((row, index) =>
                                                    <tr key={index}>
                                                        {(Number(caseInfo.idlichhen) > 0) ?
                                                            <React.Fragment>
                                                                <td>{index + 1}</td>
                                                                <td>{row.mavp}</td>
                                                                <td>{row.ten}</td>
                                                                <td>{row.dongia?.toLocaleString('en-US')}</td>
                                                                <td></td>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <td>{index + 1}</td>
                                                                <td>{row.mavp}</td>
                                                                <td>{row.ten}</td>
                                                                <td>{row.dongia?.toLocaleString('en-US')}</td>
                                                                <td>
                                                                    <div style={{ cursor: 'pointer' }}
                                                                        className="text-danger"
                                                                        onClick={() => {
                                                                            XoaChiDinh(row);
                                                                            if (OrderRef && OrderRef.current) {
                                                                                OrderRef.current.XoaChiDinh(row);
                                                                            }
                                                                        }} >
                                                                        <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                                                                    </div>
                                                                </td>
                                                            </React.Fragment>
                                                        }
                                                    </tr>
                                                )) : (dataOrder && dataOrder.map((row, index) =>
                                                    <tr key={index}>
                                                        {(Number(caseInfo.idlichhen) > 0) ?
                                                            <React.Fragment>
                                                                <td>{index + 1}</td>
                                                                <td>{row.mavp}</td>
                                                                <td>{row.ten}</td>
                                                                <td>{row.dongia?.toLocaleString('en-US')}</td>
                                                                <td></td>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <td>{index + 1}</td>
                                                                <td>{row.mavp}</td>
                                                                <td>{row.ten}</td>
                                                                <td>{row.dongia?.toLocaleString('en-US')}</td>
                                                                <td>
                                                                    <div style={{ cursor: 'pointer' }}
                                                                        className="text-danger"
                                                                        onClick={() => {
                                                                            XoaChiDinh(row);
                                                                            if (OrderRef && OrderRef.current) {
                                                                                OrderRef.current.XoaChiDinh(row);
                                                                            }
                                                                        }} >
                                                                        <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                                                                    </div>
                                                                </td>
                                                            </React.Fragment>
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-hr'></div>
                    <div className="d-flex justify-content-center w-100">
                        {lichHenKham ? <button className="btn btn-color" onClick={() => isNewCaseAppointment ? saveLichHen() : updateLichHen()}><i className="mr-2 fa fa-save"></i>{isNewCaseAppointment ? "Thêm" : "Cập nhật"}</button>
                            : <button className="btn btn-color" onClick={() => isNewCase ? saveLichHen() : updateLichHen()}><i className="mr-2 fa fa-save"></i>{isNewCase ? "Thêm" : "Cập nhật"}</button>}
                        <button className="ml-2 btn btn-danger" onClick={() => resetState()}><i className="mr-2 fas fa-sign-out-alt"></i>Đóng</button>
                    </div>
                </Modal>
                <Modal
                    width={1400}
                    visible={visibleOrder}
                    onCancel={() => setVisibleOrder(false)}
                >
                    {lichHenKham ?
                        <ModalCLS
                            showGoiKham={false}
                            maskClosable={false}
                            chinhanh={site}
                            ref={OrderRef}
                            onClose={(data) => {
                                setVisibleOrder(false);
                                if (data === null) data = [];
                                lichHenKham ? setDataOrderHK(data) : setDataOrder(data);
                            }}
                            banggia={banggiaTheoNguonKH && banggiaTheoNguonKH != "default" ? banggiaTheoNguonKH : banggiaTheoSite ? banggiaTheoSite : bangGia}
                            disableSelectBangGia={true}
                            editAfterRegister={true}
                        />
                        :
                        (Number(caseInfo.idlichhen) > 0) ?
                            <AddOrder
                                chinhanh={site}
                                ref={OrderRef}
                                selectedPatient={{
                                    mabn: caseInfo.mabn,
                                    maql: caseInfo.maql,
                                    ngayhen: caseInfo.ngayhen,
                                    id: caseInfo.idlichhen,
                                }}
                                reLoad={() => { GetListOrderById(caseInfo.idlichhen) }}
                                onClose={() => {
                                    setVisibleOrder(false);
                                    // setCurCase({});
                                }}
                                banggia={banggiaTheoNguonKH && banggiaTheoNguonKH != "default" ? banggiaTheoNguonKH : banggiaTheoSite ? banggiaTheoSite : bangGia}
                                disableSelectBangGia={false} />
                            :
                            <ModalCLS
                                showGoiKham={false}
                                maskClosable={false}
                                ref={OrderRef}
                                onClose={(data) => {
                                    setVisibleOrder(false);
                                    setDataOrder(data);
                                }}
                                banggia={banggiaTheoNguonKH && banggiaTheoNguonKH != "default" ? banggiaTheoNguonKH : banggiaTheoSite ? banggiaTheoSite : bangGia}
                                disableSelectBangGia={false}
                                editAfterRegister={true}
                            />
                    }
                </Modal>
            </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Hotline);