import React, { Component } from 'react'
import { Input, Form, InputNumber, notification, Select, Checkbox, Tooltip } from 'antd';
import * as VPService from '../services/VPService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import { FEATURE } from '../../../configureHIS/common/constants';
import { FeatureDeactive } from '../../../configure/featureConfig';

const { Option } = Select;
const initialState = {
    mavp: '',
    tenvp: '',
    maloai: 0,
    manhomvp: null,
    maloaivp: null,
    giabhyt: 0,
    giatien: 0
}
export default class CreateNewVP extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            lstVP: [],
            lstNhomVP: [],
            lstLoaiVP: [],
            value: [],
            options: this.props.options,
            autoGenerate: false
        };
    }

    componentDidMount() {
        this.loadNhomVP();
    }
    loadNhomVP() {
        VPService.GetNhomVP().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNhomVP: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm viện phí bị lỗi' });
        })
    }
    loadDanhSachLoaiVP(key) {
        VPService.GetLoaiVPByNhom(Number(key)).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiVP: result.data,
                });
                this.form.setFieldsValue({
                    maloaivp: null,
                    lstVP: null
                });
            }
        }).catch(err => {
        })
    }
    resetState() {
        this.validator.hideMessages();
        this.setState({
            giatien: 0,
            giabhyt: 0,
            value: [],
            tenvp: null,
            maloaivp: null,
            mavp: null,
            manhomvp: null
        })
    }
    CreateVP() {
        if (this.validator.allValid()) {
            const data = {
                ma: this.state.autoGenerate ? "" : this.state.mavp,
                tenvp: this.state.tenvp,
                loai: Number(this.state.maloaivp),
                gia_th: Number(this.state.giatien),
                gia_bh: 0,
                lstbanggia: this.state.value
            }
            VPService.TaoMoiVP(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                    this.resetState();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                    this.setState({
                        mavp: ""
                    })
                }
            }).catch(err => {
                notification.warning({ message: 'Tạo thất bại!' });
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }



    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm viện phí</h3>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-md-6">
                            <Form.Item label="Nhóm:" name="manhomvp">
                                <Select
                                    value={this.state.manhomvp}
                                    onChange={(e) => {
                                        e === 4 ? this.setState({ manhomvp: 4, maloaivp: 11 }, this.loadDanhSachLoaiVP(e)) :
                                        this.setState({ manhomvp: e, maloaivp: null }, this.loadDanhSachLoaiVP(e));
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstNhomVP.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                                {this.validator.message("Nhóm viện phí", this.state.manhomvp, "selection")}
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item label="Loại:">
                                <Select
                                    value={this.state.manhomvp === 4 ? 11 : this.state.maloaivp}
                                    onChange={(e) => this.setState({ maloaivp: e })}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstLoaiVP.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                                {this.validator.message("Loại viện phí", this.state.maloaivp, "selection")}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row no-gutters mt-2" style={{ display: 'flex' }}>
                        <div className="col-md-6">
                            <Form.Item style={{ flex: 1 }} label="Mã viện phí:" name="mavp" >
                                <Input
                                    disabled={this.state.autoGenerate}
                                    value={this.state.autoGenerate ? "" : this.state.mavp}
                                    onChange={(e) => this.setState({ mavp: e.target.value })}
                                    suffix={<Tooltip title="Tự động tạo mã"><Checkbox onChange={(e) => { this.setState({ autoGenerate: e.target.checked }); this.validator.purgeFields() }}></Checkbox></Tooltip>}
                                />
                                {!this.state.autoGenerate && this.validator.message("Mã viện phí", this.state.mavp, "required")}
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item label="Giá tiền:">
                                <InputNumber
                                    style={{ width: "100%" }}
                                    maxLength={10}
                                    min={0}
                                    value={this.state.giatien}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    onKeyDown={(e) => {
                                        if (e.key === 'e') {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        this.setState({ giatien: e })
                                    }} />
                                {this.validator.message("Giá tiền", this.state.giatien, "required")}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row no-gutters mt-2">
                        <div className="col-md-12">
                            <Form.Item label="Tên viện phí:">
                                <Input
                                    value={this.state.tenvp}
                                    onChange={(e) => this.setState({ tenvp: e.target.value })}
                                />
                                {this.validator.message("Tên viện phí", this.state.tenvp, "required")}
                            </Form.Item>
                        </div>
                    </div>
                    {FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 &&
                        <div className="row no-gutters mt-2" style={{ display: 'flex' }}>
                            <div className="col-md-12">
                                <Form.Item>
                                    <label>Áp dụng đồng thời cho bảng giá</label>
                                    <Select
                                        mode='multiple'
                                        style={{ width: '100%' }}
                                        value={this.state.value}
                                        options={this.state.options}
                                        onChange={(newValue) => {
                                            this.setState({
                                                value: newValue
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </div>}
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}>
                        <button className="btn btn-success"
                            onClick={() => { this.CreateVP() }}>
                            <i className="fa fa-plus" style={{ marginRight: 5 }} />Thêm</button>
                        <button className="btn btn-warning" style={{ marginLeft: 10 }}
                            onClick={() => this.resetState()}> <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.props.onCancel();
                                this.props.onReload();
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} /> Đóng
                        </button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
