import { Button, Pagination, Checkbox, DatePicker, Form, Input, Modal, notification, Radio, Select, Table, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Autocomplete from '../../components/autocomplete';
import * as Actions from '../../libs/actions';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from '../../services/commonService';
import * as ChiDinhService from '../../services/chiDinhService';
import * as CorporationServices from '../corporation/CorporationServices';
import Administrative from '../recept/components/administrative';
import Barcode from '../recept/components/barcode';
import ModalCLS from '../../components/MDCLS';
import * as CollaboratorsServices from '../nhomctv/nhomctvservices';
import * as DistributeServices from '../dieuphoi/distributeservices';
import AutocompleteTable from '../../components/autocompletetable';
import * as ConfigService from '../../services/configService';
import NguonKhachHang from "../../screens/recept/register/nguonkhachhang";
import { IsActive_FeatureLCTV } from '../../configure/featureConfig';

const { Option } = Select;
const initialState = {
    mabn: '',
    mabs: '',
    machidinh: '',
    maphongkham: '',
    ngayhen: moment(),
    loaiChiDinh: '',
    tenchidinh: '',
    hoten: '',
}
export default class TaoLichHen extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstChiDinh: [],
            lstBN: [],
            lstDoctor: [],
            lstPhong: [],
            visible: false,
            newPatient: false,
            idGiaLayMau: 0,
            isLayMau: false,
            mactv: "",
            lstCTV: [],
            listGiaLayMau: [],
            diffAddr: false,
            newAddress: '',
            dataOrder: [],
            visibleOrder: false,
            vatinvoice: false,
            ghichu: '',
            lstNhomCTV: [],
            nhomCTV: "all",
            address: "",
            getType: IsActive_FeatureLCTV() ? 2 : 1,
            currentPage: 1,
            pageSize: 10,
            banggia: 'default',
            chinhanh: this.props.chinhanh,
            listSite : [],
            site : '',
            isUpdate : this.props.isUpdate ? this.props.isUpdate : false
        }
    }
    componentWillMount() {
        this.DanhSachPhongKham();
        this.GetListGiaLayMau();
        this.DanhSachBacSi();
        this.GetListSite();
        if (this.props.layMau) {
            this.setState({
                isLayMau: this.props.layMau,
            },
                this.getListCollaborators(),
                this.getListCollaboratorsGroup(),
            );
        }
        this.setState({
            chinhanh: this.props.chinhanh,
            site : this.props.chinhanh
        })
        if(this.props.patientID){
            this.GetPatientById(this.props.patientID);
        }
        ConfigService.BangGiaDefaultSite(this.props.chinhanh).then((respone) => {
            if (respone.isSuccess) {
                this.setState({
                    banggia: respone.item ? respone.item : 'default',
                });
            }
        }).catch(err => { })
    }
    componentDidMount() {
        this.form.setFieldsValue({ nhomctv: "all" });
    }
    DanhSachPhongKham() {
        CommonService.GetDepartment().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhong: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phòng khám bị lỗi!' })
        })
    }
    DanhSachBacSi() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoctor: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi!' })
        })
    }

    DanhSachChiDinh(key) {
        AppointmentService.AllService(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstChiDinh: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu chỉ định lỗi!' })
        })
    }
    TimKiemBenhNhan(key) {
        AppointmentService.TimKiemBN(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBN: result.data,
                });
            }
        }).catch(err => {
        })
    }

    reset() {
        setTimeout(() => {
            if (this.form)
                this.form.resetFields()
        }, 20)

        this.setState({
            ...initialState,
            lstChiDinh: [],
            mabs: '',
            maphongkham: '',
            visible: false,
            mactv: "",
            newAddress: '',
            diffAddr: false,
            dataOrder: []
        })
        this.form.setFieldsValue({
            mabn: null,
            mabs: null,
            phongkham: null,
            ngayhen: null,
            maphongkham: null,
            mactv: null
        });
        Actions.ResetBarCode(true);
        this.administrative.resetState();
    }
    async SaveLichHen() {
        Actions.setLoading(true);
        // if (this.state.mabs === null || this.state.mabs === undefined) {
        //     notification.warning({ message: 'Chưa chọn bác sĩ!' });
        //     return;
        // }
        // if (this.state.mabn === null || this.state.mabn === undefined || this.state.mabn === "") {
        //     notification.warning({ message: 'Chưa chọn bệnh nhân!' });
        //     Actions.setLoading(false);
        //     return;
        // }
        if (this.state.ngayhen === null || this.state.ngayhen === undefined) {
            notification.warning({ message: 'Chưa chọn ngày hẹn khám!' });
            Actions.setLoading(false);
            return;
        }
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        let newPatient = (this.state.mabn === null || this.state.mabn === undefined || this.state.mabn === "");
        if (newPatient) {
            await this.administrative.getForm().validateFields().then(values => {
                if (values.errorFields)
                    numberError += values.errorFields.length

            }).catch((ex) => {
                if (ex.errorFields)
                    numberError += ex.errorFields.length
            })
        }
        if (numberError === 0) {
            let dataAdministrative = {};
            // if (newPatient) {
            dataAdministrative = this.administrative.getState();
            // }
            let dataNguonKhachHang = this.nguonKhachHang && this.nguonKhachHang.getState();
            let lstSelect = this.state.dataOrder;
            for (let item of lstSelect) {
                item.ma = "0";
                // item.ten = item.name;
                item.madoituong = "2";
                // item.mavp = item.id.toString();
                // if (item.type === 3) {
                //     item.mavp = item.idvp.toString();
                // }
                item.giaaTH = item.dongia.toString();
                item.dongia = item.dongia;
                item.soluong = item.soluong;
                item.ngay = moment().format("YYYY-MM-DD HH:mm")
            }
            const data = {
                mabn: this.state.mabn.toString(),
                mabs: this.state.mabs,
                machidinh: Number(this.state.machidinh),
                phongkham: this.state.maphongkham,
                tenchidinh: this.state.tenchidinh.toString(),
                ngayhen: this.state.ngayhen,
                // newPatient: this.state.newPatient,
                newPatient: newPatient,
                patient: {
                    'name': dataAdministrative.name.toUpperCase(),
                    'dob': moment(dataAdministrative.dob).format('YYYY-MM-DD'),
                    'age': dataAdministrative.age,
                    'gender': dataAdministrative.gender,
                    'people': "25",
                    'cmnd': dataAdministrative.cmnd,
                    'phone': dataAdministrative.phone,
                    'email': dataAdministrative.email,
                    'address': dataAdministrative.address,
                    'city': dataAdministrative.city ? dataAdministrative.city.toString() : '',
                    'district': dataAdministrative.district ? dataAdministrative.district.toString() : '',
                    'ward': dataAdministrative.ward ? dataAdministrative.ward.toString() : '',
                    'jobAddress': dataAdministrative.jobAddress,
                    'village': dataAdministrative.village !== undefined ? dataAdministrative.village : '',
                    'country': dataAdministrative.nation,
                    'dateInHospital': moment().format('YYYY/MM/DD'),
                    'timeInHospital': moment().format('HH:mm'),
                    'department': this.state.maphongkham,
                    'subjectCode': "2",
                    'coporator': dataAdministrative.coporator,
                    'sendby': dataAdministrative.sendby,
                    'onlyyear': dataAdministrative.onlyyear,
                    'year': dataAdministrative.year,
                },
                lstChiDinh: lstSelect,
                mactv: this.state.isLayMau && this.state.mactv,
                idGiaLayMau: this.state.idGiaLayMau,
                newAddress: this.state.isLayMau && this.state.newAddress,
                vatinvoice: this.state.vatinvoice,
                ghichu: this.state.ghichu,
                idsale: dataNguonKhachHang.idsale ? dataNguonKhachHang.idsale.toString() : "",
                otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
                manguoigioithieu: dataNguonKhachHang.idngt ? dataNguonKhachHang.idngt.toString() : "",
                site : this.state.site.toString(),
            }
            if (this.state.isLayMau) {
                AppointmentService.LuuLichHenLayMau(data, this.props.chinhanh).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' })
                        this.reset();
                        this.props.onCancel();
                    } else {
                        Actions.openMessageDialog("Lỗi", result.err.msgString);
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    notification.warning({ message: 'Xảy ra lỗi khi lưu, vui lòng thử lại!' });
                    Actions.setLoading(false);
                })
            } else
                AppointmentService.LuuLichHen(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' })
                        this.reset();
                        this.props.onCancel();
                    } else {
                        Actions.openMessageDialog("Lỗi", result.err.msgString);
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    notification.warning({ message: 'Cập nhật thông tin bị lỗi!' });
                    Actions.setLoading(false);
                })
        }
        else {
            Actions.setLoading(false);
            this.validator.showMessages();
        }
    }
    disabledDate(current) {
        return current.valueOf() < Date.now();
    }
    showModal = () => {

        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.form.setFieldsValue({
            city: null,
            district: null,
            ward: null,
            mabs: null,
            phongkham: null,
            nhomctv: "all"
        });
        this.setState({
            visible: false,
            disabledUpdate: true,
            visibledUpdate: false,
            visibledSave: true
        });
    };
    handleLoadPatientBarCode(data) {
        this.setState({ mabn: data.patientID, hoten: data.patientName, address: data.address });
        this.form.setFieldsValue({ mabn: `${data.patientID}-${data.patientName}` })
    }

    GetListGiaLayMau = () => {
        AppointmentService.GetListGiaLayMau(true).then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.form.setFieldsValue({ idGiaLayMau: result.data[0].id })
                    this.setState({ idGiaLayMau: result.data[0].id })
                }
                this.setState({ listGiaLayMau: result.data })
            }
            else {
                notification.error({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            notification.error({ message: err });
        })
    }
    // XoaChiDinh(index) {
    //     let data = this.state.dataOrder;
    //     data = data.splice(index, 1);
    //     this.setState({ dataSource: data.map(x => ({ ...x })) })
    // }
    XoaChiDinh(row) {
        let data = this.state.dataOrder;
        data = data.filter(x => x.id !== row.id)
        this.setState({ dataOrder: data.map(x => ({ ...x })) })
    }
    getListCollaboratorsGroup = () => {
        CollaboratorsServices.GetListGroup("", 1, -1).then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstNhomCTV: res.data
                });
            } else {
                notification.warning({ message: "Không thể lấy thông tin nhóm" })
            }
        }).catch(err => console.log(err))
    }
    getListCollaborators = (getType = this.state.getType, group = this.state.nhomCTV, chinhanh = this.state.chinhanh) => {
        var getAll = getType === 1 ? true : false;
        if (!getAll) {
            var date = moment(this.state.ngayhen).format('YYYY/MM/DD HH:mm:ss');
            DistributeServices.GetListCollaborators(date, true, group, chinhanh).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        lstCTV: res.data,
                    });
                }
            }).catch(() => { })
        }
        else {
            CommonService.GetListCollaborators(1, 10, false, group, chinhanh).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        lstCTV: res.data,
                    });
                }
            }).catch(() => { })
        }
    }

    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }

    getColumn = () => {
        var columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                width: 60,
                align: 'center',
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1)
            },
            {
                title: 'Mã DV',
                dataIndex: 'mavp',
                key: 'mavp',
                width: 100,

            },
            {
                title: 'Tên DV',
                dataIndex: 'ten',
                key: 'ten',
                width: 240,

            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                width: 120,
                render: (text) =>
                    text.toLocaleString('en-US')
            },

            {
                title: '',

                key: '',

                render: (record) => (
                    <div style={{ cursor: 'pointer' }}
                        className="text-danger"
                        onClick={() => {
                            // this.XoaChiDinh(index)
                            this.XoaChiDinh(record);
                            if (this.OrderRef)
                                this.OrderRef.XoaChiDinh(record);
                        }} >
                        <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                    </div>
                )
            }
        ]
        return columns;
    }

    searchIndication(key) {
        return ChiDinhService.searchSerivce(0, 0, this.state.banggia, key, 1, 20);
    }

    onSelectIndication(item) {
        const { dataOrder } = this.state;
        var existed = dataOrder.find(x => x.id === item.id);
        if (existed === undefined) {
            item.banggia = this.state.banggia;
            item.soluong = 1;
            let total = 0;
            dataOrder.push(item)
            dataOrder.map(x => total += (x.dongia * x.soluong) * ((100 - x.discount) / 100))
            this.setState({
                dataOrder: dataOrder,

            })
        }
    }
    loadCoporationDetail(id){
		CorporationServices.CoporationDetail(id).then(result => {
			if(result.isSuccess){
				if(result.banggia !== ''){
					this.setState({
						banggia : result.item.banggia,
					})
					this.listServicesRef.handleSearch(this.searchIndication());
				}
			}
		})
	}

    checkBangGia = (info) => {
        let { banggia } = this.state;
        if (info.banggia !== banggia) {
            if (this.state.dataOrder.length > 0) {
                Actions.openMessageDialog("Cảnh báo",
                    `Việc thay đổi bảng giá mới (<b>${info.banggia === "default" ? "Mặc định" : info.banggia}</b>) sẽ xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`)
                this.setState({
                    dataOrder: [],
                });
                this.OrderRef && this.OrderRef.UpdateSelectedFromProps([]);
            }
            this.setState({ banggia: info.banggia });
        }
    }

    GetPatientById = (maBN) => {
        CommonService.GetPatientDetail(maBN).then(result => {
            if (result.isSuccess) {
                setTimeout(() => {
                    this.administrative && this.administrative.handleLoadPatientBarCode(result.item);
                }, 200);
            } else {
                notification.warning({ message: result.err.msgString })
            }
        }).catch(err => {
            notification.warning({ message: err })
        })
    }

    render() {
        const columnsSearh = [
            {
                title: 'Mã Dịch vụ',
                dataIndex: 'mavp',
                key: 'mavp',
                width: 70
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'ten',
                key: 'ten',
                width: 300
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
                width: 70,
                format: 'number',
            },
        ]
        let dataSource = this.state.dataOrder.map(function (e, index) {
            let o = Object.assign({}, e);

            o.key = o.id
            return o;
        })

        var pagination = {

            total: this.state.dataOrder.length,
            current: this.state.currentPage,
            pageSize: this.state.pageSize,
            onChange: (page, size) => { this.setState({ currentPage: page, pageSize: size }) },
            onShowSizeChange: (page, size) => {
                this.setState({ currentPage: 1, pageSize: size });
            },
            showSizeChanger: true
        }

        return (
            <React.Fragment >
                <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>Tạo lịch hẹn {this.state.isLayMau ? "lấy mẫu máu" : "khám bệnh"}</h3>
                    <h3 onClick={() => { this.props.onCancel(); this.reset() }}><i className='far fa-times-circle' /></h3>
                </div>
                <Form className='mt-2' ref={(c) => this.form = c} style={{ height: 'calc(100% - 140px)' }}>
                    <div style={{ height: '100%', display: 'flex' }}>
                        <div className="warrperStyleGroup" style={{ height: '100%', marginRight: 20, flex: 3 }} >
                            <div className="grouplabel">
                                <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Thông tin bệnh nhân</h4>
                            </div>
                            <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: 5 }}>
                                <div style={{ flex: 2, textAlign: 'left', marginRight: '40px' }}><strong>Mã bệnh nhân: {this.state.mabn}</strong></div>
                                <div style={{ display: 'flex' }}>
                                    <label style={{ marginRight: 5 }}>Ngày hẹn:</label>
                                    <Form.Item name="ngayhen" rules={[{ required: true, message: 'Vui lòng chọn ngày hẹn!' }]} >
                                        <DatePicker
                                            locale={viVN}
                                            showTime
                                            format={"DD-MM-YYYY HH:mm"}
                                            className="w-100"
                                            disabledDate={this.disabledDate.bind(this)}
                                            onChange={(e) => this.setState({ ngayhen: e }, () => this.state.isLayMau && this.getListCollaborators())}
                                            value={moment(this.state.ngayhen)} placeholder="dd-MM-YYYY" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto', padding: 0, overflowX: 'hidden' }}>
                                <Administrative ref={((c) => this.administrative = c)} 
                                    onSeached={(patientID) => {
                                        this.form.setFieldsValue({ mabn: patientID })
                                        this.setState({ mabn: patientID })
                                    }}
                                    sendData={(patientInfo) => {
                                        // if(patientInfo.coporator !== ''){
                                        //     this.setState({
                                        //         disableCopor : true
                                        //     })
                                        //     this.loadCoporationDetail(patientInfo.coporator);
                                        // }
                                    }}
                                    onReplaceBangGia={(tenBangGia) => {
                                        // if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
                                        //     this.setState({
                                        //         banggia: tenBangGia,
                                        //     });
                                        //     this.listServicesRef.handleSearch(this.searchIndication());
                                        // }
							        }}
                                    isUpdate={this.state.isUpdate}
                                    onReplaceCoporation={() => {}}
                                />
                                <div className='row'>
                                    {this.state.isLayMau && <div className="col-md-12"><i>*Chỉ có thể thêm/cập nhật nguồn khách hàng khi có thông tin chỉ định</i></div>}
                                    <NguonKhachHang ref={(c) => this.nguonKhachHang = c} updateInfoBangGia={(info) => { this.checkBangGia(info) }}/>
                                </div>
                                <div className='row mt-1 my-1 ml-1 mr-2'>
                                    <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle'/></Tooltip></label>
                                    <Select
                                        placeholder="Chọn chi nhánh"
                                        style={{ width: '100%' }}
                                        value={this.state.site}
                                        onChange={(value => this.setState({ site: value }))}
                                    >
                                        {this.state.listSite && this.state.listSite.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.ten}</option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                {
                                    this.state.isLayMau &&
                                    <React.Fragment>
                                        <div style={{ marginTop: 5 }}>
                                            <label>Nhóm cộng tác viên</label>
                                            <Form.Item name="nhomctv" rules={[{ required: true, message: 'Vui lòng chọn nhóm cộng tác viên!' }]} >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Chọn nhóm cộng tác viên"
                                                    onChange={(e) => { this.setState({ nhomCTV: e }, this.getListCollaborators(this.state.getType, e)); this.form.setFieldsValue({ mactv: null }); }}>
                                                    <Option value="all">Tất cả nhóm</Option>
                                                    {this.state.lstNhomCTV.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.label}>{row.label}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div style={{ marginTop: 5 }}>
                                            <label>
                                                <Radio.Group onChange={(e) => this.setState({ getType: e.target.value }, this.getListCollaborators(e.target.value, this.state.nhomCTV, this.state.chinhanh))} value={this.state.getType}>
                                                    <Radio value={1}>Tất cả CTV</Radio>
                                                    {IsActive_FeatureLCTV() &&
                                                        <Radio value={2}>CTV đã đăng ký lịch</Radio>
                                                    }
                                                </Radio.Group>
                                            </label>
                                            <Form.Item name="mactv" rules={[{ required: true, message: 'Vui lòng chọn cộng tác viên!' }]} >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Chọn cộng tác viên"
                                                    allowClear={true}
                                                    onChange={(e) => { this.setState({ mactv: e }) }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.state.lstCTV.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.value}>{row.label}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div style={{ marginTop: 5 }}>
                                            <Checkbox
                                                checked={this.state.vatinvoice}
                                                onChange={e => this.setState({ vatinvoice: !this.state.vatinvoice })}
                                            >Xuất hóa đơn đỏ</Checkbox>
                                        </div>
                                        <div style={{ marginTop: 5 }}>
                                            <label>Ghi chú</label>
                                            <Form.Item>
                                                <Input
                                                    placeholder={"Nhập ghi chú"}
                                                    value={this.state.ghichu}
                                                    onChange={(e) => this.setState({ ghichu: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div style={{ marginTop: 5, marginBottom: 5 }}>
                                            <label>Địa chỉ lấy mẫu</label>&nbsp;<i style={{ color: "#ccc" }}>{this.state.diffAddr ? "[Bấm vào \"Giống địa chỉ liên hệ\" để nhập địa chỉ mới]" : "[Bấm vào \"Địa chỉ mới\" để chọn địa chỉ liên hệ]"}</i>
                                            <Form.Item name="newAddress" rules={[{ required: !this.state.diffAddr, message: 'Vui lòng nhập địa chỉ lấy mẫu' }]} >
                                                <Input
                                                    placeholder={this.state.diffAddr ? this.state.address : "Nhập địa chỉ lấy mẫu"}
                                                    value={this.state.newAddress}
                                                    disabled={this.state.diffAddr}
                                                    prefix={<button className={this.state.diffAddr ? "btn btn-secondary btn-sm mr-2" : "btn btn-color btn-sm mr-2"}
                                                        onClick={(e) => { e.preventDefault(); this.setState({ diffAddr: !this.state.diffAddr, newAddress: "" }) }}>{this.state.diffAddr ? "Giống địa chỉ liên hệ" : "Địa chỉ mới"}</button>}
                                                    onChange={(e) => this.setState({ newAddress: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>

                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        <div className="warrperStyleGroup" style={{ height: '100%', width: '40%' }} >
                            <div className="grouplabel">
                                <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Dịch vụ</h4>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Tooltip placement="top" title={`Bảng giá: ${this.state.banggia === 'default' ? "Mặc định" : this.state.banggia}`}>
                                    <Form.Item label="Chọn dịch vụ">
                                        <AutocompleteTable
                                            ref={(c) => (this.listServicesRef = c)}
                                            width="200px"
                                            disabled={false}
                                            placeholder="Nhập tên dịch vụ để tìm kiếm"
                                            tablewidth={500}
                                            listHeight={"50vh"}
                                            onSearch={(value) => this.searchIndication(value)}
                                            onSelect={(item) => this.onSelectIndication(item)}
                                            value={this.state.serKey} headers={columnsSearh}
                                            keyValue={'id'}
                                        >
                                        </AutocompleteTable>
                                    </Form.Item>
                                </Tooltip>
								<Form.Item>
                                    <Tooltip title="Mở danh sách dịch vụ">
                                        <div style={{ marginLeft: 10 }}>
                                            <Button type='' className="btn btn-secondary ml-auto" onClick={e => {
                                                e.stopPropagation();
                                                this.setState({ visibleOrder: true })
                                                setTimeout(() => {
                                                    this.OrderRef.UpdateSelectedFromProps(this.state.dataOrder)
                                                }, 200)
                                            }}><i className={'fa fa-plus-circle'}></i></Button>
                                        </div>
                                    </Tooltip>
                                </Form.Item>
                            </div>
                            <div style={{ height: 'calc(100% - 65px)' }}>
                                <Table dataSource={dataSource}
                                    scroll={{ y: 0 }}
                                    pagination={pagination}
                                    columns={this.getColumn()} rowKey={(e, index) => index} />
                            </div>
                        </div>
                    </div>
                </Form>
                <div className='custom-hr'></div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Form.Item style={{ marginRight: 10 }}>
                        <button className="btn btn-color" onClick={() => this.SaveLichHen()}> <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                    </Form.Item>
                    <Form.Item>
                        <button className="btn btn-danger" onClick={() => {
                            this.props.onCancel();
                            this.reset();
                        }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />
                            Đóng</button>
                    </Form.Item>
                </div>
                <Modal

                    width={1400}
                    visible={this.state.visibleOrder}
                    onCancel={() => this.setState({ visibleOrder: false })}
                >
                    <ModalCLS 
                        showGoiKham={false}
                        maskClosable={false}
                        ref={(c) => this.OrderRef = c}
                        chinhanh={this.props.chinhanh}
                        onClose={(data) => {
                            this.setState({ dataOrder: data, visibleOrder: false })
                        }}
                        onReplaceBangGia={(tenBangGia) => {
                            if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
                                this.setState({
                                    banggia: tenBangGia,
                                });
                                this.listServicesRef.handleSearch(this.searchIndication());
                            }
                        }}
                        banggia={this.state.banggia}
                        editAfterRegister={true}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}