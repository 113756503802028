import CreateMedicine from './CreateMedicine'
import React from 'react';
export default function renderModal(props) {
    return (
        <React.Fragment>
            <CreateMedicine onCancel={(value) => props.onCancel(value)}></CreateMedicine>
            {/* {props.type === 1 ?
                <CreateMedicine onCancel={() => props.onCancel()}></CreateMedicine>
                :
                <CreateMeterial onCancel={() => props.onCancel()}></CreateMeterial>
            } */}

        </React.Fragment>
    )
}