import { Checkbox, DatePicker, Input, Pagination, Popconfirm, notification, Popover, Select, Table, Tooltip, Modal, Dropdown, Menu } from 'antd';
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react'
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as CorporationServices from '../../../services/CorporationServices';
import * as PatientService from '../../../services/commonService';
import * as Statistic from '../../../services/statisticService';
import * as InvoiceService from '../../../services/invoiceService';
import * as SystemConfig from "../../../configure/systemConfig";
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import AutocompletetableNoGroup from '../../../components/autocompletetable/autocompletetableNoGroup';
import Notify, { NotifyStatus } from '../../../components/notify'
import PhieuDieuChinh from './PhieuDieuChinh';
import PhieuHoanTra from './PhieuHoanTra';
import BillDetail from '../promotion/billdetail';
import HeaderModal from "../../../configureHIS/common/headerModal";
import { FeatureDeactive } from '../../../configure/featureConfig';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
var CongNo = true;
export default forwardRef((props, ref) => {
    const _hiddenLink = useRef()
    const notiRef = useRef(null);
    const [date, setDate] = useState([moment(), moment()])
    const [coporatorFilter, setCoporatorFilter] = useState(null);
    const [dataCoporator, setDataCoporator] = useState([]);
    const [listPaymentType, setListPaymentType] = useState([]);
    const [paymentType, setPaymentType] = useState(0);
    const [key, setKey] = useState('');
    const [sorting, setSorting] = useState('');

    const [dataSource, setDataSource] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);

    const [isCheckAll, setIsCheckAll] = useState(false)
    const [billSelected, setBillSelected] = useState('');
    const [visible, setVisible] = useState(false);
    const [visibleDel, setVisibleDel] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [bill, setBill] = useState('');
    const [paymentDetail, setPaymentDetail] = useState(null);
    const [lstPaymentDetail, setLstPaymentDetail] = useState([]);

    const [visiblePay, setVisiblePay] = useState(false);
    const [lstServicesPatient, setLstServicesPatient] = useState([]);
    const [isPaid, setIsPaid] = useState(false);
    const [selectedBillId, setSelectedBillId] = useState(null);

    useEffect(() => {
        LoadCoporation();
        GetPaymentMethod();
        CongNo = FeatureDeactive().indexOf("22a36aff-f95a-4f15-97d6-733a21f672e9") == -1;
    }, [])

    useEffect(() => {
        GetListPaidBill();
    }, [page, size, sorting])

    const LoadCoporation = (key = "") => {
        CorporationServices.GetList(key, 1, 40).then(res => {
            if (res.isSuccess) {
                setDataCoporator(res.data)
            }
        })
    }

    const GetPaymentMethod = () => {
        VPService.GetDanhSachLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                setListPaymentType(result.data)
            }
        }).catch(err => {
        })
    }

    const GetListPaidBill = () => {
        let filterModel = {
            //common: paging & sorting
            skipCount: (page - 1) * size,
            maxResultCount: size,
            sorting: sorting,
            //riêng theo module
            keyword: key,
            dateFrom: date[0],
            dateTo: date[1],
            coporationId: coporatorFilter,
            paymentType: paymentType
        }
        InvoiceService.GetListPaidBill(filterModel).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data.items);
                setTotalRecord(result.data.totalCount);
            } else {
                notification.error({ message: result.error.messageText });
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.error({ message: err.error.messageText });
            else notification.error({ message: err });
        })
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        if (sorter.column && sorter.order) {
            let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
            setSorting(sort);
        }
        else {
            setSorting('');
        }
    };


    const onSubmit = () => {
        setVisible(false);
        setVisibleDel(false);
        GetListPaidBill();
        setIndeterminate(false);
    }

    const showError = (msgString) => {
        if (notiRef && notiRef.current)
            notiRef.current.Show(msgString, NotifyStatus.Success)
    }


    const SelectAll = (checked) => {
        setIndeterminate(false)
        setIsCheckAll(checked)
        const data = dataSource.map(x => ({ ...x, checked: checked }))
        setDataSource(data)
    }

    const onCancel = () => {
        setVisible(false);
        setVisibleDel(false);
        GetListPaidBill();
    }

    const handleOpenChange = (newOpen) => {
        setVisible(newOpen);
    };

    const handleOpenDelChange = (newOpen) => {
        setVisibleDel(newOpen);
    };

    const LoadPaymentDetail = (data) => {
        // let x = this.state.patient;
        // let banle = false
        // if (x.loaithu === "3") {
        //     banle = true;
        // }
        InvoiceService.PatientPaymentInvoiceDetail(data.soBienLai, moment(data.ngay).format('YYYY-MM-DD'), false).then(result => {
            if (result.isSuccess) {
                setLstPaymentDetail(result.data)
                setVisiblePay(true)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const rowOptions = (record) => {
        let items = [];
        if (CongNo) {
            items.push(
                {
                    label:
                        <a href="#" onClick={() => {
                            setVisible(true);
                            setBill(record.soBienLai)
                        }}><i className='fas fa-undo-alt mr-1' />Hoàn trả công nợ </a>,
                    key: '0',
                },
            )
        }
        items.push(
            {
                label:
                    <a href="#" onClick={() => {
                        setVisibleDel(true);
                        setBill(record.soBienLai)
                    }}><i className='fas fa-edit mr-1' />Điều chỉnh miễn giảm </a>,
                key: '1',
            },
        )
        return (
            <Menu
                items={items}
            />
        )
    }

    const columnData = [
        {
            title: '#',
            width: 60,
            align: 'Center',
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
        },
        {
            title: <div>
                <Checkbox indeterminate={indeterminate} checked={isCheckAll} onChange={e => {
                    SelectAll(e.target.checked)
                }} />
            </div>,
            width: 60,
            align: 'Center',
            render: (text, record, index) => <div>
                <Checkbox
                    checked={record.checked == true}
                    onChange={e => {
                        let data = dataSource;
                        data[index].checked = e.target.checked
                        let count = data.filter(x => x.checked == true).length
                        if (count > 0 && count < data.length) {
                            setIndeterminate(true)
                        }
                        else {
                            setIndeterminate(false)
                        }
                        if (count == 0) {
                            setIsCheckAll(false)
                        }
                        if (count == data.length) {
                            setIsCheckAll(true)
                        }
                        setDataSource(data.map(x => ({ ...x })))
                    }}
                />
            </div>
        },
        {
            title: 'Mã phiếu',
            dataIndex: 'soBienLai',
            align: 'center',
            key: 'soBienLai',
            sorter: () => { },
            width: 100,
            render: (sophieu, record) => {
                return <a className="link-primary" href='#'
                    onClick={() => {
                        LoadPaymentDetail(record)
                        setPaymentDetail(record)
                        setSelectedBillId(sophieu)
                    }}>{sophieu}</a>
            }
        },
        {
            title: 'Thao tác',
            align: 'center',
            width: 100,
            render: (row, record) => {
                return (
                    <>
                        <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                            <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                        </Dropdown>

                        <Popover
                            placement="right"
                            content={
                                <div style={{ width: 400 }}>
                                    <PhieuHoanTra billSelected={bill} dateRange={date} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()} />
                                </div>
                            }
                            trigger="click"
                            visible={visible && bill == record.soBienLai}
                            onVisibleChange={handleOpenChange}
                        />
                        <Popover
                            placement="right"
                            content={
                                <div style={{ width: 400 }}>
                                    <PhieuDieuChinh billSelected={bill} dateRange={date} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()} />
                                </div>
                            }
                            trigger="click"
                            visible={visibleDel && bill == record.soBienLai}
                            onVisibleChange={handleOpenDelChange}
                        />
                    </>
                )
            }
        },
        {
            title: 'Nguồn khách hàng',
            dataIndex: 'nguonKhachHang',
            key: 'nguonKhachHang',
            width: 120,
        },
        {
            title: 'Tên bệnh nhân',
            dataIndex: 'hoTen',
            key: 'hoTen',
            sorter: () => { },
            width: 150,
        },
        {
            title: 'Ngày thanh toán',
            dataIndex: 'ngay',
            key: 'ngay',
            sorter: () => { },
            width: 120,
            render: (value) => {
                return <span>{value ? moment(value).format("DD/MM/YYYY HH:mm") : ''}</span>;
            },
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            width: 120,
            render: (value) => {
                return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Miễn giảm',
            dataIndex: 'discount',
            key: 'discount',
            align: 'right',
            width: 120,
            render: (value, record) => {
                return <span>{isNaN(value) ? 0 : Number((value * record.amount) / 100).toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Thành tiền',
            align: 'right',
            width: 120,
            render: (value, record) => {
                return <span>{Number(record.amount - ((record.discount * record.amount) / 100)).toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Hình thức thanh toán',
            dataIndex: 'paymentName',
            key: 'paymentName',
            align: 'right',
            width: 120,
        },
    ]

    let tongtien = 0;
    let tongthanhtoan = 0;
    let tongbhyt = 0;
    let lengthService = lstServicesPatient.filter(x => x.loai === '1').length;
    let lengthPayService = lstPaymentDetail.filter(x => x.loai === '1').length;
    if (isPaid === false) {
        tongtien = lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongiakm), 0);
        tongthanhtoan = lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongiakm), 0);
        tongbhyt = lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.giabh), 0);
    }
    let posGK = 0;
    let posGKM = 0;
    return (
        <React.Fragment>
            <div className='containerChilderWrapper'>
                <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
                <HeaderModal title="Lịch sử thanh toán" />
                <Notify ref={notiRef} />
                <div className='row'>
                    <div className='col-md-4 py-1' >
                        <label>Chọn ngày</label>
                        <RangePicker allowClear={true} format="DD-MM-YYYY" value={date} locale={viVN}
                            onChange={(value) => {
                                if (value == null)
                                    setDate([null, null])
                                else
                                    setDate(value)
                            }}
                        />
                    </div>
                    <div className='col-md-4 py-1' >
                        <label>Nguồn khách hàng</label>
                        <Select value={coporatorFilter} placeholder="Chọn nguồn khách hàng"
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                setCoporatorFilter(e)
                            }}
                            showSearch
                            allowClear
                            onSearch={(value) => LoadCoporation(value)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {dataCoporator.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                        </Select>
                    </div>
                    <div className='col-md-4 py-1' >
                        <label>Hình thức thanh toán</label>
                        <Select value={paymentType} placeholder="Chọn hình thức thanh toán"
                            style={{ width: '100%' }}
                            onSelect={(e) => setPaymentType(e)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            <Option key={-1} value={0}>Tất cả</Option>
                            {listPaymentType.map((payment, index) => <Option key={index} value={payment.ma}>{payment.ten}</Option>)}
                        </Select>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 py-1' >
                        <label>Từ khóa</label>
                        <Input
                            placeholder="Nhập mã phiếu hoặc tên khách hàng"
                            value={key}
                            onChange={(e) => { setKey(e.target.value) }}
                            onPressEnter={() => {
                                setPage(1);
                                GetListPaidBill();
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 py-1'>
                        <div className='text-right'>
                            <button className='btn btn-primary mr-1'
                                onClick={() => {
                                    setPage(1);
                                    GetListPaidBill();
                                }}
                            ><i className='fa fa-search mr-1'></i> Tìm kiếm</button>
                            <button className='btn btn-secondary'
                                onClick={() => {
                                    setDate([moment(), moment()]);
                                    setKey('');
                                    setPaymentType(0);
                                    setCoporatorFilter(null);
                                }}
                            ><i className='fa fa-eraser mr-1'></i> Xóa dữ liệu nhập</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 py-1'>
                        {CongNo &&
                            <Popover
                                placement="right"
                                title={""}
                                content={
                                    <div style={{ width: 400 }}>
                                        <PhieuHoanTra billSelected={billSelected} dateRange={date} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()} />
                                    </div>
                                }
                                visible={visible && bill == ''}
                                onVisibleChange={handleOpenChange}
                                trigger="click">
                                <button className='btn btn-secondary'
                                    onClick={() => {
                                        setBillSelected(dataSource.filter(x => x.checked == true).map(x => x.soBienLai).toString())
                                        // setVisible(true)
                                        setBill('')
                                    }}
                                ><i className='fas fa-undo-alt mr-1'></i> Hoàn trả công nợ theo lô</button>
                            </Popover>}
                        <Popover
                            placement="right"
                            content={
                                <div style={{ width: 400 }}>
                                    <PhieuDieuChinh billSelected={billSelected} dateRange={date} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()} />
                                </div>
                            }
                            visible={visibleDel && bill == ''}
                            onVisibleChange={handleOpenDelChange}
                            trigger="click"
                        >
                            <button className={`btn btn-secondary ${CongNo && "ml-2"}`}
                                onClick={() => {
                                    setBillSelected(dataSource.filter(x => x.checked == true).map(x => x.soBienLai).toString())
                                    // setVisibleDel(true)
                                    setBill('')
                                }}
                            ><i className='fa fa-edit mr-1'></i> Điều chỉnh miễn giảm theo lô</button>
                        </Popover>
                    </div>
                </div>
                <div style={{ height: 'calc(100% - 325px)' }} >
                    <Table
                        scroll={{ x: 'max-content', y: 0 }}
                        columns={columnData}
                        dataSource={dataSource}
                        pagination={false}
                        rowKey={(row, index) => index}
                        onChange={onTableChange}
                        locale={{
                            triggerDesc: "Bấm để sắp xếp giảm dần",
                            triggerAsc: "Bấm để sắp xếp tăng dần",
                            cancelSort: "Bấm để hủy bỏ sắp xếp",
                        }}
                    />
                </div>
                <div className='row'>
                    <div className='col-12 py-1'>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                            total={totalRecord}
                            showTotal={total => `Tổng: ${total}`}
                            size={size}
                            current={page}
                            onChange={(page, size) => {
                                setPage(page);
                                setSize(size);
                            }}
                            onShowSizeChange={(page, size) => {
                                setPage(page);
                                setSize(size);
                            }}
                            showSizeChanger={true}
                        />
                    </div>
                </div>

                <Modal
                    width={1000}
                    visible={visiblePay}
                    maskClosable={true}
                    onClose={() => setVisiblePay(false)}>
                    <BillDetail lstPaymentDetail={lstPaymentDetail} billId={selectedBillId} onClose={() => setVisiblePay(false)} />
                </Modal>
            </div>
        </React.Fragment>
    )
})
