

export let List = () => {
    let menuDefaultString = (localStorage.getItem('menuDefaultLoad') ?? '[]');
    let menuDefault = JSON.parse(menuDefaultString);
    return menuDefault;
}

export let Add = (menuId) => {
    let menuDefault = List();
    if (menuDefault.indexOf(menuId) < 0) {
        menuDefault.push(menuId);
        localStorage.setItem('menuDefaultLoad', JSON.stringify(menuDefault));
    }
}

export let Remove = (menuId) => {
    let menuDefault = List();
    menuDefault = menuDefault.filter(x => x !== menuId);
    if (menuDefault && menuDefault.length > 0) localStorage.setItem('menuDefaultLoad', JSON.stringify(menuDefault));
    else localStorage.removeItem('menuDefaultLoad');
}
