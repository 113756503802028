import { Button, Input, notification, Select } from 'antd';
import React, { useEffect, useState, useCallback, useRef } from 'react'
import * as Actions from '../../libs/actions';
import * as CorporationServices from './CorporationServices';
import * as PatientService from '../../services/patientService';
import * as VPService from '../../configureHIS/update_vp/services/VPService';
import * as NhanVienSerivce from '../../services/employeeService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import { DeleteThanhToanNoiTru } from '../../services/inPatientService';
import { FEATURE } from '../../configureHIS/common/constants';
import { FeatureDeactive } from '../../configure/featureConfig';

const { TextArea } = Input;
const { Option } = Select;

export default (props) => {
    
    const [edit, setEdit] = useState(false);
    const [detail, setDetail] = useState({});
    const [ten, setTen] = useState('');
    const [dienthoai, setDienthoai] = useState('');
    const [diachi, setDiachi] = useState('');
    const [daidien, setDaidien] = useState('');
    const [taikhoan, setTaikhoan] = useState('');
    const [username, setUsername] = useState('');
    const [ma, setMa] = useState('');
    const [lstNhanVienSale, setlstNhanVienSale] = useState([]);
    const [sale, setNhanVienSale] = useState(0)
    const [lstNguoigioithieu, setlstNguoigioithieu] = useState([]);
    const [nguoigioithieu, setNguoigioithieu] = useState(0);
    const [lstBanggia, setlstBanggia] = useState([]);
    const [lstLoaiTT, setlstLoaiTT] = useState([]);
    const [banggia, setBanggia] = useState('default');
    const [paymenttype, setPaymenttype] = useState(0);
    const [email, setEmail] = useState('');

    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        setEdit(props.edit)
    }, [props.edit])
    useEffect(() => {
        setDetail(props.detail)
    }, [props.detail])  
    useEffect(() => {
        if (detail.ma) {
            setDiachi(detail.diachi);
            setTen(detail.ten);
            setDienthoai(detail.dienthoai);
            setTaikhoan(detail.taikhoan);
            setMa(detail.ma);
            setDaidien(detail.daidien);
            setUsername(detail.username);
            setNhanVienSale(detail.sale);
            setNguoigioithieu(detail.nguoigioithieu);
            setBanggia(detail.banggia);
            setPaymenttype(detail.paymenttype);
            setEmail(detail.email);
        }
    }, [detail])

    useEffect(() => {
        GetlstNhanVienSale();
        GetlstNguoiGioiThieu();
        GetListBangGia();
        getPaymentMethod();
    },[])

    function GetlstNhanVienSale(){
        NhanVienSerivce.DanhSachNhanVien('', 1, 100,26).then(result => {
            if (result.isSuccess) {
                setlstNhanVienSale(result.data)
            }
        }).catch(err => {
        })
    }

    function GetlstNguoiGioiThieu(){
        Actions.setLoading(true);
        PatientService.GetListNguoiGioiThieu('', true, 1, 100).then(results => {
            if (results.isSuccess) {
                setlstNguoigioithieu(results.data);
            } else {
                Actions.openMessageDialog("Lỗi", results.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    function GetListBangGia(){
        VPService.GetListBangGia().then(result => {
            if (result.isSuccess) {
                setlstBanggia(result.data)
            }
        }).catch(err => {
        })
    }

    function getPaymentMethod() {
        VPService.GetDanhSachLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                let res = result.data;
                setlstLoaiTT(res)
            }
        }).catch(err => {
        })
    }
    
    const Submit = () => {
        if (validator.current.allValid()) {
            let data = {
                diachi, ten, dienthoai, taikhoan, ma, daidien, username,
                sale: sale,
                nguoigioithieu: nguoigioithieu,
                banggia, paymenttype,
                email : email
            }
            if (!edit) {
                Actions.setLoading(true);
                CorporationServices.CreateCoporation(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: "Thêm thành công" })
                        if (props.onSuccess)
                            props.onSuccess()
                    } else {
                        result.err.msgString ?
                            notification.warning({ message: result.err.msgString })
                            : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                    Actions.openMessageDialog('Lỗi', err)
                })
            }
            else {
                data.id = detail.id;
                Actions.setLoading(true);
                CorporationServices.UpdateCoporation(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: "Cập nhật thành công" })
                        if (props.onSuccess)
                            props.onSuccess()
                    } else {
                        Actions.openMessageDialog('Lỗi', result.err.msgString);
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                    Actions.openMessageDialog('Lỗi', err)
                })
            }
        } else {
            forceUpdate();
            validator.current.showMessages();
        }
    }
    return (
        <React.Fragment>
            <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                <h3>{edit ? detail.ten : 'Thêm nguồn khách hàng'}</h3>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label>Mã</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                    <Input disabled={edit} value={ma.toUpperCase()} className="w-100"
                        placeholder="Nhập mã nguồn khách hàng" maxLength={8}
                        onChange={(e) => {
                            setMa(e.target.value.toUpperCase());
                        }} />
                    {validator.current.message("Mã nguồn khách hàng", ma, "required")}
                </div>
                <div className="col-md-6 mb-2">
                    <label>Tên</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                    <Input value={ten} className="w-100"
                        placeholder="Nhập tên nguồn khách hàng"
                        onChange={(e) => {
                            setTen(e.target.value);
                        }} />
                    {validator.current.message("Tên nguồn khách hàng", ten, "required")}
                </div>
                <div className="col-md-6 mb-2">
                    <label>Điện thoại</label>
                    <Input value={dienthoai} className="w-100"
                        placeholder="Nhập điện thoại"
                        onKeyPress={(e) => {
                            if (e.key === " " || isNaN(e.key) === true)
                                e.preventDefault();
                        }}
                        onChange={(e) => {
                            setDienthoai(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Email</label>
                    <Input value={email} 
                        type="email"
                        className="w-100"
                        placeholder="Nhập email"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Người đại diện</label>
                    <Input value={daidien} className="w-100" maxLength={25}
                        placeholder="Nhập tên người đại diện"
                        onChange={(e) => {
                            setDaidien(e.target.value);
                        }} />
                </div>
                {/* <div className="col-md-6 mb-2">
                    <label>Tài khoản xem kết quả</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                    <Input
                        className='w-100' maxLength={20}
                        placeholder='Nhập tài khoản xem kết quả'
                        onChange={e => {
                            setUsername(e.target.value)
                        }}
                        value={username}
                    ></Input>
                    {validator.current.message("Tài khoản xem kết quả", username, "required")}
                </div> */}
                <div className="col-md-6 mb-2">
                    <label>Tài khoản ngân hàng</label>
                    <Input
                        className='w-100' maxLength={20}
                        placeholder='Nhập số tài khoản ngân hàng'
                        onKeyPress={(e) => {
                            if (e.key === " " || isNaN(e.key) === true)
                                e.preventDefault();
                        }}
                        onChange={e => {
                            setTaikhoan(e.target.value)
                        }}
                        value={taikhoan}
                    ></Input>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Địa chỉ</label>
                    <TextArea rows={1}
                        onChange={e => {
                            setDiachi(e.target.value)
                        }}
                        value={diachi}
                    ></TextArea>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Sale</label>
                    <Select
                        className="w-100"
                        showSearch
                        allowClear={true}
                        value={sale !== 0 ? sale : null}
                        onChange={(value) => { setNhanVienSale(value)  }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {lstNhanVienSale.map((row, index) => {
                            return (
                                <Option key={index} value={row.id}>{row.hoten}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Người giới thiệu</label>
                    <Select 
                        className="w-100"
                        showSearch
                        allowClear={true}
                        value={nguoigioithieu && nguoigioithieu != 0 ? nguoigioithieu.toString() : null}
                        onChange={(e) => {setNguoigioithieu(e !== undefined ? parseInt(e) : e)}}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {lstNguoigioithieu.map((row, index) => {
                            return (
                                <Option key={index} value={row.id}>{row.hoten}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Hình thức thanh toán mặc định</label>
                    <Select className="w-100" value={paymenttype !== 0 ? paymenttype : null} onChange={(e) => { setPaymenttype(e) }}>
                        {lstLoaiTT && lstLoaiTT.map((hinhthuc, index) =>
                            <option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                        )}
                    </Select>
                </div>
                {FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 &&
                    <div className="col-md-6 mb-2">
                        <label>Bảng giá</label>
                        <Select
                            label="Bang gia"
                            className="w-100"
                            showSearch
                            allowClear={true}
                            value={banggia}
                            onChange={(value) => { setBanggia(value) }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {/* <Option key={-1} value='default'>Mặc định</Option> */}
                            {lstBanggia.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>}
            </div>
            <div className="d-flex align-self-center">
                <button className="btn btn-color mr-1"
                    onClick={() => {
                        Submit()
                    }}>
                    <i className="fas fa-save" style={{ marginRight: 5 }} />Lưu</button>
                <button
                    className="btn btn-danger ml-1"
                    onClick={() => {
                        if (props.onClose)
                            props.onClose()
                    }}>
                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
            </div>
        </React.Fragment>
    )
}
