import { DatePicker, Form, notification, Table, Select, Pagination } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as PrintService from '../../../../services/printService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';
import * as VPService from '../../../../configureHIS/update_vp/services/VPService';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default class theodichvuBN extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            tongthucthu: 0,
            tongbn: 0,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            lstLoaiTT: [],
            currentPage : 1,
            currentSize : 10
        };
    }

    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        this.getPaymentMethod();
        this.GetListSite();
        this.LoadThongKeDoanhSo();
        
    }


    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadThongKeDoanhSo(page = this.state.currentPage, size = this.state.currentSize) {
        Actions.setLoading(true);
        StatisticService.ThongKeTheoDichVuBenhNhanPerformance(this.props.ngay !== undefined ? this.props.ngay[0].format('YYYY-MM-DD HH:mm') : this.state.ngay[0].format('YYYY-MM-DD 00:00'),
            this.props.ngay !== undefined ? this.props.ngay[1].format('YYYY-MM-DD HH:mm') : this.state.ngay[1].format('YYYY-MM-DD 23:59'),
            this.props.paymenttype === undefined ? 0 : this.props.paymenttype,this.props.coporator === undefined ? "" : this.props.coporator, '', '', this.props.chinhanh, page, size).then(result => {
                if (result.isSuccess) {
                    let tongtien = Number(result.returnObject)||0;
                    let tongbn = 0;
                    let count = 1;
                    result.data.forEach(x => {
                        // tongtien += x.tongtien;
                        if (x.mavp === null || x.mavp === '')
                            x.stt = count++
                    });
                    tongbn = result.totalRecords;
                    // result.data.GroupBy(x => new { x.mabn, x.ngay }).Select(x => x.First()).Count();
                    this.setState({
                        dataSource: result.data,
                        tongthucthu: tongtien,
                        tongbn: tongbn,
                        totalRecords: result.totalRecords,
                        currentPage: page, currentSize: size
                    });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
    }
    ExportThongKeDoanhSo(props) {
        Actions.setLoading(true);
        StatisticService.ExportThongKeTheoDichVuBenhNhan(this.props.ngay !== undefined ? this.props.ngay[0].format('YYYY-MM-DD HH:mm') : this.state.ngay[0].format('YYYY-MM-DD 00:00'),
            this.props.ngay !== undefined ? this.props.ngay[1].format('YYYY-MM-DD HH:mm') : this.state.ngay[1].format('YYYY-MM-DD 23:59'),
            this.props.paymenttype, this.props.coporator === undefined ? "" : this.props.coporator, this.props.chinhanh)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDoanhSoTheoHoaDon";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    PrintThongkeDichVuBenhNhan() {
        PrintService.PrintThongkeDichVuBenhNhan(this.props.ngay !== undefined ? this.props.ngay[0].format('YYYY-MM-DD HH:mm') : this.state.ngay[0].format('YYYY-MM-DD 00:00'),
            this.props.ngay !== undefined ? this.props.ngay[1].format('YYYY-MM-DD HH:mm') : this.state.ngay[1].format('YYYY-MM-DD 23:59'),
            this.props.paymenttype, this.props.coporator === undefined ? "" : this.props.coporator, this.props.chinhanh).then((result) => {
                Actions.DoPrint(result.item)
            })
    }
    CheckNumberMoney(num) {
        if (num < 0)
            return 0;
        return num;
    }
    GetAllInvoices = (data) => {
        if (data.sobienlai)
            return data.sobienlai;
        else return "";
    }
    getPaymentMethod() {
        const getAll = true;
        VPService.GetDanhSachLoaiThanhToan(getAll).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiTT: result.data,
                })
            }
        }).catch(err => {
        })
    }
    render() {
        const columns = [
            {
                title: 'STT',
                width: 50,
                align: 'center',
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <strong className={record.refunded ? "text-white" : ""} style={{ fontSize: '15px', float: 'left', marginLeft: '5px' }}>{record.stt + (this.state.currentPage - 1) * this.state.currentSize}. {`Biên lai${record.refunded ? " hoàn trả" : ""}`}: {this.GetAllInvoices(record)} - {record.tenbn} ({(record.tongtien).toLocaleString('en-US')})</strong>,
                            props: {
                                colSpan: 12,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{this.state.dataSource.filter(x => x.sobienlai === record.sobienlai).indexOf(record)}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Sản phẩm/Dịch vụ',
                dataIndex: 'tenvp',
                key: 'tenvp',
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span>{ }</span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{record.tenvp}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },

            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span>{ }</span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{record.soluong}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 100,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{record.dongia.toLocaleString('en-US')}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Thành tiền',
                align: 'right',
                width: 100,
                render: (text, row, index) => {
                    if (row.mavp === null || row.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{row.negValue ? (-1 * Math.round(row.soluong * row.dongia)).toLocaleString('en-US') : Math.round(row.soluong * row.dongia).toLocaleString('en-US')}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            // {
            //     title: 'BHYT trả',
            //     align: 'right',
            //     width: 100,
            //     render: (text, row, index) => {
            //         if (row.mavp === null || row.mavp === '') {
            //             return {
            //                 children: <span></span>,
            //                 props: {
            //                     colSpan: 0,
            //                 }
            //             }
            //         }
            //         else {
            //             return {
            //                 children: <span>{(row.soluong * row.giabh).toLocaleString('en-US')}</span>,
            //                 props: {
            //                     colSpan: 1,
            //                 }
            //             }
            //         }
            //     },
            // },
            {
                title: 'Giảm khác',
                align: 'right',
                width: 100,
                render: (text, row, index) => {
                    if (row.mavp === null || row.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{this.CheckNumberMoney(Math.abs(Number((row.soluong * row.dongia * (1 - row.tylekhuyenmai / 100) - row.soluong * row.giabh) * (row.giamgia / 100)).toFixed(3))).toLocaleString('en-US')}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Thực thu',
                align: 'right',
                width: 100,
                render: (text, row, index) => {
                    if (row.mavp === null || row.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{
                                row.negValue ? Number((0 - (row.soluong * row.dongia * (1 - row.tylekhuyenmai / 100) - row.soluong * row.giabh) * (1 - row.giamgia / 100)).toFixed(3)).toLocaleString('en-US')
                                    : Number(this.CheckNumberMoney((row.soluong * row.dongia * (1 - row.tylekhuyenmai / 100) - row.soluong * row.giabh) * (1 - row.giamgia / 100)).toFixed(3)).toLocaleString('en-US')}
                            </span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            // {
            //     title: 'Công nợ',
            //     align: 'right',
            //     width: 100,
            //     render: (text, record, index) => {
            //         if (record.mavp === null || record.mavp === '') {
            //             return {
            //                 children: <span></span>,
            //                 props: {
            //                     colSpan: 0,
            //                 }
            //             }
            //         }
            //         else {
            //             return {
            //                 children: <span>0</span>,
            //                 props: {
            //                     colSpan: 1,
            //                 }
            //             }
            //         }
            //     },
            // },
            // {
            //     title: 'Thu thực tế',
            //     align: 'right',
            //     width: 100,
            //     render: (text, row, index) => {
            //         if (row.mavp === null || row.mavp === '') {
            //             return {
            //                 children: <span></span>,
            //                 props: {
            //                     colSpan: 0,
            //                 }
            //             }
            //         }
            //         else {
            //             return {
            //                 children: <span>{this.CheckNumberMoney(Math.round((row.soluong * row.dongia * (1 - row.tylekhuyenmai / 100) - row.soluong * row.giabh) * (1 - row.giamgia / 100))).toLocaleString('en-US')}</span>,
            //                 props: {
            //                     colSpan: 1,
            //                 }
            //             }
            //         }
            //     },
            // },
            {
                title: 'Người lập',
                dataIndex: 'nguoilap',
                key: 'nguoilap',
                width: 200,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{text}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Hình thức thanh toán',
                dataIndex: 'hinhthuc',
                key: 'hinhthuc',
                width: 160,
                render: (type, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        let foundHinhThucThanhToan = this.state.lstLoaiTT && this.state.lstLoaiTT.find(x => Number(x.ma) === Number(type));
                        let hinhthucthanhtoan = (Number(type) > 0 && this.state.lstLoaiTT && foundHinhThucThanhToan !== undefined && foundHinhThucThanhToan.ten)
                        if(Number(type) == 4 && record.congnodathu !== null && record.congnodathu !== "" ) {
                            return {
                                    children: <span>
                                        {hinhthucthanhtoan} (Đã thu)
                                    </span>,
                                    props: {
                                        colSpan: 1,
                                    }
                                } 
                        } else {
                            return {
                                    children: <span>
                                        {hinhthucthanhtoan}
                                    </span>,
                                    props: {
                                        colSpan: 1,
                                    }
                                } 
                        }
                    }
                },
            },
            {
                title: 'Ngày lập',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 120,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{text}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },

        ]
        return (
            <React.Fragment>
                <div style={{ padding: "0px", display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 15 }}>
                        {/* {this.state.isMultisite === true &&
                            <div><Form.Item>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    allowClear={false}
                                    value={this.state.chinhanh}
                                    onChange={(e) => {
                                        this.setState({ chinhanh: e })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {this.state.listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select></Form.Item>
                            </div>
                        } */}
                        {this.props.doanhso === true &&
                            <>
                                <div className="col-auto">
                                    <Form.Item>
                                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                            allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                            placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                            onChange={(value => this.setState({ ngay: value }, () => { this.LoadThongKeDoanhSo() }))}
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item>
                                    <button className="btn btn-color" onClick={() => { this.LoadThongKeDoanhSo() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                </Form.Item>
                            </>
                        }
                        <Form.Item>
                            <button className="btn btn-outline-secondary" onClick={() => { this.PrintThongkeDichVuBenhNhan() }}> <i style={{ marginRight: 5 }} className="fa fa-print" /> In</button>
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-color" style={{ margin: "0px 10px 0 5px" }} onClick={() => { this.ExportThongKeDoanhSo() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                        </Form.Item>
                        {/* <div style={{ marginLeft: '5px' }}>Tổng khách hàng: <strong>{this.state.tongbn.toLocaleString('en-US')}</strong></div> */}
                        <div style={{ marginLeft: '15px' }}>Tổng tiền: <strong>{this.state.tongthucthu.toLocaleString('en-US')}</strong></div>
                    </div>

                    <div style={{ height: 'calc(100% - 120px)' }}>
                        <Table
                            scroll={{ x: 'max-content', y: 0 }}
                            dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index}
                            pagination={false}
                            rowClassName={(record) => {
                                if (record.refunded)
                                    return "bg-danger text-white";
                            }}
                        />
                        <Pagination
                            style={{
                                marginTop: 10, textAlignLast: 'center'
                            }}
                            total={this.state.totalRecords}
                            current={this.state.currentPage || 1}
                            size={this.state.currentSize || 10}
                            showTotal={(total) => `Tổng: ${total}`}
                            showSizeChanger={true}
                            onChange={(page, size) => {
                                this.setState({
                                    currentPage: page,
                                    currentSize: size,
                                }, () => this.LoadThongKeDoanhSo(page, size));
                            }}
                            onShowSizeChange={(page, size) => {
                                this.setState({
                                    currentPage: 1,
                                    currentSize: size,
                                }, () => this.LoadThongKeDoanhSo(1, size));
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
