import { Collapse, DatePicker, Form, notification, Select, Table, Pagination } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            loai: '',
            tenloai: 'Tất cả',
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            currentPage: 1,
            totalRecords: 0
        };
    }

    componentDidMount() {
        // UsersService.CheckPremissionMultisite().then(result => {
        //     if (result.isSuccess) {
        //         this.setState({
        //             isMultisite: result.item
        //         });
        //     }
        // });
        // this.GetListSite();
        this.LoadThongKeDoanhSo();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadThongKeDoanhSo(page = 1, size = 10) {
        Actions.setLoading(true);
        StatisticService.ThongKeDoanhSoDichVu(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh, this.state.loai, page, size, false).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data.map(x => ({ ...x, isActive: false })),
                    totalRecords: result.totalRecords,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    LoadThongKeDoanhSoChiTiet(row, page = 1, size = 10) {
        Actions.setLoading(true);
        StatisticService.ThongKeDoanhSoDichVuChiTiet(row.ngay, this.state.chinhanh, this.state.loai, page, size, false).then(result => {
            if (result.isSuccess) {
                let data = this.state.dataSource;
                let index = data.findIndex(x => x.ngay == row.ngay);
                if (index >= 0) {
                    data[index]['lstData'] = result.data;
                    data[index].currentPage = page
                    data[index].currentSize = size
                    data[index].totalRecords = result.totalRecords||0
                    this.setState({
                        dataSource: data.map(x => ({ ...x })),
                    });
                }
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeDoanhSo() {
        Actions.setLoading(true);
        StatisticService.ExportDoanhSo(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh, this.state.loai, this.state.tenloai, false)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDoanhSo";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    render() {
        const columnChild = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
            },
            {
                title: 'Thành tiền',
                dataIndex: 'tongtien',
                key: 'tongtien',
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            }
        ]
        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                },
                width: 100
            },
            {
                title: 'Chi tiết',
                // dataIndex: 'lstData',
                // key: 'lstData',
                width: 600,
                render: (row) => {
                    return (
                        <Collapse className="remove-space-statistic" activeKey={row.isActive == true ? "1" : "0"}
                            onChange={(e) => {
                                let data = this.state.dataSource;
                                row.isActive = !row.isActive
                                this.setState({
                                    dataSource: data.map(x => ({ ...x }))
                                });
                                if (row.isActive == true) {
                                    this.LoadThongKeDoanhSoChiTiet(row, 1, 10)
                                }
                            }}
                        >
                            <Panel header="Xem chi tiết" key={"1"}>
                                <div style={{ width: '100%' }}>
                                    <Table
                                        style={{ margin: '0 !important', width: '100%' }}
                                        pagination={{
                                            total: row.totalRecords,
                                            current: row.currentPage || 1,
                                            size: row.currentSize || 10,
                                            showTotal: total => `Tổng: ${total}`,
                                            showSizeChanger: true,
                                            onChange: (page, size) => {
                                                this.LoadThongKeDoanhSoChiTiet(row, page, size)
                                            },
                                            onShowSizeChange: (page, size) => this.LoadThongKeDoanhSoChiTiet(row, 1, size)
                                        }}
                                        dataSource={row.lstData} columns={columnChild} rowKey={(row, index) => index} />
                                </div>
                            </Panel>
                        </Collapse>
                    );
                },
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'tongtien',
                key: 'tongtien',
                width: 100,
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            },
        ]
        return (
            <div className='containerChilderWrapper'>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê dịch vụ</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                    <div className="row no-gutters">
                        {/* {this.state.isMultisite === true &&
                            <div className="col-auto">
                                <Form.Item>
                                    <Select
                                        style={{ width: '200px' }}
                                        showSearch
                                        allowClear={false}
                                        value={this.state.chinhanh}
                                        onChange={(e) => {
                                            this.setState({ chinhanh: e })
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key={-1} value={''}>Chi nhánh</Option>
                                        {this.state.listSite.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.ma}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        } */}
                        <div className="col-auto">
                            <Form.Item>
                                <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                    allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                    placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                    onChange={(value => this.setState({ ngay: value }, () => this.LoadThongKeDoanhSo()))}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item style={{ marginLeft: 20, display: 'none' }} label="Loại:">
                            <Select
                                style={{ width: 200 }}
                                value={this.state.loai}
                                showSearch
                                onChange={(e, x) => this.setState({ loai: e, tenloai: x.children })}>
                                <Option key={1} value={''}>Tất cả</Option>
                                <Option key={2} value={'1'}>Xét nghiệm</Option>
                                <Option key={3} value={'2'}>Chẩn đoán hình ảnh</Option>
                                <Option key={4} value={'3'}>Tư vấn khám</Option>
                                <Option key={5} value={'4'}>Thuốc</Option>

                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-success" style={{ margin: "0px 5px" }} onClick={() => { this.LoadThongKeDoanhSo() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-primary" onClick={() => { this.ExportThongKeDoanhSo() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                        </Form.Item>
                    </div>
                </div>
                <div style={{ height: 'calc(100% - 155px)' }}>
                    <Table
                        scroll={{ x: 'max-content', y: 0 }}
                        pagination={false}
                        // pagination={{
                        //     position: ['bottomCenter'],
                        //     // showSizeChanger: true,
                        //     // style: { textAlignLast: "center", marginTop: 30 },
                        //     // total: this.state.totalRecords,
                        //     // showTotal: (total) => (`Tổng: ${total}`)
                        //     current:{this.state.currentPage},
                        //         style={{ textAlignLast: "center", marginTop: "10px" }},
                        //         total={this.state.totalRecords},
                        //         showTotal={total => `Tổng: ${total}`},
                        //         onChange={(page, size) => { this.LoadThongKeDoanhSo(page, size) }},
                        //         onShowSizeChange={(page, size) => this.LoadThongKeDoanhSo(1, size)},
                        //         showSizeChanger={true}
                        // }}
                        dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                </div>
                {/* <Pagination
                    showSizeChanger={true}
                    style={{ textAlignLast: "center", marginTop: 15 }}
                    total={this.state.totalRecords}
                    showTotal={(total) => (`Tổng: ${total}`)}
                /> */}
                <Pagination
                    current={this.state.currentPage}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={this.state.totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => { this.setState({ currentPage: page }); this.LoadThongKeDoanhSo(page, size) }}
                    onShowSizeChange={(page, size) => this.LoadThongKeDoanhSo(1, size)}
                    showSizeChanger={true}
                />
            </div>
        )
    }
}
