import React,{ useEffect, useState, useRef } from "react"
import {Switch, Popconfirm, Form, Table, Input, Pagination, Modal, notification,InputNumber} from 'antd';
import CreateNew from "./create"
import * as Actions from '../../../libs/actions';
import * as ConfigService from '../../../services/configService'
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';


function Marketing(props) {
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [lstMarketing , setLstMarketing] = useState([]);
    const [id , setId] = useState('');
    const [ten, setTen] = useState('');
    const [viettat, setViettat] = useState('');
    const [visible, setVisible] = useState(false);
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [checkValid, setcheckValid] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const form = useRef();

    useEffect(() => {
        GetKenhKhachHang();
    },[currentPage,size])

    const GetKenhKhachHang = () => {
        Actions.setLoading(true);
        ConfigService.GetKenhKhachHang(currentPage, size, true).then(result => {
            if (result.isSuccess) {
                setLstMarketing(result.data)
                setTotalRecords(result.totalRecords)
            } else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    const UpdateMarketing = () => {
        const data = {
            ten : ten,
            viettat : viettat,
            id : id,
        }
        ConfigService.UpdateKenhKhachHang(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' })
                GetKenhKhachHang();
            } else {
                notification.warning({ message: result.err.msgString });
            }
        })
        
    }

    const DeleteMarketing = (id) => {
        ConfigService.DeleteKenhKhachHang(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' })
                GetKenhKhachHang();
            } else {
                notification.warning({ message: result.err.msgString });
            }
        })
    }

    const updateHide = (id, e) => {
        ConfigService.HideKenhKhachHang(id, !e).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' })
                GetKenhKhachHang();
            } else {
                notification.warning({ message: result.err.msgString });
            }
        })
    }

    const handleCancel = () => {
        setVisible(false)
        setVisibleCreate(false)
        GetKenhKhachHang();
    }

    const columns=[
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: '5%',
            render: (text, record, index) => ((currentPage - 1) * size + Number(index) + 1),
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: '20%',
            align: 'left',
        },
        {
            title:'Viết tắt',
            dataIndex:'viettat',
            key: 'viettat',
            width:'20%',
            align: 'center',
        },
        {
            title: 'Hiển thị',
            align: 'center',
            width:'10%',
            render: (row) => {
                return (
                    <Switch checkedChildren="Hiển thị" unCheckedChildren="Ẩn" checked={row.hide === false}
                        onChange={(e) => { updateHide(row.id, e); }} />
                )
            }
        },
        {
            title: 'Action',
            align: 'center',
            width: '30%',
            render: (row) => {
                return (
                    <div>
                        <button className="btn btn-warning"
                            title="Cập nhật loại thanh toán!"
                            onClick={() => {
                                setVisible(true);
                                setId(row.id)
                                setTen(row.ten);
                                setViettat(row.viettat);
                            }}>
                            <i style={{ marginRight: 10 }} className="fa fa-edit"></i>Cập nhật
                        </button>
                        <Popconfirm
                            title="Bạn có chắc muốn xóa?"
                            onConfirm={() => { 
                                DeleteMarketing(row.id)
                                }}
                            okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" style={{ marginLeft: "15px" }}
                            ><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]

    return (
    <React.Fragment>
            <Form ref={form} >
                <div className='containerChilderWrapper'>
                    <div className='h4 text-center mt-2 mb-3 font-bold text-uppercase'>Danh mục kênh khách hàng</div>
                    <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'right' }}>
                        <Form.Item >
                            <button className="btn btn-success" onClick={() => setVisibleCreate(true)}
                            ><i style={{ marginRight: 10 }} className="fa fa-plus" />Tạo mới</button>
                        </Form.Item>
                    </div>

                    <div style={{ paddingTop: 20, height: 'calc(100% - 130px)' }}>
                        <Table dataSource={lstMarketing} scroll={{ y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        current={currentPage}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setSize(size)
                        }}
                        onShowSizeChange={(page, size) => {
                            setCurrentPage(1);
                            setSize(size)
                        }
                        }
                        showSizeChanger={true}
                    />
                </div>
            </Form>
            <Modal maskClosable={false}
                width={900}
                visible={visible}
                cancelText='Đóng'
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}>
                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <h3>Cập nhật kênh khách hàng</h3>
                </div>
                <div className="container-fluid row" style={{ padding: 'inherit' }}>
                    <div className="form-group" style={{ flex: 1 }} >
                        <Form.Item label="Tên kênh khách hàng:" style={{ flex: 1 }}>
                            <Input
                                placeholder="Tên kênh khách hàng"
                                value={ten}
                                name="tenkenhkhachhang"
                                onChange={(e) => {
                                    setTen(e.target.value)
                                }} />
                            {validator.current.message('Tên kênh khách hàng', ten, 'required')}
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '8px' }} >
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Viết tắt:" style={{ flex: 1 }}>
                                <Input
                                    placeholder="Viết tắt"
                                    value={viettat}
                                    name="tenviettat"
                                    onChange={(e) => {
                                        setViettat(e.target.value)
                                    }} />
                                {validator.current.message('Tên kênh khách hàng', ten, 'required')}
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button className="btn btn-success" onClick={() => {UpdateMarketing()}}>
                        <i className="fa fa-save" style={{ marginRight: 5 }} ></i>Cập nhật</button>
                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                        onClick={() => {
                            handleCancel()
                        }}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                    </button>
                </div>
            </Modal>
            <Modal maskClosable={false}
                width={900}
                visible={visibleCreate}
                cancelText='Đóng'
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}>
                <CreateNew
                    onCancel={() => handleCancel()}/>
            </Modal>
        </React.Fragment>
    )
}
export default Marketing;
