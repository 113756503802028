export const KHAMBENH = 8;
export const NHOMXETNGHIEM = 2;
export const NHOMCDHA = 3;
export const LOYALTY_TYPE = {
    VOUCHER: 0,
    SERVICE: 1,
    CARD : 2,
    MEMBER: 3,
};

export const PAYMENT_SERVICE = {
    GOIKHAM: -1,
    THUOC: -2,
    BANLE: -3,
    VATTU: -4,
}

export const SERVICE_TYPE = {
    DICHVU: 1,
    THUOCKEDON: 2,
    THUOCBANLE: 3,
    VATTU: 4,
}

//Button classname
export const BUTTON = {
    SUCCESS: "btn btn-success",
    WARNING: "btn btn-warning",
    DANGER: "btn btn-danger",
    THEME: "btn btn-color",
    SECONDARY: "btn btn-secondary",
    PRIMARY: "btn btn-primary",
    INFO: "btn btn-info",
    OUTLINE: "btn btn-outline",
    LIGHT: "btn btn-light",
    OUTLINE_SECONDARY: "btn btn-outline-secondary",
    CUSTOM: "btn btn-custom",
    OUTLINE_SUCCESS: "btn btn-outline-success",
    OUTLINE_DANGER: "btn btn-outline-danger",
    OUTLINE_INFO: "btn btn-outline-info",
    APPOINMENT: "btn btn-appointment",
    OUTLINE_WARNING: "btn btn-warning",
}

//Feature
export const FEATURE = {
    CDHA: '1de4afcd-2e02-45f1-a046-05e685c58ad9',
    CNTS: '22a36aff-f95a-4f15-97d6-733a21f672e9',
    CTKM: '944a1168-57b5-4855-9a22-a98276d40ac2',
    DEBITCARD: '6b8c66a3-f125-4056-8a9b-770f1d345003',
    MULTI_SITE: 'a14aefe9-50f8-4a36-a975-fa60f42cf320',
    MULTI_BANG_GIA: '28618a1e-a850-4aa3-9b18-6b66b758e222',
    PHAUTHUAT_THUTHUAT: '31eae08c-ecda-482e-b991-21361b59f34d',
    XETNGHIEM: "6f2bd701-d6c6-4f3b-ad34-51d0074775dd",
}

//Patient History
export const HISTORYFEATURE = {
    THUTHUAT : "THUTHUAT",
    XETNGHIEM : "XETNGHIEM",
    CDHA : "CDHA",
    KHAMBENH : "KHAMBENH",
    DVKhac : "DVKhac",
}

//Patient History
export const HISTORYFEATURETYPE = {
    SIEUAM : "SIEUAM",
    XQUANG : "XQUANG",
    NOISOI : "NOISOI",
}

// export const BUTTON = {
//     SUCCESS: "btn btn-color",
//     WARNING: "btn btn-color",
//     DANGER: "btn btn-danger",
//     THEME: "btn btn-color",
//     SECONDARY: "btn btn-secondary",
//     PRIMARY: "btn btn-color",
//     INFO: "btn btn-info",
//     OUTLINE: "btn btn-outline",
//     LIGHT: "btn btn-light",
//     OUTLINE_SECONDARY: "btn btn-outline-secondary",
//     CUSTOM: "btn btn-custom",
//     OUTLINE_SUCCESS: "btn btn-color",
//     OUTLINE_DANGER: "btn btn-color",
//     OUTLINE_INFO: "btn btn-color",
//     APPOINMENT: "btn btn-appointment",
//     OUTLINE_WARNING: "btn btn-color",
// }