import { Input, Button, notification } from 'antd';
import React, { useState, useEffect } from 'react'
import * as Actions from '../../../libs/actions';
import * as VPService from '../services/VPService';

export default (props) => {
    const [detail, setDetail] = useState({});
    const [ma, setMa] = useState('');
    const [ten, setTen] = useState('');
    const [listSubTest, setListSubTest] = useState([]);
    const [btmax, setBtmax] = useState('');
    const [btmin, setBtmin] = useState('');
    const [unit, setUnit] = useState('');
    const [edit, setEdit] = useState(false);
    const [normalrange, setNormalrange] = useState('');
    const [stt, setStt] = useState(0);

    useEffect(() => {
        if (detail.id)
            LoadData();
    }, [detail])
    useEffect(() => {
        setDetail(props.detail);
    }, [props.detail])

    const LoadData = () => {

        Actions.setLoading(true);
        VPService.GetListSubTest(detail.id).then(result => {
            if (result.isSuccess) {
                setListSubTest(result.data);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: err });
        })

    }

    const submit = () => {
        const data = {
            ma: ma,
            ten: ten,
            binhthuongmax: btmax,
            binhthuongmin: btmin,
            unit: unit,
            normalrange: normalrange,
            stt: Number(stt),
        }
        if (data.ma.trim() == '' || data.ten.trim() == '') {
            notification.warning({ message: 'Vui lòng nhập liệu!' });
            return;
        }
        Actions.setLoading(true);
        if (edit === false) {
            VPService.InsertSubTest(detail.id, data).then(result => {
                if (result.isSuccess) {
                    LoadData();
                    setBtmax('');
                    setBtmin('');
                    setMa('');
                    setTen('');
                    setUnit('');
                    setNormalrange('');
                    setStt(0);
                    notification.success({ message: 'Thêm thành công' });
                }
                else
                    notification.warning({ message: result.err.msgString });
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: err });
            })
        }
        else {
            VPService.UpdateSubTest(detail.id, data).then(result => {
                if (result.isSuccess) {
                    setEdit(false);
                    LoadData();
                    setBtmax('');
                    setBtmin('');
                    setMa('');
                    setTen('');
                    setUnit('');
                    setNormalrange('');
                    setStt(0);
                    notification.success({ message: 'Thêm thành công' });
                }
                else
                    notification.warning({ message: result.err.msgString });
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: err });
            })
        }
    }
    const Detele = (data) => {
      
        Actions.setLoading(true);
        VPService.DeleteSubTest(detail.id, data.ma).then(result => {
            if (result.isSuccess) {
                LoadData();
                notification.success({ message: 'Xóa thành công' });
            }
            else
                notification.warning({ message: result.err.msgString });
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: err });
        })
    }
    return (
        <React.Fragment>
            <div
                className="text-center h4 text-uppercase"
            >{detail.ten}</div>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label>Mã</label>
                    <Input className="w-100" maxLength={10} placeholder="Nhập mã test con" value={ma} onChange={(e) => {
                        setMa(e.target.value)
                    }}
                        disabled={edit}
                    />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Tên</label>
                    <Input className="w-100" maxLength={100} placeholder="Nhập tên test con" value={ten} onChange={(e) => {
                        setTen(e.target.value)
                    }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Bình thường min</label>
                    <Input className="w-100" maxLength={100} placeholder="Nhập bình thường min" value={btmin} onChange={(e) => {
                        setBtmin(e.target.value)
                    }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Bình thường max</label>
                    <Input className="w-100" maxLength={100} placeholder="Nhập bình thường max" value={btmax} onChange={(e) => {
                        setBtmax(e.target.value)
                    }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Đơn vị</label>
                    <Input className="w-100" maxLength={100} placeholder="Nhập đơn vị" value={unit} onChange={(e) => {
                        setUnit(e.target.value)
                    }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>NormalRange</label>
                    <Input className="w-100" maxLength={100} placeholder="Nhập NormalRange" value={normalrange} onChange={(e) => {
                        setNormalrange(e.target.value)
                    }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Thứ tự</label>
                    <Input type={'number'} className="w-100" maxLength={10} placeholder="Nhập thứ tự" value={stt}
                        onChange={(e) => {
                            if (e.target.value.length <= 10)
                                setStt(e.target.value)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'e' || e.key === 'E' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                    />
                </div>
                <div className="col-12 mb-2 text-center align-self-end">
                    <div className="d-flex justify-content-center">
                        {edit === false ?
                            <Button className="btn-color"
                                onClick={() => {
                                    submit()
                                }}
                            >Thêm test con</Button>
                            :
                            <React.Fragment>
                                <Button className="btn-color"
                                    onClick={() => {
                                        submit()
                                    }}
                                >Sửa test con</Button>
                                <Button className="ml-2"
                                    onClick={() => {
                                        setEdit(false);
                                        setBtmax('');
                                        setBtmin('');
                                        setMa('');
                                        setTen('');
                                        setUnit('');
                                        setNormalrange('');
                                    }}
                                >Hủy</Button>
                            </React.Fragment>
                        }
                        <Button className="ml-2"
                            onClick={() => {
                                if (props.onclose)
                                    props.onclose()
                            }}
                        >Đóng</Button>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Mã</th>
                                    <th>Tên</th>
                                    <th>Bình thường max</th>
                                    <th>Bình thường min</th>
                                    <th>NormalRange</th>
                                    <th>Đơn vị</th>
                                    <th>Thứ tự</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listSubTest.map((row, index) => <tr key={index}>
                                    <td>{row.ma}</td>
                                    <td>{row.ten}</td>
                                    <td>{row.binhthuongmax}</td>
                                    <td>{row.binhthuongmin}</td>
                                    <td>{row.normalrange}</td>
                                    <td>{row.unit}</td>
                                    <td>{row.stt}</td>
                                    <td>
                                        <div style={{ display: 'flex' }}>
                                            <Button className="btn btn-danger"
                                                onClick={() => {
                                                    Detele(row)
                                                }}
                                            ><i className="fa fa-trash"></i></Button>
                                            <Button className="btn btn-warning ml-2"
                                                onClick={() => {
                                                    setEdit(true);
                                                    setBtmax(row.binhthuongmax);
                                                    setBtmin(row.binhthuongmin);
                                                    setMa(row.ma);
                                                    setTen(row.ten);
                                                    setUnit(row.unit);
                                                    setNormalrange(row.normalrange);
                                                }}
                                            ><i className="fa fa-edit"></i></Button>
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
