import React, { Fragment, useEffect, useState } from 'react'
import { Select, Checkbox } from 'antd';
import { connect } from 'react-redux';
import * as AuthServices from '../../services/authServices';
const { Option } = Select;

function SiteFilter(props) {
    const [site, setSite] = useState(props.availableSites);
    const [availableSites, setAvailableSites] = useState([]);
    const [allSite, setAllSite] = useState(false);

    useEffect(() => {
        getAvaiableSites();
    }, []);

    const getAvaiableSites = () => {
        AuthServices.getAvailableSites().then((res) => {
            if (res.isSuccess) {
                setAvailableSites(res.data);
            }
        })
    };

    useEffect(() => {
        if (site.length === props.availableSites.length)
            setAllSite(true);
        else setAllSite(false)
        props.updateSeletedSite && props.updateSeletedSite(site);
    }, [site]);

    return (
        <Fragment>
            <div className='col-12 mt-2'>
                <label>Chi nhánh&nbsp;
                    <Checkbox
                        className='ml-2'
                        onChange={(e) => {
                            setAllSite(!allSite);
                            if (!e.target.checked) setSite([]);
                            else setSite(props.availableSites);
                        }}
                        checked={allSite}>Tất cả</Checkbox>
                </label>
                <Select
                    id="prosta-slt-site"
                    mode="multiple"
                    allowClear
                    className="w-100"
                    placeholder="Chọn chi nhánh (Lấy chi nhánh hiện tại nếu không chọn)"
                    value={site}
                    onChange={(site) => { setSite(site) }}
                    showSearch
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {availableSites && availableSites.map((row, index) => {
                        return (
                            <Option key={index} value={row.id}>{row.ten}</Option>
                        )
                    })}
                </Select>
            </div>
        </Fragment >
    );
}

const mapStateToProps = (state) => {
    return ({
        availableSites: state.global.availableSites
    })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(SiteFilter);