import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Upload, Select, Steps, notification, Table } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import { ValidationCustom } from '../../commons/validationCustom';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../components/notify';
import * as CompanyExamService from '../../services/CompanyExamService';
import * as ConfigService from "../../services/configService";
import * as NhanVienService from '../../services/employeeService';
import * as PatientService from '../../services/patientService';
import * as AuthServices from '../../services/authServices';
import { connect } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;
const { Step } = Steps;
const listSendResultTypes = [ // lấy theo SendMessageType trong HIS.Resource/Enum
  // Sms,
  // Zalo,
  // Email
  {value: 0, text: 'Sms'},
  {value: 1, text: 'Zalo'},
  {value: 2, text: 'Email'},
  {value: 3, text: 'Tại trung tâm'},
  {value: 4, text: 'Tại phòng khám'},
]
const DownLoadTemplate = React.forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const upload = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  //state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [detailScreenMode, setDetailScreenMode] = useState(props.detailScreenMode);
  //model
  const [id, setId] = useState(props.companyExamId);
  const [siteId, setSiteId] = useState(props.siteid);
  const [coporationId, setCoporationId] = useState();
  const [saleId, setSaleId] = useState(null);
  const [nguoiGioiThieuId, setNguoiGioiThieuId] = useState(null);
  const [contractNo, setContractNo] = useState();
  const [sendResultTypes, setSendResultTypes] = useState([]);
  const [remark, setRemark] = useState();
  const [file, setFile] = useState();
  const [banggia, setBanggia] = useState();  
  const [siteName, setSiteName] = useState();
  const [coporationName, setCoporationName] = useState();
  const [saleName, setSaleName] = useState();
  const [nguoiGioiThieuName, setNguoiGioiThieuName] = useState(null);
  const [sendResultTypeNames, setSendResultTypeNames] = useState([]);
  const [isEdited, setIsEdited] = useState();
  const [listFileImport, setListFileImport] = useState([]);

  const [listSites, setListSites] = useState([]);
  const [listCoporations, setListCoporations] = useState(props.listCoporations);
  const [listSales, setListSales] = useState([]);
  const [listNguoiDuoiThieus, setListNguoiDuoiThieus] = useState([]);
  const [importStatus, setImportStatus] = useState('');//undefine | success | error
  const [errorFileString, setErrorFileString] = useState('');
  const [listMessageResult, setListMessageResult] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [closeReload, setCloseReload] = useState(false);
  //#region useEffect
  useEffect(() => {
    getListSites();
    getListSales();
    getListNguoiGioiThieus();
    if (props.detailScreenMode == detailScreenModeEnum.update)
      getById(id);
  }, []);
  //#endregion 
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
  };

  const setData = (data) => {
    setCoporationId(data.coporationId);
    setCoporationName(data.coporationName);
    setSiteId(data.siteId);
    setSiteName(data.siteName);
    setSaleId(data.saleId);
    setSaleName(data.saleName);
    setNguoiGioiThieuId(data.nguoiGioiThieuId);
    setNguoiGioiThieuName(data.nguoiGioiThieuName);
    setContractNo(data.contractNo);
    setSendResultTypes(data.sendResultTypes);
    setIsEdited(data.isEdited);
    if (data.sendResultTypes && data.sendResultTypes.length) {
      setSendResultTypeNames(
        listSendResultTypes.filter(x => data.sendResultTypes.indexOf(x.value) > -1).map(x => x.text).join(", ")
      );
    }
    setListFileImport(data.listFileImport);
    setRemark(data.remark);
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    CompanyExamService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
    // forceUpdate();
  }

  const getListSites = () => {
    AuthServices.getAvailableSites().then(result => {
      if (result.isSuccess) {
        setListSites(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Actions.setLoading(false);
      forceUpdate();
    })
  }

  const getListSales = () => {
    let filterModel = {
      skipCount: 0,
      maxResultCount: 0,
      getAll: true,
      role: 26
    }
    NhanVienService.GetListEmployee(filterModel).then(result => {
        if (result.isSuccess) {
            let data = result.data.items;
            setListSales(data);
        }
    }).catch(err => {
    })
  }
  
  const getListNguoiGioiThieus = () => {
    let filterModel = {
        skipCount: 0,
        maxResultCount: 0,
        getAll: true,
    }
    PatientService.GetReferentList(filterModel).then(results => {
        if (results.isSuccess) {
          setListNguoiDuoiThieus(results.data.items);
        } else {
            Actions.openMessageDialog("Lỗi", results.err.msgString);
        }
    }).catch(err => {
        Actions.openMessageDialog("Lỗi", err);
        Actions.setLoading(false);
    })
  }

  const onChangeCoporation = (value) => {
    let record = props.listCoporations.filter(x=>x.ma == value)[0];
    if (record) {
      if (record.banggia) {
        setBanggia(record.banggia);
      }
      else {
        ConfigService.BangGiaDefaultSite(props.siteid).then((respone) => {
            if (respone.isSuccess) {
              if (respone.item != '' && respone.item != 'default' && respone.item != null) {
                setBanggia(respone.item);
                return;
              }
            }
            setBanggia('');
        }).catch(err => {
        });
      }
    }
  }

  const handleFileChange = (e) => {
    for(var i = 0; i < e.fileList.length  - 1; i++) {
      upload.current.fileList.shift();
    }
    setFile(upload.current.fileList[0]);
    if (upload.current.fileList[0]) setErrorFileString('');
  }

  const handleFileRemove = (e) => {
  }

  const validate = () => {
    let isValid = true;
    if (!file && detailScreenMode == detailScreenModeEnum.create) {
      setErrorFileString("Bắt buộc phải chọn file");
      isValid = false;
    }
    return isValid;
  }

  const saveButtonHandle = () => {
    if (validator.current.allValid() && validate()) {
      // setModel();
      if (detailScreenMode == detailScreenModeEnum.update && id) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        saveCreate();
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }

  const saveCreate = () => {
    Actions.setLoading(true);
    let formData = new FormData();
    if (file.blob) {
        formData.append('file', file.blob);
    } else {
        formData.append('file', file.originFileObj);
    }
    // formData.append('file', file.blob);
    formData.append('coporationId', coporationId);
    formData.append('siteId', siteId);
    if (saleId) formData.append('saleId', saleId);
    if (nguoiGioiThieuId) formData.append('nguoiGioiThieuId', nguoiGioiThieuId);
    if (contractNo) formData.append('contractNo', contractNo);
    if (remark) formData.append('remark', remark);
    if (banggia) formData.append('banggia', banggia);
    sendResultTypes.forEach(x => formData.append('sendResultTypes', x));
    // formData.append('dto', model);
    CompanyExamService.create(formData).then(result => {
      let listMes = [];
      if (result.isSuccess) {
        setCloseReload(true);
        setCurrentStep(1);
        notification.success({ message: "Thành công" });
        notiRef && notiRef.current.Close();
        setId(result.data.result.id);
        setData(result.data.result);
        setDetailScreenMode(detailScreenModeEnum.update);
        try {
          if (typeof(result.data.messages)=='object' && result.data.messages.length)
            listMes = result.data.messages;
        } catch (e) {
          listMes = [result.data.messages];
        }
        setImportStatus('success');
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
        try {
          if (typeof(result.error.extensionData)=='object' && result.error.extensionData.length)
            listMes = result.error.extensionData;
        } catch (e) {
          listMes = [result.error.messageText];
        }
        setImportStatus('error');
      }
      setListMessageResult(listMes);
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  const saveUpdate = () => {
    Actions.setLoading(true);
    let formData = new FormData();
    if (file) {
      if (file.blob) {
          formData.append('file', file.blob);
      } else {
          formData.append('file', file.originFileObj);
      }
    }
    // formData.append('id', id);
    formData.append('coporationId', coporationId);
    formData.append('siteId', siteId);
    if (saleId) formData.append('saleId', saleId);
    if (nguoiGioiThieuId) formData.append('nguoiGioiThieuId', nguoiGioiThieuId);
    if (contractNo) formData.append('contractNo', contractNo);
    if (remark) formData.append('remark', remark);
    if (banggia) formData.append('banggia', banggia);
    sendResultTypes.forEach(x => formData.append('sendResultTypes', x));
    CompanyExamService.update(id, formData).then(result => {
      let listMes = [];
      if (result.isSuccess) {
        setCloseReload(true);
        setCurrentStep(1);
        // setData(result.data.result);
        setDetailScreenMode(detailScreenModeEnum.update);
        notification.success({ message: "Thành công" });
        notiRef && notiRef.current.Close();
        try {
          if (typeof(result.data.messages)=='object' && result.data.messages.length)
            listMes = result.data.messages;
        } catch (e) {
          listMes = [result.data.messages];
        }
        setImportStatus('success');
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
        try {
          if (typeof(result.error.extensionData)=='object' && result.error.extensionData.length)
            listMes = result.error.extensionData;
        } catch (e) {
          listMes = [result.error.messageText];
        }
        setImportStatus('error');
      }
      setListMessageResult(listMes);
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Import danh sách</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel(closeReload);
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row">{/* import file */}
                <div className={(detailScreenMode==detailScreenModeEnum.create || isEdited) ? "d-none" : "col-md-12 d-flex flex-column"}>
                  <label>Đính kèm</label>
                  {(listFileImport ?? []).map((record, index) => {
                    return (
                      <a key={index} href="#" 
                        onClick={() => {}}
                      >{record.fileName}</a>
                    )
                  })}
                </div>
                <div className={(detailScreenMode==detailScreenModeEnum.update && !isEdited) ? "d-none" : "col-md-12 d-flex flex-column"}>
                  <div style={{fontSize: 14, color: 'rgb(255, 77, 79)'}}>
                  </div>
                  <Dragger
                    ref={upload}
                    customRequest={dummyRequest}
                    // listType="picture-card"
                    // multiple={true}
                    // fileList={files}
                    // onPreview={handlePreview.bind(this)}
                    onChange={handleFileChange.bind(this)}
                    // onChange={(e) => { handleChange(e); }}
                    onRemove={handleFileRemove.bind(this)}
                    accept=".xls, .xlsx, .csv|excel/*"
                    itemRender={(originNode, file, fileList) => {
                      return <div className="handle-move-upload">
                        {originNode}
                      </div>
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <i className="fas fa-cloud-upload-alt text-color" style={{ fontSize:"2.5rem" }}></i>
                    </p>
                    <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây</p>
                  </Dragger>
                </div>
                <label className='text-danger'>{errorFileString}</label>
              </div>
              <div className="row mt-2">{/* Chi nhánh | Số hợp đồng */}
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label>Chi nhánh tiếp nhận</label><label className='required-field'>*</label>
                  </div>
                  <label hidden={detailScreenMode==detailScreenModeEnum.create}>{siteName}</label>
                  <Select hidden={detailScreenMode==detailScreenModeEnum.update}
                    allowClear
                    className="w-100"
                    placeholder="Chọn chi nhánh"
                    maxTagCount= 'responsive'
                    value={ siteId }
                    onChange={(value) => {setSiteId(value);}}
                  >
                  {(listSites ?? []).map((record, index) => {
                    return (
                      <Option key={record.id} value={record.id}>{record.ten}</Option>
                    )
                  })}
                  </Select>
                  {validator.current.message("Chi nhánh", siteId, "required")}
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label className='required-field'>Số hợp đồng</label>
                  </div>
                  <Input
                    value={contractNo}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    // disabled={isDisableFewField}
                    onChange={(e) => setContractNo(e.target.value)}
                  ></Input>
                  {validator.current.message("Số hợp đồng", contractNo, "required")}
                </div>
              </div>
              <div className="row mt-2">{/* Nguồn khách hàng | Nhân viên sales */}
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label className='required-field'>Nguồn khách hàng</label>
                  </div>
                  <label hidden={detailScreenMode==detailScreenModeEnum.create}>{coporationName}</label>
                  <Select hidden={detailScreenMode==detailScreenModeEnum.update}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      let ma = (option?.value ?? '').toLowerCase();
                      let ten = (option?.name ?? '').toLowerCase();
                      return (ma.includes(input.toLowerCase()) || ten.includes(input.toLowerCase()));
                    }}
                    className="w-100"
                    placeholder="Chọn nguồn khách hàng"
                    maxTagCount= 'responsive'
                    value={ coporationId }
                    onChange={(value) => {setCoporationId(value); onChangeCoporation(value);}}
                  >
                    <Option disabled key='row0' className="" >
                      <div className="d-flex justify-content-between">
                        <div><b>Tên nguồn khách hàng</b></div>
                        <div><b>Mã</b></div>
                      </div>
                    </Option>
                    {(listCoporations ?? []).map((record, index) => {
                      return (
                        <Option key={record.ma} value={record.ma} name={record.ten}>
                          <div className="d-flex justify-content-between">
                            <div>{record.ten}</div>
                            <div>{record.ma}</div>
                          </div>
                        </Option>
                      )
                    })}
                  </Select>
                  {validator.current.message("Nguồn khách hàng", coporationId, "required")}
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label>Nhân viên sales</label>
                  <label hidden={detailScreenMode==detailScreenModeEnum.create || isEdited}>{saleName}</label>
                  <Select hidden={detailScreenMode==detailScreenModeEnum.update && !isEdited}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    className="w-100"
                    placeholder="Chọn nhân viên sales"
                    maxTagCount= 'responsive'
                    value={ saleId }
                    onChange={(value) => {setSaleId(value);}}
                  >
                    {(listSales ?? []).map((record, index) => {
                      return (
                        <Option key={record.id} value={record.id}>{record.hoTen}</Option>
                      )
                    })}
                  </Select>
                </div>
              </div>
              <div className="row mt-2">{/* Người giới thiệu | Hình thức trả kết quả */}
                <div className="col-md-6 d-flex flex-column">
                  <label>Người giới thiệu</label>
                  <label hidden={detailScreenMode==detailScreenModeEnum.create || isEdited}>{nguoiGioiThieuName}</label>
                  <Select hidden={detailScreenMode==detailScreenModeEnum.update && !isEdited}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    className="w-100"
                    placeholder="Chọn người giới thiệu"
                    maxTagCount= 'responsive'
                    value={ nguoiGioiThieuId }
                    onChange={(value) => {setNguoiGioiThieuId(value);}}
                  >
                    {(listNguoiDuoiThieus ?? []).map((record, index) => {
                      return (
                        <Option key={record.id} value={record.id}>{record.hoTen}</Option>
                      )
                    })}
                  </Select>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label>Hình thức trả kết quả</label>
                  <label hidden={detailScreenMode==detailScreenModeEnum.create || isEdited}>{sendResultTypeNames}</label>
                  <Select  hidden={detailScreenMode==detailScreenModeEnum.update && !isEdited}
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    className="w-100"
                    placeholder="Chọn người giới thiệu"
                    maxTagCount= 'responsive'
                    value={ sendResultTypes }
                    onChange={(value) => {setSendResultTypes(value);}}
                  >
                    {(listSendResultTypes ?? []).map((record, index) => {
                      return (
                        <Option key={record.value} value={record.value}>{record.text}</Option>
                      )
                    })}
                  </Select>
                </div>
              </div>
              <div className="row mt-2">{/* Ghi chú */}
                <div className="col-md-12 d-flex flex-column">
                  <label>Ghi chú</label>
                  <TextArea 
                    rows={4}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  ></TextArea >
                </div>
              </div>
              <div className="row mt-2">{/* button import*/}
                <div className="col-md-12 d-flex justify-content-end">
                  <button className='btn btn-color mr-2' 
                    onClick={saveButtonHandle}
                  >
                    <i className='fas fa-cloud-upload-alt mr-2'></i>
                    Import
                  </button>
                </div>
              </div>
              <div className="row mt-2">{/* Tiến trình */}
                <div className="col-md-12 d-flex flex-column">
                  <Steps current={currentStep} progressDot labelPlacement="vertical">
                    <Step key={0} title="Xác minh" description="" />
                    <Step key={1} title="Đăng ký" description="" />
                  </Steps>
                </div>
              </div>
              <div className="row mt-2">{/* Kết quả */}
                <div className={`col-md-12 ${(importStatus != 'error' ? "d-none" : "d-flex")} flex-column`}>{/* Lỗi */}
                  <div className="d-flex justify-content-center">
                    <i className="fas fa-times-circle text-red" style={{ fontSize:"2.5rem" }}></i>
                  </div>
                  <div className="d-flex justify-content-between border-bottom border-2 border-dark">
                    <label className="text-red"><b>Thông tin lỗi</b></label>
                    {/* <label key="1">{"11 lỗi"}</label> */}
                  </div>
                </div>
                <div className={`col-md-12 ${(importStatus != 'success' ? "d-none" : "d-flex")} flex-column`}>{/* Thành công */}
                  <div className="d-flex justify-content-center">
                    <i className="fas fa-check-circle text-info" style={{ fontSize:"2.5rem" }}></i>
                  </div>
                  <div className="d-flex justify-content-between border-bottom border-2 border-dark">
                    <label className="text-info"><b>Thông tin đăng ký thành công</b></label>
                  </div>
                </div>
              </div>
              <div className="list-page-table mt-2" hidden={importStatus != 'success' && importStatus != 'error'}>{/* list */}
                <Table style={{ height: "calc(100% - 1px)", width: "100%" }}
                  className="table-min-h-0"
                  dataSource={listMessageResult} 
                  columns={[
                    {
                        title: '#',
                        dataIndex: 'stt',
                        key: 'stt',
                        align: 'center',
                        width: 30,
                        render: (text, record, index) => (index + 1)
                    },
                    {
                        title: 'Nội dung',
                        dataIndex: 'mes',
                        key: 'mes',
                        align: 'left',
                        render: (text, record, index) => {
                            return <span style={{whiteSpace: "pre-line"}}>{ record }</span>;
                        }
                    },
                  ]}
                  locale={{ emptyText: 'Không có dữ liệu' }} 
                  scroll={{ x: 0, y: 0 }}
                  rowKey={(e) => e}
                  rowClassName={(record, rowIndex) => {
                      return "align-top"
                  }}
                  pagination={false}
                />
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div >
            </div>
            <div className='d-flex justify-content-end' >
                <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel(closeReload)}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
      siteCode: state.global.siteCode,
      siteName: state.global.siteName
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DownLoadTemplate);