import { DatePicker, notification, Select, Statistic } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import * as Actions from '../../../libs/actions';
import * as StatisticService from '../../../services/statisticService';
import { colors } from '../../../commons/constanst';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../services/configService';
import * as UsersService from '../../../services/usersService';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default class chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            ngay: [moment().subtract(7, "days"), moment()],
            type: "1",
            totalExam: 0,
            totalMoney: 0,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
        };
    }
    componentDidMount() {
        // UsersService.CheckPremissionMultisite().then(result => {
        //     if (result.isSuccess) {
        //         this.setState({
        //             isMultisite: result.item
        //         });
        //     }
        // });
        // this.GetListSite();
        this.loadData();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    setData(data) {
        this.setState({ data })
    }
    toggleDataSeries(e) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        }
        else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }

    checktypePicker() {
        switch (this.state.type) {
            case "1":
                return '';
            case "2":
                return 'month';
            case "3":
                return 'year';
            case "4":
                return 'year';
            default:
                return '';
        }
    }

    getFormat() {
        switch (this.state.type) {
            case "1":
                return 'DD-MM-YYYY';
            case "2":
                return 'MM-YYYY';
            case "3":
                return 'YYYY';
            case "4":
                return 'YYYY';
            default:
                return '';
        }
    }

    loadData() {
        let fromDate = null;
        let toDate = null;
        if (Array.isArray(this.state.ngay)) {
            fromDate = this.state.ngay[0].format('YYYY-MM-DD');
            toDate = this.state.ngay[1].format('YYYY-MM-DD');
        } else {
            fromDate = this.state.ngay.format('YYYY-MM-DD');
        }
        StatisticService.LisStatisticChart(this.state.type,
            fromDate, toDate, this.state.chinhanh, 1).then(result => {
                if (result.isSuccess) {
                    var finalData = {};
                    var labels = result.item.labels;
                    if (this.state.type === "3") {
                        for (let i = 0; i < labels.length; i++) {
                            labels[i] = "Q" + labels[i]
                        }
                    }
                    var datasets = [];
                    let index = 0;
                    for (let item of result.item.records) {
                        let temp = {};
                        temp.data = item.values;
                        temp.label = item.name
                        temp.backgroundColor = colors[index]
                        temp.borderColor = colors[index]
                        temp.borderWidth = 1
                        temp.hoverBackgroundColor = colors[index]
                        temp.hoverBorderColor = colors[index]
                        index++;
                        datasets.push(temp);
                    }
                    finalData.labels = labels
                    finalData.datasets = datasets
                    this.setState({ data: finalData, totalMoney: result.item.totalMoney, totalExam: result.item.totalCount });

                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
    }
    render() {
        return (
            <div style={{ height: '96%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: 5 }}>
                    <div className="row no-gutters" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        {/* {this.state.isMultisite === true &&
                            <div className="col-auto">
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    allowClear={false}
                                    value={this.state.chinhanh}
                                    onChange={(e) => {
                                        this.setState({ chinhanh: e })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {this.state.listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        } */}
                        <div className="col-auto">
                            <Select defaultValue="1" style={{ width: 120, marginRight: 30, marginLeft: 5 }} onChange={(value) => {
                                if (value !== "3") {

                                    this.setState({ type: value, ngay: [moment().subtract(7, "days"), moment()] })
                                } else {
                                    this.setState({ type: value, ngay: moment() })
                                }
                            }}>
                                <Option value="1">Ngày</Option>
                                <Option value="2">Tháng</Option>
                                <Option value="3">Quý</Option>
                                <Option value="4">Năm</Option>
                            </Select>
                        </div>
                        <div className="col-auto">
                            {this.state.type === "3" ?
                                <DatePicker locale={viVN} onChange={(value) => this.setState({ ngay: value }, () => this.loadData())} style={{ marginLeft: 5 }}
                                    format={this.getFormat()} value={this.state.ngay} allowClear={false}
                                    placeholder={[this.getFormat()]}
                                    picker={
                                        this.checktypePicker()
                                    } />
                                :
                                this.state.type !== "1" ?
                                    <RangePicker locale={viVN} onChange={(value) => this.setState({ ngay: value }, () => this.loadData())} style={{ marginLeft: 5 }}
                                        format={this.getFormat()} value={this.state.ngay} allowClear={false}
                                        placeholder={[this.getFormat(), this.getFormat()]}
                                        picker={
                                            this.checktypePicker()
                                        } />
                                    :
                                    <RangePicker locale={viVN} onChange={(value) => this.setState({ ngay: value }, () => this.loadData())} style={{ marginLeft: 5 }}
                                        format={this.getFormat()} value={this.state.ngay} allowClear={false}
                                        placeholder={[this.getFormat(), this.getFormat()]}
                                    />
                            }
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => { this.loadData() }}><i style={{ marginLeft: 10 }} className="fa fa-search" />Tìm</button>
                        </div>
                    </div>
                    <Statistic title="Tổng Tiền" value={this.state.totalMoney} />
                </div>
                <div style={{ flex: 1 }}>
                    <Bar style={{ maxWidth: '100%' }} data={this.state.data}
                        options={{
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {

                                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                        value = value.toString();
                                        value = value.split(/(?=(?:...)*$)/);

                                        // Convert the array to a string and format the output
                                        value = value.join(',');
                                        return value

                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    barThickness: 30,
                                    stacked: true,
                                    ticks:
                                    {
                                        fontColor: "rgb(0,0,0)",
                                    },
                                    gridLines: {
                                        lineWidth: 0,
                                        zeroLineWidth: 1
                                    },
                                }
                                ],
                                yAxes: [{
                                    stacked: true,
                                    id: 'A',
                                    position: 'left',
                                    ticks: {
                                        fontColor: "#fe4a49",
                                        beginAtZero: true,
                                        min: 0,
                                        userCallback: function (value, index, values) {
                                            // Convert the number to a string and splite the string every 3 charaters from the end
                                            value = value.toString();
                                            value = value.split(/(?=(?:...)*$)/);

                                            // Convert the array to a string and format the output
                                            value = value.join(',');
                                            return value;
                                        }
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Tổng tiền'
                                    },

                                },
                                ]
                            },
                            maintainAspectRatio: false,
                            responsive: true
                        }} />
                </div>
            </div>
        );
    }
}