import { DatePicker, Popover } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import * as Statistic from '../../../services/statisticService';
import * as Actions from '../../../libs/actions';
import { useEffect } from 'react';
import { NotifyStatus } from '../../../components/notify'

const { RangePicker } = DatePicker;


//type 1: phải thu, 2 đã thu
export default forwardRef(({ type, ClickDetail, ShowNotify, site }, ref) => {
    const _hiddenLink = useRef()
    const [date, setDate] = useState([null, null])
    const [openDate, setOpenDate] = useState(false)
    const [detail, setDetail] = useState({});

    useImperativeHandle(ref, () => ({
        GetData: () => {
            GetData();
        },
    }));

    useEffect(() => {
        GetData()
    }, [date])

    const GetData = () => {
        let data = {}
            if (date[0])
                data.dateFrom = date[0].format("yyyy/MM/DD");
            if (date[1])
                data.dateTo = date[1].format("yyyy/MM/DD");
            if (site)
                data.site = site;
            Actions.setLoading(true);

        if (type == 1) {
            Statistic.CongNoPhaiThuSummary(data).then(res => {
                if (res.isSuccess) {
                    setDetail(res.item);
                    Actions.setLoading(false);
                }
                else {
                    if (ShowNotify) ShowNotify(res.err.msgString, NotifyStatus.Error)
                }
            }).catch(() => {
                Actions.setLoading(false);
            })
        }
        else {
            Statistic.CongNoDaThuSummary(data).then(res => {
                if (res.isSuccess) {
                    setDetail(res.item);
                    Actions.setLoading(false);
                }
            }).catch(() => {
                Actions.setLoading(false);
            })
        }
    }

    const ExportData = () => {
        let data = {}
            if (date[0]){
                data.dateFrom = date[0].format("yyyy/MM/DD");
            } else {
                data.dateFrom = "0001/01/01 00:00"
            }
            if (date[1]){
                data.dateTo = date[1].format("yyyy/MM/DD");
            } else {
                data.dateTo = "0001/01/01 23:59"
            }
            if (site)
                data.site = site;
        if (type == 1) {
            Actions.setLoading(true);
            Statistic.ExportCongNoPhaiThu(data.dateFrom, data.dateTo,'','',data.site).then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ExportCongNoPhaiThu";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
        }
        else {
            Actions.setLoading(true);
            Statistic.ExportCongNoDaThu(data.dateFrom, data.dateTo,'','',data.site).then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ExportCongNoDaThu";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
        }
    }


    return (
        <React.Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
            <div style={{ display: 'flex' }} className='align-self-center'>
                <h5 style={{ flex: 1, fontWeight: '500' }}>Công nợ {type == 1 ? 'phải thu' : 'đã thu'}</h5>
                <Popover
                    content={<div style={{ width: 300, padding: 10 }}>
                        <RangePicker allowClear={true} format="DD-MM-YYYY" value={date} locale={viVN}
                            onChange={(value) => {
                                if (value == null)
                                    setDate([null, null])
                                else
                                    setDate(value)
                            }}
                        />
                    </div>}
                    trigger="click"
                    placement="bottom"
                    visible={openDate}
                    onVisibleChange={(e) => {
                        setOpenDate(e)
                    }}
                >
                    <div
                        onClick={() => {
                            setOpenDate(true)
                        }}
                        style={{ paddingLeft: 10, paddingRight: 10, cursor: 'pointer' }}>
                        <i className='fa fa-calendar-alt'></i>
                    </div>
                </Popover>
                <div class="dropdown dropdown-inline-table">
                    <div class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        style={{ paddingLeft: 10, paddingRight: 10, cursor: 'pointer', border: '1px solid gray', borderRadius: 3 }}>
                        <i className='fa fa-ellipsis-v'></i>
                    </div>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="#"
                            onClick={() => {
                                ExportData();
                            }}
                        ><i className='fas fa-file-excel mr-1'></i> Xuất Excel</a>
                        <a class="dropdown-item" href="#"
                            onClick={() => {
                                if (ClickDetail){
                                    let data = {}
                                    if (date){
                                        data.date = date;
                                    } else {
                                        data.date = null;
                                    }
                                    data.type = type;
                                    ClickDetail(data)
                                } 
                            }}
                        ><i className='fa fa-info-circle mr-1'></i> Xem chi tiết</a>
                    </div>
                </div>
            </div>
            { date[0] !== null &&
                <div className='py-1' style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>ngày {type == 1 ? 'lập phiếu' : 'thu'}:</div>
                    <div
                        onClick={() => setOpenDate(true)}
                    >{date[0] == null ? '' : date[0].format("DD/MM/YYYY")} ~ {date[1] == null ? '' : date[1].format("DD/MM/YYYY")}</div>
                </div>
            }
            
            <div className='py-1 font-weight-bold' style={{ position: 'relative', display: 'flex' }}>
                <div className={`btn btn-${type == 1 ? 'danger' : 'primary'} d-flex`} style={{ width: 300, fontSize: 30, paddingTop: 10, paddingBottom: 10 }}>
                    <div><u>đ</u></div>
                    <div style={{ flex: 1, textAlign: 'right' }}>{isNaN(detail.sotien) ? '' : Number(detail.sotien).toLocaleString('en-US')}</div>
                </div>
            </div>
            <div className='py-1' style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>Tổng số phiếu</div>
                <div>{isNaN(detail.sohd) ? '' : Number(detail.sohd).toLocaleString('en-US')}</div>
            </div>
            <div className='py-1' style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>Tổng số bệnh nhân</div>
                <div>{isNaN(detail.sobn) ? '' : Number(detail.sobn).toLocaleString('en-US')}</div>
            </div>
        </React.Fragment>
    )
})
