import { Collapse, Table, Tabs } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import Result from '../../screens/lis/component/result';
import ThuThuat from '../../screens/exam/examination/thuthuatResult';
import * as PatientService from '../../services/patientService';
import * as ChiDinhService from '../../services/chiDinhService';
import CDHA from './cdha';
import * as SystemConfig from '../../configure/systemConfig';
import VitalSign from '../../screens/patient/component/vitalSign';
import { isNullOrEmpty } from '../../libs/util';

const { Panel } = Collapse;
const { TabPane } = Tabs
export default class HistoryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historyInfo: null,
            ngayhenkham: null,
            chuandoan: '',
            ketluan: '',
            danhsachhen: [],
            lstBenhkemtheo: [],
            thongTinKhamBenh: []
        }
    };

    componentWillUnmount() {
    }

    componentDidMount() {
        PatientService.getHistoryDetails(this.props.selectedTran.maql, moment(this.props.selectedTran.time).format('YYYY-MM-DD'),
            this.props.mabn)
            .then((result) => {
                if (result.isSuccess === true) {
                    this.setState({ historyInfo: result.item })
                }
            })
        this.ThongTinKetLuan();
    }

    ThongTinKetLuan() {
        ChiDinhService.ThongTinKetLuanHenKham(this.props.mabn, this.props.selectedTran.maql).then(result => {
            if (result.isSuccess) {
                if (moment(result.item.ngaygiohen).year() !== 1) {
                    this.setState({
                        ngayhenkham: moment(result.item.ngaygiohen),
                    })
                }
                this.setState({
                    chuandoan: result.item.chuandoan,
                    ketluan: result.item.ketluan,
                    danhsachhen: result.item.danhsachhen,
                    lstBenhkemtheo: result.item.lstBenhkemtheo,
                    thongTinKhamBenh: result.item.thongTinKhamBenh
                })
            }
        })
    }

    getTitle(info) {
        let title = "";
        if (!isNullOrEmpty(info.tenPhongKham))
            title += info.tenPhongKham + " - ";
        if (!isNullOrEmpty(info.tenDichVu))
            title += info.tenDichVu + " - ";
        if (!isNullOrEmpty(info.ngayKham))
            title += moment(info.ngayKham).format(SystemConfig.FORMAT_TIME_DATE);
        return title;
    }

    render() {
        let { historyInfo } = this.state;
        const columns = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'name',
                key: 'ten',
            },
            {
                title: 'Mã thuốc',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
            },
        ]
        return (
            <React.Fragment>
                {historyInfo &&
                    <Tabs>
                        <TabPane tab={"Khám bệnh"} key="1">
                            <div className="row mt-2">
                                <VitalSign maql={this.props.selectedTran.maql} col={12} />
                            </div>
                            <div className='mt-2' style={{ display: "flex" }}>
                                <label className='mita-title'>Chẩn đoán:</label>
                                <ul>
                                    {
                                        this.state.lstBenhkemtheo && this.state.lstBenhkemtheo.map((cd, index) => {
                                            return <li key={cd.value}>{cd.label}</li>
                                        })
                                    }
                                </ul>
                            </div>
                            <div className='mt-2' style={{ display: "flex", flexDirection: "column" }}>
                                <label className='mita-title'>Kết quả:</label>
                                <ol>
                                    {
                                        this.state.thongTinKhamBenh && this.state.thongTinKhamBenh.map((kq, index) => {
                                            return (
                                                <li>
                                                    <div className='w-100 px-2'>
                                                        <div className='mita-title border-bottom'>{this.getTitle(kq)}</div>
                                                        <div className='px-2'>
                                                            <div><label className='mita-title mr-1'>Lý do:</label>{kq.lyDo}</div>
                                                            <div><label className='mita-title mr-1'>Mô tả:</label><span dangerouslySetInnerHTML={{ __html: kq.ketQua }} /></div>
                                                            <div><label className='mita-title mr-1'>Kết luận:</label><span dangerouslySetInnerHTML={{ __html: kq.ketLuan }} /></div>
                                                            <div><label className='mita-title mr-1'>Lời dặn:</label>{kq.loiDan}</div>
                                                            <div><label className='mita-title mr-1'>Ghi chú:</label>{kq.ghiChu}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ol>
                            </div>
                            <div className='mt-2' style={{ display: "flex" }}>
                                <label className='mita-title'>Ngày hẹn tái khám:</label>
                                <ul>
                                    {
                                        this.state.ngayhenkham !== null && <li key="default">{moment(this.state.ngayhenkham).format(SystemConfig.FORMAT_DATE_TIME)}</li>}                                        {
                                        this.state.danhsachhen && this.state.danhsachhen.length > 0 &&
                                        this.state.danhsachhen.map(x => {
                                            return (
                                                moment(x.ngaygiohen).year() !== 1 && <li key={x.ten}>{moment(x.ngaygiohen).format(SystemConfig.FORMAT_DATE_TIME)} ({x.ten})</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </TabPane>

                        {historyInfo && historyInfo.medicineHistory && historyInfo.medicineHistory.isHasHistory === true &&
                            <TabPane tab={"Đơn thuốc"} key="2">
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div className="warrperStyleGroup" style={{ width: '49%', height: '100%', marginRight: 20 }}>
                                        <div className="grouplabel">
                                            <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Đơn Thuốc Chỉ Định</h4>
                                        </div>
                                        <Table
                                            scroll={{ y: 0 }}
                                            pagination={false}
                                            dataSource={historyInfo.medicineHistory.issuedByDoctor} columns={columns} rowKey={(e, index) => index} />
                                    </div>
                                    <div className="warrperStyleGroup" style={{ width: '49%', height: '100%' }}>
                                        <div className="grouplabel">
                                            <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Đơn Thuốc Đã Bán</h4>
                                        </div>
                                        {historyInfo.medicineHistory.patientBoughtList.map((record, index) => {
                                            return (
                                                <div >
                                                    {historyInfo.medicineHistory.patientBoughtList.length > 1 &&
                                                        <div>ID: {record.idMedicine}</div>
                                                    }
                                                    <Table pagination={false}
                                                        scroll={{ y: 0 }}
                                                        dataSource={record.patientBought} columns={columns} rowKey={(e, index) => index} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                        }
                        {historyInfo.utraSoundResult && historyInfo.utraSoundResult.isHasHistory === true &&
                            <TabPane tab={"Chẩn đoán hình ảnh"} key="3">
                                <div style={{ display: 'flex' }}>
                                    <Collapse style={{ width: '100%' }} defaultActiveKey={['0']}>
                                        {historyInfo.utraSoundResult.result.map((record, index) => {
                                            return (
                                                <Panel className="site-collapse-custom-collapse" header={record.tenloai} key={index}>
                                                    <CDHA key={record.idchidinh} item={record}></CDHA>
                                                </Panel>
                                            )
                                        })}
                                    </Collapse>

                                </div>
                            </TabPane>
                        }


                        {this.props.selectedTran.maql && this.props.selectedTran.time && historyInfo && historyInfo.lisResult && historyInfo.lisResult.listTestResult.length > 0 &&
                            <TabPane tab={"Xét nghiệm"} key="4">
                                <Result
                                    history={true}
                                    maql={this.props.selectedTran.maql}
                                    ngay={this.props.selectedTran.time}
                                    resultInfo={{
                                        patientID: this.props.mabn,
                                        maQL: this.props.selectedTran.maql,
                                        // sid: ,
                                        // siteid: ,
                                        // coporator: ,
                                    }} />
                            </TabPane>
                        }


                        {this.props.selectedTran.maql && this.props.selectedTran.time && historyInfo && historyInfo.isHasSurgeryHistory &&
                            <TabPane tab={"Thủ thuật"} key="5">
                                <ThuThuat
                                    disabledSave={false}
                                    managerId={this.props.selectedTran.maql}
                                    dateIn={moment(this.props.selectedTran.time).format("YYYY/MM/DD HH:mm")}
                                    code={this.props.mabn}
                                    history={true}
                                />
                            </TabPane>
                        }


                    </Tabs>

                }
            </React.Fragment>
        )
    }
}
