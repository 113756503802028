import * as ApiCaller from '../../../libs/httpRequests';
const prefix = 'Invoice';

export function GetDanhSachVP(searchBydate, key, banggia, nhom, loai, page = 1, size = 10) {
    if (searchBydate === undefined) searchBydate = false;
    if (nhom === null) nhom = 0;
    if (loai === null) loai = 0;
    banggia = encodeURIComponent(banggia);
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachvpbyloai?searchBydate=${searchBydate}&key=${key}&banggia=${banggia}&nhom=${nhom}&id_loai=${loai}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetNhomVP(getAll = false) {
    return new Promise((resolve, reject) => {
        let moreParam = getAll ? `?all=${getAll}` : "";
        return ApiCaller.httpGet(`${prefix}/danhsachnhomvp${moreParam}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetNhomVPAll() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachnhomvpall`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetLoaiVPByNhom(nhom) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachloaivp?id_nhom=${nhom}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhatGiaTienVP(data, tenbanggia) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/updategiavp?banggia=${tenbanggia}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function TaoMoiVP(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/taovp`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function UpdateHideVP(ma, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateHideVP?ma=${ma}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhapTenLoaiXN(id, ten) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/CapNhapTenLoaiXN?id=${id}&ten=${ten}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhapLoaiXNVP(idvp, idloai) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/CapNhapLoaiXNVP?idvp=${idvp}&idloai=${idloai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetDanhSachNhomVP(key, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachnhomvienphi?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function UpdateHideNhomVP(ma, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateHideNhomVP?ma=${ma}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function createNhomVP(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/taonhomvp`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateNhomVP(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/capnhatnhomvp`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteNhomVP(manvp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/xoanhomvp?manvp=${manvp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetDanhSachLoaiVP(id_nhom, key, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachloaivienphi?id_nhom=${id_nhom}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function UpdateHideLoaiVP(id, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/updatehideloaivp?id=${id}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function createLoaiVPByNhom(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/taoloaivp`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateLoaiVP(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/capnhatloaivp`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteLoaiVP(id_loai) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/xoaloaivp?id_loai=${id_loai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListBangGia() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachbanggiavp`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getChiTietBangGia(name) {
    return new Promise((resolve, reject) => {
        name = encodeURIComponent(name);
        return ApiCaller.httpGet(`${prefix}/chitietbanggiavp?name=${name}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function delChiTietBangGia(banggia, data) {
    return new Promise((resolve, reject) => {
        banggia = encodeURIComponent(banggia);
        return ApiCaller.httpPost(`${prefix}/xoavienphibanggia?name=${banggia}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function addAllFee(data, name) {
    return new Promise((resolve, reject) => {
        name = encodeURIComponent(name);
        return ApiCaller.httpPost(`${prefix}/luubanggiavienphi?name=${name}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function delBangGiaVP(name) {
    return new Promise((resolve, reject) => {
        name = encodeURIComponent(name);
        return ApiCaller.httpDelete(`${prefix}/xoabanggia?name=${name}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteVienPhi(id, banggia) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/xoagiavienphi?id=${id}&banggia=${banggia}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function importDanhSach(data, banggia) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/importbanggiavienphi?banggia=${banggia}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function TemplateImportBangGia() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/TemplateImportBangGia`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateTimeReturnResult(id, value, loai) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/updatetimereturnresult?id=${id}&value=${value}&loai=${loai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function MauImportGiaVienPhi(configTime = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/MauImportGiaVienPhi/${configTime}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function importBangGiaMacDinh(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/ImportBangGiaMacDinh`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function InsertSubTest(id, data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/InsertSubTest/${id}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function UpdateSubTest(id, data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateSubTest/${id}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DeleteSubTest(id, ma) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteSubTest?id=${id}&ma=${ma}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListSubTest(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListSubTest/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetTestConfig(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetTestConfig/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function UpdateTestConfig(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateTestConfig`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function importTGTKQ(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/ImportTGTKQ`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function MauImportSubTest() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/MauImportSubTest`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ImportSubTest(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/ImportSubTest`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function lstLoaiThanhToan(key, page, size){
    if (page === null || page === undefined) page = 1;
    if (size === null || size === undefined) size = 10;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/lstLoaiThanhToan?page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function CreateLoaiTT(data){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateLoaiTT`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function UpdateLoaiTT(data){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateLoaiTT`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function UpdateHideLoaiTT(ma, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateHideLoaiTT?ma=${ma}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DeleteLoaiTT(ma){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteLoaiTT?ma=${ma}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function GetDanhSachLoaiThanhToan(getAll = false){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LoaiThanhToan/${getAll && "all"}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
