import { Button, Input, notification, Upload, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import * as ConfigService from '../../../../services/configService';
import * as InvoiceService from '../../../../services/invoiceService';
import * as KhoService from '../../../../services/KhoService';
import * as Actions from '../../../../libs/actions';
import { InboxOutlined } from '@ant-design/icons';
import { FEATURE } from '../../../../configureHIS/common/constants';
import { FeatureDeactive } from '../../../../configure/featureConfig';

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export default (props) => {

    const [edit, setEdit] = useState(false);
    const [detail, setDetail] = useState({});
    const [ten, setTen] = useState('');
    const [dienthoai, setDienthoai] = useState('');
    const [diachi, setDiachi] = useState('');
    const [logo, setLogo] = useState('');
    const [codebn, setCodebn] = useState('');
    const [fileList, setfileList] = useState([]);
    const [ma, setMa] = useState('');
    const [banggia, setBanggia] = useState('');
    const [dataBG, setDataBG] = useState([]);
    const [listKho, setListKho] = useState([]);
    const [kho, setKho] = useState('');
    const [dms, setDms] = useState('');
    const [banking, setBanking] = useState('');
    const [tax, setTax] = useState('');
    const config = {
        name: 'file',
        multiple: false,
        accept: 'image/*',
        onChange(info) {
            getBase64(info.fileList[0].originFileObj).then(result => {
                setLogo(result);
            }).catch(err => {
                setLogo('');
                console.log(err);
            })


        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    useEffect(() => {
        GetListBangGia();
        DanhSachKho();
    }, [])
    useEffect(() => {
        setEdit(props.edit)
    }, [props.edit])
    useEffect(() => {
        setDetail(props.detail)
    }, [props.detail])
    useEffect(() => {
        if (detail.id) {
            setDiachi(detail.diachi);
            setTen(detail.ten);
            setDienthoai(detail.dienthoai);
            setLogo(detail.logo);
            setMa(detail.ma);
            setCodebn(detail.codebn);
            setBanggia(detail.banggia);
            setDms(detail.hostDMS);
            setKho(detail.khothuoc);
            setBanking(detail.thongtinck);
            setTax(detail.tax);
        }
    }, [detail])
    const GetListBangGia = () => {
        InvoiceService.GetListBangGia().then(result => {
            if (result.isSuccess) {
                setDataBG(result.data);
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        })
    }
    const DanhSachKho = () => {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess) {
                setListKho(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
        })
    }
    const Submit = () => {
        let data = {
            diachi, ten, dienthoai, logo, ma, codebn, banggia, hostDMS: dms, khothuoc: kho ? kho.toString() : '',
            thongtinck: banking, tax,
        }
        if (ten.trim() === '' || ma.trim() === '') {
            notification.warning({ message: "Vui lòng nhập tên và mã!" });
            return;
        }
        if (!edit) {
            Actions.setLoading(true);
            ConfigService.CreateSite(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Tạo mới site thành công" })
                    if (props.onSuccess)
                        props.onSuccess()
                } else {
                    if (result.err && result.err.msgString) Actions.openMessageDialog('Lỗi', result.err.msgString)
                    else if (result.error && result.error.messageText) Actions.openMessageDialog('Lỗi', result.error.messageText)
                    else Actions.openMessageDialog('Lỗi', "Đã xảy ra lỗi, vui lòng liên hệ nhà cung cấp phần mềm để được xử lý");
                }
            }).catch(err => {
                Actions.openMessageDialog('Lỗi', err)
            }).finally(() => {
                Actions.setLoading(false);
            })
        }
        else {
            data.id = detail.id;
            Actions.setLoading(true);
            ConfigService.UpdateSite(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Cập nhật thành công" })
                    if (props.onSuccess)
                        props.onSuccess()
                } else {
                    if (result.err && result.err.msgString) Actions.openMessageDialog('Lỗi', result.err.msgString)
                    else if (result.error && result.error.messageText) Actions.openMessageDialog('Lỗi', result.error.messageText)
                    else Actions.openMessageDialog('Lỗi', "Đã xảy ra lỗi, vui lòng liên hệ nhà cung cấp phần mềm để được xử lý");
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err)
            })
        }
    }
    return (
        <React.Fragment>
            <div className="h4 text-center text-uppercase">{edit ? detail.ten : 'Tạo mới site'}</div>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label>Mã</label>
                    <Input value={ma} className="w-100"
                        placeholder="Nhập mã"
                        onChange={(e) => {
                            setMa(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Tên</label>
                    <Input value={ten} className="w-100"
                        placeholder="Nhập tên"
                        onChange={(e) => {
                            setTen(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Điện thoại</label>
                    <Input value={dienthoai} className="w-100"
                        placeholder="Nhập điện thoại"
                        onChange={(e) => {
                            setDienthoai(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Code BN</label>
                    <Input value={codebn} className="w-100" maxLength={3}
                        placeholder="Nhập mã hiển thị trước số mã bệnh nhân"
                        onChange={(e) => {
                            setCodebn(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>HostDMS</label>
                    <Input value={dms} className="w-100"
                        placeholder="Nhập địa chỉ kết nối DMS"
                        onChange={(e) => {
                            setDms(e.target.value);
                        }} />
                </div>
                {FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 &&
                    <div className="col-md-6 mb-2">
                        <label>Bảng giá mặc định</label>
                        <Select
                            value={banggia}
                            showSearch
                            className="w-100"
                            onChange={(e) => { setBanggia(e) }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear={true}
                        >
                            {dataBG.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>}
                <div className="col-md-6 mb-2">
                    <div className='mb-2'>
                        <label>Kho bán thuốc</label>
                        <Select
                            showSearch
                            className="w-100"
                            value={kho}
                            allowClear={true}
                            onChange={(e) => { setKho(e) }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {listKho.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value.toString()}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className='mb-2'>
                        <label>Địa chỉ</label>
                        <TextArea rows={4}
                            onChange={e => {
                                setDiachi(e.target.value)
                            }}
                            value={diachi}
                        ></TextArea>
                    </div>
                    <div className='mb-2'>
                        <label>Mã số thuế</label>
                        <TextArea rows={1}
                            onChange={e => {
                                setTax(e.target.value)
                            }}
                            value={tax}
                        ></TextArea>
                    </div>
                    <div>
                        <label>Thông tin tài khoản ngân hàng</label>
                        <TextArea rows={3}
                            onChange={e => {
                                setBanking(e.target.value)
                            }}
                            value={banking}
                        ></TextArea>
                    </div>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Logo</label>
                    <div >
                        {logo !== '' &&
                            <div style={{ width: '100%' }}>
                                <img style={{ width: '100%' }} src={logo} />
                            </div>
                        }
                        <Dragger {...config} beforeUpload={() => false} fileList={fileList} itemRender={null} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây.</p>
                        </Dragger>
                    </div>
                </div>
            </div>
            <div className="d-flex align-self-center">
                <Button className="btn btn-color mr-1"
                    onClick={() => {
                        Submit()
                    }}>
                    Lưu</Button>
                <Button
                    className="ml-1"
                    onClick={() => {
                        if (props.onClose)
                            props.onClose()
                    }}>
                    Đóng</Button>
            </div>
        </React.Fragment>
    )
}
