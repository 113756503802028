import { Input, Form, notification, Select, InputNumber } from 'antd';
import React, { Component } from 'react';
import * as cfgService from '../../../services/configService';
import SimpleReactValidator from 'simple-react-validator';

const initialState = {
    tensuatan: "",
    dongia: 0.0,
    loai: 1
}
const { Option } = Select;
export default class CreateNewSuatAn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            lstSuatAn: []
        };
        this.validator = new SimpleReactValidator({
            messages: {
                numeric: "Cần phải nhập :attribute là số",
                required: "Cần phải nhập :attribute",
                max: "Nhập :attribute tối đa :max",
                min: "Cần nhập :atribute tối thiểu :min"
            },
            element: message => <div style={{ color: "red" }}>{message}</div>
        });
    }
    CreateSuatAn() {
        if (this.validator.allValid()) {
            const data = {
                "ten": this.state.tensuatan,
                "dongia": this.state.dongia,
                "loai": this.state.loai
            }
            cfgService.CreateSuatAn(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                    this.resetState();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.resetState();
            }).catch(() => {
                notification.warning({ message: 'Tạo thất bại!' });
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetState() {
        this.setState({
            tensuatan: "",
            dongia: 0,
            loai: null
        });
        this.validator.hideMessages();
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm suất ăn</h3>
                    </div>
                    <div>
                        <div className="row" style={{ display: 'flex', justifyContent: 'center' }} >
                            <div className="col-md-12" style={{ padding: 10 }}>
                                <Form.Item label="Tên suất ăn:">
                                    <Input
                                        name="tensuatan"
                                        value={this.state.tensuatan}
                                        onChange={(e) => this.setState({ tensuatan: e.target.value })}
                                    />
                                    {this.validator.message("Tên suất ăn", this.state.tensuatan, "required")}
                                </Form.Item>
                            </div>
                            <div className="col-md-12" style={{ padding: 10 }}>
                                <Form.Item label="Đơn giá:">
                                    <InputNumber
                                        className="w-100"
                                        name="dongia"
                                        min={0}
                                        max={9999999}
                                        maxLength={8}
                                        minLength={1}
                                        defaultValue={0}
                                        value={this.state.dongia}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={(e) => this.setState({ dongia: e })}
                                    />
                                    {this.validator.message("Đơn giá", this.state.dongia, "required|numeric|max:99999999,num|min:0,num")}
                                </Form.Item>
                            </div>
                            <div className="col-md-12" style={{ padding: 10 }}>
                                <Form.Item label="Loại:">
                                    <Select
                                        className="w-100"
                                        width="100%"
                                        name="tenloai"
                                        placeholder="Chọn loại"
                                        style={{ width: 120 }}
                                        value={this.state.loai}
                                        onChange={(e) => {
                                            this.setState({ loai: e })
                                        }}
                                    >
                                        <Option key={0} value={1}>Cơm - Cháo</Option>
                                        <Option key={1} value={2}>Sữa - Bột</Option>
                                    </Select>
                                    {this.validator.message("Loại suất ăn", this.state.loai, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>

                            <button className="btn btn-success" style={{ marginRight: 10 }}
                                onClick={() => { this.CreateSuatAn() }}>
                                <i className="fa fa-save" style={{ marginRight: 5 }} />Thêm</button>

                            <button className="btn btn-success"
                                onClick={() => this.resetState()}> <i style={{ marginRight: 10 }} className="fa fa-eraser" />Nhập mới</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.props.onCancel();
                                    this.props.onReload();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                />Đóng

                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}