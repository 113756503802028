import React, { Component } from 'react'
import { Form, Input, Popconfirm, Table, Select, notification, InputNumber } from 'antd';
import * as Actions from '../../../libs/actions';
import * as configService from '../../../services/configService';
import Modal from 'antd/lib/modal/Modal';
import Autocomplete from '../../../components/autocomplete';
import HeaderModal from '../../../configureHIS/common/headerModal';

const { Option } = Select;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lstServices: [],
            service: '',
            type: '',
            lstType: [],
            amount: 1,
            amountedit: 1,
            lstData: [],
            visable: false,
            lstTypeEquip: [],
            typeEquip: '',
            lstEquip: [],
            equip: '',
            detail: {},
        };
    }
    componentDidMount() {
        this.GetListServiceTypeConfig();
        this.GetListEquipmentTypeConfig();
    }

    GetListEquipOfService() {
        configService.GetListEquipOfService(this.state.service).then((respone) => {
            if (respone.isSuccess === true) {
                this.setState({ lstData: respone.data })
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }

    GetListServiceTypeConfig() {
        configService.GetListServiceTypeConfig().then((respone) => {
            if (respone.isSuccess === true) {
                this.setState({ lstType: respone.data })
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }

    GetListEquipmentTypeConfig() {
        configService.GetListEquipmentTypeConfig().then((respone) => {
            if (respone.isSuccess === true) {
                this.setState({ lstTypeEquip: respone.data })
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }

    GetListServiceConfig(key) {
        let type = this.state.type === undefined ? '' : this.state.type;
        configService.GetListServiceConfig(type, key).then((respone) => {
            if (respone.isSuccess === true) {
                this.setState({
                    lstServices: respone.data,
                    service: ''
                });
                this.form.setFieldsValue({
                    'service': ''
                })
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }

    GetListEquipmentConfig(key) {
        let type = this.state.typeEquip === undefined ? '' : this.state.typeEquip;
        configService.GetListEquipmentConfig(type, key).then((respone) => {
            if (respone.isSuccess === true) {
                this.setState({
                    lstEquip: respone.data,
                    equip: ''
                });
                this.form.setFieldsValue({
                    'equip': ''
                })
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }
    InsertEquipOfService() {
        const data = {
            'id': this.state.equip,
            'mavp': this.state.service,
            'soluong': this.state.amount,
        }
        Actions.setLoading(true);
        configService.InsertEquipOfService(data).then((respone) => {
            if (respone.isSuccess === true) {
                notification.success({ message: "Thao tác thành công!" });
                this.setState({ equip: '', amount: 1 });
                this.form.setFieldsValue({
                    equip: ''
                })
                this.GetListEquipOfService();
            } else {
                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
            Actions.setLoading(false);
        }).catch((ex) => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }

    UpdateEquipOfService() {
        const data = {
            'id': this.state.detail.id,
            'mavp': this.state.detail.mavp,
            'soluong': this.state.amountedit,
        }
        Actions.setLoading(true);
        configService.UpdateEquipOfService(data).then((respone) => {
            if (respone.isSuccess === true) {
                notification.success({ message: "Thao tác thành công!" });
                this.setState({ visable: false, amountedit: 1 })
                this.GetListEquipOfService();
            } else {
                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
            Actions.setLoading(false);
        }).catch((ex) => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }

    DeleteEquipOfService(id, mavp) {
        Actions.setLoading(true);
        configService.DeleteEquipOfService(id, mavp).then((respone) => {
            if (respone.isSuccess === true) {
                notification.success({ message: "Thao tác thành công!" });
                this.GetListEquipOfService();
            } else {
                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
            Actions.setLoading(false);
        }).catch((ex) => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }
    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên vật tư',
                dataIndex: 'tenbd',
                key: 'tenbd',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
            },
            {
                title: 'Action',
                render: (row) => {
                    return (
                        <div>
                            <button style={{ marginRight: 10 }} className="btn btn-warning" onClick={() => this.setState({ visible: true, detail: row })}>
                                <i className="fa fa-pencil-alt" style={{ marginRight: 5 }}></i>Cập nhật</button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa vật tư này không?"
                                onConfirm={() => { this.setState({ visible: false }); this.DeleteEquipOfService(row.id, row.mavp) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" ><i className="fa fa-trash" style={{ marginRight: 5 }}></i>Xóa</button>
                            </Popconfirm>

                        </div>
                    );
                },
            }
        ]
        return (
            <React.Fragment>
                <div className='containerChilderWrapper' >
                    <Form ref={(c) => this.form = c} style={{ height: "100vh" }}>
                        <HeaderModal title="Thiết lập vật tư cho dịch vụ" />
                        <div className="row" style={{ display: 'flex', paddingTop: 10 }}>
                            <div className="col-md-6 mt1">
                                <Form.Item label="Loại dịch vụ:">
                                    <Select
                                        allowClear={true}
                                        showSearch
                                        value={this.state.type}
                                        onChange={(e) => { this.setState({ type: e, lstData: [] }, () => { this.GetListServiceConfig(e) }) }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } >
                                        {this.state.lstType.map((sl, index) => {
                                            return (
                                                <Option key={index} value={sl.value}>{sl.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mt1">
                                <Form.Item label="Tên dịch vụ:" name="service">
                                    <Autocomplete width={'100%'}
                                        ref={((c) => this.autocomplete = c)}
                                        dataSource={this.state.lstServices}
                                        handleSearch={this.GetListServiceConfig.bind(this)}
                                        getItem={(key, value) => {
                                            this.form.setFieldsValue({ service: key });
                                            this.setState({ service: key }, () => this.GetListEquipOfService())
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row" style={{ display: 'flex' }}>
                            <div className="col-md-6 mt1">
                                <Form.Item label="Loại vật tư:">
                                    <Select
                                        allowClear={true}
                                        showSearch
                                        value={this.state.typeEquip}
                                        onChange={(e) => {
                                            this.setState({ typeEquip: e },
                                                () => { this.GetListEquipmentConfig(e) })
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstTypeEquip.map((sl, index) => {
                                            return (
                                                <Option key={index} value={sl.value}>{sl.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mt1">
                                <Form.Item label="Tên vật tư:" name="equip">
                                    <Autocomplete width={'100%'}
                                        ref={((c) => this.autocomplete = c)}
                                        dataSource={this.state.lstEquip}
                                        handleSearch={this.GetListEquipmentConfig.bind(this)}
                                        getItem={(key, value) => {
                                            this.form.setFieldsValue({ equip: key });
                                            this.setState({ equip: key })
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt1">
                                <Form.Item label="Số lượng:">
                                    <InputNumber width={250}
                                        min={1}
                                        maxLength={5}
                                        max={99999}
                                        onChange={(e) => this.setState({ amount: e })}
                                        value={this.state.dongia} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt1">
                                <Form.Item>
                                    <button className="btn btn-success"
                                        onClick={() => { this.InsertEquipOfService() }}
                                    ><i className="fa fa-plus" style={{ marginRight: 5 }}></i>Thêm</button>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="table-responsive" style={{ paddingTop: 10 }}>
                            <Table columns={columns} scroll={{ y: 0 }}
                                dataSource={this.state.lstData} />
                        </div>
                        {this.state.visible && <Modal maskClosable={false}
                            visible={this.state.visible}
                            onCancel={() => { this.setState({ visible: false }) }}
                            footer={[
                                <button key="submit" className="btn btn-success" onClick={() => this.UpdateEquipOfService()}>
                                    <i className="fa fa-save" style={{ marginRight: 10 }}></i>Lưu</button>,
                            ]}
                        >
                            <div style={{ marginBottom: '8px', marginTop: '30px' }}>
                                <Form.Item label="Tên vật tư:">
                                    <Input disabled={true} value={this.state.detail.tenbd} />
                                </Form.Item>
                            </div>
                            <div style={{ marginBottom: '8px' }}>
                                <Form.Item label="Số lượng:">
                                    <InputNumber min={1} maxLength={5} max={99999} style={{ width: 200 }}
                                        onChange={(e) => this.setState({ amountedit: e })}
                                        defaultValue={this.state.detail.soluong} />
                                </Form.Item>
                            </div>
                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                <Form.Item>
                                    <button style={{ marginRight: 10 }} className="btn btn-success" onClick={() => this.UpdateEquipOfService()}>
                                        <i className="fa fa-save" style={{ marginRight: 5 }}></i>Lưu</button>
                                </Form.Item>
                                <Form.Item>
                                    <button className="btn btn-danger" onClick={() => { this.setState({ visible: false }) }}>
                                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}></i>Đóng</button>
                                </Form.Item>
                            </div>
                        </Modal>}
                    </Form>
                </div>
            </React.Fragment>
        )
    }
}
