import { DatePicker, Form, notification, Table, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../../libs/actions';
import * as StatisticService from '../../../../../services/statisticService';
import * as PrintService from '../../../../../services/printService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../../../services/configService';
import * as UsersService from '../../../../../services/usersService';
const { RangePicker } = DatePicker;
const { Option } = Select;

export default class theodichvuBN extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            tongthucthu: 0,
            tongbn: 0,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
        };
    }

    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        this.GetListSite();
        this.LoadThongKeDoanhSo();
    }

    
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadThongKeDoanhSo() {
        Actions.setLoading(true);
        StatisticService.ThongKeTheoDichVuBenhNhan(this.props.ngay !== undefined ? this.props.ngay[0].format('YYYY-MM-DD HH:mm') : this.state.ngay[0].format('YYYY-MM-DD 00:00'),
                this.props.ngay !== undefined ? this.props.ngay[1].format('YYYY-MM-DD HH:mm') : this.state.ngay[1].format('YYYY-MM-DD 23:59'),
                this.props.paymenttype, this.state.chinhanh).then(result => {
            if (result.isSuccess) {
                let tongtien = 0;
                let tongbn = 0;
                result.data.forEach(x => { tongtien += x.tongtien; if (x.mavp === null || x.mavp === '');});
                tongbn = result.totalRecords;
                // result.data.GroupBy(x => new { x.mabn, x.ngay }).Select(x => x.First()).Count();
                this.setState({
                    dataSource: result.data,
                    tongthucthu: tongtien,
                    tongbn: tongbn,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeDoanhSo(props) {
        Actions.setLoading(true);
        StatisticService.ExportThongKeDichVuTheoNguonKhachHang(this.props.ngay !== undefined ? this.props.ngay[0].format('YYYY-MM-DD HH:mm') : this.state.ngay[0].format('YYYY-MM-DD 00:00'),
                this.props.ngay !== undefined ? this.props.ngay[1].format('YYYY-MM-DD HH:mm') : this.state.ngay[1].format('YYYY-MM-DD 23:59'),
                this.props.paymenttype, this.state.chinhanh)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDichVuTheoNguonKhachHang";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    CheckNumberMoney(num) {
        if (num < 0)
            return 0;
        return num;
    }
    GetAllInvoices = (data) => {
        if (data.sobienlai)
            return data.sobienlai;
        else return "";
    }
    render() {
        const columns = [
            {
                title: 'STT',
                width: 50,
                align: 'center',
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <strong style={{ fontSize: '15px', float: 'left', marginLeft: '5px' }}>Biên lai: {this.GetAllInvoices(record)} - {record.tenbn} ({(record.tongtien).toLocaleString('en-US')})</strong>,
                            props: {
                                colSpan: 12,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{this.state.dataSource.filter(x => x.sobienlai === record.sobienlai).indexOf(record)}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Sản phẩm/Dịch vụ',
                dataIndex: 'tenvp',
                key: 'tenvp',
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span>{ }</span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{record.tenvp}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },

            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span>{ }</span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{record.soluong}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Ngày lập',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 120,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{text}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            {
                title: 'Nguồn khách hàng',
                dataIndex: 'tendonvi',
                key: 'tendonvi',
                width: 200,
                render: (text, record, index) => {
                    if (record.mavp === null || record.mavp === '') {
                        return {
                            children: <span></span>,
                            props: {
                                colSpan: 0,
                            }
                        }
                    }
                    else {
                        return {
                            children: <span>{text}</span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },

        ]
        return (
            <React.Fragment>
                <div style={{ padding: "0px", display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 15 }}>
                        {/* {this.state.isMultisite === true &&
                            <div><Form.Item>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    allowClear={false}
                                    value={this.state.chinhanh}
                                    onChange={(e) => {
                                        this.setState({ chinhanh: e })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {this.state.listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select></Form.Item>
                            </div>
                        } */}
                        {this.props.doanhso === true &&
                        <>
                            <div className="col-auto">
                                <Form.Item>
                                    <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                        allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                        placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                        onChange={(value => this.setState({ ngay: value }, () => { this.LoadThongKeDoanhSo() }))}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item>
                                <button className="btn btn-color" onClick={() => { this.LoadThongKeDoanhSo() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                            </Form.Item>
                        </>
                        }
                        <Form.Item>
                            <button className="btn btn-color" style={{ margin: "0px 10px 0 5px" }} onClick={() => { this.ExportThongKeDoanhSo() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                        </Form.Item>
                        <div style={{ marginLeft: '5px' }}>Tổng bệnh nhân: <strong>{this.state.tongbn.toLocaleString('en-US')}</strong></div>
                    </div>
                    
                    <div style={{ height: 'calc(100% - 120px)' }}>
                        <Table
                            pagination={false}
                            scroll={{ x: 'max-content', y: 0 }}
                            dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
