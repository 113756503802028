import React, { Fragment, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { notification } from 'antd';
import * as Actions from '../../libs/actions';
import Administrative from '../recept/components/administrative';
import * as RegisMediService from '../../services/regisMediService';
import * as CommonService from '../../services/commonService';
import * as PatientService from '../../services/patientService';
import { useEffect } from 'react';

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        GetPatientById: (patientID) => {
            GetPatientById(patientID);
        },
    }));

    const [isUpdate, setIsUpdate] = useState(false);
    const [isPreview, setIsPreview] = useState(true);
    const [newPatient, setNewPatient] = useState(true);
    const [nguoiGioiThieu, setNguoiGioiThieu] = useState(true);
    const [isNotRegister, setIsNotRegister] = useState(true);

    const _administrative = useRef();

    useEffect(() => {
        setIsPreview(props.isPreview ? props.isPreview : false);
        setIsUpdate(props.isUpdate ? props.isUpdate : false);
        setIsUpdate(props.isPreview ? props.isPreview : false);
        setNewPatient(props.newPatient ? props.newPatient : true);
        setNguoiGioiThieu(props.nguoiGioiThieu ? props.nguoiGioiThieu : true);
        setIsNotRegister(props.isNotRegister ? props.isNotRegister : true);
        props.patientID && GetPatientById(props.patientID);
    }, [props])
    
    async function UpdatePatient() {
        let obj = _administrative.current.getState();
        const data = {
            patientID: obj.code,
            PatientName: obj.name,
            birthDay: obj.dob,
            gender: obj.gender,
            phone: obj.phone,
            email: obj.email,
            cardId: obj.cmnd,
            ward: obj.ward,
            district: obj.district,
            city: obj.city,
            patientName: obj.name,
            address: obj.address,
            coporator: obj.coporator,
            onlyyear: obj.onlyyear,
            marketing : obj.marketing.join(';'),
        }
        PatientService.Update(data).then(result => {
            if (result.isSuccess) {
                props.onCancel();
                props.loadDanhSach();
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                notification.warning({ message: result.err.msgString })
            }
        }).catch(err => {
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }

    async function CreatePatient() {
        let data = _administrative.current.getState();
        data.city = data.city ? data.city.toString() : '';
        data.district = data.district ? data.district.toString() : '';
        data.ward = data.ward ? data.ward.toString() : '';
        data.subjectCode = '2';
        data.people = '25';
        let numberError = 0;
        await _administrative.current.getForm().validateFields().then(values => {
            if (values.errorFields)
                numberError += values.errorFields.length

        }).catch((ex) => {
            if (ex.errorFields)
                numberError += ex.errorFields.length
        })
        if (_administrative.current.validator.allValid()) {
            if (numberError === 0) {
                Actions.setLoading(true);
                RegisMediService.CreatePatient(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' });
                        props.onCancel();
                        props.loadDanhSach();
                    }
                    else {
                        Actions.openMessageDialog("Lỗi", result.err.msgString)
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.openMessageDialog("Lỗi", err);
                    Actions.setLoading(false);
                })
            }
        }
    }

    const GetPatientById = (patientID) => {
        CommonService.GetPatientDetail(patientID).then(result => {
            if (result.isSuccess) {
                setTimeout(() => {
                    _administrative.current && _administrative.current.handleLoadPatientBarCode(result.item);
                }, 200);
            } else {
                notification.warning({ message: result.err.msgString })
            }
        }).catch(err => {
            notification.warning({ message: err })
        })
    }

    return (
        <Fragment>
            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>{isPreview ? "Xem" : isUpdate ? "Cập nhật" : "Thêm"} thông tin khách hàng</h3>
                <h3 onClick={() => { props.onCancel() }}><i className='far fa-times-circle' /></h3>
            </div>
            <Administrative ref={_administrative}
                newPatient={newPatient}
                nguoigioithieu={nguoiGioiThieu}
                isnotRegister={isNotRegister}
                isUpdate={isUpdate}
            />
            <div className='custom-hr'></div>
            <div className='row'>
                <div className="col-md-12 d-flex justify-content-end" >
                    <div>
                        {!isPreview && <button className="btn btn-color" onClick={() => {{ isUpdate ? UpdatePatient() : CreatePatient() }}}>
                            <i className="fa fa-save mr-2" />{isUpdate ? 'Cập nhật' : "Lưu"}
                        </button>}
                        <button className="btn btn-danger ml-2" onClick={() => { props.onCancel() }}>
                            <i className="fas fa-sign-out-alt mr-2" />Đóng
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
})