import React, { Component } from 'react'
import { Input, Form, notification, Select } from 'antd';
import * as Actions from '../../../libs/actions';
import * as PhongKhamService from '../services/PhongKhamService';
import SimpleReactValidator from 'simple-react-validator';

const { Option } = Select;
const initialState = {
    mapk: '',
    tenpk: '',
}
export default class CreateRoom extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstDept: [],
            dept: 0,
            lstKhoa: [],
            khoa: '',
        };
    }

    componentDidMount() {
        PhongKhamService.DanhSachLoaiPhongKham().then(result => {
            if (result.isSuccess) {
                this.setState({ lstDept: result.data })
                if (result.data.length > 0) {
                    this.setState({ dept: result.data[0].value })
                    this.form.setFieldsValue({ dept: result.data[0].value });
                }
            }
            else {
                Actions("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions("Lỗi", err);
        })
        PhongKhamService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                let data = result.data.map(x => ({ 'value': x.makp, 'label': x.tenkp }))
                this.setState({ lstKhoa: data })
                if (data.length > 0) {
                    this.setState({ khoa: data[0].value })
                    this.form.setFieldsValue({ khoa: data[0].value });
                }
            }
            else {
                Actions("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions("Lỗi", err);
        })
    }
    resetState() {
        this.form.setFieldsValue({
            mapk: null,
            tenpk: null
        });
        this.setState({
            mapk: '',
            tenpk: ''
        })
    }
    async CreatePK() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (this.state.mapk.length > 3) {
            notification.warning({ message: 'Mã phòng khám tối đa 3 ký tự!' });
            return;
        }
        if (numberError === 0) {
            const data = {
                makp: this.state.mapk,
                tenkp: this.state.tenpk,
                loaipk: this.state.dept,
                makhoa: this.state.khoa,
            }
            PhongKhamService.TaoMoiPK(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                    this.resetState();
                }
                else {
                    notification.warning({ message: result.err.msgString })
                }
            }).catch(err => {
                notification.warning({ message: 'Tạo thất bại. Có lỗi xảy ra!' });
            })
        } else {
            this.validator.showMessages();
        }
    }



    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm phòng khám bệnh</h3>
                    </div>
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ display: 'flex', marginBottom: 10, marginTop: 10 }}>
                            <Form.Item style={{ flex: 1 }} label="Mã phòng:" name="mapk"
                                rules={[{ required: true, message: 'Yêu cầu nhập mã phòng khám!' }]}>
                                <Input
                                    maxLength={3}
                                    value={this.state.mapk}
                                    onChange={(e) => this.setState({ mapk: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} label="Tên phòng:" name="tenpk"
                                rules={[{ required: true, message: 'Yêu cầu nhập tên phòng khám!' }]}>
                                <Input
                                    value={this.state.tenpk}
                                    onChange={(e) => this.setState({ tenpk: e.target.value })}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <Form.Item style={{ flex: 1 }} label="Loại phòng:"
                                name="dept" rules={[{ required: true, message: 'Vui lòng chọn loại phòng khám!' }]}>
                                <Select
                                    value={this.state.dept}
                                    onChange={(e) => this.setState({ dept: e })}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstDept.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} label="Khoa:"
                                name="khoa" rules={[{ required: true, message: 'Vui lòng chọn khoa!' }]}>
                                <Select
                                    value={this.state.khoa}
                                    onChange={(e) => this.setState({ khoa: e })}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstKhoa.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success" onClick={() => { this.CreatePK() }}>
                                <i className="fa fa-save" style={{ marginRight: 5 }} />Thêm</button>
                            <button style={{ marginLeft: 10 }} className="btn btn-warning" onClick={() => this.resetState()}>
                                <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>

                            <button style={{ marginLeft: 10 }} className="btn btn-danger"
                                onClick={() => {
                                    this.props.onCancel();
                                    this.props.onReload();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng

                              </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
