import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { Select, DatePicker, notification, Statistic, Form } from 'antd';

import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import { colors } from '../../../../commons/constanst';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';

const { RangePicker } = DatePicker;
const { Option } = Select;



export default class chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            ngay: [moment().subtract(7, "days"), moment()],
            type: "1",
            totalExam: 0,
            totalMoney: 0,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
        };
    }
    componentDidMount() {
        // UsersService.CheckPremissionMultisite().then(result => {
        //     if (result.isSuccess) {
        //         this.setState({
        //             isMultisite: result.item
        //         });
        //     }
        // });
        // this.GetListSite();
        this.RevenueStatisticChart();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    setData(data) {


        this.setState({ data })
    }
    toggleDataSeries(e) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        }
        else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }

    checktypePicker() {
        switch (this.state.type) {
            case "1":
                return '';
            case "2":
                return 'month';
            case "3":
                return 'year';
            case "4":
                return 'year';
            default:
                return '';
        }
    }


    getFormat() {
        switch (this.state.type) {
            case "1":
                return 'DD-MM-YYYY';
            case "2":
                return 'MM-YYYY';
            case "3":
                return 'YYYY';
            case "4":
                return 'YYYY';
            default:
                return '';
        }
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    RevenueStatisticChart() {
        let fromDate = null;
        let toDate = null;
        if (Array.isArray(this.state.ngay)) {
            fromDate = this.state.ngay[0].format('YYYY-MM-DD');
            toDate = this.state.ngay[1].format('YYYY-MM-DD');
        } else {
            fromDate = this.state.ngay.format('YYYY-MM-DD');
        }
        StatisticService.RevenueStatisticChart(this.state.type,
            fromDate, toDate, this.state.chinhanh).then(result => {
                if (result.isSuccess) {
                    var finalData = {};
                    var labels = result.item.labels;
                    var datasets = [];
                    var cdha = {};
                    cdha.data = result.item.cdha;
                    cdha.label = 'Chẩn đoán hình ảnh'
                    cdha.backgroundColor = colors[0]
                    cdha.borderColor = colors[0]
                    cdha.borderWidth = 1
                    cdha.hoverBackgroundColor = colors[0]
                    cdha.hoverBorderColor = colors[0]

                    var khambenh = {};
                    khambenh.data = result.item.khambenh;
                    khambenh.label = 'Khám bệnh'
                    khambenh.backgroundColor = colors[1]
                    khambenh.borderColor = colors[1]
                    khambenh.borderWidth = 1
                    khambenh.hoverBackgroundColor = colors[1]
                    khambenh.hoverBorderColor = colors[1]

                    var xetnghiem = {};
                    xetnghiem.data = result.item.xetnghiem;
                    xetnghiem.label = 'Xét nghiệm'
                    xetnghiem.backgroundColor = colors[2]
                    xetnghiem.borderColor = colors[2]
                    xetnghiem.borderWidth = 1
                    xetnghiem.hoverBackgroundColor = colors[2]
                    xetnghiem.hoverBorderColor = colors[2]

                    var ttpt = {};
                    ttpt.data = result.item.ttpt;
                    ttpt.label = 'Phẫu thuật - thủ thuật'
                    ttpt.backgroundColor = colors[3]
                    ttpt.borderColor = colors[3]
                    ttpt.borderWidth = 1
                    ttpt.hoverBackgroundColor = colors[3]
                    ttpt.hoverBorderColor = colors[3]

                    var thuoc = {};
                    thuoc.data = result.item.thuoc;
                    thuoc.label = 'Thuốc'
                    thuoc.backgroundColor = colors[4]
                    thuoc.borderColor = colors[4]
                    thuoc.borderWidth = 1
                    thuoc.hoverBackgroundColor = colors[4]
                    thuoc.hoverBorderColor = colors[4]

                    // var dongy = {};
                    // dongy.data = result.item.dongy;
                    // dongy.label = 'Đông y'
                    // dongy.backgroundColor = colors[5]
                    // dongy.borderColor = colors[5]
                    // dongy.borderWidth = 1
                    // dongy.hoverBackgroundColor = colors[5]
                    // dongy.hoverBorderColor = colors[5]

                    // var capcuu = {};
                    // capcuu.data = result.item.capcuu;
                    // capcuu.label = 'Cấp cứu'
                    // capcuu.backgroundColor = colors[6]
                    // capcuu.borderColor = colors[6]
                    // capcuu.borderWidth = 1
                    // capcuu.hoverBackgroundColor = colors[6]
                    // capcuu.hoverBorderColor = colors[6]

                    var DVKhac = {};
                    DVKhac.data = result.item.dichvukhac;
                    DVKhac.label = 'Dịch vụ khác'
                    DVKhac.backgroundColor = colors[7]
                    DVKhac.borderColor = colors[7]
                    DVKhac.borderWidth = 1
                    DVKhac.hoverBackgroundColor = colors[7]
                    DVKhac.hoverBorderColor = colors[7]

                    datasets.push(thuoc);
                    datasets.push(xetnghiem);
                    datasets.push(cdha);
                    datasets.push(khambenh);
                    datasets.push(ttpt);
                    // datasets.push(dongy);
                    // datasets.push(capcuu);
                    datasets.push(DVKhac);

                    finalData.labels = labels
                    finalData.datasets = datasets
                    this.setState({ data: finalData, totalMoney: result.item.totalMoney });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
    }
    render() {

        return (
            <div style={{ height: '96%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: 5 }}>
                    <div className="row no-gutters" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        {/* {this.state.isMultisite === true &&
                            <div className="col-auto mt-2">
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    allowClear={false}
                                    value={this.state.chinhanh}
                                    onChange={(e) => {
                                        this.setState({ chinhanh: e })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {this.state.listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        } */}
                        <div className="col-auto mt-2">
                            <Select defaultValue="1" style={{ width: 120, marginRight: 30, marginLeft: 5 }} onChange={(value) => {
                                if (value !== "3") {

                                    this.setState({ type: value, ngay: [moment().subtract(7, "days"), moment()] })
                                } else {
                                    this.setState({ type: value, ngay: moment() })
                                }
                            }}>
                                <Option value="1">Ngày</Option>
                                <Option value="2">Tháng</Option>
                                <Option value="3">Quý</Option>
                                <Option value="4">Năm</Option>
                            </Select>
                        </div>
                        <div className="col-auto mt-2">
                            {this.state.type === "3" ?
                                <Form.Item>
                                    <DatePicker locale={viVN} disabledDate={this.disabledDate} allowClear={false}
                                        onChange={(value) => this.setState({ ngay: value }, () => this.RevenueStatisticChart())} style={{ marginLeft: 5 }}
                                        format={this.getFormat()} value={this.state.ngay}
                                        placeholder={[this.getFormat()]}
                                        picker={
                                            this.checktypePicker()
                                        } />
                                </Form.Item>
                                :
                                this.state.type !== "1" ?
                                    <Form.Item>
                                        <RangePicker locale={viVN} disabledDate={this.disabledDate} allowClear={false}
                                            onChange={(value) => this.setState({ ngay: value }, () => this.RevenueStatisticChart())} style={{ marginLeft: 5 }}
                                            format={this.getFormat()} value={this.state.ngay}
                                            placeholder={[this.getFormat(), this.getFormat()]}
                                            picker={
                                                this.checktypePicker()
                                            } /></Form.Item>
                                    :
                                    <Form.Item>
                                        <RangePicker locale={viVN} disabledDate={this.disabledDate} allowClear={false}
                                            onChange={(value) => this.setState({ ngay: value }, () => this.RevenueStatisticChart())} style={{ marginLeft: 5 }}
                                            format={this.getFormat()} value={this.state.ngay}
                                            placeholder={[this.getFormat(), this.getFormat()]}
                                        /></Form.Item>
                            }
                        </div>
                        <div className="col-auto mt-2">
                            <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { this.RevenueStatisticChart() }}><i style={{ marginLeft: 5 }} className="fa fa-search" />Tìm</button>
                        </div>
                    </div>
                    <Statistic title="Tổng Tiền" value={this.state.totalMoney} />
                </div>
                <div style={{ flex: 1 }}>
                    <Bar style={{ maxWidth: '100%' }} data={this.state.data}
                        getElementAtEvent={(e) => { }}
                        onElementsClick={(e) => { }}
                        options={{
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {

                                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                        value = value.toString();
                                        value = value.split(/(?=(?:...)*$)/);

                                        // Convert the array to a string and format the output
                                        value = value.join(',');
                                        return value

                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    barThickness: 30,
                                    stacked: true,
                                    ticks: {
                                        fontColor: "rgb(0,0,0)",
                                    },
                                    gridLines: {
                                        lineWidth: 0,
                                        zeroLineWidth: 1
                                    },
                                }
                                ],
                                yAxes: [{
                                    stacked: true,
                                    id: 'A',
                                    position: 'left',
                                    ticks: {
                                        fontColor: "rgb(25,99,230)",
                                        beginAtZero: true,
                                        min: 0,
                                        userCallback: function (value, index, values) {
                                            // Convert the number to a string and splite the string every 3 charaters from the end
                                            value = value.toString();
                                            value = value.split(/(?=(?:...)*$)/);

                                            // Convert the array to a string and format the output
                                            value = value.join(',');
                                            return value;
                                        }
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Doanh số'
                                    },

                                },
                                ]
                            },
                            maintainAspectRatio: false,
                            responsive: true
                        }} />
                </div>
            </div>
        );
    }
}