import { Form, Input, notification, Select } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as CommonService from '../../../services/commonService';
const { Option } = Select;
const initialState = {
    maquan: '',
    mapxa: '',
    tenpxa: '',
}
export default class createward extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstDistrict: [],
            lstCity: [],
            matt: [],
            newWard: true
        };
    }

    componentWillMount() {
        this.loadCity();
    }
    loadCity() {
        CommonService.DanhMucThanhThi().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstCity: result.data
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thành phố bị lỗi' })
        })
    }
    loadDistrict(matt) {
        CommonService.DanhMucHuyen(matt).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDistrict: result.data,
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu quận huyện bị lỗi' })
        })
    }
    async create() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                maquan: this.state.maquan,
                mapxa: this.state.mapxa,
                tenpxa: this.state.tenpxa,
                loai: 'phuongxa',
            }
            CommonService.updateCity(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Lưu thành công!' });
                    this.resetState();
                    if (this.props.onLoad)
                        this.props.onLoad();
                    this.props.onReload();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }
    }
    resetState() {
        this.setState({
            maquan: '',
            mapxa: '',
            tenpxa: '',
        })
        this.form.setFieldsValue({
            maquan: null,
            mapxa: '',
            tenpxa: '',
        });
    }
    checkNewWard(key, page = 1, size = 1) {
        CommonService.DanhMucPhuongXa(this.state.maquan, key, page, size).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    newWard: false
                });
            }
            else {
                this.setState({
                    newWard: true
                })
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ padding: "5px 20px" }}>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: " 30px" }}>
                            <h3>Thêm dữ liệu phường/xã</h3>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex' }}>
                            <div className="col-md-6">
                                <Form.Item label="Tỉnh/Thành phố:" name="matt"
                                    rules={[{ required: true, message: 'Yêu cầu chọn tỉnh/thành phố!' }]}>
                                    <Select
                                        showSearch
                                        onChange={(e) => { this.setState({ matt: e }, () => { this.loadDistrict(e) }) }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstCity.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.key}>{row.value}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Quận/Huyện:" name="maquan"
                                    rules={[{ required: true, message: 'Yêu cầu chọn quận/huyện!' }]}>
                                    <Select
                                        showSearch
                                        onChange={(e) => { this.setState({ maquan: e }); this.form.setFieldsValue({ mapxa: null }) }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstDistrict.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.key}>{row.value}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            {/* <div className="col-md-6">
                                <Form.Item label="Mã phường/xã:" name="mapxa"
                                    rules={[{ required: true, message: 'Yêu cầu nhập mã phường/xã!' }]}>
                                    <Input
                                        maxLength={7}
                                        value={this.state.mapxa}
                                        onChange={(e) => {
                                            this.setState({ mapxa: e.target.value });
                                            this.checkNewWard(e.target.value)
                                        }}
                                        onBlur={() => {
                                            !this.state.newWard && this.state.mapxa && notification.warning({message: "Mã phường/xã đã tồn tại"});
                                        }}
                                    />
                                </Form.Item>
                            </div> */}
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex' }}>
                            <div className="col-md-12">
                                <Form.Item label="Phường/xã:" name="tenpxa"
                                    rules={[{ required: true, message: 'Yêu cầu nhập tên phường/xã!' }]}>
                                    <Input
                                        value={this.state.tenpxa}
                                        onChange={(e) => this.setState({ tenpxa: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success" disabled={!this.state.newWard} onClick={() => this.create()}>
                                <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                            <button className="btn btn-warning" style={{ marginLeft: 10 }} onClick={() => this.resetState()}> <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.props.onCancel()
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
