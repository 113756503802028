import { Button, Form, Input, Pagination, Switch, Table, notification, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import JSONPretty from 'react-json-pretty';
import * as Actions from '../../../libs/actions';
import * as LogService from '../../../services/logService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import { FORMAT_DATE_TIME_SECOND } from '../../../configure/systemConfig';
const { RangePicker } = DatePicker;
const { Option } = Select;
export default function ListLog() {
    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    const [date, setDate] = useState([moment(), moment()]);
    const [selectedId, setSelectedId] = useState('');
    const [dataJson, setDataJson] = useState("");
    const [dataMessage, setDataMessage] = useState("");
    const [type, setType] = useState(0);
    const [status, setStatus] = useState(0);
    useEffect(() => {
        GetListLog();
    }, []);


    useEffect(() => {
        if (currentPage === 0) {
            return;
        }
        GetListLog()
    }, [currentPage, size]);


    const GetListLog = () => {
        Actions.setLoading(true);
        LogService.GetListLog(date[0].format("YYYY/MM/DD"), date[1].format("YYYY/MM/DD"), type, status, key, currentPage, size).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
                setTotalRecords(result.totalRecords)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const UpdateCheck = (id, check) => {
        LogService.UpdateCheck(id, check).then(result => {
            let data = dataSource;
            let item = data.find(x => x.id === id);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.isCheck === true ? false : true;
                    item.isCheck = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.isCheck;
                    item.isCheck = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            setDataSource(data.map(x => ({ ...x })));
        }).catch(err => {
            let data = dataSource;
            let item = data.find(x => x.id === id);
            if (item !== undefined) {
                const newcheck = item.isCheck;
                item.isCheck = newcheck;
            }
            setDataSource(data.map(x => ({ ...x })));
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    const columns = [
        {
            title: 'Ngày',
            dataIndex: 'dateCreate',
            key: 'dateCreate',
            width: 130,
            render: (value) => {
                return (
                    <div>{moment(value).format(FORMAT_DATE_TIME_SECOND)}</div>
                )
            }
        },
        {
            title: 'Controller',
            dataIndex: 'controller',
            key: 'Controller',
            width: 120
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'Action',
            width: 160
        },
        {
            title: 'Method',
            dataIndex: 'method',
            key: 'Method',
            width: 80,
        },
        {
            title: 'Check',
            align: 'center',
            width: 100,
            render: (row) => {
                return (
                    <Switch checkedChildren="Đã check" unCheckedChildren="Chưa check" checked={row.isCheck === true}
                        onChange={(e) => {
                            UpdateCheck(row.id, e)
                        }} />
                )
            }
        }
    ];
    return (
        <div style={{ height: '100%' }}>
            <div className="row no-gutters mb-2" style={{ justifyContent: 'center' }}>
                <div className="col-auto">
                    <Form.Item>
                        <Select
                            allowClear={false}
                            value={type}
                            onChange={e => { setType(e) }}
                        >
                            <Option key={0} value={0}>Loại log</Option>
                            <Option key={1} value={1}>Controller</Option>
                            <Option key={2} value={2}>PostgreSQL</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Select
                            allowClear={false}
                            value={status}
                            onChange={e => { setStatus(e) }}
                        >
                            <Option key={0} value={0}>Trạng thái</Option>
                            <Option key={1} value={1}>Đã check</Option>
                            <Option key={2} value={2}>Chưa check</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                            allowClear={false} format="DD-MM-YYYY" value={date}
                            style={{ width: '200px' }}
                            placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                            onChange={(e) => { setDate(e) }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Input
                            value={key}
                            placeholder="Nhập từ khóa"
                            onChange={(e) => setKey(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setCurrentPage(0);
                                    setTimeout(() => {
                                        setCurrentPage(1);
                                    }, 100);
                                }
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Button className="btn btn-color" onClick={() => {
                            setCurrentPage(0);
                            setTimeout(() => {
                                setCurrentPage(1);
                            }, 100);
                        }
                        }>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                    </Form.Item>
                </div>
            </div>

            <div style={{ height: 'calc(100% - 80px)', marginTop:10, display: 'flex' }}>
                <div style={{ height: '100%', width: '49%', marginRight: 10 }}>
                    <Table dataSource={dataSource}
                        scroll={{ y: 0, x: 'max-content' }}
                        pagination={false}
                        columns={columns} rowKey={(e, index) => index}
                        onRow={(record) => {
                            return {
                                onClick: e => {
                                    setSelectedId(record.id);
                                    setDataJson(record.param);
                                    setDataMessage(record.message);
                                }
                            };
                        }}
                        rowClassName={(record) => {
                            return `rowCustomSelect ${(selectedId === record.id) ? 'rowCustomSelectActive' : ''}`
                        }}
                    />

                    <Pagination
                        current={currentPage}
                        size={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setCurrentPage(page) }}
                        onShowSizeChange={(page, size) => { setSize(size) }}
                        showSizeChanger={true}
                    />
                </div>
                <div style={{ width: '50%', height: '100%', overflow: 'auto' }}>
                    <div className="warrperStyleGroup" style={{ height: '100%',  }}>
                        <div className="grouplabel" style={{marginTop:7}}>
                            <h4>Thông tin</h4>
                        </div>

                        <div className="font-weight-bold">PARAM:</div>
                        {/* <ReactJson src={JSON.parse(dataJson)}  /> */}
                        <JSONPretty id="json-pretty" data={dataJson}></JSONPretty>
                        <div className="font-weight-bold">MESSAGE:</div>
                        <JSONPretty id="json-pretty" data={dataMessage}></JSONPretty>
                    </div>
                </div>
            </div>
        </div>


    )

}