import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Report';

export function getAverageRevenueSumary(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/AverageRevenueSumary`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getAverageRevenueListForChart(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/AverageRevenueListForChart`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getAverageRevenueList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/AverageRevenueList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getAverageRevenueListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/AverageRevenueListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};

export function getSpentRevenueSumary(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SpentRevenueSumary`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getSpentRevenueListForChart(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SpentRevenueListForChart`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getSpentRevenueList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SpentRevenueList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getSpentRevenueListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/SpentRevenueListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};

//#region Thống kê doanh thu CDHA
export function getCDHARevenueListForChartLoai(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CDHARevenueListForChartLoai`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getCDHARevenueListForChartVienPhi(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CDHARevenueListForChartVienPhi`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getCDHARevenueGetList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CDHARevenueGetList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getCDHARevenueListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/CDHARevenueListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
export function SummaryRegisterServiceList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SummaryRegisterServiceList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DetailRegisterServiceList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/DetailRegisterServiceList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function RegisterServiceListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/RegisterServiceListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
export function SummaryPerformanceServiceList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SummaryPerformanceServiceList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DetailPerformanceServiceList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/DetailPerformanceServiceList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function PerformanceServiceListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/PerformanceServiceListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
//#endregion
