import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, Modal, notification, Table, Input, Pagination, Checkbox, Tooltip, Upload, Tag } from 'antd';
import * as Actions from '../../../libs/actions';
import * as Instruction from './MedicinesUsingService';

const maxLength = 100;
const { TextArea } = Input;
const { Dragger } = Upload;

function CachDungThuoc() {
    const [key, setKey] = useState("");
    const [uncompleted, setUncompleted] = useState(0);
    const [inEditMode, setInEditMode] = useState(false);
    const [focusId, setFocusId] = useState();
    const [newInfo, setNewInfo] = useState('');
    const [showMore, setShowMore] = useState('');
    const [listMedicines, setListMedicines] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [visibleImport, setVisibleImport] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [showImportResult, setShowImportResult] = useState(false);
    const [insertRow, setInsertRow] = useState(0);
    const [rejectRow, setRejectRow] = useState(0);
    const [rejectList, setRejectList] = useState('');
    const [visibleImportInfo, setVisibleImportInfo] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);

    const _hiddenLink = useRef();
    const _header = useRef();

    const columns = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 70,
        },
        {
            title: 'Mã',
            dataIndex: 'ma',
            key: 'ma',
            align: 'center',
            width: 100,
        },
        {
            title: 'Tên',
            dataIndex: 'tenthuoc',
            key: 'tenthuoc',
            align: 'left',
            width: 250,
        },
        {
            title: 'Nước',
            dataIndex: 'tennuoc',
            key: 'tennuoc',
            align: 'left',
            width: 100
        },
        {
            title: 'Hợp chất',
            dataIndex: 'tenhc',
            key: 'tenhc',
            align: 'left',
            width: 200
        },
        {
            title: 'ĐVT',
            dataIndex: 'donvi',
            key: 'donvi',
            align: 'left',
            width: 80
        },
        {
            title: 'Cách dùng',
            key: 'cachdung',
            align: 'left',
            width: 260,
            render: (row) => {
                if (row.id === focusId && inEditMode) {
                    return <TextArea
                        rows={2} defaultValue={row.cachdung}
                        value={newInfo} onChange={(e) => setNewInfo(e.target.value)}
                        onPressEnter={() => updateMedicinesInstruction(row.id)}
                        placeholder="Nhập cách sử dụng thuốc"></TextArea>
                } else {
                    return (
                        <div>
                            <span style={{ whiteSpace: "pre-wrap" }}>{showMore === row.id ? row.cachdung : row.cachdung.substring(0, maxLength)}</span>
                            {row.cachdung.length > maxLength &&
                                <span value={row.id} style={{ color: "blue", cursor: "pointer" }}
                                    onClick={(e) => showMore === row.id ? setShowMore('') : setShowMore(row.id)}>{showMore === row.id ? " Thu gọn" : "...Xem thêm"}</span>
                            }
                        </div>
                    )
                }
            }
        },
        {
            align: 'center',
            width: 90,
            render: (row) => {
                return (
                    <div>
                        {!inEditMode ?
                            <Tooltip title="Cập nhật">
                                <button className="btn btn-color" onClick={() => { setInEditMode(true); setFocusId(row.id); setNewInfo(row.cachdung) }}>
                                    <i className="fas fa-edit" />
                                </button>
                            </Tooltip>
                            :
                            row.id === focusId &&
                            <div>
                                <Tooltip title="Lưu">
                                    <button className="btn btn-color" onClick={() => updateMedicinesInstruction(row.id)}><i className="fas fa-save" /></button>
                                </Tooltip>
                                <Tooltip title="Hủy">
                                    <button className="btn btn-danger" style={{ marginLeft: 10 }} onClick={(e) => { e.stopPropagation(); resetState() }}><i className="fas fa-times" /></button>
                                </Tooltip>
                            </div>
                        }
                    </div>
                )
            }
        }
    ]

    useEffect(() => {
        getMedicinesList();
    }, [uncompleted])
    useEffect(() => {
        setHeaderHeight(_header.current.clientHeight);
    }, [])

    const getMedicinesList = (page = currentPage, size = pageSize, flag = uncompleted) => {
        Instruction.getMedicinesList(key, page, size, flag).then(res => {
            if (res.isSuccess) {
                setListMedicines(res.data);
                setTotalRecords(res.totalRecords)
                setCurrentPage(page);
                setPageSize(size);
            }
        }).catch(() => { })
    }

    const exportMedicinesList = (flag = uncompleted) => {
        Actions.setLoading(true);
        Instruction.ExportMedicinesList(flag).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = "Danh-Muc-Thuoc-Va-Cach-Dung";
                _hiddenLink.current.click();
            })
            Actions.setLoading(false);
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    const importMedicinesList = () => {
        const formData = new FormData();
        formData.append('file', fileList[0].originFileObj);
        Instruction.importMedicinesList(formData).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Import thành công" })
                setFileList([]);
                setInsertRow(res.returnObject.insertRow);
                setRejectRow(res.returnObject.rejectRow);
                setRejectList(res.returnObject.rejectList);
            }
            else {
                notification.warning({ message: res.err.msgString });
                resetImportResult();
            }
        }).catch(() => {
            resetImportResult();
        })
    }

    const updateMedicinesInstruction = (id) => {
        const data = {
            id,
            cachdung: newInfo
        }
        Instruction.createUpdateInstruction(data).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Đã cập nhật" })
                resetState();
                getMedicinesList();
            } else {
                notification.warning({ message: "Không thể cập nhật, vui lòng thử lại sau" })
            }
        }).catch(() => { })
    }

    const resetState = () => {
        setFocusId(null);
        setInEditMode(false);
    }

    const configFileUpload = {
        showUploadList: {
            showDownloadIcon: false,
        },
        onRemove(file) {
            setFileList([])
        },
        onChange(info) {
            setFileList(info.fileList)
        },
        fileList
    };

    const resetImportResult = () => {
        setInsertRow(0);
        setRejectRow(0);
        setRejectList('');
    }

    return (
        <Fragment>
            <div style={{ height: '100%' }}>
                <div className='containerChilderWrapper'>
                    <div ref={_header}>
                        <div className='h3 text-center mt-2'>Cấu hình cách dùng thuốc</div>
                        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
                        <div className="col-md-12 d-flex justify-content-center align-items-center flex-wrap">
                            <Input value={key} onChange={(e) => setKey(e.target.value)}
                                placeholder="Nhập tên thuốc để tìm kiếm" style={{ width: "60%" }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") getMedicinesList(1, 10);
                                }}
                                suffix={<Checkbox checked={uncompleted === 1} onChange={(e) => setUncompleted(e.target.checked ? 1 : 0)}>Chưa nhập cách dùng</Checkbox>} />
                            <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { getMedicinesList(1, 10) }}> <i style={{ marginRight: 5 }} className="fa fa-refresh" />Tìm kiếm</button>
                            <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { exportMedicinesList() }}><i style={{ marginRight: 5 }} className="fa fa-download" />Xuất excel</button>
                            <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { setVisibleImport(true) }}><i style={{ marginRight: 5 }} className="fas fa-upload" />Import</button>
                        </div>
                    </div>
                    <div className="row mt-2" style={{ height: `calc(100% - ${headerHeight}px - 20px)` }}>
                        <div className="col-md-12" style={{ height: `calc(100% - ${headerHeight}px)` }}>
                            <Table
                                scroll={{ x: 'max-content', y: 0 }}
                                rowKey={(row) => `tbl-inst-${row.id}`}
                                dataSource={listMedicines}
                                columns={columns}
                                pagination={false}
                                onRow={(row) => {
                                    return {
                                        onClick: () => {
                                            setFocusId(row.id);
                                            setInEditMode(true);
                                            setNewInfo(row.cachdung);
                                        }
                                    };
                                }}
                                rowClassName={(row) => {
                                    return `rowCustomSelect ${(focusId === row.id) ? 'rowCustomSelectActive' : ''}`
                                }}
                            />
                            <Pagination
                                style={{ textAlignLast: 'center', marginTop: 10 }}
                                total={totalRecords}
                                showTotal={total => `Tổng: ${total}`}
                                current={currentPage}
                                onChange={(page, size) => {
                                    getMedicinesList(page, size);
                                }}
                                onShowSizeChange={(page, size) => {
                                }}
                                showSizeChanger={true}
                            />
                        </div>
                    </div>
                    <Modal visible={visibleImport}
                        width={600}
                        onCancel={() => setVisibleImport(false)}
                        cancelText='Đóng'
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ width: "100%" }}>
                                <Dragger
                                    {...configFileUpload}
                                    beforeUpload={() => false}
                                    accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    maxCount={1}
                                    style={{ width: '100%' }}>
                                    <div>
                                        <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                        <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                        <p className="ant-upload-hint">
                                            Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                        </p>
                                    </div>
                                </Dragger>
                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 25 }}>
                                    <p style={{ textTransform: "uppercase", textAlign: "center", display: showImportResult ? 'flex' : 'none' }}>Kết quả import dữ liệu</p>
                                    <p style={{ display: insertRow > 0 ? 'flex' : 'none' }}>Cập nhật:{'\u00A0'}<Tag color="green">{`${insertRow}`}</Tag> dữ liệu.</p>
                                    <p style={{ display: rejectRow > 0 ? 'flex' : 'none' }}>Bỏ qua:{'\u00A0'}<Tag color="red">{`${rejectRow}`}</Tag> dữ liệu.</p>
                                    <p style={{ display: rejectList ? 'flex' : 'none' }}>Danh sách bỏ qua:{'\u00A0'}{`${rejectList}`}</p>
                                    <div style={{ display: rejectRow > 0 ? 'unset' : 'none', background: "aliceblue", border: "solid 1px #bbb", borderRadius: 15, padding: 9, cursor: "pointer" }}>
                                        <Tooltip title={!visibleImportInfo ? "Tìm hiểu nguyên nhân và khắc phục" : "Đóng"}>
                                            <i className={!visibleImportInfo ? "far fa-question-circle" : "far fa-times-circle"}
                                                style={{ fontSize: 18 }}
                                                onClick={() => setVisibleImportInfo(!visibleImportInfo)}></i>
                                        </Tooltip><br />
                                        <div style={{
                                            visibility: visibleImportInfo ? "visible" : "hidden", display: visibleImportInfo ? "unset" : "none"
                                        }}>
                                            <ol style={{ textAlign: "justify" }}>
                                                {rejectRow > 0 &&
                                                    <span>
                                                        <li>Nếu cách dùng không được cập nhật, hoặc để trống sẽ bị bỏ qua</li>
                                                        <li>ID không tồn tại sẽ bị bỏ qua</li>
                                                    </span>}
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <button disabled={fileList.length > 0 ? false : true} className="btn btn-color" onClick={() => importMedicinesList()}>
                                        <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import dữ liệu</button>
                                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                        onClick={() => {
                                            setVisibleImport(false);
                                            setShowImportResult(false);
                                            resetImportResult();
                                            getMedicinesList();
                                        }}>
                                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </Fragment>
    );
}

export default CachDungThuoc;