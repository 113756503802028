import React, { Component } from 'react';
import { Popconfirm, Input, Form, notification, Table, Modal, Switch, Pagination, InputNumber } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as VPService from '../services/VPService';
import CreateNew from '../components/createNewNhomVP';
import * as Actions from '../../../libs/actions';

let task = null;
const initialState = {
    totalRecords: 0,
    visible: false,
    visibleCreate: false,
}
export default class DanhSacNhomVP extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: {
                required: 'Vui lòng nhập :attribute'
            },
            element: message => <div style={{ color: 'red' }}>{message}</div>
        });
        this.state = {
            ...initialState,
            lstNhomVP: [],
            tennhomvp: '',
            viettat: '',
            hien_thi: '',
            id: 0,
            loadingHide: false,
            currentPage: 1,
            pageSize: 10,
            key: '',
            order: 1,
        };
    }
    componentDidMount() {
        this.loadDanhSachNhomVP();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }
    loadDanhSachNhomVP(page = this.state.currentPage, size = this.state.pageSize) {
        VPService.GetDanhSachNhomVP(this.state.key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNhomVP: result.data,
                    totalRecords: result.totalRecords
                });
            }
        }).catch(err => {
        })
    }
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };
    onReload() {
        this.loadDanhSachNhomVP();
    }
    handleCancel = e => {
        this.setState({
            visible: false,
            visibleCreate: false
        });
    };

    updateHide(mabd, check) {
        this.setState({ loadingHide: true })
        let status = 0;
        if (check === false) {
            status = 1;
        }
        VPService.UpdateHideNhomVP(mabd, status).then(result => {
            let data = this.state.lstNhomVP;
            let item = data.find(x => x.value === mabd);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hien_thi === 1 ? 0 : 1;
                    item.hien_thi = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hien_thi;
                    item.hien_thi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", this.getErrorMessage(result.err.msgString));
            }
            this.setState({
                lstNhomVP: data,
                loadingHide: false,
            })
        }).catch(err => {
            let data = this.state.lstNhomVP;
            let item = data.find(x => x.value === mabd);
            if (item !== undefined) {
                const newcheck = item.hien_thi;
                item.hien_thi = newcheck;
            }
            this.setState({ loadingHide: false, lstNhomVP: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    updateNhomVienPhi() {
        if (this.validator.allValid()) {
            const data = {
                'ma': Number(this.state.id),
                'tennhomvp': this.state.tennhomvp,
                'tenviettat': this.state.viettat,
                'thutu': this.state.order,
            }
            VPService.updateNhomVP(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    let list = this.state.lstNhomVP;
                    this.onReload();
                    this.handleCancel();
                    this.setState({ lstNhomVP: list, order: 1 });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    deleteNhomVienPhi(idNhomVP) {
        VPService.deleteNhomVP(idNhomVP).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                let list = this.state.lstNhomVP;
                this.setState({ lstNhomVP: list });
                this.onReload();
                this.handleCancel();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
        })
    }

    getErrorMessage(message){
        if (message) {
            let errMsg = message.split("\r\n").find(x => x.includes("HIS.Commons.HisException:"));
            return errMsg ? errMsg.trim().replace("HIS.Commons.HisException:", "") : message;
        } return "";
    }

    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 80,
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1),
            },
            // {
            //     title: 'Mã',
            //     dataIndex: 'value',
            //     key: 'ma',
            //     align: 'center',
            //     width: 100,
            // },
            {
                title: 'Tên',
                dataIndex: 'label',
                key: 'ten',
                width: '30%',
                align: 'left',
            },
            {
                title: 'Viết tắt',
                dataIndex: 'viet_tat',
                key: 'viet_tat',
                align: 'left',
            },
            {
                title: 'Thứ tự hiển thị',
                dataIndex: 'thutu',
                key: 'thutu',
                align: 'center',
                width: 150,
            },
            {
                title: 'Hiển thị',
                align: 'center',
                render: (row) => {
                    return (
                        <Switch loading={this.state.loadingHide} checkedChildren="Hiển thị" unCheckedChildren="Ẩn" checked={row.hien_thi === 0}
                            onChange={(e) => { this.updateHide(row.value, e); }} />
                    )
                }
            },
            {
                title: 'Action',
                align: 'center',
                render: (row) => {
                    return (
                        <div>
                            <button className="btn btn-warning"
                                title="Cập nhật nhóm viện phí!"
                                onClick={() => { this.setState({ id: row.value, tennhomvp: row.label, viettat: row.viet_tat, visible: true, order: row.thutu }); }} >
                                <i style={{ marginRight: 10 }} className="fa fa-edit"></i>Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { 
                                    this.deleteNhomVienPhi(row.value) 
                                }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: "15px" }}
                                ><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        let dataSource = this.state.lstNhomVP.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div className='containerChilderWrapper'  >
                        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                        <div className='h4 text-center mt-2 mb-3 font-bold text-uppercase'>Cấu hình danh mục nhóm viện phí</div>
                        <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Form.Item>
                                <Input
                                    style={{ width: 400, maxWidth: "70vw" }}
                                    value={this.state.key}
                                    placeholder="Nhập mã hoặc tên nhóm viện phí..."
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={(e) => {
                                        task = setTimeout(() => { this.loadDanhSachNhomVP(1, this.state.pageSize) }, 300);
                                    }
                                    }
                                />
                            </Form.Item>
                            {/* <Form.Item >
                                <button className="btn btn-success"
                                    onClick={this.showModalCreate}
                                ><i style={{ marginRight: 10 }} className="fa fa-plus" />Tạo mới</button> */}
                                {/* <Button disabled type="primary" className="btn btn-primary" style={{ marginLeft: 10 }}
                                    onClick={() => { }}> <i style={{ marginRight: 10 }} className="fas fa-download" />Xuất excel</Button> */}
                            {/* </Form.Item> */}
                        </div>

                        <div style={{ paddingTop: 20, height: 'calc(100% - 130px)' }}>
                            <Table dataSource={dataSource} scroll={{ y: 0 }}
                                pagination={false}
                                columns={columns} rowKey={(e, index) => index} />
                        </div>
                        <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadDanhSachNhomVP(page, size) }}
                            onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }); this.loadDanhSachNhomVP(1, size) }}
                            showSizeChanger={true}
                        />
                    </div>
                </Form>
                <Modal maskClosable={false}
                    width={900}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Cập nhật nhóm viện phí</h3>
                    </div>
                    <div className="row" style={{ padding: 'inherit' }}>
                        <div className="col-md-12 form-group" style={{ flex: 1 }} >
                            <Form.Item label="Tên nhóm" style={{ flex: 1 }}>
                                <Input
                                    placeholder="Tên nhóm viện phí"
                                    value={this.state.tennhomvp}
                                    name="tennhomvp"
                                    onChange={(e) => {
                                        this.setState({ tennhomvp: e.target.value })
                                    }} />
                                {this.validator.message('Tên nhóm viện phí', this.state.tennhomvp, 'required')}
                            </Form.Item>
                        </div>
                        <div className="col-md-12 mt-2" style={{ display: 'flex' }} >
                            <div style={{ flex: 2 }}>
                                <Form.Item style={{ flex: 1 }}>
                                    <label>Tên viết tắt</label>
                                    <Input
                                        placeholder="Tên viết tắt"
                                        value={this.state.viettat}
                                        name="vietat"
                                        onChange={(e) => {
                                            this.setState({ viettat: e.target.value })
                                        }} />
                                    {this.validator.message('Tên viết tắt', this.state.viettat, 'required')}
                                </Form.Item>
                            </div>
                            <div style={{ flex: 1 }}>
                                <Form.Item style={{ flex: 1 }}>
                                    <label>Thứ tự hiển thị</label>
                                    <InputNumber
                                        className='w-100'
                                        min={1}
                                        placeholder="Thứ tự hiển thị"
                                        value={this.state.order}
                                        name="order"
                                        onChange={(e) => {
                                            this.setState({ order: e })
                                        }} />
                                    {this.validator.message('Thứ tự hiển thị', this.state.order, 'required')}
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <button className="btn btn-success" onClick={() => this.updateNhomVienPhi()}>
                            <i className="fa fa-save" style={{ marginRight: 5 }}></i>Cập nhật</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.setState({ visible: false });
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </Modal>
                <Modal maskClosable={false}
                    width={900}
                    visible={this.state.visibleCreate}

                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateNew
                        onCancel={this.handleCancel}
                        onReload={this.onReload.bind(this)} />
                </Modal>
            </React.Fragment>
        )
    }
}


