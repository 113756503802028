import React, { Fragment } from 'react';

function HeaderModal(props) {
    const handleCancel = () => {
        props.onClose();
    }
    return (
        <Fragment>
            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>{props.title ? props.title : "Chưa có tiêu đề"}</h3>
                {props.onClose && <h3 onClick={() => { handleCancel(); }}>
                    <i className='far fa-times-circle stopRowClick' style={{ backgroundColor: "#ccc", borderRadius: "50%", border: "3px solid #ccc" }} />
                </h3>}
            </div>
        </Fragment>
    );
}

export default HeaderModal;