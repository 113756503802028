import * as ApiCaller from '../libs/httpRequests';

const prefix = 'MedicalExamination';
const prefix1 = 'Common';
export function SaveMediExam(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/khambenh`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ListXuTri()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/dsxutri`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ListTuyen()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix1}/dstuyen`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ThongTinKetLuan(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThongTinKetLuan?maql=${maql}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
/*2023.03.17: Có thể cái này không dùng nữa.
sau 3 tháng mà không dùng nữa thì xóa nhé */
// export function LuuKetLuan(data) {
//     return new Promise((resolve, reject) => {
//         return ApiCaller.httpPost(`${prefix}/luuketluan`,data).then(respone =>{
//             return resolve(respone) 
//         }).catch(err =>{
//             return reject(err) 
//         })
//     });
// };
export function renewSIDFromSystem(managementID, orderId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetNewSID?managementID=${managementID}&orderId=${orderId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
}
export function UpdateSID(managementID, orderId, SIDType, SID) {
    let data = {
        MaQl: managementID,
        orderId: orderId,
        InputSidType: SIDType,
        Code: SID,
    }
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateSID`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
}
export function GetSIDInfo(managermentID, SID) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetSIDInfo?managermentID=${managermentID}&SID=${SID}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
}
export function GetSIDInfoByOrderId(managermentID, orderId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetSIDInfoByOrderId?managermentID=${managermentID}&orderId=${orderId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
}
export function ValidSID(SID) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ValidSID/${SID}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
}
export function GetListByExamRoom(filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListByExamRoom`, filter).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetExaminationDetail(examId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetExaminationDetail/${examId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListTreatmentDirection() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListTreatmentDirection`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function SaveExamination(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SaveExamination`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function Print(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/Print`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ListRegisterSubClinical(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ListRegisterSubClinical/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
