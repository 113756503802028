
import React, { useEffect, useState, useRef, forwardRef, useCallback } from 'react';
import { Select } from 'antd';
import * as Actions from '../../libs/actions';
import Notify, { NotifyStatus } from '../../components/notify';
import * as PrintTemplateService from '../../services/printTemplateService';

const { Option } = Select;
export default forwardRef((props, ref) => {
    const notiRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    /*Các props cần truyền vào:
    - componentId: componentId của PrintTemplate
    */
    //state hứng props
    const [componentId, setComponentId] = useState(""); 
    const [totalRecord, setTotalRecord] = useState(0);
    const [listResult, setListResult] = useState([]);
    const [selectedId, setSelectedId] = useState(); 
    const [viewMode, setViewMode] = useState();
    const [displayMode, setDisplayMode] = useState('select'); //"select" | "radio": chế độ chọn select hoặc radio
    
    useEffect(() => {
      setComponentId(props.componentId ?? "");
      getListPrintTemplate();
    }, [props.componentId]);

    const getListPrintTemplate = () => {
        Actions.setLoading(true);
        let filterModel = {
          skipCount: 0,
          maxResultCount: .0,
          //riêng theo module
          componentId: props.componentId,
        }
        PrintTemplateService.getList(filterModel).then(result => {
          if (result.isSuccess) {
            setTotalRecord(result.data.totalCount);
            setListResult(result.data.items);
            let recordDefault = result.data.items.find(x=>x.isDefault);
            if (recordDefault) {
              setSelectedId(recordDefault.id);
              props.changeSelectedId(recordDefault.id)
            }
            notiRef && notiRef.current.Close();
          }
          else {
            notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
          }
        }).catch(err => {
          if (err.error && err.error.messageText)
            notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
          else notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    return (
        <>
          <div className="row">{/* notification */}
            <div className="col-md-12"><Notify ref={notiRef} /></div>
          </div>
          <div className="row"> {/* Mô tả */}
            <div className="col-md-12 d-flex flex-column">
              <label>Chọn template</label>
              <Select
                // allowClear
                showSearch
                filterOption={(input, option) => {
                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                className="w-100"
                placeholder="Chọn template"
                maxTagCount='responsive'
                value={selectedId}
                onChange={(value) => { setSelectedId(value); props.changeSelectedId(value); }}
              >
              {(listResult ?? []).map((record, index) => {
                return (
                  <Option key={record.id} value={record.id}>{record.printTemplateName}</Option>
                )
              })}
              </Select>
            </div>
          </div>
        </>
    );
})