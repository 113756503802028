import { Button, DatePicker, Select } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import Notify, { NotifyStatus } from '../../../../components/notify';
import { BUTTON } from '../../../../configureHIS/common/constants';
import { connect } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as ConfigService from '../../../../services/configService';
import * as AuthServices from '../../../../services/authServices';
import * as SystemConfig from "../../../../configure/systemConfig";
import * as UsersService from '../../../../services/usersService';
import DonutChart from '../../../../components/chart/DonutPlotWithStatistics'
import HeaderModal from '../../../../configureHIS/common/headerModal'
import Chitiet from './tieptanchitiet';
import SiteFilter from '../../../../configureHIS/common/siteFilter';

const { Option } = Select;
const { RangePicker } = DatePicker;
function  NguoiTiepDon() {
    const [date, setDate] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [isMultisite, setIsMultisite] = useState(false);

    const [key, setKey] = useState('');
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSelect, setDataSelect] = useState([]);
    const [listSelected, setListSelected] = useState([]);
    const [site, setSite] = useState([]);
    const [availableSites, setAvailableSites] = useState([]);

    const [isChart, setIsChart] = useState(true);

    const _hiddenLink = useRef();
    const _report = useRef();
    const _notiRef = useRef();
    const _chartWithNumber = useRef();
    const _chartWithValue = useRef();

    useEffect(() => {
        getAvaiableSites();
        GetListSite();
    }, [])

    useEffect(() => {
        Reload()
    }, [isChart]);

    const Reload = () =>{
        ThongKeNguoiTiepDon();
        if(_report && _report.current){
            _report.current.reload();
        }
    }

    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }

    const getAvaiableSites = () => {
        AuthServices.getAvailableSites().then((res) => {
            if (res.isSuccess) {
                setAvailableSites(res.data);
            }
        })
    };

    function ThongKeNguoiTiepDon() {
        Actions.setLoading(true);
        let filterModel = {
            //common: paging & sorting
            skipCount: (page - 1) * size,
            maxResultCount: size,
            //riêng theo module
            dateFrom: date[0],
            dateTo: date[1],
            referenceIds: listSelected,
            siteIds : site,
        }
        StatisticService.ReceptionistRevenueStatisticChart(filterModel).then(result => {
            if (result.isSuccess) {
                let dataSourceWithNumber = result.data && result.data.map(x => ({type : x.name , value : x.totalNumber}))
                let dataSourceWithValue = result.data && result.data.map(x => ({type : x.name , value : x.revenue}))
                _chartWithNumber.current && _chartWithNumber.current.setDataSource(dataSourceWithNumber);
                _chartWithValue.current && _chartWithValue.current.setDataSource(dataSourceWithValue);
                setDataSource(result.data);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    return (
        <>
        <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
        <div className='containerChilderWrapper'>
            <HeaderModal title='Thống kê người tiếp đón' />
            <Notify ref={_notiRef} />
            <div className='row mt-2'>
                <SiteFilter updateSeletedSite={(listSite) => { setSite(listSite) }} />
                <div className="col-md-8 mt-2">
                    {/* <label>Lựa chọn</label>
                    <div className='row no-gutters'>
                        <div className='col-8'>
                            <Select
                                id="prosta-slt-people"
                                mode="multiple"
                                allowClear
                                className='w-100'
                                placeholder="Nhập mã, tên"
                                value={listSelected}
                                onChange={(e) => { setListSelected(e) }}
                                onSearch={(e) => {setKey(e)}}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {dataSelect && dataSelect.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div> */}
                </div>
                <div className="col-md-4 mt-2">
                    <label>Ngày lập phiếu</label>
                    <RangePicker
                        id="promotion-select-date"
                        className='w-100'
                        locale={viVN}
                        value={date}
                        allowClear={false}
                        format={[SystemConfig.FORMAT_DATE, SystemConfig.FORMAT_DATE]}
                        onChange={(e) => { setDate(e) }}>
                    </RangePicker>
                </div>
            </div>
            <div className="row">
                <div className="ml-auto d-flex flex-direction-row align-items-center mt-2">
                    <span>Hiển thị</span>
                    <div className="col-auto">
                        <Select
                            id="promotion-select-chrtlst"
                            style={{ width: 200 }}
                            value={isChart}
                            onChange={(value) => {
                                setIsChart(value);
                            }}>
                            <Option key={1} value={true}><i className="fas fa-chart-bar mr-1" />Biểu đồ</Option>
                            <Option key={2} value={false}><i className="fas fa-list mr-1" />Danh sách</Option>
                        </Select>
                    </div>
                    <div className="col-auto">
                        <button className={BUTTON.THEME} onClick={() => { Reload() }}><i className="fas fa-chart-bar mr-1" />Thống kê</button>
                    </div>
                </div>
            </div>
            {isChart ?
                <div className='row mt-2 d-flex justify-content-between'>
                        <div className='warrperStyleGroup ml-2 p-2' style={{width: 'calc(50% - 10px)'}}>
                            <label className='d-flex justify-content-center'>Biểu đồ theo số lượng khách hàng</label>
                            <DonutChart ref={_chartWithNumber} isValue ={false} />
                        </div>
                        <div className='warrperStyleGroup mr-2 p-2' style={{width: 'calc(50% - 10px)'}}>
                            <label className='d-flex justify-content-center'>Biểu đồ theo thực thu</label>
                            <DonutChart ref={_chartWithValue} isValue ={true} />
                        </div>
                </div>
            :
                <Chitiet ref={_report} ngay={date} siteid={site} chinhanh={chinhanh} listSelected={listSelected}/>
            }
            </div>
            
        </>
    )
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(NguoiTiepDon);
