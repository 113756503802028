import { DatePicker, Form, Input, InputNumber, notification, Popconfirm, Statistic } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import EditableTable from '../../../commons/editableTable';
import { ValidationCustom } from '../../../commons/validationCustom';
import AutocompleteTable from '../../../components/autocompletetable';
import AutocompletetableNoGroup from '../../../components/autocompletetable/autocompletetableNoGroup';
import * as Actions from '../../../libs/actions';
import { isNullOrEmpty } from '../../../libs/util';
import * as ChiDinhService from '../../../services/chiDinhService';
import * as CommonService from '../../../services/commonService';
import * as MedicineService from '../../../services/medicineService';
import * as PrintService from '../../../services/printService';

const initialState = {
    thuoc: '',
    lstBN: [],
    lstDetail: [],
    lstDetailNew: [],
    lstColums: [],
    giaban: 0,
    soluong: 0,
    tongtien: 0,
    tongtienNew: 0,
    disible: true,
    hidden: true,
    tinhtien: true,
    drawShow: false,
    render: '',
    loai: '',
    sophieu: 0,

    hoten: '',
    sodt: '',
    key: '',
    cmnd: null,
    ngaysinh: null,
    hangsanxuat: ''
}
export default class toabanle extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            AdministrativePatientName: 'Hành Chánh',
            activeKey: [],
            listThuoc: [],
            disibleSearch: false,
            tenKho: '',
            maNguon: 11,
            maKho: 502,
            lstKho: [],
            lstNguon: [],
            isPrint: false,
            printdata: '',
            lstCachDung: [],
            txtCachdung: '',
            listMedicineGroup: [],
            ghichu: ''
        };
    }
    componentWillMount() {
        this.SearchMedicine();
        this.loadKho();

    }
    componentDidMount() {
        this.resetField();
    }
    PrintConclusion() {
        const data = this.state.printdata.split('-');
        if (data.length !== 4) {
            Actions.openMessageDialog("Lỗi", "Không thể in lúc này, xin thử lại!")
        }
        PrintService.PrintConclusionBanLe(data[1], data[0], Number(data[3]), data[2]).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintUse() {
        const data = this.state.printdata.split('-');
        if (data.length !== 4) {
            Actions.openMessageDialog("Lỗi", "Không thể in lúc này, xin thử lại!")
        }
        PrintService.PrintUseRetail(data[0], data[2]).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    loadKho() {
        MedicineService.GetListKho().then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        tenKho: result.data[0].label,
                    });
                }
                this.setState({
                    lstKho: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' })
        })
    }
    resetField() {
        this.setState({
            ...initialState,
            listThuoc: [],
            lstDetailNew: [],
            disibleSearch: false,
            isPrint: false,
            printdata: '',
            lstCachDung: [],
            txtCachdung: '',
            tuoi: '',
            hoten: '',
            mabn: ''
        }, () => this.clearTalbe());
        try {
            this.AutocompletePatient.setValue(null);
        } catch (error) {

        }

        this.form.setFieldsValue({
            mabn: null,
            key: null,
            listThuoc: [],
            newTable: [],
            lstDetailNew: [],
            hoten: null,
            sodt: null,
            cmnd: null,
            tuoi: null
        })
    }
    clearTalbe() {
        this.newTable.setDataSource(this.state.lstDetailNew);
    }
    onClose = () => {
        this.setState({
            drawShow: false,
        });
    };
    async LuuHoaDonBanLe() {
        if ((this.state.hoten === '' || this.state.hoten === undefined) && this.state.mabn === '') {
            notification.warning({ message: 'Vui lòng nhập họ tên' })
            return;
        }
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            let medicines = this.newTable.getDataSource()
            for (let item of medicines) {
                item.soluong = +item.soluong;
                item.ngay = moment();
                item.songay = isNullOrEmpty(item.songay) ? 1 : item.songay;
                item.slsang = isNullOrEmpty(item.slsang) ? 0 : item.slsang;
                item.sltrua = isNullOrEmpty(item.sltrua) ? 0 : item.sltrua;
                item.slchieu = isNullOrEmpty(item.slchieu) ? 0 : item.slchieu;
                item.sltoi = isNullOrEmpty(item.sltoi) ? 0 : item.sltoi;
            }
            let data = {
                ngay: moment(),
                id: this.state.printdata,
                mabn: this.state.mabn,
                hoten: this.state.hoten,
                dienthoai: this.state.sodt ? (this.state.sodt).toString() : '',
                cmnd: this.state.cmnd ? (this.state.cmnd).toString() : null,
                ngaysinh: moment(this.state.ngaysinh ? this.state.ngaysinh : moment()),
                medicines,
                ghichu: this.state.ghichu
            }
            Actions.setLoading(true);
            MedicineService.LuuHoaDonBanLe(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Lưu thành công!' })
                    this.setState({ isPrint: true, printdata: result.id })
                    Actions.ReloadPhieuThuoc(true);
                }
                else {
                    notification.warning({ message: result.err.msgString })
                }
            }).catch(err => {
                notification.warning({ message: 'Lưu bị lỗi!' })
            }).finally(() => Actions.setLoading(false))
        }
    }
    loadPatient(key) {
        return CommonService.searchPatient(key);
    }
    onSelectPatient(item) {
        this.setState({
            mabn: item.patientID,
            hoten: item.patientName,
            ngaysinh: moment(item.birthYear),
            cmnd: item.cardId,
            sodt: item.phone,
            disibleSearch: true,
            activeKey: "1",
            tuoi: moment().year() - moment(item.birthYear).year()
        })

        this.form.setFieldsValue({
            mabn: item.patientID,
            hoten: item.patientName,
            ngaysinh: moment(item.birthYear),
            cmnd: item.cardId,
            sodt: item.phone,
            disibleSearch: true,
            activeKey: "1",
            tuoi: moment().year() - moment(item.birthYear).year()
        })
        try {
            this.AutocompletePatient.setValue(item.patientName);
        } catch (error) {

        }
    }
    xoathuoc(row) {
        let lstDetailNew = this.state.lstDetailNew;
        let lst = this.state.lstDetailNew.filter(x => x.id !== row.id);
        lstDetailNew = lst;
        this.newTable.setDataSource(lstDetailNew);
        let tongtienNew = 0;
        for (let item of lstDetailNew) {
            if (item.soluong && item.giathucthu)
                tongtienNew += item.soluong * item.giathucthu;
        }
        this.setState({ tongtienNew, lstDetailNew })
    }
    handleChangeNewMedicine(data) {
        try {
            let tongtienNew = 0;
            for (let item of data) {
                if (item.soluong && item.giathucthu)
                    tongtienNew += item.soluong * item.giathucthu;
            }
            let temp = data.map(function (e, index) {
                let o = Object.assign({}, e);
                return o;
            })
            this.newTable.setDataSource(temp);
            this.setState({ tongtienNew: tongtienNew, lstDetailNew: temp });
        } catch (error) {

        }
    }
    keyFunction = (e, row, col) => {
        if (col === 'cachdung') {
            e.preventDefault();

            if (e.key === 'Tab') {
                if (row < this.state.lstDetailNew.length) {
                    this.newTable.focusNewRow(row + 1,
                        'soluong');
                } else {
                    if (this.Autocomplete)
                        this.Autocomplete.focus();
                }

            }
            if (e.key === 'Enter') {
                if (this.ButtonSave)
                    this.ButtonSave.focus();
            }
        }

    }
    AddNewMedicine(item) {
        let { lstDetailNew } = this.state
        item.id = Number(item.value);
        item.makho = Number(item.makho);
        item.manguon = Number(item.manguon);
        let olditems = lstDetailNew.filter(record => record.ma === item.ma);
        if (olditems.length > 0) {
            return
        } else {
            item.tenthuoc = item.ten;
            item.dang = item.donvi;
            item.isNew = true
            item.key = item.ma
            item.soluong = null
            item.countNumber = lstDetailNew.length + 1;
            lstDetailNew.push(item);
        }
        let tongtienNew = 0;
        for (let item of lstDetailNew) {
            if (item.soluong && item.giathucthu)
                tongtienNew += item.soluong * item.giathucthu;
        }

        this.newTable.setDataSource(lstDetailNew);
        setTimeout(() => {
            this.newTable.focusNewRow(lstDetailNew[lstDetailNew.length - 1].countNumber,
                'soluong');
        }, 200)

        this.setState({ tongtienNew: tongtienNew, lstDetailNew: lstDetailNew })
    }

    SearchMedicine(key) {
        return MedicineService.DanhSachThuocBanle(2, key, 11, 502)
    }
    SearchGoiThuoc(key) {
        return MedicineService.GetListGoiThuoc(key, 1, 20);
    }
    onSelect(item) {
        if (item.slton === 0) {
            notification.warning({ message: `Thuốc ${item.ten} không còn trong kho !!!` })
        } else {
            item.giathucthu = item.dongia;
            this.AddNewMedicine(item)
        }
    }
    onSelectGoiThuoc(item) {
        MedicineService.DanhSachThuocBanLeTrongGoiThuoc(item.id, 11, '', false).then(result => {
            if (result.isSuccess === true) {
                if (result.data.length > 0) {
                    let data = this.state.lstDetailNew;
                    result.data.forEach(item => {
                        item.id = Number(item.value);
                        item.makho = Number(item.makho);
                        item.manguon = Number(item.manguon);
                        let olditems = data.filter(record => record.ma === item.ma);
                        if (olditems.length > 0) {
                            // continue;
                        } else {
                            item.tenthuoc = item.ten;
                            item.dang = item.donvi;
                            item.isNew = true
                            item.key = item.ma
                            item.countNumber = data.length + 1;
                            item.giathucthu = item.dongia;
                            data.push(item);
                        }
                    });
                    let tongtienNew = 0;
                    for (let item of data) {
                        if (item.soluong && item.giathucthu)
                            tongtienNew += item.soluong * item.giathucthu;
                    }

                    this.newTable.setDataSource(data);
                    setTimeout(() => {
                        this.newTable.focusNewRow(data[data.length - 1].countNumber,
                            'soluong');
                    }, 200)

                    this.setState({ tongtienNew: tongtienNew, lstDetailNew: data })
                }
                else {
                    notification.warning({ message: 'Không có thuốc nào trong kho.' })
                }
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        })
    }
    DanhSachCachDung(txtCachdung) {
        let data = [];
        MedicineService.DanhSachCachDung(txtCachdung).then(result => {
            if (result.isSuccess) {
                data = result.data;
                if (result.data.findIndex(x => x.value === txtCachdung) < 0) {
                    data.push({ 'value': txtCachdung, 'label': txtCachdung })
                }
                this.setState({
                    lstCachDung: data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải cách dùng bị lỗi' })
        })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    render() {
        const columPatientSearch = [
            {
                title: 'Họ tên',
                dataIndex: 'patientName',
                key: 'patientName',
                width: 200,
                render: (value) => {
                    return <b>{value}</b>;
                },
            },
            {
                title: 'Năm sinh',
                dataIndex: 'birthYear',
                key: 'birthYear',
                width: 200,
                format: 'datetime'
            },
            {
                title: 'Số điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                width: 200,
            },

        ]
        const columns = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => Number(index) + 1,
                width: 40,
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                width: 150,
            },

            {
                title: 'ĐVT',
                dataIndex: 'dang',
                key: 'dang',
                width: 70
            },
            {
                title: 'Tên hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                width: 150,
            },
            {
                title: 'Hãng sản xuất',
                dataIndex: 'hangsanxuat',
                key: 'hangsanxuat',
                width: 120
            },
            {
                title: 'Nước s/x',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 100
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                type: 'number',
                width: 100,
                editable: true,
                isrequire: true,
                maxLength: 7,
                min: 1,
                //    CustomKey: 'ma'
            },
            {
                title: 'Cách dùng',
                key: 'cachdung',
                dataIndex: 'cachdung',
                editable: true,
                width: 230,
                //  CustomKey: 'ma'
            },
            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                width: 80,
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : '0'}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                type: 'number',
                width: 100,
                editable: true,
                isrequire: true,
                min: 0,
            },
            {
                title: '',
                width: 30,
                render: (row, index) => {

                    return (
                        <Popconfirm
                            title="Bạn có chắc muốn xóa thuốc này không?"
                            onConfirm={() => { this.xoathuoc(row) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <div style={{ cursor: 'pointer' }}>
                                <span style={{ width: '100%', height: '100%', marginTop: 3, color: 'red' }}> <i className="fa fa-trash"></i>  </span>
                            </div>
                        </Popconfirm>
                    )
                }
            }
        ];
        const columnsSearh = [
            {
                title: 'Tên thuốc',
                dataIndex: 'ten',
                key: 'ten',
                width: 200,
            },
            {
                title: 'Tên hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                width: 350,
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 100,
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                width: 80,
                format: 'number'
            },
            {
                title: 'Đơn vị',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 80,
            },
            {
                title: 'Tồn kho',
                dataIndex: 'slton',
                key: 'slton',
                width: 80,
                format: 'number'
            },
        ];
        const columnsGoiThuoc = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                width: 100,
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                width: 350,
            },
            {
                title: 'Số ngày',
                dataIndex: 'songay',
                key: 'songay',
                width: 100,
            },
            {
                title: 'Mô tả',
                dataIndex: 'mota',
                key: 'mota',
                width: 150,
            }
        ];
        let dataSourceNew = this.state.lstDetailNew.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.ma
            return o;
        })
        return (
            <React.Fragment>
                <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>Phiếu xuất bán lẻ</h3>
                    <h3 onClick={() => this.props.onClose()}><i className='far fa-times-circle' /></h3>
                </div>
                <Form ref={(c) => this.form = c} style={{ height: 'auto' }}>
                    <div
                        className="row"
                        style={{
                            borderBottom: 'rgb(232,232,232)',
                            marginBottom: 10
                        }}>
                        <div className="col-12"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                            <h4 style={{ flex: 3, textAlign: 'left' }}>Thông tin bệnh nhân</h4>
                            {this.state.mabn &&
                                <span><b>Mã bệnh nhân: {this.state.mabn}</b></span>
                            }
                        </div>
                        {/* <Form.Item name="key" style={{ flex: 1 }}>
                                <Input
                                    style={{ marginRight: 10 }}
                                    placeholder="Nhập tên hoặc mã bệnh nhân"
                                    onChange={(e) => { this.setState({ key: e.target.value }) }}
                                />
                            </Form.Item> */}
                    </div>
                    <div className="row" style={{ marginBottom: 10 }}>
                        {/* <Form.Item label="Họ tên:" name="hoten" style={{ mmarginRight: 10 }} rules={[{ required: true, message: 'Vui lòng nhập họ tên!' }]}>
                                <Input
                                    placeholder="Nhập họ tên"
                                    style={{ width: 200, marginRight: 30 }}
                                    value={this.state.hoten}
                                    onChange={(e) => { this.setState({ hoten: e.target.value }) }}
                                />
                            </Form.Item> */}
                        <div className="col-md-4 col-sm-12">
                            <Form.Item label="Họ tên">
                                <AutocompletetableNoGroup
                                    ref={(c) => this.AutocompletePatient = c}
                                    placeholder={"Nhập họ tên"}
                                    tablewidth={700}
                                    onSearch={this.loadPatient}
                                    onChange={(value) => this.setState({ hoten: value })}
                                    onSelect={this.onSelectPatient.bind(this)}
                                    value={this.state.hoten} headers={columPatientSearch}
                                    keyValue={'patientID'}>
                                </AutocompletetableNoGroup>
                            </Form.Item>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-6">
                            <Form.Item label="Số điện thoại:" name="sodt" >
                                <Input
                                    placeholder="Nhập số điện thoại"
                                    value={this.state.sodt}
                                    onKeyPress={(e) => {
                                        if (e.key === " " || isNaN(e.key) === true)
                                            e.preventDefault();
                                    }}
                                    maxLength={11}
                                    onChange={(e) => {
                                        this.setState({ sodt: e.target.value });
                                        this.validator.showMessages();
                                    }} />
                                {this.validator.message("Số điện thoại", this.state.sodt, "phonenumber")}
                            </Form.Item>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-6">
                            <Form.Item label="Căn cước công dân:" >
                                <Input
                                    placeholder="Nhập căn cước công dân"
                                    onKeyPress={(e) => {
                                        if (e.key === " " || isNaN(e.key) === true)
                                            e.preventDefault();
                                    }}
                                    maxLength={20}
                                    value={this.state.cmnd}
                                    onChange={(e) => {
                                        this.setState({ cmnd: e.target.value });
                                        this.validator.showMessages();
                                    }} />
                                {this.validator.message("Căn cước công dân", this.state.cmnd, "cmnd")}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: 10 }}>
                        <div className="col-md-4 col-sm-6 col-xs-6">
                            <Form.Item label="Ngày sinh:">
                                <DatePicker
                                    allowClear={false}
                                    className="w-100"
                                    locale={viVN}
                                    format={"DD-MM-YYYY"}
                                    disabled={this.state.disibleSearch}
                                    disabledDate={this.disabledDate}
                                    defaultValue={moment()}
                                    value={this.state.ngaysinh}
                                    placeholder="DD-MM-YYYY"
                                    onChange={(value) => (this.setState({ ngaysinh: value, tuoi: moment().year() - value.year() }))} />
                            </Form.Item>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-6">
                            <Form.Item label="Tuổi:" >
                                <InputNumber
                                    className="w-100"
                                    disabled={this.state.disibleSearch}
                                    placeholder="Nhập tuổi"
                                    min={0}
                                    maxLength={3}
                                    max={150}
                                    value={this.state.tuoi}
                                    onChange={(e) => { this.setState({ tuoi: e, ngaysinh: moment(`${moment().subtract('year', e).year()}-01-01`) }) }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form >
                <div>
                    <div className="row" style={{ marginBottom: 10 }}>
                        <div className="col-md-8" style={{ width: "100%", marginTop: 20 }}>
                            <AutocompleteTable
                                placeholder={"Tìm theo tên hay hoạt chất"}
                                tablewidth={1000}
                                width={250}
                                onSearch={this.SearchMedicine}
                                onSelect={this.onSelect.bind(this)}
                                value={this.state.thuoc} headers={columnsSearh}
                                keyValue={'ma'}
                                ref={(c) => { this.Autocomplete = c }}
                            >
                            </AutocompleteTable>
                            <AutocompleteTable
                                placeholder={"Tìm theo mã, tên gói thuốc"}
                                width={250}
                                tablewidth={800}
                                listHeight={"50vh"}
                                onSearch={this.SearchGoiThuoc}
                                onSelect={this.onSelectGoiThuoc.bind(this)}
                                value={this.state.goithuoc} headers={columnsGoiThuoc}
                                hideGroupHeader={true}
                                keyValue={'id'}
                            >
                            </AutocompleteTable>
                            <button className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => { this.resetField(); }} >
                                <i style={{ marginRight: 5 }} className="fa fa-eraser" />Làm mới
                            </button>
                        </div>
                        <div className="col-md-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                            <Statistic
                                valueStyle={{ fontSize: 14 }}
                                title="Tổng Tiền" value={this.state.tongtienNew.toLocaleString('en-US') + ' VNĐ'}
                                precision={2} />
                        </div>
                    </div>
                    <EditableTable
                        ref={(c) => this.newTable = c}
                        onchange={(data) => { this.handleChangeNewMedicine(data) }}
                        scrollY={'35vh'}
                        dataSource={dataSourceNew} columns={columns} pagination={false}
                        keyFunction={this.keyFunction}
                        rowClassName={(record) => {
                            return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                        }}>
                    </EditableTable>
                    <div style={{ marginTop: 10 }}>Ghi chú: <br></br>
                        <Input style={{ marginTop: 7 }}
                            placeholder="Nhập ghi chú"
                            value={this.state.ghichu}
                            onChange={(e) => { this.setState({ ghichu: e.target.value }) }} />
                    </div>
                </div>
                <div className='custom-hr' />
                <div
                    className="form-group"
                    style={{
                        marginTop: 15, display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    {this.state.isPrint !== true &&
                        <button className="btn btn-color"
                            ref={(c) => { this.ButtonSave = c }}
                            onClick={() => this.LuuHoaDonBanLe()} style={{ marginRight: 5 }}>
                            <i className="fa fa-save" style={{ marginRight: 5 }} />Lưu
                        </button>
                    }
                    {this.state.isPrint === true &&
                        <button className="btn btn-color"
                            ref={(c) => { this.ButtonSave = c }}
                            onClick={() => this.LuuHoaDonBanLe()} style={{ marginRight: 5 }}>
                            <i className="fa fa-save" style={{ marginRight: 5 }} />Chỉnh sửa
                        </button>
                    }
                    {this.state.isPrint === true &&
                        <div>
                            <button className="btn btn-outline-secondary"
                                onClick={() => { this.PrintConclusion() }} style={{ marginLeft: 10 }}>
                                <i className="fa fa-print" style={{ marginRight: 5 }} />In phiếu
                            </button>
                            <button className="btn btn-outline-secondary"
                                onClick={() => { this.PrintUse() }} style={{ marginLeft: 10 }}>In cách dùng
                            </button>
                        </div>
                    }
                    <button className="btn btn-danger" style={{ marginLeft: 5 }}
                        onClick={() => {

                            this.handleChangeNewMedicine([]);
                            this.newTable.setDataSource([]);
                            this.setState({ lstDetailNew: [], printdata: '' })
                            if (this.props.onClose)
                                this.props.onClose()
                        }}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                        />Đóng
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

